import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { InputValidationCheck, preventPlusSign } from "../../ValidationCheck";
import toast, { Toaster } from "react-hot-toast";

const TotalSalesTable = ({ salesData = [], setsalesData, type, getSubmitStatus, preventInvalidKeyPress, POSDataStatus }) => {
  // console.log("TotalSales_Table", salesData);
  // const handleInputChange = (index, field, value) => {
  //   const updatedData = [...salesData];
  //   updatedData[index][field] = value;
  //   setsalesData(updatedData);
  // };
  // const handleInputChange = (index, key, value) => {
  //   setsalesData((prevData) => {
  //       const updatedData = [...prevData];
  //       const currentItem = { ...updatedData[index] };

  //       if (key === 'sales') {
  //           // Text input handling
  //           if (value === '') {
  //               currentItem[key] = value;
  //           } else if (/^\d/.test(value)) {
  //               console.warn('The sales type cannot start with a number');
  //               return prevData;
  //           } else if (InputValidationCheck(value)) {
  //               console.warn('Invalid input for sales type');
  //               return prevData;
  //           } else {
  //               currentItem[key] = value.trimStart();
  //           }
  //       } else {
  //           // Number input handling
  //           if (value === '' || /^-?\d*(\.\d*)?$/.test(value)) {
  //               currentItem[key] = value === '' ? 0 : parseFloat(value); // Default to 0 if blank
  //           } else {
  //               console.warn('Invalid input for number field');
  //               return prevData;
  //           }
  //       }

  //       // Update the specific row
  //       updatedData[index] = currentItem;
  //       return updatedData;
  //   });
  // };
  // const handleInputChange = (index, key, value) => {
  //   setsalesData((prevData) => {
  //     // Clone the data and the specific item being updated
  //     const updatedData = [...prevData];
  //     const currentItem = { ...updatedData[index] };
  
  //     if (key === "sales") {
  //       // Handle text input for 'sales'
  //       if (!value) {
  //         // Allow empty input
  //         currentItem[key] = value;
  //       } else if (/^\d/.test(value)) {
  //         // Prevent 'sales' type from starting with a number
  //         console.warn("The sales type cannot start with a number");
  //         return prevData;
  //       } else if (InputValidationCheck(value)) {
  //         // Check for additional invalid input
  //         console.warn("Invalid input for sales type");
  //         return prevData;
  //       } else {
  //         // Update 'sales' with trimmed input
  //         currentItem[key] = value.trimStart();
  //       }
  //     } else {
  //       // Handle numeric inputs
  //       if (value === "" || /^-?\d*(\.\d*)?$/.test(value)) {
  //         // Allow valid numbers or empty input (default to 0)
  //         currentItem[key] = value === "" ? 0 : parseFloat(value);
  //       } else {
  //         // Invalid number input
  //         console.warn("Invalid input for number field");
  //         return prevData;
  //       }
  //     }
  
  //     // Reset the highlight property whenever a field is updated
  //     currentItem.highlight = false;
  
  //     // Update the specific row in the data array
  //     updatedData[index] = currentItem;
  //     return updatedData;
  //   });
  // };
  
  const handleInputChange = (index, key, value) => {
    setsalesData((prevData) => {
      debugger
      const updatedData = [...prevData];
      const currentItem = { ...updatedData[index] };
  
      if (key === "sales") {
        // Handle text input for 'sales'
        if (value === "") {
          currentItem[key] = value; // Allow empty value
          currentItem.highlightSales = true; // Highlight field if empty
        } else if (/^\d/.test(value)) {
          console.warn("The sales type cannot start with a number");
          return prevData;
        } else if (InputValidationCheck(value)) {
          console.warn("Invalid input for sales type");
          return prevData;
        } else {
          currentItem[key] = value.trimStart();
          currentItem.highlightSales = false; // Remove highlight when valid
        }
      } else if (key === "qty") {
        // Handle number input for 'qty'
        if (value === "" || /^-?\d*(\.\d*)?$/.test(value)) {
          currentItem[key] = value === "" ? 0 : parseFloat(value); // Default to 0 if blank
          currentItem.highlightQty = value === "" || parseFloat(value) === 0; // Highlight if invalid
        } else {
          console.warn("Invalid input for quantity");
          return prevData;
        }
      } else {
        if (value === "" || /^-?\d*(\.\d*)?$/.test(value)) {
          currentItem[key] = value === "" ? 0 : parseFloat(value); // Default to 0 if blank
          // currentItem.highlightQty = value === "" || parseFloat(value) === 0; // Highlight if invalid
        } else {
          console.warn("Invalid input for quantity");
          return prevData;
        }

      }
  
      // Update the current row
      updatedData[index] = currentItem;
  
      // Check if the immediate previous row's 'sales' and 'qty' fields are empty
      if (index > 0) {
        const prevRow = updatedData[index - 1];
        const isSalesEmpty = prevRow.sales === "";
        const isQtyEmpty = prevRow.qty === 0 || prevRow.qty === "";
  
        // Update highlight based on whether fields are still empty
        updatedData[index - 1] = {
          ...prevRow,
          highlightSales: isSalesEmpty,
          highlightQty: isQtyEmpty,
        };
      }
  
      return updatedData;
    });
  };

  function getReadOnlyStatus(){
    let form = getSubmitStatus().form;
    let pos = POSDataStatus;
    if(pos) return true;
    else{
      return !form
    }

  }
  
  
  
  // const handleAddRow = () => {
  //   setsalesData([
  //     ...salesData,
  //     { sales: "", qty: 0, grossAmount: 0, refunds: 0, discounts: 0, amount: 0 },
  //   ]);
  // };
  // const handleAddRow = () => {
  //   setsalesData((prevData) => {
  //     // Check if there are rows in the data
  //     if (prevData.length > 0) {
  //       // Get the last row (immediate previous row)
  //       const lastRow = prevData[prevData.length - 1];
  
  //       // Check if any field in the last row is empty
  //       const isEmptyField = Object.entries(lastRow).some(([key, value]) => {
  //         if (key !== "highlight") {
  //           return value === "" || value === 0; // Adjust based on your default value logic
  //         }
  //         return false;
  //       });
  
  //       if (isEmptyField) {
  //         // Highlight the last row fields if any are empty
  //         const updatedData = prevData.map((row, index) => {
  //           if (index === prevData.length - 1) {
  //             return { ...row, highlight: true }; // Add the highlight flag
  //           }
  //           return row;
  //         });
  
  //         // Update the state with the highlighted row
  //         return updatedData;
  //       }
  //     }
  
  //     // If all fields are filled, add a new row
  //     return [
  //       ...prevData,
  //       { sales: "", qty: 0, grossAmount: 0, refunds: 0, discounts: 0, amount: 0, highlight: false },
  //     ];
  //   });
  // };
  const handleAddRow = () => {
    setsalesData((prevData) => {
      // Check if there are rows in the data
      if (prevData.length > 0) {
        // Get the last row (immediate previous row)
        const lastRow = prevData[prevData.length - 1];
  
        // Check if `sales` or `qty` fields are empty in the last row
        const isSalesEmpty = lastRow.sales === "";
        const isQtyEmpty = lastRow.qty === 0 || lastRow.qty === "";
  
        if (isSalesEmpty || isQtyEmpty) {
          // Highlight only the fields in the last row that are empty
          const updatedData = prevData.map((row, index) => {
            if (index === prevData.length - 1) {
              return {
                ...row,
                highlightSales: isSalesEmpty, // Highlight sales field
                highlightQty: isQtyEmpty,    // Highlight qty field
              };
            }
            return row;
          });
  
          // Update the state with the highlighted fields
          return updatedData;
        }
      }
  
      // If all required fields are filled, add a new row
      return [
        ...prevData,
        {
          sales: "",
          qty: 0,
          grossAmount: 0,
          refunds: 0,
          discounts: 0,
          amount: 0,
          highlightSales: false,
          highlightQty: false,
        },
      ];
    });
  };
  
  
  const handleRemoveRow = (index) => {
    const updatedData = salesData.filter((_, i) => i !== index);
    setsalesData(updatedData);
  };

  return (
    <>
    <Toaster />
    <div className="p-6 bg-blue-50 totalsalesArea ">
      <h2 className="text-[32px] font-medium text-left text-[#0b1f47] mb-4">
        Total sales
      </h2>
      {type !== "dashboard" ?
        <div className="eodBackground w-full rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
          {/* Header */}
          <div className="flex gap-[20px]  text-sm rounded-t-lg pt-[20px] pb-[13px] px-[20px]">
            <div className="flex-none w-[16%]  text-[18px] font-medium text-[#0b1f47]">Sales</div>
            <div className="flex-none w-[14%] text-left  text-[18px] font-medium text-[#0b1f47]">Qty</div>
            <div className="flex-none w-[14%] text-left  text-[18px] font-medium text-[#0b1f47]">Gross Amount</div>
            <div className="flex-none w-[14%] text-left  text-[18px] font-medium text-[#0b1f47]">Refunds</div>
            <div className="flex-none w-[14%] text-left  text-[18px] font-medium text-[#0b1f47]">Discounts</div>
            <div className="flex-none w-[14%] text-left  text-[18px] font-medium text-[#0b1f47]">Amount</div>
            <div className="flex-none w-[3%]"></div> {/* For Trash Icon */}
          </div>
          {/* Rows */}
          {
          salesData.map((item, index) => (
            <div key={index} className="flex gap-[20px] text-sm items-center bg-white pb-[13px] px-[20px]">
              <div className="flex-none w-[16%] ">
                <input
                  type="text"
                  placeholder="Add sale type"
                  // className="w-full  border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full border ${
                  //   item.highlight && !item.sales ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  className={`w-full border rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff] ${
                    item.highlightSales ? "border-red-500" : "border-gray-300"
                  }`}
                  value={item.sales}
                  onChange={(e) => handleInputChange(index, "sales", e.target.value)}
                  readOnly={getReadOnlyStatus()}
                />
              </div>

              <div className="flex-none w-[14%] relative">

                <input
                  type="number"
                  placeholder="0.00"
                  // className="w-full  border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full pl-5 border ${
                  //   item.highlight && !item.qty ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  className={`w-full border rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff] ${
                    item.highlightQty ? "border-red-500" : "border-gray-300"
                  }`}
                  value={item.qty || ''} // Ensure netSales is a number
                  name="qty"
                  onChange={(e) => handleInputChange(index, "qty", e.target.value)}
                  // onKeyDown={preventInvalidKeyPress}
                   onKeyDown={(e) => {
                                preventPlusSign(e); // Prevent plus sign key
                                preventInvalidKeyPress(e); // Other invalid key press prevention logic
                              }}
                  step="0.01"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  readOnly={getReadOnlyStatus()}
                />
              </div>

              <div className="flex-none w-[14%] relative">
                <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full pl-5 border ${
                  //   item.highlight && !item.grossAmount ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  value={item.grossAmount || ''} // Ensure netSales is a number
                  name="grossAmount"
                  onChange={(e) => handleInputChange(index, "grossAmount", e.target.value)}
                  onKeyDown={(e) => {
                    preventPlusSign(e); // Prevent plus sign key
                    preventInvalidKeyPress(e); // Other invalid key press prevention logic
                  }}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  readOnly={getReadOnlyStatus()}
                  min="0"
                />
              </div>

              <div className="flex-none w-[14%] relative">
                <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full pl-5 border ${
                  //   item.highlight && !item.refunds ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  value={item.refunds || ''} // Ensure netSales is a number
                  name="refunds"
                  onChange={(e) => handleInputChange(index, "refunds", e.target.value)}
                  onKeyDown={(e) => {
                    preventPlusSign(e); // Prevent plus sign key
                    preventInvalidKeyPress(e); // Other invalid key press prevention logic
                  }}
                  step="0.01"
                  readOnly={getReadOnlyStatus()}
                  onWheel={(e) => e.target.blur()}
                  min="0"
                />
              </div>

              <div className="flex-none w-[14%] relative">
                <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full pl-5 border ${
                  //   item.highlight && !item.discounts ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  value={item.discounts || ''} // Ensure netSales is a number
                  name="discounts"
                  onChange={(e) => handleInputChange(index, "discounts", e.target.value)}
                  onKeyDown={(e) => {
                    preventPlusSign(e); // Prevent plus sign key
                    preventInvalidKeyPress(e); // Other invalid key press prevention logic
                  }}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  readOnly={getReadOnlyStatus()}
                  min="0"
                />
              </div>

              <div className="flex-none w-[14%] relative">
                <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] text-[#5b6477] bg-[#f1f6fa] focus:bg-[#fff]"
                  // className={`w-full pl-5 border ${
                  //   item.highlight && !item.amount ? "border-red-500" : "border-b-[rgb(209_213_219_/_30%)]"
                  // } rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]`}
                  value={item.amount || ''} // Ensure netSales is a number
                  name="amount"
                  onChange={(e) => handleInputChange(index, "amount", e.target.value)}
                  onKeyDown={(e) => {
                    preventPlusSign(e); // Prevent plus sign key
                    preventInvalidKeyPress(e); // Other invalid key press prevention logic
                  }}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  readOnly={getReadOnlyStatus()}
                  min="0"
                />
              </div>
              { !getReadOnlyStatus() &&
              <div className="flex-none w-[3%] text-left">
                {index > 0 && (
                  <button
                    onClick={() => handleRemoveRow(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FiTrash2 size={16} />
                  </button>
                )}
              </div>
              }

            </div>
          ))
          }
          
          {/* Add Field Button */}
          { !getReadOnlyStatus() &&
            <div className="flex gap-[20px] text-sm font-semibold items-center bg-white pb-[13.5px] px-[20px]">
              <button onClick={handleAddRow} className="text-[#0b1f47] font-normal hover:text-[#0079d1] flex-1 text-left">
                Add field +
              </button>
            </div>
          }

          <div className={`flex gap-[20px] text-sm items-center bg-white rounded-b-lg pb-[35px] px-[20px] ${type === "dashboard" ? "border-t border-t-[#0b1f47]" : ""
            }`}>
            <div className={`flex-none text-right pr-[10px] w-[16%] font-semibold text-[#0b1f47]  ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>Total</div>
            <div className={`flex-none w-[14%] text-left pl-[10px] text-[#0b1f47] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>
              {salesData.reduce((sum, item) => sum + (parseFloat(item.qty) || 0), 0)}
            </div>
            <div className={`flex-none w-[14%] text-left  pl-[10px] text-[#0b1f47] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>
              $
              {salesData
                .reduce((sum, item) => sum + (parseFloat(item.grossAmount) || 0), 0)
                .toFixed(2)}
            </div>
            <div className={`flex-none w-[14%] text-left  pl-[10px] text-[#0b1f47] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>
              $
              {salesData.reduce((sum, item) => sum + (parseFloat(item.refunds) || 0), 0).toFixed(2)}
            </div>
            <div className={`flex-none w-[14%] text-left text-red-500  pl-[10px] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>
              $
              {salesData
                .reduce((sum, item) => sum + (parseFloat(item.discounts) || 0), 0)
                .toFixed(2)}
            </div>
            <div className={`flex-none w-[14%] text-left  pl-[10px] text-[#0b1f47] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]"
              }`}>
              $
              {salesData
                .reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0)
                .toFixed(2)}
            </div>
            <div className="flex-none w-[3%]"></div> {/* Empty space for alignment */}
          </div>

        </div>

        :
         
        <div className="eodBackground w-full rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
          {/* Header Row */}

          <div className="flex gap-[20px]  text-sm rounded-t-lg py-[13px] px-[20px]">
            <div className="flex-1 text-left  text-[18px] font-medium text-[#0b1f47]">Sales</div>
            <div className="flex-1  text-center  text-[18px] font-medium text-[#0b1f47]">Qty</div>
            <div className="flex-1  text-center  text-[18px] font-medium text-[#0b1f47]">Gross Amount</div>
            <div className="flex-1 text-center  text-[18px] font-medium text-[#0b1f47]">Refunds</div>
            <div className="flex-1  text-center  text-[18px] font-medium text-[#0b1f47]">Discounts</div>
            <div className="flex-1  text-right  text-[18px] font-medium text-[#0b1f47]">Amount</div>
            
          </div>

          {/* Data Rows */}
          {salesData.map((item) => (
            <div key={item.id} className="flex  gap-[20px] border-gray-200 py-[13px] px-[20px]">
              <div className="flex-1 text-[16px] font-normal  text-[#0b1f47] text-left">{item.sales}</div>
              <div className="flex-1 text-[16px] font-normal  text-[#0b1f47] text-center">{Number(item.qty || 0).toFixed(2)}</div>
              <div className="flex-1  text-[16px] font-normal  text-[#0b1f47] text-center">{`$${Number(item.gross || 0).toFixed(2)}`}</div>
              <div className="flex-1 text-[16px] font-normal  text-[#0b1f47] text-center">{`$${Number(item.refunds|| 0).toFixed(2)}`}</div>
              <div className="flex-1 text-[16px] font-normal  text-[#0b1f47] text-center">{`$${Number(item.discounts|| 0).toFixed(2)}`}</div>
              <div className="flex-1  text-[16px] font-normal  text-[#0b1f47] text-right">{`$${Number(item.amount|| 0).toFixed(2)}`}</div>
            </div>
          ))}
          {/* Total Row */}
          <div className="flex gap-[20px] border-gray-300 border-t border-t-black font-semibold bg-white pt-[5px] mx-[20px] pb-[32px]">
            <div className="flex-1 w-[16%] text-[18px] text-[#0b1f47] text-right font-medium">Total</div>
            <div className="flex-1 w-[14%] text-[18px] font-normal  text-[#0b1f47] text-center"> {salesData.reduce((sum, item) => sum + (parseFloat(item.qty) || 0), 0).toFixed(2)}</div>
            <div className="flex-1 w-[14%] text-[18px] font-normal  text-[#0b1f47] text-center">
            ${salesData
                .reduce((sum, item) => sum + (parseFloat(item.grossAmount) || 0), 0)
                .toFixed(2)}
              
              </div>
            <div className="flex-1 w-[14%]  text-[18px] font-normal   text-red-500 text-center">
            ${salesData.reduce((sum, item) => sum + (parseFloat(item.refunds) || 0), 0).toFixed(2)}
            </div>
            <div className="flex-1 w-[14%]  text-[18px] font-normal  text-[#0b1f47] text-center">
            ${salesData
                .reduce((sum, item) => sum + (parseFloat(item.discounts) || 0), 0)
                .toFixed(2)}
              
              </div>
            <div className="flex-1 w-[14%] text-[18px] font-normal  text-[#0b1f47] text-right"> 
              ${salesData
                .reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0)
                .toFixed(2)}</div>
          </div>
        </div>

      }
    </div>
    </>
  );
};

export default TotalSalesTable;
