
import { useClickAway } from '@uidotdev/usehooks';
import React, { useState, useEffect } from 'react';
import { MdAttachFile, MdOutlineCancel } from "react-icons/md";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const venues = [
    'Byblos Downtown',
    'Byblos Uptown',
    'Chica',
    'Patria',
    'Pink Sky',
    'Storys'
];

const SelectVenueAndDate = () => {
    const [isOpenVenue, setIsOpenVenue] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState('Select venue');
    const [searchVenue, setSearchVenue] = useState('');

    const [mDate, setmDate] = useState(moment().toDate());
    const years = Array.from({ length: 100 }, (_, i) => 1923 + i); // Example: 1923 to 2023
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const ref = useClickAway(() => {
        setIsOpenVenue(false);
    })
    const handleDropdownToggleVenue = () => {
        setIsOpenVenue(!isOpenVenue);
    };

    const handleSelectVenue = (venue) => {
        setSelectedVenue(venue);
        setSearchVenue(''); // Clear search field when selecting a venue
        setIsOpenVenue(false);
    };

    const handleSearchVenueChange = (event) => {
        setSearchVenue(event.target.value);
    };

    // Filter venues based on the search query
    const filteredVenues = venues.filter(venue =>
        venue.toLowerCase().includes(searchVenue.toLowerCase())
    );


    return (
        <div className='reportingHead pl-5 pb-3 w-[calc(100%-104px)]'>
            <div className='reportingNotes reportingDateCon'>
                <p className='reportLabel'>Date<span className='requiredLabel'>*</span></p>
                <DatePicker
                    className="maintenanceDate text-[#5a6783] text-sm py-2 px-3 rounded-md"
                    dateFormat="MMMM dd, yyyy"
                    placeholderText="Enter a date"
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className="CalendarDiv">
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <div>
                                <select
                                    value={moment(date).year()}
                                    onChange={({ target: { value } }) => changeYear(value)}>
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    value={months[moment(date).month()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    )}
                    selected={mDate ? moment(mDate).toDate() : null}
                    onChange={setmDate}
                    onClick={e => e.preventDefault()}
                />

            </div>
            <div className='reportingNotes'>
                <p className='reportLabel'>
                    Venue Eg: Patria *<span className='requiredLabel'>*</span>
                </p>
                <div className='mainHeaderContainer'>
                    <div
                        className='mainHeader'
                        style={{ borderRadius: '4px' }}
                        onClick={handleDropdownToggleVenue}
                    >
                        {selectedVenue}
                        <i className={`fa fa-chevron-right custom_icon ${isOpenVenue ? 'open' : ''}`}></i>
                    </div>
                    {isOpenVenue && (
                        <div className='custom_dropdown-body_task inReport open !top-1' ref={ref}>
                            <div className="searchareaDiv relative">
                                <input
                                    className="_inputBar searchLeft popupserach"
                                    type="text"
                                    placeholder="Search"
                                    value={searchVenue}
                                    onChange={handleSearchVenueChange}
                                    autoFocus
                                />
                                {searchVenue.length > 0 && (
                                    <MdOutlineCancel
                                        onClick={() => setSearchVenue('')}
                                        className='absolute text-red-600 text-xl right-2 top-2'
                                    />
                                )}
                            </div>
                            {filteredVenues.length > 0 ? (
                                filteredVenues.map((venue, index) => (
                                    <div
                                        key={index}
                                        className='custom_dropdown-item'
                                        onClick={() => handleSelectVenue(venue)}
                                    >
                                        {venue}
                                    </div>
                                ))
                            ) : (
                                <div className="custom_dropdown-item">
                                    <p className='text-center py-6'> No venues found</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SelectVenueAndDate;