import { useEffect } from 'react';

const usePolling = (callback, interval) => {
    useEffect(() => {
        const poll = setInterval(() => {
            callback();
        }, interval);

        return () => clearInterval(poll); // Cleanup on unmount
    }, [callback, interval]);
};

export default usePolling;