import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

const CreateCash = ({type, cashData }) => { 
  // console.log('cashData',cashData)
  
  // const cash_data = [
  //   { type: "Cash Payments", qty: 0, amount: cashData?.amount || 0 },
  //   { type: "Non-Cash Tips", qty: 0, amount: cashData?.amount || 0 },
  // ];
  
  const calculateTotal = (key) => cashData.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);

  return (
    <div className="p-6 bg-blue-50 rounded-lg eodBackground">
      <h2 className="text-[#0b1f47] text-[32px]  mb-4">Cash</h2>
      <div className="rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
        {/* Table Header */}
        <div className="flex gap-[20px] text-sm pt-[33px] pb-[13px] px-[20px]">
          <div className="w-[50%] text-[18px] font-medium text-left text-[#0b1f47]">Type</div>
          {/* <div className="w-[25%] text-[18px] text-left text-[#0b1f47]">Qnty</div> */}
          <div className="w-[50%] text-[18px] font-medium text-left text-[#0b1f47]">Amount</div>
         
        </div>

        {/* Table Rows */}
        {cashData.map((tax, index) => (
          <div
            key={index}
            className="flex gap-[20px] items-center bg-white pb-[13px] px-[20px]  "
          >
            <div className="w-[50%]  text-[14px] text-left text-[#0b1f47]">{tax.type}</div>
              {/* Quantity */}
              {/* <div className="w-[25%] text-[14px] text-left text-[#0b1f47]">{tax.qty}</div> */}
              {/* Amount */}
            <div className="w-[50%] text-[14px] text-left text-[#0b1f47]">
                {tax.amount < 0 ? (
                  <span className="text-red">${tax.amount}</span>
                ) : (
                  <span>${tax.amount}</span>
                )}
            </div>
          </div>
        ))}
    

        {/* Total Row */}
        <div className={`flex gap-[20px] text-sm  pb-[33px] mx-[20px]  ${type === "dashboard" ?  "border-t border-t-[#0b1f47]" :""  }`}>
          <div className={`w-[50%] text-[18px] text-right text-[#0b1f47] font-medium ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]" }`}>Total</div>
          {/* <div className={`w-[25%] text-left  text-[18px] text-[#0b1f47] ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]" }`}>{calculateTotal("qty")}</div> */}
          <div className={`w-[50%] text-left text-[18px] text-[#0b1f47]  ${type === "dashboard" ? "" : "border-t border-t-[#0b1f47]" }`}>
            ${calculateTotal("amount").toFixed(2)}
          </div>
         
        </div>

       
      </div>
    </div>
  );
};

export default CreateCash;
