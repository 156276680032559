import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    task_search_val: '',
    active_discussion: [],
    change_file_data: null,
    new_file: null,
    delete_file: null,
    new_task: null,
    deleted_task: null,
    active_task: null,
    active_task_filter: [],
    new_checklist: null,
    all_projects: [],
    all_tasks: [],
    project_ids: ['all']
}

export const task = createSlice({
    name: 'task',
    initialState,
    reducers: {
        set_user: (state, action) => {
            return ({
                ...state,
                user: { ...action.payload }
            })
        },
        set_task_search_val: (state, action) => {
            return ({
                ...state,
                task_search_val: action.payload
            })
        },
        set_active_task: (state, action) => {
            return {
                ...state,
                active_task: action.payload,
            }
        },
        save_update_checklist: (state, action) => {
            const { _doc } = action.payload;

            // Validate conditions for updating active_task
            const isMatchingTask = _doc.task_id === state.active_task?._id;
            const isChecklistExists = state.active_task?.checklists.some((c) => c._id === _doc._id);

            // If all conditions are met, update the active_task
            if (isMatchingTask) {
                return {
                    ...state,
                    active_task: {
                        ...state.active_task,
                        checklists: isChecklistExists ? [...state.active_task.checklists.map(c => c._id === _doc._id ? { ..._doc } : c)] : [...state.active_task.checklists, _doc],
                    },
                    new_checklist: _doc,
                };
            }

            // Otherwise, return the unchanged state
            return state;
        },

        set_new_task: (state, action) => {
            const { API_SERVER_URL, CLIENT_BASE_URL, xmpp_type, xmpp_unix, ...actual_task } = action.payload;
            return {
                ...state,
                new_task: actual_task,
            }
        },
        set_deleted_task: (state, action) => {
            return {
                ...state,
                deleted_task: action.payload,
            }
        },
        set_active_task_filter: (state, action) => {
            return {
                ...state,
                active_task_filter: action.payload,
            }
        },
        set_all_projects: (state, action) => {
            return {
                ...state,
                all_projects: [...action.payload],
            }
        },
        set_all_tasks: (state, action) => {
            return {
                ...state,
                all_tasks: [...action.payload],
            }
        },
        set_project_ids: (state, action) => {
            return {
                ...state,
                project_ids: [...action.payload],
            }
        },
        set_active_discussion: (state, action) => {
            return {
                ...state,
                active_discussion: [...action.payload],
            }
        },
        update_active_discussion: (state, action) => {
            return {
                ...state,
                active_discussion: state.active_task?._id === action.payload?.task_id ? [...state.active_discussion, action.payload] : [...state.active_discussion],
                new_file: action.payload.all_attachment.length > 0 ? action.payload.all_attachment : null
            }
        },
        update_active_discussion_msg: (state, action) => {
            const { conversation_id, msg_id, fields } = action.payload;
            state.active_discussion = state.active_discussion.map(msg =>
                msg.conversation_id === conversation_id && msg.msg_id === msg_id ? { ...msg, ...fields } : msg
            );
        },
        updateDiscussionMsgTag: (state, action) => {
            const { payload } = action;
            const {
                msg_id,
                file_id,
                removetag,
                newtag,
                newtag_tag_data,
            } = payload;

            const updateAttachments = (attachments) =>
                attachments.map((f) =>
                    f.id === file_id
                        ? {
                            ...f,
                            tag_list_details: [
                                ...(f.tag_list_details || []).filter((t) => !removetag.includes(t.tag_id)),
                                ...newtag_tag_data.filter((t) => !f.tag_list.includes(t.tag_id)),
                            ],
                            tag_list: [
                                ...f.tag_list.filter((t) => !removetag.includes(t)),
                                ...newtag.filter((t) => !f.tag_list.includes(t)),
                            ],
                        }
                        : f
                );

            const updateMessages = (messages) =>
                messages.map((v) =>
                    v.msg_id === msg_id
                        ? {
                            ...v,
                            all_attachment: updateAttachments(v.all_attachment),
                        }
                        : v
                );

            // if (state.active_conversation) {
            // if (conversation_id === state.active_conversation.conversation_id) {
            return {
                ...state,
                active_discussion: updateMessages(state.active_discussion),
                change_file_data: payload,
            };
            // }
            // }

            // return {
            //     ...state,
            //     change_file_data: payload,
            // };
        },
        removeDiscussionAttachment: (state, action) => {
            return {
                ...state,
                active_discussion: state.active_discussion.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.filter(f => f.id !== action.payload.file_id) } : v),
                change_file_data: action.payload
            }
        },
        updateOneDiscussionMsg: (state, action) => {
            let data = [];
            let entry = false;

            const { field, msg_id, delete_type, msg_body, user_id } = action.payload;
            const active_msg = state.active_discussion.filter(f => f.msg_id === msg_id)

            // Determine the data and entry conditions based on the field
            switch (field) {
                case 'has_delete':
                    data = [state.user?.id];
                    if (active_msg.length > 0) {
                        entry = true;
                        if (delete_type === 'for_all') {
                            data = [...active_msg[0]?.participants, 'for_all'];
                        }
                    }
                    break;

                case 'has_emoji':
                    if (active_msg.length > 0) {
                        entry = true;
                        data = action.payload.has_emoji;
                    }
                    break;

                case 'has_flagged':
                case 'has_star':
                    if (active_msg.length > 0) {
                        entry = true;
                        data = action.payload.has_flagged === 'yes' ? [state.user?.id] : [];
                    }
                    break;

                case 'full_delete':
                    if (active_msg.length > 0) {
                        return {
                            ...state,
                            delete_file: delete_type === 'for_all' ? state.active_discussion.filter(msg => msg.msg_id === msg_id && msg.all_attachment.length > 0)?.[0]?.all_attachment?.[0] || null : null,
                            active_discussion: delete_type === 'for_all' ?
                                state.active_discussion.filter(msg => msg.msg_id !== msg_id)
                                :
                                state.active_discussion.map(msg => msg.msg_id === msg_id ? { ...msg, msg_body: 'You deleted this message', has_delete: [user_id] } : msg),
                        }
                    }
                    break;

                default:
                    break;
            }

            // Apply updates if entry is true
            if (entry) {
                const updateMessages = (messages) =>
                    messages.map(msg =>
                        msg.msg_id === msg_id
                            ? {
                                ...msg,
                                [field]: data,
                                msg_body: msg_body !== undefined ? msg_body : msg.msg_body,
                            }
                            : msg
                    );

                return {
                    ...state,
                    active_discussion: updateMessages(state.active_discussion)
                };
            } else {
                return {
                    ...state
                }
            }
        },
        updateDiscussionMsgStar: (state, action) => {
            return {
                ...state,
                active_discussion: state.active_discussion.map(v =>
                    v.msg_id === action.payload.msg_id ?
                        {
                            ...v,
                            all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f)
                        }
                        : v
                ),
                change_file_data: action.payload
            }
        },
    },
})

export const { set_user, task_search_val, set_new_task, set_task_search_val, set_active_discussion, set_project_ids, updateDiscussionMsgTag, removeDiscussionAttachment, updateOneDiscussionMsg, updateDiscussionMsgStar,
    update_active_discussion, update_active_discussion_msg, set_active_task, set_active_task_filter, set_all_projects, set_all_tasks, set_deleted_task, save_update_checklist } = task.actions
export default task.reducer