import React, { useState, useEffect } from 'react';
import { set_venue_contact_popup } from '../../../redux/message';
import { TEAMMATE_INVITE } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { MdAdd } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import { set_EOD_Contact_Email, set_maintanence_Contact_Email, set_New_Contact_user, set_contact_EOD_Contact_user, set_contact_Maintanence_Contact_user } from '../../../redux/adminSlice';


function AddNewContactPopup({ eod_maintanence_Contact_PopUp_Type }) {

    const [addContact, setaddContact] = useState('add-contact');
    const dispatch = useDispatch();

    const all_Recipients = useSelector(state => state.admin.allUser);
    const all_venue = useSelector(state => state.admin.all_venue);

    const [selected_recepient_user, setSelected_recepient_user] = useState([]);
    const [listed_recepient_user, setListed_recepient_user] = useState([]);



    useEffect(() => {
        if (eod_maintanence_Contact_PopUp_Type === "eod_conact") {
            const recipients_List = all_Recipients?.filter(data =>
                data.role === "Recipient" && all_venue.eod_contact_participants.includes(data.email)
            ) || [];
            setSelected_recepient_user(recipients_List);
    
            const List = (all_Recipients || []).filter(data => data.role === "Recipient" && !all_venue.eod_contact_participants.includes(data.email))
                .sort((a, b) => a.email.localeCompare(b.email));
            setListed_recepient_user(List);
        }
    
        if (eod_maintanence_Contact_PopUp_Type === "maintenance_conact") {
            const recipients_List = all_Recipients?.filter(data =>
                data.role === "Recipient" && all_venue.maintenance_contact_participants.includes(data.email)
            ) || [];
            setSelected_recepient_user(recipients_List);
    
            const List = (all_Recipients || []).filter(data => data.role === "Recipient" && !all_venue.maintenance_contact_participants.includes(data.email))
                .sort((a, b) => a.email.localeCompare(b.email));
            setListed_recepient_user(List);
        }    
    }, [eod_maintanence_Contact_PopUp_Type, all_Recipients, all_venue.eod_contact_participants, all_venue.maintenance_contact_participants]);



    const close_new_contactPopup = () => {
        if (addContact === 'add-new-contact') {
            setaddContact('add-contact');
        } else {
            dispatch(set_venue_contact_popup(false))
        }
    }

    const [contactUsers, setContactUsers] = useState({ firstname: '', lastname: '', email: '', phone: '', company: '' });
    const [contactErrors, setContactErrors] = useState({ firstname: '', lastname: '', email: '', phone: '', company: '' });


    const validateName = (name) => {
        return !validator.isEmpty(name) && !validator.isNumeric(name.charAt(0));
    };


    const onChangeHandle = (e, type) => {
        let value = type === 'phone' ? e : e.target.value;
        let errors = { ...contactErrors };

        switch (type) {
            case 'phone':
                if (validator.isNumeric(value) || value === '') {
                    errors.phone = '';
                } else {
                    errors.phone = 'Phone number is invalid.';
                }
                break;

            case 'email':
                if (validator.isEmail(value) || value === '') {
                    const emailss = all_Recipients?.filter(data => data.role === "Recipient").map(data => data.email) || [];
                    const specificEmail = emailss.find(email => email === value);
                    if (specificEmail) {
                        errors.email = "This email address has already been used.";
                    } else {
                        errors.email = '';
                    }
                } else {
                    errors.email = 'Invalid email address.';
                }
                break;

            case 'firstname':
            case 'lastname':
                if (validateName(value)) {
                    errors[type] = '';
                } else {
                    errors[type] = `${type === 'firstname' ? 'First' : 'Last'} name is invalid.`;
                }
                break;

            case 'company':
                errors.company = ''; // No specific validation for Company name.
                break;

            default:
                break;
        }

        setContactUsers({ ...contactUsers, [type]: value });
        setContactErrors(errors);
    };


    const iskeyDown = (event) => {
        const invalidChars = /[^a-zA-Z0-9\s]/;
        if (invalidChars.test(event.key)) {
            event.preventDefault();
        }
    };


    const [info, setInfo] = useState('');
    const URL = "https://ipinfo.io/json";

    useEffect(() => {
        fetch(URL)
            .then((response) => response.json())
            .then((data) => {
                setInfo(data.country.toLowerCase()); // Adjust this according to the data structure of ipinfo.io response
            })
            .catch((error) => {
                console.error('Error fetching geolocation data:', error);
            });
    }, []);


    const add_Recipeint_User = (user) => {
        setSelected_recepient_user((prev) => [...prev, user]);
        setListed_recepient_user((prev) => prev.filter((item) => item.email !== user.email));
    }


    const close_Recipeint_User = (user) => {
        setSelected_recepient_user((prev) => prev.filter((item) => item.email !== user.email));
        setListed_recepient_user((prev) => {
            const updatedList = [...prev, user];
            return updatedList.sort((a, b) => a.email.localeCompare(b.email));
        });
    }

    const [newuser, setNewuser] = useState([]);
    const submit_Recipeint = () => {
        if (eod_maintanence_Contact_PopUp_Type === "eod_conact") {

            const recipientsEmail = selected_recepient_user.map(data => data.email);
            const matched = recipientsEmail.filter(data => all_venue.recipients.includes(data));
            dispatch(set_EOD_Contact_Email(matched));
            dispatch(set_contact_EOD_Contact_user(recipientsEmail));
            dispatch(set_New_Contact_user(newuser));
            dispatch(set_venue_contact_popup(false));
        }

        if (eod_maintanence_Contact_PopUp_Type === "maintenance_conact") {
            const recipientsEmail = selected_recepient_user.map(data => data.email);
            const matched = recipientsEmail.filter(data => all_venue.maintenance_recipients.includes(data));
            dispatch(set_maintanence_Contact_Email(matched));
            dispatch(set_contact_Maintanence_Contact_user(recipientsEmail));
            dispatch(set_New_Contact_user(newuser));
            dispatch(set_venue_contact_popup(false));
        }
    }


    // Contnact user search function
    const [searchQuery, setSearchQuery] = useState("");

    const listed_recepientUser = listed_recepient_user.filter((item) =>
        item.firstname.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const [inviteUser] = useMutation(TEAMMATE_INVITE);

    const add_new_contactsubmit = async () => {

        const recepientse = {
            firstname: contactUsers.firstname,
            lastname: contactUsers.lastname,
            emails: contactUsers.email,
            role: "Recipient",
            phone: contactUsers.phone,
            company: contactUsers.company
        };

        try {
            // console.log(197, recepientse);

            let response = await inviteUser({ variables: { input: recepientse } });

            if (!response || !response.data || !response.data.teammate_invite.status) {
                console.log("Unexpected response:11", response);
                return;
            }

            if (response.data.teammate_invite.status) {
                if (response.data.teammate_invite.data && response.data.teammate_invite.data[0]) {
                    add_Recipeint_User(response.data.teammate_invite.data[0]);
                    // setNewuser(response.data.teammate_invite.data[0]);
                    setNewuser((prevData) => [...prevData, response.data.teammate_invite.data[0]]);
                } else {
                    console.log("No data found in the teammate_invite response.");
                }
                setaddContact('add-contact');
            } else {
                console.log("Invitation failed. Please try again.");
            }
        } catch (error) {
            console.log("Error inviting user:", error);
        }
    };



    return (
        <div className='backwrap gustInviteWrap'>
            <div className='tagModal gustInviteModal'>
                <div className="tagModalHead">

                    {addContact === 'add-contact' &&
                        <h4 className="popupTitle"> Add contact(s) to this venue</h4>
                    }

                    {addContact === 'add-new-contact' &&
                        <h4 className="popupTitle"> Add a new contact to this venue</h4>
                    }

                    <span onClick={close_new_contactPopup} className="closeModal"></span>

                </div>
                <div className="tagModalBody !pb-0 !pr-0">
                    {addContact === 'add-contact' &&
                        <div className="participantsModal !w-[600px]" >
                            <div className="searchParticipants !pr-[30px] !pt-[10px] !pb-[10px]">

                                <span style={{ width: "calc(100% - 130px)", position: "relative", float: "left" }}>
                                    <input type="text" className="participantsSearch"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Search user" defaultValue="" style={{ width: "100%" }} autoFocus={true} />
                                    <span className="leftSearchCloseBtn remove top-[14px] right-[10px]" onClick={() => setSearchQuery("")} />
                                </span>

                                <button className="paricipantsSubmit active !border-0 !bg-[#0D1E45] hover:!bg-[#0069d9]" onClick={() => submit_Recipeint()}>Add member</button>

                                <div className="selectedParticipants">
                                    {selected_recepient_user.map((recipients, index) => (
                                        <div className="miniItem" key={recipients.id}>
                                            <div className="miniItem_image">
                                                <span className="nameLetters" />
                                            </div>
                                            <div className="miniItem_name">{recipients.firstname} {recipients.lastname}</div>
                                            <div className="miniItem_remove" onClick={() => close_Recipeint_User(recipients)} />
                                        </div>
                                    ))}
                                </div>


                                <div className="participantsContainer">
                                    {listed_recepientUser.map((list_recipients, index) => (
                                        <div className="participantItem active" key={list_recipients.id} onClick={() => add_Recipeint_User(list_recipients)}>
                                            <div className="participantImage">
                                                <span className="nameLetters" />
                                            </div>
                                            <p className="participantTitle">{list_recipients.firstname}</p>
                                            <p className="participantEmail" />
                                            <p className="emailLabel">{list_recipients.email}</p>
                                            <p />
                                        </div>
                                    ))}
                                </div>


                                <div
                                    className="addNewLabel"
                                    data-for="addaNewContact"
                                    data-tip="Click to add a new contact"
                                    currentitem="false"
                                    onClick={() => setaddContact('add-new-contact')}>
                                    <span className='addIconSpan'>
                                        <MdAdd size={16} />
                                    </span>
                                    <span className='addLabelText'>Add a new contact</span>
                                </div>


                            </div>

                        </div>
                    }

                    {addContact === 'add-new-contact' &&
                        <div className="createUserFormContainer">
                            <form className="createUserForm">

                                {/* First Name */}
                                <div className="cu_doubleInputGroup pr-[35px] mt-2">
                                    <div className="inputGroup">
                                        <label className="inputLabel">First name<span className="red_star">*</span></label>
                                        <input
                                            type="text"
                                            className={`inputField ${contactErrors.firstname ? 'error' : ''}`}
                                            placeholder="First name"
                                            onChange={(e) => onChangeHandle(e, 'firstname')}
                                            onKeyPress={(e) => iskeyDown(e)}
                                            value={contactUsers.firstname}
                                            autoFocus={true} />
                                        <span className="errorLabel !relative">{contactErrors.firstname}</span>
                                    </div>

                                    {/* Last Name */}
                                    <div className="inputGroup">
                                        <label className="inputLabel">Last name<span className="red_star">*</span></label>
                                        <input
                                            type="text"
                                            className={`inputField ${contactErrors.lastname ? 'error' : ''}`}
                                            placeholder="Last name"
                                            onChange={(e) => onChangeHandle(e, 'lastname')}
                                            onKeyPress={(e) => iskeyDown(e)}
                                            value={contactUsers.lastname}
                                        />
                                        <span className="errorLabel !relative">{contactErrors.lastname}</span>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="inputGroup">
                                    <label className="inputLabel">Email<span className="red_star">*</span></label>
                                    <input
                                        type="email"
                                        className={`inputField ${contactErrors.email ? 'error' : ''}`}
                                        placeholder="Enter email address"
                                        onChange={(e) => onChangeHandle(e, 'email')}
                                        value={contactUsers.email}
                                    />
                                    <span className="errorLabel !relative">{contactErrors.email}</span>
                                </div>

                                {/* Phone Number */}
                                <div className="inputGroup">
                                    <label className="inputLabel">Phone number</label>
                                    <PhoneInput
                                        name="phone"
                                        inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                                        country={info}
                                        value={contactUsers.phone}
                                        onChange={(value) => onChangeHandle(value, 'phone')}
                                        placeholder="(000) 000-0000"
                                        className={`inputField ${contactErrors.phone ? 'error' : ''}`}
                                    />
                                    <span className="errorLabel !relative">{contactErrors.phone}</span>
                                </div>

                                {/* Company name */}
                                <div className="inputGroup">
                                    <label className="inputLabel">Company name</label>
                                    <input
                                        type="text"
                                        className="inputField"
                                        placeholder="Enter Company name"
                                        onChange={(e) => onChangeHandle(e, 'company')}
                                        value={contactUsers.company}
                                    />
                                </div>
                            </form>

                            <div className="createUserModalFoot">
                                <button className="btnCancel"
                                    onClick={close_new_contactPopup}
                                >Cancel</button>
                                <button
                                    onClick={() => add_new_contactsubmit()}
                                    className={`btnAction ${contactErrors.firstname || contactErrors.lastname || contactErrors.email || !contactUsers.firstname || !contactUsers.lastname || !contactUsers.email
                                        ? "disabled"
                                        : ""
                                        }`}
                                    disabled={contactErrors.firstname || contactErrors.lastname || contactErrors.email || !contactUsers.firstname || !contactUsers.lastname || !contactUsers.email} >
                                    Add contact
                                </button>
                            </div>

                        </div>
                    }

                </div>
            </div>
        </div>
    )
}


export default AddNewContactPopup