/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SWITCH_ACCOUNT_MUTATION } from '../../Utils/GraphqlMutaion';
import { GET_ALL_COMPANIES, GET_ME } from '../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { getCookie } from '../../Utils/Common';

// Define a component for the account selector
const AccountSelector = ({ company_img, company_name, company_id, switchTeam, active_company, role}) => (
  <div className="accountSelector" onClick={(e) => switchTeam(company_id)}>
    <div className={`acountLogo ${active_company === company_id ? '!border-[2px] !border-red-600' : ''}`}>
      <img src={company_img} alt="user" title='user' />
    </div>
    <div className={`acountName ${active_company === company_id ? 'text-red-600' : ''}`}>{company_name}</div>
    <div className={`acountDesignation ${active_company === company_id ? 'text-red-600' : ''}`}>{role}</div>
  </div>
);

// Define the main component for the multi-company switcher
const MultiCompanySwitcher = () => {
  const client = useApolloClient();
  const [loader, setLoader] = useState(false);
  const cacheMe = client.readQuery({ query: GET_ME });
  const user = cacheMe.me;

  const { loading: companiesLoading, data: companiesData } = useQuery(GET_ALL_COMPANIES, { variables: { email: user.email }, fetchPolicy: 'no-cache' });

  const [update] = useMutation(SWITCH_ACCOUNT_MUTATION);

  const switchTeam = async (company_id) => {
    setLoader(true)
    try {
      const { data: switchData } = await update({
        variables: {
          input: {
            email: user.email,
            company_id: company_id,
            device_id: getCookie('device_id')
          }
        }
      });

      const { refresh_token, token, message } = switchData.switch_account;
      if (message) {
        toast.success(message);
      }
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      window.location.href = '/'
    } catch (error) {
      // Handle error if needed
    }
    finally {
      setLoader(false)
    }
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        navigate(-1)
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  console.log("companiesData", GET_ALL_COMPANIES);

  return (
    <>
      {
        companiesLoading === true || loader ? <div className="switchLoaderMain"></div> :
          <div className="multiCompany createGroupConv userUpdatePopup">
            <div className="createConv_head" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
              <span onClick={handleGoBack} data-tip="Back" className="createConv_back profileBack" data-for="multiCompany_tooltip"></span>
              <p className="profileTitle" style={{ top: '22px' }}><span className="userProfileName">Switch Account ({companiesData && companiesData.companies.length})</span></p>
              <span onClick={handleGoBack} data-tip="Close" className="createConv_close profileClose" data-for="multiCompany_tooltip"></span>
            </div>
            <div className="otherAccountContainer">
              <div className="accountScroller">
                {companiesData && companiesData.companies.map((company, index) => (
                  <AccountSelector
                    key={index}
                    company_id={company.company_id}
                    company_name={company.company_name}
                    company_img={company.company_img}
                    switchTeam={switchTeam}
                    active_company={user.company_id}
                    role={company.role}
                  />
                ))}
              </div>
            </div>
          </div>
      }

    </>
  );
};

export default MultiCompanySwitcher;
