import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setMergeMsg } from '../../../redux/message';
import { MERGE_COMPANY } from '../../../Utils/GraphqlMutaion';
import { FIND_OWNED_COMPANY, GET_ME } from '../../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MdCheck } from "react-icons/md";
import MergeWarning from './MergeWarning';

const AccountSelector = ({ company_img, company_name, company_id, active_company, setShowWarning }) => {
    return (
        <div className="accountSelector" onClick={() => setShowWarning(company_id, company_name)}>
            <div className={`acountLogo ${active_company === company_id ? '!border-[2px] !border-red-600' : ''}`}>
                <img src={company_img} alt="user" title='user' />
            </div>
            <div className={`acountName ${active_company === company_id ? 'text-red-600' : ''}`}>{company_name}</div>
        </div>
    );
};

const MergeCompany = () => {
    const [showWarning, setShowWarning] = useState(false);
    const [companyNameForModal, setCompanyNameForModal] = useState('');
    const [companyIdForModal, setCompanyIdForModal] = useState('');
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [messageArray, setMessageArray] = useState([]);
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const merge_msg = useSelector(state => state.message.merge_msg);

    useEffect(() => {
        if (merge_msg.msg && !messageArray.includes(merge_msg.msg)) {
            setMessageArray(prevMessages => [...prevMessages, merge_msg.msg]);
        }
        if (merge_msg.msg === 'All Done') {
            dispatch(setMergeMsg({ msg: '...' }));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [merge_msg, messageArray, dispatch]);

    const { loading: companiesLoading, data: companiesData } = useQuery(FIND_OWNED_COMPANY, { variables: {}, fetchPolicy: 'no-cache' });

    const [update] = useMutation(MERGE_COMPANY);

    const switchTeam = async (company_id) => {
        setLoader(true);
        try {
            const { data: switchData } = await update({
                variables: {
                    company_id: company_id
                }
            });
            const { status, message, data } = switchData.merge_company;
            if (status) {
                dispatch(setMergeMsg({ msg: data.msg }));
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            setLoader(false);
        }
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleMergeConfirmation = (company_id, company_name) => {
        // console.log("company_id", company_id, company_name);
        setCompanyNameForModal(company_name);
        setCompanyIdForModal(company_id);
        setShowWarning(true);
    };

    const handleCancelMerge = () => {
        setShowWarning(false);
    };

    const handleMerge = (company_id) => {
        // Call your merge function here
        switchTeam(company_id);
        // console.log('Merge confirmed for:', company_id);
        setShowWarning(false); // Close the modal after merge confirmation
    };

    return (
        <>
            {companiesLoading === true || loader ?
                <div className="switchLoaderMain"></div> :
                <div className='multiCompany createGroupConv userUpdatePopup'>
                    <div className="createConv_head" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
                        <span data-tip="Back" onClick={handleGoBack} className="createConv_back profileBack" data-for="multiCompany_tooltip"></span>
                        <p className="profileTitle" style={{ top: '22px' }}>
                            <span className="userProfileName">Merge Company</span>
                        </p>
                        <span data-tip="Close" onClick={handleGoBack} className="createConv_close profileClose" data-for="multiCompany_tooltip"></span>
                    </div>
                    <div className='flex flex-wrap items-center gap-10 mt-8 ml-8'>
                        {companiesData && companiesData.find_owned_company.data.map((company, index) => (
                            <AccountSelector
                                key={index}
                                company_id={company.company_id}
                                company_name={company.company_name}
                                company_img='img.png'
                                switchTeam={switchTeam}
                                active_company={user.company_id}
                                setShowWarning={handleMergeConfirmation}
                                showWarning={showWarning}
                            />
                        ))}
                    </div>

                    <div className='merge_result ml-[52px]'>
                        {messageArray.map((message, index) => (
                            <div key={index} className='flex items-center gap-2 text-green-600'>
                                <MdCheck className='text-[22px]' />
                                <p>{message}</p>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {showWarning &&
                <MergeWarning
                    company_name={companyNameForModal}
                    onCancel={handleCancelMerge}
                    onMerge={handleMerge}
                    company_id={companyIdForModal}
                />
            }
        </>
    );
};

export default MergeCompany;
