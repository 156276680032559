import React, { useState, useEffect } from 'react';
import { ROLE_ADD } from '../../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";

function CreateEditRoles({ roles, company_id, setRoles, setCreatePopup, editRoles, setEditRoles }) {
    const [roleNameText, setRoleNameText] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [loader, setLoader] = useState(false);

    const [update] = useMutation(ROLE_ADD);

    useEffect(() => {
        if (editRoles) {
            setIsEditMode(true);
            setRoleNameText(editRoles.roleName);
        } else {
            setIsEditMode(false);
            setRoleNameText('');
        }
    }, [editRoles]);

    const handleCreateOrUpdateRole = async () => {
        if (isEditMode && editRoles) {
            // Update existing module
            // setRoles((prevRoles) =>
            //     prevRoles.map((role) =>
            //         role.id === editRoles.id
            //             ? { ...role, roleName: roleNameText }
            //             : role
            //     )
            // );
        } else {
            // Create new module
            setLoader(true);
            try {
                const { data: roleAddData } = await update({
                    variables: {company_id, role_title: roleNameText}
                });
                const { status, message, data } = roleAddData.role_add;
                if (status) {
                    // console.log(42, data)
                    setRoles((prevRole) => [...prevRole, data]);
                }
            } catch (error) {
                // Handle error if needed
            } finally {
                setLoader(false);
            }

            // const newRoles = {
            //     id: roles.length + 1,
            //     roleName: roleNameText,
            //     access: [], // Initialize with empty subModules
            //     createdDate: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
            //     createdBy: 'Admin', // Placeholder for created by user
            // };
            
        }

        setRoleNameText('');
        setCreatePopup(false);
        setEditRoles(null); // Reset edit module after operation
    };

    const handleInputChange = (event) => {
        setRoleNameText(event.target.value);
    };

    return (
        <>
            <div className="backwrap">
                <div className="createTeamModal">
                    <div className="createTeamModalHead">
                        <h4 className="popupTitle">
                            {isEditMode ? 'Edit Role' : 'Create Role'}
                        </h4>
                        <span className="closeModal" onClick={() => {
                            setCreatePopup(false);
                            setEditRoles(null); // Reset edit module when closing the popup
                        }}></span>
                    </div>
                    <div className="createTeamModalBody">
                        <div className="crTeam_inputGroup">
                            <label htmlFor="moduleName">Role Name</label>
                            <input
                                type="text"
                                id="moduleName"
                                maxLength="25"
                                placeholder="Role name"
                                value={roleNameText}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='createTeamModalFoot !px-0'>
                            <button
                                className={`${roleNameText ? 'btnAction' : 'btnAction opacity-50 pointer-events-none'}`}
                                onClick={handleCreateOrUpdateRole}>
                                {isEditMode ? 'Update Role' : 'Create Role'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateEditRoles;
