import DOMPurify from 'isomorphic-dompurify';

/**
 * Validates the input for blank values, invalid patterns, or disallowed characters.
 * @param {string} input - The input string to validate.
 * @param {boolean} isNumber - Whether the input should be a number (for number fields).
 * @returns {boolean} - True if the input is invalid, otherwise false.
 */
export function InputValidationCheck(input, isNumber = false) {
  if (!input) return true; // Handle null/undefined cases

  // If the input field type is a number, check that it contains only numbers
  if (isNumber && !isNumberInput(input)) {
    return true; // Invalid input for a number field
  }

  // Remove HTML tags
  const withoutHTML = input.replace(/<[^>]*>?/gm, '');

  // Sanitize the input
  const sanitizedInput = DOMPurify.sanitize(withoutHTML);

  // Remove non-breaking spaces
  const cleanedInput = sanitizedInput.replace(/(?:&nbsp;)/gm, '').trim();

  // Check if the string is blank
  if (!cleanedInput || /^\s*$/.test(cleanedInput)) return true;

  // Check if the first character is a number or special character
  if (/^[^a-zA-Z]/.test(cleanedInput)) return true;

  // Check for two consecutive special characters
  if (/[!@#$%^&*()_+{}\[\]:;"'<>,.?/\\\-]{2}/.test(cleanedInput)) return true;

  return false; // Input is valid
}
export function validationFirstChercterNuber(input, isNumber = false) {
  if (!input) return false; // Allow empty input

  // If the input field type is a number, check that it contains only numbers
  if (isNumber && !isNumberInput(input)) {
    return false; // Invalid input for a number field
  }

  // Remove HTML tags
  const withoutHTML = input.replace(/<[^>]*>?/gm, '');

  // Sanitize the input
  const sanitizedInput = DOMPurify.sanitize(withoutHTML);

  // Remove non-breaking spaces
  const cleanedInput = sanitizedInput.replace(/(?:&nbsp;)/gm, '').trim();

  // Check if the string is blank
  if (!cleanedInput || /^\s*$/.test(cleanedInput)) return false;

  // Check if the first character is a number or special character
  if (/^[^a-zA-Z0-9]/.test(cleanedInput)) return false;

  // Check for two consecutive special characters
  if (/[!@#$%^&*()_+{}[\]:;"'<>,.?/\\\-]{2}/.test(cleanedInput)) return false;

  return true; // Input is valid
}
export function validFirstChercterAlsoNuber(input, isNumber = false) {
  if (!input) return true; // Invalid if empty

  // If the input field type is a number, check that it contains only numbers
  if (isNumber && !isNumberInput(input)) {
    return true; // Invalid input for a number field
  }

  // Remove HTML tags
  const withoutHTML = input.replace(/<[^>]*>?/gm, '');

  // Sanitize the input
  const sanitizedInput = DOMPurify.sanitize(withoutHTML);

  // Remove non-breaking spaces
  const cleanedInput = sanitizedInput.replace(/(?:&nbsp;)/gm, '').trim();

  // Check if the string is blank
  if (!cleanedInput || /^\s*$/.test(cleanedInput)) return true;

  // Check if the first character is a number or special character
  if (/^[^a-zA-Z0-9]/.test(cleanedInput)) return true;

  // Check for two consecutive special characters
  if (/[!@#$%^&*()_+{}[\]:;"'<>,.?/\\-]{2}/.test(cleanedInput)) return true;

  return false; // Valid input
}
/**
 * Checks if the input is a valid number.
 * @param {string} input - The input string to validate.
 * @returns {boolean} - True if the input is a valid number, otherwise false.
 */
export function isNumberInput(input) {
  // Check if the input contains only numbers (integer or float)
  return /^[0-9]+(\.[0-9]+)?$/.test(input);
}

/* Sanitizes a numeric input to allow only digits and a single decimal point.
* @param {string} value - The input value to sanitize.
* @returns {string} - The sanitized numeric input.
*/

export function sanitizeNumberInput(value) {
  // Allow only digits and one decimal point
  return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}
export function isBlankOrInvalid(input) {
  // Check if input is blank, contains only spaces, is a sign (+ or -), or contains special characters
  return !input || 
         /^\s*$/.test(input) ||       // Blank or only spaces
         /^[+-]$/.test(input) ||      // Just a sign (+ or -)
         /[^a-zA-Z0-9.+-]/.test(input); // Contains special characters (non-alphanumeric, excluding .,+,-)
}
/**
* Prevents invalid key presses (e.g., '+', '-', 'e', 'E') in numeric input fields.
* @param {KeyboardEvent} event - The keyboard event to handle.
*/
export function preventInvalidKeyPress(event) {
  // Prevent keys: +, -, e, E, and multiple dots
  const invalidKeys = ['+', '-', 'e', 'E'];
  if (
    invalidKeys.includes(event.key) || 
    (event.key === '.' && event.target.value.includes('.')) // Block multiple dots
  ) {
    event.preventDefault();
  }
}
export function preventPlusSign(event){
  if (event.key === '+' || event.key === '-' || event.keyCode === 187) { // 187 is the keycode for '+' on some keyboards
    event.preventDefault();
  }
};
