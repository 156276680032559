/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { set_view_tag_files } from '../../redux/message';
import { BsXLg } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';


function FileHubHead() {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const view_tag_files = useSelector(state => state.message.view_tag_files)
    const active_conversation = useSelector(state => state.message.active_conversation)
    const all_tags = useSelector(state => state.message.all_tags)
    const userMe = useSelector(state => state.message.user)
    const [tab_state, setTab_state] = useState(params.file_hub_tab ? params.file_hub_tab : 'tags')

    // console.log('conversations', userMe)

    useEffect(() => {
        if (params.file_hub_tab) {
            setTab_state(params.file_hub_tab)
        }
    }, [params])

    const close_popup = () => {
        if (view_tag_files.state) {
            dispatch(set_view_tag_files({ state: false, tag_ids: [] }))
        } else {
            if (params.conversation_id) {
                navigate('/connect/' + params.conversation_id)
            } else {
                navigate('/connect/' + userMe.id)
            }
        }
    }


    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Escape') {
    //             close_popup()
    //         }
    //     };
    //     document.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);


    return (
        <div className='Filehub_header'>
            <div className='tagFile_header'>
                <div className="tagFile_column first_column">
                    <div className="tagname_icon relative">
                        <span className="backToChat !absolute !left-[24px]" id='backNand' onClick={close_popup} ></span>

                        {params.conversation_id ?
                            view_tag_files.state ?
                                <div className="selectedTags flex gap-1 ml-20 !flex-wrap !w-[180%]">
                                    <div>
                                        <span className="">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" color="#e82a87" height="25" width="25" xmlns="http://www.w3.org/2000/svg" style={{ color: '#e82a87' }}><path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path></svg>
                                        </span>
                                    </div>

                                    {all_tags.filter(r => view_tag_files.tag_ids.indexOf(r.tag_id) > -1).map(v =>
                                        <p className="tags_Color !z-[99] !h-[24px]" key={'tag1' + v.tag_id}>{v.title}
                                            <BsXLg style={{ cursor: 'pointer', color: 'white', position: 'relative', top: '3px', marginLeft: '5px', border: '1px solid white', borderRadius: '50%', padding: '2px' }} size={14} onClick={close_popup} />
                                        </p>
                                    )}
                                </div>
                                : <span className="tagNameText" style={{ top: '-4px', left: '40px' }}>{tab_state.charAt(0).toUpperCase() + tab_state.slice(1)} | {active_conversation?.title}</span>
                            :
                            view_tag_files.state ?
                                <span className="tagNameText" style={{ top: '-4px', left: '40px' }}> Tag Files</span>
                                : <span className="tagNameText" style={{ top: '-4px', left: '40px' }}>File Hub | {tab_state.charAt(0).toUpperCase() + tab_state.slice(1)}</span>
                        }
                    </div>
                </div>

                <div className='tagFile_column secound_column'>
                    <ul className="tagFileLists">
                        <div onClick={() => navigate('tags')} className={classNames("tagFileLists_tab", tab_state === 'tags' ? 'activeTab' : '')}>Tags</div>
                        <div onClick={() => navigate({ pathname: 'files', search: '?file_type=all' })} className={classNames("tagFileLists_tab", tab_state === 'files' ? 'activeTab' : '')}>Files</div>
                        <div onClick={() => navigate('links')} className={classNames("tagFileLists_tab", tab_state === 'links' ? 'activeTab' : '')} >Links</div>
                    </ul>
                    <span id="close_popup" onClick={close_popup} className="createConv_close profileClose" ></span>
                </div>
                <Tooltip className='z-[9999]' anchorSelect="#backNand" float="true" content="Back"></Tooltip>
                <Tooltip className='z-[9999]' anchorSelect="#close_popup" place='left' float="true" content="Back"></Tooltip>
            </div>
        </div >

    )
}
export default FileHubHead;