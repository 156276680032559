import React, { useState } from 'react';
import { MdOutlineCancel, MdClose } from 'react-icons/md';
import ClickAwayListener from 'react-click-away-listener';

const AdvanceSearchStatus = ({ selectedStatus, setSelectedStatus }) => {
    const [statusOptionsVisible, setStatusOptionsVisible] = useState(false);
    const [showMoreStatus, setShowMoreStatus] = useState(false);

    const toggleStatusOptions = () => {
        setStatusOptionsVisible(prev => !prev);
    };

    const handleStatusOptionClick = (value) => {
        if (!selectedStatus.includes(value)) {
            setSelectedStatus(prev => [...prev, value]);
        }
        setStatusOptionsVisible(false);
    };

    const handleRemoveStatus = (status) => {
        const updatedStatus = selectedStatus.filter(item => item !== status);
        setSelectedStatus(updatedStatus);
        if (updatedStatus.length === 0) {
            setShowMoreStatus(false); // Close the expanded view if no statuses are left
        }
    };

    const handleShowMoreStatus = () => {
        setShowMoreStatus(prev => !prev);
    };

    // Determine which statuses to show
    const statusesToShow = selectedStatus.slice(0, 2);
    const remainingCount = selectedStatus.length - 2;

    const handleClickAway = () => {
        setStatusOptionsVisible(false);
    };

    return (
        <div className="col3 relative !w-[24%] !ml-0">
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className='custom_dropdown_task'>
                    <button
                        className={`custom_dropdown-header !w-full ${statusOptionsVisible ? 'selected-green' : ''}`}
                        onClick={toggleStatusOptions}
                    >
                        Status <i className={`fa fa-chevron-right custom_icon ${statusOptionsVisible ? 'open' : 'false'}`}></i>
                    </button>
                    {statusOptionsVisible && (
                        <div className="custom_dropdown-body-task-open">
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleStatusOptionClick("Not Started")}
                            >
                                Not Started
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleStatusOptionClick("In Progress")}
                            >
                                In Progress
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleStatusOptionClick("On Hold")}
                            >
                                On Hold
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleStatusOptionClick("Completed")}
                            >
                                Completed
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleStatusOptionClick("Canceled")}
                            >
                                Canceled
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>

            <div className='flex flex-wrap gap-1 mt-1'>
                {statusesToShow.map(status => (
                    <div
                        key={status}
                        className='flex items-center gap-1 pl-2 pr-1 bg-[#318fff] text-white rounded text-sm'
                    >
                        <div>{status}</div>
                        <div onClick={() => handleRemoveStatus(status)}><MdOutlineCancel /></div>
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div
                        onClick={handleShowMoreStatus}
                        className='flex items-center gap-1 pl-2 pr-2 bg-[#318fff] text-white rounded text-sm cursor-pointer'
                    >
                        <div>+{remainingCount}</div>
                    </div>
                )}
            </div>

            {showMoreStatus && (
                <div className='room_list_popup statussPopup'>
                    <span
                        className='absolute top-0 right-0 text-lg'
                        onClick={handleShowMoreStatus}
                    >
                        <MdClose className='cursor-pointer text-[#318FFF]' />
                    </span>
                    {selectedStatus.map(status => (
                        <div key={status} className='flex items-center gap-1 pl-2 pr-1 bg-[#318fff] text-white rounded text-sm'>
                            <div>{status}</div>
                            <div onClick={() => handleRemoveStatus(status)}><MdOutlineCancel /></div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AdvanceSearchStatus;
