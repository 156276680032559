
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useNavigate, Outlet } from "react-router-dom"
import { GET_ME, GET_SINGLE_MSG, GET_REPLY_MESSAGES, GET_ALL_USERS } from '../../Utils/GraphqlQueries';
import { useLazyQuery, useApolloClient } from '@apollo/client'
import { useState, useEffect, useRef } from "react";
import { MessageSendBox } from "./MessageSendBox";
import { GET_SEPERATOR, IsGroupMsg, modifyMyMsg, scrollToBottom } from "../../Utils/Common";
import MessagesContainer from "./Messages/MessagesContainer";
import { useSelector, useDispatch } from 'react-redux'
import { set_deleted_msg, set_kick_out_data, set_messages, set_reply_main_msg, setBooleanState } from "../../redux/message";

import { Tooltip } from "react-tooltip";
import ImageLoader from "../Ai/ImageLoader";

export const ReplySection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const messages = useSelector((state) => state.message.reply_msgs)
    const active_conv = useSelector(state => state.message.active_conversation)
    const { reply_main_msg, kick_out_data, ai_res_waiting, generating_image, deleted_msg } = useSelector(state => state.message)

    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const [page, setPage] = useState(1);
    const [totalPages, settotalPages] = useState(0);
    const params = useParams();
    const [deleteMsgIndex, setDeleteMsgIndex] = useState(false);
    const [fetchThisMsg, { loading: ThisMSGLoading }] = useLazyQuery(GET_SINGLE_MSG, { fetchPolicy: 'no-cache' });
    const [fetchMessages] = useLazyQuery(GET_REPLY_MESSAGES, { fetchPolicy: 'no-cache' });

    const [msg_loader, setMsg_loader] = useState(true)
    const [scroll_loader, setScroll_loader] = useState(false)
    const msgContainerRef = useRef(null);
    const prevScrollHeight = useRef(0);

    useEffect(() => {
        if (messages) {
            if (messages[messages.length - 1]?.sender === "c4439ad9-67b3-4414-a5a9-87a0d0153c58") {
                if (ai_res_waiting) {
                    dispatch(setBooleanState({ data: false, key: 'generating_image' }))
                    dispatch(setBooleanState({ key: 'ai_res_waiting', data: false }))
                }
            }
        }
    }, [messages])

    const afterFetchThisMsg = async (res) => {
        let data = res.data;
        let dycrepetMsgs = await modifyMyMsg([data.message.msg]);
        dispatch(set_reply_main_msg(dycrepetMsgs[0]))
    }

    const [firstDivHeight, setFirstDivHeight] = useState(0);
    const replyMainMsgCon = useRef(null);
    const getFirstDivHeight = () => {
        if (replyMainMsgCon.current) {
            setTimeout(() => {
                const height = replyMainMsgCon.current.offsetHeight;
                setFirstDivHeight(height + 250);
            }, 500);
        }
    };

    useEffect(() => {
        dispatch(set_messages({ data: [], is_reply_msg: 'yes', page: 1 }))
        setPage(1)
        settotalPages(0)
        let fetch = async () => {
            let res1 = await fetchThisMsg({ variables: { msg_id: params.msg_id } })
            let res = await fetchMessages({ variables: { msg_id: params.msg_id, page: 1 } });
            afterFetchThisMsg(res1)
            afterFetchMethod(res, false, 1);
            setMsg_loader(false)
            getFirstDivHeight()
        }
        fetch();
    }, [params.conversation_id, params.msg_id])

    const afterFetchMethod = async (res, filter, c) => {
        let data = res.data
        let dycrepetMsgs = []
        dycrepetMsgs = await modifyMyMsg(data.reply_messages.msgs);
        setPage(data.reply_messages.pagination.page)
        settotalPages(data.reply_messages.pagination.totalPages)
        dispatch(set_messages({ data: JSON.parse(JSON.stringify([...dycrepetMsgs])), is_reply_msg: 'yes', page: c }))
        let container = msgContainerRef.current;
        if (c > 1) {
            // Restore scroll position for newly prepended items
            requestAnimationFrame(() => {
                container.scrollTop = container.scrollHeight - prevScrollHeight.current;
            });
        } else {
            setTimeout(() => {
                scrollToBottom('replyMsgFullContainer')
            }, 300);
        }
        setScroll_loader(false)
    }

    const load_previous_msgs = async () => {
        setScroll_loader(true)
        let container = msgContainerRef.current;
        prevScrollHeight.current = container?.scrollHeight;
        try {
            let res = await fetchMessages({ variables: { msg_id: params.msg_id, page: (page + 1) } });
            afterFetchMethod(res, false, (page + 1));
        } catch (error) {
            setScroll_loader(false)
            console.log(error);
        }
    }

    const onScrollMsg = (e) => {
        if (e.target.scrollTop < 1) {
            if (!scroll_loader) {
                console.log(page, totalPages)
                if (page < totalPages) {
                    load_previous_msgs();
                }
            }
        }
    }

    const closePopup = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (kick_out_data?.msg_id === params.msg_id) {
            closePopup()
        }
    }, [kick_out_data])

    useEffect(() => {
        if (deleted_msg?.msg_id === params.msg_id) {
            closePopup()
        }
    }, [deleted_msg])

    useEffect(() => () => {
        dispatch(setBooleanState({ key: 'msg_ai_active', data: false }))
        dispatch(set_reply_main_msg(null))
        dispatch(set_kick_out_data(null))

        dispatch(setBooleanState({ data: false, key: 'generating_image' }))
        dispatch(setBooleanState({ key: 'ai_res_waiting', data: false }))
        dispatch(set_deleted_msg(null))
    }, [])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                closePopup()
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div style={deleteMsgIndex === true ? { zIndex: 3 } : { zIndex: 30 }} className={`replyThreadContainer !z-[10] replyThreadContainer_new`}>
                <div className="replyMsg_head">
                    <div className="replyHeadTitle">
                        <span style={{ marginLeft: '20px' }} className="backToChat" data-for="replyThread_tooltip" data-tip="Back" onClick={closePopup} ></span>
                        <h3><span>Reply thread(s)</span></h3>
                        <span style={{ right: '0px', marginRight: '0px' }} id="close112" className="backToChat closeBtn" data-for="replyThread_tooltip" data-tip="Back" onClick={closePopup} ></span>
                    </div>
                    <span className="flash_msg_updated"></span>
                    <Tooltip anchorSelect="#close112" place='top' style={{ zIndex: 9999 }} content="Close"></Tooltip>
                </div>

                <div className="replySeparator bg-[#F3F3F3]"><p style={{ zIndex: 1 }}>{messages?.length} Replies</p></div>
                <div className="replyMsgFullContainer" id="replyMsgFullContainer" onScroll={onScrollMsg} ref={msgContainerRef}>
                    {(scroll_loader) && <div className="loaderMain mini_loader"></div>}
                    <div id="replyMsgContainerScroll" className={`replyMsgContainer ${reply_main_msg?.all_attachment?.length ? 'replyForPrivateFile' : ''} `} ref={replyMainMsgCon}>
                        {messages && !ThisMSGLoading && reply_main_msg &&
                            <MessagesContainer
                                data={reply_main_msg}
                                key={reply_main_msg.msg_id}
                                room={active_conv}
                                me={cacheMe.me}
                                thread={true}
                                edit_msg_id={''}
                                all_users={cacheAllUsers.users ? cacheAllUsers.users : []}
                                all_msgs={[reply_main_msg]}
                                is_reply_head={true}
                                seperator={''}
                            />
                        }
                    </div>
                    <div className="onlyReplyContainer" id="onlyReplyContainer" style={{ height: `calc(100% - ${firstDivHeight}px)` }}>
                        {/* <div className="onlyReplyContainer" id="onlyReplyContainer" onScroll={onScrollMsg} ref={msgContainerRef}> */}
                        {reply_main_msg &&
                            messages?.map((m, k) =>
                                <MessagesContainer
                                    data={m}
                                    key={m.msg_id}
                                    room={active_conv}
                                    me={cacheMe.me}
                                    thread={true}
                                    edit_msg_id={''}
                                    all_users={cacheAllUsers.users ? cacheAllUsers.users : []}
                                    all_msgs={messages}
                                    seperator={GET_SEPERATOR(messages, m.msg_id)}
                                    is_group_msg={IsGroupMsg(messages[k - 1], m)}
                                    setDeleteMsgIndex={setDeleteMsgIndex}
                                />
                            )
                        }

                        {(ai_res_waiting && reply_main_msg?.msg_type?.includes('aiReplyReq')) &&
                            <div className='user_msg w-full h-auto pr-[160px] userArea '>
                                <div className="msg_infoo w-full">
                                    <div className="mag_inner !gap-[10px]">
                                        <div className="sender_img nameL ">
                                            <div className="sender_img nameL !mr-0">
                                                <img src="https://wfss001.freeli.io/room-images-uploads/Photos/Chat_Gpt_Png%401733289656817.webp" width="41" height="41" loading="lazy" alt="sender" title="sender" />
                                            </div>
                                        </div>
                                        <div className="sender_info_chat">
                                            <p className="sender_name mr-2">Workfreeli AI </p>
                                        </div>
                                    </div>
                                    <div className="single_user_msg group">
                                        <div className="flex relative justify-end items-center gapp-2 flex-row-reverse">
                                            <div className="float-left  max-w-[100%]">
                                                <div className="msg_body msgBodyArea group">
                                                    <div className="msg_txt msg_text_bg !text-sm darkTextWhite group-hover:!bg-[#f5f9fc] !bg-[#F3F4F6]">
                                                        {generating_image ?
                                                            <div className='genarated_img_wraper' style={{ height: '256px', width: '256px', backgroundColor: '#f9f9f9' }}>
                                                                <ImageLoader />
                                                            </div>
                                                            :
                                                            <span className="type_indicate"></span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    {msg_loader && <div className={`msg_screen_loader fixed w-[calc(100%-346px)] ${params.filter_name ? 'h-[calc(100%-76px)]' : 'h-[calc(100%-178px)]'} flex items-center justify-center right-0 top-[76px] bg-[#f9f9f9b0]`}>
                        <div className="loaderMain mini_loader"></div>
                    </div>
                    }
                </div>
                <Outlet context={[active_conv]} />

                {
                    (active_conv?.close_for !== 'yes' && active_conv?.archive !== 'yes' && !params.filter_name && !reply_main_msg?.msg_type?.includes('task')) ?
                        <MessageSendBox
                            room={active_conv}
                            me={cacheMe.me}
                            is_reply_msg='yes'
                        />
                        : null
                }
            </div>

        </>
    )
}