import { useClickAway } from '@uidotdev/usehooks';
import React, { useState, useEffect } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { MdCancel, MdFolder, MdInsertDriveFile } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import classNames from "classnames";
// import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client'
import { setPopup, set_popup_action_data, set_global_action_data, set_share_link_popup, set_action_file, set_view_tag_files } from "../../redux/message";
import { fileExtension, getThumImage, niceBytes, openFile, openFilePOP } from '../../Utils/Common';
import ImgPreviewPopup from '../Connect/Messages/ImgPreviewPopup';
import PlayerPopup from '../Connect/Messages/PlayerPopup';
import WarningPopup from '../Connect/WarningPopup';
import { GET_ME, OTHERS_TAG_Details } from '../../Utils/GraphqlQueries';
import { FixedSizeList as List } from "react-window";

const FileDownload = require('js-file-download');

const DeleteText = () => {
    return (
        <p className="!text-left px-3">Are you sure you want to delete this message? This cannot be <span className="undone">undone</span></p>
    )
}

const TagsFileList = ({ relatedTag, setrelatedTag, selectedTag, loading, handleRelatedTagsClick, allFiles, setallFiles,
    selectedFilters, setSelectedFilters, pagination, onScrollLinks, containerRef, searchFileName, setSearchFileName, get_files, page, setPage, selectedItemIndex, setSelectedItemIndex,prevloader}) => {
    const dispatch = useDispatch();
    const params = useParams()
    const view_tag_files = useSelector(state => state.message.view_tag_files)
    const client = useApolloClient();
    const [showPopup, setShowPopup] = useState(false);
    const cacheMe = client.readQuery({ query: GET_ME });
    const availableFilters = ['title_- Ascending','title_- Descending', 'date', 'size', 'type', 'uploaded_by', 'reference_ID', 'starred_files'];
    // const [searchFileName, setSearchFileName] = useState('');
    const [filterFileOptions, setFilterFileOptions] = useState(false);
    // const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [selectedTagsIndex, setSelectedTagsIndex] = useState(-1);
    // const [items, setItems] = useState(allFiles.slice(0, 20));
    // const [hasMore, setHasMore] = useState(true);
    const [preview_data, setPreview_data] = useState(null);
    const [video_data, setVideo_data] = useState(null);
    // const [preview_all_data, setPreview_all_data] = useState(null);
    const togglePopup = () => setShowPopup(!showPopup);
    const selected_tag = useSelector(state => state.message.selected_tag)

    const [action_file, setaction_file] = useState(null)
    const [file_delete, setFile_delete] = useState(false)
    const [msgDeletePopup, setMsgDeletePopup] = useState(false);

    const [triggerAPI, setTriggerAPI] = useState(false);
    // console.log("🚀 ~ TagRelatedPopup ~ selected_tag_open:", selected_tag)

    // const { loading: loading_res, error: tag_error, data: tag_res } = useQuery(OTHERS_TAG_Details, { 
    //     variables: { 
    //         tag_id: selected_tag?.tag_id, 
    //         conversation_id: params.conversation_id ? params.conversation_id : '' 
    //     }, fetchPolicy: 'no-cache' 
    // });
    // // const [loading, setLoading] = useState(true);
    // // const [relatedTag, setrelatedTag] = useState([]);
    // console.log('relatedTag:file',relatedTag);
    // useEffect(() => {
    //     debugger
    //     if (tag_res) {
    //         let allTag_ids = [];
    //         let allTags = [];
    //         for (let t of tag_res.others_tag_details) {
    //             if (allTag_ids.indexOf(t.tag_id) === -1 && t.tag_id !== selected_tag?.tag_id) {
    //                 allTag_ids.push(t.tag_id);
    //                 allTags.push({ ...t, isChecked: t.isChecked || false });
    //             }
    //         }
    //         setrelatedTag(allTags)
    //         // setLoading(false)

    //     }
    //     // console.log('tag_res:', tag_res)

    // }, [tag_res])



    // const fetchMoreData = () => {
    //     debugger
    //     if (items.length >= allFiles.length) {
    //         setHasMore(false);
    //         return;
    //     }
    //     setTimeout(() => {
    //         setItems(allFiles.slice(0, items.length + 20));
    //     }, 1000);
    // };
    const showHoverItemHandler = (i) => {
        setSelectedItemIndex(i);
    };
    const showClickItemHandler = (i) => {
        setSelectedItemIndex(i);;
    }

    const showHoverTagsHandler = (i) => {
        setSelectedTagsIndex(i);
    };

    const hideHoverTagsHandler = () => {
        setSelectedTagsIndex(-1);
    };

    const ref = useClickAway(() => {
        setFilterFileOptions(false);
    });

    const handleFilterClick = (filter) => {
        // if (!selectedFilters.includes(filter)) {
        //     setSelectedFilters([...selectedFilters, filter]);
        // }
        setSelectedFilters(filter);
        setFilterFileOptions(false);

    };

    const AfileDownload = (url, filename) => {
        // event.preventDefault();
        // event.stopPropagation();
        // event.nativeEvent.stopImmediatePropagation();
        // props.setPopup({ type: 'downloadingPopup', data: true })
        // props.set_downloading_file([filename]);
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                // //console.log("Show Download POPUP Progress ", Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%");
            }
        }).then((response) => {
            FileDownload(response.data, filename);
            // props.setPopup({ type: 'downloadingPopup', data: false });
        });
    }
    const openDelete = (msg) => {
        if (msg) {
            if (msg.all_attachment.length > 1) {
                setFile_delete(true)
            } else {
                setMsgDeletePopup(true)
            }
        } else {
            setFile_delete(true)
        }
    }

    const handleFileAction = (type, _f, msg) => {
        dispatch(set_action_file(_f))
        setaction_file(_f)
        // if (type === 'star') {
        //     starMarkFile(_f, msg)
        // } 
        if (type === 'tag') {
            dispatch(setPopup({ data: true, key: 'tag_popup' }));
        } else if (type === 'download') {
            AfileDownload(_f.location, _f.originalname)
        }
        else if (type === 'forward') {
            handleMsgAction('file_forward', msg, _f)
        }
        else if (type === 'share') {
            dispatch(set_share_link_popup(true))
        }
        else if (type === 'delete') {
            openDelete()
        }
    }
    const handleMsgAction = (type, msg, extra) => {
        // if (type === 'title') {
        //     dispatch(set_global_action_data({ msg: msg, type: 'msg' }));
        //     dispatch(setPopup({ data: true, key: 'custom_title_popup' }));
        // } else if (type === 'reply') {
        //     navigate(`reply/${msg.msg_id}`)
        // } else if (type === 'download') {
        //     downloadMsgFiles(msg.all_attachment)
        // } else if (type === 'edit') {
        //     setEditMsg(true)
        // } else if (type === 'quick') {
        //     //     dispatch(set_selected_main_msg(msg))
        //     //     dispatch(set_private_quickView(true))
        // } else if (type === 'edit_participant') {
        //     dispatch(set_global_action_data({ type: 'private_msg_update', msg: msg }))
        //     dispatch(setPopup({ data: true, key: 'private_msg' }));
        // } else if (type === 'copy') {
        //     copyToClipBoard(msg)
        // } else if (type === 'forward') {
        //     dispatch(set_global_action_data({ msg: msg, forward_type: 'msg' }));
        //     dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
        // } 
        if (type === 'file_forward') {
            dispatch(set_global_action_data({ msg: msg, file: extra, forward_type: 'file' }));
            dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
        }
        // else if (type === 'delete') {
        //     setMsgDeletePopup(true)
        //     setDeleteMsgIndex(true)
        // }
    }


    const filteredFiles = allFiles?.filter(file => file.originalname?.toLowerCase().includes(searchFileName.toLowerCase())) || [];
    // console.log("filteredFiles", filteredFiles);

    const file_action = (_f) => {
        if (_f.file_type.indexOf('image') > -1) {
            setPreview_data(_f)
            // setPreview_all_data(msg.all_attachment.filter((i) => i.file_type.indexOf('image') > -1))
        }
        else if (_f.file_type.indexOf('pdf') > -1 || _f.file_type.indexOf('audio') > -1) {
            localStorage.setItem('file_view',JSON.stringify(_f));
            window.open('/file', "_blank")
            
        } else if (_f.file_type.indexOf('application') > -1) {
            AfileDownload(_f.location, _f.originalname);

        } else if (_f.file_type.indexOf('video') > -1 ) {
            setVideo_data(_f);
        }
    }

    const removeFilter = (filter) => {
        // setSelectedFilters(selectedFilters.filter((f) => f !== filter));
        setSelectedFilters(null);
    };

    const toggleCheckbox = (id) => {
        // debugger;
        const updatedtags = relatedTag.map((tag) => tag.tag_id === id ? { ...tag, isChecked: !tag.isChecked } : tag);
        setrelatedTag(updatedtags);
        if (updatedtags.filter(v => v.isChecked).length) {
            dispatch(set_view_tag_files({ state: true, tag_ids: [selected_tag?.tag_id, ...updatedtags.filter(v => v.isChecked).map(t => t.tag_id)] }))
        } else {
            dispatch(set_view_tag_files({ state: false, tag_ids: [] }))
        }
        // setSelectAll(updatedtags.every((tag) => tag.isChecked));
    };
    const resetTags = () => {
        const resetTags = relatedTag.map((tag) => ({ ...tag, isChecked: false }));
        setrelatedTag(resetTags);
        dispatch(set_view_tag_files({ state: false, tag_ids: [] }));
    };

    const handleDelPopup = () => {
        setMsgDeletePopup(false);
        setFile_delete(false);
    }
    const selectedTags = relatedTag.filter((v) => v.isChecked); console.log("selectedTags", selectedTags);
    const maxDisplayCount = 2;

    useEffect(() => {
        // debugger
        if (triggerAPI) {
            get_files('init', selectedTag?.tag_id || '')
            setTriggerAPI(false);
        }

    }, [triggerAPI])

    const Row = ({ index, style }) => {
        const file = allFiles[index];
        const filteredTags = file.tag_list_details?.filter(
          (f) =>
            (f.tag_type === "public" || f.tag_type === "task" 
                // || (f.tag_type === "private" && f.tagged_by === cacheMe.me.id)
            ) && f.title !== "UNTAGGED FILES" && f.tag_type !== "private"
        );
    
        return (
          <div
            className="filteredFilesArea group cursor-pointer flex justify-between p-2 px-6 items-center hover:bg-[#D9D9D9] border-b border-b-[#e3f2ff] last:border-b-0"
            key={file.id}
            style={style}
          >
            {selectedItemIndex === index && filteredTags?.length ? (
              <div
                className="allHoverTaglist !h-[139px] !w-[90%] overflow-auto p-[17px_1px_2px_13px] rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] bg-white z-50"
                style={{ right: "unset", left: "0px", top: "20px", position: "absolute", padding: "1px 15px 5px 10px" }}
              >
                <div className="sticky top-0 w-full bg-white pt-[10px] pl-[20px] z-[99999] h-[40px]">
                  <span
                    className="closeModal forTag absolute !right-[0px] !top-[15px]"
                    onClick={() => {
                      setSelectedItemIndex(-1);
                    }}
                  ></span>
                  <h2 className="popupTitle tagfileTitle !mb-[10px]">File tags</h2>
                </div>
                <div className="tagsTitles flex gap-[10px] justify-start clear-both flex-wrap">
                  {filteredTags?.map((tag) => (
                    <p
                      className="p-[4px_9px_3px_11px] rounded-[4px] bg-[#e3f2ff] font-[Rubik] text-[14px] font-normal leading-normal tracking-normal text-left text-[#0b1f47]"
                      key={tag.tag_id}
                    >
                      {tag.title}
                    </p>
                  ))}
                </div>
              </div>
            ) : null}
    
            <div
              className="w-[60%] flex gap-2 items-center"
              onClick={() => file_action(file)}
            >
              <div className="img_holder">
                {file.location.includes("blob:") ? (
                  <img src={file.location} loading="lazy" alt="img" />
                ) : (
                  <img
                    src={
                      file.file_type?.indexOf("image") > -1 && file.file_type?.indexOf("svg") === -1
                        ? getThumImage(file.location)
                        : file.file_category?.indexOf("voice") > -1
                        ? `/media/images/light_theme/voice.svg`
                        : `/media/images/light_theme/${fileExtension(file?.originalname)}.svg`
                    }
                    loading="lazy"
                    alt="file ext"
                    title="sender"
                    className="file_ext"
                  />
                )}
              </div>
              <div className="fileTitleArea">
                <p className="text-[#0b1f47] text-sm tag_filename" id="file_originalname">
                  {file.originalname}
                </p>
                <p className="text-[#858fa3] text-tiny font-semibold flex space-x-2 tag_fileDate">
                  <span>{niceBytes(file.file_size)}</span> <span>|</span>
                  <span>{file?.created_at ? moment(file?.created_at).format("LL") : "Unknown Date"}</span>
                </p>
              </div>
            </div>
    
            <div className="w-[30%] text-[#0b1f47] text-tiny uploaded_by_userName">
              <p>{file.uploaded_by}</p>
            </div>
    
            <div className="w-[10%] flex justify-center">
              {filteredTags?.length ? (
                <p className="timeAndDate" style={{ paddingLeft: "10px" }}>
                  {filteredTags?.length || ""}
                </p>
              ) : (
                <span className="notApplicable">N/A</span>
              )}
              <ul
                className="colorGroup"
                onClick={(e) => {
                  showClickItemHandler(index);
                }}
                id="FileTag"
              >
                {filteredTags?.slice(0, 2).map((tag, i) => (
                  <li
                    key={i}
                    className="colorGroupList"
                    style={{ background: tag.tag_color }}
                  ></li>
                ))}
              </ul>
            </div>
            <div className={"gFileHoverLists !right-[65px] !top-[6px]"}>
              <div
                className="fileOpts tag_opts"
                onClick={() => handleFileAction("tag", file, file)}
                id="addatag"
              ></div>
              <div
                className="fileOpts forward_opts"
                onClick={() => handleFileAction("forward", file, file)}
                id="forwardopts"
              ></div>
              <div
                className="fileOpts share_opts"
                style={file.user_id !== cacheMe.me.id ? { opacity: "0.4", pointerEvents: "none", backgroundColor: "gray" } : {}}
                onClick={() => handleFileAction("share", file, file)}
                id="share_opts"
              ></div>
              <div
                data-tip="Download"
                className="fileOpts download_opts"
                onClick={() => handleFileAction("download", file, file)}
                id="download_opts"
              ></div>
              {file.user_id === cacheMe.me.id && (
                <div
                  className="fileOpts delete_opts"
                  id="deleteopts"
                  onClick={() => handleFileAction("delete", file, file)}
                ></div>
              )}
            </div>
          </div>
        );
      };



    return (
        <div className='selected_tag_files w-full'>
            <div className="flex items-center justify-between gap-3 bg-[#E3F2FE] rounded-t-2xl mostRecentTagsHead">
                <div className="flex items-center  relative">
                    <div className="flex gap-3 items-center border-r p-5" style={{ borderRightColor: 'rgba(100, 166, 244, 0.35)' }}>
                        <MdFolder className="text-[#0b1f47] text-2xl -mt-1" />
                        <p className="text-[16px] text-[#0b1f47]">Files</p>
                    </div>
                    <div className="conv_filter opt_icons !bg-[length:20px] !w-[20px] !mr-0 !ml-[36px] cursor-pointer"
                        onClick={() => setFilterFileOptions(!filterFileOptions)}
                    ></div>
                    {filterFileOptions &&
                        <div ref={ref} className="bg-white w-52 z-10 rounded-lg border border-[#e3f2ff] absolute top-5 left-40 tagsFilterOptions">
                            {availableFilters.map((filter) => (
                                <p
                                    key={filter}
                                    className={classNames('text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer', selectedFilters === filter ? 'active' : '')}
                                    // className={selectedFilters === filter ? 'active' : ''}  // Disable already selected filters
                                    onClick={() => handleFilterClick(filter)}

                                >
                                    {filter.charAt(0).toUpperCase() + filter.slice(1).replace('_', ' ')}
                                </p>
                            ))}

                            {/* <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Date</p>
                            <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Size</p>
                            <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Type</p>
                            <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Uploaded by</p>
                            <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Reference ID</p>
                            <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Starred Files</p> */}
                        </div>
                    }
                    {selectedFilters && (
                        <div className="selected-filter">
                            <div className="filtered_item_name">
                                <span> {selectedFilters.charAt(0).toUpperCase() + selectedFilters.slice(1).replace('_', ' ')}</span>
                            </div>
                            {/* <div className="miniItem_remove" onClick={() => removeFilter()} > </div> */}
                        </div>
                    )}
                </div>
                {relatedTag.filter(v => v.isChecked).length > 0 ?
                    <div className="p-5 cursor-pointer" onClick={resetTags}>
                        <p id="Realfilter" className="  text-[#0b1f47] hover:text-[#318fff] text-[12px]">Clear filter(s)</p>
                        <Tooltip className="z-50" anchorSelect="#Realfilter" float="true" content="Clear filter(s)"></Tooltip>
                    </div>
                    :
                    <div id="rel_filter" className="p-5 cursor-pointer" onClick={handleRelatedTagsClick}>
                        <p className=" text-[#0b1f47] hover:text-[#318fff] text-[12px]">Related tags ({relatedTag?.length || 0})</p>
                        <Tooltip className="z-50" anchorSelect="#rel_filter" float="true" content="Click to view related tags"></Tooltip>
                    </div>
                }


            </div>
            {loading && <div className="loaderMain mini_loader" style={{top: '50%'}}></div>  }
                <div className='mostRecentTagsFlexBox  !rounded-b-2xl'>
                    <div className="flex pt-[17px] pb-[11px] px-6 items-center border-b flex-wrap border-b-[#e3f2ff] gap-3">
                        <div className="flex gap-2 items-center">
                            <div className="w-[14px] h-[14px] rounded-full"
                                style={{ backgroundColor: selectedTag.tag_color }}></div>
                            <p className="text-sm text-[#0b1f47]">{selectedTag.title}</p>
                        </div>
                        {selectedTags.slice(0, maxDisplayCount).map((tag) => (
                            <div className="flex gap-2 items-center  tagTitleIndv">
                                <div className="w-[14px] h-[14px] rounded-full" style={{ backgroundColor: tag.tag_color }}></div>
                                <p className="text-sm text-[#0b1f47]">{tag.title}</p>
                                <span className="selectedtagName"><span className='removeFile tagRemove' data-for={"tooltiptag" + tag.tag_id} data-tip="Remove this related tag" onClick={() => toggleCheckbox(tag.tag_id)} ></span></span>
                            </div>
                        ))
                        }
                        {selectedTags.length > maxDisplayCount && (
                            <span
                                id='alltagsview'
                                className="fileTag text-sm flex gap-[3px] text-[#fff] !bg-[#0b1f47] !pl-[5px] !pr-[5px] !pt-[3px] !pb-[3px] cursor-pointer"
                                onClick={togglePopup}
                            >
                                <span className='ml-[16px]'> + {selectedTags.length - maxDisplayCount} more</span>
                            </span>
                        )}
                        <Tooltip className="z-50" anchorSelect="#alltagsview" float="true" content="Click to view all tags"></Tooltip>
                    </div>
                    {/* Popup all tags*/}
                    {showPopup && (
                        <div className="popup-overlay fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 overflow-hidden pb-[20px]">
                            <div className="popup-content  bg-white p-[20px] !pt-[0px] rounded-lg !max-w-[500px] !max-h-[500px] relative">
                                {/* Sticky Header */}
                                <div className="sticky top-0 bg-white border-b pb-2  pt-2 mb-4 z-10">
                                    <div className="flex justify-between items-center">
                                        <h3 className=" font-medium text-[#002e98] text-[20px]">Selected file tags</h3>
                                        <span
                                            className="createConv_close profileClose  !top-[10px] !right-0 !w-[16px]"
                                            onClick={togglePopup}
                                            id='rel_t'
                                            aria-label="Close popup"
                                        >

                                        </span>
                                        <Tooltip delayShow={500} className="z-50" anchorSelect={"#rel_t"} float="true" content="Close"></Tooltip>
                                    </div>
                                </div>
                                <div className="popup-tags flex flex-wrap gap-2 mt-4">
                                    {selectedTags.map((tag) => (
                                        <div key={tag.tag_id} style={{ backgroundColor: tag.tag_color }} className="flex gap-2 items-center tagTitleIndv rounded relative">
                                            {/* <div
                                                className="w-[14px] h-[14px] rounded-full"
                                                style={{ backgroundColor: tag.tag_color }}
                                            ></div> */}
                                            <p className="text-[14px] text-[#fff] pl-[5px] pr-[5px] pt-[3px] pb-[3px] ">{tag.title} </p>
                                            <span className='removeFile tagRemove !top-[7px] !w-[13px] !h-[13px]' id={`rel_tag_${tag.tag_id}`} onClick={() => toggleCheckbox(tag.tag_id)} ></span>

                                            <Tooltip delayShow={500} className="z-50" anchorSelect={`#rel_tag_${tag.tag_id}`} float="true" content="Remove this from related tag"></Tooltip>
                                        </div>

                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="p-1 px-1 border-b border-b-[#e3f2ff] relative searchBoxInHub">
                        <input value={searchFileName}
                            // onChange={(e) => setSearchFileName(e.target.value)}  //Remove this related tag
                            onChange={(e) => {
                                const value = e.target.value;
                                setSearchFileName(value);

                                // Trigger API when the input becomes an empty string
                                if (value === '') {
                                    setTriggerAPI(true);
                                }
                            }}

                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setTriggerAPI(true);

                                }
                            }}
                            type="text"
                            className="searchInputbox h-[34px] w-full px-6 rounded-full border border-[#a0d2fd] bg-[#F1F6FA] !placeholder-[#318fff]"
                            placeholder="Search a file name" />
                        {
                            searchFileName.length > 0 ? <MdCancel onClick={() => { setSearchFileName(''); setTriggerAPI(true); }} className="absolute right-[12px] top-[9px] text-2xl text-red-600 cursor-pointer" />
                                : <IoSearchOutline size="16px" className="absolute right-5 top-3 text-2xl text-[#0b1f47] " />
                        }

                    </div>
                    <div className='!rounded-b-2xl'>
                        <div className="mostRecentTagshead flex justify-between p-2 px-6 items-center border-b border-b-[#e3f2ff] relative">
                            <p className="text-[#858fa3] text-[12px] w-[60%]">File name</p>
                            <p className="text-[#858fa3] text-[12px] w-[30%]">Uploaded by</p>
                            <p className="text-[#858fa3] text-[12px] w-[10%] flex justify-center">Tags</p>
                        </div>
                        {/* React window */}    
                        <div  ref={containerRef} className="h-[calc(100vh-400px)] relative fileListBlock"
                        // onScroll={onScrollLinks}
                        >
                        {allFiles?.length > 0 ? (
                            <List
                                // ref={containerRef}
                                height={containerRef.current ? containerRef.current.clientHeight : 0}  // Dynamically get container height
                                itemCount={allFiles.length}
                                itemSize={50}  // Change itemSize to 50px
                                width="100%"
                                onScroll={onScrollLinks}
                            >
                                {Row}
                            </List>
                        ) : ( !loading && 
                            <div className="no_files_available">No data found matching the criteria.</div>
                        )}
                        </div>
                    </div>
                </div>
            
            
            {preview_data && <ImgPreviewPopup setPreview_data={setPreview_data} active_file={preview_data} all_file={[]} user={cacheMe.me} />}
            {video_data && <PlayerPopup setVideo_data={setVideo_data} video_data={video_data} all_file={[]} user={cacheMe.me} />}
            {(file_delete) && <WarningPopup allFiles={allFiles} setallFiles={setallFiles}
                messageTitle={file_delete ? 'Delete file' : 'Delete message'}
                description={file_delete ? 'Are you sure you want to delete this file?' : <DeleteText />}
                closePopUp={handleDelPopup} data={{ ...action_file }} type={file_delete ? "file_delete" : "delete_msg"} />}
        </div>
    );
};

export default TagsFileList;