import React from 'react';

const RoleBackWarning = ({ company_name, onCancel, onMerge, company_id }) => {
    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">Rollback Confirmation</h4>
                    <span className="closeModal" onClick={onCancel} data-for="rightSection_tooltip" data-tip="Close" currentitem="false"></span>
                </div>
                <div className="freeliModalBody">
                    <p className="delDesc !text-base !text-justify !px-[10px]">
                        Are you sure you want to rollback the "{company_name}" company from your current company? If yes, all data, including users, conversations, and messages, will be point to the previous time.
                    </p>
                </div>
                <div className="freeliModalFoot">
                    <button className="btnCancel" onClick={onCancel}>No</button>
                    <button className="btnActionConfirm" style={{ backgroundColor: 'rgb(255, 85, 85)' }} onClick={() => onMerge(company_id)}>Yes</button>
                </div>
            </div>
        </div>
    );
};

export default RoleBackWarning;