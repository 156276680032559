import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
// import {createTeamAPI, updateTeamAPI} from '../../Utils/API'
import toast from 'react-hot-toast';
import { CREATE_UPDATE_TEAM } from '../../../Utils/GraphqlMutaion';
import { InputValidationCheck } from '../../ValidationCheck';
function CreateTeam({ type, setCreate_popup, setallTeams, data, user, allTeams }) {
	// console.log("🚀 ~ CreateTeam ~ data:", data)
	const [loader, setLoader] = useState(false);
	const [title, setTitle] = useState(type === 'edit' ? data.team_title : '');
	const [error, setError] = useState('');

	const [create_update_team] = useMutation(CREATE_UPDATE_TEAM)

	const createTeam = async () => {
		setLoader(true)
		let apires = await create_update_team({
			variables:
			{
				input: {
					title: title,
					type: type === 'create' ? 'new' : 'update',
					participants: [user.id],
				}
			}
		})
		// console.log("🚀 ~ createTeam ~ apires:", apires)
		if (apires.data.create_update_team.status) {
			setallTeams(prev => [apires.data.create_update_team.return_data[0], ...prev])
		}
		setLoader(false)
		toast.success('Team created successfully', { duration: 4000 });
		setCreate_popup('')
	}

	const updateTeam = async () => {

		setLoader(true)
		let apires = await create_update_team({
			variables:
			{
				input: {
					title: title,
					type: type === 'create' ? 'new' : 'update',
					participants: data.participants,
					team_id: data.team_id
				}
			}
		})
		// console.log("🚀 ~ createTeam ~ apires:", apires)
		if (apires.data.create_update_team.status) {
			// console.log(25, apires.data.create_update_team.data);
			setallTeams(prev => prev.map(m => m.team_id === data.team_id ? { ...m, team_title: title } : m))
		}
		setLoader(false)
		toast.success('Team updated successfully', { duration: 4000 });
		setCreate_popup('')

		// try {
		// 	let data = {
		// 		team_id: props.data.team_id,
		// 		participants: props.data.participants,
		// 		title: title,

		// 	}
		// 	setLoader(true)
		// 	let APIres = await updateTeamAPI(data);
		// 	setLoader(false);
		// 	props.setEditTeam(false);
		// 	// console.log(APIres)
		// 	if (APIres.status) {
		// 		props.setTeams([...APIres.data])
		// 		toast.success('Team updated successfully', {duration: 4000});
		// 	} else {
		// 		toast.error(APIres.message, {duration: 4000});
		// 	}

		// } catch (error) {
		// 	console.log(error);
		// }
	}

	// console.log(49, props.logindata);




	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Escape') {
				setCreate_popup('');
			}
		};
		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [setCreate_popup]);

	const handleInputChange = (e) => {
		const { value } = e.target;

		// If the value is empty or the user is clearing the input (backspace case)
		if (value === '') {
			// Allow clearing the input
			setTitle(value);
			setError('');
			return;
		}

		// Use InputValidationCheck to validate the input (assuming title is not a number field)
		if (InputValidationCheck(value)) {
			console.warn('Invalid input for title'); // Can log or display an error message here
			return;
		}

		// Trim spaces from the start of the value
		const trimmedValue = value.trimStart();

		// If the title is empty or contains only spaces, we prevent further updates
		if (!trimmedValue) {
			console.warn('Title cannot be empty or contain only spaces');
			return;
		}

		const nameExists = allTeams.some(team => team.team_title.toLowerCase() === trimmedValue.toLowerCase());
		if (nameExists) {
			setError('Team name already exists');
		} else {
			setError('');
		}

		// Update state with the validated value
		setTitle(trimmedValue);
	};

	const allTeamNameExist = allTeams.filter(f => f.team_title);


	// console.log("🚀 ~ allTeamNameExist:", allTeamNameExist)

	return (

		<>
			<div className="backwrap">
				{type === 'create' ?
					<div className="createTeamModal">
						<div className="createTeamModalHead">
							<h4 className="popupTitle">Create team</h4>
							<span className="closeModal"
								onClick={() => setCreate_popup('')}
							></span>
						</div>
						<div className="createTeamModalBody !pb-0">
							<div className="crTeam_inputGroup ">
								<label>Team name</label>
								<input autoFocus type="text"
									value={title} maxLength={25}
									className={`!mb-0`}
									// onChange={(event) => setTitle(event.target.value)} 
									onChange={handleInputChange}
									placeholder="Team name" />
							</div>
							{error && <span className="text-red-500 text-xs !relative">{error}</span>}
						</div>
						<div className="createTeamModalFoot">
							{loader ? <button className="btnAction btn_loader" style={{ 'color': 'transparent' }}>Loading..</button>
								:
								!error && title.trim() !== '' ?
									<button className="btnAction" onClick={createTeam}>Create team</button>
									:
									<button className="btnAction" style={{ 'opacity': '0.5' }}>Create a team</button>
							}

						</div>
					</div>
					:
					<div className="createTeamModal">
						<div className="createTeamModalHead">
							<h4 className="popupTitle">Edit team</h4>
							<span className="closeModal" onClick={() => setCreate_popup('')}></span>
						</div>
						<div className="createTeamModalBody">
							<div className="crTeam_inputGroup">
								<label>Team name</label>
								<input type="text"
									maxLength={25} value={title}
									// onChange={(event) => setTitle(event.target.value)} 
									onChange={handleInputChange}
									placeholder="Team name" />
							</div>
						</div>
						<div className="createTeamModalFoot">
							{
								loader ? <button className="btnAction btn_loader" style={{ 'color': 'transparent' }}>Update</button>
									:
									title.trim() !== '' ?
										<button className="btnAction" onClick={updateTeam}>Update</button>
										:
										<button className="btnAction" style={{ 'opacity': '0.5' }}>Update</button>
							}

						</div>
					</div>
				}
			</div>
		</>
	)
}

export default CreateTeam;