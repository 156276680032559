import React from 'react';
import './barchart.css';
import EODStartEnd from './EODStartEnd';
// import TotalSalesInDashboard from './TotalSalesInDashboard';
import CreatePayments from './CreateEdit/CreatePayments';
import DashboardStatisSum from './DashboardStatisSum'
import CreateTaxes from './CreateEdit/CreateTaxes';
import CreateCash from './CreateEdit/CreateCash';
import TotalSalesTable from './CreateEdit/CreateTotalSales';


const SalesBarChart = ({ demoData, data, date }) => {
    const weekDayBreakdown = data?.weekDayBreakdown || demoData.weekDayBreakdown;

    // Calculate the total for each day
    const totalPerDay = weekDayBreakdown.map(v => v.totalActual + v.totalEstimate);
    const overallMaxTotal = Math.max(...totalPerDay);

    // Define minimum width percentage
    const minWidthPercentage = 8;

    let sales_data = []; // Sales data
    if(demoData?.total_sales?.Items?.length){
        demoData?.total_sales?.Items?.forEach(item => {
            sales_data.push({
                sales: item.Name,
                qty: item.Quantity,
                grossAmount: item.GrossAmount,
                refunds: item.RefundAmount,
                discounts: item.DiscountAmount,
                amount: item.NetAmount
            })
        });
    }

    let payment_data = [];  // Payments data
    if(demoData?.payments?.Items?.length){
        demoData?.payments?.Items?.forEach(item => {
            payment_data.push({
                type: item.Name,
                qty: item.Quantity,
                amount: item.Amount
            })
        });
    }

    let tax_data = []; // Taxes data
    if(demoData?.taxes?.Items?.length){
        demoData?.taxes?.Items?.forEach(item => {
            tax_data.push({
                type: item.Name,
                qty: item.Quantity,
                amount: item.Amount
            })
        });
    }

    return (
        <>
            <div className='salesProgressBarHead'>
                <p className='salesProgressBarText text-[32px] font-medium leading-normal text-left text-[#0b1f47]'>Sales period</p>
                <EODStartEnd _date={date} type='bar' />
            </div>
            {/* <TotalSalesInDashboard /> */}
            <TotalSalesTable type="dashboard" salesData={sales_data} />
            {/* Total Sales by week day */}
            <h1 className=" totalLabel text-[32px] font-medium text-left text-[#0b1f47] pt-[30px] pl-[25px] pb-[20px]">Total sales by week day</h1>
            <div className='eodBackground rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white  ml-[25px] mr-[25px] pb-[10px]'>
                <div className='barChartHeader  pl-[25px]  pr-[25px]'>
                    <div className='barChartSalesHead'>
                        <p className='barChartCostText'>
                            {`Labour cost: $${new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(Math.round(demoData.dateRangeTotal.totalActualLabour || 0))}`}
                        </p>
                    </div>
                    <div className='barChartCostHead'>
                        <p className='barChartCostText'>
                            {`Net sales: $${new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(Math.round(demoData.dateRangeTotal.totalNetSales || 0))}`}
                        </p>
                    </div>
                </div>
                <div className='barChartCon  pl-[25px]  pr-[25px]' >
                    {weekDayBreakdown.map((v, index) => {
                        const totalWidth = v.totalActual + v.totalEstimate;
                        const normalizedWidth = overallMaxTotal ? (totalWidth / overallMaxTotal) * 100 : 0;

                        // Apply minimum width logic
                        const actualWidth = v.totalActual > 0 ? Math.max((v.totalActual / totalWidth) * normalizedWidth || 0, minWidthPercentage) : 0;
                        const estimateWidth = v.totalEstimate > 0 ? Math.max((v.totalEstimate / totalWidth) * normalizedWidth || 0, minWidthPercentage) : 0;

                        return (
                            <div className='barChartMainCon' key={index}>
                                <div className='barChartDayNameCon'>
                                    <p className='barChartDayName'>{v.day}</p>
                                </div>
                                <div className='barChartMainBg'>
                                    {actualWidth > 0 && (
                                        <div
                                            className='barChartCost  min-w-fit'
                                            style={{
                                                width: `${actualWidth}%`,
                                                background: v.totalActual > 0 ? undefined : '#DDD',
                                            }}
                                        >
                                            <p style={{ textAlign: `${estimateWidth > 0 ? 'left' : 'center'}` }} className='barChartCostText'>
                                                ${new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }).format(Math.round(v.totalActual) || 0)}
                                            </p>
                                        </div>
                                    )}
                                    {estimateWidth > 0 && (
                                        <div
                                            className='barChartSales min-w-fit'
                                            style={{
                                                width: `${estimateWidth}%`,
                                                background: v.totalEstimate > 0 ? undefined : '#DDD',
                                                marginLeft: `${actualWidth > 0 ? '-15px' : '0px'}`,
                                            }}
                                        >
                                            <p style={{ textAlign: `${actualWidth > 0 ? 'right' : 'center'}` }} className='barChartSalesText pl-[10px]'>
                                                ${new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }).format(Math.round(v.totalEstimate) || 0)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Payment*/}
            <div className="eodBackground  bg-blue-50 flex items-center w-full">
                <div className="flex gap-[0px]  w-full">
                    <div className='w-1/2'>
                        <DashboardStatisSum demoData={demoData} />
                    </div>
                    <div className='w-1/2'>
                        <CreatePayments paymentsData={payment_data} type="dashboard" paddingTopBotom={4} />
                    </div>
                </div>
            </div>
            <div className="eodBackground bg-blue-50 flex items-center w-full">
                <div className="flex gap-[0px]  w-full">
                    <div className='w-1/2'>
                    <CreateTaxes type="dashboard" taxesData={tax_data} />
                    </div>
                    <div className='w-1/2'>
                    <CreateCash type="dashboard" cashData={demoData.cash} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SalesBarChart;
