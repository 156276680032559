

import React, { useState, useEffect } from "react";
import { set_venue_particeprnt_popup } from '../../../redux/message';
import { set_eod_Venue_check_Submitter, set_eod_Venue_check_Re_Open, set_eod_Venue_check_eod_email, add_eod_venu_member, add_Maintanence_venu_member } from '../../../redux/adminSlice';
import { useDispatch, useSelector } from "react-redux";


function VenueUserParticipents({ eod_maintanence_PopUp_Type }) {

    const dispatch = useDispatch();
    const close_particeprnt_popup = (type) => {
        dispatch(set_venue_particeprnt_popup(false))
    };




    const allUser = useSelector(state => state.admin.allUser);
    const [allparticipants, setAllparticipants] = useState([]);

    const all_venue = useSelector(state => state.admin.all_venue);
    const [listAddParticipants, setListAddParticipants] = useState([]);


    useEffect(() => {

        if (eod_maintanence_PopUp_Type === "EOD") {
            const filteredUsers = allUser.filter(user => all_venue.eod_participants.includes(user.id));
            setListAddParticipants(filteredUsers);

            // const allList = allUser.filter((item) => !all_venue.eod_participants.includes(item.id));

            const allList = allUser
                .filter((item) => !all_venue.eod_participants.includes(item.id))
                .filter((data) => data.role === "Member" || data.role === "Admin")
                .sort((a, b) => a.email.localeCompare(b.email));
            setAllparticipants(allList);
        }


        if (eod_maintanence_PopUp_Type === "maintanence") {
            // console.log("eee", all_venue);
            // console.log("allUser", allUser);
            const filteredUsers = allUser.filter(user => all_venue.maintenance_participants.includes(user.email));
            setListAddParticipants(filteredUsers);

            // const allList = allUser.filter((item) => !all_venue.maintenance_participants.includes(item.email));              
            const allRoll_List = allUser.filter((data) => data.role === "Member" || data.role === "Admin");
            const allList = allRoll_List.filter((item) => !all_venue.maintenance_participants.includes(item.email)).sort((a, b) => a.email.localeCompare(b.email));
            setAllparticipants(allList);
        }

    }, [allUser, all_venue, eod_maintanence_PopUp_Type]);


    const addmember = (member) => {
        setListAddParticipants((prev) => [...prev, member]);
        setAllparticipants((prev) => prev.filter((item) => item.email !== member.email));
    }

    const closeMember = (member) => {
        // console.log("40", member);
        setListAddParticipants((prev) => prev.filter((item) => item.email !== member.email))
        setAllparticipants((prev) => {
            const updatedList = [...prev, member];
            return updatedList.sort((a, b) => a.email.localeCompare(b.email));
        });
    }



    // const eod_venu_member = useSelector(state => state.admin.eod_venu_member);
    const maintenance_participants = useSelector(state => state.admin.all_venue);

    const submit = () => {
        if (eod_maintanence_PopUp_Type === "EOD") {
            const id = listAddParticipants.map((ids) => ids.id);
            dispatch(add_eod_venu_member(id));

            const eod_submitters = id.filter(item => maintenance_participants.eod_submitters.includes(item));
            dispatch(set_eod_Venue_check_Submitter(eod_submitters));


            const eod_reopen = id.filter(item => maintenance_participants.eod_reopen.includes(item));
            dispatch(set_eod_Venue_check_Re_Open(eod_reopen));

            const eod_email = id.filter(item => maintenance_participants.eod_email.includes(item));
            dispatch(set_eod_Venue_check_eod_email(eod_email));

            close_particeprnt_popup();
        }

        if (eod_maintanence_PopUp_Type === "maintanence") {
            const email = listAddParticipants.map((ids) => ids.email);
            dispatch(add_Maintanence_venu_member(email));
            close_particeprnt_popup();
            // console.log("eeeee", maintenance_participants.maintenance_participants);
        }
    }



    const [searchQuery, setSearchQuery] = useState("");


    const allParticipantsList = allparticipants.filter((item) =>
        item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.lastname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const adMemberBtnValidation = () => {
        if (eod_maintanence_PopUp_Type === "EOD") {
            const eodParticipants = all_venue?.eod_participants;
            const listParticipants = listAddParticipants.map((ids) => ids.id);
            return JSON.stringify(eodParticipants) === JSON.stringify(listParticipants);
        }
    
        if (eod_maintanence_PopUp_Type === "maintanence") {
            const maintenanceParticipants = all_venue?.maintenance_participants;
            const listParticipants = listAddParticipants.map((ids) => ids.email);
            return JSON.stringify(maintenanceParticipants) === JSON.stringify(listParticipants);
        }
    
        return true;
    };

    return (
        <>

            <div className="backwrap participantsBackwrap">
                <div className="participantsModal">
                    <div className="participantsModalHead">
                        <h4 className="popupTitle">Add member(s) to this venue</h4>
                        <span
                            className="closeModal"
                            data-for="rightSection_tooltip"
                            data-tip="Close"
                            onClick={close_particeprnt_popup}
                        />
                    </div>
                    <div className="participantsModalBody">
                        <div className="searchParticipants mb-10">
                            <span
                                style={{
                                    width: "calc(100% - 130px)",
                                    position: "relative",
                                    float: "left"
                                }}
                            >
                                <input
                                    type="text"
                                    className="participantsSearch"
                                    placeholder="Search user"
                                    defaultValue=""
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    style={{ width: "100%" }}
                                    autoFocus={true}
                                />
                                <span
                                    className="leftSearchCloseBtn remove !top-[14px] !right-[10px]"
                                    onClick={(e) => setSearchQuery("")}

                                />
                            </span>
                            <button className="paricipantsSubmit active !border-0 !bg-[#0D1E45] hover:!bg-[#0069d9]" onClick={() => submit()} disabled={adMemberBtnValidation()}>Add member</button>
                            <div className="selectedParticipants mb-0" >
                                {listAddParticipants.map((listadded) => (
                                    <div className="miniItem" key={listadded.id}>
                                        <div className="miniItem_image">
                                            <img src={listadded.img} alt={`Profile of ${listadded.firstname}`} />
                                        </div>
                                        <div className="miniItem_name">{listadded.firstname} {listadded.lastname}</div>
                                        <div className="miniItem_remove" onClick={() => closeMember(listadded)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="participantsContainer">
                            {allParticipantsList.map((participant) => (
                                <div className="participantItem active" key={participant.id} onClick={() => addmember(participant)}>
                                    <div className="participantImage">
                                        {participant.img ? (
                                            <img src={participant.img} alt={`Profile of ${participant.firstname}`} />
                                        ) : (
                                            <span className="nameLetters">{participant.firstname.charAt(0)}</span>
                                        )}
                                    </div>
                                    <p className="participantTitle">{participant.firstname} {participant.lastname}</p>
                                    <p className="participantEmail">{participant.email}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default VenueUserParticipents;