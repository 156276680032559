import React, { useEffect, useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import { TbArrowBackUp } from 'react-icons/tb';
import { MdOutlineCancel } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';
import sortArrayByKey from '../../../hooks/useSortedArray';

const KeywordFilter = ({ setKeywordFilterPopup }) => {
    const { fetchByFilter, filter, keywords, set_filter, setPagination, set_column_id } = useTaskContext();
    const [moreFilterPopUp, setMoreFilterPopUp] = useState(false);
    const [selected_keywords, set_selected_keywords] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('asc'); // Default sort option

    const handleCheckboxChange = (keyword) => {
        set_selected_keywords(prev => prev.includes(keyword) ? [...prev.filter(f => f !== keyword)] : [...prev, keyword])
    };

    const handleSelectAllChange = () => {
        set_selected_keywords(prev => prev.length === keywords.length ? [] : [...keywords.map(m => m.keywords_title)])
    };

    const view_filter = () => {
        let f_array = [...selected_keywords]; // get array of ids
        f_array.unshift('keywords'); // modify f_array to add 'keywords' at the beginning
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(prev => [...prev, f_array]); // spread and update the task_filter
        fetchByFilter([...filter, f_array]);
        setKeywordFilterPopup(false)
    }

    const filteredKeywords = keywords
        .filter((f) =>
            f.keywords_title.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
            switch (sortOption) {
                case 'asc':
                    return a.keywords_title.localeCompare(b.keywords_title); // Sort ascending by keywords_title
                case 'dsc':
                    return b.keywords_title.localeCompare(a.keywords_title); // Sort descending by keywords_title
                case 'recent':
                default:
                    return 0; // No sorting for 'Most Recent'
            }
        });


    // const sorted_data = sortArrayByKey(filteredKeywords, 'title', 'asc');

    const ref = useClickAway(() => {
        setKeywordFilterPopup(false);
    });

    const ref1 = useClickAway(() => {
        setMoreFilterPopUp(false);
    });

    return (
        <div ref={ref}>
            <div className='tagRelatedPopup forKeywords inRoomFilter !z-[9999] !w-[560px]'>
                <div className='tagToggle_sidebar'>
                    <div className="tagToggle_sidebar_head justify-between !py-6 !px-7">
                        <div className='flex items-center gap-2'>
                            <TbArrowBackUp className='text-xl text-[#318FFF] cursor-pointer' onClick={() => setKeywordFilterPopup(false)} />
                            <div className="tagsText !text-[#2c56ac] !text-sm">Keywords</div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className="filterKanbanIcon inKeywordFiltered relative" onClick={() => setMoreFilterPopUp(!moreFilterPopUp)}>
                            </div>
                            <div className="searchareaDiv relative">
                                <input
                                    id='popupserachFilter'
                                    className="_inputBar searchLeft relative"
                                    type="text"
                                    placeholder="Search a keyword"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {searchQuery.length > 0 ?
                                    <MdOutlineCancel onClick={() => setSearchQuery("")} className=' cursor-pointer !right-2 absolute text-red-600 top-2 text-xl' /> : <div className="srcSectionPopup !right-2" data-for="top_head" data-tip="Search"></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="selectAllArea !py-4 !px-7">
                        <div className="checkboxes__item">
                            <label className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={keywords.length === selected_keywords.length}
                                    onChange={handleSelectAllChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Select all</div>
                            </label>
                        </div>
                    </div>
                    <div className="allTagsArea">
                        <ul className="alltagList">
                            {filteredKeywords.map(v => (
                                <li key={v._id} className='!py-4 !px-7'>
                                    <div className="tagsRelateLists">
                                        <div className="checkboxes__item">
                                            <label className="checkbox style-c">
                                                <input
                                                    type="checkbox"
                                                    // checked={!!checkedKeywords[v.keywords_title]}
                                                    checked={selected_keywords.includes(v.keywords_title)}
                                                    onChange={() => handleCheckboxChange(v.keywords_title)}
                                                />
                                                <div className="checkbox__checkmark"></div>
                                                <div className="checkbox__body">{v.keywords_title}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tagCoupleBtn !bottom-5">
                        <div className="skipButton" onClick={() => setKeywordFilterPopup(false)}>Skip</div>
                        <div className="skipButton !w-[80px]" onClick={() => set_selected_keywords([])}>Reset</div>
                        <div className="viewFilesButton !w-[75px]" onClick={view_filter}>View</div>
                    </div>
                </div>
                {moreFilterPopUp && (
                    <ul ref={ref1} className="userActionPopup showAll !w-[200px] checklistPopup ForTaskList !left-[52%] top-14">
                        <li
                            className={`!text-xs ${sortOption === 'Most Recent' ? 'active' : ''}`}
                            onClick={() => setSortOption('recent')}
                        >
                            Most Recent keywords
                        </li>
                        <li
                            className={`!text-xs ${sortOption === 'Ascending' ? 'active' : ''}`}
                            onClick={() => setSortOption('asc')}
                        >
                            Ascending
                        </li>
                        <li
                            className={`!text-xs ${sortOption === 'Descending' ? 'active' : ''}`}
                            onClick={() => setSortOption('dsc')}
                        >
                            Descending
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default KeywordFilter;
