import { configureStore } from '@reduxjs/toolkit'
import message from './message'
import task from './taskSlice'
import admin from './adminSlice'
import eod from './eodSlice'

export const store = configureStore({
    reducer: {
        message: message,
        task: task,
        admin: admin,
        eod: eod
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Disabling serializable check for non-serializable data like File objects
            serializableCheck: {
                ignoredActions: ['message/set_voice_file', 'message/set_connect_files'], // Add other actions to ignore if necessary
                ignoredPaths: ['message.voice_file', 'message.connect_files'], // Add other paths to ignore if necessary
            },
        }),
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in non-production environments
})