/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import AudioAnalyser from "react-audio-analyser"
import { BiPlayCircle, BiPauseCircle } from "react-icons/bi";
import { AiFillDelete, AiFillSave } from "react-icons/ai";
import classNames from "classnames";
import { useDispatch } from 'react-redux';
import { set_voice_file, setPopup } from '../../../redux/message';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
// import { Data } from 'emoji-mart';

const VoiceMessage = ({ setVoiceSms }) => {
    const dispatch = useDispatch()

    const [RecordingState, setRecordingState] = useState("inactive");
    const [RecordingSrc, setRecordingSrc] = useState("");
    const [RecordingFile, setRecordingFile] = useState("");
    const [RecordingGraph, setRecordingGraph] = useState(false);
    const [RecordingError, setRecordingError] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [RecordingStart, setRecordingStart] = useState(false);
    const [, setRecordingPause] = useState(false);
    const [RecordingStop, setRecordingStop] = useState(false);
    const [RecordingDel, setRecordingDel] = useState(false);
    const [title, setTitle] = useState('');
    
    // const streamRef = useRef(null); // To hold the microphone stream reference
    const mediaRecorderRef = useRef(null); // To hold the MediaRecorder reference

    useEffect(() => {
        // Start recording when the component mounts
        controlAudio("recording");
    }, []);

    const delAudio = (type) => {
        setRecordingDel(false);
        setRecordingSrc("");
        setRecordingFile("")
        if (type === "close") {
            const e = document.querySelector(".audiograph audio");
            e.parentElement.removeChild(e);
        }
    }

    const controlAudio = async (e, type) => {
        // debugger;
        if (e === "recording") {
            setRecordingStart(false);
            setRecordingPause(true);
            setRecordingStop(true);
            setRecordingGraph(true);
            delAudio("open");

            // mediaRecorderRef.current = e.target;

            // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // streamRef.current = stream; // Save the stream reference

        } else if (e === "paused") {
            setRecordingStart(true);
            setRecordingPause(false);
            setRecordingStop(true);
            setRecordingGraph(true);

        } else if (e === "inactive") {
            setRecordingStart(true);
            setRecordingPause(false);
            setRecordingStop(false);
            setRecordingDel(true);
           

             // Stop the microphone stream to turn off access
            //  if (streamRef.current) {
            //     streamRef.current.getTracks().forEach(track => track.stop());
            //     streamRef.current = null;
            // }

        }
        setRecordingState(e);
    }
    
    const audioProps = {
        audioSrc: RecordingSrc,
        audioType: 'audio/mp3',
        className: 'audiograph',
        status: RecordingState,
        backgroundColor: '#ffffff',
        strokeColor: '#0c1e47',
        timeslice: 1000,
        startCallback: (e) => {
            // console.log("succ start", e);
            // debugger;
            mediaRecorderRef.current = e.target;
        },
        pauseCallback: (e) => {
            // console.log("succ pause", e);
        },
        stopCallback: (e) => {
            const blobURL = window.URL.createObjectURL(e);
            setRecordedBlob(e)
            setRecordingSrc(blobURL);
            setRecordingFile(`voice-message-${Date.now()}.mp3`);
            // debugger;
            if (mediaRecorderRef.current?.stream) {
                mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
                mediaRecorderRef.current = null;
            }
        },
        onRecordCallback: (e) => {
            // console.log("recording", e);
        },
        errorCallback: (err) => {
            console.log("error", err);
            setRecordingError(true);
            setRecordingStart(false);
            setRecordingPause(false);
            setRecordingStop(false);
            setRecordingGraph(false);
            setRecordingDel(false);
        }
    };


    const continueToUpload = async () => {
        // debugger;
        const file = new File([recordedBlob], RecordingFile, {
            type: 'audio/mp3',
            lastModified: Date.now()
        });

        // console.log("🚀 ~ continueToUpload ~ file:", file)
        file['file_category'] = 'voice';
        file['voice_set_type'] = 'voice';
        file['voice_set_title'] = title;
        
        var dt = new DataTransfer();
        dt.items.add(file);
        var files = dt.files;
        dispatch(set_voice_file(files))
        dispatch(setPopup({ key: 'voice_record', data: false }))
        dispatch(setPopup({ key: 'upload_popup', data: true }))
        // navigate(`/connect/${conversation_id}/file_upload`)
        // window.voice_set_title = title;
        // window.voice_set_type = 'voice';
    }

    const close_popup = () => {
        dispatch(setPopup({ key: 'voice_record', data: false }))
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                dispatch(setPopup({ key: 'voice_record', data: false }))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
     const handleInputChange = (e) => {
                    const { value } = e.target;
                
                    // If the value is empty or the user is clearing the input (backspace case)
                    if (value === '') {
                        // Allow clearing the input
                   
                        setTitle(value);
                        return;
                    }
                
                    // Validate the first character to check if it's special or non-alphanumeric
                    if (validFirstChercterAlsoNuber(value)) {
                        console.warn('Invalid input for title: First character cannot be special');
                        return; // Prevent update if first character is special
                    }
                
                    // Trim spaces from the start of the value
                    const trimmedValue = value.trimStart();
                
                    // If the title is empty or contains only spaces, we prevent further updates
                    if (!trimmedValue) {
                        console.warn('Title cannot be empty or contain only spaces');
                        return; // Prevent update if the value is empty after trimming
                    }
                
                    // Check if the value contains consecutive special characters
                    if (/[^\w\s][^\w\s]/.test(trimmedValue)) {
                        console.warn('Title cannot contain consecutive special characters');
                        return; // Prevent update if there are consecutive special characters
                    }
                
                    // If all validations pass, update state with the validated value
                
                    setTitle(trimmedValue);
                };

    return (
        <div className='backwrap'>
            <div className='fileUploadModal'>
                <div className="fileUploadModalHead">
                    <h4 className="popupTitle">Record a voice message for yourself.</h4>
                    <span onClick={close_popup} className="closeModal" data-for="rightSection_tooltip" data-tip="Close" ></span>
                </div>
                <div className="fileUploadModalBody" >
                    <div className={classNames("send_voice_opt", RecordingError ? 'hide' : 'show')}>
                        <div className="voice_control">
                            {RecordingStart === true ?
                                <div className="voice_upload_label" onClick={() => controlAudio("recording", "again")}>
                                    <BiPlayCircle className="voice-icon" />
                                    <span className="voice_upload_text">Record</span>
                                </div> : <div className="voice_upload_label"
                                    onClick={() => controlAudio("paused")}>
                                    <BiPauseCircle className="voice-icon" />
                                    <span className="voice_upload_text">Pause</span>
                                </div>
                            }
                            {RecordingStop === true ?
                                <div className="voice_upload_label"
                                    onClick={() => controlAudio("inactive")}>
                                    <AiFillSave className="voice-icon" />
                                    <span className="voice_upload_text">Save</span>
                                </div> : ""}
                            {RecordingDel === true ?
                                <>
                                    <div className="voice_upload_label"
                                        onClick={() => delAudio("close")}>
                                        <AiFillDelete className="voice-icon del" />
                                        <span className="voice_upload_text">Delete</span>
                                    </div>
                                </> : ''
                            }
                        </div>
                        <div className={classNames("voice-graph", RecordingGraph ? 'show' : 'hide', RecordingFile ? 'hidegraph' : 'showgraph')}>
                            {RecordingFile ? <div className="voiceTitleBody">
                                <input type="text" onChange={handleInputChange} className="customTitleInput" placeholder="Enter a title to this voice message" />
                            </div> : ''}
                            <AudioAnalyser {...audioProps} backgroundColor="white" strokeColor="#0C1E47" > </AudioAnalyser>
                            {RecordingFile ? <span className="voice_upload_text">File: {RecordingFile}</span> : ''}

                        </div>
                    </div>
                    {RecordingError ? <div className="voice_device_error">
                        To record voice messages, Workfreeli needs access to your microphone.
                    </div> : ''}

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ marginTop: '16px' }} onClick={close_popup} className="btnCancel">Cancel</button>
                        <button className={classNames("voiceContinue", RecordingSrc ? 'active' : 'inactive')} onClick={continueToUpload}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoiceMessage;

