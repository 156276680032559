import { RouterProvider } from "react-router-dom"
import { ComponentProvier } from './ComponentProvier'
import './App.css';
import './Call.css';
import './Stylesheets/report.css'
import './Components/newchat.css'
import './Components/Maintenance/maintenance.css'
import './Stylesheets/Admin.css'
import './Stylesheets/TaskReport.css'
import './Stylesheets/tasks/Calendar.css'
import './Stylesheets/tasks/quickTask.css'
import './Stylesheets/tasks/taskmain.css'
import './Stylesheets/tasks/taskmanagement.css'
import './Stylesheets/tasks/TaskPartial.css'
import './Stylesheets/tasks/tasks.css'
import './Stylesheets/tasks/taskSettings.css'
import './Stylesheets/Calendar.css'
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css';
import './Stylesheets/venue.css';
import './Stylesheets/billingsubscription.css';
import './Stylesheets/TagFile.css'
// import { Suspense } from "react";
import React, { Suspense, useEffect, useState } from 'react';
import MicrositeDown from "./MicrositeDown ";
// import MicrositeDown from './MicrositeDown'




function App() {
  //for max device width
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {isMobile ? <MicrositeDown /> :
        <Suspense>
          <RouterProvider router={ComponentProvier} />
        </Suspense>
      }
    </>

  );
}

export default App;
