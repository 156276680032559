/* eslint-disable no-unused-vars */
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import Moment from 'react-moment';
import 'moment-timezone';
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";
import { fileExtension, getONLYUSERSDATA } from "../../../Utils/Common";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import PerMsgEmoji from "./PerMsgEmoji";
import WarningPopup from "../WarningPopup";
import { useMutation, useApolloClient } from "@apollo/client";
import { ATTACHMENT_STAR_MUTATION, Flagged_UnFlagged_Mutation } from "../../../Utils/GraphqlMutaion";
import { useDispatch, useSelector } from "react-redux";
import { update_messages, set_downloading_progress, set_downloading_file, set_share_link_popup, set_action_file } from "../../../redux/message";
import { EmojiContainer } from "./EmojiContainer";
import ImgPreviewPopup from "./ImgPreviewPopup";
import Axios from "axios";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { setPopup, set_global_action_data } from "../../../redux/message";
import EditMsg from "./EditMsg";
import { Get_My_Counter } from "../../../Utils/Common";
import { Link, useParams } from "react-router-dom";
// import UserMiniProfile from "../UserMiniProfile";
import React from "react";
import QuickView from "../QuickView";
import { Tooltip } from "react-tooltip";
import { FaCheck, FaLock } from "react-icons/fa6";
import { TbArrowForward, TbFlag3Filled } from "react-icons/tb";
import { IoCopyOutline } from "react-icons/io5";
import { BsEmojiSmileFill, BsThreeDots } from "react-icons/bs";
import { LuForward } from "react-icons/lu";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import MessageReplyIcon from "../../../media/images/png/thread-icon.png";
import AiReplyIcon from "../../../media/images/gpt_blue.svg";
import LinkImage from "../../../media/images/link-svgrepo-com.svg";
import { update_active_discussion_msg } from "../../../redux/taskSlice";
import { GET_ME } from '../../../Utils/GraphqlQueries';
import ReactPlayer from 'react-player';
import { FaTasks } from "react-icons/fa";

const FileDownload = require('js-file-download');

const DeleteText = () => {
    return (
        <p className="!text-left px-3">Are you sure you want to delete this message? This cannot be <span className="undone">undone</span></p>
    )
}

const MessagesContainer = React.memo(function MessagesContainer({ data: v, room, me: user, setDeleteMsgIndex, thread, edit_msg_id, all_users, all_msgs, filterHead, seperator, is_reply_head, quickMsg = false, is_group_msg = false, type = '' }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const CountersData = useSelector(state => state.message.countersdata);
    const downloading_progress = useSelector(state => state.message.downloading_progress);
    const [preview_data, setPreview_data] = useState(null);
    const [preview_all_data, setPreview_all_data] = useState(null);
    const [updateFlagUnFlag] = useMutation(Flagged_UnFlagged_Mutation)
    const params = useParams();
    const messagesEndRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const copyToClipBoard = (v) => {
        let msgBody = sanitizeHtml(v?.msg_body.replace(/```html|```/g, '').trim(), {
            allowedTags: [],
            allowedAttributes: {},
            allowedIframeHostnames: [],
        });
        navigator.clipboard.writeText(msgBody);
        toast.success('Already Copied')
    };

    function convertHTMLEntity(text) {
        const span = document.createElement('span');

        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    const flag_action = async (m) => {
        updateFlagUnFlag({
            variables: {
                input: {
                    conversation_id: m.conversation_id,
                    msg_id: m.msg_id,
                    is_add: m?.has_flagged?.indexOf(user?.id) > -1 ? 'no' : 'yes'
                }
            }

        });
        dispatch(
            update_messages({
                conversation_id: m.conversation_id,
                msg_id: m.msg_id,
                fields: {
                    has_flagged: m?.has_flagged?.indexOf(user?.id) > -1 ? [] : [user.id]
                },
                is_reply_msg: thread ? 'yes' : 'no',
                msg_type: m.msg_type
            })
        )
    }
    const [downloading_list, set_downloading_list] = useState({});
    const AfileDownload = (url, filename) => {
        console.log(url, filename);

        dispatch(set_downloading_file([filename]))
        if (downloading_progress[filename]) return
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
                dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
            }
        }).then((response) => {
            FileDownload(response.data, filename);
            let d_progress = { ...downloading_progress };
            d_progress = { ...(delete d_progress[filename] && d_progress) }
            dispatch(set_downloading_progress(d_progress))
        });
    }

    const downloadMsgFiles = (files) => {
        let fdata = []
        files.map(v => {
            fdata = [...fdata, { originalname: v.originalname, url: v.location }]
            return false
        })
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "";
        if (fdata.length === 1) {
            AfileDownload(fdata[0].url, fdata[0].originalname);
        } else {
            // zipFilename = active_conv.details.title + "_" + new Date().getTime() + ".zip";
            zipFilename = "Message_" + new Date().getTime() + ".zip"
            dispatch(set_downloading_file([zipFilename]));
            let file_list = {};
            fdata.forEach((file) => file_list[file.originalname] = '0%');
            set_downloading_list({ ...downloading_list, ...file_list })
            fdata.forEach(function (item) {
                var filename = item.originalname;

                JSZipUtils.getBinaryContent(item.url, {
                    progress: function (e) {
                        set_downloading_list({ ...downloading_list, [filename]: parseInt(e.percent) + "%" })
                    },
                    callback: function (err, data) {
                        if (err) {
                            throw err; // or handle the error
                        }
                        zip.file(filename, data, { binary: true });
                        let dl = { ...downloading_list };
                        delete dl[filename];
                        set_downloading_list({ ...downloading_list, ...dl });

                        count++;
                        if (count === fdata.length) {
                            zip.generateAsync({ type: 'blob' }).then(function (content) {
                                saveAs(content, zipFilename);
                                set_downloading_list({});
                                // if (Object.keys(downloading_progress).length === 0) {
                                //     props.setPopup({ type: 'downloadingPopup', data: false });
                                // }
                            });
                        }
                    }
                });
            });
        }
    }

    useEffect(() => {
        dispatch(set_downloading_progress({ ...downloading_progress, ...downloading_list }))
    }, [downloading_list])

    const [starMutation] = useMutation(ATTACHMENT_STAR_MUTATION);
    const starMarkFile = (file, msg) => {
        starMutation({
            variables: {
                input: {
                    file_id: file.id,
                    is_reply_msg: msg.is_reply_msg
                }
            }
        })
        if (msg.msg_type.indexOf('discussion') > -1) {
            dispatch(update_active_discussion_msg({
                conversation_id: msg.conversation_id,
                msg_id: msg.msg_id,
                fields: {
                    all_attachment: msg.all_attachment.map(f => f.id === file.id ? { ...f, star: f.star.indexOf(user?.id) > -1 ? [] : [user?.id] } : f)
                },
                is_reply_msg: msg.is_reply_msg,
                msg_type: msg.msg_type
            }))
        } else {
            dispatch(update_messages({
                conversation_id: msg.conversation_id,
                msg_id: msg.msg_id,
                fields: {
                    all_attachment: msg.all_attachment.map(f => f.id === file.id ? { ...f, star: f.star.indexOf(user?.id) > -1 ? [] : [user?.id] } : f)
                },
                is_reply_msg: msg.is_reply_msg,
                msg_type: msg.msg_type
            }))
        }
    }

    const [msgDeletePopup, setMsgDeletePopup] = useState(false);
    const [file_delete, setFile_delete] = useState(false)

    const openDelete = (msg) => {
        if (msg) {
            if (msg.all_attachment.length > 1) {
                setFile_delete(true)
            } else {
                setMsgDeletePopup(true)
            }
        } else {
            setFile_delete(true)
        }
    }
    const [action_file, setaction_file] = useState(null)
    const handleFileAction = (type, _f, msg) => {
        dispatch(set_action_file(_f))
        setaction_file(_f)
        if (type === 'star') {
            starMarkFile(_f, msg)
        } else if (type === 'tag') {
            dispatch(setPopup({ data: true, key: 'tag_popup' }));
        } else if (type === 'download') {
            AfileDownload(_f.location, _f.originalname)
        } else if (type === 'forward') {
            handleMsgAction('file_forward', msg, _f)
        } else if (type === 'share') {
            dispatch(set_share_link_popup(true))
        } else if (type === 'delete') {
            openDelete(msg)
        }
    }

    const handleMsgAction = (type, msg, extra) => {
        if (type === 'title') {
            dispatch(set_global_action_data({ msg: msg, type: 'msg' }));
            dispatch(setPopup({ data: true, key: 'custom_title_popup' }));
        } else if (type === 'msg_url_title') {
            dispatch(set_global_action_data({ msg: msg, type: 'msg_url_title' }));
            dispatch(setPopup({ data: true, key: 'custom_title_popup' }));
        } else if (type === 'reply') {
            navigate(`reply/${msg.msg_id}`)
        } else if (type === 'download') {
            downloadMsgFiles(msg.all_attachment)
        } else if (type === 'edit') {
            setEditMsg(true)
        } else if (type === 'quick') {
            //     dispatch(set_selected_main_msg(msg))
            //     dispatch(set_private_quickView(true))
        } else if (type === 'edit_participant') {
            dispatch(set_global_action_data({ type: 'private_msg_update', msg: msg }))
            dispatch(setPopup({ data: true, key: 'private_msg' }));
        } else if (type === 'copy') {
            copyToClipBoard(msg)
        } else if (type === 'forward') {
            dispatch(set_global_action_data({ msg: msg, forward_type: 'msg' }));
            dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
        } else if (type === 'file_forward') {
            dispatch(set_global_action_data({ msg: msg, file: extra, forward_type: 'file' }));
            dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
        } else if (type === 'delete') {
            setMsgDeletePopup(true)
            if (setDeleteMsgIndex) setDeleteMsgIndex(true)
        }
    }

    const getThumImage = (l) => {
        let ar = l.split('/');
        let n = 'thumb-' + ar[ar.length - 1];
        ar[ar.length - 1] = n;
        return ar.join('/');
    }

    function niceBytes(x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    const [editMsg, setEditMsg] = useState(false);
    const [is_show, setIs_show] = useState(false);
    let is_noti = v.msg_type?.indexOf('log_notify') > -1;
    let is_secret = quickMsg ? false : v.is_secret;
    let is_task = v.msg_type?.includes('task_msg')
    let task_noti = v.msg_type?.includes('task_notification')
    let is_AI = v.msg_type?.includes('aiReplyReq')

    const [perMsgEmoji, setPerMsgEmoji] = useState(false);
    const checkUserImage = () => {
        let hasImage = (v?.senderimg?.indexOf('img.png') > -1) ? false : true;
        return (
            hasImage ?
                <img src={v?.senderimg} width={41} height={41} loading="lazy" alt='sender' title="sender" />
                :
                <h3 className="nameLetters">{v?.sendername?.charAt(0)}</h3>
        )
    }

    const handleDelPopup = () => {
        setMsgDeletePopup(false);
        setFile_delete(false);
    }

    const file_action = (msg, _f) => {
        if (_f.file_type.indexOf('image') > -1) {
            setPreview_data(_f)
            setPreview_all_data(msg.all_attachment.filter((i) => i.file_type.indexOf('image') > -1))
        } else {
            localStorage.setItem('file_view', JSON.stringify(_f));
            window.open('/file', "_blank")
        }
        // else if (_f.file_type.indexOf('pdf') > -1) {
        // } else if (_f.file_type.indexOf('application') > -1 && _f.file_type.indexOf('pdf') === -1) {
        // } else if (_f.file_type.indexOf('video') > -1 || _f.file_type.indexOf('audio') > -1) {
        // }
    }


    const [quickViewPopup, setQuickViewPopup] = useState(false);
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [moreOptions, setMoreOptions] = useState(false);

    // const leftSideMessageStyle = v?.sender === user?.id ? 'myArea' : 'userArea';
    // const leftSideMessageStyle = v?.sender === user?.id ? '' : 'flex-row-reverse';

    function getImageNameFromUrl(url) {
        // Extract the last part of the URL after the last '/'
        const lastSegment = url.split('/').pop();
        // Remove query parameters or fragments
        const imageName = lastSegment.split('?')[0].split('#')[0];
        return imageName;
    }

    const handleClickOnMsg = (e) => {

        if (e.target.classList.contains('editTpenLink')) {
            const link = v.link_data.filter(l => l.url_id === e.target.dataset.id)?.[0]
            handleMsgAction('msg_url_title', link)
        }

        // if (event.target.classList.contains("genarated_img")) {
        // 	console.log("Image clicked");
        // 	// Set the image source and open the preview
        // 	if (props.data.img_url) {
        // 		props.setpreviewImageSrc(props.data.img_url);
        // 		props.setPopup({ type: 'showAiPopup', data: true });

        // 	}
        // }

        if (e.target.classList.contains("download_ai_img")) {
            console.log("Image Download clicked", v);
            if (v.img_url) {
                let imageName = getImageNameFromUrl(v.img_url);
                AfileDownload(v.img_url, imageName);
            }
        }

        if (e.target.classList.contains("read-more")) {
            setIsExpanded(true);
        } else if (e.target.classList.contains("read-less")) {
            setIsExpanded(false);
        }
    }

    function isMentionPattern(str) {
        const mentionPattern = /^#mention_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return mentionPattern.test(str);
    }

    function removeHTMLTags(str) {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    
    const returnLinkDesign = (_msg) => {
        if (!_msg?.msg_body?.length) return null;
        const _body = _msg.msg_body.replace(/```html|```/g, '')?.trim()
        const msg_body =
        removeHTMLTags(v.msg_body).length > 300 ?
        isExpanded
        ? `${_body} <span class="text-blue-500 cursor-pointer hover:underline read-less">Read less</span>`
        : `${_body.substring(0, 300)} <span class="text-blue-500 cursor-pointer hover:underline read-more">... Read more</span>`
        : _body;
        // Convert message to string and sanitize unnecessary tags
        const msg_body1 = msg_body.toString();
        const sanitizedBody = convertHTMLEntity(msg_body1.replace(/<p dir="ltr">/gi, ''));
        
        // Function to wrap links with custom design or remove target for mentions
        const wrapLinkWithDesign = (match, href, text) => {
            // Check if it's a mention
            const isMention = match.includes('#mention_');

            // Remove target attribute if it's a mention
            if (isMention) {
                if (isMentionPattern(href)) {
                    return match.replace(/target=["']_blank["']\s?/gi, '');
                } else {
                    return `<a href="${href}" class="wysiwyg-mention disabled-link" data-mention="" data-value="MARUF HASAN">@Everyone</a>`;
                }
            }

            // Check if the link matches any `link_data` for custom design
            let matchedLink = _msg?.link_data?.find((u) => u.url === href);
            if (matchedLink) {

                return `
                    <div class="p-2.5 mt-1 mb-1 mx-3 group rounded-md border border-[#318fff]">
                        <div class="msg_link_container flex flex-col">
                            <div class="flex gap-1 msg_title_link LinkBar" >
                                <img src="${LinkImage}" class="w-[18px] h-[18px]"></img>
                                <p class="add_link_title !text-sm" data-id="${matchedLink.url_id}" data-title="${matchedLink.title || ''}">${matchedLink.title || 'Write a title'}</p>
                                <span class="editTpenLink text-[#318fff] cursor-pointer" style="${matchedLink.user_id !== user.id ? 'display: none; cursor: pointer;' : 'cursor: pointer;'}" data-id="${matchedLink.url_id}"/>
                            </div>
                            <div>
                                <a class="msg_link_url" target="_blank" href="${matchedLink.url}">${text}</a>
                            </div>
                        </div>
                    </div>
                `;
            }

            // If no custom design is needed, return the original link
            return match;
        };

        // Match links using regex and replace with custom design or remove target for mentions
        // const linkRegex = /<a\s+(?:[^>]*?href=["']([^"']*)["'][^>]*?)>(.*?)<\/a>/gi;
        const linkRegex = /<a\s+(?:[^>]*?href=["'](https?:\/\/(?:www\.)?[^"']*)["'][^>]*?)>(.*?)<\/a>/gi;
        let styledResult = sanitizedBody.replace(linkRegex, (match, href, text) => {
            return wrapLinkWithDesign(match, href, text);
        });

        // Handle line breaks for consistent rendering
        styledResult = styledResult.replace(/(<br\s*\/?>)+/gi, '<br>');

        // Emoji replacement (optional)
        const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b]|\u23e9-\u23fa)/gim;
        styledResult = styledResult.replace(emojiRegex, (match) => {
            return `<span class="newemoji" style="font-size:14px;">${match}</span>`;
        });

        return styledResult;
    };



    const maxLength = 300;

    const truncateContent = (content, maxLength) => {
        const div = document.createElement("div");
        div.innerHTML = content.msg_body;

        let truncated = "";
        let length = 0;

        Array.from(div.childNodes).forEach((node) => {
            if (length >= maxLength) return;

            if (node.nodeType === Node.TEXT_NODE) {
                // Handle text nodes
                if (length + node.textContent.length > maxLength) {
                    truncated += node.textContent.substring(0, maxLength - length);
                } else {
                    truncated += node.textContent;
                }
                length += node.textContent.length;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                // Handle element nodes (e.g., links)
                const innerTextLength = node.textContent.length;
                if (length + innerTextLength > maxLength) {
                    if (length < maxLength) {
                        truncated += node.outerHTML; // Include the full link if possible
                    }
                } else {
                    truncated += node.outerHTML;
                }
                length += innerTextLength;
            }
        });

        return { ...content, msg_body: truncated };
    };



    const isContentTruncated = removeHTMLTags(v.msg_body).length > maxLength;

    const processedContent = returnLinkDesign(v);
    // isContentTruncated ? isExpanded
    //     ? `${returnLinkDesign(v)} <span class="text-blue-500 cursor-pointer hover:underline read-less">Read less</span>`
    //     : `${returnLinkDesign(truncateContent(v, maxLength))} <span class="text-blue-500 cursor-pointer hover:underline read-more">... Read more</span>`
    //     : returnLinkDesign(v); // No truncation needed for short content

    // const geparts = (content) => {
    //     const regex = /([\s\S]*?)<pre><code>([\s\S]*?)<\/code><\/pre>/g;
    //     let parts = [];
    //     let lastIndex = 0;
    //     let match;

    //     while ((match = regex.exec(content)) !== null) {
    //         const [fullMatch, before, code] = match;
    //         if (before.trim()) {
    //             parts.push({ type: "text", content: before });
    //         }
    //         parts.push({ type: "code", content: code });
    //         lastIndex = regex.lastIndex;
    //     }
    //     if (lastIndex < content.length) {
    //         parts.push({ type: "text", content: content.substring(lastIndex) });
    //     }
    //     return parts;
    // };

    // const parts = geparts(processedContent);


    const viewUser = () => {
        if (v.sender !== "c4439ad9-67b3-4414-a5a9-87a0d0153c58") {
            navigate('#mention_' + v.sender)
        }
    }

    return (
        (!is_task || (is_task && (v.task_data?.created_by === user.id || v.task_data?.assign_to?.includes(user.id) || v.task_data?.observers?.includes(user.id)))) &&
        <>
            {seperator !== '' && <div style={filterHead && { marginTop: '20px' }} className="msgSeparator" ><p className="z-10">{seperator}</p></div>}
            <div className={`user_msg w-full ${is_task && !thread ? "taskViewBabble" : ""}  ${quickMsg && v?.all_attachment?.length ? '!px-7 overflow-auto !h-[calc(100vh-370px)]' : 'h-auto pr-[160px]'} ${v?.sender === user?.id ? 'userArea' : 'userArea'} ${type === "discussion" ? '!pl-[20px] !pr-[20px]' : ''}`}>
                <div className="msg_infoo w-full">
                    {!is_group_msg &&
                        <div className="mag_inner !gap-[10px]">
                            <div className="sender_img nameL ">
                                {(user.id === v.sender ?
                                    <Link to="/connect/profile" title="profile">
                                        <div className="sender_img nameL !mr-0">{checkUserImage(0)}</div>
                                    </Link>
                                    :
                                    <div onClick={viewUser} className="sender_img nameL !mr-0">{checkUserImage(0)}</div>
                                )}
                            </div>
                            <div className="sender_info_chat">
                                {
                                    user.id === v.sender ?
                                        <div className={`userProfileDiv ${is_task ? "changeNameLabel" : ""}`} >
                                            <Link
                                                to="/connect/profile"
                                                className={`sender_name mr-2`}>
                                                {quickMsg ? v.sendername.slice(0, 20) : v.sendername} {quickMsg && v.sendername.length > 20 && "..."}
                                            </Link>
                                            {is_task &&
                                                <div className="taskCreatedLabel">
                                                    <div className="labelTesk">Task created</div>
                                                    <div className="onlyAssigneeLable">
                                                        <FaLock className="text-[10px] darkColor text-[#424242]" />

                                                        Only assignee, observers, and you can see this task.
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        : <p onClick={viewUser} className="sender_name mr-2">{v.sendername}</p>
                                }
                                <div className={`${v?.sender === user.id ? 'sender_info_myArea !justify-start' : 'sender_info_area_userArea'}`}>
                                    <p className="msg_time">
                                        <time dateTime="2024-02-25T10:30:00Z"><Moment format="h:mm A">{v?.created_at}</Moment>  - </time>
                                    </p>
                                    <p className="is_delivered">Delivered</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={`single_user_msg group`}>
                        <div className={`flex relative justify-end items-center gapp-2 flex-row-reverse ${v?.sender === user?.id ? 'flex-row-reverse' : 'flex-row-reverse'}`}>
                            {!quickMsg ?
                                <>
                                    {(!editMsg && params.filter_name !== 'flag' && !v.has_delete?.includes(user?.id)) &&
                                        <div className={`msgHoverArea ww-[40%] !bg-white justify-start !right-[0px] !top-[5px] absolute ${v?.sender === user?.id ? 'flex justify-endd' : 'flex justify-startt'}`}>
                                            <div className="hidden !bg-white group-hover:block relative">
                                                {(!is_noti && !quickMsg && !is_secret && !is_task && v.msg_type !== 'call') &&
                                                    <div className={`fullHoverDiv flex rounded-lg border !bg-white border-[#e3f2ff]`}>
                                                        <div className={`flex items-center p-1 px-2 gap-2 ${v?.sender === user?.id ? 'border-r border-r-[#e3f2ff]' : 'border-l border-l-[#e3f2ff]'}`}>
                                                            <p className="text-[#5b6477] timeDark text-sm w-[65px]">
                                                                <Moment format="h:mm A">{v.created_at}</Moment>
                                                            </p>
                                                            <FaCheck className="text-[#a6d068] text-sm" />
                                                        </div>

                                                        {(!is_noti && !quickMsg && !thread && !is_task && !task_noti) &&
                                                            <div id={"reply" + v.msg_id} className={`p-1 cursor-pointer ${v?.sender === user?.id ? 'border-r border-r-[#e3f2ff]' : 'border-l border-l-[#e3f2ff]'}`} onClick={() => handleMsgAction('reply', v)}>
                                                                <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white text-[#032E84] hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                    <TbArrowForward className="text-[18px] rotate-180" />
                                                                </div>
                                                            </div>
                                                        }

                                                        {(!is_task && !task_noti) &&
                                                            <div className={`p-1 !pr-1 flex items-center text-[#032E84]`}>
                                                                {(!is_noti && !quickMsg && !thread) &&
                                                                    <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white text-[#032E84] hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                        <TbFlag3Filled
                                                                            id={"flag" + v.msg_id}
                                                                            onClick={() => flag_action(v)}
                                                                            className={`text-base cursor-pointer  ${v?.has_flagged?.indexOf(user?.id) > -1 && 'text-red-600'}`} />
                                                                    </div>
                                                                }
                                                                {
                                                                    (v.sender === user?.id && room?.archive !== 'yes' && type !== 'discussion') &&
                                                                    <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white text-[#032E84] hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                        <FaRegEdit onClick={() => handleMsgAction('title', v)} id={"title" + v.msg_id} className="text-[14px]" />
                                                                    </div>
                                                                }
                                                                <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white text-[#032E84] hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                    <IoCopyOutline onClick={() => handleMsgAction('copy', v)} id={"copy" + v.msg_id} className="text-[14px]" />
                                                                </div>

                                                                {type !== 'discussion' &&
                                                                    <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                        <BsEmojiSmileFill onClick={() => setPerMsgEmoji(true)} style={perMsgEmoji ? { pointerEvents: 'none' } : { display: 'block' }} id={"emoji" + v.msg_id} className="text-[14px]" />
                                                                    </div>
                                                                }

                                                                {type !== 'discussion' && !is_AI &&
                                                                    <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                        <LuForward onClick={() => handleMsgAction('forward', v)} id={"forward" + v.msg_id} className="text-xl" />
                                                                    </div>
                                                                }

                                                                {
                                                                    (!v.msg_type?.includes('link') && !is_AI) &&
                                                                    <>
                                                                        {(v.sender === user?.id && !thread && room?.archive !== 'yes') &&
                                                                            <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#032E84] rounded-full optionsIconDark">
                                                                                <AiOutlineEdit onClick={() => setEditMsg(prev => !prev)} id={"edit" + v.msg_id} className="text-base" />
                                                                            </div>
                                                                        }
                                                                    </>

                                                                }

                                                                {(is_AI && thread) ? '' :
                                                                    <div className="h-5 w-7 flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#032E84] rounded-full text-[#afdaff] optionsIconDark">
                                                                        <BsThreeDots onClick={() => setMoreOptions(!moreOptions)} id={"more" + v.msg_id} className="text-base" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    moreOptions &&
                                                    <div className="">
                                                        <ul className="msgMorePopup reverse !z-0" onClick={() => handleMsgAction('delete', v)}>
                                                            <li className="msgOpts deleteMsgOpt"> Delete message </li>
                                                        </ul>
                                                    </div>
                                                }
                                                {perMsgEmoji && <PerMsgEmoji msg={v} setPerMsgEmoji={setPerMsgEmoji} />}
                                            </div>
                                        </div>
                                    }
                                </> : ""
                            }

                            <div style={quickMsg ? { width: '100%' } : {}} className={`float-left ${quickMsg ? 'w-full px-0' : ''} ${v.all_attachment.length === 1 || v.task_id ? 'w-full' : v.all_attachment.length === 0 ? "max-w-[100%]" : v.msg_type.indexOf('link_data') === -1 ? "w-full" : "max-w-[100%] w-full"}`}>
                                {/* For deleted message */}
                                {(v.has_delete?.includes(user?.id)) &&
                                    <div className={`msg_body msgBodyArea group`}>
                                        <div className='msg_txt !text-sm group-hover:bg-[#f5f9fc]'>
                                            {'You deleted this message'}
                                        </div>
                                    </div>
                                }
                                {(!v.has_delete?.includes(user?.id)) &&
                                    <>
                                        {editMsg && <div className='msg_body msgBodyArea group'><EditMsg data={v} setEditMsg={setEditMsg} room={room} /></div>}
                                        {!is_task &&
                                            <div className='msg_body msgBodyArea group'>
                                                {is_secret && thread ?
                                                    "" :
                                                    <>
                                                        {v.url_base_title && v.url_base_title !== '' &&
                                                            <p className="titleBar">
                                                                <span className="msgLinkTitle"> {v.url_base_title} </span>
                                                                {(!quickMsg && v.sender === user.id) && <span onClick={() => handleMsgAction('title', v)} className="editTpen" id={"addEdit" + v.msg_id}></span>}
                                                            </p>
                                                        }
                                                    </>
                                                }

                                                {/* Private view */}
                                                {(is_secret && !thread && params.filter_name !== 'private') &&
                                                    <div className="privateMsgContainer">
                                                        <span>Private message to </span>
                                                        <span style={{ paddingLeft: '5px', fontWeight: '500', lineHeight: '26px' }}>{getONLYUSERSDATA(all_users, v.secret_user, 'name').join(', ')}</span>
                                                        <span className="privateMsgV" onClick={() => setQuickViewPopup('quick', true, v)}>Quick view</span>
                                                        <span className="privateMsgV" onClick={() => handleMsgAction('reply', v)}>View & reply</span>
                                                        {v.sender === user?.id && <span className="privateMsgV" onClick={() => handleMsgAction('edit_participant', v)} data-for="rightSection_tooltip" data-tip="Click to update the participants"> Add / Edit </span>}
                                                        <span onClick={() => handleMsgAction('delete', v)} className="privateMsgV" data-for="rightSection_tooltip" data-tip="Click to delete"> Delete </span>
                                                    </div>
                                                }

                                                {
                                                    (is_secret && (thread || params.filter_name === 'private')) &&
                                                    <div className="flex justify-start flex-wrap">
                                                        <ul className="secrectUserName">
                                                            {getONLYUSERSDATA(all_users, v.secret_user, 'name').map(u =>
                                                                <li key={u}>{u}</li>
                                                            )}
                                                        </ul>
                                                        {(!thread && v.sender === user?.id) &&
                                                            <button onClick={() => handleMsgAction('edit_participant', v)} className="h-[22px]  text-[12px] rounded-[5px] pt-[2px] mt-[2px] px-2 withTooltip" style={{ backgroundColor: 'rgb(9, 38, 106)', color: 'white' }}>
                                                                <i className="fas fa-users" style={{ backgroundColor: 'rgb(9, 38, 106)', color: 'white' }}></i> Add / Edit
                                                            </button>
                                                        }
                                                    </div>
                                                }

                                                {/* Main Msg text */}
                                                {/* {!editMsg &&
                                                <>
                                                    {(!is_secret || (is_secret && (thread || params.filter_name === 'private'))) && <div className="msg_txt msg_text_bg !text-sm darkTextWhite group-hover:!bg-[#f5f9fc] !bg-[#F3F4F6]" dangerouslySetInnerHTML={{ __html: returnLinkDesign(v.msg_body) }} />}
                                                </>
                                            } */}
                                                <>
                                                    {(!is_secret || (is_secret && (thread || params.filter_name === "private"))) && (
                                                        // <div className="msg_txt msg_text_bg !text-sm darkTextWhite group-hover:!bg-[#d9dde0] !bg-[#e8eaec]"
                                                        //     onClick={(e) => {
                                                        //         // Handle "Read More" and "Read Less" clicks
                                                        //         if (e.target.classList.contains("read-more")) {
                                                        //             setIsExpanded(true);
                                                        //         } else if (e.target.classList.contains("read-less")) {
                                                        //             setIsExpanded(false);
                                                        //         }
                                                        //     }}

                                                        //     dangerouslySetInnerHTML={{
                                                        //         __html: v.msg_body.length > maxLength
                                                        //             ? isExpanded
                                                        //                 ? returnLinkDesign(`${v.msg_body} <span class="text-blue-500 cursor-pointer hover:underline read-less">Read less</span>`)
                                                        //                 : returnLinkDesign(`${v.msg_body.substring(0, maxLength)} <span class="text-blue-500 cursor-pointer hover:underline read-more">... Read more</span>`)
                                                        //             : returnLinkDesign(v.msg_body), // Render full content if less than maxLength
                                                        //     }}
                                                        // />
                                                        // nayeem vai
                                                        <div className={`msg_txt msg_text_bg !text-sm darkTextWhite group-hover:!bg-[#d9dde0] !bg-[#e8eaec]`}
                                                            onClick={handleClickOnMsg}
                                                            dangerouslySetInnerHTML={{ __html: processedContent }}
                                                        />

                                                        // rajon
                                                        // <div
                                                        //     className="aiwidth msg_txt msg_text_bg !text-sm darkTextWhite group-hover:!bg-[#e0e1e4] !bg-[#e8eaec] "
                                                        //     onClick={handleClickOnMsg}
                                                        // >
                                                        //     {parts.map((part, index) =>
                                                        //         part.type === "text" ? (
                                                        //             <div key={index} dangerouslySetInnerHTML={{ __html: part.content }} />
                                                        //         ) : (
                                                        //             <SyntaxHighlighter key={index} language="javascript" style={solarizedlight}>
                                                        //                 {part.content.trim()}
                                                        //             </SyntaxHighlighter>
                                                        //         )
                                                        //     )}
                                                        // </div>
                                                    )}
                                                </>

                                                {/* Edited Message */}

                                                {!thread &&
                                                    <>
                                                        {(v.edit_history && v.edit_history !== '' && Array.isArray(v.edit_history) /* && props.edit_msg_id !== v.msg_id*/) &&
                                                            <div className={"show_edited_msg " + is_show}>
                                                                <span className="show_more_reply" onClick={() => setIs_show(!is_show)}>{is_show ? 'Click to collapse edit trail' : 'Click to view edit trail'}</span>
                                                                {v.edit_history.map((m, i) => <div className="edit_history_msg" key={i} dangerouslySetInnerHTML={{ __html: returnLinkDesign(m) }} />)}
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                {/*  */}
                                                {/* Attachments */}
                                                {v.all_attachment && v.all_attachment.length > 0 && (!is_secret || (is_secret && is_reply_head) || (is_secret && params.filter_name === 'private')) &&
                                                    <div className={` ${quickMsg ? "quickMsg_forView" : ""} ${v.all_attachment.length === 1 ? " max-w-[50%]" : " w-full"} darkMode w-full rounded-md py-[5px]`}>
                                                        <div className={`grid ${type === "discussion" ? '!grid-cols-1' : ''} ${v.all_attachment.length < 2 ? "grid-cols-1" : v.all_attachment.length < 3 ? "grid-cols-2" : quickMsg ? "grid-cols-1" : "grid-cols-3"} gap-2`}>
                                                            {
                                                                v.all_attachment.slice(0, showAllFiles ? v.all_attachment.length : 5).map((f, i) =>
                                                                    <div className="multi_valuee single_file !m-0 bg-white rounded-lg" key={'msg_file_' + i}>
                                                                        {(f.star && f.star?.indexOf(user?.id) > -1) || f.url_short_id ?
                                                                            <span className="fileActiveActions">
                                                                                {f.star !== null && f.star?.indexOf(user?.id) > -1 && <span className='starredIcon'></span>}
                                                                                {f.url_short_id !== null && f.url_short_id !== '' && <span className='sharedIcon'></span>}
                                                                            </span>
                                                                            : ''}

                                                                        {v.sender_is_active !== 0 && !is_secret &&
                                                                            <div className="file_hover_option !z-0">
                                                                                <div onClick={() => handleFileAction('star', f, v)} id={"starred" + v.msg_id} className={f.star && f.star?.indexOf(user?.id) > -1 ? 'fileOpts star_opts active' : 'fileOpts star_opts'}></div>
                                                                                <div onClick={() => handleFileAction('tag', f, v)} className="fileOpts tag_opts" id={"addTags" + v.msg_id}></div>
                                                                                <div onClick={() => handleFileAction('download', f, v)} id={"download" + v.msg_id} className="fileOpts download_opts" data-name={f.originalname} data-link={f.location}></div>
                                                                                {type !== 'discussion' && <div id={"forward" + v.msg_id} onClick={() => handleFileAction('forward', f, v)} className="fileOpts forward_opts"></div>}
                                                                                {type !== 'discussion' && <div onClick={() => handleFileAction('share', f, v)} style={user?.id === f.user_id ? {} : { pointerEvents: 'none', opacity: '0.5' }} id={"share" + v.msg_id} className="fileOpts share_opts"></div>}
                                                                                <div onClick={() => handleFileAction('delete', f, v)} style={user?.id === f.user_id ? {} : { pointerEvents: 'none', opacity: '0.5' }} id={"delete" + v.msg_id} className="fileOpts delete_opts"></div>
                                                                            </div>
                                                                        }
                                                                        <div className="flex items-center gap-2 px-[15px] pt-[15px] pb-[3px]" onClick={() => file_action(v, f)}>
                                                                            <div className="img_holderr cursor-pointer !w-[22.8px] !h-[30px]">
                                                                                {f.location.includes('blob:') ?
                                                                                    <img src={f.location} className="!w-max-[100%] !h-max-[100%] object-fill rounded-md" loading='lazy' alt="img" />
                                                                                    :
                                                                                    <img src={
                                                                                        f.file_type?.indexOf('image') > -1 && f.file_type?.indexOf('svg') === -1 ?
                                                                                            getThumImage(f.location)
                                                                                            : f.file_category?.indexOf('voice') > -1 ?
                                                                                                `/media/images/light_theme/voice.svg`
                                                                                                : `/media/images/light_theme/${fileExtension(f?.originalname)}.svg`
                                                                                    } loading='lazy' alt="file ext" title="sender" className="file_ext !w-[100%] !h-[100%] object-fill rounded-md" />
                                                                                }
                                                                            </div>
                                                                            <div className="w-[83%] cursor-pointer">
                                                                                <p className={` ${v.all_attachment.length > 1 ? "w-[calc(100% - 80px)]" : "w-full"} text-left text-[12px] !text-[#0b1f47] whitespace-break-spaces`} title={f.originalname}>{f.originalname}</p>
                                                                                <p className="file_size  !text-[#5b6477] !text-[12px]">{niceBytes(f.file_size)}
                                                                                    {(f.reference_type || f.referenceId) &&
                                                                                        <span className="fileRefHolder">
                                                                                            <span className="fileRef" style={{ backgroundColor: "#732BE2" }}> Ref. {f.reference_type}: {f.referenceId}</span>
                                                                                        </span>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {f.file_type.indexOf('audio') > -1 ?
                                                                            <div className={classNames((f.star !== null && f.star.indexOf(cacheMe.me.id) > -1) || f.url_short_id !== null ? 'playerWrapper' : '', 'audioPlyer')}>
                                                                                <ReactPlayer
                                                                                    onReady={() => console.log('')}
                                                                                    playing={false}
                                                                                    playsinline={true}
                                                                                    controls={true}
                                                                                    url={f.location}
                                                                                    width="100%"
                                                                                    height="50px"
                                                                                    style={{ display: "flow-root", float: "left", marginTop: "5px" }}
                                                                                />
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                        {f.tag_list_details && f.tag_list_details.length > 0 ?
                                                                            <div className="fileTagHolder dgergreg border-t border-solid border-[#d9d9d9]" style={is_reply_head === true ? { pointerEvents: 'none' } : {}}>
                                                                                {f.tag_list_details.filter(fi => (fi.tag_type === 'public' || (fi.tag_type === 'private' && fi.tagged_by === user?.id) || fi.tag_type === 'task') && fi.title !== 'UNTAGGED FILES').map((t, i) =>
                                                                                    (i < 2) ?
                                                                                        <span onClick={() => handleFileAction('tag', f, v)} 
                                                                                        style={{ backgroundColor: t.tag_type === 'public' ? '#032e84' : '#032e84' }} 
                                                                                        className={'fileTag  hover:!bg-[#318FFF]'} key={t.tag_id}>{t.title}</span>
                                                                                        : (i === 2) ?
                                                                                            <span style={{ backgroundColor: '#032e84' }} className={'fileTag !bg-[#318FFF] hover:!bg-[#032e84]'} key={t.tag_id} onClick={() => handleFileAction('tag', f, v)}> + {f.tag_list_details.filter(fi => fi.tag_type === 'public' || (fi.tag_type === 'private' && fi.tagged_by === user?.id)).length - 2}</span>
                                                                                            : null
                                                                                )}
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        {v.all_attachment.length > 5 &&
                                                            <div className="mt-2 w-full flex justify-center">
                                                                <button className="pt-[4px] font-bold attachFileMorePlus !text-[14px] text-[#0b1f47] hover:!text-white hover:!bg-[#318FFF]" onClick={() => setShowAllFiles(!showAllFiles)}> {showAllFiles ? 'Show less' : `+ ${v.all_attachment.length - 5} more`}</button>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {!thread &&
                                                    <>
                                                        {(!quickMsg && v.has_reply > 0) &&
                                                            <div className="divReplySection">
                                                                <div className="msgReplySection gap-1 -mt-[6px]" onClick={() => handleMsgAction('reply', v)}>
                                                                    <p className="relative leading-5">
                                                                        <span className="absolute">
                                                                        {is_task ?  <FaTasks className='tasks_notify_icon' size={18} color='#318fff' />
                                                                            :
                                                                            <img className="w-4 h-4 mt-1" src={is_AI ? AiReplyIcon : MessageReplyIcon} alt="reply" title="reply" />
                                                                           } 
                                                                        </span>
                                                                        <span className={classNames("rep_left ml-[20px]", Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) > 0 && 'unread')} data_length="1">View threaded chat {`(${Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) > 0 ? Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) + ' of ' : ''}${v.has_reply})`} </span>
                                                                        <span className="rep_mid">Last reply <Moment fromNow>{v.last_reply_time}</Moment> from  </span>

                                                                        <span className="rep_righ">{v.last_reply_name}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }


                                                <EmojiContainer msg_id={v.msg_id} has_emoji={v.has_emoji} />
                                                {/* {(!is_noti && !quickMsg && !thread) &&
                                                    <div className="msgFlagReplyOption">
                                                        <div className={`msgFlagBtn ${v?.has_flagged?.indexOf(user?.id) > -1 && 'flaged'}`} onClick={() => flag_action(v)}>{v?.has_flagged?.indexOf(user?.id) > -1 ? 'Flagged' : 'Flag'}</div>
                                                        <div className="msgReplyBtn" onClick={() => handleMsgAction('reply', v)} >Reply </div>
                                                    </div>
                                                } */}
                                            </div>
                                        }
                                        {(is_task && (v.task_data?.created_by === user.id || v.task_data?.assign_to?.includes(user.id) || v.task_data?.observers?.includes(user.id))) &&
                                            <div className='msg_body msgBodyArea group'>
                                                <Link to={v.task_data?._id} className='task_message_con'>
                                                    <div className='task_message_icon'>
                                                        <div className="form-group" id="taskm">
                                                            <input type="checkbox" id="html" checked readOnly />
                                                            <label htmlFor="html"></label>
                                                        </div>
                                                    </div>
                                                    <div className='task_message_label'>{v.task_data?.task_title}</div>
                                                </Link>

                                                {!thread &&
                                                    <>
                                                        {(!quickMsg && v.has_reply > 0) &&
                                                            <div className="divReplySection">
                                                                <div className="msgReplySection gap-1 -mt-[6px]" onClick={() => handleMsgAction('reply', v)}>
                                                                    <p className="relative leading-5">
                                                                        <span className="absolute">
                                                                        {is_task ?  <FaTasks className='tasks_notify_icon' size={18} color='#318fff' />
                                                                        :
                                                                            <img className="w-4 h-4 mt-1" src={MessageReplyIcon} alt="reply" title="reply" />
                                                                            }
                                                                        </span>
                                                                        <span className={classNames("rep_left ml-[20px]", Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) > 0 && 'unread')} data_length="1">Task notifications {`(${Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) > 0 ? Get_My_Counter(CountersData, 'smru', v.conversation_id, v.msg_id) + ' of ' : ''}${v.has_reply})`} </span>
                                                                        <span className="rep_mid">Last reply <Moment fromNow>{v.last_reply_time}</Moment> from  </span>
                                                                        <span className="rep_righ">{v.last_reply_name}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                    </>
                                }

                            </div>
                        </div>

                        <div ref={messagesEndRef} />
                        <div id={"refMsg_id" + v.msg_id} />
                    </div>
                </div>

                {preview_data && <ImgPreviewPopup setPreview_data={setPreview_data} active_file={preview_data} all_file={preview_all_data} user={user} />}

                {(msgDeletePopup || file_delete) && <WarningPopup messageTitle={file_delete ? 'Delete File' : 'Delete Message'} description={file_delete ? 'Are you sure you want to delete this file?' : <DeleteText />} closePopUp={handleDelPopup} data={file_delete ? { ...action_file, ...v } : v} type={file_delete ? "file_delete" : "delete_msg"} />}

                {quickViewPopup && <QuickView setQuickViewPopup={setQuickViewPopup} handleMsgAction={handleMsgAction} data={v} all_users={all_users} />}

                <Tooltip anchorSelect={"#reply" + v.msg_id} float="true" content="Reply"></Tooltip>
                <Tooltip anchorSelect={"#flag" + v.msg_id} float="true" content="Flag"></Tooltip>
                <Tooltip anchorSelect={"#title" + v.msg_id} float="true" content="Add Title"></Tooltip>
                <Tooltip anchorSelect={"#copy" + v.msg_id} float="true" content="Copy"></Tooltip>
                <Tooltip anchorSelect={"#emoji" + v.msg_id} float="true" content="Emoji"></Tooltip>
                <Tooltip anchorSelect={"#forward" + v.msg_id} float="true" content="Forward"></Tooltip>
                <Tooltip anchorSelect={"#edit" + v.msg_id} float="true" content="Edit"></Tooltip>
                <Tooltip anchorSelect={"#more" + v.msg_id} float="true" content="More options"></Tooltip>
                <Tooltip anchorSelect={"#addEdit" + v.msg_id} float="true" content="Add/Edit"></Tooltip>


                <Tooltip anchorSelect={"#starred" + v.msg_id} float="true" content="Add to starred"></Tooltip>
                <Tooltip anchorSelect={"#addTags" + v.msg_id} float="true" content="Add tags"></Tooltip>
                <Tooltip anchorSelect={"#download" + v.msg_id} float="true" content="Download"></Tooltip>
                <Tooltip anchorSelect={"#forward" + v.msg_id} float="true" content="Forward"></Tooltip>
                <Tooltip anchorSelect={"#share" + v.msg_id} float="true" content="Share"></Tooltip>
                <Tooltip anchorSelect={"#delete" + v.msg_id} float="true" content="Delete"></Tooltip>
            </div>
        </>
    )
})
export default MessagesContainer;