/* eslint-disable no-loop-func */

import React, { useEffect, useRef, useState } from 'react';
// import './ai.css'
import { generateImage, generateText, generateTextStream, mongoObjectId } from '../../Utils/Common';
import { GET_ME, GET_EOD_ANALYSIS, LIST_EOD_ANALYSIS } from '../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { HiArrowSmDown, HiArrowSmUp } from "react-icons/hi";
import { TfiClose } from "react-icons/tfi";
import { LiaLongArrowAltLeftSolid } from "react-icons/lia";
import { CgAttachment } from "react-icons/cg";
import { GoTrash } from "react-icons/go";
import Moment from 'react-moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import EodBarSnap from "./EodBarSnap";
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import toast from 'react-hot-toast';

// import { Delete_workfreeliai, Save_workfreeliai } from '../../Utils/GraphqlMutaion';

const WorkFreeliDB = () => {
    const containerRef = useRef(null);
    const client = useApolloClient();
    const { me: user } = client.readQuery({ query: GET_ME });
    // const [showScrollButton, setShowScrollButton] = useState(false);
    const [active_ques, set_active_ques] = useState(null);
    // const [all_ques, set_all_ques] = useState([]);
    // const [response, setResponse] = useState([]);
    const [initialLoader, setInitialLoader] = useState(false);
    const navigate = useNavigate();
    const [msgscreen, setMsgscreen] = useState(true);
    const [rmsgscreen, setRmsgscreen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedNumber, setselectedNumber] = useState(0);
    const [loader, setLoader] = useState(false);
	const [history_list, set_history_list] = useState([
		

	]);
    const inputVenueRef = useRef(null);

	useEffect(() => {
		if (isOpen2 && inputVenueRef.current) {
			inputVenueRef.current.focus();
		}
	}, [isOpen2]);

    const [searchVenueByVal, setSearchVenueByVal] = useState('');
	const searchByVenueList = (v) => {
		setSearchVenueByVal(v);

	}

    const [responseData, setResponseData] = useState({});
    const [selectedMsg, setselectedMsg] = useState("");

    const [fetchAnalysis, { loading: analysisLoading, data: eod_analysis }] = useLazyQuery(GET_EOD_ANALYSIS, { fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (eod_analysis?.get_eod_analysis?.status) {
            setResponseData(eod_analysis?.get_eod_analysis);
        }
    }, [eod_analysis]);

    
    const [message, setMessage] = useState("");
    const textareaRef = useRef(null);

    const [fetchListAnalysis, { loading: listLoading, data: eod_list_analysis }] = useLazyQuery(LIST_EOD_ANALYSIS, { fetchPolicy: 'no-cache' });
    useEffect(() => {
        
        if (eod_list_analysis?.list_eod_analysis?.status) {
            // debugger
            set_history_list(eod_list_analysis?.list_eod_analysis?.data || []);
        }
    }, [eod_list_analysis]);

    const handleChange = (e) => {
        setMessage(e.target.value);

        // Auto-adjust height
        textareaRef.current.style.height = "auto"; // Reset height to calculate new height
        textareaRef.current.style.height = `${Math.max(
            45, // Minimum height
            Math.min(textareaRef.current.scrollHeight, 300) // Max height is 300px
        )}px`;
    };

    // const [save_ai_res] = useMutation(Save_workfreeliai)
    // const saveAIResInDB = async (fields) => {
    //     let apires = await save_ai_res({
    //         variables: {
    //             input: { ...fields }
    //         }
    //     })
    //     console.log("🚀 ~ saveAIResInDB ~ apires:", apires)
    // }

    const handleSend = async () => {
        // debugger;
        if (message.trim() !== "") {
            setselectedMsg(message);
            fetchAnalysis({ variables: { timezone: moment.tz.guess(), type: "0", message: message } });

            // let root_id = active_ques ? active_ques?._id : '';
            // let q = message;
            setMessage(""); // Clear textarea after sending
            // textareaRef.current.style.height = "45px"; // Reset to minimum height after sending
            // try {
            //     setInitialLoader(true);


            //     let ni = await generateText("You are a decision-making assistant. If a question requires a visual representation (e.g., an image, chart, or diagram), respond with 'needs image'. Otherwise, respond with 'does not need image'.", q);
            //     // debugger
            //     const commonFields = {
            //         _id: mongoObjectId(),
            //         user_id: user.id,
            //         ques: q,
            //         ans: "",
            //         res: true,
            //         like: false,
            //         dislike: false,
            //         root_id: root_id,
            //         created_at: new Date().toISOString(),
            //     };
            //     setResponse(prev => [...prev, commonFields]);
            //     if (root_id === '') {
            //         set_active_ques(commonFields);
            //         set_all_ques(prev => [...prev, commonFields]);
            //     }
            //     // Choose appropriate API based on the content
            //     let b;
            //     if (ni === 'needs image') {
            //         set_chunk_img_loader(true)

            //         // setTimeout(() => {
            //         //     scrollToBottom();
            //         // }, 100);

            //         b = await generateImage(q);
            //         // debugger
            //         // console.log(160, b);
            //         let img = `<div class="genarated_img_wraper"><img src="${b}" class="genarated_img" /></div>`;
            //         setResponse((prev) =>
            //             prev.map((item) =>
            //                 item._id === commonFields._id ? { ...item, ans: img } : item
            //             )
            //         );
            //         setTimeout(function(){
            //             saveAIResInDB({...commonFields, ans: img, image: b})
            //         }, 1000)
            //         set_chunk_img_loader(false)
            //     } else {
            //         b = await generateTextStream(commonFields, q, handleStream);
            //         // console.log(170, b);
            //         setTimeout(function(){
            //             saveAIResInDB({...commonFields, ans: b})
            //         }, 1000)
            //         // console.log(172, b);
            //         // debugger
            //     }

            //     // Update the state with the response
            //     // if (root_id === '') {
            //     // 	set_active_ques(b[0]);
            //     // 	set_all_ques(prev => [...b, ...prev]);
            //     // 	setResponse([...b]);
            //     // } else {
            //     // 	setResponse([...response, ...b]);
            //     // 	set_all_ques(prev =>
            //     // 		prev.map(m => m._id === root_id ? { ...m, childCount: m.childCount + 1 } : m)
            //     // 	);
            //     // }
            // } catch (error) {
            //     console.log(error);
            // }

            // setInitialLoader(false);
            // set_loader_msg('');
        }
    };

    // const uploadFiles = async (_f) => {
    //     set_img_loader(true)
    //     // let bucket_name = user.email;
    //     // bucket_name = bucket_name.replace("@", "-");
    //     // bucket_name = bucket_name.replace(/\_/g, '-');
    //     // bucket_name = bucket_name.replace(/\./g, '-');
    //     for (let i = 0; i < _f.length; i++) {
    //         var data = new FormData();
    //         await new Promise(resolve => {
    //             // data.append("bucket_name", bucket_name);
    //             // data.append("sl", 1);
    //             data.append("file_upload", _f[i]);
    //             // const config = {
    //             // 	onUploadProgress: (progressEvent) => {
    //             // 	}
    //             // }
    //             // ai_upload(data, config, function (res) {
    //             // 	console.log("🚀 ~ res:", res)
    //             // 	set_ai_files(prev => [...prev, ...res.file_info.map(m => m.location)])
    //             // 	set_img_loader(false)
    //             resolve();
    //             // });
    //         });
    //         set_form_data_file(data)
    //         console.log("🚀 ~ uploadFiles ~ data:", data)
    //     }
    // }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent newline
            handleSend();
        }
    };

    const close_ai = () => {
        // set_root_id('')
        if (rmsgscreen) {
            set_active_ques(null);
            setMsgscreen(true);
            setRmsgscreen(false);
        } else {
            navigate(-1)
        }
    }



    // // Scroll to the bottom with smooth scrolling
    // const scrollToBottom = () => {
    //     if (containerRef.current) {
    //         setShowScrollButton(false)
    //         containerRef.current.scrollTo({
    //             top: containerRef.current.scrollHeight,
    //             behavior: 'smooth',
    //         });
    //     }
    // };

    useEffect(() => {

        fetchListAnalysis({ variables: {  } });
        
    }, []);

    // function customMarkdownParser(text) {
    //     // Convert headers
    //     text = text.replace(/###\s?(.*?)(?=\n|$)/g, '<h3>$1</h3>');

    //     // Convert bold text
    //     text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    //     // Replace multiple newlines with a single <br>
    //     // text = text.replace(/\n\s*\n/g, '<br>'); // For multiple line breaks
    //     // text = text.replace(/\n/g, ' '); // For single line breaks, replace with space

    //     return text;
    // }
    // const [delete_ai_msg] = useMutation(Delete_workfreeliai)
    // const handleDelete = async (event, v, type) => {
    //     event.preventDefault()
    //     event.stopPropagation()

    //     let apires = await delete_ai_msg({
    //         variables: {
    //             input: { _id: v._id }
    //         }
    //     })
    //     console.log("🚀 ~ handleDelete ~ apires:", apires)
    //     if (apires.data.delete_workfreeliai.statis) {
    //         if (type === 'q') {
    //             set_all_ques(prev => prev.filter(f => f._id !== v._id))
    //         } else {
    //             setResponse(prev => prev.filter(f => f._id !== v._id))
    //         }
    //     }
    // }

    return (
        <div className="right_container" style={{ zIndex: "9999" }}>
            <div className="ai_header">
                <div className="ai_header_part">
                    <span className="ai_back" data-for="backAi" data-tip="Back" currentitem="false" onClick={close_ai} >
                        <LiaLongArrowAltLeftSolid />
                    </span>
                    <div className="action_list"> WorkFreeli DB </div>
                </div>
                <div className="ai_header_part" data-for="backAiClose" data-tip="Close">
                    <span className="ai_close" onClick={close_ai} ><TfiClose /></span>
                </div>
                {/* <ReactTooltip id={"backAi"} type="dark" place="right" /> */}
                {/* <ReactTooltip id={"backAiClose"} type="dark" place="left" /> */}
            </div>

            <div className="ai_body">
                <div className="qns-response mindsdb">
                    <div className='response_section' ref={containerRef}>
                        <div className='reportingNotes'>
                            <p className='reportLabel'>Please select a question</p>
                            <OutsideClickHandler onOutsideClick={() => setIsOpen2(false)}>
                                <div className={classNames('custom_dropdown_task', isOpen2 ? 'changeBg' : '')}>
                                    <div className={classNames('custom_dropdown-header')}
                                        onClick={() => {
                                            setIsOpen2(!isOpen2);
                                        }}
                                        style={{ borderRadius: '4px' }}
                                    >
                                        {selectedMsg !== '' ? selectedMsg : <span className='venueLabel'>Choose a question</span>}
                                        <i className={`fa fa-chevron-right custom_icon ${isOpen2 ? 'open' : ''}`}></i>
                                    </div>

                                    <div className={`custom_dropdown-body_task inReport ${isOpen2 && 'open'}`}>
                                        <div className="searchareaDiv"
                                            style={{ position: 'sticky', top: '-6px', backgroundColor: '#fff' }}
                                        >
                                            <input
                                                ref={inputVenueRef}
                                                style={{ width: '100%' }}
                                                value={searchVenueByVal}
                                                onChange={(event) => {
                                                    searchByVenueList(event.target.value)
                                                    // setIsChanged(true);
                                                }}
                                                className="_inputBar searchLeft popupserach"
                                                type="text"
                                                placeholder="Search"
                                                autoFocus
                                            />
                                            {searchVenueByVal === "" ? <div className="srcSectionPopup" data-for="top_head" data-tip="Search"></div> : ''}
                                            {searchVenueByVal !== "" ? (
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        height: "18px",
                                                        float: "right",
                                                        marginTop: "-30px",
                                                        display: "block",
                                                        top: "42px",
                                                    }}
                                                    className="removeFile"
                                                    onClick={() => setSearchVenueByVal("")}
                                                    data-for="member_tooltip"
                                                    data-tip="Clear Search"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        {history_list
                                            .filter((item) => item.ques.toLowerCase().includes(searchVenueByVal.toLowerCase()))
                                            .map((list) => (
                                                <div
                                                    key={list._id}
                                                    className={`custom_dropdown-item ${selectedNumber === list._id ? 'selected' : ''}`}
                                                    onClick={async () => {
                                                        setIsOpen2(false);
                                                        setLoader(true);
                                                        setselectedMsg(list.ques);
                                                        setselectedNumber(list._id);
                                                        setResponseData({});

                                                        fetchAnalysis({ variables: { timezone: moment.tz.guess(), type: list._id, message: list.ques } });

                                                        // let data = await get_mindsdb_report(list._id, list.ques);
                                                        // console.log('get_mindsdb_report', data);
                                                        // if (data.status) {
                                                        //     // setResponse(data.data);
                                                        //     setResponseData(data);
                                                        // } else {
                                                        //     toast.error(data.error, { duration: 4000 });
                                                        // }
                                                        setLoader(false);

                                                    }}
                                                >
                                                    {list.ques}
                                                </div>
                                            ))}
                                        {/* {props.venue_list_report
											.filter((item) =>
												item.venue_title.toLowerCase().includes(searchVenueByVal.toLowerCase())
											).length === 0 && searchVenueByVal !== "" && (
												<div className="no-data-found">
													No venue found
												</div>
											)} */}
                                    </div>

                                </div>
                            </OutsideClickHandler>

                        </div>

                        {responseData?.status ? (
                            <div className="divTable eodTable" style={{ marginTop: "10px" }}>
                                <div className="divTableBody" style={{ height: "auto" }}>
                                    <p className="file_count" style={{ marginBottom: "5px", color: "rgb(49, 143, 255)", paddingLeft: "7px" }}>
                                        <b>{selectedMsg}</b>
                                    </p>

                                    {responseData?.type === "venue" && responseData.data[0]?.sales ? (
                                        <>
                                            <div
                                                className="salesSnapshot"
                                                style={{ fontSize: "16px", lineHeight: "1.8", paddingLeft: "7px" }}
                                            >
                                                <p>
                                                    <strong>Venue:</strong> {responseData.data[0].venue || "Unknown Venue"}
                                                </p>
                                                <p>
                                                    <strong>Sales snapshot:</strong>
                                                </p>
                                                <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • Today:{" "}
                                                        {parseFloat(responseData.data[0].sales.today) === 0
                                                            ? "Not submitted yet"
                                                            : `$${parseFloat(responseData.data[0].sales.today).toLocaleString("en-US", {
                                                                minimumFractionDigits: 2,
                                                            })}`}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • Yesterday:{" "}
                                                        {parseFloat(responseData.data[0].sales.yesterday) === 0
                                                            ? "Not submitted yet"
                                                            : `$${parseFloat(responseData.data[0].sales.yesterday).toLocaleString("en-US", {
                                                                minimumFractionDigits: 2,
                                                            })}`}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • Average per day:{" "}
                                                        {parseFloat(responseData.data[0].sales.averagePerDay) === 0
                                                            ? "Not submitted yet"
                                                            : `$${parseFloat(responseData.data[0].sales.averagePerDay).toLocaleString("en-US", {
                                                                minimumFractionDigits: 2,
                                                            })}`}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • This month-to-date:{" "}
                                                        {parseFloat(responseData.data[0].sales.monthToDate) === 0
                                                            ? "Not submitted yet"
                                                            : `$${parseFloat(responseData.data[0].sales.monthToDate).toLocaleString("en-US", {
                                                                minimumFractionDigits: 2,
                                                            })}`}
                                                    </li>
                                                </ul>
                                                <p>
                                                    <strong>Performance snapshot:</strong>
                                                </p>
                                                <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • This month-to-date sale is{" "}
                                                        {responseData.data[0].sales.percentageChange}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        •{" "}
                                                        {responseData.data[0].sales.topPerformingDay
                                                            ? `${responseData.data[0].sales.topPerformingDay} are top performing days`
                                                            : "No top performing day data available"}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        •{" "}
                                                        {responseData.data[0].sales.slowestDay
                                                            ? `${responseData.data[0].sales.slowestDay} are generally slow days`
                                                            : "No slow days data available"}
                                                    </li>
                                                    <li style={{ marginBottom: "8px" }}>
                                                        • Average labor:{" "}
                                                        {responseData.data[0].sales.averageLaborPercentage
                                                            ? `${responseData.data[0].sales.averageLaborPercentage}% of net sales`
                                                            : "No labor data available"}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <EodBarSnap performanceSnapshot={responseData?.data[0] || []} />
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}




                                    {/* {responseData?.type === "query" ? (
										<>
											<div className="divTableRow divTableFirstRow">
												<div className="divTableCell">Venue</div>
												<div className="divTableCell">Reporting date</div>
												<div className="divTableCell">Created at</div>
												<div className="divTableCell">Created by</div>
												<div className="divTableCell">Net sales</div>
												<div className="divTableCell">Comps</div>
												<div className="divTableCell">Total</div>
												<div className="divTableCell">Actual</div>
												<div className="divTableCell">Forecasted</div>
												<div className="divTableCell">Status</div>
												<span
													className="downloadIcon"
													style={{
														cursor: 'pointer',
														position: 'absolute',
														right: '0px',
														top: '0px'
													}}
													onClick={() => {
														if (csvLinkRef.current) {
															csvLinkRef.current.link.click();
														}
													}}
												>
													<span className='exportIcon' data-for='exportIcon' data-tip='Export'></span>
												</span>
												<CSVLink
													id="csvFile"
													ref={csvLinkRef}
													data={response.map(report => {
														let obj = {};
														obj["Venue"] = report.notes_for;
														obj["Reporting date"] = moment(report.reporting_date).format("LL");
														obj["Created date"] = moment(report.created_at).format("LL");
														obj["Net sales"] = "$" + parseFloat(report?.net_sales || 0).toFixed(2);
														obj["Comp"] = "$" + parseFloat(report.comps || 0).toFixed(2);
														obj["Total"] = "$" + parseFloat(report.total || 0).toFixed(2);
														obj["Forecast"] = "$" + parseFloat(report.forecasted_labour || 0).toFixed(2);
														obj["Actual"] = "$" + parseFloat(report.actual_labour || 0).toFixed(2);
														obj["% Net sale"] = parseFloat(report.total_percent || 0).toFixed(0) + "%";
														obj["Status"] = report.status === 'SUBMIT' ? 'Submitted' : report.status === 'DRAFT' ? 'Draft' : report.status === 'REOPEN' ? 'Re-opened' : '';
														obj["Created by"] = report.created_by_name;
														obj["Last updated by"] = report.updated_by_name;

														return obj;
													})}
													filename="report_list.csv"
												/>
											</div>

											{responseData.data.length > 0 ? (
												responseData.data.map((report, index) => (
													<div className={`divTableRow inner ${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
														<div className="divTableCell"><p className='vanue_text'>{report.notes_for}</p></div>
														<div className="divTableCell">
															<div className='dateDivReport'>
																<div>{report.timezone ? moment(report.reporting_date).tz(report.timezone).format("LL") : moment(report.reporting_date).format("LL")}</div>
																<div className="reporting_timezone">{report.timezone ? `(${formatToGMT(report.timezone)})` : '(GMT-04)'}</div>
															</div>
														</div>
														<div className="divTableCell">
															<div className='dateDivReport'>
																<div>{report.timezone ? moment(report.created_at).tz(report.created_at).format("LL") : moment(report.created_at).format("LL")}</div>
																<div className="reporting_timezone">{report.timezone ? `(${formatToGMT(report.timezone)})` : '(GMT-04)'}</div>
															</div>
														</div>
														<div className="divTableCell lastupdatedCol">
															<div className='lastUpdated_text'>
																<p className='user' data-tip={report.created_by_name} data-for="updated_by_name" delayShow={1000}>
																	{report.created_by_name}
																</p>
															</div>
														</div>
														<div className="divTableCell">
															<p className='net_sales_text'>
																{isNaN(report.net_sales) ? report.net_sales : new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD',
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																}).format((report?.net_sales || 0))}
															</p>
														</div>
														<div className="divTableCell">
															<p className='comps_text'>
																{isNaN(report.comps) ? report.comps : new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD',
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																}).format((report.comps || 0))}
															</p>
														</div>
														<div className="divTableCell">
															<p className='total_text'>
																{isNaN(report.total) ? report.total : new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD',
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																}).format((report.total || 0))}
															</p>
														</div>
														<div className="divTableCell">
															{isNaN(report.actual_labour) ? report.actual_labour : new Intl.NumberFormat('en-US', {
																style: 'currency',
																currency: 'USD',
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															}).format(report.actual_labour)}
														</div>
														<div className="divTableCell">
															{isNaN(report.forecasted_labour) ? report.forecasted_labour : new Intl.NumberFormat('en-US', {
																style: 'currency',
																currency: 'USD',
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															}).format(report.forecasted_labour)}
														</div>
														<div className="divTableCell">
															<p className={`${report.status === 'REOPEN' || report.status === 'DRAFT' ? 'draftColor' : 'completeColor'}`}>
																{report.status === 'SUBMIT' ? 'Submitted' : report.status === 'DRAFT' ? 'Draft' : report.status === 'REOPEN' ? 'Re-opened' : ''}
															</p>
														</div>
													</div>
												))
											) : (
												<div className="notFound reportNoFound">No data found matching the criteria.</div>
											)}

											<div style={{ marginTop: "10px" }}>
												<EodBarChart data={responseData?.data || []} />
											</div>
										</>
									) : null} */}
                                </div>
                            </div>
                        ) : null}

                    </div>
                    {/* {(showScrollButton) && (
                        <button onClick={scrollToBottom} className='scroll_button'><HiArrowSmDown /></button>
                    )} */}
                </div>
                <div className='ai_msg_send'>
                    <div className='ai_send_area'>
                        {/* <label className='ai_msg_attach_btn' htmlFor='aiFile'>
                            <input type="file" name="file_upload" id="aiFile" disabled onChange={(e) => uploadFiles(e.target.files)} multiple hidden />
                            <CgAttachment />
                        </label> */}
                        {/* {(ai_files.length > 0 || img_loader) &&
                            <div className='send_doc_holder'>
                                {ai_files.map((v, i) =>
                                    <div className='ai_send_doc'>
                                        <span className='ai_doc_close' onClick={() => set_ai_files(prev => prev.filter(f => f !== v))}><TfiClose /></span>
                                        <img src={v} alt='ai_doc' key={'ai_' + i} />
                                    </div>
                                )}
                                {img_loader &&
                                    <div className='ai_send_doc'>
                                        <svg className="imgloader" width="35" height="35" viewBox="0 0 50 50">
                                            <circle cx="25" cy="25" r="20"></circle>
                                        </svg>
                                    </div>
                                }
                            </div>
                        } */}
                        <textarea
                            ref={textareaRef}
                            value={message}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder={active_ques ? 'Have any more question about this?' : 'How can I help you?'}
                            readOnly={initialLoader}
                            rows="1"
                            autoFocus
                        />
                        <button className='ai_msg_send_btn' onClick={handleSend} style={message.trim() ? {} : { pointerEvents: 'none', opacity: '0.5' }} ><HiArrowSmUp /></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

// const SvgLoader = () => {
//     return (
//         <div className="ai_loader">
//             <svg
//                 version="1.1"
//                 id="L4"
//                 xmlns="http://www.w3.org/2000/svg"
//                 x="0px"
//                 y="0px"
//                 viewBox="0 0 200 100"
//                 xmlSpace="preserve"
//             >
//                 {/* Circle 1 */}
//                 <circle fill="#d8d8d8" stroke="none" cx="15" cy="40" r="12">
//                     <animate
//                         attributeName="opacity"
//                         dur="1s"
//                         values="0;1;0"
//                         repeatCount="indefinite"
//                         begin="0.1s"
//                     />
//                 </circle>

//                 {/* Circle 2 */}
//                 <circle fill="#d8d8d8" stroke="none" cx="55" cy="40" r="12"> {/* Increased cx by 10px */}
//                     <animate
//                         attributeName="opacity"
//                         dur="1s"
//                         values="0;1;0"
//                         repeatCount="indefinite"
//                         begin="0.2s"
//                     />
//                 </circle>

//                 {/* Circle 3 */}
//                 <circle fill="#d8d8d8" stroke="none" cx="95" cy="40" r="12"> {/* Increased cx by 10px */}
//                     <animate
//                         attributeName="opacity"
//                         dur="1s"
//                         values="0;1;0"
//                         repeatCount="indefinite"
//                         begin="0.3s"
//                     />
//                 </circle>

//                 {/* Circle 4 */}
//                 <circle fill="#d8d8d8" stroke="none" cx="135" cy="40" r="12"> {/* Increased cx by 10px */}
//                     <animate
//                         attributeName="opacity"
//                         dur="1s"
//                         values="0;1;0"
//                         repeatCount="indefinite"
//                         begin="0.4s"
//                     />
//                 </circle>
//             </svg>
//         </div>
//     )
// };

export default WorkFreeliDB;