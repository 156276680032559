const TeamWarnPopup = ({ title, desc, onConfirm, onClose, remove_conv, teamDelete }) => {
    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">{title}</h4>
                    <span className="closeModal" data-for="rightSection_tooltip" onClick={onClose}></span>
                </div>
                <div className="freeliModalBody !pl-10 !pr-10">
                    <p className="delDesc">{desc}</p>
                </div>
                <div className="freeliModalFoot">
                    <button className="sweetCancel mr-3 !text-[14px]" onClick={onClose}>Cancel</button>
                    {/* {
                        !teamDelete && <button className="sweetSave !text-[13px] !w-[140px] !leading-4 !mr-2" onClick={remove_conv} >Remove from conversation(s)</button>
                    } */}

                    <button className="sweetSave !text-[14px]" onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default TeamWarnPopup;