/* eslint-disable react/jsx-pascal-case */
import { Register } from "./Components/Initial/Register";
import { Login } from "./Components/Initial/Login";
import { Connect } from "./Components/Connect/Connect";
import { PublicRoute } from './Utils/PublicRoute'
import { PrivateRoute } from './Utils/PrivateRoute'
import { AdminRoute } from './Utils/AdminRoute'
import ErrorPage from "./Components/ErrorPage"
import { createBrowserRouter } from "react-router-dom";
import { ConversationSection } from "./Components/Connect/ConversationSection";
import { ReplySection } from "./Components/Connect/ReplySection";
import { FileUpload } from "./Components/Connect/FileUpload";
import { PrivateMsg } from "./Components/Connect/PrivateMsg";
import ProductPopup from "./Components/GlobalPopups/ProductPopup";
import ProductPopupMain from "./Components/GlobalPopups/ProductPopupMain";
import FileHub from "./Components/FileHub/FileHub";
import CreateRoom from "./Components/Room/CreateRoom";
import UserProfile from "./Components/Connect/UserProfile";
import UpdateRoom from "./Components/Room/UpdateRoom";
import TeamManageHome from "./Components/Admin/TeamManagement/TeamManageHome";
import MuteNotifications from "./Components/Connect/MuteNotifications";
import RoomCategoryHome from "./Components/Admin/RoomCategory/RoomCategoryHome";
import SharedTeamTagsHome from "./Components/Admin/SharedTeamTags/SharedTeamTagsHome";
import TaskManagementHome from "./Components/TasksManagement/TaskManagementHome";
import CreateQuickTask from "./Components/TasksManagement/CreateTaskTab/CreateQuickTask";
import CalendarHome from "./Components/Calender/CalendarHome";
import MultiCompanySwitcher from "./Components/Connect/MultiCompanySwitcher";
import SharedFileUrl from "./Components/Connect/SharedFileUrl";
import FileHubUrl from "./Components/Connect/FileHubUrl";
import Calling_meeting from "./Components/Call_window/Calling_meeting";
import Notifications from './Components/Connect/Notifications'
import MargeCompany from "./Components/Admin/Marge Company/MargeCompany";
import RolebackCompany from "./Components/Admin/Marge Company/RolebackCompany";
import MaintenanceHome from "./Components/Maintenance/MaintenanceHome";
import ModuleManagementHome from "./Components/Admin/ModuleManagement/ModuleManagementhome";
import RoleManagementHome from "./Components/Admin/RoleManagement/RoleManagementHome";
import EodReportHome from "./Components/EodReport/EodReportHome";
import CreateAndEditReport from "./Components/EodReport/CreateEdit/CreateAndEditReport";
import VenueManager from "./Components/Admin/VenueManagement/VenueManager";
import BusinessManager from "./Components/Admin/BusinessAccount/BusinessManager";
import UserManagementHome from "./Components/Admin/UserManagement/UserManagementHome";
import WorkFreeliAI from "./Components/Ai/WorkFreeliAI";
import WorkFreeliDB from "./Components/MindsDB/WorkFreeliDB";
import { TaskProvider } from "./Components/TasksManagement/TaskContext";
import SubscriptionBillingHome from "./Components/Admin/SubscriptionBilling/SubscriptionBillingHome"
import TaskPropertiesCon from "./Components/TasksManagement/TaskProperties/TaskPropertiesCon";
export const ComponentProvier = createBrowserRouter([
    {
        path: "/",
        loader: PrivateRoute,
        element: <Connect />,
        children: [
            {
                path: "connect",
                children: [
                    {
                        path: ":conversation_id",
                        element: <ConversationSection />,
                        children: [
                            {
                                path: "filter/:filter_name",
                                element: <></>,
                                children: [
                                    {
                                        path: ":search_string",
                                        element: <></>
                                    }
                                ]
                            },
                            {
                                path: "private_update/:msg_id",
                                element: <PrivateMsg />
                            },
                            {
                                path: "file_hub",
                                element: <FileHub />,
                                children: [
                                    {
                                        path: ":file_hub_tab",
                                        element: <></>
                                    }
                                ]
                            },
                            {
                                path: "daily-report",
                                element: <EodReportHome />,
                            },
                            {
                                path: "maintenance",
                                element: <MaintenanceHome />,
                            },
                            {
                                path: "create-reports",
                                element: <CreateAndEditReport />
                            },
                            {
                                path: "edit-reports",
                                element: <CreateAndEditReport />
                            },
                            {
                                path: "reply/:msg_id",
                                element: <ReplySection />,
                            },
                            {
                                path: "update_room",
                                element: <UpdateRoom />,
                                children: [
                                    {
                                        path: ":popup",
                                        element: <></>
                                    }
                                ]
                            },
                            {
                                path: "file_upload_old",
                                element: <FileUpload is_reply_msg={'no'} type="file_upload" />
                            },
                            {
                                path: "mute_conversation",
                                element: <MuteNotifications />
                            },
                            {
                                path: "profile",
                                element: <UserProfile />,
                            },
                            {
                                path: "tasks",
                                element: <TaskManagementHome from='room' />,
                                children: [
                                    {
                                        path: ":tasks_tab",
                                        element: <></>,
                                        children: [
                                            {
                                                path: ":task_id",
                                                element: <></>,
                                            }
                                        ]
                                    },
                                    {
                                        path: "create-task",
                                        element: <TaskProvider> <CreateQuickTask /></TaskProvider>,
                                    }
                                ]
                            },
                            {
                                path: ":task_id",
                                element: <TaskProvider> <TaskPropertiesCon /></TaskProvider>,
                            },
                        ]
                    },
                    {
                        path: "tasks",
                        element: <TaskManagementHome />,
                        children: [
                            {
                                path: ":tasks_tab",
                                element: <></>,
                                children: [
                                    {
                                        path: ":task_id",
                                        element: <TaskPropertiesCon />,
                                    }
                                ]
                            },
                            {
                                path: "create-task",
                                element: <TaskProvider> <CreateQuickTask /></TaskProvider>,
                            }
                        ]
                    },
                    {
                        path: "reply/:msg_id",
                        element: <ReplySection />,
                        children: [
                            {
                                path: "file_upload",
                                element: <FileUpload is_reply_msg={'yes'} type="file_upload" />
                            }
                        ]
                    },
                    {
                        path: "create_room",
                        element: <CreateRoom />,
                        children: [
                            {
                                path: ":popup",
                                element: <></>
                            }
                        ]
                    },
                    {
                        path: "profile",
                        element: <UserProfile />,
                    },
                    {
                        path: "workfreeli-ai",
                        element: <WorkFreeliAI />,
                    },
                    {
                        path: "workfreeli-db",
                        element: <WorkFreeliDB />,
                    },
                    {
                        path: "admin-settings",
                        loader: AdminRoute,
                        element: <UserManagementHome />,
                    },
                    {
                        path: "notifications",
                        element: <Notifications />,
                    },
                    {
                        path: "team-management",
                        loader: AdminRoute,
                        element: <TeamManageHome />,
                    },
                    {
                        path: "shared-team-tags",
                        loader: AdminRoute,
                        element: <SharedTeamTagsHome />,
                    },
                    {
                        path: "marge-company",
                        element: <MargeCompany />,
                    },
                    {
                        path: "role-manage",
                        element: <RoleManagementHome />,
                    },
                    {
                        path: "module-management",
                        element: <ModuleManagementHome />,
                    },
                    {
                        path: "rollback-company",
                        element: <RolebackCompany />,
                    },
                    {
                        path: "room-category",
                        loader: AdminRoute,
                        element: <RoomCategoryHome />,
                    },
                    {
                        path: "calendar",
                        element: <CalendarHome />,
                    },
                    {
                        path: "switch-account",
                        element: <MultiCompanySwitcher />,
                    },
                    {
                        path: "file_hub",
                        element: <FileHub />,
                        children: [
                            {
                                path: ":file_hub_tab",
                                element: <></>
                            }
                        ]
                    },
                    {
                        path: "daily-report",
                        element: <EodReportHome />,
                        children: [
                            {
                                path: "create-report",
                                element: <CreateAndEditReport />,
                            },
                        ]
                    },
                    {
                        path: "maintenance",
                        element: <MaintenanceHome />,
                    },
                    {
                        path: "venue-management",
                        loader: AdminRoute,
                        element: <VenueManager />,
                    },
                    {
                        path: "business-management",
                        loader: AdminRoute,
                        element: <BusinessManager />,
                    },
                    {
                        path: "subscription-billing",
                        loader: AdminRoute,
                        element: <SubscriptionBillingHome />,
                    },
                ]
            }

        ],
        errorElement: <ErrorPage />
    },
    {
        path: "/f/:url",
        loader: PrivateRoute,
        element: <SharedFileUrl />,
    },
    {
        path: "/file",
        loader: PrivateRoute,
        element: <FileHubUrl />,
    },

    {
        path: "/sign-up",
        loader: PublicRoute,
        element: <Register />,
    },
    {
        path: "/login",
        loader: PublicRoute,
        element: <Login type="normal" />
    },
    {
        path: "/signin_with_otp/:email",
        loader: PublicRoute,
        element: <Login type="otp_with_email" />
    },
    {
        path: "/signin_with_otp",
        loader: PublicRoute,
        element: <Login type="otp_login" />
    },
    {
        path: "/productpage",
        element: <ProductPopup />
    },
    {
        path: "/productpage_manipulated",
        element: <ProductPopupMain />
    },
    {
        path: "/call/:cid",
        loader: PrivateRoute,
        element: <Calling_meeting />,
    },
    {
        path: "/c/:id",
        // loader: PublicRoute,
        element: <Calling_meeting />,
    },
    {
        path: "/u/:id",
        // loader: PublicRoute,
        element: <Calling_meeting />,
    },

]);