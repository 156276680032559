/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Summary from './Summary';

function SubscriptionChoosePlanTab(props) {
    const [minStorage, setMinStorage] = useState(0);

    useEffect(() => {
        if (!props.billingData || !props.billingData.choose_plan) return;
    
        let d = [0, 20, 50, 100, 200, 500, 1000, 2000];
        let used = Number(props.billingData.storage);
        let old_storage = ((Number(props.billingData.plan_user_limit) * 2) + 5);
    
        let min = null;
        if (old_storage < used) {
            let diff = (used - old_storage);
            for (let i of d) {
                if (min === null) {
                    if (i > diff) {
                        min = i;
                    }
                }
            }
        }
    
        if (min !== null) {
            setMinStorage(min);
        }
    }, [props.billingData]);

    const onChangeHandlerUsers = (event) => {
        //	//console.log(event.target.value)
        props.setBillingData({
            ...props.billingData,
            choose_plan: {
                ...props.billingData.choose_plan,
                user: event.target.value
            }
        });
    }

    const onClickHandler = () => {
        props.setActiveTab(3);
    }
    const onClickNextHandler = () => {
        //console.log('clicked');
        props.setActiveTab(1);
    }

    const incrementHandler = () => {
        props.setBillingData({
            ...props.billingData,
            choose_plan: {
                ...props.billingData.choose_plan,
                user: Number(props.billingData.choose_plan.user) + 1
            }
        });

    }
    const decrementHandler = () => {

        props.setBillingData({
            ...props.billingData,
            choose_plan: {
                ...props.billingData.choose_plan,
                user: Math.max(Number(props.billingData.choose_plan.user) - 1, 0)
            }
        });

    }

    const replyStorage = (type) => {
        let data = [0, 20, 50, 100, 200, 500, 1000, 2000];
        let current = data.indexOf(props.billingData.choose_plan.storage);
        if (type === '+') {
            props.setBillingData({
                ...props.billingData,
                choose_plan: {
                    ...props.billingData.choose_plan,
                    storage: data[current + 1]
                }
            });
        } else {
            props.setBillingData({
                ...props.billingData,
                choose_plan: {
                    ...props.billingData.choose_plan,
                    storage: data[current - 1]
                }
            });
        }
    }

    return (
        <>
            <div className="supportAndBillingContainer upgradePlan">
                <div className='UpgradePlanLeftArea'>
                    <div className='PlanLeftAreaInner'>
                        <div className='firstLeftArea'>
                            <div className='UpgradePlanConter'>
                                1
                            </div>
                            <div className='selectUpgradePlan'>
                                Select and upgraded plan below
                            </div>
                            <div className='currentPlan'>
                                Your Current Plan:
                                <p>{props.billingData.plan_name.toUpperCase()}</p>
                            </div>
                        </div>
                        <div className='secoundLeftArea'>
                            <div className='essentialLeftArea'>
                                <div className='essentialText'>
                                    Essential
                                </div>
                                <div className='essentialUserText'>
                                    <div className='essentialUserCurrency'>$4.00 per license</div>
                                    <div className='everymonth'>every month</div>
                                </div>
                                <div className='essentialUsers'>
                                    <div className="numberOfUsers">
                                        <div className="numberOfUsersInput">
                                            <span className="uparrow" onClick={incrementHandler}></span>
                                            <div className="usersData">
                                                {/* <span className="usersStorage">  {props.billingData.choose_plan.user}</span><span className="usersnum">Users</span> */}
                                                <input type='number'
                                                    value={props.billingData?.choose_plan?.user || 0}
                                                    onChange={Number(props.billingData.active_users) === Number(props.billingData?.choose_plan?.user || 0) ? '' : onChangeHandlerUsers}
                                                    name="usersNum"
                                                    className='chose_plan_users'

                                                />
                                                <span className="usersnum"> licenses</span>
                                            </div>
                                            {Number(props.billingData.active_users) === Number(props.billingData?.choose_plan?.user || 0) ? <span className="downarrow inactive" ></span> : <span className="downarrow" onClick={decrementHandler}></span>}
                                            {/* <span className="downarrow" onClick={decrementHandler}></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='essentialRightArea'>
                                <span className='sbUserNumber'>{props.billingData.plan_user_limit} licenses <span style={{ color: '#0c1e47', fontSize: '14px', fontWeight: 'normal' }}>(Assigned:{props.billingData.active_users}, Unassigned:{(Number(props.billingData.plan_user_limit) - Number(props.billingData.active_users))}) </span></span>
                                <ul className='essentialUL'>
                                    <li className='essentialLists'>First month free</li>
                                    <li className='essentialLists'>5 GB of storage</li>
                                    <li className='essentialLists'>2 GB of additional storage per license</li>
                                    <li className='essentialLists'>Invite unlimited guests</li>
                                    <li className='essentialLists'>No limitations voice and video call dutations</li>
                                    <li className='essentialLists'>Encrypted data transmission</li>
                                    <li className='essentialLists'>Option to purchase additional storage</li>
                                </ul>
                            </div>
                            <div className="activePlan">
                                <span className="markRound marked"></span>
                            </div>
                        </div>
                        <div className="firstLeftArea" style={{ borderTop: "1px solid #eee", paddingTop: "25px" }}>
                            <div className="UpgradePlanConter">
                                2
                            </div>
                            <div className="selectUpgradePlan">
                                Would you like to add more storage
                            </div>
                        </div>
                        <div className='secoundLeftArea aditionalstorage'>
                            <div className='essentialLeftArea additioal'>
                                <div className='essentialText AdditionalStorageText'>
                                    Additional storage
                                </div>
                                <div className='essentialUsers'>
                                    <div className="numberOfUsers">
                                        <div className="numberOfUsersInput">
                                            {props.billingData.choose_plan.storage === 2000 ? <span className="uparrow inactive"></span> : <span className="uparrow" onClick={() => replyStorage('+')}></span>}
                                            <div className="usersData">
                                                {/* <span className="usersStorage">  {props.billingData.choose_plan.user}</span><span className="usersnum">Users</span> */}
                                                {minStorage > props.billingData.choose_plan.storage ?
                                                    <input
                                                        type='number'
                                                        value={minStorage === 1000 ? 1 : minStorage === 2000 ? 2 : minStorage}
                                                        name="usersStorage"
                                                        className='chose_plan_users'

                                                    /> :
                                                    <input
                                                        type='number'
                                                        value={props.billingData.choose_plan.storage === 1000 ? 1 : props.billingData.choose_plan.storage === 2000 ? 2 : props.billingData.choose_plan.storage}
                                                        name="usersStorage"
                                                        className='chose_plan_users'

                                                    />
                                                }
                                                <span className="usersnum storageGB">{props.billingData.choose_plan.storage === 1000 ? 'TB' : props.billingData.choose_plan.storage === 2000 ? 'TB' : 'GB'}</span>
                                            </div>
                                            {minStorage === props.billingData.choose_plan.storage ?
                                                <span className="downarrow inactive" ></span>
                                                :
                                                props.billingData.choose_plan.storage === 0 ? <span className="downarrow inactive" ></span> : <span className="downarrow" onClick={() => replyStorage('-')}></span>
                                            }

                                            {/* <span className="downarrow" onClick={decrementHandler}></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='essentialRightArea'>
                                {/* <span className='sbUserNumber'>{props.billingData.plan_user_limit} licenses <span style={{ color: '#0c1e47', fontSize: '18px', fontWeight: 'normal' }}>(Active:{props.billingData.active_users}, Available:{(Number(props.billingData.plan_user_limit) - Number(props.billingData.active_users))}) </span></span> */}
                                <span className='sbUserNumberSt'>Current storage usage {props.billingData.storage} GB /</span><span className='sbUserNumberSt'> {((Number(props.billingData.plan_user_limit) * 2) + (5 + props.totalAditionl))} GB</span>
                                <ul className='essentialUL'>
                                    <li className='essentialLists'>2 GB of additional storage per license</li>
                                    <li className='essentialLists'>File recovery and version history</li>
                                </ul>
                            </div>
                            <div className="activePlan" style={{ top: '20px' }}>
                                <span className="markRound marked"></span>
                            </div>

                        </div>
   
                    </div>

                </div>
                <Summary billingData={props.billingData} loader={false} onClickNextHandler={onClickNextHandler} onClickHandler={onClickHandler} btn="Continue" />
            </div>
        </>
    )
}





export default SubscriptionChoosePlanTab;