import { useClickAway } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineKeyboardBackspace, MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { set_eod_related_venue_popup, set_eod_dateFiltered_popup } from '../../redux/message';
import { Tooltip } from 'react-tooltip';
// import { Link } from 'react-router-dom';

const ReportHeader = ({ setVenueTopFilter, venueTopFilter, setDateTopFilter, dateTopFilter, setStartDateFilter, setEndDateFilter, searchTerm, handleChange, handleClearSearch, eodTabs, handleEodTabs, handleOpenPopup, handleGoBack, totalCount, eodData, resetCheckboxes }) => {
    //    console.log("header props",props);
    const selected_venue_id = useSelector(state => state.eod.selected_venue_id) || "";
    const [filterOptions, setFilterOptions] = useState(false);
    const dispatch = useDispatch();
    const ref = useClickAway(() => {
        setFilterOptions(false)
    })
    const open_related_venue_popup = (type) => {
        // alert('ok');

        dispatch(set_eod_related_venue_popup(true))
        setFilterOptions(!filterOptions)
    };
    const open_dateFilered_popup = (type) => {
        // alert('ok');

        dispatch(set_eod_dateFiltered_popup(true))
        setFilterOptions(!filterOptions)
    };
    return (
        <>
            <div className='salesReportDashboard adminRightHead taskHeader !pl-5'>
                <div onClick={handleGoBack} className="backToMainProperties flex items-center">
                    {/* <span className="closeAdminPanelReport"></span> */}
                    <span
                        className="backToChat"
                        id="closeModal"
                    />
                    <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#closeModal" place='left' float="true" content="Back" />

                    <span className="backtoProperties cursor-pointer !text-[14px] font-normal leading-[1.17] tracking-normal text-left !text-[#0b1f47]"> Sales report</span>
                </div>
                <span onClick={handleGoBack} className="closeReportProperties !top-[22px] !mr-4" id='closeProperties'></span>
                <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#closeProperties" place='left' float="true" content="Close" />

            </div>
            <div className='adminRightHead taskHeader !pl-5'>
                {/* <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#backToPavillion"  place='right' float="true" content="Back" /> 
            <div className="backToMainProperties"  onClick={handleGoBack} style={{ display: 'flex', alignItems: 'center' }}>
                <MdOutlineKeyboardBackspace id='backToPavillion' className='mr-2 text-[#002E98] text-3xl cursor-pointer' />
               
            </div>
            */}

                <div className="eocTabHeader">
                    <p className={`eodTabText ${eodTabs === 'sales' ? 'eodTabTextActive' : ''}`} onClick={() => handleEodTabs('sales')}>Dashboard</p>
                    <p className={`eodTabText ${eodTabs === 'entry' ? 'eodTabTextActive' : ''}`} onClick={() => handleEodTabs('entry')}>End of day entry ({eodData?.length || 0} of {totalCount})</p>
                </div>
                <div className='taskHeader_right_area relative'>
                    {(venueTopFilter || dateTopFilter) &&  eodTabs === 'entry' &&
                        <div className="filterActionPreview forTaskFiltered !relative !top-0 !left-0 !translate-x-0">
                            {/* <div style={{ margin: '0' }} className={classNames("miniItem_image", 'notification_ico')}></div> */}
                            <div className="filterKanbanIcon inTaskFiltered"></div>
                            <div className='Filterby'>Filtered by : </div>

                            <div className="taskNotiItems">
                                {venueTopFilter &&
                                    <div className="assigneenotifi">
                                        <div className="items_name">
                                            <span>Venue</span>
                                        </div>
                                        <div className="miniItem_remove"
                                            onClick={() => {
                                                resetCheckboxes()
                                                setTimeout(() => {
                                                    setVenueTopFilter(false);
                                                }, 200)



                                            }}
                                        >

                                        </div>
                                    </div>
                                }

                                {dateTopFilter &&
                                    <div className="assigneenotifi">
                                        <div className="items_name">
                                            <span>Date</span>
                                        </div>
                                        <div className="miniItem_remove"
                                            onClick={() => {
                                                setStartDateFilter('');
                                                setEndDateFilter('');
                                                setDateTopFilter(false);

                                            }}
                                        >
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>}

                    {
                        eodTabs === 'entry' &&
                        <>
                        <div className='relative z-50'>
                            <div className="opt_icons conv_filter !mr-4" onClick={() => setFilterOptions(!filterOptions)} id='filteredEod'></div>
                           
                            {filterOptions &&
                                <div className="ForTaskListInner">
                                    <div ref={ref}>
                                        <ul className="userActionPopup showAll checklistPopup ForTaskList left-[-135px]">
                                            {!selected_venue_id ? <li className='!text-left' onClick={open_related_venue_popup} >Venue</li>: ''}
                                            <li className='!text-left' onClick={open_dateFilered_popup} >Date</li>
                                            {/* <li className='!text-left'>Net Sales</li> */}
                                        </ul>
                                    </div>
                                </div>
                            }
                            <Tooltip className="!z-9999999" anchorSelect="#filteredEod" place='left' float="true" content="Click here to filter" />
                            </div>
                            
                            <div className="searchareaDiv mr-4 relative w-56">
                                <input
                                    className="_inputBar searchLeft"
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                                {searchTerm &&
                                    <MdOutlineCancel
                                        className="absolute text-red-600 right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                        size={20}
                                        onClick={handleClearSearch}
                                        title="Clear search"
                                    />
                                }
                            </div>
                        </>
                    }

                    <div
                        onClick={() => handleOpenPopup("createReport")}
                        className="createReportButton mr-4 !w-[160px] !text-[14px] !font-medium !flex justify-between gab-[20px] !pl-[10px] !pr-[10px]" id='SalesReport'>
                        {/* Sales report */}
                        Create an entry
                        <span className="taskPlusIcon mr-2 -mt-1 -ml-2">
                            <AiOutlinePlus size={16} />
                        </span>
                        <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#SalesReport" place='left' float="true" content=" Create an entry" />

                    </div>

                    {/* <span onClick={handleGoBack} className="closeReportProperties !top-[22px] !mr-4" id='closeReportProperties'></span>
                    <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#closeReportProperties" place='left' float="true" content="Close" /> */}
                </div>

            </div>
        </>
    );
};

export default ReportHeader;