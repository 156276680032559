import React, { useEffect, useState } from 'react';
import { UserSelection } from '../GlobalPopups/UserSelection';
import { Link } from 'react-router-dom';

const StartDiscussionPopup = ({setStartDiscussion}) => {
    const [miniList, setMiniList] = useState([])
    const [is_direct_room_show,set_isDirectRoom]= useState(false)

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setStartDiscussion(false)
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [setStartDiscussion]);

    return (
        !is_direct_room_show ? 
        <div className="backwrap">
            <div className="chooseCreateConv">
                <div className="closePopup" onClick={() => setStartDiscussion(false)}></div>
                <h2 className="popup_title">Start a discussion</h2>
                <div className="PopupContent">
                    <div className="createConv_type _single" onClick={()=>set_isDirectRoom(true)}>
                        <span className="createTypeIcon"></span>
                        <span>Direct message</span>
                    </div>
                    <Link to="/connect/create_room" title='Create Room' onClick={() => setStartDiscussion(false)} className="createConv_type _group">
                        <span className="createTypeIcon"></span>
                        <span>Create room</span>
                    </Link>
                </div>
            </div>
        </div>
        :
        <UserSelection type="direct_conv" miniList={miniList} setMiniList={setMiniList} setStartDiscussion={setStartDiscussion}/>
    );
};

export default StartDiscussionPopup;