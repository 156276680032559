/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useClickAway } from '@uidotdev/usehooks';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';

function TaskRoomSelect({ setTaskRoomUpdateWarning, set_temp_room, selectedRoom }) {
    const { selectedTask, user, isObserver, isAssignee } = useTaskContext();
    const conversations = useSelector((state) => state.message.conversations);
    const [searchInput, setSearchInput] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const filteredRooms = conversations.filter((room) =>
        room.title.toLowerCase().includes(searchInput.toLowerCase())
    );

    const ref = useClickAway(() => {
        setIsEditing(false);
    });

    const handleRoomSelect = (room) => {
        setTaskRoomUpdateWarning(true);
        set_temp_room(room);
        setIsEditing(false);
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (event) => {
        if (!isEditing) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredRooms.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredRooms.length) % filteredRooms.length);
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            handleRoomSelect(filteredRooms[highlightedIndex]);
        }
    };

    useEffect(() => {
        if (isEditing) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditing, highlightedIndex, filteredRooms]);

    return (
        <>
            {!isEditing ? (
                <div id="updateRoomPro" className={`flex gap-4 text-[#0079d1] cursor-pointer contentHover ${(isObserver() || isAssignee()) && 'pointer-events-none'}`} onClick={() => { setIsEditing(true); }} >
                    <p className="text-sm" >Room: {selectedRoom?.title}</p>
                    {selectedTask?.created_by === user.id && (
                        <div className="editImage"></div>
                    )}
                </div>
            ) : (
                <div className="relative" ref={ref}>
                    <div className="border h-[28px] w-[240px] border-gray-300 flex justify-between rounded-[5px] items-center roomTaskproperties">
                        <div className="userDropDownArea">
                            <ul className="usersGroup"></ul>
                            <div
                                className="ellipsis text-sm"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                            >
                                <span className="selectOnew !w-[200px]">
                                    {selectedRoom?.title}
                                </span>
                            </div>
                        </div>
                        <div className="dropdown" style={{ backgroundColor: 'transparent' }}>
                            <div className="dropdown-header !rotate-90">
                                <i
                                    className="fa fa-chevron-right icon open"
                                    style={{ color: 'black' }}
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div className="AddKeywordsPopup_task_settings bg-white border border-gray-300 insightTaskBoard !mt-0 !top-[28px] !w-[240px]">
                        <div className="keywordItem">
                            <div
                                className="searchAndFilterBar"
                                style={{ position: 'relative' }}
                            >
                                <div className="selectedRoomCont !pl-1 !pr-1">
                                    <span className="tags_Color roomColor !h-5 !bg-[#0b1f47] !text-white">
                                        Room: {selectedRoom?.title}
                                    </span>
                                </div>
                                <div className="searchareaDiv">
                                    <div className="p-2 relative">
                                        <input
                                            className="_inputBar searchLeft"
                                            type="text"
                                            placeholder="Search Room"
                                            value={searchInput}
                                            onChange={handleSearchInputChange}
                                        />
                                        <IoMdCloseCircleOutline
                                            onClick={() => setSearchInput('')}
                                            className="text-red-600 cursor-pointer text-2xl absolute right-3 top-3"
                                        />
                                    </div>

                                    <div className="keyword_list_task_settings workloadUsers">
                                        {filteredRooms?.filter(f => f.conversation_id !== selectedRoom.conversation_id)?.map((room, index) => (
                                            <p onClick={() => handleRoomSelect(room)} className={`_tag_rooms ${highlightedIndex === index ? 'bg-[#0C1E47] text-white' : ''
                                                }`}
                                                tabIndex="0"
                                                key={index}
                                            >
                                                {room.title}
                                            </p>
                                        ))}
                                        {filteredRooms.length === 0 && (
                                            <p className=" text-center border-b border-b-[#e3f2ff] text-sm">
                                                No user found
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isEditing && (
                <Tooltip
                    anchorSelect="#updateRoomPro"
                    place="bottom"
                    // float={true}
                    content="Click to update room"
                />
            )}
        </>
    );
}

export default TaskRoomSelect;
