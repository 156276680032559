// thunks/assignTaskThunk.js
import { updateMsgTag } from './message';
import { updateDiscussionMsgTag } from './taskSlice';

export const actionTagThunk = (data) => (dispatch) => {

    // Dispatch action to add the task to the tasks list
    dispatch(updateDiscussionMsgTag(data))
    dispatch(updateMsgTag(data))
};
