import React from "react";
import { preventPlusSign } from "../../ValidationCheck";

const CreateHourlyArea = ({ labourData, handleLabourCostsChange, calculateTotal, getSubmitStatus, preventInvalidKeyPress }) => {


  // const calculateTotal = (key) =>
  //   labourData.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);

  return (
    <div className="p-6 bg-blue-50 eodBackground">
      <h2 className="text-[#0b1f47] text-[32px]  mb-4">Total labour costs</h2>

      <div id="totalLabourCosts" className=" w-full rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
        {/* Table Header */}
        <div className="flex gap-[20px] text-sm pt-[33px] pb-[13px] px-[20px]">
          <div className="w-[20%] text-left  text-[18px]  text-[#0b1f47] font-medium">Labour</div>
          <div className="w-[20%] text-left  text-[18px]  text-[#0b1f47] font-medium">Forecast</div>
          <div className="w-[20%] text-left  text-[18px]  text-[#0b1f47] font-medium">Actual</div>
          <div className="w-[20%] text-left  text-[18px]  text-[#0b1f47] font-medium">Labour % of net sales</div>
          <div className="w-[20%] text-left  text-[18px]  text-[#0b1f47] font-medium">Discounts</div>
        </div>

        {/* Table Rows */}
        {labourData.map((row, index) => (
          <div
            key={index}
            className="flex gap-[20px] text-sm items-center bg-white pb-[13px] px-[20px] "
          >
            {/* Labour */}
            <div className="w-[20%] text-left text-[14px]  text-[#0b1f47]">

              {/* {row.labour} */}
              <input
                type="text"
                className="laboutCategory w-full  px-[0px] py-1 text-slate-300 !h-[30px] !color-[#0b1f47]"
                value={row.labour}

              />
            </div>
            {/* Forecast */}
            <div className="w-[20%] text-left text-[14px]  text-[#0b1f47] relative">
              <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                $
              </span>
              <input
                type="number"
                step="0.01"
                placeholder="0.00"
                className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px]"
                value={row.forecast || ''}
                onChange={(e) => handleLabourCostsChange(index, "forecast", e.target.value)}
                onWheel={(e) => e.target.blur()}
                readOnly={!getSubmitStatus().form}
                onKeyDown={(e) => {
                  preventPlusSign(e); // Prevent plus sign key
                  preventInvalidKeyPress(e); // Other invalid key press prevention logic
                }}
                min="0"
              />
              {/* ${row.forecast.toFixed(2)} */}

            </div>
            {/* Actual */}
            <div className="w-[20%] text-left text-[14px]  text-[#0b1f47] relative">
              {/* ${row.actual.toFixed(2)} */}
              <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                $
              </span>
              <input
                type="number"
                step="0.01"
                placeholder="0.00"
                className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px]"
                defaultValue={row.actual || ''}
                onChange={(e) => handleLabourCostsChange(index, "actual", e.target.value)}
                onWheel={(e) => e.target.blur()}
                readOnly={!getSubmitStatus().form}
                onKeyDown={(e) => {
                  preventPlusSign(e); // Prevent plus sign key
                  preventInvalidKeyPress(e); // Other invalid key press prevention logic
                }}
                min="0"

              />

            </div>
            {/* Labour % of Net Sales */}
            <div className="w-[20%] text-left text-[14px]  text-[#0b1f47] relative pointer-events-none cursor-none">
              {/* ${row.labourNetSales.toFixed(2)} */}
              <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                $
              </span>
              <input
                type="number"
                step="0.01"
                placeholder="0.00"
                className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px]"
                value={row.labourNetSales?.toFixed(2) || ''}
                onChange={(e) => handleLabourCostsChange(index, "labourNetSales", e.target.value)}
                onWheel={(e) => e.target.blur()}
                readOnly={!getSubmitStatus().form}
                onKeyDown={(e) => {
                  preventPlusSign(e); // Prevent plus sign key
                  preventInvalidKeyPress(e); // Other invalid key press prevention logic
                }}
                min="0"
              />

            </div>
            {/* Discounts */}
            <div className={`w-[20%] text-left text-[14px] relative ${row.discounts > 0 ? "text-red" : "text-[#0b1f47]"}`}
            >
              <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                $
              </span>
              <input
                type="number"
                step="0.01"
                placeholder="0.00"
                className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px]"
                value={row.discounts || ''}
                onChange={(e) => handleLabourCostsChange(index, "discounts", e.target.value)}
                onWheel={(e) => e.target.blur()}
                readOnly={!getSubmitStatus().form}
                onKeyDown={(e) => {
                  preventPlusSign(e); // Prevent plus sign key
                  preventInvalidKeyPress(e); // Other invalid key press prevention logic
                }}
                min="0"
              />

              {/* ${row.discounts.toFixed(2)} */}
            </div>
          </div>
        ))}

        {/* Total Row */}
        <div className="flex text-sm pb-[33px] mx-[20px] gap-[20px] ">
          <div className="w-[20%] text-right font-semibold text-[18px] pr-[10px] text-[#0b1f47] border-t border-t-[#0b1f47]">Total</div>
          <div className="w-[20%] text-left font-medium text-[18px] pl-[10px] text-[#0b1f47] border-t border-t-[#0b1f47]">
            ${calculateTotal("forecast").toFixed(2)}
          </div>
          <div className="w-[20%] font-medium text-left text-[18px] pl-[10px] text-[#0b1f47] border-t border-t-[#0b1f47]">
            ${calculateTotal("actual").toFixed(2)}
          </div>
          <div className="w-[20%] font-medium text-left text-[18px] pl-[10px] text-[#0b1f47] border-t border-t-[#0b1f47]">
            ${calculateTotal("labourNetSales").toFixed(2)}
          </div>
          <div className="w-[20%] font-medium text-left text-red text-[18px] pl-[10px] text-[#0b1f47] border-t border-t-[#0b1f47]">
            ${calculateTotal("discounts").toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateHourlyArea;
