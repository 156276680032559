/* eslint-disable react-hooks/exhaustive-deps */
import isEmpty from "validator/lib/isEmpty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopup, set_global_action_data, update_msg_link } from "../../redux/message";
import { useMutation } from "@apollo/client";
import { URL_TITLE_UPDATE } from "../../Utils/GraphqlMutaion";
import { validFirstChercterAlsoNuber } from "../ValidationCheck";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const CustomTitle = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const { global_action_data, user } = useSelector((state) => state.message);

  const mainTitle =
    global_action_data?.type === "single_link" ||
      global_action_data?.type === "msg_url_title"
      ? global_action_data?.msg.title
      : global_action_data?.msg.sender === user.id
        ? global_action_data?.msg.url_base_title || ""
        : "";

  const buttonStatus = mainTitle; // Simplified, since it's identical to `mainTitle`

  const [title, setTitle] = useState(mainTitle);
  const [btnLoader, setBtnLoader] = useState(false);
  const [hasTitleChange, setHasTitleChange] = useState(false);

  useEffect(() => {
    setHasTitleChange(mainTitle?.trim() !== title?.trim());
  }, [title, mainTitle]);

  const [update_title] = useMutation(URL_TITLE_UPDATE);

  const clickToSave = async (title) => {
    try {
      if (global_action_data?.type === "private") {
        return;
      }

      setBtnLoader(true);

      const obj = {
        conversation_id: global_action_data?.msg.conversation_id,
        msg_id: global_action_data?.msg.msg_id,
        participants: global_action_data?.msg?.participants || [],
        is_reply_msg: global_action_data?.msg.is_reply_msg ? global_action_data?.msg.is_reply_msg : params.msg_id ? 'yes' : 'no',
      };

      if (global_action_data?.type === "msg") {
        obj["msg_title"] = isEmpty(title?.trim()) ? "" : title;
      } else {
        obj["url_title"] = isEmpty(title?.trim()) ? "" : title;
        obj["old_url_title"] = global_action_data?.msg.url_base_title || "";
        obj["url_id"] = global_action_data?.msg.url_id;
      }

      const { data } = await update_title({ variables: { input: obj } });
      console.log("API Response:", data);
      dispatch(update_msg_link(data.url_title_update.data));
      toast.success(data.url_title_update.messages);

      if (global_action_data.setApi_data) {
        global_action_data.setApi_data(
          global_action_data.api_data.map((v) =>
            v.url_id === obj["url_id"] ? { ...v, title: obj["url_title"] } : v
          )
        );
      }

      setBtnLoader(false);
      closeCustomTitle();
    } catch (error) {
      console.error("Error updating title:", error);
      setBtnLoader(false);
    }
  };

  const closeCustomTitle = () => {
    dispatch(set_global_action_data(null));
    dispatch(setPopup({ data: false, key: "custom_title_popup" }));
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        closeCustomTitle();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (buttonStatus !== "" && !hasTitleChange) {
        clickToSave("");
      } else {
        clickToSave(title);
      }
    }
  };

  // const handleInputChange = (e) => {
  //     const { value } = e.target;

  //     // Allow clearing the input
  //     if (value === '') {
  //         setTitle(value);
  //         return;
  //     }

  //     // Validate input
  //     if (validFirstChercterAlsoNuber(value)) {
  //         console.warn('Invalid input for title');
  //         return;
  //     }

  //     const trimmedValue = value.trimStart();

  //     if (!trimmedValue) {
  //         console.warn('Title cannot be empty or contain only spaces');
  //         return;
  //     }

  //     setTitle(trimmedValue);
  // };
  const handleInputChange = (e) => {
    const { value } = e.target;

    // If the value is empty or the user is clearing the input (backspace case)
    if (value === "") {
      // Allow clearing the input

      setTitle(value);
      return;
    }

    // Validate the first character to check if it's special or non-alphanumeric
    if (validFirstChercterAlsoNuber(value)) {
      console.warn(
        "Invalid input for title: First character cannot be special"
      );
      return; // Prevent update if first character is special
    }

    // Trim spaces from the start of the value
    const trimmedValue = value.trimStart();

    // If the title is empty or contains only spaces, we prevent further updates
    if (!trimmedValue) {
      console.warn("Title cannot be empty or contain only spaces");
      return; // Prevent update if the value is empty after trimming
    }

    // Check if the value contains consecutive special characters
    if (/[^\w\s][^\w\s]/.test(trimmedValue)) {
      console.warn("Title cannot contain consecutive special characters");
      return; // Prevent update if there are consecutive special characters
    }

    // If all validations pass, update state with the validated value

    setTitle(trimmedValue);
  };

  return (
    <div className="backwrap">
      <div className="customTitleModal">
        <div className="customTitleHead">
          {global_action_data?.type === "private" ? (
            <>
              <h4 className={"popupTitle"} style={{ marginBottom: "15px" }}>
                {global_action_data?.type === "private"
                  ? "Add a title for private message"
                  : global_action_data?.type === "msg"
                    ? "Add a title to this message"
                    : "Add a title to this link"}
              </h4>
              <p>This title will be visible to private user in this room.</p>
            </>
          ) : global_action_data?.type === "single_link" ? (
            <>
              <h4 className={"popupTitle"}>Add a title to this link</h4>
              <p>This title will be visible only file hub.</p>
            </>
          ) : global_action_data?.msg.sender === user.id ? (
            <>
              <h4 className={"popupTitle"} style={{ marginBottom: "15px" }}>
                {global_action_data?.type === "msg"
                  ? "Add a title to this message"
                  : "Add a title to this link"}
              </h4>
              <p>This title will be visible to everyone in this room.</p>
            </>
          ) : (
            <>
              <h4 className={"popupTitle"} style={{ marginBottom: "15px" }}>
                Add a custom title for yourself
              </h4>
              <p>This title will only be visible to you, not to anyone else.</p>
            </>
          )}
          <span className="closeModal" onClick={closeCustomTitle}></span>
        </div>
        <div className="customTitleBody !px-[25px]">
          <input
            type="text"
            className="customTitleInput"
            maxLength={64}
            placeholder={
              global_action_data?.type === "msg" ||
                global_action_data?.type === "private"
                ? "Enter message title"
                : global_action_data?.type === "single_link"
                  ? "Enter link title"
                  : "Enter custom title"
            }
            value={title || ""}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            autoFocus
          />
        </div>
        <div className="customTitleFoot newTitle !px-[25px] !py-6">
          <button className="btnCancel" onClick={closeCustomTitle}>
            Cancel
          </button>
          {buttonStatus !== "" && !hasTitleChange && (
            <button
              className="saveTitle activeTitlebtn hover:!bg-[#D82D28]"
              onClick={() => clickToSave("")}
              style={{ backgroundColor: "#ff5555" }}

            >
              {btnLoader ? "Loading..." : "Remove"}
            </button>
          )}

          {hasTitleChange && (
            <button
              className="saveTitle activeTitlebtn saveBtn"
              style={
                title !== ""
                  ? { marginBottom: "0px" }
                  : {
                    marginBottom: "0px",
                    pointerEvents: "none",
                    opacity: "0.7",
                  }
              }
              onClick={() => clickToSave(title)}
            >
              {btnLoader ? "Loading..." : "Save"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTitle;
