import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LeftBarMaintenance = ({searchStr,setSearchStr }) => {

    const navigate = useNavigate();

    const handleBack = () => {
        setSearchStr('');
        navigate(-1);
    }
    const venueList = [
        { id: 1, venueTitle: "Grand Hall" },
        { id: 2, venueTitle: "Skyline Rooftop" },
        { id: 3, venueTitle: "Ocean View Terrace" },
        { id: 4, venueTitle: "Meadow Banquet Hall" },
        { id: 5, venueTitle: "City Center Auditorium" },
        { id: 6, venueTitle: "Mountain View Lounge" },
        { id: 7, venueTitle: "Heritage Ballroom" },
        { id: 8, venueTitle: "Starlight Garden" },
        { id: 9, venueTitle: "Crystal Pavilion" },
        { id: 10, venueTitle: "Pearl Banquet Hall" },
        { id: 11, venueTitle: "Emerald Conference Center" },
        { id: 12, venueTitle: "Sunset Beach Venue" },
        { id: 13, venueTitle: "Royal Palace Hall" },
        { id: 14, venueTitle: "Vintage Chateau" },
        { id: 15, venueTitle: "Galaxy Event Space" },
        { id: 16, venueTitle: "Lakeside Retreat" },
        { id: 17, venueTitle: "The Majestic Plaza" },
        { id: 18, venueTitle: "Golden Horizon Pavilion" },
        { id: 19, venueTitle: "Maplewood Hall" },
        { id: 20, venueTitle: "Horizon Conference Room" },
        { id: 21, venueTitle: "Evergreen Banquet" },
        { id: 22, venueTitle: "Aurora Event Hall" },
        { id: 23, venueTitle: "Infinity Terrace" },
        { id: 24, venueTitle: "Opal Function Hall" },
        { id: 25, venueTitle: "Sapphire Ballroom" },
        { id: 26, venueTitle: "Cedarwood Pavilion" },
        { id: 27, venueTitle: "Urban Chic Loft" },
        { id: 28, venueTitle: "Silverstream Conference Hall" },
        { id: 29, venueTitle: "Harvest Moon Garden" },
        { id: 30, venueTitle: "Diamond Celebration Center" },
    ];
    const filteredVenu = venueList.filter((venue) => {
        return (
            venue.venueTitle &&
            venue.venueTitle.toLowerCase().includes(searchStr.toLowerCase())
        );
    });
    

    return (
        <div className='mt-4 pt-[10px] border-t border-t-[#475674]'>
            <div
                onClick={handleBack}
                className="bactToAllProject !px-[20px] font-bold thread_back text-[#BFDCFF] "
            >
                <span className="project_icon"></span>
                <span className='font-bold text-[#BFDCFF]'>Venues</span>
            </div>
            <div className='group_container mt-[10px] archiveLastChild projectListTask !h-[calc(100vh-480px)] !overflow-y-auto'>
                <Scrollbars
                    className="custom-scrollbars"
                    renderThumbVertical={({ style, ...props }) => (
                        <div className="track"
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: "#318fff",
                                borderRadius: "50px",
                            }}
                        />
                    )}
                    autoHide={false}
                >
                     {filteredVenu.length > 0 ? (
                        filteredVenu.map((v) => (
                            <li key={'_venue' + v.id} className="conv_list !pl-[20px]">
                                <span className="project_icon"></span>
                                <span className="conv_list_title" title={v.venueTitle}>
                                    {v.venueTitle}
                                </span>
                            </li>
                        ))
                    ) : (
                        <p  className="no-projects-message text-[#fff] text-center pt-10">No venue found.</p>
                    )}
                </Scrollbars>
            </div>
        </div>
    );
};

export default LeftBarMaintenance;
