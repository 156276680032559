import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useClickAway } from '@uidotdev/usehooks';
import RolesDetails from './RolesDetails';
import CreateEditRoles from './CreateEditRole';
import WarningRoles from './WarningRoles';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FIND_ROLES, GET_ME } from '../../../Utils/GraphqlQueries';
import Moment from 'react-moment';

const demoRoles = [
    // {
    //     role_id: 1,
    //     role_title: 'Admin',
    //     created_at: '26/10/2024 6:30pm',
    //     created_by_name: 'Shakil Ahmed',
    //     role_access: [
    //         { role_id: 1, accessName: 'File Hub', status: "Active", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //         { role_id: 2, accessName: 'Task dashboard', status: "Deactive", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //     ]
    // },
    // {
    //     role_id: 2,
    //     role_title: 'Member',
    //     created_at: '15/08/2024 2:00pm',
    //     created_by_name: 'Jane Doe',
    //     role_access: [
    //         { role_id: 1, accessName: 'File Hub', status: "Active", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //         { role_id: 2, accessName: 'Task dashboard', status: "Deactive", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //     ]
    // },
    // {
    //     role_id: 3,
    //     role_title: 'Guest',
    //     created_at: '01/07/2024 10:30am',
    //     created_by_name: 'John Smith',
    //     role_access: [
    //         { role_id: 1, accessName: 'File Hub', status: "Active", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //         { role_id: 2, accessName: 'Task dashboard', status: "Deactive", created_at: '26/10/2024 6:30pm', created_by_name: 'Shakil Ahmed' },
    //     ]
    // },
];

const RoleManagementHome = () => {
    const [createPopup, setCreatePopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [roles, setRoles] = useState(demoRoles);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roleDetailsPopup, setRoleDetailsPopup] = useState(false);
    const [deleteRolePopup, setDeleteRolePopup] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState(null);
    const [openOptions, setOpenOptions] = useState(null);
    const [editRoles, setEditRoles] = useState(null); // Added state for managing module edit
    const [sortKey, setSortKey] = useState('role_title');
    const [sortOrder, setSortOrder] = useState('asc');
    const client = useApolloClient();
    // const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const cacheMe = client.readQuery({ query: GET_ME });
    const navigate = useNavigate();

    const { loading: rolesLoading, data: rolesData } = useQuery(FIND_ROLES, { variables: { company_id: cacheMe.me.company_id }, fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (rolesData) {
            // console.log('rolesData 65', rolesData.roles.data);
            setRoles(rolesData.roles.data)
        }
    }, [rolesData])

    // useEffect(()=>{
    //     console.log(39,roles)
    // }, [roles])

    const handleGoBack = () => {
        navigate(-1);
    };

    const ref = useClickAway(() => {
        setOpenOptions(null);
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleModuleClick = (role) => {
        setSelectedRole(role);
        setRoleDetailsPopup(true);
    };

    const handleSortChange = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const handleMoreOptionsClick = (roleId) => {
        setOpenOptions((prev) => (prev === roleId ? null : roleId));
    };

    const handleEditRoles = (role) => {
        setEditRoles(role);
        setCreatePopup(true);
        setOpenOptions(null);
    };

    const handleDeleteModule = (role) => {
        setRoleToDelete(role);
        setDeleteRolePopup(true);
        setOpenOptions(null);
    };

    const filteredRoles = roles
        .filter(role => role.role_title.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            const aValue = a[sortKey];
            const bValue = b[sortKey];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <>
            <div className='right_container'>
                <div className='adminRightHead'>
                    <span className="user_Management_title text-5xl">Role Management</span>
                </div>
                <div className='usersTabSection'>
                    <div className="usersTabSecDevide leftSide" style={{ gap: '10px' }}>
                        <div className={`usersTabList teamManagementTitle`}>
                            <div className='cursor-pointer'><p>Role List ({roles.length})</p></div>
                        </div>
                    </div>
                    <div className='usersTabSecDevide rightSide'>
                        <span className='relative'>
                            <input
                                className="userSearchInput"
                                type="text"
                                placeholder="Search role"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <span onClick={() => setSearchTerm('')} className="leftSearchCloseBtn remove"></span>
                        </span>
                        <button onClick={() => { setEditRoles(null); setCreatePopup(true); }} className="userCreateBtn tooltip5">
                            Create New Role
                        </button>
                    </div>
                </div>
                <div className='usersTableContainer'>
                    <div className="userTableHead">
                        <ul>
                            <li className={`_userName ${sortKey === 'role_title' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`}
                                style={{ width: "45%" }} onClick={() => handleSortChange('role_title')}>
                                <span className="sortIcons"></span>Role Name
                            </li>
                            <li className={`_userEmail !justify-center ${sortKey === 'usersCount' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`} style={{ width: "25%" }}>
                                No. of user
                            </li>
                            <li className={`_userRole !pl-0 ${sortKey === 'created_at' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`} style={{ width: "25%" }}>
                                Created Date
                            </li>
                            <li className="_userAction" style={{ width: "5%" }}>Action</li>
                        </ul>
                    </div>
                    <div className='userTableBody'>
                        {filteredRoles.length > 0 ? (
                            filteredRoles.map((role) => (
                                <div key={role.role_id} className='relative'>
                                    <ul className="_userList">
                                        <li className="_userName !w-[45%]" onClick={() => handleModuleClick(role)}>
                                            <span className="_userNameTxt">{role.role_title}</span>
                                        </li>
                                        <li className="_userName !text-center !block !w-[25%]">
                                            <span className="_userNameTxt">{role.role_access.length}</span>
                                        </li>
                                        <li className="_userName !pl-0 !w-[25%]">
                                            <span className="_userNameTxt"><Moment format="MMM Do, YYYY">{role.created_at}</Moment> <br />
                                                <span className="_userNameTxt !text-gray-400">by {role.created_by_name}</span>
                                            </span>
                                        </li>
                                        <li onClick={() => handleMoreOptionsClick(role.role_id, 'more')} className="_userAction !text-center relative !justify-center !w-[5%]">
                                            <span className="userActionIcon"></span>
                                        </li>
                                    </ul>

                                    {openOptions === role.role_id && (
                                        <ul ref={ref} className="userActionPopup !absolute">
                                            <li onClick={() => handleEditRoles(role)} className="!text-start">Edit Role</li>
                                            <li onClick={() => handleDeleteModule(role)} className="!text-start">Delete Role</li>
                                        </ul>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="noResults !text-center mt-48">
                                <p>No roles found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {createPopup && (
                <CreateEditRoles
                    setCreatePopup={setCreatePopup}
                    roles={roles}
                    company_id={cacheMe.me.company_id}
                    setRoles={setRoles}
                    editRoles={editRoles}
                    setEditRoles={setEditRoles}
                />
            )}
            {selectedRole && roleDetailsPopup &&
                <RolesDetails
                    selectedRole={selectedRole}
                    setRoles={setRoles}
                    setSelectedRole={setSelectedRole}
                    setRoleDetailsPopup={setRoleDetailsPopup}
                />
            }
            {deleteRolePopup &&
                <WarningRoles
                roles={roles}
                setRoles={setRoles}
                    setRoleToDelete={setRoleToDelete}
                    roleToDelete={roleToDelete}
                    setDeleteRolePopup={setDeleteRolePopup} />}
        </>
    );
};

export default RoleManagementHome;