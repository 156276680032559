import React, { useState, useEffect, useRef } from 'react';
import moment from "moment-timezone";
import classNames from 'classnames';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import { MdOutlineCancel } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';
import { useMutation } from '@apollo/client';
import { Read_task_notification } from '../../../Utils/GraphqlMutaion';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';

const TaskDescriptionsPopup = ({ setTaskDescriptionsPopup }) => {
  const { setSelectedTask, set_tasks, OnUpdateTask, selectedTask, user, users } = useTaskContext()
  const textareaRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(selectedTask?.description);
  const [descriptionBy] = useState(getONLYUSERSDATA(users, selectedTask?.description_by, 'name'));
  const [descriptionAt] = useState(moment(selectedTask?.description_at).format('MMMM D, YYYY h:mm A'));

  useEffect(() => { setDescription(selectedTask?.description); }, [selectedTask]);

  const [read_notification] = useMutation(Read_task_notification, {
    onCompleted: (res) => {
      if (res.read_task_notification.success === 'true') {
        setSelectedTask(prev => ({ ...prev, view_description: [] }))
        set_tasks(prev => prev.map(m => m._id === selectedTask._id ? { ...m, view_description: [] } : m))
      }
    }
  });

  useEffect(() => {
    if (selectedTask && selectedTask?.view_description?.indexOf(user?.id) > -1) {
      read_notification({
        variables: { input: { _id: selectedTask?._id, type: 'view_description' } }
      }); // Read the notification
    }
  }, [selectedTask, user, read_notification]);


  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    const focusTextarea = () => {
      if (isEditing) {
        textareaRef.current.focus();
      }
    };
    const timeoutId = setTimeout(focusTextarea, 0);
    return () => clearTimeout(timeoutId);
  }, [isEditing]);

  useEffect(() => {
    if (textareaRef.current) {
      const textLength = description.length;
      textareaRef.current.selectionStart = textLength;
      textareaRef.current.selectionEnd = textLength;
      textareaRef.current.focus();
    }
  }, [description]);

  const handleBlur = () => {
    if (isEditing) {
      OnUpdateTask({
        description: description,
        description_by: user.id,
        description_at: new Date(),
        save_type: 'description'
      })
    }
  }
  const handleDesChange = (e) => {
    const { value } = e.target;

    // If the value is empty or the user is clearing the input (backspace case)
    if (value === '') {
      setDescription(value); // Allow clearing the input
      return;
    }

    // Validate the input
    if (validFirstChercterAlsoNuber(value)) {
      console.warn('Invalid input for title'); // Log or show an error message
      return;
    }

    // Trim spaces from the start of the value
    const trimmedValue = value.trimStart();

    // If the title is empty or contains only spaces, prevent further updates
    if (!trimmedValue) {
      console.warn('Title cannot be empty or contain only spaces');
      return;
    }

    // Update state with the validated value
    setDescription(trimmedValue);
  };

  return (
    <div className="trackCostPopUpCon">

      <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
        <div className="backToMainProperties">
          <span onClick={() => setTaskDescriptionsPopup(false)} className="closeAdminPanel mt-2" data-for="descloseTooltip" data-tip="Back" ></span>
          <span className="backtoProperties">Description</span>
        </div>
      </div>
      <div className={classNames('Track_body bodyArea')} onClick={handleEditClick}>
        <div className='middle_area' style={{ height: 'calc(100vh - 65px)', padding: '20px', paddingTop: '0' }}>
          <div className="lastReviewed_History">
            <p className="pt_date text-start" data-tip={`Last updated by ${descriptionBy} on ${descriptionAt}`} data-for="lastReviewed">
              {`Last updated by ${descriptionBy} on ${descriptionAt}`}
            </p>
          </div>

          <div className={`yourNoteIndex overlay-container ${isEditing ? 'editing' : ''}`}>
            {!isEditing && <div className="edit-icon">Click to update description</div>}
            <textarea ref={textareaRef} value={description} onChange={handleDesChange} onBlur={handleBlur} className="youtnotesTextArea forNoteAndDes" rows={Math.max(description.split('\n').length, 20)} placeholder="Write a description..." readOnly={!isEditing}></textarea>
            {description !== '' && isEditing ? <MdOutlineCancel className=" absolute right-0 top-[45%] text-red-600 text-xl" onClick={() => setDescription('')} /> : ''}
            {!isEditing && <div className="overlay"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDescriptionsPopup;