const sortArrayByKey = (array, key, order = 'asc') => {
  if (!Array.isArray(array)) {
    console.error('Invalid array provided. Defaulting to an empty array.');
    return [];
  }

  if (array.length === 0) {
    // Return the array without a warning if an empty array is valid.
    return [];
  }

  if (typeof key !== 'string' || key.trim() === '') {
    console.error('Invalid key provided. Returning the original array.');
    return array;
  }

  const sortedArray = [...array].sort((a, b) => {
    if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
      console.warn(`Key "${key}" does not exist in some objects. Returning the original array.`);
      return 0;
    }

    if (a[key].toLowerCase() > b[key].toLowerCase()) return order === 'asc' ? 1 : -1;
    if (a[key].toLowerCase() < b[key].toLowerCase()) return order === 'asc' ? -1 : 1;
    return 0;
  });

  return sortedArray;
};

export default sortArrayByKey;
