import React, { useRef } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const EodBarSnap = ({ performanceSnapshot }) => {
  // console.log("performanceSnapshot",performanceSnapshot);
  const chartRef = useRef(null);

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    chartRef.current.render();
  };

  // Extract data from performanceSnapshot
  const chartData = [
    { label: "Today", y: parseFloat(performanceSnapshot.sales.today) || 0 },
    { label: "Yesterday", y: parseFloat(performanceSnapshot.sales.yesterday) || 0 },
    { label: "Average Per Day", y: parseFloat(performanceSnapshot.sales.averagePerDay) || 0 },
    { label: "This Month-to-Date", y: parseFloat(performanceSnapshot.sales.monthToDate) || 0 },
  ];

  const options = {
    theme: "light",
    animationEnabled: true,
    animationDuration: 3000,
    title: {
      text: `Sales Analysis - ${performanceSnapshot.venue}`,
    },
    axisX: {
      title: "Sales",
      labelAngle: -45,
    },
    axisY: {
      title: "Amount ($)",
      includeZero: true,
      labelFormatter: function (e) {
        return "$" + e.value.toLocaleString(); // Add dollar sign and format number with commas
      },
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: [
      {
        type: "column",
        name: "Sales Data",
        showInLegend: true,
        yValueFormatString: "$#,##0.##",
        dataPoints: chartData,
        dropShadow: {
          enabled: true,
          opacity: 0.6,
          angle: 45,
          distance: 5,
          blur: 10,
          color: "#888",
        },
      },
    ],
  };

  return (
    <CanvasJSChart options={options} onRef={(ref) => (chartRef.current = ref)} />
  );
};

export default EodBarSnap;