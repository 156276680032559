/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MUTE_CONVERSATION_MUTATION } from '../../Utils/GraphqlMutaion';

const muteOptions = [
  { label: 'Until I turn it back on', value: '20Y' },
  { label: 'For 30 minutes', value: '30M' },
  { label: 'For 1 Hour', value: '1H' },
  { label: 'For 12 Hours', value: '12H' },
  { label: 'For 1 Day', value: '1D' },
  { label: 'For 1 Month', value: '1M' },
];

const MuteNotifications = () => {
  const navigate = useNavigate()
  const user = useSelector(state => state.message.user)
  const [createMute] = useMutation(MUTE_CONVERSATION_MUTATION);
  const activeConv = useSelector((state) => state.message.active_conversation);
  const [alreadyMuted, setAlreadyMuted] = useState(false);
  const [muteDuration, setMuteDuration] = useState('20Y');

  useEffect(() => {
    if (!activeConv) return;

    const convMute = activeConv.mute || [];
    let myMute = null;

    for (const m of convMute) {
      try {
        // If the entry looks like concatenated JSON, split it into separate objects
        const entries = typeof m === 'string' ? m.match(/({.*?})/g) : [m];
        console.log("🚀 ~ useEffect ~ entries:", entries)

        if (entries) {
          for (const entry of entries) {
            const muteEntry = typeof entry === 'string' ? JSON.parse(entry) : entry;
            console.log("🚀 ~ useEffect ~ muteEntry:", muteEntry)
            if (muteEntry?.mute_by === user.id) {
              myMute = muteEntry;
              break; // Exit inner loop on match
            }
          }
        }
      } catch (error) {
        console.error("Error parsing mute entry:", m, error);
      }

      if (myMute) break; // Exit outer loop on match
    }
    console.log("🚀 ~ useEffect ~ activeConv:", activeConv)
    console.log("🚀 ~ useEffect ~ myMute:", myMute)

    if (myMute) {
      setMuteDuration(myMute.mute_duration);
      setAlreadyMuted(true);
    }
  }, [activeConv, user.id]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ muteDuration", muteDuration);
  }, [muteDuration]);



  const handleMuteSelection = (duration) => setMuteDuration(duration);

  const calculateEndTime = (duration) => {
    const now = moment();
    const mapping = {
      '30M': { amount: 30, unit: 'minutes' },
      '1H': { amount: 1, unit: 'hour' },
      '12H': { amount: 12, unit: 'hours' },
      '1D': { amount: 1, unit: 'day' },
      '1M': { amount: 1, unit: 'month' },
      '20Y': { amount: 20, unit: 'years' },
    };
    const { amount, unit } = mapping[duration] || {};
    return now.add(amount, unit).format('llll');
  };

  const handleSubmitMute = async () => {
    const nowTime = moment().format('llll');
    const endTime = calculateEndTime(muteDuration);

    const input = {
      conversation_id: activeConv?.conversation_id,
      mute_duration: muteDuration,
      mute_start_time: nowTime,
      mute_end_time: endTime,
      mute_timezone: moment().format('Z'),
      type: alreadyMuted ? 'update' : 'add',
    };

    try {
      await createMute({ variables: { input } });
      navigate(-1);
    } catch (error) {
      console.error('Error submitting mute:', error);
    }
  };


  const handleClearMute = async () => {
    const input = {
      conversation_id: activeConv?.conversation_id,
      type: 'delete',
    };

    try {
      await createMute({ variables: { input } });
      navigate(-1);
    } catch (error) {
      console.error('Error clearing mute:', error);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') navigate(-1);
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [navigate]);

  return (
    <div className="backwrap">
      <div className="muteModal">
        <div className="muteModalHead">
          <h4 className="popupTitle">Mute notification for this room</h4>
          <span className="closeModal" onClick={() => navigate(-1)} data-tip="Close" />
        </div>
        <div className="muteModalBody">
          <div className="mutePopLabel">
            <span>Please select one of the mute options.</span>
          </div>
          <div className="muteContainer">
            {muteOptions.map((option) => (
              <div key={option.value} onClick={() => handleMuteSelection(option.value)}
                className={`muteItem ${muteDuration === option.value ? 'active' : ''}`}
              >
                <span className="muteRadio" />
                <p className="muteTitle">{option.label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="muteModalFoot">
          <button className="btnCancel" onClick={() => navigate(-1)}>Cancel</button>
          {alreadyMuted && <button className="btnCancel clear_btn danger" onClick={handleClearMute}>Unmute</button>}
          <button className="btnAction" onClick={handleSubmitMute} >
            {alreadyMuted ? 'Update' : 'Mute'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MuteNotifications;