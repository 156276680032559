import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import SalesBarChart from './SalesBarChart';
import moment from 'moment';
import Select, { components } from 'react-select';
import { MdClear, MdOutlineClose } from 'react-icons/md';
import { FaAngleDown } from 'react-icons/fa6';
import MaintenancePieChart from './MaintenancePieChart';

const demoVenueList = [
    { venue_id: 1, venue_title: 'Venue A' },
    { venue_id: 2, venue_title: 'Venue B' },
    { venue_id: 3, venue_title: 'Venue C' },
];

const demoEodData = {
    today: 1200,
    yesterday: 900,
    thisWeek: 4500,
    thisMonth: 15000,
    thisYear: 120000,
};

const SalesReportTab = () => {
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [visible, setVisible] = useState(true);
    const [fade, setFade] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleVenueChange = (selectedOptions) => {
        setSelectedVenues(selectedOptions.map(option => option.venue_id));
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <FaAngleDown className='rightArrowSelectIcon' style={{
                    color: '#858fa3',
                    transition: 'transform 0.2s',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                }} />
            </components.DropdownIndicator>
        );
    };

    const ClearIndicator = (props) => {
        const {
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;

        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >

                {
                    selectedVenues.length > 1 && <div className='clearAllContainer' style={{ padding: '0px 5px' }}>
                        <MdClear className='clearAllIconSelect' />
                    </div>
                }
            </div>
        );
    };

    const customStyles = {
        control: base => ({
            ...base,
            maxHeight: 70,
            overflow: 'auto',
            backgroundColor: "white",
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
        }),
        placeholder: defaultStyles => ({
            ...defaultStyles,
            color: '#858fa3',
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer',
            color: state.isFocused ? 'blue' : 'black',
        }),
        indicatorSeparator: () => { },

    };


    useEffect(() => {
        const showTimer = setTimeout(() => {
            setVisible(true);
            setFade(true); // Start fade-in effect
        }, 1000); // Show after 1 seconds

        const hideTimer = setTimeout(() => {
            setFade(false); // Start fade-out effect
            setTimeout(() => setVisible(false), 500); // Wait for fade-out to complete
        }, 10000); // Hide after 10 seconds

        return () => {
            clearTimeout(showTimer);
            clearTimeout(hideTimer);
        };
    }, []);

    return (
        <>
            <div className='salesReportTabCon'>
                <div className='salesReportMainCon'>
                    <div className='eodReportDateAreaHeader'>
                        <div className='salesReportLeft'>
                            <p className='salesReportDateText'>{moment().format("MMMM Do, YYYY")}</p>
                            <div className='selectVenueRight'>
                                <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={demoVenueList}
                                    getOptionLabel={(option) => option.venue_title}
                                    getOptionValue={(option) => option.venue_id}
                                    styles={customStyles}
                                    value={demoVenueList.filter(v => selectedVenues.includes(v.venue_id))}
                                    onChange={handleVenueChange}
                                    placeholder='Select venues'
                                    onMenuOpen={() => setIsDropdownOpen(true)}
                                    onMenuClose={() => setIsDropdownOpen(false)}
                                    components={{ DropdownIndicator, ClearIndicator }}
                                />
                            </div>
                        </div>
                        <div className='salesReportRightCon'>
                            {visible && (
                                <div className={`salesReportRightText ${fade ? 'show' : ''}`}>
                                    <p>Sales data is based on End of Day reports</p>
                                    <MdOutlineClose className='salesReportRightTextIcon' onClick={() => setVisible(false)} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='salesReportGraphHead'>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.today || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>Today's sales</p>
                        </div>
                        {/* <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.yesterday || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>Yesterday's sales</p>
                        </div> */}
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisWeek || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This week till date</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisMonth || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This month till date</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisYear || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This year till date</p>
                        </div>
                    </div>
                </div>
                <div className='salesProgressBarCon'>
                    <SalesBarChart />
                    <MaintenancePieChart />
                </div>
            </div>
        </>
    );
};

export default SalesReportTab;