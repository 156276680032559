/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router';
import WarningPopup from '../../Connect/WarningPopup';
import { GET_ME } from '../../../Utils/GraphqlQueries';
import { UPDATE_USER_MUTATION } from '../../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation } from '@apollo/client';
import moment from 'moment';
import { upload_obj } from '../../../Utils/Common';
import { Toaster, toast } from "react-hot-toast";
import defaultImage from '../../../media/images/img.png';
import RoleAccess from './RoleAccess';

// https://wfss001.freeli.io/profile-pic/Photos/img.png

const UserRolePage = ({user, setSelectedUser}) => {
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    // const user = cacheMe.me;
    const navigate = useNavigate();
    // const [copied, setCopied] = useState(false);
    const [update] = useMutation(UPDATE_USER_MUTATION);
    const [isEdited, setIsEdited] = useState(false);
    const [imgFound, setImgFound] = useState(user.img || "https://wfss001.freeli.io/profile-pic/Photos/img.png");
    // const [fileLoader, setFileLoader] = useState(false);
    const [userdata, setUserData] = useState({
        firstname: user.firstname,
        lastname: user.lastname,
        img: user.img || "https://wfss001.freeli.io/profile-pic/Photos/img.png",
        phone: user.phone ? user.phone[0] : '+880',
        email: user.email,
        company_id: user.company_id,
        role: user.role,
        access: user.access
    });

    const submit = async () => {
        try {
            await update({
                variables: {
                    input: {
                        firstname: userdata.firstname,
                        lastname: userdata.lastname,
                        img: userdata.img,
                        phone: userdata.phone ? userdata.phone : '+880',
                        user_id: user.id
                    }
                }
            });
            window.location.href = '/';
        } catch (error) {
            console.log(error);
        }
    };

    const handleInput = (value, fieldName) => {
        setUserData({ ...userdata, [fieldName]: value });
        setIsEdited(true);
    };

    const uploadFiles = async (_f) => {
        // setFileLoader(true);
        var data = new FormData();
        data.append("bucket_name", 'profile-pic');
        var sl = moment().format('x');
        _f[0]['originalname'] = _f[0].name;
        _f[0]['mimetype'] = _f[0].type;
        _f[0]['fieldname'] = 'file_upload';
        data.append("sl", sl);
        data.append("file_upload", _f[0]);
        const config = {};
        upload_obj(data, config, (res) => {
            // console.log('Uploaded file:', res);
            setUserData({ ...userdata, img: res.file_info[0].key });
            setImgFound(res.file_info[0].location);
            // setFileLoader(false);
            setIsEdited(true);
        });
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleGoBack = () => {
        // navigate(-1);
        setSelectedUser(null);
    };

    const [activeTab, setActiveTab] = useState('profileTab');
    const [resetPopUp, setResetPopUp] = useState(false);

    const handleResetPopup = () => {
        setResetPopUp(!resetPopUp);
    };

    const copyLinkToClipboard = () => {
        const link = document.getElementById('conferenceLinkText');
        link.select();
        document.execCommand('copy');
        // setCopied(true);
        toast.success('Link copied to clipboard');
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                navigate(-1);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const removeImage = () => {
        setUserData({ ...userdata, img: "https://wfss001.freeli.io/profile-pic/Photos/img.png" });
        setImgFound(defaultImage);
        setIsEdited(true);
    };

    return (
        <>
            <Toaster />
            <div className="createGroupConv userUpdatePopup">
                <div className="createConv_head" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
                    <span onClick={handleGoBack} data-tip="Back" className="!left-[50px] createConv_back profileBack" currentitem="false"></span>
                    <p className="profileTitle !left-19" style={{ top: '22px' }}>
                        <span className="userProfileName">{userdata.firstname + ' ' + userdata.lastname}</span>
                    </p>
                    <span onClick={handleGoBack} data-tip="Close" className="createConv_close profileClose" currentitem="false"></span>
                </div>
                <div className="createConv_body" style={{ padding: '8px 120px', height: 'calc(100% - 100px)' }}>
                    <div className="usersTabSecDevide" style={{ marginBottom: '15px' }}>
                        <div id="profileTab" className={`usersTabList teamManagementTitle ${activeTab === 'profileTab' ? 'usersTabListActive active' : ''}`} onClick={() => handleTabClick('profileTab')}>Profile settings</div>
                        {
                            cacheMe.id === user.id &&   <div id="security" className={`usersTabList teamManagementTitle ${activeTab === 'security' ? ' usersTabListActive active' : ''}`} onClick={() => handleTabClick('security')} >Role and Access </div>
                        }  
                    </div>
                    {
                        activeTab === 'profileTab' ? <div className="userProfileArea">
                            <div className="ProfileTab relative">
                                <div className="name_and_profile relative">
                                    <div className="inputGroup">
                                        <label className="inputLabel">First name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" className="firstName disabled" disabled name="firstname" placeholder="First Name" required="" value={userdata.firstname} onChange={(e) => handleInput(e.target.value, 'firstname')} />
                                    </div>
                                    <div className="inputGroup">
                                        <label className="inputLabel">Last name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" className="lastName disabled" disabled name="lastname" placeholder="Last Name" required="" value={userdata.lastname} onChange={(e) => handleInput(e.target.value, 'lastname')} />
                                    </div>
                                    <div className="inputGroup">
                                        <label className="inputLabel">Email address</label>
                                        <input className="disabled" disabled type="email" name="email" placeholder="User Email" value={userdata.email} onChange={(e) => handleInput(e.target.value, 'email')} />
                                    </div>

                                    <div className="inputGroup" style={{ position: 'relative' }}>
                                        <label className="inputLabel" phone="880">Phone number (optional)</label>
                                        <PhoneInput
                                            className="inputClass userPhone disabled" 
                                            inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                                            disabled
                                            enableSearch={true}
                                            preferredCountries={["bd", "us", "ca", "in"]}
                                            country="bd"
                                            value={userdata.phone}
                                            onChange={(value) => handleInput(value, 'phone')}
                                            inputProps={{
                                                placeholder: "(000) 000-0000",
                                            }}
                                        />
                                        <span className="rgInputMsg">This is for verification and security purposes only. We will never share your phone number with anyone.</span>
                                    </div>
                                    {/* <div className="inputGroup conferenceLink">
                                        <div className="reset-link-div">
                                            <label className="inputLabel">Call link</label>
                                            <label className="createUrl_reset" data-for="voip_tooltip" data-tip="Reset call link if you found the link vulnerable" currentitem="false" onClick={handleResetPopup}>Reset link</label>
                                            <div className="opt_icons conv_voice" data-for="voip_tooltip" data-tip="Open a conference call to allow other to join here" currentitem="false"></div>
                                        </div>
                                        <span>
                                            <div onClick={copyLinkToClipboard} className="copyIcon"><img title='Copy' data-for="updateConv_tooltip" data-tip="Click to copy link" className="copyMeLink profile" src="/media/images/copyLink.svg" alt="copy" /></div>
                                            <input id="conferenceLinkText" name="conferenceLinkText" readOnly type="text" className="team-name short_id_input" placeholder="" value="https://cacdn01.freeli.io/u/nXfc7hepeGBWyayzgjBahC" />
                                        </span>
                                        <span className="rgInputMsg">Share this link with teammates or guests to start a video/voice call.</span>
                                    </div> */}
                                </div>
                                <div className="name_and_profile relative">
                                    <p className="set_avatar">Set an avatar</p>
                                    <div className="upload_section flex-col user_up relative">
                                        <label className="img-div">
                                            <div className="content-overlay"></div>
                                            <img title="Image" src={imgFound} className={'roomUploadImage'} alt="img" />
                                            {/* <div className="removeAndChange" style={removeImage ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}>
                                                <div className="changeButton">
                                                    <input type="file" id="upload_conv_img" onChange={(e) => uploadFiles(e.target.files)} name="photos" accept="image/x-png,image/jpeg,image/jpg" hidden />
                                                    <label htmlFor="upload_conv_img">Change</label>
                                                </div>
                                            </div> */}
                                        </label>
                                        {/* {
                                            userdata?.img === "https://wfss001.freeli.io/profile-pic/Photos/img.png" ? "" : <div className="bg-[#E82A87] cursor-pointer z-10 -mt-16 px-2 text-white text-sm rounded-md" onClick={removeImage}>Remove</div>
                                        } */}

                                    </div>
                                </div>
                            </div>
                        </div> : <RoleAccess thisUser={userdata} />
                    }
                </div>
                {
                    isEdited &&

                    <div className="createConv_foot">
                        <button className="createConv_create mr-3" onClick={() => handleGoBack()}>Back</button>
                        <button className="createConv_create" onClick={submit}>Update</button>
                    </div>
                }
            </div>
            {
                resetPopUp && <WarningPopup
                    messageTitle={'Reset call link'}
                    description={'Are you sure you want to reset call link?'}
                    closePopUp={handleResetPopup} />
            }
        </>
    );
};

export default UserRolePage;
