/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import KanbanBoard from './KanbanBoard';
import { useTaskContext } from '../TaskContext';

const columns = {
    kanban: [
        { id: 'Not Started', title: 'Not Started' },
        { id: 'In Progress', title: 'In Progress' },
        { id: 'On Hold', title: 'On Hold' },
        { id: 'Canceled', title: 'Canceled' },
        { id: 'Completed', title: 'Completed' },
    ],
    kanban_progress: [
        { id: "0", title: "Not Defined", },
        { id: "25", title: "Stage 1", },
        { id: "50", title: "Stage 2", },
        { id: "75", title: "Stage 3", },
        { id: "100", title: "Final stage", },
    ],
    kanban_dueby: [
        { id: "unset_due_Date", title: "Unset Due date" },
        { id: "overdue", title: "Overdue" },
        { id: "0", title: "Due Today" },
        { id: "1", title: "Due Tomorrow" },
        { id: "7", title: "Next 7 days" },
        { id: "30", title: "Next 30 days" },
        { id: "60", title: "Next 60 days" },
        { id: "90", title: "Next 90 days" },
        { id: "180", title: "Next 180 days" },
        { id: "completed", title: "Completed tasks" }
    ]
}
function KanbanHome() {
    const { tasks, viewType } = useTaskContext();
    let column = columns[viewType]

    return (
        <div className='w-full' style={{ height: 'calc(100% - 117px)' }}>
            {<KanbanBoard column_list={column} tasksData={tasks} />}
        </div>
    )
}

export default KanbanHome;
