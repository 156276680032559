import React, { useState } from 'react';
const CreatePayments = ({ paymentsData = [], setPaymentsData, paddingTopBotom = 4, type }) => {
  // console.log("CreatePayments",paymentsData);
  
  // const [paymentsData, setPaymentsData] = useState([
  //   { type: "Interac", qty: 8, amount: 826.27 },
  //   { type: "Visa Off", qty: 18, amount: 3231.62 },
  //   { type: "MC Off", qty: 12, amount: 2469.99 },
  //   { type: "CASH (0.00CAD)", qty: 5, amount: 297.80 },
  //   { type: "AMEX Off", qty: 9, amount: 1896.72 },
  //   { type: "RNDADJST", qty: 2, amount: -0.04 },
  // ]);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...paymentsData];
    updatedData[index][field] = field === "type" ? value : parseFloat(value) || 0;
    setPaymentsData(updatedData);
  };

  const handleAddRow = () => {
    setPaymentsData([...paymentsData, { type: "", qty: 0, amount: 0 }]);
  };

  const handleRemoveRow = (index) => {
    const updatedData = paymentsData.filter((_, i) => i !== index);
    setPaymentsData(updatedData);
  };

  const calculateTotal = (key) =>
    paymentsData.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);

  return (
    <div className="p-6 bg-blue-50 w-full eodBackground">
      <h2 className="text-[#0b1f47] text-[32px]  mb-4">Payments</h2>
      <div className="rounded-[10px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
        {/* Table Header */}
        <div className="flex gap-[5px] pt-[33px] pb-[13px] px-[20px]">
          <div className="w-[50%]  text-[18px] text-left text-[#0b1f47]">Type</div>
          <div className="w-[25%]  text-[18px]  text-left text-[#0b1f47]">Qty</div>
          <div className="w-[25%]  text-[18px]  text-left text-[#0b1f47]">Amount</div>

        </div>

        {/* Table Rows */}
        {paymentsData.map((payment, index) => (
          <div
            key={index}
            className={`flex gap-[20px] text-sm items-center  py-${paddingTopBotom} px-[20px]`}
          >
            <div className="w-[50%]  text-[14px]  text-[#0b1f47]">{payment.type}</div>
            <div className="w-[25%] text-left  text-[14px]  text-[#0b1f47]">{payment.qty}</div>
            <div className="w-[25%] text-left  text-[14px]  text-[#0b1f47]">
              ${payment.amount?.toFixed(2)}
            </div>

          </div>
        ))}

        {/* Total Row */}
        <div className={`flex gap-[20px] text-sm  items-center rounded-b-lg pb-[33px] mx-[20px] ${type === "dashboard" ?  "border-t border-t-[#0b1f47]" :""  }`}>
          <div className={`w-[50%] text-right  text-[18px]  text-[#0b1f47] font-medium ${type === "dashboard" ?  "":"border-t border-t-[#0b1f47]"   }`}>Total</div>
          <div className={`w-[25%] text-[18px] text-left text-[#0b1f47] font-medium ${type === "dashboard" ?  "":"border-t border-t-[#0b1f47]"   }`}>{calculateTotal("qty")}</div>
          <div className={`w-[25%] text-[18px] text-left text-[#0b1f47] font-medium ${type === "dashboard" ?  "":"border-t border-t-[#0b1f47]"   }`}>
            ${calculateTotal("amount").toFixed(2)}
          </div>

        </div>
      </div>
    </div>
  );
};

export default CreatePayments;
