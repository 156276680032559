/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, memo } from 'react';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import InviteRoomGuest from './InviteRoomGuest';
import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_ALL_USERS, GET_CATEGORIES, GET_ME, GET_SINGLE_ROOM, GET_TAGS, GET_TEAMS } from '../../Utils/GraphqlQueries';
import { useOutletContext } from 'react-router';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getONLYUSERSDATA, upload_obj } from '../../Utils/Common';
import moment from 'moment';
import { UPDATE_ROOM_MUTATION, TEAMMATE_INVITE_RESEND } from '../../Utils/GraphqlMutaion';
import { TagPopup } from '../GlobalPopups/TagPopup';
import { UserSelection } from '../GlobalPopups/UserSelection';
import { update_room_data, set_active_conversation } from '../../redux/message';
import { useDispatch, useSelector } from 'react-redux';
import defaultImage from '../../media/images/feelix.jpg';
import RoomUpdateWarnning from './RoomUpdateWarnning';
import { GET_RING_CALLING, SET_CALLING_URL, GET_CALLING_ACCEPT, GET_CALLING_REJECT } from '../../Utils/GraphqlQueries';
import { ring_calling } from '../../Utils/Common';
import WarningPopup from '../Connect/WarningPopup';

const plus = process.env.PUBLIC_URL + 'media/images/plusLarge.webp'
const copyIcon = process.env.PUBLIC_URL + '/media/images/copyLink.svg';


function hasPermission(data, myid) {
    if (data?.created_by === myid || data?.participants_admin?.indexOf(myid) > -1) {
        return true;
    } else {
        return false;
    }
}

const UpdateRoom = () => {
    const [inviteGuestRoomPopup, setInviteGuestRoomPopup] = useState(false);
    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    const [ThisRoom, setThisRoom] = useOutletContext();
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const { data: teamsData, loading: teamLoading, error: teamError } = useQuery(GET_TEAMS);
    const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = useQuery(GET_CATEGORIES);
    const active_conv = ThisRoom;
    const permission = hasPermission(active_conv, user?.id)
    const [participants, setParticipants] = useState([]);
    const [all_users, setAll_users] = useState([]);
    const [all_teams, setAll_teams] = useState([]);
    const [all_bunits, setAll_bunits] = useState([]);
    const [tag_list, setTag_list] = useState([]);
    const [loader, setLoader] = useState(false)
    const [fileLoader, setFileLoader] = useState(false)
    const [create_status, setCreate_status] = useState(false)
    const [resetLinkPopup, setResetLinkPopup] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [imgFound, setImgFound] = useState(active_conv.conv_img);
    const [showImageRemove, setShowImageRemove] = useState(true);
    const [miniList, setMiniList] = useState([]);
    const [cancelWarnning, setCancelWarnning] = useState(false);
    const [form_data, setForm_data] = useState({
        conversation_id: active_conv.conversation_id,
        title: active_conv.title,
        company_id: active_conv.company_id,
        participants: active_conv.participants,
        old_participants: active_conv.participants,
        group: active_conv.group,
        team_id: active_conv.team_id,
        privacy: active_conv.privacy,
        conv_img: active_conv.conv_img.split('/')[active_conv.conv_img.split('/')?.length - 1],
        b_unit_id: active_conv.b_unit_id,
        participants_admin: active_conv.participants_admin, //not Req
        participants_guest: active_conv.participants_guest, //not Req
        tag_list: active_conv.tag_list, //not Req
        temp_user: [],
    });
    const [seletedGuestuser, setSelectedguest] = useState([])
    const [fetchCalling] = useLazyQuery(GET_RING_CALLING);
    const [setCallingURL] = useLazyQuery(SET_CALLING_URL);
    const [getCallingAccept] = useLazyQuery(GET_CALLING_ACCEPT);
    const [getCallingReject] = useLazyQuery(GET_CALLING_REJECT);

    useQuery(GET_TAGS, {
        variables: { company_id: cacheMe.me.company_id },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            let all_tags = [...data.tags.private, ...data.tags.public]
            setTag_list(all_tags.filter(v => active_conv?.tag_list?.indexOf(v.tag_id) > -1))
        }
    });

    const makeAdmin = (type, id) => {
        if (type === 'add') {
            setForm_data({
                ...form_data,
                participants_admin: [...form_data.participants_admin, id]
            })
        } else {
            setForm_data({
                ...form_data,
                participants_admin: form_data.participants_admin.filter(v => v !== id)
            })
        }
    }

    const removeParticipants = (id) => {
        setForm_data({
            ...form_data,
            participants: form_data.participants.filter(v => v !== id)
        })
    }

    useEffect(() => {
        if (miniList?.length > 0) {
            setForm_data({
                ...form_data,
                participants: [...form_data.participants, ...miniList.map(v => v.id)],
            })
        }
    }, [miniList])

    useEffect(() => {
        let state = false;

        if (active_conv.title?.trim() !== form_data.title?.trim() && form_data.title?.trim() !== '') {
            state = true;
        } else if (active_conv.b_unit_id !== form_data.b_unit_id && form_data.b_unit_id !== '') {
            state = true;
        } else if (active_conv.tag_list?.toString() !== form_data.tag_list?.toString()) {
            state = true;
        } else if (active_conv.conv_img?.split('/')[active_conv.conv_img?.split('/')?.length - 1] !== form_data.conv_img) {
            state = true;
        } else if (active_conv.team_id !== form_data.team_id && form_data.team_id !== '') {
            state = true;
        } else if (active_conv.privacy !== form_data.privacy && form_data.privacy !== '') {
            state = true;
        } else if (active_conv.group !== form_data.group && form_data.group !== '') {
            state = true;
        } else if (active_conv.participants_admin?.toString() !== form_data.participants_admin?.toString()) {
            state = true;
        } else if (active_conv.participants_guest?.toString() !== seletedGuestuser?.map(m => m.id)?.toString()) {
            state = true;
        } else if (active_conv.participants?.toString() !== form_data.participants?.toString()) {
            state = true;
        } else {
            state = false;
        }
        setCreate_status(state)
    }, [form_data, seletedGuestuser])

    const ref = useRef(null);
    const [showLinkDiv, setShowLinkDiv] = useState(false);

    const copyToClipboard = () => {
        setShowLinkDiv(true);
        const value = ref.current.value;
        navigator.clipboard.writeText(value)
            .then(result => {
                toast.success('Already Copied', { duration: 3000 });
                setTimeout(function () {
                    setShowLinkDiv(false);
                }, 3000);
            })
            .catch(err => {
                toast.error('Something worng to copy', { duration: 3000 });
            })
    };

    const uploadFiles = async (_f) => {

        setFileLoader(true)
        var fileData = [];
        var data = new FormData();
        data.append("bucket_name", 'room-images-uploads');
        var sl = moment().format('x');
        _f[0]['originalname'] = _f[0].name;
        _f[0]['mimetype'] = _f[0].type;
        _f[0]['fieldname'] = 'file_upload';
        fileData.push(_f[0]);
        data.append("sl", sl);
        data.append("file_upload", _f[0]);
        const config = {}
        upload_obj(data, config, function (res) {
            setForm_data({ ...form_data, conv_img: res.file_info[0].key });
            setImgFound(res.file_info[0].location)
            setShowImageRemove(true)
            setFileLoader(false)
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm_data({ ...form_data, [name]: value })
    }

    const [selected_team, setSelected_team] = useState(null)
    const [selected_bunit, setSelected_bunit] = useState(null)

    const handleSelect = (e, type) => {
        if (type === 'team_id') {
            let team_participants = all_teams.filter(f => f.value === e.value)[0].participants_details;
            setParticipants(team_participants)
            setSelected_team(e)
        } else {
            setSelected_bunit(e)
        }
        setForm_data({ ...form_data, [type]: e.value })
    }

    useEffect(() => {
        if (cacheAllUsers?.users) {
            setAll_users(cacheAllUsers.users)
        }
    }, [cacheAllUsers])

    useEffect(() => {
        if (cacheAllUsers?.users && active_conv?.participants) {
            let allGuest = cacheAllUsers.users.filter(u => u.role === "Guest" && active_conv.participants.indexOf(u.id) > -1)
            setSelectedguest(allGuest)
        }
    }, [active_conv, cacheAllUsers])

    useEffect(() => {
        if (teamsData) {
            let team = []
            for (const iterator of teamsData.teams) {
                team.push({ value: iterator.team_id, label: iterator.team_title, participants_details: getONLYUSERSDATA(cacheAllUsers.users, iterator.participants, 'all') })
            }

            setParticipants(team.filter(f => f.value === active_conv.team_id)?.[0]?.participants_details)
            setSelected_team(team.filter(f => f.value === active_conv.team_id)?.[0])
            setAll_teams(team)
        }
    }, [teamsData])

    useEffect(() => {
        if (categoriesData) {
            let bunit = []
            for (const iterator of categoriesData.categories) {
                bunit.push({ value: iterator.unit_id, label: iterator.unit_name })
            }
            setSelected_bunit(bunit.filter(f => f.value === active_conv.b_unit_id)?.[0])
            setAll_bunits(bunit)
        }

    }, [categoriesData])

    const [updateRoom, { data, loading, error }] = useMutation(UPDATE_ROOM_MUTATION);
    const [resend] = useMutation(TEAMMATE_INVITE_RESEND);

    const resendLink = async (id) => {
        try {

            let res = await resend(
                {
                    variables: {
                        input: {
                            id: id
                        }
                    }
                }
            );
            if (res.data.teammate_invite_resend.status) {
                setLoader(false)
                toast.success(res.data.teammate_invite_resend.message)
            } else {
                setLoader(false)
                toast.error(res.data.teammate_invite_resend.message)
            }

        } catch (error) {
            setLoader(false)
            console.log("🚀 ~ consthandle_update_room= ~ error:", error)
        }
    }

    const handle_update_room = async () => {
        setLoader(true)
        try {
            let res = await updateRoom(
                {
                    variables: {
                        input: {
                            ...form_data,
                            participants: form_data.participants.filter(v => active_conv.participants_guest.indexOf(v) === -1),
                            participants_guest: seletedGuestuser.filter(g => !g.new).map(u => u.id),
                            new_participants_guest: seletedGuestuser.filter(g => g.new).map(u => ({ firstname: u.firstname, lastname: u.lastname, phone: u.phone, email: u.email })),
                        }
                    }
                }
            );
            if (res.data.update_room.status) {
                setLoader(false)
                toast.success(res.data.update_room.message)
                setThisRoom(res.data.update_room.data)
                dispatch(update_room_data(res.data.update_room.data))
                dispatch(set_active_conversation(res.data.update_room.data))
                navigate(`/connect/${res.data.update_room.data.conversation_id}`);
            } else {
                setLoader(false)
                toast.error(res.data.update_room.message)
            }

        } catch (error) {
            setLoader(false)
            console.log("🚀 ~ consthandle_update_room= ~ error:", error)
        } finally {
            setCancelWarnning(false); // Ensure the modal is closed after save
        }
    }

    const add_member = () => {
        if (form_data.team_id !== '') {
            navigate('users')
        }
    }

    useEffect(() => {
        if (tag_list?.length) {
            const ids = tag_list.map(tag => tag.tag_id);
            setForm_data(prev => ({ ...prev, tag_list: ids }));
        }
    }, [tag_list]);


    const removeFromMini = (tag) => {
        setTag_list(tag_list.filter(f => f.tag_id !== tag.tag_id))
    }

    const minidiv = tag_list?.map((item, i) =>
        <div className={'miniItem'} style={{ backgroundColor: item.tag_color }} key={i}>
            <div className={'miniItem_name'}>{item.title}</div>
            <div className={'miniItem_remove'} onClick={() => removeFromMini(item)}></div>
        </div>
    )

    const ShowAfterHide = () => {
        return (
            <div className="showCopyArea">
                <span className='showText'>Link copied to clipboard</span>
                <span className='showTextWithIcon'></span>
            </div>
        )
    }

    const resetAction = (action) => {
        if (action) {
            setResetLinkPopup(false)
        } else {
            setResetLinkPopup(action)
        }
    }

    const [hoveredElements, setHoveredElements] = React.useState({});

    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };
    const handleGoBack = () => {
        if (create_status) {
            setCancelWarnning(true); // Show the RoomUpdateWarnning component
        } else {
            navigate(-1); // Navigate back
        }

    };

    // const [popupCloseType, setPopupCloseType] = useState("updateRoomType");

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Escape' && popupCloseType === "updateRoomType") {
    //             navigate(-1)
    //         }
    //     };
    //     document.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);



    const removeImage = () => {
        setForm_data({ ...form_data, conv_img: "img.png" });
        setImgFound(defaultImage);
        setIsEdited(true);
        setShowImageRemove(false)
    };
    const onCancel = () => {
        setCancelWarnning(false); // Close the warning
        navigate(-1); // Navigate back
    };
    const [resetPopUp, setResetPopUp] = useState(false);
    const [shortID, setshortID] = useState(active_conv.short_id);
    const handleResetPopup = () => {
        setResetPopUp(!resetPopUp);
    };
    const meetwin = () => {
		debugger;
		ring_calling({
            user_id: user.id,
            company_id: active_conv.company_id,
            conversation_id: active_conv.conversation_id,
            conversation_type: 'group',
            convname: active_conv.title,
            participants_all: active_conv.participants,
            arr_participants: [user.id],
            call_link: window.location.origin +'/c/'+ shortID,
            call_option: 'window',
            fetchCalling, getCallingAccept, getCallingReject
        })

		// props.calling_open_btn.current.click();
	}

    return (
        <>
            {cancelWarnning && <RoomUpdateWarnning onCancel={onCancel} onSave={handle_update_room} />}
            <div className='updateGroupConv' >
                <div className="createConv_head" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
                    <span className="createConv_back updateRoom" data-for="createConv_tooltip" data-tip="Back" onClick={handleGoBack}></span>
                    <span className="createConv_close updateRoom" data-for="createConv_tooltip" data-tip="Close" onClick={handleGoBack}></span>
                    <h2 className="createConv_title" style={{ margin: '0px 0px 0px 60px' }}>{active_conv.title}</h2>
                </div>
                <div className={`${permission ? 'createConv_body' : '!h-[calc(100%-80px)] createConv_body'}`}>
                    <div className="name_and_profile">
                        <div className="inputGroup">
                            <label className="inputLabel">Title of room<span className="red_star">*</span></label>
                            <input style={permission ? {} : { background: '#D8D8D8', color: '#9f9898' }} type="text" name='title' value={form_data.title} onChange={handleChange} className="team-name" maxLength="64" placeholder="Name of the room" readOnly={!permission} />
                        </div>
                        <div className="inputGroup">
                            <label className="inputLabel">
                                Room category<span className="red_star">*</span>
                                <span style={{ marginLeft: '5px', cursor: 'pointer' }} onMouseEnter={() => handleMouseHover('element1')} onMouseLeave={() => handleMouseLeave('element1')} className="tooltip2 user_Management_title">
                                    <span className="info_tooltip_new"></span>
                                    {hoveredElements['element1'] &&
                                        <span className="tooltiptext2 inRoom" >
                                            <p> You need to select a room category when creating a new Room. This is a useful feature to help sort and categorize data such as files, images, links and working documents using the files dashboard on Workfreeli. Create custom room categories as needed.</p>
                                            <span className='tooltipClose'></span>
                                        </span>
                                    }
                                </span>
                            </label>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: permission ? '#ffffff' : '#D8D8D8',
                                    }),
                                }}
                                // inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                                value={selected_bunit}
                                className="select-ecosystem cu_inputGroup_select"
                                closeMenuOnSelect={true}
                                options={all_bunits}
                                isSearchable
                                isDisabled={!permission}
                                onChange={(e) => handleSelect(e, 'b_unit_id')}
                                placeholder={<span className="selectPlaceholder">Select a room category</span>}
                            />
                        </div>
                        <div className="inputGroup roomConferenceLink">
                            <div className="reset-link-div">
                                <label className="inputLabel">Room call link</label>
                                <label className="createUrl_reset" style={permission ? {} : { pointerEvents: 'none' }} onClick={handleResetPopup} 
                                // onClick={() => setResetLinkPopup(true)} 
                                data-for="voip_tooltip" data-tip="Reset call link if you found the link vulnerable">Reset link</label>
                                <div className="opt_icons conv_voice" data-for="voip_tooltip" data-tip="Open a conference call to allow others to join here" onClick={(e) => meetwin()}></div>
                            </div>

                            {!showLinkDiv &&
                                <div>
                                    <div className="copyIcon">
                                        <img src={copyIcon} onClick={copyToClipboard} w={16} h={20} className="copyMeLink profile" alt="copy" title='copy' />
                                    </div>
                                    <input style={permission ? {} : { background: '#D8D8D8', color: 'black' }} name="conferenceLinkText" ref={ref} type="text" value={`${window.location.origin + '/c/' + shortID}`} onChange={() => null} className="team-name short_id_input" readOnly />
                                </div>
                            }
                            {showLinkDiv && <ShowAfterHide />}
                            <span className="rgInputMsg">Share this link with teammates or guests to start a video/voice call.</span>
                        </div>
                    </div>
                    <div className="name_and_profile teamProfile" style={{ marginBottom: '40px' }}>
                        <div className="upload_section user_up roomRelated" style={permission ? {} : { background: '#D8D8D8', color: '#9f9898' }}>
                            <div className="upload_section !w-auto flex-col user_up relative" style={permission ? {} : { pointerEvents: 'none', opacity: '0.5' }}>
                                <label className={`img-div !w-[120px] !h-[120px] relative ${fileLoader && 'pointer-events-none'}`}>
                                    {fileLoader && <div className=' absolute w-full h-full flex items-center justify-center z-10 text-[14px] bg-slate-200 rounded-[50%]'>Uploading...</div>}
                                    <div className="content-overlay !w-[120px] !h-[120px]"></div>
                                    <img title="Image" src={imgFound.indexOf('img.png') > -1 ? defaultImage : imgFound} className={'roomUploadImage !w-[120px] !h-[120px]'} alt="img" />
                                    <div className="removeAndChange">
                                        <div className={`${permission ? 'changeButton' : ''}`} style={{ backgroundSize: "28px" }}>
                                            <input type="file" id="upload_conv_img" onChange={(e) => uploadFiles(e.target.files)} name="photos" accept="image/x-png,image/jpeg,image/jpg" hidden />
                                        </div>
                                    </div>
                                </label>
                                {
                                    showImageRemove && <>
                                        {
                                            imgFound.indexOf('img.png') === -1 && permission && <div onClick={removeImage} className="bg-[#E82A87] cursor-pointer text-[10px] z-10 -mt-8 px-2 text-white rounded-md">Remove</div>
                                        }
                                    </>
                                }
                            </div>
                            <form className="roomImgUp_form" encType="multipart/form-data">
                                <div className="file-up-div">
                                    <input type="file" id="upload_conv_img" onChange={(e) => uploadFiles(e.target.files)} name="photos" accept="image/x-png,image/jpeg,image/jpg" hidden disabled={!permission} />
                                </div>
                            </form>
                            <div className={`teamTextArea ${permission ? "mt-4" : "mt-0"}`}>
                                <p className="set_avatar">Set up a group image</p>
                                <p className="set_avatar_click">Click here to add a photo</p>
                            </div>
                        </div>
                        <div className="addGuest backGroundCololor" style={{ marginTop: '193px', padding: '10px 20px' }}>
                            <label className="inputLabel">
                                Shared team tags
                                <span style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onMouseEnter={() => handleMouseHover('element3')}
                                    onMouseLeave={() => handleMouseLeave('element3')}
                                    className="tooltip2 user_Management_title">
                                    <span className="info_tooltip_new"></span>
                                    {hoveredElements['element3'] &&
                                        <span className="tooltiptext2 inRoom">
                                            <p> Use Shared team tags to stay organized with your team. These are tags created by an Admin for anyone in your team to use. You can set default tags to be added to all files in this room uploaded by any room members.</p>
                                            <span className='tooltipClose'></span>
                                        </span>
                                    }
                                </span>
                            </label>

                            <div className={`selectedParticipants tag_container_mini teamTag ${tag_list?.length ?'':'hidden'}`}>
                                <div className="addArea"> {minidiv} </div>
                            </div>


                            <div className="inputGroup"
                                onMouseEnter={() => handleMouseHover('sharedTeamTagDisable')}
                                onMouseLeave={() => handleMouseLeave('sharedTeamTagDisable')}
                                style={{ marginBottom: '10px', paddingRight: '0px' }}>
                                <Link to={'tag_popup'} title='Add Shared team tag' style={permission ? {} : { pointerEvents: 'none' }}>
                                    <button className="addMemberBtn" disabled={!permission}> Add default Shared team tag(s) to this room</button>
                                </Link>
                                {hoveredElements['sharedTeamTagDisable'] && !permission &&
                                    <span className="tooltip2 roomGuest add user_Management_title ">
                                        <span className="tooltiptext2 buttonGuest !w-[350px]"><p>Only room admin(s) can perform this task</p>
                                            <span className="tooltipClose"></span>
                                        </span>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="borderarea"></div>
                    <div className="inputGroup teamInput">
                        <label className="inputLabel">Team<span className="red_star">*</span>
                        </label>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: permission ? '#fffff' : '#D8D8D8',
                                }),
                            }}
                            value={selected_team}
                            className="select-ecosystem cu_inputGroup_select"
                            closeMenuOnSelect={true}
                            options={all_teams}
                            isSearchable
                            onChange={(e) => handleSelect(e, 'team_id')}
                            isDisabled={true}
                            placeholder={<span className="selectPlaceholder">Select a team</span>}
                        />
                    </div>
                    <div className="name_and_profile">
                        <div className="addMember">
                            <div className="inputGroup" onMouseEnter={() => handleMouseHover('addMemberDisable')} onMouseLeave={() => handleMouseLeave('addMemberDisable')} style={{ marginBottom: '12px' }}>
                                <button className="addMemberBtn" style={{ pointerEvents: 'auto', opacity: 1 }} onClick={add_member} disabled={!permission}> Add member(s) to this room</button>

                                {hoveredElements['addMemberDisable'] && !permission && <span className="tooltip2 roomGuest add user_Management_title">
                                    <span className="tooltiptext2 buttonGuest !w-[350px]">
                                        <p>Only room admin(s) can perform this task</p>
                                        <span className="tooltipClose"></span>
                                    </span>
                                </span>
                                }

                                <label className="inputLabel" style={{ fontSize: '14px', marginTop: '12px', marginBottom: '0px' }}>
                                    Room member[s]
                                    <span onMouseEnter={() => handleMouseHover('element2')} onMouseLeave={() => handleMouseLeave('element2')} className="info_tooltip"></span>
                                    {hoveredElements['element2'] &&
                                        <span style={{ marginLeft: '5px', cursor: 'pointer' }} className="tooltip2 user_Management_title">
                                            <span className="tooltiptext2 roomMem" >
                                                <p>Add member(s) to this room for team collaboration.</p>
                                                <span className='tooltipClose'></span>
                                            </span>
                                        </span>
                                    }
                                </label>
                            </div>
                            <div className="addedMemberList">
                                <UserList permission={permission} users={all_users?.filter(f => f.id === active_conv?.created_by && f.role !== 'Guest')} participants={form_data.participants} participants_admin={form_data.participants_admin} user={user} makeAdmin={makeAdmin} removeParticipants={removeParticipants} created_by={true} />
                                <UserList permission={permission} users={all_users?.filter(f => f.id !== active_conv?.created_by && f.role !== 'Guest')} participants={form_data.participants} participants_admin={form_data.participants_admin} user={user} makeAdmin={makeAdmin} removeParticipants={removeParticipants} created_by={false} />
                            </div>
                        </div>
                    </div>
                    <div className="name_and_profile teamProfile">
                        <div className="addGuest" style={{ paddingLeft: '20px' }}>
                            <div
                                onMouseEnter={() => handleMouseHover('inviteGuestDisable')}
                                onMouseLeave={() => handleMouseLeave('inviteGuestDisable')}
                                className="inputGroup guestButton" style={form_data.team_id?.length > 0 ? { pointerEvents: 'auto', opacity: 1, paddingLeft: '20px', marginBottom: '12px' } : { pointerEvents: 'none', opacity: 0.5, paddingLeft: '20px', marginBottom: '12px' }}>
                                <Link to={'invite_guest'} className="addMemberBtn" disabled={!permission}>Invite guest(s) to this room</Link>

                                {hoveredElements['inviteGuestDisable'] && !permission &&
                                    <span className="tooltip2 roomGuest add user_Management_title">
                                        <span className="tooltiptext2 buttonGuest !w-[350px]">
                                            <p>Only room admin(s) can perform this task</p>
                                            <span className="tooltipClose"></span>
                                        </span>
                                    </span>
                                }

                                <label className="inputLabel" style={{ fontSize: '16px', marginTop: '12px' }}>Room guest[s]<span className="info_tooltip"></span></label>
                            </div>
                            <div className="addedGuestList teamGuest">
                                <div className="addedMemberList">
                                    {seletedGuestuser.map(u =>
                                        <div className="memberItem" style={permission ? {} : { background: '#D8D8D8', color: '#9f9898' }}>
                                            <div className="memberItemImage"><span className="nameLetters"></span></div>
                                            <div className="memberItemData">
                                                <p className="memberItemTitle" style={permission ? {} : { color: '#9f9898' }}>{u.firstname + " " + u.lastname} <span className="memberItemDesignation">[Guest]</span></p>
                                            </div>
                                            {
                                                permission &&
                                                <div className="memberItemAction">
                                                    {u.login_total === 0 &&
                                                        <span className="makeAdmin" onClick={() => { resendLink(u.id) }}>Resend</span>
                                                    }
                                                    <span className="removeAdmin" onClick={() => { setSelectedguest(prev => prev.filter(g => g.id !== u.id)); }}>Remove</span>
                                                </div>
                                            }
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {permission && create_status &&
                    <div className="createConv_foot">
                        {<button className="createConv_create" style={create_status ? {} : { pointerEvents: 'none', opacity: '0.6' }} onClick={handle_update_room}>{loader ? 'Loading..' : 'Save changes'}</button>}
                    </div>
                }
            </div>
            {params.popup === 'tag_popup' && <TagPopup type="update_room" viewType={'public'} tag_list={tag_list.map(v => v.tag_id)} setTag_list={setTag_list} />}
            {params.popup === 'users' && <UserSelection type='update_room' miniList={miniList} setMiniList={setMiniList} participants={participants.filter(f => form_data.participants.indexOf(f.id) === -1).map(v => v.id)} />}

            {params.popup === 'invite_guest' && <InviteRoomGuest type="update_room" setInviteGuestRoomPopup={setInviteGuestRoomPopup} seletedGuestuser={seletedGuestuser} setSelectedguest={setSelectedguest} />}
            {/* {resetLinkPopup && <ResetLink resetAction={resetAction} />} */}
            {
                resetPopUp && <WarningPopup
                    messageTitle={'Reset call link'}
                    description={'Are you sure you want to reset call link?'}
                    closePopUp={handleResetPopup}
                    type={"call_group_reset"}
                    setshortID={setshortID} />
            }
        </>
    );
};

const UserList = ({ users, participants, user, makeAdmin, participants_admin, removeParticipants, created_by, permission }) => {
    const OnlineUsers = useSelector((state) => state.message.online_users);
    return (
        users.filter(f => participants.indexOf(f.id) > -1).map((v, i) =>
            <div style={permission ? {} : { background: '#D8D8D8', color: '#9f9898' }} className={OnlineUsers.indexOf(v.id) > -1 ? "memberItem online" : "memberItem offline"} key={'member_' + i}>
                <div className="memberItemImage">
                    <img src={v.img} alt="profilepic" width={30} height={30} title='profile' />
                </div>
                <div className="memberItemData">
                    <p className="memberItemTitle" style={permission ? {} : { color: '#9f9898' }} >
                        {v.firstname + ' ' + v.lastname} <span className="memberItemDesignation">[{created_by ? 'Creator' : participants_admin.indexOf(v.id) > -1 ? 'Admin' : 'Member'}]</span>
                    </p>
                </div>
                {(!created_by && participants_admin.indexOf(user.id) > -1) &&
                    <div className="memberItemAction">
                        {participants_admin.indexOf(v.id) > -1 ?
                            v.id !== user.id && <span className="removeAdmin" onClick={() => makeAdmin('remove', v.id)}>Remove Admin</span>
                            :
                            <span className="makeAdmin" onClick={() => makeAdmin('add', v.id)}>Make admin</span>
                        }
                        {participants_admin.indexOf(v.id) === -1 &&
                            <span className="removeAdmin" onClick={() => removeParticipants(v.id)}>Remove</span>
                        }

                    </div>
                }
            </div>
        )
    )
}

export default memo(UpdateRoom);