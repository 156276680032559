import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { BsFillTrash3Fill } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import CreateTaskTitle from './CreateTaskTitle';
import TaskRoomSelect from './TaskRoomSelect';
import TaskAssignTo from './TaskAssignTo';
import AddKeyword from './AddKeyword';
import StartDate from './StartDate';
import { CREATE_QUICK_TASK } from '../../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation } from '@apollo/client';
import { GET_ME, GET_ALL_USERS } from '../../../Utils/GraphqlQueries';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
// import { validationFirstChercterNuber } from '../../ValidationCheck';
import { useDispatch } from 'react-redux';
import { set_new_task } from '../../../redux/taskSlice';
import DueDate from './DueDate';
import TaskObserver from './TaskObserver';
import CreateTaskProject from './CreateTaskProject';

function CreateQuickTask(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const client = useApolloClient();
  const cacheMe = client.readQuery({ query: GET_ME });
  const user = cacheMe.me;
  const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
  const [create] = useMutation(CREATE_QUICK_TASK);
  const [conversation_id] = useState(params.conversation_id && params.conversation_id !== 'tasks' ? params.conversation_id : user.id);
  const active_conversation = useSelector(state => state.message.active_conversation)
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deletePopup, setDeletePopup] = useState('');

  // Content editable
  const contentRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);


  const onChangeTagInput = (e) => {
    const input = e.target.value;

    // Limit input length to 72 characters
    if (input.length > 72) return;

    setInputValue(input);

    // // Validate input (no starting number using `validationFirstChercterNuber`)
    // const isValid = validationFirstChercterNuber(input, false); // `false` assumes non-numeric validation

    // // Update state only if valid
    // if (!isValid) {
    //   setInputValue(input);
    // } else {
    //   toast.error("Input cannot start with a number");
    // }
  };

  const saveTaskFunc = () => {
    if (inputValue === '') {
      toast.error('Input box should not be empty');
    } else {
      setValue((prev) => [...prev, {
        project_id: '',
        temp_key: new Date().getTime(),
        task_title: inputValue,
        conversation_id: conversation_id,
        participants: params.conversation_id && params.conversation_id !== 'tasks' ? active_conversation.participants : [user.id],
        conversation_name: params.conversation_id && params.conversation_id !== 'tasks' ? active_conversation.title : user.firstname + ' ' + user.lastname,
        assign_to: [],
        observers: [],
        key_words: [],
        start_date: null, // Set default to null to avoid invalid DateTime error
        end_date: null // Set default to null to avoid invalid DateTime error
      }]);
      setInputValue('');
    }
  }

  useEffect(() => {
    console.log('value:', value);

  }, [value])

  const handleKey = e => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      saveTaskFunc();
    }
  };
  const remove = (temp_key) => {
    setValue(prev => prev.filter(t => t.temp_key !== temp_key));
    setDeletePopup(null); // Close the popup after deletion
    toast.success('Successfully deleted');
  }

  useEffect(() => {
    if (contentRef.current && isFocused) {
      const range = document.createRange();
      range.selectNodeContents(contentRef.current);
      range.collapse(false);
      setIsFocused(false);
    }
  }, [isFocused]);

  const completedTask = async () => {
    // debugger;
    try {
      let changesData = [];
      for (let i of value) {
        let f = { ...i };
        delete f['temp_key'];
        changesData.push(f);
      }
      let apires = await create({
        variables: {
          input: changesData
        }
      });
      dispatch(set_new_task(apires.data.create_quick_tasks?.[0]));
      toast.success('Task created successfully');
      // if (params.conversation_id && params.conversation_id !== 'tasks') {
      //   navigate(`/connect/${params.conversation_id}/tasks/kanban-view`);
      // } else {
      // }
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  /* Task title autofocus */
  const inputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500); // Delay of 1 second

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div className='subRightContainer'>
      <div className='CreateQuickTask_container'>
        <div className='Create_Quick_Task flex justify-between items-center'>
          <div className='quick_Task_Head'>
            <div className='check_box_img'></div>
            <div className='heading_create_task'>
              <div className='heading_text'>Create a task</div>
              <div className='para_text'>Create one or more tasks below</div>
            </div>
          </div>
          <div>
            <span className="backToChat closeBtn hover:!bg-[#0D1E45]" id='closeQuickTask' style={{ marginRight: '0px', top: '34px' }} onClick={handleGoBack} ></span>
            <Tooltip anchorSelect="#closeQuickTask" place='top' content="Close"></Tooltip>
          </div>
        </div>
        <div className='quick_Task_body'>
          <div className='inputTaskAtewa'>
            <div className='inputboxArea'>
              <input ref={inputRef} onKeyDown={handleKey} value={inputValue} onChange={onChangeTagInput} className="addNewTask" placeholder='Write a task here' autoFocus={true} maxLength={72} />

              {inputValue !== '' && <span className='clearInput inv' style={{ top: '47px', right: '60px' }} onClick={() => { setInputValue('') }}></span>}
              <span className='checkList_plusIcon fotQuickTask'
                data-for="checkList_tooltip"
                style={{ position: 'absolute', top: '30px', height: '100%', cursor: 'pointer', right: '43px' }}
                data-tip={'Click to create checklist'}
                onClick={saveTaskFunc}
              >
                <i className="fa fa-plus custom_icon"></i>
              </span>
            </div>
            {inputValue.length > 0 && <p className=" ml-10 charecteLeft text-[10px]">Characters left: {72 - inputValue.length}</p>}
          </div>
          <div className='new_Task_list'>
            {value.map((item, index) => (
              <div className={`contentHover min-h-[86px] border-b border-b-[#D6E9FF] pl-10 pr-10 pb-[10px]`} key={item.temp_key}>
                <div className='min-h-[86px] flex justify-between items-center'>
                  <div className="w-[40%]">
                    <CreateTaskTitle task={item} setValue={setValue} />
                    <div className='mt-1'>
                      <TaskRoomSelect conversation_id={conversation_id} setValue={setValue} task={item} />
                    </div>
                    <div className='mt-1'>
                      <CreateTaskProject setValue={setValue} task={item} />
                    </div>
                  </div>
                  <div className={`${(item.key_words.length > 0 || item.assign_to.length > 0 || item.observers.length > 0 || item.start_date || item.end_date) ? '' : 'taskCreateRightPart'} w-[60%] flex flex-col justify-between gap-2`}>
                    <div className='flex justify-between items-center'>
                      <TaskAssignTo setValue={setValue} task={item} all_users={cacheAllUsers.users} />

                      <TaskObserver setValue={setValue} user={user} task={item} all_users={cacheAllUsers.users} />
                      <div id='delete' className='task_delete_sec cursor-pointer' onClick={() => setDeletePopup(item.temp_key)}>
                        <BsFillTrash3Fill id='delete' size='18px' className={` ${deletePopup === item.temp_key ? 'text-red-600' : 'text-[#00246e]'}`} />
                      </div>
                      {deletePopup === item.temp_key &&
                        <div className='deletedPopup !right-20 z-50'>
                          <div className='deletedPopup_text'>Are you sure you want to delete this task?</div>
                          <div className='deletedPopup_delete_btn cursor-pointer' onClick={() => remove(deletePopup)}>delete</div>
                          <div className='deletedPopup_cancel_btn cursor-pointer' onClick={() => setDeletePopup(null)}>cancel</div>
                        </div>
                      }
                    </div>
                    <div className={`flex justify-between items-center min-h-[30px]`}>
                      <StartDate setValue={setValue} task={item} />
                      <DueDate setValue={setValue} task={item} />
                      <div></div>
                    </div>
                    <div className={`flex items-center min-h-[30px]`}>
                      <div className='w-[50%]'>
                        <AddKeyword setValue={setValue} task={item} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {value.length > 0 &&
              <div className='flex gap-3 absolute bottom-8 right-8'>
                <div className='completed_button hover:bg-[#0C1F45]' onClick={completedTask}>Complete</div>
                <div className="cancel_button hover:bg-[#F02761] hover:text-white" onClick={handleGoBack}>Cancel</div>
              </div>
            }
          </div>
        </div>
      </div>
      <Tooltip anchorSelect="#delete" place='top' content="Delete"></Tooltip>
    </div>
  );
}

export default CreateQuickTask;
