
import { useEffect, useState } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import CreateTag from './CreateTag';
import { GET_TAGS, GET_ME } from '../../../Utils/GraphqlQueries';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import { Toaster, toast } from 'react-hot-toast';
import Moment from 'react-moment';
import { TAG_DELETE } from '../../../Utils/GraphqlMutaion';
import TeamWarnPopup from '../TeamManagement/TeamWarnPopup';
import Scrollbars from 'react-custom-scrollbars';

function SharedTeamTagsHome() {
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const [action_data, setAction_data] = useState(null);
    const [srcString, setSrcString] = useState('');
    const user = cacheMe.me;
    const { data: Tags } = useQuery(GET_TAGS, { variables: { company_id: cacheMe.me.company_id, admin_setting: "yes" }, fetchPolicy: 'no-cache' });
    const [all_tags, setAllTags] = useState([]);


    useEffect(() => {
        if (Tags?.tags?.public) {
            setAllTags(Tags.tags.public)
        }
    }, [Tags])


    const [hoveredElements, setHoveredElements] = useState({});
    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    // const [usersOptions, setUsersOptions] = useState(false);
    const [createNewTag, setCreateNewTag] = useState(false);
    const [editTag, setEditTag] = useState(false);
    const [deleteTeamTags, setDeleteTeamTags] = useState(false);

    const [selectedUsersOptions, setSelectedUsersOptions] = useState(false);

    const handleSelectedOptions = (e) => {
        setSelectedUsersOptions(e);
        // setUsersOptions(false);
    }


    const [delete_TAG] = useMutation(TAG_DELETE)
    const delete_confirm = async () => {
        try {
            const apires = await delete_TAG({
                variables: {
                    input: {
                        tag_id: action_data?.tag_id
                    }
                }
            });
            if (apires.data.tag_delete.status) {
                setAllTags(prev => prev.filter(f => f.tag_id !== action_data?.tag_id));
                toast.success(apires.data.tag_delete.message, { duration: 4000 });
            } else {
                toast.error(apires.data.tag_delete.message, { duration: 4000 });
            }
        } catch (error) {
            toast.error("An error occurred while deleting the tag.", { duration: 4000 });
        } finally {
            setDeleteTeamTags(false);
        }
    };



    // table in filter start
    const [sortRedActive, setSortRedActive] = useState(false);

    const [sortIconTitle, setSortIconName] = useState(false);
    const [sortIconTeam, setSortIconTeam] = useState(false);
    const [sortIconFile, setSortIconFile] = useState(false);
    const [sortIconCreatedate, setSortIconCreatedate] = useState(false);

    const [sortRedbyDefault, setSortRedByDefault] = useState(true);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSortList = (type) => {
        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        if (type === 'byTitle') {
            setSortRedActive(type);
            setSortIconName(!sortIconTitle);
            setSortRedByDefault(false);
            const sortedTags = [...all_tags].sort((a, b) => {
                const nameA = a.title.toLowerCase();
                const nameB = b.title.toLowerCase();
                return newSortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            });
            // console.log(125, sortedTags);
            setAllTags(sortedTags);
        } else if (type === 'byTeamname') {
            // console.log(127, type);
            setSortRedActive(type);
            setSortIconTeam(!sortIconTeam);
            setSortRedByDefault(false);
            const sortedTags = [...all_tags].sort((a, b) => {
                const nameA = (a.team_list_name[0] || '').toString().toLowerCase();
                const nameB = (b.team_list_name[0] || '').toString().toLowerCase();

                return newSortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            });
            setAllTags(sortedTags);
            // console.log(139, sortedTags);
        } else if (type === 'byfile') {
            setSortRedActive(type);
            setSortIconFile(!sortIconFile);
            setSortRedByDefault(false);
            const sortedTags = [...all_tags].sort((a, b) => {
                const countA = a.use_count;
                const countB = b.use_count;
                return newSortOrder === 'asc'
                    ? countA - countB
                    : countB - countA;
            });
            setAllTags(sortedTags);
        } else if (type === 'byCreatedate') {
            setSortRedActive(type);
            setSortIconCreatedate(!sortIconCreatedate);
            setSortRedByDefault(false);
            const sortedTags = [...all_tags].sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return newSortOrder === 'asc'
                    ? dateA - dateB
                    : dateB - dateA;
            });
            setAllTags(sortedTags);
        }
    };
    const [create_TagShow, setCreate_TagShow] = useState(false);
    const CreateTagShow = () => {
        return (
            create_TagShow &&
            <span style={{ cursor: 'pointer' }} className="user_Management_title createTeam">
                <span className="tooltiptext5">
                    <p> Create a tag. </p>
                    <span onClick={e => { setCreate_TagShow(false); }} className='tooltipClose'></span>
                </span>
            </span>
        )
    }

    const ref = useClickAway(() => {
        setGustsSettingsOptions(false)
    });

    const [gustsSettingsOptions, setGustsSettingsOptions] = useState(false);
    const handleSettingsOptions = (tag) => {
        setGustsSettingsOptions(gustsSettingsOptions === tag.tag_id ? null : tag.tag_id);
    };


    // console.log(27, all_tags);


    return (
        <>
            <Toaster />
            <div className='right_container'>
                <div className='adminRightHead'>
                    <span className="user_Management_title text-5xl">Shared team tag(s)</span>
                    <span
                        onMouseEnter={() => handleMouseHover('element1')}
                        onMouseLeave={() => handleMouseLeave('element1')}
                        className="tooltip user_Management_title"><span className="info_tooltip_new"></span>
                        {
                            hoveredElements['element1'] &&
                            <span className="tooltiptext">
                                <p>Managing your files has never been easier with tags. Workfreeli elevates the experience by offering personal and team tags. <br /> <br />

                                    This unique approach to tagging files allows you and your team to keep track of your files in the manner that suits you best. <br /> <br />

                                    Create a team tag and make that tag available to all teams or to a specific team. Team tags are created by Admin, meaning tags are thoughtful, can avoid confusion and are controlled. <br /> <br />

                                    Personal Tags can help you manage your documents in your own way. They are unique and always remain private to each user. <br /> <br />

                                    Sort and search files by tags in each room or use the files dashboard to easily find documents across all rooms. Multiple team and personal tags can be used for any file.</p>
                                <span className="tooltipClose"></span>
                            </span>}
                    </span>
                    {
                        selectedUsersOptions &&
                        <div className="miniItem" style={{ marginLeft: "15px" }}>
                            <div className="miniItem_image" style={{ margin: "0px" }}>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="miniItem_name" style={{ marginLeft: "3px" }}>Filter Active Users only</div>
                            <div onClick={() => handleSelectedOptions(false)} className="miniItem_remove"></div>
                        </div>
                    }
                </div>

                <div className='usersTabSection'>
                    <div className="usersTabSecDevide lefttSide" style={{ gap: '10px' }}>
                        <div className={`usersTabList teamManagementTitle`} >
                            <div style={{ cursor: 'pointer' }}>
                                <p>Tags ({all_tags.length})</p>
                            </div>
                        </div>
                    </div>
                    <div className='usersTabSecDevide rightSide'>
                        <span className='relative'>
                            <input className="userSearchInput" value={srcString} onChange={(e) => setSrcString(e.target.value)} type="text" autoFocus={true} placeholder="Search tag" />
                            <span className="leftSearchCloseBtn remove !right-5" onClick={(e) => setSrcString('')} ></span>
                        </span>
                        <button
                            style={{ marginRight: '0px' }}

                            onClick={() => setCreateNewTag(true)}
                            className="userCreateBtn tooltip5"
                            onMouseEnter={() => setCreate_TagShow(true)}
                            onMouseLeave={() => setCreate_TagShow(false)}
                        >Create a tag</button>
                        <CreateTagShow />
                    </div>
                </div>
                <div className='usersTableContainer'>
                    <div className="userTableHead">
                        <ul className="">
                            <li className="_userName desc" style={{ width: "10%", justifyContent: "center" }}>Tag color</li>
                            {/* <li className="_userEmail asce" style={{ width: "30%" }}><span className="sortIcons"></span>Tag title</li> */}
                            <li className={`_userEmail ${sortRedbyDefault && 'sort_active'} ${sortRedActive === "byTitle" ? 'sort_active' : ''} ${!sortIconTitle ? 'asce' : 'desc'}`} style={{ width: "30%" }}>
                                <span onClick={() => handleSortList("byTitle")} className="sortIcons"></span>Tag title
                            </li>
                            {/* <li className="_userRole asce" style={{ width: "20%" }} ><span className="sortIcons"></span>Team</li> */}
                            <li className={`_userRole ${sortRedActive === "byTeamname" ? 'sort_active' : ''} ${!sortIconTeam ? 'asce' : 'desc'}`} style={{ width: "20%" }}>
                                <span onClick={() => handleSortList("byTeamname")} className="sortIcons"></span>Team
                            </li>
                            {/* <li className="_userStatus desc" style={{ width: "15%", justifyContent: "center" }}><span className="sortIcons"></span>No. of files</li> */}
                            <li className={`_userStatus ${sortRedActive === "byfile" ? 'sort_active' : ''} ${!sortIconFile ? 'asce' : 'desc'}`} style={{ width: "15%" }}>
                                <span onClick={() => handleSortList("byfile")} className="sortIcons"></span>No. of files
                            </li>
                            {/* <li className="_userAction desc sort_active" style={{ width: "20%" }}><span className="sortIcons"></span>Created date</li> */}
                            <li className={`_userAction ${sortRedActive === "byCreatedate" ? 'sort_active' : ''} ${!sortIconCreatedate ? 'asce' : 'desc'}`} style={{ width: "20%" }}>
                                <span onClick={() => handleSortList("byCreatedate")} className="sortIcons"></span>Created date
                            </li>
                            <li className="_userAction" style={{ width: "5%", justifyContent: "center" }}>Actions</li>
                        </ul>
                    </div>
                    <div className='userTableBody'>
                        <Scrollbars autoHide className="scrollBarClass">
                            {
                                (all_tags.filter(f => f.title.toLowerCase().includes(srcString.toLowerCase())).length === 0 ?
                                    (<div style={{ position: "absolute", inset: 0, overflow: "scroll", marginRight: "-8px", marginBottom: "-8px", textAlign: "center", marginTop: "20%" }}>
                                        No data found
                                    </div>)
                                    :
                                    (all_tags.filter(f => f.title.toLowerCase().includes(srcString.toLowerCase())).map((item) => {
                                        return (
                                            <div key={item.tag_id} style={{ position: 'relative' }}>
                                                <ul className="_userList">

                                                    <li className="_tagColor" style={{ width: "10%", justifyContent: "center" }}><span className="tag_color" style={{ backgroundColor: item.tag_color }}></span>
                                                    </li>
                                                    <li className="_userEmail" style={{ width: "30%" }}>
                                                        <span className="userEmailText">{item.title}</span>
                                                    </li>
                                                    <li className="_userRole" style={{ width: "20%" }}>
                                                        <span className="">{item.team_list_name && Array.isArray(item.team_list_name) && item.team_list_name.length > 0
                                                            ? item.team_list_name.join(', ') : "All Teams"}</span>
                                                    </li>
                                                    <li className="_userStatus" style={{ width: "15%", justifyContent: "center" }}>
                                                        {item.use_count}
                                                    </li>

                                                    <li className="_tagCreated _afileDate" style={{ width: "20%", flexDirection: "column" }}>
                                                        <span><Moment format="DD/MM/YYYY h:mm a">{item.created_at}</Moment> </span>
                                                        <span style={{ color: "rgb(153, 147, 147)", fontSize: "12px" }}> by {item.created_by_name} </span>
                                                    </li>

                                                    <li onClick={() => handleSettingsOptions(item)} className="_userAction" style={{ width: "5%", position: "relative", pointerEvents: gustsSettingsOptions === item.tag_id ? "none" : "" }}>
                                                        <span className="userActionIcon"></span>
                                                    </li>
                                                </ul>
                                                {
                                                    gustsSettingsOptions === item.tag_id &&
                                                    <ul ref={ref} className="userActionPopup" style={{ position: "absolute" }}>
                                                        <li onClick={() => { setAction_data(item); setEditTag(true) }}>Edit tag</li>
                                                        {item.use_count === 0 && <li onClick={() => { setAction_data(item); setDeleteTeamTags(true) }}>Delete tag</li>}
                                                    </ul>
                                                }
                                            </div>
                                        )
                                    })
                                    )
                                )}
                        </Scrollbars>
                    </div>
                </div>
            </div>

            {createNewTag && <CreateTag popUpType={"create"} setCreateNewTag={setCreateNewTag} action_data={null} user={user} tags={all_tags} setAllTags={setAllTags} />}
            {editTag && <CreateTag popUpType={"update"} setCreateNewTag={setEditTag} action_data={action_data} user={user} tags={all_tags} setAllTags={setAllTags} />}
            {deleteTeamTags && <TeamWarnPopup title={'Delete team tag ?'} desc={'Are you sure you want to delete this team tag ?'} onClose={() => setDeleteTeamTags(false)} onConfirm={delete_confirm} />}
        </>
    );
}

export default SharedTeamTagsHome;