/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import {checkVerifiacitonCodeAPI,sendVerificationCodeAPI,checkOTPCODEAPI,sendOTPCodeAPI} from '../../Utils/API';
// import ReCAPTCHA from "react-google-recaptcha";
import toast from 'react-hot-toast';
import validator from 'validator'
import Countdown from 'react-countdown';
// import { connect } from 'react-redux';
// import { setUserSession } from '../../Utils/Common';
// import { LoginUser,initWorkerTimer } from '../../Utils/API';
// import {
// 	//openCallWindow,
// 	showVersionPopup
// } from '../../Utils/CustomFun';
import CheckTrusted from './CheckTrusted';




function OTPVerification(props) {
	const [errorCounter, setErrorCounter] = useState(localStorage.getItem('verifyErrorCounter') !== null ? Number(localStorage.getItem('verifyErrorCounter')) : 0);
	const [reCapchaStatus, setReCapchaStatus] = useState(true);
	const grecaptchaObject = window.grecaptcha;
	const [sendtime] = useState(Date.now() + 300000);
	// const email = props.email;

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [verifyBtn, setVerifyBtn] = useState(false);

	const [verifyCode, setVerifyCode] = useState({
		digit_1: '',
		digit_2: '',
		digit_3: '',
		digit_4: '',
		digit_5: '',
		digit_6: ''
	});
	const [errorFields, setErrorFields] = useState({
		digit_1: false,
		digit_2: false,
		digit_3: false,
		digit_4: false,
		digit_5: false,
		digit_6: false,
	  });

	const codeOnChange = (e) => {
		let id = e.target.id;
		let val = e.target.value;
		let checkLength = e.target.value.length === 1 ? true : false;
		if (validator.isNumeric(val)) {

			if (id === 'digit_1') {
				setVerifyCode({ ...verifyCode, digit_1: val });

				if (checkLength)
					document.querySelector("#digit_2").focus();

			} else if (id === 'digit_2') {
				setVerifyCode({ ...verifyCode, digit_2: val })
				if (checkLength)
					document.querySelector("#digit_3").focus();
			} else if (id === 'digit_3') {
				setVerifyCode({ ...verifyCode, digit_3: val })
				if (checkLength)
					document.querySelector("#digit_4").focus();
			} else if (id === 'digit_4') {
				setVerifyCode({ ...verifyCode, digit_4: val })
				if (checkLength)
					document.querySelector("#digit_5").focus();
			} else if (id === 'digit_5') {
				setVerifyCode({ ...verifyCode, digit_5: val })
				if (checkLength)
					document.querySelector("#digit_6").focus();
			} else if (id === 'digit_6') {
				setVerifyCode({ ...verifyCode, digit_6: val })
			}
			setErrorFields((prev) => ({ ...prev, [id]: false }));
		} else {
			setVerifyCode({
				...verifyCode,
				[id]: ''
			});
			setErrorFields((prev) => ({ ...prev, [id]: true }));
		}
	}
	const pasteCode = (event) => {
		let c = event.clipboardData.getData('Text');
		if (!isNaN(c)) {
			// let code = Number(c)
			if (c.length === 6) {
				setVerifyCode({
					digit_1: c[0],
					digit_2: c[1],
					digit_3: c[2],
					digit_4: c[3],
					digit_5: c[4],
					digit_6: c[5]
				});
			}
		}

	}

	const codeOnKeyUp = (e) => {
		let id = e.target.id;
		const [, fieldIndex] = id.split("_");
		const nextSibling = document.querySelector(`input[id=digit_${parseInt(fieldIndex, 10) + 1}]`);
		const prevSibling = document.querySelector(`input[id=digit_${parseInt(fieldIndex, 10) - 1}]`);
		if (e.which === 8) {
			if (parseInt(fieldIndex, 10) > 1) {
				if (prevSibling !== null) {
					prevSibling.focus();
				}
			}
		} else if (e.which === 39) {
			if (parseInt(fieldIndex, 10) < 6) {
				if (nextSibling !== null) {
					nextSibling.focus();
				}
			}
		} else if (e.which === 37) {
			if (parseInt(fieldIndex, 10) > 1) {
				if (prevSibling !== null) {
					prevSibling.focus();
				}
			}
		}
	}
	useEffect(() => {
		// console.log(100, verifyCode)
		if (verifyCode.digit_1 !== '' && verifyCode.digit_2 !== '' && verifyCode.digit_3 !== '' && verifyCode.digit_4 !== '' && verifyCode.digit_5 !== '' && verifyCode.digit_6 !== '') {
			setVerifyBtn(true);
			if (reCapchaStatus)
				submitVerification();

		} else {
			setVerifyBtn(false);
		}
	}, [verifyCode])

	// const [ApiRes, setApiRes] = useState(null);
	const [view_trusted, setview_trusted] = useState(false);

	const afterSave = () => {
		// let login  = ApiRes;
		// if (login.token) {
		// 	let active_conv = {
		// 		details: props.conversation_id === undefined ?  login.pin_convs.filter(v => v.conversation_id === login.user.id)[0]:login.unpin_convs.filter(v => v.conversation_id === props.conversation_id)[0],
		// 		msgs: [],
		// 		login:true
		// 	}
		// 	if (active_conv.details === undefined) {
		// 		active_conv.details = login.pin_convs.filter(v => v.conversation_id === login.user.id)[0];
		// 	}
		// 	//console.log(44, props.company_login.conversation_id, active_conv);
		// 	localStorage.removeItem('conversation_id_for_login');

		// 	login['active_conv'] = {...active_conv,details:{...active_conv.details,temp_user:[]}};;
		// 	login['all_users'] = [];
		// 	setUserSession(login.token, login.user, login);
		// 	// console.log(33,rememberMe)
		// 	// socket.emit("login", {from: login.user.id, text: login.user.firstname});
		// 	props.setLogindata(login);
		// 	initWorkerTimer(login.user.mute,props);

		// 	setErrorCounter(0);
		// 	window.localStorage.removeItem("errorCounter");

		// 	toast.success('Welcome back to WorkFreeli.', {duration: 5000});

		// 	if (login.active_conv.details !== undefined) {
		// 		localStorage.setItem('active_freeli_conv', login.active_conv.details.conversation_id);
		// 		window.location.href ='/connect/' + login.active_conv.details.conversation_id
		// 		// props.history.push('/connect/' + login.active_conv.details.conversation_id);
		// 	} else {
		// 		localStorage.setItem('active_freeli_conv', login.user.id);
		// 		window.location.href ='/connect/' + login.user.id
		// 		// props.history.push('/connect/' + login.user.id);
		// 	}
		// 	// if(login.db_unix){
		// 		// window.db_unix = login.db_unix;
		// 			props.setPopup({type:'xmpp_fire', data: true});
		// 	// }
		// props.setOnlineUsers(login.online_user_lists);
		// 		// showVersionPopup(login.restart_time,props);

		// } else {
		// 	// console.log(188,props.history)
		// 	if (login.companies !== undefined) {
		// 		props.setCompanyLogin({ companies: login.companies,conversation_id:props.conversation_id, email: email, password:'otp',code: (verifyCode.digit_1 + verifyCode.digit_2 + verifyCode.digit_3 + verifyCode.digit_4 + verifyCode.digit_5 + verifyCode.digit_6) });
		// 		props.history.push('/company');

		// 	}
		// }
		props.setLoader(false);
	}

	const submitVerification = async () => {
		try {
			props.setLoader(true);
			// var res = await props.geolocationHIT();
			let res = await props.handleSignin(null, (verifyCode.digit_1 + verifyCode.digit_2 + verifyCode.digit_3 + verifyCode.digit_4 + verifyCode.digit_5 + verifyCode.digit_6))
			if(res){
				props.setVerifiedCode((verifyCode.digit_1 + verifyCode.digit_2 + verifyCode.digit_3 + verifyCode.digit_4 + verifyCode.digit_5 + verifyCode.digit_6))
				setview_trusted(true);
				setErrorCounter(0);
				window.localStorage.removeItem("verifyErrorCounter");
			}else{
				setErrorCounter(Number(errorCounter) + 1);
				localStorage.setItem('verifyErrorCounter', Number(errorCounter) + 1);
			}
			
		} catch (error) {
			console.log(214, props.error)
			if (error.data.message === 'Email verification code not match, please check again.') {
				setErrorCounter(Number(errorCounter) + 1);
				localStorage.setItem('verifyErrorCounter', Number(errorCounter) + 1);
				if (errorCounter >= 3) {
					grecaptchaObject.reset();
				}
				// document.querySelector('#email').classList.add('error');
			}
			console.log(130, error)
			props.setLoader(false)
			if (typeof (error.data.message) !== 'string') {
				toast.error(error.data.error);
			} else {
				toast.error(error.data.message);
			}
		}
	}
	const resendCode = async () => {
		// try{
		// 	setSendTime(Date.now() + 300000);
		// 	if(props.type === 'otp'){
		// 		await sendOTPCodeAPI({email:email})
		// 	}else{
		// 		await sendVerificationCodeAPI({email:email,is_invite:props.isInvite});
		// 	}
		// }catch(error){
		// 	setSendTime(Date.now() + 300000);
		// 	console.log(error)
		// }
	}

	useEffect(() => {
		if (errorCounter >= 3) {
			setReCapchaStatus(false);
		} else {
			setReCapchaStatus(true);
		}
	}, [errorCounter]);
	// Handle form submission
// Handle form submission
const handleContinue = () => {
	let newErrors = {};
  
	// Check if any field is empty
	Object.keys(verifyCode).forEach((key) => {
	  if (!verifyCode[key]) newErrors[key] = true;
	});
  
	// Set the error state for all fields that are empty
	setErrorFields(newErrors);
  
	// If no errors, proceed with the verification or form submission
	if (Object.keys(newErrors).length === 0) {
	  console.log("Verification code submitted:", verifyCode);
	}
  };

	return (

		<>
			{view_trusted ? <CheckTrusted afterSave={afterSave} />

				: <>
					{/* <>
						<div className="formHeader">Sign in with OTP</div>
						<div className="form-field email-address">
							<label>Your email</label><span className="emailAt"></span>
							<input type="text" name="email" id="email" placeholder="youremail@email.com" value="" />
							<span className="errorLabel relative">Invalid email address.</span>
						</div>
					</>
					<>
						<div className="formHeader">Forgot Password</div>
						<div className="form-field email-address">
							<label>Your email</label><span className="emailAt"></span>
							<input type="text" name="email" id="email" placeholder="youremail@email.com" value="" />
							<span className="errorLabel relative">Invalid email address.</span>
						</div>
					</> */}
					<div className="formHeader">Verification</div>
					<div className="form-field email-address">
						<label>Enter verification code</label>


						<div className="digitHolder">
							<input maxLength="1"  style={{ border: errorFields.digit_1 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_1} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_1" name="digit_1" onPaste={pasteCode} autoFocus />
							<input maxLength="1"  style={{ border: errorFields.digit_2 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_2} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_2" name="digit_2" />
							<input maxLength="1"  style={{ border: errorFields.digit_3 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_3} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_3" name="digit_3" />
							<input maxLength="1"  style={{ border: errorFields.digit_4 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_4} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_4" name="digit_4" />
							<input maxLength="1"  style={{ border: errorFields.digit_5 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_5} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_5" name="digit_5" />
							<input maxLength="1"  style={{ border: errorFields.digit_6 ? '1px solid red' : '1px solid #ccc' }} value={verifyCode.digit_6} onChange={codeOnChange} onKeyUp={codeOnKeyUp} type="text" id="digit_6" name="digit_6" />
						</div>
						<label style={{ fontSize: '14px', color: 'green', marginTop: '10px' }}>Verification code has been sent to your email. <Countdown
							date={sendtime}
							key={sendtime}
							renderer={props => props.completed ? <span className="resendBTN" onClick={resendCode} ><i className="fas fa-redo"></i> Resend</span> : <span className="resendBTN" style={{ textDecoration: 'none' }} >{props.minutes}:{props.seconds}</span>} />
						</label>
					</div>
					<div className="sign-in">
						{props.loader ?
							<button className="sendButton btn_loader"></button>
							: verifyBtn ?
								<button className="sendButton active" onClick={() => submitVerification()}>Continue</button>
								: !reCapchaStatus ?
									<button className="sendButton" >Continue</button>
									:
									<button className="sendButton" onClick={handleContinue} >Continue</button>
						}
					</div>
				</>}
		</>
	)
}


export default OTPVerification;