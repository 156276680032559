import { useClickAway } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineKeyboardBackspace, MdOutlineCancel } from "react-icons/md";
import { Link } from 'react-router-dom';

const MaintenanceHeader = ({ searchTerm, handleChange, handleClearSearch, maintenanceTabs, handleMaintenanceTabs, handleOpenPopup }) => {
    const [filterOptions, setFilterOptions] = useState(false);
    const ref = useClickAway(() => {
        setFilterOptions(false)
    })
    return (
        <>
            <div className='adminRightHead taskHeader !pl-5'>
                <div className="backToMainProperties" style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineKeyboardBackspace className='mr-2 text-[#002E98] text-3xl' />
                </div>
                <div className="eocTabHeader">
                    <p className={`eodTabText ${maintenanceTabs === 'estimated' ? 'eodTabTextActive' : ''}`} onClick={() => handleMaintenanceTabs('estimated')}>Dashboard</p>
                    <p className={`eodTabText ${maintenanceTabs === 'reportList' ? 'eodTabTextActive' : ''}`} onClick={() => handleMaintenanceTabs('reportList')}>End of day entry (20 of 52)</p>
                </div>
                <div className='taskHeader_right_area relative'>
                    {
                        maintenanceTabs === 'reportList' &&
                        <>
                            <div className="opt_icons conv_filter !mr-4" onClick={() => setFilterOptions(!filterOptions)}></div>
                            {filterOptions &&
                                <div className="ForTaskListInner">
                                    <div ref={ref}>
                                        <ul className="userActionPopup showAll checklistPopup ForTaskList">
                                            <li className='!text-left'>Venue</li>
                                            <li className='!text-left'>Date</li>
                                            <li className='!text-left'>Net Sales</li>
                                        </ul>
                                    </div>
                                </div>
                            }


                            <div className="searchareaDiv mr-4 relative w-56">
                                <input
                                    className="_inputBar searchLeft"
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleChange}
                                />
                                {searchTerm &&
                                    <MdOutlineCancel
                                        className="absolute text-red-600 right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                        size={20}
                                        onClick={handleClearSearch}
                                        title="Clear search"
                                    />
                                }
                            </div>
                        </>
                    }

                    <div onClick={() => handleOpenPopup("createToken")} className="createReportButton mr-4">
                        Create a token
                        <span className="taskPlusIcon mr-2 -mt-1 -ml-2">
                            <AiOutlinePlus size={16} />
                        </span>
                    </div>

                    <span className="closeReportProperties !top-[22px] !mr-4"></span>
                </div>
            </div>
        </>
    );
};

export default MaintenanceHeader;