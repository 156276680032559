import React, { useEffect, useState } from 'react';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import sortArrayByKey from '../../../hooks/useSortedArray';

const TaskObserver = ({ OnUpdateTask, assigneeLength }) => {
    const params = useParams()
    const { selectedTask, users, isObserver } = useTaskContext()
    const [observersPopup, setObserversPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const participants = users.filter(f => selectedTask?.participants.includes(f.id) && selectedTask?.created_by !== f.id && selectedTask?.assign_to.indexOf(f.id) === -1);
    const selectedRoom = selectedTask?.observers || [];

    const handleSelectAllChange = () => {
        if (selectedRoom.length === participants.length) {
            OnUpdateTask({ observers: [], save_type: 'observer' });
        } else {
            const allParticipantIds = participants.map((p) => p.id);
            OnUpdateTask({ observers: allParticipantIds, save_type: 'observer' });
        }
    };

    const handleChange = (participant) => {
        if (selectedRoom.includes(participant.id)) {
            OnUpdateTask({
                observers: selectedRoom.filter((id) => id !== participant.id),
                save_type: 'observer',
            });
        } else {
            OnUpdateTask({ observers: [participant.id, ...selectedRoom], save_type: 'observer' });
        }
    };

    useEffect(() => {
        setObserversPopup(false)
    }, [params.task_id])

    const checkLength = participants.filter((user) => user.firstname.toLowerCase().includes(searchQuery.toLowerCase())).length

    const handleObserversPopup = () => {
        if (assigneeLength === 0) {
            setObserversPopup(false);
        }
        else {
            setObserversPopup(true);
        }
    };
    const filter_data = participants.filter((user) => user.firstname.toLowerCase().includes(searchQuery.toLowerCase()))
    const sorted_data = sortArrayByKey(filter_data, 'firstname', 'asc');

    return (
        <>
            <div className="creator_area rightArea" id={assigneeLength === 1 && selectedTask?.observers.length === 0 ? "observer" : assigneeLength === 0 && selectedTask?.observers.length === 0 ? "assigneeFast" : selectedTask?.observers.length > 0 ? "observerUpdate" : ""} style={{ borderBottom: 'unset' }}>
                <div className="creator_label" style={{ cursor: 'pointer' }}>Observers</div>
                <div className="assingeetrim" >
                    {
                        selectedTask?.observers.length > 0 ? (
                            <div onClick={() => setObserversPopup(true)} className="flex items-center !ml-0">
                                {selectedTask?.observers.map(o => (
                                    <li key={'_observer_' + o} className="ObsImageTask" style={{ marginTop: '0px', width: '27px', marginRight: '9px', height: '27px', alignItems: 'center', backgroundColor: 'rgb(126, 214, 208)' }}>
                                        {getONLYUSERSDATA(users, o, 'fnln')}
                                    </li>
                                ))}
                                <div className="ellipsis" style={{ width: '80%', fontSize: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', cursor: 'pointer' }}>
                                    <span>{getONLYUSERSDATA(users, selectedTask?.observers[0], 'name')}</span>
                                    {selectedTask?.observers.length > 1 && (
                                        <p style={{ marginTop: '2px', fontSize: '12px' }}>+ {selectedTask?.observers.length - 1} others</p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div onClick={handleObserversPopup} className={`add_assignee hover:!text-[#0b1f47]`} >Add observers</div>
                        )
                    }
                </div>
            </div>
            {observersPopup && (
                <div className="trackCostPopUpCon">
                    <div className="adminRightHead taskHeader !pl-[20px] justify-between">
                        <div className="backToMainProperties">
                            <span onClick={() => setObserversPopup(false)} className="closeAdminPanel mt-2" data-for="descloseTooltip" data-tip="Back"></span>
                            <span className="backtoProperties">Observers</span>
                        </div>
                        <div className="searchareaDiv !w-[260px]">
                            <input
                                className="_inputBar searchLeft forfile"
                                type="text"
                                placeholder="Search user"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {searchQuery.length > 0 && (
                                <span
                                    onClick={() => setSearchQuery('')}
                                    className="removeFile"
                                    data-for="member_tooltip"
                                    data-tip="Clear Search"
                                    style={{ position: 'absolute', right: '24px', height: '18px', float: 'right', marginTop: '-30px', display: 'block', top: '50px' }}
                                ></span>
                            )}
                        </div>
                    </div>
                    <div className="Track_body mainBody forObserverBody">
                        <div className="checkList_area taskCheckListDiv" style={{ height: 'calc(100% - 24px)' }}>
                            {checkLength > 0 && (
                                <div className="selectAllArea !py-4 !px-7">
                                    <div className="checkboxes__item">
                                        <label className="checkbox style-c">
                                            <input
                                                type="checkbox"
                                                checked={selectedRoom.length === participants.length}
                                                onChange={handleSelectAllChange}
                                            />
                                            <div className="checkbox__checkmark"></div>
                                            <div className="checkbox__body">Select all</div>
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div className="allTagsArea">
                                <ul className="alltagList !h-[calc(100vh-150px)]" >
                                    {sorted_data.map((v) => (
                                        <li key={v.id} className="!py-4 !px-7">
                                            <div className="tagsRelateLists">
                                                <div className="checkboxes__item">
                                                    <label className="checkbox style-c">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRoom.includes(v.id)}
                                                            onChange={() => handleChange(v)}
                                                        />
                                                        <div className="checkbox__checkmark"></div>
                                                        <div className="checkbox__body">{`${v.firstname} ${v.lastname}`}</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {checkLength === 0 && (
                                        <li className="tagNotFound !mt-[200px] text-center text-gray-500">No users found</li>
                                    )}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            {
                observersPopup === false &&
                <>
                    {
                        assigneeLength === 1 && selectedTask?.observers.length === 0 ?
                            <Tooltip delayShow={1000} style={{ fontSize: '12px', marginTop: '20px' }} anchorSelect="#observer" place="top" content="Click to add observer(s)"></Tooltip> :
                            selectedTask?.observers.length > 0 ?
                                <Tooltip delayShow={1000} style={{ fontSize: '12px', marginTop: '20px' }} anchorSelect="#observerUpdate" place="top" content="Click to update observer(s)"></Tooltip> :
                                assigneeLength === 0 && selectedTask?.observers.length === 0 ?
                                    <Tooltip delayShow={1000} style={{ fontSize: '12px', marginTop: '20px' }} anchorSelect="#assigneeFast" place="top" content="Please select an assignee fast"></Tooltip> : ""
                    }
                </>
            }
        </>
    );
};

export default TaskObserver;
