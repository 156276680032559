import React, { useState } from 'react';
import classNames from "classnames";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import { VscCalendar } from "react-icons/vsc";
import toast, { Toaster } from 'react-hot-toast';
function EodDateRangeFilterPopup(props) {

    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    // const [startDate, setstartDate] = useState('');
    // const [endDate, setendDate] = useState('')
    const years = range(2000, getYear(new Date()) + 10, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <div className="tagRelatedPopup forDateRange inaReportPopup dateRangeFilter !top-[60px]">
              <div><Toaster /></div>
            <div className='dateRange_sidebar'>
                <div className="Advance_search_top_Task newClass !pr-0 !pl-[10px] !flex-nowrap !gap-2 !border-b-0 !justify-start !items-center">
                    <p className='serach_label'>Search : </p>
                    <div className="col1" 
                    style={{
                        width: 'auto', display: 'flex',
                        alignItems: 'center',gap: '10px' }}>
                        <p className='data_Label'>From</p>
                        <div className="row1" style={{ paddingRight: '0px', marginLeft: '0px' }}>
                            <span className='calenderIcon_task'><VscCalendar size={22} /></span>
                            <div className='fromDate_One_task col1_row2' style={{ width: '100%' }}>
                                <DatePicker
                                    className={error1 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}
                                    dateFormat="MMMM dd, yyyy"
                                    placeholderText="From date"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div className="CalendarDiv"

                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {"<"}
                                            </button>
                                            <div >
                                                <select
                                                    value={getYear(date)}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    value={months[getMonth(date)]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {">"}
                                            </button>
                                        </div>
                                    )}
                                    // selected={startDate}
                                    selected={props.startDateFilter}
                                 
                                    onChange={(date) => {

                                        if (new Date(date) > new Date(props.endDateFilter)) {
                                            toast.error('From date should be smaller than  or equal to end date', {
                                                style: {
                                                    fontSize: '14px',
                                                    maxWidth: '100%'
                                                    // Set the font size to 12px
                                                    // Add other styling properties as needed
                                                },
                                                duration: 4000
                                            });
                                            props.setStartDateFilter('')
                                            setError1(true)
                                            setTimeout(() => {
                                                setError1(false)
                                            }, 4000)
                                        } else {
                                            props.setStartDateFilter(date);
                                            setError1(false)
                                        }
                                    }}
                                />
                                {
                                    props.startDateFilter !== '' ?
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '9px',
                                                height: '18px',
                                                float: 'right',
                                                marginTop: '-33px',
                                                // display: 'block',
                                                top: '41px'

                                            }}
                                            className="removeFile"

                                            onClick={() => { props.setStartDateFilter(''); }}></span>
                                        : ''
                                }
                            </div>

                        </div>

                    </div>
                    <div className="col1" 
                    style={{
                        width: 'auto', display: 'flex',
                        alignItems: 'center', gap: '10px'
                    }}>
                        {/* <div className="col1"> */}
                        <p className='data_Label'>To</p>
                        <div className="row1" style={{ paddingLeft: '0px', marginLeft: '0px' }}>
                            <span className='calenderIcon_task'><VscCalendar size={22} /></span>
                            <div className='fromDate_One_task col1_row2'>
                                <DatePicker
                                    className={error2 === true ? "start_Date_task errorDate" : "start_Date_task"}
                                    dateFormat="MMMM dd, yyyy"
                                    placeholderText="To date"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div className="CalendarDiv"

                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {"<"}
                                            </button>
                                            <div >
                                                <select
                                                    value={getYear(date)}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[getMonth(date)]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {">"}
                                            </button>
                                        </div>
                                    )}
                                    selected={props.endDateFilter}
                                    // selected={endDate}
                                    // onChange={(date) => {
                                        
                                    //         setendDate(date);
                                    //         setError2(false)
                                        
                                    // }}
                                    onChange={(date) => {
                                        if (new Date(date) < new Date(props.startDateFilter)) {
                                            toast.error('To date should be greater than  or equal to Start date', {
                                                style: {
                                                    fontSize: '14px',
                                                    maxWidth: '100%'
                                                    // Set the font size to 12px
                                                    // Add other styling properties as needed
                                                },
                                                duration: 4000
                                            });
                                            setError2(true)
                                            props.setEndDateFilter('')
                                            setTimeout(() => {
                                                setError2(false)
                                            }, 4000)
                                        } else {
                                            // let endDate = new Date(date).setHours(23, 59, 59, 999);
                                            props.setEndDateFilter(date);
                                            setError2(false)
                                        }
                                    }}
                                />

                                {props.endDateFilter === '' ? ''
                                    : <span
                                        style={{
                                            position: 'absolute',
                                            right: '9px',
                                            height: '18px',
                                            float: 'right',
                                            marginTop: '-33px',
                                            // display: 'block',
                                            top: '41px'

                                        }}
                                        className="removeFile"

                                        onClick={() => { props.setEndDateFilter(''); }}></span>

                                }
                            </div>
                        </div>
                    </div>
                
                </div>
        
                <div className='tagCoupleBtn !right-[19px] !bottom-[10px]'
                   
                >
                    <div className="skipButton"
                        onClick={() =>  {props.close_dateFilitered_popup(false);props.setDateTopFilter(false);}}
                    >Skip</div>
                    <div className={classNames("skipButton" , props.startDateFilter !== '' || props.endDateFilter !== '' ? 'activeSkip' : 'inactiveSkip')}
                    style={{ width: '80px', opacity: props.startDateFilter !== '' && props.endDateFilter !== '' ? 1 : 0.5, pointerEvents: props.startDateFilter !== '' || props.endDateFilter !== '' ? 'auto' : 'none' }} 
                    onClick={() => {
                       
                        props.setStartDateFilter('');
                        props.setEndDateFilter('');
                        // props.setDateFilter(null);
                        props.setDateTopFilter(false);
                        // setTimeout(()=>{
                        //     props.setDateFilter(false)
                        // },0)
                       

                    }}>Reset</div>
                    <div className={classNames("viewFilesButton", props.startDateFilter !== '' || props.endDateFilter !== '' ? 'activeView' : 'inactiveView')}
                        style={{ width: '75px', opacity: props.startDateFilter !== '' && props.endDateFilter !== '' ? 1 : 0.5, pointerEvents: props.startDateFilter !== '' || props.endDateFilter !== '' ? 'auto' : 'none' }}
                 
                    onClick={()=> {
                        // debugger
                        // props.setStartDateFilter('');
                        // props.setEndDateFilter('');
                        // setTimeout(()=>{
                        //     props.setDateFilter(true)
                        // },0);
                        props.close_dateFilitered_popup(false);
                        props.setDateTopFilter(null);

                        setTimeout(()=>{
                            props.setDateTopFilter(true);
                            // props.fetchData('initial')
                        },100)

                        
                        
                    }}
                        
                    >View</div>
                </div>
            </div>

        </div>
    )
}


export default EodDateRangeFilterPopup;
