"use client";
import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';

const TaskProgress = () => {
    const { selectedTask, OnUpdateTask, isObserver } = useTaskContext()
    const [progress, setProgress] = useState(selectedTask?.progress ? selectedTask?.progress : 0);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        setProgress(selectedTask?.progress)
    }, [selectedTask])

    const handleProgressChange = (newProgress) => {
        setProgress(newProgress);
        setDropdownOpen(false);
        OnUpdateTask({ progress: newProgress, save_type: 'progress' })
        // You can perform any additional actions based on the new progress here
    };
    const ref = useClickAway(() => {
        setDropdownOpen(false)
    })
    return (
        <>
            <div className="date_area" style={{ width: '43%' }}>
                <p className="date_label">Progress</p>
                <div className={`dropdown ${isObserver() && 'pointer-events-none'}`} tabIndex="1">
                    <div id="taskProgress"
                        className={`dropdown-header forProgress !text-[#0C1E47] ${dropdownOpen ? 'open' : ''}`}
                        style={{ backgroundColor: '#ECEEF4', borderRadius: '4px' }}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        {progress === 0 ? 'Not Defined' : progress === 25 ? 'Stage 1' : progress === 50 ? 'Stage 2' : progress === 75 ? 'Stage 3' : 'Final stage'}
                        <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'right'} icon open`} />
                    </div>
                    {dropdownOpen && (
                        <div ref={ref}>
                            <div className="dropdown-body open">
                                <div className={`dropdown-item ${progress === 0 ? 'selected' : ''}`} id="1" onClick={() => handleProgressChange(0)}>
                                    <span className="dropdown-item-dot selected"></span>Not defined
                                </div>
                                <div className={`dropdown-item ${progress === 25 ? 'selected' : ''}`} id="2" onClick={() => handleProgressChange(25)}>
                                    <span className="dropdown-item-dot false"></span>Stage 1
                                </div>
                                <div className={`dropdown-item ${progress === 50 ? 'selected' : ''}`} id="3" onClick={() => handleProgressChange(50)}>
                                    <span className="dropdown-item-dot false"></span>Stage 2
                                </div>
                                <div className={`dropdown-item ${progress === 75 ? 'selected' : ''}`} id="4" onClick={() => handleProgressChange(75)}>
                                    <span className="dropdown-item-dot false"></span>Stage 3
                                </div>
                                <div className={`dropdown-item ${progress === 100 ? 'selected' : ''}`} id="5" onClick={() => handleProgressChange(100)}>
                                    <span className="dropdown-item-dot false"></span>Final stage
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Tooltip delayShow={1000} anchorSelect="#taskProgress" place="top" content="Click to update progress"></Tooltip>
        </>
    );
};

export default TaskProgress;