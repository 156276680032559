/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { ARCHIVE_MUTATION, CLOSE_MUTATION } from '../../../Utils/GraphqlMutaion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConversation, set_archive_conversations, updateArchiveConv } from '../../../redux/message';
export default function RoomStatus({ room, me, _msgs }) {
    const dispatch = useDispatch()
    const active_conv = useSelector(state => state.message.active_conversation)
    const archive_conversations = useSelector(state => state.message.archive_conversations);
    const [room_archive, { data: archive_res }] = useMutation(ARCHIVE_MUTATION);
    useEffect(() => {
        if (archive_res) {
            if (archive_res.room_archive.status) {
                dispatch(updateArchiveConv({
                    conversation_id: active_conv?.conversation_id,
                    archive: active_conv?.archive === 'yes' ? 'no' : 'yes'
                }))
            }
        }
    }, [archive_res])
    const handleArchive = async() => {
        try {
            let apires = await room_archive({
                variables: {
                    input: {
                        conversation_id: active_conv?.conversation_id,
                        action: active_conv?.archive === 'yes' ? 'no' : 'yes'
                    }
                }
            })
            console.log(32, apires);
            if (apires.data.room_archive.status) {
                dispatch(set_archive_conversations(archive_conversations?.filter(f => f.conversation_id !== active_conv?.conversation_id)))
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [room_close, { data: close_res }] = useMutation(CLOSE_MUTATION);
    useEffect(() => {
        if (close_res) {
            if (close_res.close_room.status) {
                dispatch(closeConversation({
                    conversation_id: active_conv?.conversation_id,
                    close_room: active_conv?.archive === 'yes' ? 'no' : 'yes'
                }))
            }
        }
    }, [close_res])
    const handleRoomClose = () => {
        try {
            room_close({
                variables: {
                    input: {
                        conversation_id: active_conv?.conversation_id,
                        action: active_conv?.close_for === 'yes' ? 'no' : 'yes'
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        active_conv?.close_for === 'yes' || active_conv?.archive === 'yes' ?
            <>
                {active_conv?.close_for === 'yes' && <div className='close_wrapper'></div>}
                <div className={` ${active_conv?.archive === 'yes' ? '' : 'backwrapRoomLock'} forRoomLock`}>
                    <div className={'room_bottom_status'}>
                        {active_conv?.close_for === 'yes' ?
                            <div className={'status_container'}>
                                {active_conv?.participants_admin?.indexOf(me.id) > -1 ?
                                    <>
                                        <span className={'status_name'}>This room has been locked by {_msgs?.[_msgs?.length - 1]?.sendername} on {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('MMMM Do, YYYY')} at {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('h:mm A')}</span>
                                        <span className={'status_btn'} onClick={handleRoomClose}>Unlock this room</span>
                                    </>
                                    :
                                    <span className={'status_name'}>
                                        This room has been locked by {_msgs?.[_msgs?.length - 1]?.sendername} on {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('MMMM Do, YYYY')} at {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('h:mm A')}
                                    </span>
                                }
                            </div>
                            : active_conv?.archive === 'yes' ?
                                <div className={'status_container'}>
                                    {active_conv?.participants_admin?.indexOf(me.id) > -1 ?
                                        <>
                                            <span className={'status_name'}> This room has been archived by {_msgs?.[_msgs?.length - 1]?.sendername} on {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('MMMM Do, YYYY')} at {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('h:mm A')}</span>
                                            <span className={'status_btn'} onClick={handleArchive}>Restore this room</span>
                                        </>
                                        :
                                        <span className={'status_name'}> This room has been archived by {_msgs?.[_msgs?.length - 1]?.sendername} on {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('MMMM Do, YYYY')} at {moment(_msgs?.[_msgs?.length - 1]?.created_at).format('h:mm A')}</span>
                                    }
                                </div>
                                : active_conv?.conv_is_active === 0 ?
                                    <div className={'status_container'}>
                                        <span className={'status_name'}>This room is inactive</span>
                                    </div>
                                    : null
                        }
                    </div>
                </div>
            </>
            : null
    );
}
