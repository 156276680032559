/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useRef } from 'react';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import * as linkify from "linkifyjs";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import emoji_data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Link, useParams } from "react-router-dom";
import { useApolloClient, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { sendMsgMutation } from '../../Utils/GraphqlMutaion';
import { aesEncrypt, modifyMyMsg, scrollToBottom } from '../../Utils/Common';
import { useSelector, useDispatch } from 'react-redux'
import { set_connect_files, set_messages, set_raw_message_text, setBooleanState, setPopup } from '../../redux/message';
import classNames from 'classnames';
import { GET_ALL_USERS, GET_ME } from '../../Utils/GraphqlQueries';
import { Tooltip } from 'react-tooltip';
import { MdOutlineCancel } from "react-icons/md";
import { FiBarChart2 } from "react-icons/fi";
import { BsFillEmojiSmileFill } from 'react-icons/bs';
import { FaLock } from 'react-icons/fa';

var placeCaretAtEnd = (el) => {
    el.focus();

    if (typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
};

export const EditorBox = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const messages = useSelector((state) => props.is_reply_msg === 'no' ? state.message.msgs : state.message.reply_msgs)
    const { raw_message_text, upload_popup, reply_main_msg, msg_ai_active } = useSelector((state) => state.message)
    const [emojiView, setEmojiView] = useState(false);
    const [wrongText, setWrongText] = useState(false)
    const [voice, setVoice] = useState(false)
    const [send_msg_input] = useMutation(sendMsgMutation);
    const [editorState, seteditorState] = useState(EditorState.createEmpty());

    const {
        listening,
        transcript,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const ToggleVoice = (type) => {
        if (browserSupportsSpeechRecognition) {
            if (isMicrophoneAvailable) {
                if (listening) {
                    SpeechRecognition.abortListening();
                    resetTranscript();
                } else {
                    SpeechRecognition.startListening({ continuous: true })
                }
            } else {
                toast.error("Mircrophone access denied !", { duration: 2000 });
            }
        } else {
            toast.error("Your Browser doesn't  support this feature", { duration: 2000 });
        }
        setVoice(type)
    }

    useEffect(() => {
        if (transcript.length > 0) {
            var cc = (transcript === 'quotation' || transcript === 'semicolon' || transcript === 'new line' || transcript === "dash" || transcript === "comma" || transcript === 'underscore' || transcript === 'full stop' || transcript === 'dot' || transcript === 'enter' || transcript === 'exclamation mark' || transcript === 'question mark' || transcript === 'square bracket' || transcript === 'round bracket' || transcript === 'apostrophe' || transcript === 'tab' || transcript === 'space' ? '' : ' ') + transcript.replace(/tab/gmi, '\t').replace(/new line|enter/gmi, "\n").replace(/full stop|dot/gmi, ".").replace(/space/gmi, " ").replace(/semicolon/gmi, ";").replace(/underscore/gmi, "_").replace(/hyphen/gmi, "-").replace(/Question mark/gmi, "?").replace(/exclamation mark/gmi, "!").replace(/quotation/gmi, "'").replace(/comma/gmi, ",").replace(/dash/gmi, "-").replace(/square bracket/gmi, "[ ]").replace(/round bracket/gmi, "( )").replace(/apostrophe|appostrophe/gmi, "'")
            console.log("🚀 ~ useEffect ~ cc:", cc)
            if (voice) {
                const contentBlock = htmlToDraft(cc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    seteditorState(EditorState.moveFocusToEnd(editorState));
                }
            }
        }
    }, [transcript])

    const setEditorReference = (ref) => {
        if (props.EditorRef) {
            props.EditorRef.current = ref
        } else {
            EditorRef.current = ref
        }
    }

    const onEditorStateChange = (editorState) => {
        seteditorState(editorState)

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        dispatch(set_raw_message_text(markup))
    };

    useEffect(() => {
        // console.log("🚀 ~ EditorBox ~ raw_message_text:", raw_message_text)
        if (raw_message_text === '') {
            if (editorState.getCurrentContent().hasText()) {
                const newState = EditorState.createEmpty()
                seteditorState(EditorState.moveFocusToEnd(newState));
            }
        }
    }, [raw_message_text])

    const editorOnBlur = (e, editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        if (params.msg_id) {
            localStorage.setItem('draft_' + params.msg_id, markup);
        } else {
            localStorage.setItem('draft_' + props.room?.conversation_id, markup);
        }
    }

    function removeExtraBRTags(htmlString) {
        // Remove leading <br> tags
        var regexr = /<\/br>/;
        htmlString = htmlString.replace(regexr, '')
        // Remove trailing <br> tags
        htmlString = htmlString.replace(regexr, '')
        return htmlString.trimEnd();
    }

    function extractURLs(message) {
        if (!message) return []; // Return an empty array if no message

        // Helper function to decode HTML entities
        const decodeHTMLEntities = (str) => {
            const textarea = document.createElement("textarea");
            textarea.innerHTML = str;
            return textarea.value;
        };

        // Remove email addresses
        const cleanedMessage = message.replace(/(([a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}))/gi, '');

        // Replace HTML <br> and other tags with a space, then remove all remaining HTML tags
        const plainText = cleanedMessage
            .replace(/<br\s*\/?>/gi, ' ') // Replace <br> with a space
            .replace(/<[^>]+>/g, ''); // Remove other HTML tags

        // Find URLs using linkify
        const links = linkify.find(plainText, 'url');

        // Extract, decode, and return the array of URLs
        return links.map((link) => decodeHTMLEntities(link.href));
    }


    function mentionList(message) {
        if (!message) return;

        var regex1 = /<(?:[^>]*?\s+)?href=(["'])\/profile(.*?)\1/ig

        var mentionId = message.match(regex1)
        mentionId = mentionId.toString();

        var regex2 = /<(?:[^>]*?\s+)?href=(["'])\/profile\/(.*?)/ig
        mentionId = mentionId.replace(regex2, "");

        var regex3 = /"/ig
        mentionId = mentionId.replace(regex3, "");
        var mentions = mentionId.split(',');

        return mentions;
    }

    const handleSendMsg = () => {

        if (upload_popup) {
            dispatch(setBooleanState({ key: 'triggerUpload', data: true }))
            return
        }
        // Utility to clean and format message content
        const formatMessage = (content) => {
            return removeExtraBRTags(content)
                .replace(/[\r\n]/gm, '  </br>')
                .replace(/<\/br>$/, '');
        };

        // Utility to extract mentions
        const extractMentions = (message) => {
            return message.includes('<a title="link" href="/connect/') ? mentionList(message) : [];
        };

        // Validate if the editor state is empty
        const isEmpty = convertToRaw(editorState.getCurrentContent()).blocks.every(b => b.text.trim() === "");
        if (isEmpty) return;

        // Format the message content
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const formattedContent = formatMessage(draftToHtml(rawContent));
        const urls = extractURLs(formattedContent);
        const mentions = extractMentions(formattedContent);
        console.log("🚀 ~ handleSendMsg ~ urls:", urls)

        // Create message payload
        const json_data = {
            conversation_id: props.room?.conversation_id,
            company_id: props.me.company_id,
            sender: props.me.id,
            msg_body: aesEncrypt(formattedContent),
            participants: (props.type === 'task' || !reply_main_msg) ? props.room?.participants : reply_main_msg?.participants,
            is_reply_msg: props.is_reply_msg,
            reply_for_msgid: props.is_reply_msg === 'yes' ? params.msg_id : '',
            task_id: props.type === 'task' ? props?.task?._id : undefined,
            msg_type: urls.length > 0 ? "text link" : props.type === 'task' ? "text discussion" : 'text',
            url_body: urls.length > 0 ? JSON.stringify(urls) : undefined,
            mention_user: mentions
        };


        if (voice) ToggleVoice(false);
        setEmojiView(false);

        // Send message and update state
        return new Promise(async (resolve, reject) => {
            try {
                const send_msg_data = await send_msg_input({ variables: { input: json_data } });
                seteditorState(EditorState.createEmpty());
                dispatch(set_raw_message_text(''));
                placeCaretAtEnd(document.activeElement);

                const dycrepetMsg = await modifyMyMsg([send_msg_data.data.send_msg.msg]);
                dispatch(set_messages({
                    data: [...messages, ...dycrepetMsg],
                    is_reply_msg: props.is_reply_msg,
                    created_at: new Date().toISOString(),
                    my_new_msg: true
                }));

                // Clear local drafts
                const draftKey = params.msg_id || props.room?.conversation_id;
                console.log(310, `draft_${draftKey}`);

                localStorage.removeItem(`draft_${draftKey}`, '');

                // Scroll to bottom
                setTimeout(() => {
                    scrollToBottom('main_msg_scroll_div');
                }, 300);

                resolve(true);
            } catch (error) {
                toast.error(error.message);
                reject(error);
            }
        });
    };


    const handleKeyCommand = (command) => {
        if (command === 'split-block') {
            // This command is triggered when the Enter key is pressed
            // const messageText = convertToRaw(editorState.getCurrentContent()).blocks[0].text;

            handleSendMsg();

            return 'handled';
        }
        return 'not-handled';
    };

    document.onkeydown = function (event) {
        if (document.activeElement.classList.contains('public-DraftEditor-content')) {
            if (event.keyCode === 13 && !event.shiftKey) {
                if (!wrongText) {
                    if (props.EditorRef) props.EditorRef.current.blur()
                    else EditorRef.current.blur()
                    if (document.getElementsByClassName("rdw-suggestion-dropdown")[0] === undefined) {
                        setTimeout(() => {
                            const newState = EditorState.createEmpty()
                            seteditorState(EditorState.moveFocusToEnd(newState));
                            setWrongText(false)
                        }, 10)
                        let draftMsg = '';
                        if (params.msg_id) {
                            draftMsg = localStorage.getItem('draft_' + params.msg_id) !== null && localStorage.getItem('draft_' + params.msg_id) !== undefined ? localStorage.getItem('draft_' + params.msg_id) : '';
                        } else {
                            draftMsg = localStorage.getItem('draft_' + props.room?.conversation_id) !== null && localStorage.getItem('draft_' + props.room?.conversation_id) !== undefined ? localStorage.getItem('draft_' + props.room?.conversation_id) : '';
                        }
                        let draftCheck = draftMsg.replace(/(<([^>]+)>|\n)/ig, '');
                    }
                }
            } else {
                let clientH = Number(document.getElementById('bottomBox').clientHeight) - 46;
                setTimeout(() => {
                    if (document.getElementsByClassName("rdw-suggestion-dropdown")[0] !== undefined) {
                        document.getElementsByClassName("rdw-suggestion-dropdown")[0].style.bottom = clientH + 'px';
                    }
                }, 10)
                // props.startTypingFun();
            }
        }
    };

    useEffect(() => {
        let draftMsg = '';
        if (params.msg_id) {
            draftMsg = localStorage.getItem('draft_' + params.msg_id) !== null && localStorage.getItem('draft_' + params.msg_id) !== undefined ? localStorage.getItem('draft_' + params.msg_id) : '';
        } else {
            draftMsg = localStorage.getItem('draft_' + props.room?.conversation_id) !== null && localStorage.getItem('draft_' + props.room?.conversation_id) !== undefined ? localStorage.getItem('draft_' + props.room?.conversation_id) : '';
        }
        let draftCheck = draftMsg.replace(/(<([^>]+)>|\n)/ig, '');
        if ((draftCheck === '')) {
            // setSendCounter(0)
            // const newState = EditorState.createEmpty()
            // seteditorState(EditorState.moveFocusToEnd(newState));

        }
    }, []);

    const returnMentions = () => {
        if (!props.room?.group || props.room.group !== 'yes') return [];

        // Get participant details
        const participantDetails = cacheAllUsers.users.filter(user =>
            props.room?.participants.includes(user.id)
        );

        // Build mention list
        const mentions = participantDetails
            .filter(user => user.id !== props.me.id) // Exclude the current user
            .map(user => ({
                text: `${user.firstname} ${user.lastname}`,
                value: `${user.firstname} ${user.lastname}`,
                url: `#mention_${user.id}`,
            }));

        // Add "Everyone" mention if there are participants
        if (participantDetails.length > 0) {
            const everyoneId = participantDetails.map(user => user.id).join(',');
            mentions.unshift({
                text: 'Everyone',
                value: 'Everyone',
                url: `#mention_${everyoneId}`,
            });
        }

        return mentions;
    };

    const EditorRef = useRef(null);

    useEffect(() => {
        let draftMsg = '';
        if (params.msg_id) {
            draftMsg = localStorage.getItem('draft_' + params.msg_id) !== null && localStorage.getItem('draft_' + params.msg_id) !== undefined ? localStorage.getItem('draft_' + params.msg_id) : '';
        } else {
            draftMsg = localStorage.getItem('draft_' + props.room?.conversation_id) !== null && localStorage.getItem('draft_' + props.room?.conversation_id) !== undefined ? localStorage.getItem('draft_' + props.room?.conversation_id) : '';
        }
        let draftCheck = draftMsg.replace(/(<([^>]+)>)/ig, '');
        if (draftCheck === '' || draftCheck === '\n') {
            setTimeout(() => {
                const newState = EditorState.createEmpty()
                seteditorState(EditorState.moveFocusToEnd(newState));
                setWrongText(false)
            }, 100)
            // EditorRef.current.focus()
        } else {
            setTimeout(() => {
                const contentBlock = htmlToDraft(draftMsg);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    seteditorState(EditorState.moveFocusToEnd(editorState));
                }
            }, 100)
        }
    }, [EditorRef, props.room?.conversation_id]);


    const handleEmojiSelect = (emoji) => {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithInsert = Modifier.insertText(currentContent, selection, emoji.native, null);
        const editorWithInsert = EditorState.push(editorState, textWithInsert, emoji.native);

        const newEditorState = EditorState.forceSelection(editorWithInsert, textWithInsert.getSelectionAfter());
        seteditorState(newEditorState);
        // setEmojiView(false);
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setEmojiView(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    const handleClearEditor = () => {
        const newState = EditorState.createEmpty()
        setWrongText(false)
        seteditorState(EditorState.moveFocusToEnd(newState));
        dispatch(set_raw_message_text(''))
        if (voice) ToggleVoice(false)
        if (params.msg_id) {
            localStorage.setItem('draft_' + params.msg_id, '');
        } else {
            localStorage.setItem('draft_' + props.room?.conversation_id, '');
        }
    }

    const uploadFiles = (files) => {
        if (files.length > 0) {
            console.log("🚀 ~ uploadFiles ~ files:", files)
            dispatch(set_connect_files(files))
            if (!upload_popup) {
                dispatch(setPopup({ key: 'upload_popup', data: true }))
            }
        }
    }


    return (
        <>
            {emojiView &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={`emojiContainer ${props.type === "task" && '!top-[-435px]'}`}>
                        <Picker
                            className="pickerClass"
                            set={'Apple'}
                            data={emoji_data}
                            previewPosition="none"
                            perLine={25}
                            dynamicWidth={false}
                            maxFrequentRows={4}
                            theme="light"
                            onEmojiSelect={handleEmojiSelect}
                            onClickOutside={() => console.log('Hello World...')}
                        />
                    </div>
                </div>
            }
            <div>
                <div onClick={() => ToggleVoice(!voice)} className={`${voice ? 'voiceIconMsgBox' : 'voiceIcon1MsgBox'} cursor-pointer z-10`} id='recordText' ></div>

                {(props.type !== "task" && !params.msg_id) && <div className={`openAiIconMsgBox cursor-pointer z-10`} id='openAi' onClick={() => dispatch(setBooleanState({ key: 'msg_ai_active', data: true }))}></div>}

                {props.room?.group === 'yes' && props.is_reply_msg === 'no' ?
                    <div className="flex cursor-pointer z-10 rounded-full items-center gap-1 !text-[12px] px-2 py-[2px] bg-[#9A9A9A] !text-white top-[40px] left-[110px] absolute" onClick={() => dispatch(setPopup({ data: true, key: 'private_msg' }))} id="private_msg">
                        <FaLock className="text-[12px] cursor-pointer text-white" data-for="rightSection_tooltip" data-tip="Send a private message to selected user[s]" />
                        <p className="">Private</p>
                    </div>
                    :
                    ''
                }

                <Editor
                    toolbarHidden
                    editorRef={setEditorReference}
                    editorState={editorState}
                    wrapperClassName={wrongText ? "demo-wrapper darkTextWhite BorderRedbox" : "darkTextWhite demo-wrapper"}
                    editorClassName="demo-editor"
                    placeholder={props.is_reply_msg === 'yes' ? "Reply" : "Message " + props.room?.title}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: returnMentions(),
                    }}
                    onBlur={(event, editorState) => editorOnBlur(event, editorState)}
                    spellCheck={true}
                    handleKeyCommand={handleKeyCommand}
                    onEditorStateChange={onEditorStateChange}
                />
            </div >

            <div className={classNames("msg_bottom_bar online")}>
                <div className={classNames("send_msg_opt")}>

                    {editorState.getCurrentContent().hasText() ?
                        <button onClick={handleClearEditor} id="clear">
                            <MdOutlineCancel className="textCloseCancel text-2xl text-red-500" />
                        </button>
                        : null
                    }

                    {
                        props.type === "task" ? <div id='files' onClick={() => props.setTaskFileUpload(true)} className="attachment_selector"></div>
                            :
                            <label id='files' htmlFor='_files' className="attachment_selector">
                                <input type="file" id="_files" onChange={(e) => uploadFiles(e.target.files)} multiple hidden />
                            </label> // <Link id='files' to="file_upload" className="attachment_selector"></Link>
                    }

                    {props.type !== "task" && <div onClick={() => dispatch(setPopup({ key: 'voice_record', data: true }))} id='record' className='flex !w-9 !h-9 !mt-3'>
                        <FiBarChart2 className='fiBarChart2 text-2xl text-[#00246E]' />
                        <FiBarChart2 className='fiBarChart2 text-2xl -ml-1 text-[#00246E]' />
                    </div>
                    }


                    {emojiView ?
                        <div className={`emojiClosee ${props.type === "task" && "!mr-[16px] !ml-[6px]"}`} onClick={() => setEmojiView(false)} >
                            <MdOutlineCancel id='closeEmoji' className="text-2xl text-red-500 -mt-[2px] -ml-[2px]" />
                        </div> :
                        <div className={`${props.type === "task" && "!mr-[16px] !ml-[6px]"}`} onClick={() => setEmojiView(true)}>
                            <BsFillEmojiSmileFill id='InsertEmoji' className='text-xl text-[#00246E]' />
                        </div>
                    }

                    <div id='msgSend' className="msgSend_btn" onClick={handleSendMsg}></div>
                </div>
                <Tooltip anchorSelect="#clear" content="Clear"></Tooltip>
                <Tooltip anchorSelect="#record" content="Record a voice message for yourself."></Tooltip>
                <Tooltip anchorSelect="#files" content="Attach files"></Tooltip>
                <Tooltip anchorSelect="#InsertEmoji" content="Insert emoji"></Tooltip>
                <Tooltip anchorSelect="#closeEmoji" content="Close emoji"></Tooltip>
                <Tooltip anchorSelect="#msgSend" content="Click to send"></Tooltip>
                <Tooltip anchorSelect="#openAi" content="Ask Workfreeli AI"></Tooltip>
                <Tooltip place='top-start' style={{ backgroundColor: "#222222" }} anchorSelect="#recordText" content="Click voice to text. 'send', => message send, 'clear' => clear text, 'Enter' => new line"></Tooltip>
                <Tooltip anchorSelect="#private_msg" className="z-50" content="Send a private message to selected user[s]"></Tooltip>
            </div>
        </>
    )
}

