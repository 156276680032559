
import React, { useEffect } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import { Emoji_Mutation } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { update_messages } from '../../../redux/message';
import { useDispatch } from 'react-redux';
const PerMsgEmoji = ({ msg, setPerMsgEmoji }) => {
    const dispatch = useDispatch();
    const ref = useClickAway(() => {
        setPerMsgEmoji(false)
    });
    const [update] = useMutation(Emoji_Mutation);
    const addReaction = async (type) => {

        let res = await update(
            {
                variables: {
                    input: {
                        conversation_id: msg.conversation_id,
                        msg_id: msg.msg_id,
                        emoji: type,
                        is_reply_msg: msg.is_reply_msg,
                        root_msg_id: msg.reply_for_msgid,
                        participants: msg.participants
                    }
                }
            }
        )
        dispatch(update_messages(
            {
                conversation_id: msg.conversation_id,
                msg_id: msg.msg_id,
                fields: {
                    has_emoji: res.data.emoji.has_emoji
                },
                is_reply_msg: msg.is_reply_msg,
                msg_type: msg.msg_type
            }
        ))


        // let data = {
        //     conversation_id: msg?.conversation_id,
        //     msg_id: msg?.msg_id,
        //     emoji: type,
        //     participants: msg?.participants,
        //     is_reply_msg: msg?.is_reply_msg,
        //     root_msg_id: msg?.is_reply_msg === "yes" ? msg?.reply_for_msgid : "",
        // };
        // console.log(54, data);
        // setPerMsgEmoji(false);
        // let apiRes = await add_reac_emoji(data);
        // console.log('add_reac_emoji:', apiRes);
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setPerMsgEmoji(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [setPerMsgEmoji]);

    return (
        <div className="reactionContainer !border !border-[#e3f2ff] !shadow-none !z-0 !right-0 !bottom-9" ref={ref}>
            <span role="img" aria-label="thumbsup" onClick={() => addReaction("thumbsup")}>
                <img src="/media/images/reaction/like.webp" alt="img" width={30} height={30} title='Thumbs up' />
            </span>
            <span role="img" aria-label="love" onClick={() => addReaction("heart")}>
                <img src="/media/images/reaction/love.webp" alt="img" width={30} height={30} title='love' />
            </span>
            <span role="img" aria-label="joy" onClick={() => addReaction("joy")}>
                <img src="/media/images/reaction/care.webp" alt="img" width={30} height={30} title='Care' />
            </span>
            <span role="img" aria-label="haha" onClick={() => addReaction("grinning")}>
                <img src="/media/images/reaction/haha.webp" alt="img" width={30} height={30} title='Haha' />
            </span>
            <span role="img" aria-label="wow" onClick={() => addReaction("open_mouth")}>
                <img src="/media/images/reaction/wow.webp" alt="img" width={30} height={30} title='Wow' />
            </span>
            <span role="img" aria-label="sad" onClick={() => addReaction("disappointed_relieved")}>
                <img src="/media/images/reaction/sad.webp" alt="img" width={30} height={30} title='Sad' />
            </span>
            <span role="img" aria-label="angry" onClick={() => addReaction("rage")}>
                <img src="/media/images/reaction/angry.webp" alt="img" width={30} height={30} title='Angry' />
            </span>
            <span role="img" aria-label="ask" onClick={() => addReaction("folded_hands")}>
                <img src="/media/images/reaction/ask.webp" alt="img" width={30} height={30} title='Ask' />
            </span>
            <span role="img" aria-label="tick" onClick={() => addReaction("check_mark")}>
                <img src="/media/images/reaction/tick.webp" alt="img" width={30} height={30} title='Tick' />
            </span>
        </div>
    );
};

export default PerMsgEmoji;


