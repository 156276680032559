import React, { memo } from 'react';

const TaskListKeywordPopup = memo(({ KeyWordPopup, set_KeyWordPopup }) => {
    return (
        <div className="backwrap">
            <div className="tagareaList" style={{ right: '0%', top: '0px', maxHeight: '500px', maxWidth: '500px', position: 'relative', paddingTop: '20px', minWidth: '250px' }}>
                <div style={{ width: '100%', display: 'inline-block' }}>
                    <h2 className="popupTitle tagfileTitle">Keywords</h2>
                    <span onClick={() => set_KeyWordPopup(null)} className="closeModal forTag" data-for="rightSection_tooltip" data-tip="Close" style={{ position: 'absolute', right: '25px', top: '25px' }}></span>
                </div>
                {KeyWordPopup.map((v, i) =>
                    <span key={i} style={{ background: 'rgb(11, 31, 71)' }}>{v}</span>
                )}
            </div>
        </div>
    );
});

export default TaskListKeywordPopup;