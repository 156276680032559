
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Tooltip } from "react-tooltip";
import { GET_TEAMS } from '../../Utils/GraphqlQueries';

const InviteGuestList = ({ setPrivateSms, userData, setUserData, selectedUsers, setSelectedUsers }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSelectUsers = (user) => {
        const updatedUserData = userData.filter((u) => u.id !== user.id);
        setUserData(updatedUserData);
        setSelectedUsers([...selectedUsers, user]);
    };

    const handleRemoveUsers = (user) => {
        setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
        setUserData([...userData, user]);
    };

    const filteredUsersData = userData.filter((user) =>
        user.firstname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortByName = (data) => {
        if (!Array.isArray(data)) {
            throw new TypeError('Input must be an array');
        }

        return data.sort((a, b) =>
            (a.firstname + ' ' + a.lastname).localeCompare((b.firstname + ' ' + b.lastname), undefined, { sensitivity: 'base' })
        );
    };

    const [getTeams, { loading: teams_loading }] = useLazyQuery(GET_TEAMS)
    const [teams, setTeams] = useState([])
    const getTeamList = async (_id) => {
        try {
            let apires = await getTeams({ variables: { user_id: _id } })
            setTeams(apires.data.teams)
        } catch (error) {
            console.log("🚀 ~ getTeamList ~ error:", error)
        }
    }

    return (

        <div className="" style={{ marginTop: '10px' }}>
            <div className="searchParticipants !mb-0">
                <span style={{ width: 'calc(100% - 130px)', position: 'relative', float: 'left' }}>
                    <input
                        type="text"
                        className="participantsSearch"
                        placeholder="Search user"
                        style={{ width: '100%' }}
                        value={searchQuery}
                        onChange={handleSearch}
                        autoFocus={true}
                    />
                    <span className="leftSearchCloseBtn remove" style={{ top: '14px', right: '10px' }} onClick={() => setSearchQuery('')}></span>
                </span>
                {/* <button className={`paricipantsSubmit ${selectedUsers.length === 0 ? 'inactive' : 'active'}`} onClick={() => setStartNext(true)}>Start</button> */}
                <button className="paricipantsSubmit">Search</button>
                <div className="selectedParticipants">

                    {selectedUsers.map((user) => (
                        <div key={user.id} className="miniItem">
                            <div className="miniItem_image">
                                <img src="https://wfss001.freeli.io/profile-pic/Photos/img.png" alt="img" title='img' />
                            </div>
                            <div className="miniItem_name">{user.firstname + " " + user.lastname}</div>
                            <div className="miniItem_remove" onClick={() => handleRemoveUsers(user)}></div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="participantsContainer">
                {filteredUsersData.length === 0 ? (
                    <p className="NomemberFound">No member(s) found!</p>
                ) : (
                    sortByName(filteredUsersData)?.filter(u => selectedUsers.map(s => s.id).indexOf(u.id) === -1).map((user) => (
                        <div key={user.id} className="participantItem active" onClick={() => handleSelectUsers(user)}>
                            <div className="participantImage online"><h3 className="nameLetters"> {user.firstname.slice(0, 1).toUpperCase()}{user.lastname.slice(0, 1).toUpperCase()} </h3></div>
                            <div className="flex gap-2 justify-start items-center">
                                <p className="participantTitle">{user.firstname + " " + user.lastname} </p>
                                <p className="text-[#003ec7] hover:text-[#028bff] text-xs ml-1" id={"viewTeam" + user.id}>View team(s)</p>
                            </div>
                            <p className="participantEmail">{user.email}</p>
                            <Tooltip style={{ zIndex: 9999 }} place="top" anchorSelect={"#viewTeam" + user.id} afterShow={() => getTeamList(user.id)}>
                                {teams_loading ? 'Loading...' : null}
                                <ul>
                                    {teams?.map((t, i) =>
                                        <li key={i}>{t.team_title}</li>
                                    )}
                                </ul>
                            </Tooltip>
                        </div>
                    ))
                )}
            </div>

        </div>
    );
};

export default InviteGuestList;



