import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from "react-click-away-listener";

const FilterOptions = ({ conversation_id, setOpenHeadMoreOptions, openHeadMoreOptions, url, me }) => {

    const [flaggedFilterView, setFlaggedFilterView] = React.useState(false)

    const navigate = useNavigate()

    const filterConversation = (type) => {
        if (type === 'flag') {
            setFlaggedFilterView(!flaggedFilterView)
        }
        console.log(flaggedFilterView)

        setOpenHeadMoreOptions(false);
        navigate(`/connect/${conversation_id}/filter/${type}`)
        console.log(`Filtering conversation by type: ${type}`);

        // Simulate API call and dispatch actions
    };

    const handleClickAway = () => {
        setOpenHeadMoreOptions(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="tooltip w-[200px] z-50 pb-2 h-auto -right-[80px] top-[60px] mt-2 bg-white border border-gray-200 rounded-lg">
                <p className='text-xs text-[#5b6477] p-5 !pb-1'>View messages with</p>
                <ul className="text-gray-700">
                    {
                        url === me ? "" : <li onClick={() => filterConversation('private')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">Private messages</li>
                    }
                    <li onClick={() => filterConversation('all_reply')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Threads
                    </li>
                    <li onClick={() => filterConversation('link')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Links
                    </li>
                    <li onClick={() => filterConversation('msg_title')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Titles
                    </li>
                    <li onClick={() => filterConversation('media')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Files
                    </li>
                    <li onClick={() => filterConversation('star')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Starred
                    </li>
                    <li onClick={() => filterConversation('flag')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Flagged content
                    </li>
                    {
                        url === me ? "" :
                            <li onClick={() => filterConversation('unread_msg')} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                                Unread
                            </li>
                    }
                </ul>
            </div>
        </ClickAwayListener>
    );
};

export default FilterOptions;
