import React from 'react';

function SupportAndBillingSuccess(props) {


    return (
        <>


            <div className="supportAndBillingContainer">
                <p className="congratulationsText" style={{ textAlign: 'center', paddingTop: '140px' }}> Congratulations</p>
                <p className="congratsPara" style={{ textAlign: 'center' }}>Your plan is now updated. An reciept of your purchase has been sent to your {props.billingData.address.email}</p>

            </div>



        </>
    )
}





export default SupportAndBillingSuccess;