import React, { useState } from 'react';
import { VscCalendar } from 'react-icons/vsc';
import DatePicker from "react-datepicker";
import range from "lodash/range";
import { getMonth, getYear } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for DatePicker

const DueDate = ({ task, setValue }) => {
    const [dueDate, setDueDate] = useState(null);
    const [error1, setError1] = useState(false);
    const [showEnter, setShowEnter] = useState(false);

    // Dummy data for demonstration purposes
    const years = range(2000, getYear(new Date()) + 10, 1);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const handleDateChange = (date) => {
        if (!date) {
            setError1(true);
            return;
        }
        setError1(false);
        setDueDate(date);
        setValue((prev) => prev.map(v => v.temp_key === task.temp_key ? { ...v, end_date: date.toISOString() } : v));
    };

    const handleDateClear = () => {
        setDueDate(null);
        setValue((prev) => prev.map(v => v.temp_key === task.temp_key ? { ...v, end_date: null } : v));
    };

    return (
        <div className='flex gap-3 items-center !ml-[-20px] w-[44%]'>
            <div className=''>
                <VscCalendar className='VscCalendarIcon' color={'#318fff'} size={22} />
            </div>
            <div className='relative w-[100%]'>
                <div onMouseEnter={() => setShowEnter(true)} onMouseLeave={() => setShowEnter(false)} className='date_area_div date_area_div_width_quick'>
                    <DatePicker
                        className={error1 ? "start_Date_task errorDate Focus !h-[30px]" : "start_Date_task Focus !h-[30px]"}
                        dateFormat="MMMM dd, yyyy"
                        placeholderText="Due date"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className="CalendarDiv">
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                <div>
                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                            </div>
                        )}
                        selected={dueDate}
                        onChange={handleDateChange}
                        minDate={task.start_date}
                    />
                    {showEnter && dueDate ?
                        <span style={{ right: '10px', top: '5px', display: 'block', width: '18px', height: '18px' }} className='removeFile' onClick={handleDateClear}></span> : ""
                    }
                </div>
            </div>
        </div>
    );
};

export default DueDate;
