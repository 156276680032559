/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import { MdAttachFile } from 'react-icons/md';
import CurrencyInput from 'react-currency-input-field';
import { useTaskContext } from '../TaskContext';
import { RenderFiles } from './TaskFilePopup';
import { Tooltip } from 'react-tooltip';
import { Read_task_notification } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { InputValidationCheck, preventInvalidKeyPress, preventPlusSign } from '../../ValidationCheck';

const TrackCostPopup = ({ handleTrackCostPopupClose, set_cost_id }) => {
    const { set_tasks, setSelectedTask, selectedTask, OnUpdateTask, isObserver, setTaskFileUpload, user } = useTaskContext()
    const is_edit = true;
    const input_ref = useRef(null);
    const [mainCostBreakdown, setMainCostBreakdown] = useState({ cost_title: '', forecasted_cost: '', actual_cost: '' });
    const [all_breakdown, set_all_breakdown] = useState(selectedTask?.cost_breakdown?.map(({ actual_cost, cost_title, forecasted_cost }) => ({ actual_cost, cost_title, forecasted_cost })) || []);

    useEffect(() => {
        set_all_breakdown(selectedTask?.cost_breakdown)
    }, [selectedTask?.cost_breakdown])

    const [read_notification] = useMutation(Read_task_notification, {
        onCompleted: (res) => {
            if (res.read_task_notification.success === 'true') {
                setSelectedTask(prev => ({ ...prev, view_cost: [] }))
                set_tasks(prev => prev.map(m => m._id === selectedTask._id ? { ...m, view_cost: [] } : m))
            }
        }
    });

    useEffect(() => {
        if (selectedTask && selectedTask?.view_cost?.indexOf(user?.id) > -1) {
            read_notification(
                { variables: { input: { _id: selectedTask?._id, type: 'view_cost' } } }
            ); // Read the notification
        }
    }, [selectedTask, user, read_notification]);


    // const handleInputChange = (index, value, name) => {
    //     if (value?.trim()) {
    //         set_all_breakdown(prev => prev.map((m, i) => i === index ? { ...m, [name]: value } : m));
    //     }
    // };
    const handleInputChange = (index, value, name) => {
        set_all_breakdown((prev) =>
            prev.map((m, i) =>
                i === index ? { ...m, [name]: value } : m
            )
        );
    };
    // const handleInputChange = (index, value, name) => {
    //     // Allow clearing the input (backspace case)
    //     if (value === "") {
    //         set_all_breakdown((prev) =>
    //             prev.map((m, i) => (i === index ? { ...m, [name]: value } : m))
    //         );
    //         return;
    //     }

    //     // Validate the input
    //     if (InputValidationCheck(value)) {
    //         console.warn("Invalid input"); // Log the error or display a warning
    //         return; // Prevent invalid input
    //     }
    //     else {
    //         // Update state with valid input
    //         set_all_breakdown((prev) =>
    //             prev.map((m, i) => (i === index ? { ...m, [name]: value } : m))
    //         );
    //     }

    //     // Update state with valid input

    // };

    const totalCosts = (all_breakdown || []).reduce((totals, item) => {
        totals.forecasted_cost += Number(item?.forecasted_cost) || 0;
        totals.actual_cost += Number(item?.actual_cost) || 0;
        return totals;
    }, { forecasted_cost: 0, actual_cost: 0 });


    const handleAddRow = () => {
        if (mainCostBreakdown.cost_title?.trim() !== '' && (mainCostBreakdown?.forecasted_cost?.trim() !== '' || mainCostBreakdown?.actual_cost?.trim() !== '')) {
            let forecasted_cost = Number(totalCosts?.forecasted_cost) + Number(mainCostBreakdown?.forecasted_cost);
            let actual_cost = Number(totalCosts?.actual_cost) + Number(mainCostBreakdown?.actual_cost);
            OnUpdateTask({
                cost_breakdown: [
                    ...(all_breakdown?.map(({ cost_title, forecasted_cost, actual_cost }) => ({ cost_title, forecasted_cost, actual_cost })) || []),
                    mainCostBreakdown,
                ],
                forecasted_cost: forecasted_cost,
                actual_cost: actual_cost,
                cost_variance: Number(forecasted_cost - actual_cost),
                save_type: 'cost'
            })
            setMainCostBreakdown({ cost_title: '', forecasted_cost: '', actual_cost: '' })
            input_ref.current.focus();
        }
    };

    const handleBlur = () => {
        let break_down = all_breakdown.map(({ actual_cost, cost_title, forecasted_cost }) => ({ actual_cost, cost_title, forecasted_cost }))
        const updatedCostBreakdown = [...break_down];
        // You can add an additional task update here if needed
        OnUpdateTask({
            cost_breakdown: updatedCostBreakdown,
            forecasted_cost: updatedCostBreakdown.reduce((total, item) => total + Number(item?.forecasted_cost), 0),
            actual_cost: updatedCostBreakdown.reduce((total, item) => total + Number(item?.actual_cost), 0),
            cost_variance: updatedCostBreakdown.reduce((total, item) => total + (Number(item?.forecasted_cost) - Number(item?.actual_cost)), 0),
            save_type: 'cost',
        });
    };

    const handleRemoveClick = (cost, index) => {
        let break_down = all_breakdown.map(({ actual_cost, cost_title, forecasted_cost }) => ({ actual_cost, cost_title, forecasted_cost }))
        const updatedCostBreakdown = [...break_down];
        updatedCostBreakdown.splice(index, 1);

        let forecasted_cost = Number(totalCosts?.forecasted_cost) - Number(cost?.forecasted_cost);
        let actual_cost = Number(totalCosts?.actual_cost) - Number(cost?.actual_cost);
        OnUpdateTask({
            cost_breakdown: updatedCostBreakdown,
            forecasted_cost: forecasted_cost,
            actual_cost: actual_cost,
            cost_variance: Number(forecasted_cost - actual_cost),
            save_type: 'cost'
        })
    };

    const [track_id, set_track_id] = useState('');

    const handleTrackYourCostsChange = (e) => {
        const { name, value } = e.target;
        if (value === "") {
            setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
            return;
        }
        // Validate the input
        if (InputValidationCheck(value)) {
            console.warn('Invalid input'); // Log the error or display a warning
            return; // Prevent invalid input
        } else {
            // Update state with valid input
            setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
        }
    };
    // Export cost to CSV
    const exportCost = () => {
        const csvContent = [
            ['ID', 'Title', 'Forecasted', 'Actual'].join(','), // Header row
            ...all_breakdown.map((item) =>
                [
                    item._id,
                    item.cost_title,
                    item.forecasted_cost,
                    item.actual_cost
                ].join(',')
            ),
        ].join('\n');

        // Create a blob and trigger a download
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cost.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <div className='trackCostPopUpCon'>
                <div className='costsPopUp'>
                    <div className='TrackHoursPopUp'>
                        <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
                            <div className="backToMainProperties">
                                <span className="closeAdminPanel" id="back" onClick={handleTrackCostPopupClose}></span>
                                <span className="backtoProperties">Track costs</span>
                            </div>
                            {all_breakdown?.length > 0 &&
                                <div className="Track_save checkExport preventDefault absolute top-3 right-4 !w-[65px]" onClick={exportCost}>Export </div>
                            }
                        </div>

                        <div className="Track_footer mainFooter">
                            <div className='add_entryText !pb-1'>Add an entry below</div>
                            {isObserver() && <p className='text-red-600 text-sm ml-5 pb-1'>These fields are not accessible to your user role</p>}
                            <div className={`Track_input_fields ${isObserver() && 'pointer-events-none'}`} style={{ paddingRight: '8px' }}>
                                <div className='Track_col' style={{ width: '74%' }}>
                                    <p>Track your costs</p>
                                    <input type='text' ref={input_ref}
                                        value={mainCostBreakdown.cost_title}
                                        onChange={handleTrackYourCostsChange}
                                        // onChange={(e) => setMainCostBreakdown(prev => ({ ...prev, [e.target.name]: e.target.value }))} 
                                        name="cost_title"
                                        placeholder='Cost title like food, travel etc'
                                        className='track_inputBox' autoFocus={!isObserver()} />
                                </div>
                                <div className='Track_col' style={{ width: '25%', marginLeft: '12px' }}>
                                    <p className='ml-2'>Forecasted</p>
                                    <div className='flex items-center'>
                                        <span>$</span>
                                        <input type='number'
                                            className='track_inputBox cost_title'
                                            value={mainCostBreakdown?.forecasted_cost}
                                            onChange={(e) => {
                                                const { name, value } = e.target;

                                                // Allow empty value for clearing input
                                                if (value === "") {
                                                    setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
                                                    return;
                                                }

                                                // Ensure the value is a number and not negative
                                                if (!isNaN(value) && parseFloat(value) >= 0) {
                                                    setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
                                                } else {
                                                    console.warn("Negative values are not allowed.");
                                                }
                                            }}
                                            name="forecasted_cost" placeholder="0"
                                            onKeyDown={(e) => {
                                                preventPlusSign(e); // Prevent plus sign key
                                                preventInvalidKeyPress(e); // Other invalid key press prevention logic
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='Track_col' style={{ width: '25%', marginLeft: '14px' }}>
                                    <p className='ml-2'>Actual</p>
                                    <div className='flex items-center'>
                                        <span>$</span>
                                        <input type='number'
                                            className='track_inputBox cost_title'
                                            value={mainCostBreakdown.actual_cost}
                                            // onChange={(e) => setMainCostBreakdown(prev => ({ ...prev, [e.target.name]: e.target.value }))} 
                                            onChange={(e) => {
                                                const { name, value } = e.target;

                                                // Allow empty value for clearing input
                                                if (value === "") {
                                                    setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
                                                    return;
                                                }

                                                // Ensure the value is a number and not negative
                                                if (!isNaN(value) && parseFloat(value) >= 0) {
                                                    setMainCostBreakdown((prev) => ({ ...prev, [name]: value }));
                                                } else {
                                                    console.warn("Negative values are not allowed.");
                                                }
                                            }}
                                            name="actual_cost" placeholder="0"
                                            onKeyDown={(e) => {
                                                preventPlusSign(e); // Prevent plus sign key
                                                preventInvalidKeyPress(e); // Other invalid key press prevention logic
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='file_attachment_lebel !p-0 !mt-[22px] !ml-[5px]'>
                                    {is_edit && (
                                        <div className='addBtn' onClick={handleAddRow} style={mainCostBreakdown.cost_title?.trim() !== '' && (mainCostBreakdown?.forecasted_cost?.trim() !== '' || mainCostBreakdown.actual_cost?.trim() !== '') ? {} : { pointerEvents: 'none', opacity: '0.6' }} >Add</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="Track_body mainBody">
                            {all_breakdown?.length > 0 ?
                                <div className='Track_fields'>
                                    {all_breakdown?.map((cost, i) => (
                                        <div className={`Track_input_fields !flow-root`} key={i}>
                                            <div className={`flex items-center ${isObserver() && 'pointer-events-none'}`}>
                                                <span className="task_unread"></span>
                                                <div className='Track_col' style={{ width: '60%' }}>
                                                    <input
                                                        type='text'
                                                        name="cost_title"
                                                        value={cost.cost_title}
                                                        onChange={(e) => handleInputChange(i, e.target.value, 'cost_title')}
                                                        onBlur={handleBlur}
                                                        className={`track_inputBox ${cost.cost_title.length > 0 ? 'cost_title' : ''}`}
                                                        placeholder='Actual cost title'
                                                    />
                                                </div>
                                                <div className='Track_col flex items-center justify-center' style={{ width: '20%', marginLeft: '12px' }}>
                                                    <span>$</span>
                                                    <CurrencyInput
                                                        className={`track_inputBox ${cost?.forecasted_cost > 0 ? "cost_title" : ""}`}
                                                        name="forecasted_cost"
                                                        placeholder="0"
                                                        decimalsLimit={2}
                                                        prefix=""
                                                        value={cost?.forecasted_cost}
                                                        onValueChange={(value) => handleInputChange(i, value, "forecasted_cost")}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className='Track_col flex items-center justify-center' style={{ width: '20%', marginLeft: '14px' }}>
                                                    <span>$</span>
                                                    <CurrencyInput
                                                        className={`track_inputBox ${cost.actual_cost > 0 ? "cost_title" : ""}`}
                                                        name="actual_cost"
                                                        placeholder="0"
                                                        decimalsLimit={2}
                                                        prefix=""
                                                        value={cost.actual_cost}
                                                        onValueChange={(value) => handleInputChange(i, value, "actual_cost")}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>

                                                <div className='icon_attachment' style={{ marginTop: '5px', marginLeft: '5px', width: '28px' }}>
                                                    <span className='attachment_icon_file' onClick={() => { set_cost_id(cost._id); setTaskFileUpload(true) }}>
                                                        <MdAttachFile id="file" className="file_Attachment" color='#0079d1' size={24} />
                                                    </span>
                                                </div>
                                                {!isObserver() && <div id='delete' className="removeFile trackCosts" style={{ top: '3px' }} onClick={() => handleRemoveClick(cost, i)}></div>}
                                            </div>

                                            {track_id === cost._id &&
                                                <div className='Track_files'>
                                                    {selectedTask?.files?.filter(f => f.cost_id && f.cost_id === cost._id)?.length > 0 &&
                                                        <RenderFiles files={selectedTask?.files?.filter(f => f.cost_id && f.cost_id === cost._id)} />}
                                                </div>
                                            }
                                            {selectedTask?.files?.filter(f => f.cost_id && f.cost_id === cost._id)?.length > 0 &&
                                                <div className='file_show_hide mt-1 cursor-pointer' onClick={() => set_track_id(track_id === cost._id ? '' : cost._id)}>
                                                    {track_id === cost._id ? 'Hide files' : 'Show files'}
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='notFoundRow !-mt-8'> Please add a row </div>
                            }
                        </div>

                        <div className="footerBottom mainFooterBottom mainFooterBottom">
                            <div className="Track_body_headding foot border-b border-b-[#cde5f9]">
                                <div className="Track_col pl-2" style={{ width: '56%' }}>Total</div>
                                <div className="Track_col" style={{ width: '19%', marginLeft: '-24px', textAlign: 'right' }}>${totalCosts?.forecasted_cost?.toFixed(2)}</div>
                                <div className="Track_col" style={{ width: '20%', marginLeft: '14px', textAlign: 'right' }}>${totalCosts?.actual_cost?.toFixed(2)}</div>
                            </div>
                            <div className="Track_body_headding foot_middle">
                                <div className="Track_variance_text">variance</div>
                                <div className="Track_variance_amount" style={{ marginRight: '18px' }}>${(totalCosts?.forecasted_cost - totalCosts?.actual_cost)?.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Tooltip anchorSelect="#back" style={{ zIndex: 9999 }} place="top" content="Back"></Tooltip>
            <Tooltip anchorSelect="#file" style={{ zIndex: 9999 }} place="top" content="Attach a file"></Tooltip>
            <Tooltip anchorSelect="#delete" style={{ zIndex: 9999 }} place="top" content="Delete"></Tooltip>
        </>
    );
};

export default TrackCostPopup;