/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import CryptoJS from "crypto-js";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import toast from 'react-hot-toast';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import DOMPurify from 'isomorphic-dompurify';
import { useMutation } from '@apollo/client';
import { EDIT_MESSAGE_MUTATION } from '../../../Utils/GraphqlMutaion';

const mykey = 'D1583ED51EEB8E58F2D3317F4839A';

const EditMsg = ({ data, setEditMsg, room }) => {
    const EditorRef = useRef();
    // const _active_conv = useSelector(state => state.message.active_conversation);
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const [newval, setNewVal] = useState(data.msg_body.replace(/<\/br>$/g, ''));
    const [, setLoader] = useState(false);
    const [, setIsEdited] = useState(false);

    const [isEditedValue, setIsEditedValue] = useState(false);
    const oldmsg = data.msg_body.replace(/<\/br>$/g, '');
    function isBlank(str) {
        var newSting = str.replace(/(?:&nbsp;)/gm, '');
        return (!newSting || /^\s*$/.test(newSting));
    }

    function removeHtmlTags(str) {
        return str.replace(/<[^>]*>/g, '');
    }

    const onEditorStateChange = (editorState) => {
        seteditorState(editorState);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        let _isEmpty = isBlank(DOMPurify.sanitize(markup.replace(/<[^>]*>?/gm, '')))
        if (_isEmpty) {
            setIsEdited(false);
        } else {
            setNewVal(markup);
            setIsEdited(markup !== oldmsg);
        }
    }

    useEffect(() => {
        if ((removeHtmlTags(oldmsg)?.trim() !== removeHtmlTags(editorState.getCurrentContent().getPlainText())?.trim()) && removeHtmlTags(editorState.getCurrentContent().getPlainText())?.trim()?.length > 0) {
            setIsEditedValue(true);
        } else {
            setIsEditedValue(false);
        }
    }, [editorState, oldmsg]);

    useEffect(() => {
        const html = data.msg_body.replace(/<\/br>$/g, '');
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            seteditorState(EditorState.moveFocusToEnd(editorState));
        }
    }, []);

    const [update_msg] = useMutation(EDIT_MESSAGE_MUTATION);

    const saveMsg = async () => {
        if (!isBlank(newval)) {
            if (oldmsg !== newval && newval.replace(/<[^>]+>/g, '') !== '') {
                const cipher = CryptoJS.AES.encrypt(JSON.stringify(newval.trim()), mykey).toString();
                setLoader(true);
                let obj = {
                    conversation_id: data.conversation_id,
                    msg_id: data.msg_id,
                    is_reply: data.is_reply_msg,
                    new_msg_body: cipher.toString(),
                }
                update_msg({
                    variables: {
                        input: { ...obj }
                    }
                });
                setEditMsg(false);
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            toast.error("Blank Text");
        }
    }

    const handleKeyCommand = (command, editorState) => {
        if (command === 'split-block') {
            saveMsg();
            return 'handled';
        }
        return 'not-handled';
    }

    return (
        <div className={`editMsgContainer ${editorState.getCurrentContent().getPlainText().length === 0 ? 'redAlertBorder' : ''}`} id="msgEditBox">
            <Editor
                toolbarHidden
                editorRef={(ref) => (EditorRef.current = ref)}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                wrapperClassName={" demo-wrapper"}
                editorClassName="demo-editor"
                spellCheck={true}
                onEditorStateChange={(event) => onEditorStateChange(event)}
            />
            <div className="editMsgBtnSection">
                <button className="btnCancel" onClick={() => setEditMsg(false)}>Cancel</button>
                {/* || oldmsg === '' */}
                <button className={`btnAction hover:bg-[#318fff] ${isBlank(newval) || !isEditedValue ? 'disabled !pointer-events-none !bg-[#9A9A9A]' : ''}`} onClick={saveMsg}>Save</button>
            </div>
        </div>
    )
};

export default EditMsg;
