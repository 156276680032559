import React, { useEffect, useState } from 'react';
// import { BsCurrencyDollar } from "react-icons/bs";
import 'react-datepicker/dist/react-datepicker.css';
import SalesBarChart from './SalesBarChart';
import EodPieChart from './EodPieChart';
import moment from 'moment';
import Select, { components } from 'react-select';
import { FaAngleDown } from 'react-icons/fa6';
import { MdClear, MdOutlineClose } from 'react-icons/md';
import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GETVENULIST, GET_ME, GET_EOD_DASHBOARD } from '../../Utils/GraphqlQueries';
import { useSelector, useDispatch } from 'react-redux';

const EodDashboardTab = () => {
    const [demoEodData, setDashboardData] = useState({
        today: 0,
        yesterday: 0,
        thisWeek: 0,
        thisMonth: 0,
        thisYear: 0,
        weekDayBreakdown: [
            { day: 'Monday', totalActual: 0, totalEstimate: 0 },
            { day: 'Tuesday', totalActual: 0, totalEstimate: 0 },
            { day: 'Wednesday', totalActual: 0, totalEstimate: 0 },
            { day: 'Thursday', totalActual: 0, totalEstimate: 0 },
            { day: 'Friday', totalActual: 0, totalEstimate: 0 },
            { day: 'Saturday', totalActual: 0, totalEstimate: 0 },
            { day: 'Sunday', totalActual: 0, totalEstimate: 0 },
        ],
        dateRangeTotal: {
            totalActualLabour: 0,
            totalNetSales: 0,
        },
        pia: [{
            totalFohHourlyActual: 0,
            totalFohSalaryActual: 0,
            totalBohHourlyActual: 0,
            totalBohSalaryActual: 0,
        }],
        statistics : {
            AverageStay: 0,
            AvgCheckGross: 0,
            AvgCheckNet: 0,
            AvgGuestGross: 0,
            AvgGuestHour: 0,
            AvgGuestNet: 0,
            AvgHourGross: 0,
            AvgHourNet: 0,
            CheckCount: 0,
            EntreeCount: 0,
            GuestCount: 0,
            TotalHours: 0,
        },
        cash: [
            // {
            // CashPaymentsAmount: 0,
            // NonCashTips: 0
            // }
        ]
    });
    // const [EodStats, setEodStats] = useState({
    // })
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const [venues, setVenuesList] = useState([]);
    const { data: venue_list } = useQuery(GETVENULIST)
    
    const [fetchDashboard, { loading: dashboardLoading, data: eod_dashboard }] = useLazyQuery(GET_EOD_DASHBOARD, { fetchPolicy: 'no-cache' });

    // console.log('eod_dashboard',dashboardLoading, eod_dashboard);

    const [week_day_from, set_week_day_from] = useState(null);
    const [week_day_to, set_week_day_to] = useState(null);
    const [day_from, set_day_from] = useState(null);
    const [day_to, set_day_to] = useState(null);

    const selected_venue_id = useSelector(state => state.eod.selected_venue_id) || '';
    const [selectedVenues, setSelectedVenues] = useState([]);

    useEffect(() => {
        
        if(selected_venue_id){
            setSelectedVenues([selected_venue_id]);
        }else{
            setSelectedVenues([]);
        }
    
    },[selected_venue_id])

    useEffect(() => {
        
        if (venue_list?.get_venue_list?.status) {
            setVenuesList(venue_list?.get_venue_list?.result?.filter(f=> f.id))
        }
    }, [venue_list]);

    useEffect(() => {
        if (eod_dashboard?.get_eod_dashboard?.status) {
            setDashboardData(eod_dashboard?.get_eod_dashboard.result);
        }
    }, [eod_dashboard]);

    
    
    const [visible, setVisible] = useState(true);
    const [fade, setFade] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleVenueChange = (selectedOptions) => {
        
        let selected = selectedOptions.map(option => option.venue_id);
        // console.log('handleVenueChange',selected)
        setSelectedVenues(selected);
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <FaAngleDown className='rightArrowSelectIcon' style={{
                    color: '#858fa3',
                    transition: 'transform 0.2s',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                }} />
            </components.DropdownIndicator>
        );
    };

    const ClearIndicator = (props) => {
        const {
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;

        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >

                {
                    selectedVenues.length > 1 && <div className='clearAllContainer' style={{ padding: '0px 5px' }}>
                        <MdClear className='clearAllIconSelect' />
                    </div>
                }
            </div>
        );
    };

    const customStyles = {
        control: base => ({
            ...base,
            maxHeight: 70,
            overflow: 'auto',
            backgroundColor: "white",
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
        }),
        placeholder: defaultStyles => ({
            ...defaultStyles,
            color: '#858fa3',
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer',
            color: state.isFocused ? 'blue' : 'black',
        }),
        indicatorSeparator: () => { },

    };


    useEffect(() => {
        // debugger;
        // fetchDashboard({ variables: { timezone: moment.tz.guess()  } });
        const showTimer = setTimeout(() => {
            setVisible(true);
            setFade(true); // Start fade-in effect
        }, 1000); // Show after 1 seconds

        const hideTimer = setTimeout(() => {
            setFade(false); // Start fade-out effect
            setTimeout(() => setVisible(false), 500); // Wait for fade-out to complete
        }, 10000); // Hide after 10 seconds

        return () => {
            clearTimeout(showTimer);
            clearTimeout(hideTimer);
        };
    }, []);

    const getVenuePermit = (venue) => {
        // console.log('dash:per',venue);
        if (venue.venue_title === 'All') return false;

        let Submitter = venue?.eod_submitters;
        let Reopener = venue?.eod_reopen;

        if (Reopener?.includes(cacheMe.me.id) || Submitter?.includes(cacheMe.me.id)) { return true }
        else return false

        // if (Reopener?.includes(cacheMe.me.id) && Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Reopener?.includes(cacheMe.me.id)) { return false }
        // return false


    }

    

    useEffect(() => {
        // debugger
        fetchDashboard({ variables: { timezone: moment.tz.guess(), week_day_from, week_day_to, day_from, day_to, venue_id: selectedVenues  } });
    }, [selectedVenues, week_day_from, week_day_to, day_from, day_to])


    return (
        <>
        {
            dashboardLoading ?
            <div className='h-full w-full flex items-center justify-center absolute left-0 top-50 z-[1] bg-white/90'>
                <div className="loaderMain mini_loader"></div>
            </div> 
            :
        
            <div className='salesReportTabCon'>
                <div className='salesReportMainCon'>
                    <div className='eodReportDateAreaHeader'>
                        <div className='salesReportLeft'>
                            <p className='salesReportDateText'>{moment().format("MMMM Do, YYYY")}</p>
                            <div className='selectVenueRight'>
                                <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={venues}
                                    getOptionLabel={(option) => option.venue_title}
                                    getOptionValue={(option) => option.venue_id}
                                    styles={customStyles}
                                    value={venues.filter(v => selectedVenues.includes(v.venue_id))}
                                    // value={venues.filter(v => selectedVenues.includes(v.venue_id))}
                                    onChange={handleVenueChange}
                                    placeholder='Select venues'
                                    onMenuOpen={() => setIsDropdownOpen(true)}
                                    onMenuClose={() => setIsDropdownOpen(false)}
                                    components={{ DropdownIndicator, ClearIndicator }}
                                />
                            </div>
                        </div>
                        <div className='salesReportRightCon'>
                            {visible && (
                                <div className={`salesReportRightText ${fade ? 'show' : ''}`}>
                                    <p>Sales data is based on End of Day reports</p>
                                    <MdOutlineClose className='salesReportRightTextIcon' onClick={() => setVisible(false)} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='salesReportGraphHead'>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.today || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>Today's sales</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.yesterday || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>Yesterday's sales</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisWeek || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This week till date</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisMonth || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This month till date</p>
                        </div>
                        <div className='salesReportGraphBox'>
                            <div className='salesReportBox'>
                                <p className='salesReportIcon'>$</p>
                                <p className='salesReportNumber'>
                                    {
                                        new Intl.NumberFormat('en-US', {
                                            // style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(Math.round(demoEodData?.thisYear || 0))
                                    }
                                </p>
                            </div>
                            <p className='salesReportText'>This year till date</p>
                        </div>
                    </div>
                </div>
                <div className='salesProgressBarCon'>
                    <SalesBarChart demoData={demoEodData} date={{ week_day_from, set_week_day_from, week_day_to, set_week_day_to, day_from, set_day_from, day_to, set_day_to }} />
                    <EodPieChart demoData={demoEodData} date={{ week_day_from, set_week_day_from, week_day_to, set_week_day_to, day_from, set_day_from, day_to, set_day_to }}   />
                </div>
            </div>
        }
        </>
    );
};

export default EodDashboardTab;