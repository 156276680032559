import React from 'react';

const WarningRoles = ({ roleToDelete, setDeleteRolePopup, setRoleToDelete, roles, setRoles }) => {
    const confirmRoleModule = () => {
        if (roleToDelete) {
            setRoles(roles.filter(role => role.id !== roleToDelete.id)); // Remove module
            setRoleToDelete(null);
            setDeleteRolePopup(false); // Close the popup
        }
    };

    const cancelRoleModule = () => {
        setRoleToDelete(null);
        setDeleteRolePopup(false); // Close the popup
    };
    return (
        <div className="backwrap">
            <div className="createTeamModal">
                <div className="createTeamModalHead">
                    <h4 className="popupTitle">Delete Role</h4>
                    <span className="closeModal" onClick={cancelRoleModule}></span>
                </div>
                <div className="createTeamModalBody">
                    <p>Are you sure you want to delete the role "{roleToDelete?.roleName}"?</p>
                </div>
                
                <div className='createTeamModalFoot !px-0 gap-3 mr-6'>
                    <button className="btnAction !bg-red-600" onClick={confirmRoleModule}>Delete role</button>
                    <button className="btnCancel hover:!border-[#0c1e47] " onClick={cancelRoleModule}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default WarningRoles;