/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router';
import SecuritySettings from './SecuritySettings';
import WarningPopup from './WarningPopup';
import { GET_ME, GET_CALLING_URL } from '../../Utils/GraphqlQueries';
import { UPDATE_USER_MUTATION } from '../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { upload_obj } from '../../Utils/Common';
import { Toaster, toast } from "react-hot-toast";
import defaultImage from '../../media/images/img.png';
import TimezoneSelect from 'react-timezone-select';
import DatePicker from 'react-datepicker';
import { MdOutlineCancel } from "react-icons/md";
import UserProWarnning from './UserProWarnning';
import { ring_calling } from '../../Utils/Common';
import { useSelector } from "react-redux";
import { GET_RING_CALLING, SET_CALLING_URL, GET_CALLING_ACCEPT, GET_CALLING_REJECT } from '../../Utils/GraphqlQueries';


// https://wfss001.freeli.io/profile-pic/Photos/img.png

const UserProfile = () => {
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    // console.log('UserProfile',user);
    const navigate = useNavigate();
    // const [copied, setCopied] = useState(false);
    const [update] = useMutation(UPDATE_USER_MUTATION);
    const [isEdited, setIsEdited] = useState(false);
    const [imgFound, setImgFound] = useState(user.img || "https://wfss001.freeli.io/profile-pic/Photos/img.png");
    const [shortID, setshortID] = useState(user.short_id);
    const [cancelWarnning, setCancelWarnning] = useState(false);

    const active_conv = useSelector(state => state.message.active_conversation); 
    // console.log("active_conv",active_conv);
    
    const [fetchCalling] = useLazyQuery(GET_RING_CALLING);
    const [setCallingURL] = useLazyQuery(SET_CALLING_URL);
    const [getCallingAccept] = useLazyQuery(GET_CALLING_ACCEPT);
    const [getCallingReject] = useLazyQuery(GET_CALLING_REJECT);
    // const [fileLoader, setFileLoader] = useState(false);
    // const [getCallingURL, { loading: getLoadingURL, data: getURLdata }] = useQuery(GET_CALLING_URL);
    // const { data: getCallingURL } = useQuery(GET_CALLING_URL,{ variables: { type: 'user',company_id: cacheMe.me.company_id }, fetchPolicy: 'no-cache' });
    // console.log('getCallingURL',getCallingURL);

    const [selectedTimezone, setSelectedTimezone] = useState({
        // value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        value: user.timezone
    });

    const initialData = {
        firstname: user.firstname,
        lastname: user.lastname,
        img: user.img || "https://wfss001.freeli.io/profile-pic/Photos/img.png",
        phone: user.phone ? user.phone[0] : "+880",
        email: user.email,
        timezone: user.timezone,
        digest_email: user.digest_email,
        email_send_time: user.email_send_time,
    };

    const [userdata, setUserData] = useState(initialData);
    // Compare current data with initial data
    const hasDataChanged = (current, initial) => {
        return JSON.stringify(current) !== JSON.stringify(initial);
    };

    const submit = async () => {
        try {
            await update({
                variables: {
                    input: {
                        firstname: userdata.firstname,
                        lastname: userdata.lastname,
                        img: userdata.img,
                        phone: userdata.phone ? userdata.phone : '+880',
                        timezone: userdata.timezone,
                        digest_email: userdata.digest_email,
                        email_send_time: userdata.email_send_time,
                        user_id: user.id
                    }
                }
            });
            window.location.href = '/';
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setIsEdited(hasDataChanged(userdata, initialData));
    }, [userdata, initialData]);

    const handleInput = (value, fieldName) => {
        // debugger
        setUserData((prevData) => ({
            ...prevData,
            [fieldName]: value
        }));
    };

    const handleTimezoneChange = (newTimezone) => {
        setSelectedTimezone(newTimezone);
    };

    const uploadFiles = async (_f) => {
        // setFileLoader(true);
        var data = new FormData();
        data.append("bucket_name", 'profile-pic');
        var sl = moment().format('x');
        _f[0]['originalname'] = _f[0].name;
        _f[0]['mimetype'] = _f[0].type;
        _f[0]['fieldname'] = 'file_upload';
        data.append("sl", sl);
        data.append("file_upload", _f[0]);
        const config = {};
        upload_obj(data, config, (res) => {
            console.log('Uploaded file:', res);
            setUserData({ ...userdata, img: res.file_info[0].key });
            setImgFound(res.file_info[0].location);
            // setFileLoader(false);
            setIsEdited(true);
        });
    };

    const handleTabClick = (tabId) => {
        if(tabId === "security"){
            
        }
        setActiveTab(tabId);
    };

    // const handleGoBack = () => {
    //     navigate(-1);
    // };
    const handleGoBack = () => {
        if( isEdited){
            setCancelWarnning(true); // Show the RoomUpdateWarnning component
        }else{
            navigate(-1); // Navigate back
        }
        
    };


    const [activeTab, setActiveTab] = useState('profileTab');
    const [resetPopUp, setResetPopUp] = useState(false);

    const handleResetPopup = () => {
        setResetPopUp(!resetPopUp);
    };

    const copyLinkToClipboard = () => {
        const link = document.getElementById('conferenceLinkText');
        link.select();
        document.execCommand('copy');
        // setCopied(true);
        toast.success('Link copied to clipboard');
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                navigate(-1);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const removeImage = () => {
        setUserData({ ...userdata, img: "https://wfss001.freeli.io/profile-pic/Photos/img.png" });
        setImgFound(defaultImage);
        setIsEdited(true);
    };
    const onCancel = () => {
        setCancelWarnning(false); // Close the warning
        navigate(-1); // Navigate back
    };

    const meetwin = () => {
		// openCallWindow(props.logindata.user.id);
        // debugger;
        // let conversation_type = active_conv?.group === 'yes' ? 'group' : 'personal';
        // var active_conv_details = { ...active_conv };
        var call_link = window.location.origin + '/u/' + shortID

		ring_calling({
            user_id: user.id,
            conversation_type: 'personal',
            arr_participants: [user.id],
            participants_all: [user.id],
            company_id: user.company_id,
            conversation_id: user.id,
            convname: user.firstname + " " + user.lastname,
            call_link: call_link,
            call_option: 'window',
            fetchCalling, getCallingAccept, getCallingReject
        });

		// props.calling_open_btn.current.click();
	}


    return (
        <>
            <Toaster />
            {cancelWarnning && <UserProWarnning onCancel={onCancel} onSave={submit}/>}
            <div className="createGroupConv userUpdatePopup">
                <div className="createConv_head" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
                    <span onClick={handleGoBack} data-tip="Back" className="createConv_back profileBack" currentitem="false"></span>
                    <p className="profileTitle" style={{ top: '23px' }}>
                        <span className="userProfileName">{userdata.firstname + ' ' + userdata.lastname}</span>
                    </p>
                    <span onClick={handleGoBack} data-tip="Close" className="createConv_close profileClose" currentitem="false"></span>
                </div>
                <div className="createConv_body" style={{ padding: '8px 120px' }}>
                    <div className="usersTabSecDevide" style={{ marginBottom: '15px' }}>
                        <div id="profileTab" className={`usersTabList teamManagementTitle ${activeTab === 'profileTab' ? 'usersTabListActive active' : ''}`} onClick={() => handleTabClick('profileTab')}>Profile settings</div>
                        <div id="security" className={`usersTabList teamManagementTitle ${activeTab === 'security' ? ' usersTabListActive active' : ''}`} onClick={() => handleTabClick('security')} >Security settings </div>
                    </div>
                    {
                        activeTab === 'profileTab' ? <div className="userProfileArea">
                            <div className="ProfileTab relative">
                                <div className="name_and_profile relative">
                                    <div className="inputGroup">
                                        <label className="inputLabel">First name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" className="firstName" name="firstname" placeholder="First Name" required="" value={userdata.firstname} onChange={(e) => handleInput(e.target.value, 'firstname')} />
                                    </div>
                                    <div className="inputGroup">
                                        <label className="inputLabel">Last name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" className="lastName" name="lastname" placeholder="Last Name" required="" value={userdata.lastname} onChange={(e) => handleInput(e.target.value, 'lastname')} />
                                    </div>
                                    <div className="inputGroup">
                                        <label className="inputLabel">Email address</label>
                                        <input className="disabled" disabled type="email" name="email" placeholder="User Email" value={userdata.email} onChange={(e) => handleInput(e.target.value, 'email')} />
                                    </div>

                                    <div className="inputGroup" style={{ position: 'relative' }}>
                                        <label className="inputLabel" phone="880">Phone number (optional)</label>
                                        <PhoneInput
                                            className="inputClass userPhone"
                                            inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                                            enableSearch={true}
                                            preferredCountries={["bd", "us", "ca", "in"]}
                                            country="bd"
                                            value={userdata.phone}
                                            onChange={(value) => handleInput(value, 'phone')}
                                            inputProps={{
                                                placeholder: "(000) 000-0000",
                                            }}
                                        />
                                        <span className="rgInputMsg">This is for verification and security purposes only. We will never share your phone number with anyone.</span>
                                    </div>
                                    <div className="pr-[35px] mb-5" style={{ position: 'relative' }}>
                                        <label className="inputLabel" >Timezone (optional)</label>
                                        <TimezoneSelect
                                            placeholder="Choose your timezone"
                                            value={userdata.timezone} 
                                            onChange={(e) => handleInput(e.value, 'timezone')} 
                                            className="custom-timezone-select !border-none" styles={{ border: "none" }}  
                                        />
                                    </div>
                                    <div className="review_onOff flex gap-10 innUderUpdated" style={{ paddingBottom: '10px' }}>
                                        <div className="reviewToggle">
                                            <label className="inputLabel">Daily email digest (optional)</label>
                                            <div className="toggle-button-cover" style={{ left: '0px' }}>
                                                <input
                                                    className="cm-toggle"
                                                    checked={userdata.digest_email}
                                                    onChange={(e) => handleInput(e.target.checked, 'digest_email')}
                                                    type="checkbox"
                                                />
                                            </div>
                                        </div>

                                        {userdata.digest_email && (
                                            <div className="timeToggle group" style={{ position: 'relative' }}>
                                                <label className="inputLabel">Time (optional)</label>
                                                <DatePicker
                                                    className='mainTime updateUserTime'
                                                    selected={userdata.email_send_time ? new Date(userdata.email_send_time) : null}
                                                    onChange={(date) => handleInput(date ? date.toISOString() : null, 'email_send_time')}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={5}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    placeholderText="Time"
                                                />
                                                {userdata.email_send_time && (
                                                    <MdOutlineCancel
                                                        className="hidden group-hover:block absolute top-[34px] text-red-600 text-xl right-2"
                                                        onClick={() => handleInput(null, 'email_send_time')} // Set null to clear the value
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="inputGroup conferenceLink">
                                        <div className="reset-link-div">
                                            <label className="inputLabel">Call link</label>
                                            <label className="createUrl_reset" data-for="voip_tooltip" data-tip="Reset call link if you found the link vulnerable" currentitem="false" onClick={handleResetPopup}>Reset link</label>
                                            <div className="opt_icons conv_voice" data-for="voip_tooltip" data-tip="Open a conference call to allow other to join here" currentitem="false" onClick={(e) => meetwin()}></div>
                                        </div>
                                        <span>
                                            <div onClick={copyLinkToClipboard} className="copyIcon"><img title='Copy' data-for="updateConv_tooltip" data-tip="Click to copy link" className="copyMeLink profile" src="/media/images/copyLink.svg" alt="copy" /></div>
                                            <input id="conferenceLinkText" name="conferenceLinkText" readOnly type="text" className="team-name short_id_input" placeholder="" value={`${window.location.origin + '/u/' + shortID}`} />
                                        </span>
                                        <span className="rgInputMsg">Share this link with teammates or guests to start a video/voice call.</span>
                                    </div>
                                </div>
                                <div className="name_and_profile relative">
                                    <p className="set_avatar">Set an avatar</p>
                                    <div className="upload_section flex-col user_up relative">
                                        <label className="img-div">
                                            <div className="content-overlay"></div>
                                            <img title="Image" src={imgFound} className={'roomUploadImage'} alt="img" />
                                            <div className="removeAndChange" style={removeImage ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}>
                                                <div className="changeButton">
                                                    <input type="file" id="upload_conv_img" onChange={(e) => uploadFiles(e.target.files)} name="photos" accept="image/x-png,image/jpeg,image/jpg" hidden />
                                                    {/* <label htmlFor="upload_conv_img">Change</label> */}
                                                </div>

                                            </div>
                                        </label>
                                        {
                                            userdata?.img === "https://wfss001.freeli.io/profile-pic/Photos/img.png" ? "" : <div className="bg-[#E82A87] cursor-pointer z-10 -mt-16 px-2 text-white text-sm rounded-md" onClick={removeImage}>Remove</div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div> : <SecuritySettings userdata={user} />
                    }
                </div>
                {
                    isEdited &&

                    <div className="createConv_foot">
                        <button className="createConv_create mr-3" onClick={() => handleGoBack()}>Back</button>
                        <button className="createConv_create" onClick={submit}>Update</button>
                    </div>
                }
            </div>
            {
                resetPopUp && <WarningPopup
                    messageTitle={'Reset call link'}
                    description={'Are you sure you want to reset call link?'}
                    closePopUp={handleResetPopup}
                    type={"call_url_reset"}
                    setshortID={setshortID} />
            }
        </>
    );
};

export default UserProfile;
