import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BsStopwatch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';

const TaskDueTime = () => {
    const { selectedTask, OnUpdateTask, isObserver } = useTaskContext()
    const [showEnter, setShowEnter] = useState(false);
    const [due_time, setDue_time] = useState(selectedTask?.due_time ? new Date(selectedTask?.due_time) : null);

    useEffect(() => {
        setDue_time(selectedTask?.due_time ? new Date(selectedTask?.due_time) : null);
    }, [selectedTask])

    const handleTimeChange = (newValue) => {
        setDue_time(newValue); // Ensure this happens before calling the callback
        OnUpdateTask({ due_time: newValue });
    };

    return (
        <>
            <div className="dueTime">
                <p className='date_label' style={{ marginTop: '0px' }}>Due time</p>
                <div
                    id="dueTime"
                    onMouseEnter={() => setShowEnter(true)}
                    onMouseLeave={() => setShowEnter(false)}
                    className={`date_area_div ${isObserver() && 'pointer-events-none'}`}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            className='start_Date_task due_time'
                            selected={due_time}
                            onChange={(date) => handleTimeChange(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            placeholderText="Due time"
                        />
                    </LocalizationProvider>
                    {showEnter ?
                        <> {
                            due_time === null ?
                                <span style={{ top: '34px', right: '10px' }} className='absolute'>
                                    <BsStopwatch color={'#318fff'} size={18} />
                                </span>
                                :
                                <span style={{ right: '14px', top: '35px', display: 'block' }} onClick={() => handleTimeChange(null)} className='removeFile' ></span>
                        }
                        </> :
                        <span style={{ top: '34px', right: '10px' }} className='absolute'>
                            <BsStopwatch color={'#318fff'} size={18} />
                        </span>
                    }
                </div>
            </div>
            <Tooltip delayShow={1000} anchorSelect="#dueTime" place="top" content="Due date can note be empty"></Tooltip>
        </>
    );
};

export default TaskDueTime;
