import React, { useState } from 'react';
// import Moment from 'react-moment';
// import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from "classnames";
import 'moment-timezone';
// import {useHistory} from 'react-router-dom';

// import { ProgressBar } from 'react-bootstrap';
import SupportAndBillingProgressBar from './SupportAndBillingProgressBar';



function SubscriptionBillingTab(props) {
    //   const history = useHistory();
    const onClickHandler = () => {
        props.setActiveTab(2)
    }
    const onClickEditBilling = () => {
        props.setActiveTab(3)
    }
    const onClickEditPayment = () => {
        props.setActiveTab(4)

    }
    const onClicViewHistorykHandler = () => {
        props.setActiveTab(7);
    }

    const countpercentage = (parseInt(props.billingData.storage) / parseInt(props.billingData.plan_storage_limit)) * 100
    const percentage = Math.min(countpercentage, 100)
    //    //console.log("700000",props.billingData.plan_name);
    const [sweetAlert, setSweetAlert] = useState(false);
    const [sweetAlertType, setSweetAlertType] = useState('');

    const warningConfirm = () => {

        // console.log("check");
    }
    const ButtonText = () => {
        return (
            <span onClick={onClickHandler} className='buttonTextUpgrade'>
                Upgrade
            </span>

        );

    }
    const ButtonUpgradeIcon = () => {
        return (
            <span onClick={onClickHandler} className='ButtonUpgradeIcon'></span>
        );

    }

    return (
        <div className='bd-[#fafafa] border-t border-t-[#d8d8d8] h-screen'>
            
            <div className="SubscriptionBillingTab_container" >
              
                    {/* {props.loader ? <div className='btn_loader for_loader'></div>: */}
                    <div className={classNames("supportAndBillingContainer"
                        // ,props.loader ? ' btn_loader for_loader':''
                    )}

                    >
                        <div className="supportBillingDivs">
                            <div className="supportAndBillingPlan">
                                <div className="supportAndBillingHead">
                                    <p>Your Plan</p>
                                    <h3>{props.billingData.plan_name ? props.billingData.plan_name.toUpperCase() : 'STARTER'}</h3>
                                    <div className='currency'>
                                        ${'250' || '0'} USD
                                    </div>
                                </div>
                                <div className='supportAndBillingUsers'>
                                    <span className='sbUsersIcon'>
                                        {/* <img src="/media/images/icons/other/LightBlue/PNG/WorkFreeli_Icons_LightBlue_3usersormore.png" alt="" /> */}
                                        {/* </span><span className='sbUserNumber'>{props.billingData.users} Users</span><span className='sbUsersTotal'>{props.billingData.plan_name.toUpperCase() === 'STARTER' ? '/ 10 Users':''}</span>  */}
                                    </span>
                                    <span className='sbUserNumber'>{props.billingData.plan_user_limit} licenses <span style={{ color: '#0c1e47', fontSize: '18px', fontWeight: 'normal' }}>(Assigned:{props.billingData.active_users}, Unassigned:{(Number(props.billingData.plan_user_limit) - Number(props.billingData.active_users))}) </span></span>
                                    <span className='upgradePlanButton'>
                                        {
                                            props.billingData.plan_name === 'starter' ? <ButtonText /> : <ButtonUpgradeIcon />
                                        }
                                    </span>
                                </div>

                                <div className='supportAndBillingStorage'>
                                    <div className='storageLeftarea'>
                                        <p className='sbStorage'>Storage</p>
                                        {/* <span className='sbUserNumberSt'>{props.billingData.storage} GB</span><span className='sbUsersTotal'>{props.billingData.plan_name.toUpperCase() === 'STARTER' ? '/ 5GB':''}</span>  */}
                                        <span className='sbUserNumberSt'>{props.billingData.storage} GB /</span><span className='sbUserNumberSt'> {((Number(props.billingData.plan_user_limit) * 2) + (5 + props.totalAditionl))} GB</span>

                                    </div>
                                    <SupportAndBillingProgressBar bgcolor="#0c1e47;" progress={percentage} height={16} />
                                    <div className='upgrade_area'>
                                        {
                                            props.billingData.plan_name === 'starter' ? <ButtonText /> : <ButtonUpgradeIcon />

                                        }
                                    </div>
                             
                                </div>
                           
                                <div className="userTableHead ">
                                    <ul className="" >
                                        <li className="_userEmail" style={{ width: '70%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>Storage with licenses (2 GB x {props.billingData.plan_user_limit} licenses) + 5 GB(FREE)</li>
                                        <li className="_userRole" style={{ width: '30%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>{((Number(props.billingData.plan_user_limit) * 2) + 5)} GB</li>
                                    </ul>
                                    {props.totalAditionl > 0 ?
                                        <ul className="" >
                                            <li className="_userEmail" style={{ width: '70%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>Additional storage (Added by You)</li>
                                            <li className="_userRole" style={{ width: '30%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>{props.totalAditionl} GB <span onClick={onClicViewHistorykHandler} style={{ marginLeft: '4px', fontSize: '12px' }}> (View History)</span></li>
                                        </ul>
                                        : ''}
                                    <ul className="" style={{ borderBottom: '0px' }} >
                                        <li className="_userEmail" style={{ width: '70%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>Total</li>
                                        <li className="_userRole" style={{ width: '30%', float: 'left', textAlign: 'left', padding: '8px 8px' }}>{((Number(props.billingData.plan_user_limit) * 2) + (5 + props.totalAditionl))} GB</li>
                                    </ul>
                               
                                </div>

                                <div onClick={onClickHandler} className="supportBillingBottom">
                                    <div className='upgradeText'>Upgrade your plan {props.billingData.plan_name.toUpperCase() === 'STARTER' ? 'To Essentials' : ''}</div>   <div className='upgradeIcon'></div>
                                </div>
                            </div>
                            <div className="supportAndBillingSubsctiption">
                                <div className='subsctiptionPayment'>
                                    Subscription and payment settings
                                </div>
                                <div className='recurringbillingArea'>
                                    <div className='recurringbilling'>
                                        <p className='recurringbillingText'>Recurring billing</p>
                                        {/*<div className='recurringbillingNot'>
                                            {props.billingData.address.length !== 0 ?
                                                    <div className='reviewAreaBilling' style={{padding:'8px',height:'auto'}} onClick={onClickEditBilling}>
                                                        {props.billingData.address.first_name + ' ' + props.billingData.address.last_name}
                                                        <br />
                                                        {props.billingData.address.street_address}
                                                        <br />
                                                        {props.billingData.address.province}
                                                        <br />
                                                        {props.billingData.address.zip}
                                                    
                                                    </div>
                                            : 'N/A'}
                                            </div> */}
                                    </div>
                                    <div className='editRecurringbilling' onClick={onClickEditBilling}>
                                        <p className='editrecurringbillingText' >Edit recurring billing</p>
                                    </div>

                                </div>
                                <div className="billing_address_area">

                                    {props.billingData.address.length !== 0 ?
                                        <div className='reviewAreaBilling' style={{ padding: '8px', height: 'auto' }} onClick={onClickEditBilling}>
                                            {props.billingData.address.first_name + ' ' + props.billingData.address.last_name}
                                            <br />
                                            {props.billingData.address.street_address}
                                            <br />
                                            {props.billingData.address.province}
                                            <br />
                                            {props.billingData.address.zip}

                                        </div>
                                        : 'N/A'}
                                </div>
                                <div className='recurringbillingArea'>
                                    <div className='recurringbilling'>
                                        <p className='recurringbillingText'>Payment method</p>

                                    </div>
                                    <div className='editRecurringbilling' onClick={onClickEditPayment}>
                                        <p className='editrecurringbillingText'>Edit payment method</p>
                                    </div>

                                </div>
                                <div className="billing_address_area">
                                    {
                                        Object.keys(props.billingData.payment.cardObj).length ?
                                            <div className='reviewAreaBilling' style={{ padding: '8px', height: 'auto' }} onClick={onClickEditBilling}>
                                                {props.billingData.payment.cardObj.fullname}
                                                <br />
                                                {props.billingData.payment.cardObj.brand} ending in: ***{props.billingData.payment.cardObj.last4}
                                                <br />
                                                Exp: {props.billingData.payment.cardObj.exp_month} / {props.billingData.payment.cardObj.exp_year}


                                            </div>
                                            : 'N/A'
                                    }
                                </div>
                                <div className='recurringbillingArea freePlan'>

                                    <div className='recurringbilling'>
                                        <p className='recurringbillingText'>Subscription status</p>
                                        <p style={{ marginTop: '20px' }} className='recurringbillingText'>subscriptions@workfreeli.com for cancellation request</p>

                                    </div>
                                    <div className='editRecurringbilling'>
                                        <p onClick={() => { setSweetAlertType('cancel'); setSweetAlert(true) }} className='editrecurringbillingText'>Cancel subscription </p>
                                        {/* <p className='editrecurringbillingText' style={{borderBottom:'0px',position:'relative'}}>Please contact with workfreeli admin for cancel subscription. </p>  */}
                                    </div>

                                </div>
                                {/* <div className='freePlanActiveArea'>
                                   <span className='freePlanActive'>{props.billingData.plan_name.toUpperCase()} Plan(active)</span><span className='closeAccount' onClick={()=> {setSweetAlertType('close'); setSweetAlert(true)}}>Close Account</span>
                               </div> */}
                                <div className='subsctiptionPaymentBotton'>
                                    <span className='subscriptionHistory'>Billing History</span> <span onClick={onClicViewHistorykHandler} className='viewHistory'>View history</span>
                                </div>

                            </div>



                        </div>

                    </div>
                    {/* } */}
                

            </div>
           

        </div>
    )
}



export default SubscriptionBillingTab;