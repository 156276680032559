/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import CreateTeam from './CreateTeam';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_TEAMS, GET_ALL_USERS, GET_ME } from '../../../Utils/GraphqlQueries';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import TeamDetails from './TeamDetails';
import { Toaster, toast } from 'react-hot-toast';
import AdminWarningPopup from '../AdminWarningPopup';
import TeamWarnPopup from './TeamWarnPopup';
import { DELETE_TEAM, UPDATE_SYSTEM_ROOM } from '../../../Utils/GraphqlMutaion';
import { UserSelection } from '../../GlobalPopups/UserSelection';
import Scrollbars from 'react-custom-scrollbars';

function TeamManageHome() {
    const [loader, setLoader] = useState(true);
    const [srcString, setSrcString] = useState('');
    const [miniList, setMiniList] = useState([]);
    const [style, setStyle] = useState({ display: 'none' });
    const [hoveredElements, setHoveredElements] = useState({});
    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    const [usersOptions, setUsersOptions] = useState(false);
    const [create_popup, setCreate_popup] = useState('');
    const [action_data, setAction_data] = useState(null);
    const [selectedUsersOptions, setSelectedUsersOptions] = useState(false);
    const [teamDetails, setTeamDetails] = useState(false);
    const [teamDelete, setTeamDelete] = useState(false);

    const handleSelectedOptions = (e) => {
        setSelectedUsersOptions(e);
        setUsersOptions(false);

    }

    const handleTeamDetails = (v) => {
        setTeamDetails(true);
        setAction_data(v)
    };

    const ref = useClickAway(() => {
        setActiveOption('')
    });

    const [allTeams, setallTeams] = useState([]);
    const [user_data, setUser_data] = useState(null);
    const [all_user_data, setall_user_data] = useState([]);
    const { loading: u_loader, data: user } = useQuery(GET_ME);
    useEffect(() => {
        setUser_data(user.me)
    }, [user])
    const [fetchUsers, { loading: UsersLoading, data: AllUsers }] = useLazyQuery(GET_ALL_USERS);

    useEffect(() => {
        if (AllUsers?.users) {
            setall_user_data(AllUsers.users)
        }
    }, [AllUsers])

    useEffect(() => {
        async function init() {
            setLoader(true)
            if (user_data) {
                let apires = await fetchUsers({ variables: { company_id: user_data.company_id } });
                // console.log("🚀 ~ useEffect ~ apires:", apires)
                setLoader(false)
            }
        }
        init()
    }, [user_data])

    const { loading: team_loader, data: team_data } = useQuery(GET_ALL_TEAMS);

    useEffect(() => {
        // console.log('allTeams:', team_data);
        if (team_data?.teams) {
            setallTeams(team_data?.teams)
        }
    }, [team_data])

    const [activeOption, setActiveOption] = useState('');

    const handleSettingsOptions = (v) => {
        if (v.admin.indexOf(user_data.id) > -1) {
            setActiveOption(prev => prev === v.team_id ? '' : v.team_id);
        } else {
            toast.error('You are not an admin of this team', { duration: 4000 });
        }
    }
    const [enableConversation, setEnableConversation] = useState(false);
    const [restrictUser, setRestrictUser] = useState(false);

    const [delete_team] = useMutation(DELETE_TEAM)

    const delete_confirm = async () => {
        let apires = await delete_team({
            variables: {
                input: {
                    team_id: action_data?.team_id
                }
            }
        })

        if (apires.data.delete_team.status) {
            toast.success(apires.data.delete_team.message, { duration: 4000 });
        } else {
            toast.error(apires.data.delete_team.message, { duration: 4000 });
        }
        setTeamDelete(false)
    }

    const [update_system_room] = useMutation(UPDATE_SYSTEM_ROOM)

    const conv_enable_confirm = async () => {
        let is_active = action_data?.team_system_conversation_active?.toLowerCase() === 'no' ? 'yes' : 'no'
        let apires = await update_system_room({
            variables: {
                input: {
                    team_id: action_data?.team_id,
                    title: action_data?.team_title,
                    participants: action_data?.participants,
                    team_system_conversation_off_sms: [],
                    is_active: is_active,
                }
            }
        })

        if (apires.data.update_system_room.status) {
            setallTeams(prev => prev.map(m => m.team_id === action_data?.team_id ? { ...m, team_system_conversation_active: is_active } : m))
            toast.success(apires.data.update_system_room.message, { duration: 4000 });
        } else {
            toast.error(apires.data.update_system_room.message, { duration: 4000 });
        }
        setEnableConversation(false)
    }

    const manage_restricted = async (ids) => {
        // console.log("🚀 ~ constmanage_restricted=async ~ ids:", ids)
        let apires = await update_system_room({
            variables: {
                input: {
                    team_id: action_data?.team_id,
                    title: action_data?.team_title,
                    participants: action_data?.participants,
                    team_system_conversation_off_sms: ids,
                    is_active: action_data?.team_system_conversation_active?.toLowerCase(),
                }
            }
        })
        // console.log("🚀 ~ TeamManageHome ~ apires:", apires)
        if (apires.data.update_system_room.status) {
            setallTeams(prev => prev.map(m => m.team_id === action_data?.team_id ? { ...m, team_system_conversation_off_sms: ids } : m))
            toast.success(apires.data.update_system_room.message, { duration: 4000 });
        } else {
            toast.error(apires.data.update_system_room.message, { duration: 4000 });
        }
        setRestrictUser(false)
    }


    // table in filter start
    const [sortRedActive, setSortRedActive] = useState(false);
    const [sortIconName, setSortIconName] = useState(false);
    const [sortRedbyDefault, setSortRedByDefault] = useState(true);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSortList = (type) => {
        if (type === 'byteamtitle') {
            const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            setSortRedActive(type);
            setSortIconName((prev) => !prev);
            setSortRedByDefault(false);

            const sortedallTeams = [...allTeams].sort((a, b) => {
                const nameA = a.team_title.toLowerCase();
                const nameB = b.team_title.toLowerCase();
                return newSortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            });
            setallTeams(sortedallTeams);
        }
    }

    return (
        <>
            <Toaster />
            <div className='right_container'>
                <div className='adminRightHead'>
                    <span className="user_Management_title text-5xl">Team management</span>
                    <span style={{ marginLeft: '5px', cursor: 'pointer' }} onMouseEnter={e => { setStyle({ display: 'block' }); }} onMouseLeave={e => { setStyle({ display: 'none' }); }} className="tooltip1 user_Management_title"><span className="info_tooltip_new"></span>
                        <span style={style} className="tooltiptext1" >
                            <p>
                                Teams are a great way for a group of users to collaborate. You can select one or more users in any team. A user can be part of multiple teams. Only users in a team can participate in a room chat, share files etc. You only need more than one team if you would like to group of users to remain completely private. For example, your Toronto Office and Vancouver Office are completely two separate operations where your staff in each office do not interact. However, as admin, you would like to control Workfreeli settings for both offices. Or for example, the majority of the staff for both offices do not interact, but some users do such as senior managers or shared common personnel. In this scenario, you can add those common personnel to both teams so that they have full access to all you staff in both offices and can participate in any team/work rooms.
                            </p>
                            <p style={{ marginTop: '10px' }}>
                                As another example, you may want to create a specific team with users from numerous departments and offices to handle a specific project or activity or responsibility. If that project or activity is long-term, then you may consider setting up a team with those users in order to elevate their privacy and to prevent access by other staff to this team. You may not want users of this particular team to share any information on their activity with any other staff members who are not authorized. This would be a great way to do that.
                            </p>
                            <p style={{ marginTop: '10px' }}>
                                If no users are active yet while creating team: User selection page will show yourself only and if there are any pending users. Pending users cannot be selected.

                            </p>
                            <span onClick={e => { setStyle({ display: 'none' }); }} className='tooltipClose'></span>
                        </span>
                    </span>
                    {
                        selectedUsersOptions &&
                        <div className="miniItem" style={{ marginLeft: "15px" }}>
                            <div className="miniItem_image" style={{ margin: "0px" }}>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="miniItem_name" style={{ marginLeft: "3px" }}>Filter Active Users only</div>
                            <div onClick={() => handleSelectedOptions(false)} className="miniItem_remove"></div>
                        </div>
                    }
                </div>

                <div className='usersTabSection'>
                    
                        
                        <div className="usersTabSecDevide lefttSide" style={{ gap: '10px' }}>
                            <div className={`usersTabList teamManagementTitle`} >
                                <div style={{ cursor: 'pointer' }}>
                                    <p>Teams ({allTeams.length})</p>
                                </div>
                            </div>
                        </div>
                    
                    <div className='usersTabSecDevide rightSide'>
                        <span className='relative'>
                            <input className="userSearchInput" value={srcString} autoFocus={true} onChange={(e) => setSrcString(e.target.value)} type="text" placeholder="Search team" />
                            <span className="leftSearchCloseBtn remove !right-5" onClick={() => setSrcString('')} ></span>
                        </span>
                        <button onClick={() => setCreate_popup('create')} style={{ marginRight: '0px' }} onMouseEnter={() => handleMouseHover('element2')} onMouseLeave={() => handleMouseLeave('element2')} className="userCreateBtn tooltip5">Create team</button>
                        {
                            hoveredElements['element2'] &&
                            <span className="user_Management_title" >
                                <span className="tooltiptext6 newUserTool" >
                                    <p> Create a team </p>
                                    <span className="tooltipClose"></span>
                                </span>
                            </span>
                        }

                    </div>
                </div>

                <div className='usersTableContainer'>
                    <div className="userTableHead">
                        <ul className="">
                            {/* <li className="_userName desc sort_active" style={{ width: "25%" }}> <span className="sortIcons"></span>Team Name aa</li> */}
                            <li onClick={() => handleSortList("byteamtitle")} className={`_userName sort_active !p-0 ${sortRedActive === "byteamtitle" ? 'sort_active' : ''} ${!sortIconName ? 'asce' : 'desc'}`} style={{ width: "20%" }}>
                                <span className="sortIcons"></span>Team name
                            </li>
                            <li className="_userEmail !p-0 flex !justify-center desc " style={{ width: "12%" }}>Team size</li>
                            <li className="_userRole !p-0" style={{ width: "20%" }} >Team created by</li>
                            <li className="_userStatus !p-0 desc" style={{ width: "20%" }}>Team updated by</li>
                            <li className="_userStatus !p-0" style={{ width: "12%" }}>Your role</li>
                            <li className="_userStatus !p-0" style={{ width: "12%" }}>Conversation</li>
                            <li className="_userAction !p-0" style={{ width: "5%" }}>Action</li>
                        </ul>
                    </div>
                    <div className='userTableBody'>
                        <Scrollbars autoHide className="scrollBarClass">
                            {loader ? '' : (
                                allTeams.filter(f => f.team_title.toLowerCase().includes(srcString.toLowerCase())).length > 0 ? (
                                    allTeams.filter(f => f.team_title.toLowerCase().includes(srcString.toLowerCase())).map((v) => {
                                        return (
                                            <div key={v.team_id} style={{ position: 'relative' }}>
                                                <ul className="_userList">
                                                    <li className="_userName" style={{ width: "20%" }} onClick={() => handleTeamDetails(v)}>
                                                        <span className="_userNameTxt">{v.team_title}</span>
                                                    </li>
                                                    <li className="_userEmail flex justify-center !pl-0" style={{ width: "12%" }}>
                                                        {v.participants.length}
                                                    </li>
                                                    <li className="_userRole" style={{ width: "20%" }}>
                                                        <span className=""> {getONLYUSERSDATA(all_user_data, v.created_by, 'name')} </span>
                                                    </li>
                                                    <li className="_userStatus" style={{ width: "20%" }}>
                                                        <time className="userDateText">{getONLYUSERSDATA(all_user_data, v.updated_by, 'name')}</time>
                                                    </li>
                                                    <li className="_userStatus" style={{ width: "12%" }}>
                                                        <span className="_userNameTxt">{v.admin.indexOf(user_data.id) > -1 ? 'Admin' : 'Member'}</span>
                                                    </li>
                                                    <li className="_userStatus" style={{ width: "12%" }}>
                                                        <span className="_userNameTxt">{v.team_system_conversation_active?.toLowerCase() === 'yes' ? 'Enabled' : 'Disabled'}</span>
                                                    </li>
                                                    <li onClick={() => handleSettingsOptions(v)} className="_userAction flex " style={{ width: "5%", position: "relative", pointerEvents: activeOption === v.team_id ? "none" : "" }}>
                                                        <span className="userActionIcon"></span>
                                                    </li>
                                                </ul>
                                                {activeOption === v.team_id && (
                                                    <ul className="userActionPopup" style={{ position: "absolute", top: '78px' }} ref={ref}>
                                                        <li className='!text-start' onClick={() => { setAction_data(v); setCreate_popup('edit'); handleSettingsOptions('') }}>Edit team</li>
                                                        <li className='!text-start' onClick={() => { setAction_data(v); setTeamDelete(true); handleSettingsOptions('') }}>Delete team</li>
                                                        <li className='!text-start' onClick={() => { setAction_data(v); setEnableConversation(true); handleSettingsOptions('') }}>Conversation enable</li>
                                                        <li className='!text-start' onClick={() => { setAction_data(v); setRestrictUser(true); handleSettingsOptions('') }}>Manage restricted</li>
                                                    </ul>
                                                )}
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="noDataFound flex justify-center items-center mt-48 m-auto">No data found</div>
                                )
                            )}
                        </Scrollbars>
                    </div >
                </div >
            </div >

            {create_popup !== '' && <CreateTeam type={create_popup} setCreate_popup={setCreate_popup} allTeams={allTeams} setallTeams={setallTeams} data={action_data} user={user_data} />}
            {teamDetails && <TeamDetails data={action_data} setTeamDetails={setTeamDetails} setallTeams={setallTeams} all_users={all_user_data} user={user_data} />}
            {
                teamDelete && <TeamWarnPopup
                    title={'Delete team ?'}
                    teamDelete={teamDelete}
                    desc={'Are you sure you want to delete this team ?'}
                    onClose={() => setTeamDelete(false)} onConfirm={delete_confirm} />}
            {
                enableConversation && <TeamWarnPopup
                    title={'Enable conversation ?'}
                    desc={'Are you sure you want to enable this conversation ?'}
                    onClose={() => setEnableConversation(false)} onConfirm={conv_enable_confirm} />
            }
            {restrictUser && <UserSelection team={action_data} type="manage_restricted" miniList={miniList} setMiniList={setMiniList} setRestrictUser={setRestrictUser} team_users={action_data?.participants} manage_restricted={manage_restricted} />}
        </>
    );
}

export default TeamManageHome;
