import React from 'react'
import { BsArrowRightCircle, BsFillBellFill } from 'react-icons/bs'

const TaskCard = () => {
    return (
        <div className='taskCardMain'>
            <div className='taskCard_left'>
                <div>
                    <input type="checkbox" />
                </div>
                <div className='taskCard_text'>
                    <p>Create Information Deck for Oxford</p>
                    <p>Oxford Properties | Assigned to Kaye Anne</p>
                </div>
            </div>
            <div className='taskCard_right'>
                <div>
                    <BsFillBellFill size={20} />
                </div>
                <div>
                    <BsArrowRightCircle size={20} style={{
                        color: "#318fff"
                    }} />
                </div>
            </div>
        </div>
    )
}

export default TaskCard