/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useCallback, useRef } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useClickAway } from "@uidotdev/usehooks";
import classNames from "classnames";
import { BsXLg, BsArrowRight } from "react-icons/bs";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import { BiReset } from "react-icons/bi";
import { VscCalendar } from "react-icons/vsc";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/client'
import { HUB_ALL_LINKS, GET_ALL_USERS, GET_ME } from '../../Utils/GraphqlQueries';
import { useParams } from "react-router-dom";
import DeleteLink from "./DeleteLink";
import { useDispatch, useSelector } from "react-redux";
import { setPopup, set_global_action_data } from "../../redux/message";
import { Tooltip } from "react-tooltip";



function Links(props) {
  const params = useParams()
  const dispatch = useDispatch()
  const client = useApolloClient();
  const cacheMe = client.readQuery({ query: GET_ME });

  const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
  const [api_data, setApi_data] = useState([]); 
  // console.log('Links:api:', api_data)
  const change_link_data = useSelector(state => state.message.change_link_data)
  const [resetCounter, setResetCounter] = useState(0);
  const [roomListPopup, setRoomListPopup] = useState(false);
  const [uploaderListPopup, setuploaderListPopup] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(params.conversation_id ? [params.conversation_id] : []);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const conversations = useSelector(state => state.message.conversations)
  const active_conversation = useSelector(state => state.message.active_conversation)
  const [roomList, setRoomList] = useState(conversations)
  const [Uploader_list, setUploader_list] = useState([]);
  const [searchByRoomVal, setSearchByRoomVal] = useState('');
  const [searchByUploaderVal, setsearchByUploaderVal] = useState('');
  // const [pageState, setpageState] = useState(null);
  const [pageState, setpageState] = useState({ page: 0, totalPages: 0, total: 0 });
  const [initialLoader, setInitialLoader] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [input_val, setInputVal] = useState('');
  const [search_val, setSearch_val] = useState('Basic search');
  const [activesortCol, setActivesortCol] = useState('uploaded_by');
  const [activeOrdering, setActiveOrdering] = useState('asce');
  const [, setOpen] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const containerRef = useRef();
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    setRoomList(conversations)
  }, [conversations])

  useEffect(() => {
    if (change_link_data) {
      // console.log('change_link_data:', change_link_data);
      // console.log("🚀 ~ useEffect ~ api_data:", api_data)
      setApi_data(api_data.map(f => f.msg_id === change_link_data.msg_id ? { ...f, title: change_link_data.url_title } : f))
    }
  }, [change_link_data])

  useEffect(() => {
    if (active_conversation) {
      let user_data = cacheAllUsers?.users?.filter(f => active_conversation?.participants?.indexOf(f.id) > -1);
      setUploader_list(user_data)
    } else {
      setUploader_list(cacheAllUsers.users)
    }
  }, [active_conversation])

  const years = range(2000, getYear(new Date()) + 10, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [countSort, setCountSort] = useState(0)
  const sorting = (e, type, class_name) => {
    debugger
    let actionType = class_name === 'desc' ? true : false
    setActivesortCol(type)


    if (actionType) { //Ascending Sort
      // console.log('Ascending Sort')
      setActiveOrdering('asce')
      // console.log(1, e.target.classList)

      if (type === 'file_size') {
        setApi_data([...api_data.sort((a, b) => parseFloat(a[type]) - parseFloat(b[type]))])
      } else {
        setApi_data([...api_data.sort((a, b) => a[type]?.toUpperCase() > b[type]?.toUpperCase() ? 1 : -1)])
      }
    } else { //Descending Sort
      // console.log('Descending')
      setActiveOrdering('desc')

      if (type === 'file_size') {
        setApi_data([...api_data.sort((a, b) => parseFloat(b[type]) - parseFloat(a[type]))])
      } else {
        setApi_data([...api_data.sort((a, b) => a[type]?.toUpperCase() < b[type]?.toUpperCase() ? 1 : -1)])
      }
    }
    setCountSort((countSort + 1))
  }
  const resetFun = () => {
    setpageState({ page: 0, totalPages: 0, total: 0 })
    setRoomListPopup(false);
    setError1(false);
    setError2(false);
    setSearchByRoomVal('');
    setsearchByUploaderVal('');
    setInputVal('');
    setSelectedRooms(props.conversation_gallery ? [props.logindata.active_conv.details.conversation_id] : []);
    setSelectedCreators([]);
    setStartDate('');
    setEndDate('');
    setResetCounter((resetCounter + 1))

  }
  // useEffect(() => {
  //   if (search_val !== 'Advanced search') {

  //     resetFun();
  //   }
  // }, [search_val, resetCounter])

  const searchByRoom = (v) => {
    setSearchByRoomVal(v);
    if (v !== '') {
      setRoomListPopup(true)
    } else {
      setRoomListPopup(false)
    }
  }

  useEffect(() => {
    if (searchByRoomVal === '') {
      setRoomList(roomList.map(v => searchByRoomVal === '' ? { ...v, l_show: true } : v))
    } else {
      setRoomList(roomList.map(v => v.title.toLowerCase().indexOf(searchByRoomVal.toLowerCase()) > -1 ? { ...v, l_show: true } : { ...v, l_show: false }))
    }
  }, [searchByRoomVal]);

  const searchByUploader = (v) => {
    setsearchByUploaderVal(v);
    if (v !== '') {
      setuploaderListPopup(true)
    } else {
      setuploaderListPopup(false)
    }
  }

  useEffect(() => {
    if (Uploader_list.length === 0) return
    if (searchByUploaderVal === '') {
      setUploader_list(Uploader_list.map(v => searchByUploaderVal === '' ? { ...v, l_show: true } : v))
    } else {
      setUploader_list(Uploader_list.map(v => ((v.firstname + ' ' + v.lastname).toLowerCase().indexOf(searchByUploaderVal.toLowerCase()) > -1) ? { ...v, l_show: true } : { ...v, l_show: false }))
    }
  }, [searchByUploaderVal]);

  const selectRooms = (v) => {
    setSearchByRoomVal('');
    setRoomListPopup(false);
    setSelectedRooms([...selectedRooms, v.conversation_id]);
  }
  const selectCreate = (v) => {
    // console.log(175, v)
    setsearchByUploaderVal('');
    setuploaderListPopup(false);
    setSelectedCreators([...selectedCreators, v.id]);
  }

  const keyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setRoomListPopup(false);
      setuploaderListPopup(false);
      setOpen(false)
      props.setPopup({ type: 'customTitle', data: false });
      setShowDeletePopup(false);
    }

  }, [])

  const BlurEffect = (type) => {
    setTimeout(() => {
      if (type === 'searchByRoom') {
        setRoomListPopup(false)
      }

      if (type === 'searchByUploaderVal') {
        setuploaderListPopup(false)
      }

    }, 500)

  }

  // const copyToClipBoard = (body) => {
  //   let Url = body.replace(/(&nbsp;|<([^>]+)>)/ig, '')

  //   navigator.clipboard.writeText(Url);
  //   toast.success('Link Copied', { duration: 4000 });
  // }

  const copyToClipBoard = (body) => {
    // Remove HTML tags and replace `&nbsp;` with a space
    let Url = body.replace(/(&nbsp;|<([^>]+)>)/ig, '');

    // Check if Clipboard API is available and site is served over HTTPS or localhost
    if (navigator.clipboard && navigator.clipboard.writeText && (window.location.protocol === 'https:' || window.location.hostname === 'localhost')) {
      navigator.clipboard.writeText(Url)
        .then(() => {
          toast.success('Link Copied', { duration: 4000 });
        })
        .catch(err => {
          console.error('Failed to copy using Clipboard API: ', err);
          toast.error('Failed to copy text');
        });
    } else {
      // Fallback method using a hidden <textarea>
      try {
        const tempInput = document.createElement('textarea');
        tempInput.value = Url;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy'); // Legacy API
        document.body.removeChild(tempInput);
        toast.success('Link Copied', { duration: 4000 });
      } catch (err) {
        console.error('Failed to copy using fallback method: ', err);
        toast.error('Failed to copy text');
      }
    }
  };

  const [action_link, setActionLink] = useState(null)

  const deleteLink = (link) => {
    setActionLink(link);
    setShowDeletePopup(true);
  }

  const add_title = (link) => {
    dispatch(set_global_action_data({ msg: link, type: 'single_link', api_data: api_data, setApi_data: setApi_data }));
    dispatch(setPopup({ data: true, key: 'custom_title_popup' }));
  }

  const FormatUrl = (url) => {
    // console.log(3333,url)
    let Url = url.replace(/(&nbsp;|<([^>]+)>)/ig, '')
    return Url;
  }

  const ref = useClickAway(() => {
    setRoomListPopup(false)
  });
  const ref2 = useClickAway(() => {
    setuploaderListPopup(false)
  });
  // sort_by: 'title', sort_style: '-1'
  const { loading: loading_res, data: link_res } = useQuery(HUB_ALL_LINKS, { 
    variables: { 
    conversation_ids: params.conversation_id ? [params.conversation_id] : [],
    sort_by: 'url',
    sort_style: '1'
  }, fetchPolicy: 'no-cache' });

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [prevloader, setPrevloader] = useState(false)
  const [get_links_api] = useLazyQuery(HUB_ALL_LINKS, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (link_res) {
      setApi_data(link_res.hub_all_link_msgs.links);
      setPagination(link_res.hub_all_link_msgs.pagination);
    }
    // console.log('link_res:', link_res)

  }, [link_res])

  const filter_links = async (type, _pg) => {
    if(prevloader) return
    // console.log('filter__links',type, _pg);
    setPrevloader(true);
    if(_pg === 1){
      setApi_data([]);
    }
    let conv_id = params.conversation_id ? [params.conversation_id] : [];
    let json = {
      conversation_ids: type === 'reset' ? conv_id : selectedRooms,
      page: type === 'reset' ? 1 : _pg,
      from: type === 'reset' ? '' : startDate !== '' ? moment(startDate).format('YYYY-MM-DD') : startDate,
      to: type === 'reset' ? '' : endDate !== '' ? moment(endDate).format('YYYY-MM-DD') : endDate,
      url: type === 'reset' ? '' : input_val,
      user_ids: type === 'reset' ? [] : selectedCreators,
      sort_by: 'url',
      sort_style: '1'
    }
    let res = await get_links_api({
      variables: json
    });

    if (res?.data) {
      if (type === 'scroll') {
        setApi_data(prev => [...prev, ...res?.data.hub_all_link_msgs.links]);
      } else {
        setApi_data(res?.data.hub_all_link_msgs.links);
        setPage(1)
      }
      setPagination(res.data.hub_all_link_msgs.pagination);
    }
    setPrevloader(false)
    // console.log("🚀 ~ constfilter__links= ~ res:", res)
  }
  const prevScrollHeight = useRef(0);
  const onScrollLinks = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    // console.log('scrollTop:', scrollTop, 'clientHeight:', clientHeight, 'scrollHeight:', scrollHeight);
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (!prevloader) {
        if (pagination.totalPages > pagination.page) {
          let container = containerRef.current;
          prevScrollHeight.current = container.scrollHeight;

          filter_links('scroll', page + 1)
          setPage(prevPage => prevPage + 1); // Increment page for pagination
        }
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      filter_links('filter', 1)
    }
  }
  const isInitialMount = useRef(true);
  useEffect(() => {
    // debugger;
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!params.conversation_id) {
      if (startDate === "" && endDate === "" && input_val === "" && searchByRoomVal === "" && selectedRooms.length === 0 && selectedCreators.length < 1) {
        setButtonStatus(false);
        filter_links('reset', 1)
      } else {
        setButtonStatus(true);
      }
    } else {
      if (startDate === "" && endDate === "" && input_val === "" && searchByRoomVal === "" && selectedCreators.length < 1) {
        setButtonStatus(false);
        filter_links('reset', 1)
      } else {
        setButtonStatus(true);
      }
    }

  }, [startDate, endDate, input_val, searchByRoomVal, selectedRooms, selectedCreators]);

  const ButtonArea = () => {
    return (
      <div className='buttonList'>
        <div className={classNames("buttonsDesign reset_button", (selectedRooms.length !== 0 || selectedCreators.length !== 0 || searchByUploaderVal !== "" || startDate !== "" || endDate !== "" || input_val !== "" || searchByRoomVal !== "" ? "" : "dissableReset"))}>
          <span className="goIconArea" onClick={() => { setRoomListPopup(false); resetFun(); filter_links('reset', 1) }}>
            <span className="goText" style={{ position: 'relative', top: '0px' }}>Clear</span>
            <BiReset size={20} className='arrow_go_icon clear' />
          </span>
        </div>

        <div className={classNames("buttonsDesign go_button", buttonStatus ? "activeButton" : "inactiveButton")}
          // className='buttonsDesign go_button' 
          onClick={() => filter_links('filter', 1)}>
          <span className="goIconArea">
            <span className="goText" style={{ position: 'relative', top: '0px' }}>Search</span>
            <BsArrowRight size={20} className='arrow_go_icon' />
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <Toaster />
      <div className="tagFileArea advancedFilePanel">
        {search_val === 'Basic search' ?
          <div className="search_total_area">
            <div className="search_right_area" style={{ flexBasis: "100%" }}>
              <div className="Advance_search" >
                <div className="Advance_search_top newClass " style={{ paddingRight: "0px" }}>
                  <div style={{ flexBasis: "20%" }} onClick={() => { setSearch_val('Advanced search') }} className="Advance_search_text Advance_item">
                    Advanced search <span>

                      <MdKeyboardArrowDown size={16} />
                    </span>
                  </div>
                  <div style={{ flexBasis: "84%", borderBottom: 'unset', borderRight: 'unset', padding: '5px 0px 0 20px' }} className="Advance_search_list Advance_item" name="fontSize">
                    <div className="searchAndFilter" style={{ borderBottom: 'unset', position: 'relative', width: '98%' }}>
                      <input id="_inputBar_tagdetails"
                        className={`_inputSearchBar ${search_val && '!w-full'}`}
                        type="text"
                        placeholder="Search"
                        value={input_val}
                        onChange={(event) => {
                          // debugger
                          const val = event.target.value; // Using a variable for readability
                          setInputVal(val);
                          if (!val) {
                            resetFun();
                            filter_links('reset', 1);
                          }
                        }}
                        autoFocus={true}
                        onKeyUp={handleKeyUp}
                        style={{ marginRight: '2px' }} />
                      {input_val === '' ?
                        <div style={{ right: '107px', height: '31px' }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                        : <span style={{ right: '112px' }} className="clearAllSrcTest"
                          onClick={() => { resetFun(); filter_links('reset', 1) }} data-for="top_head" data-tip="Clear Search"></span>}

                      <div
                        // className="SearchButton"
                        className={classNames("SearchButton", input_val ? "activeButton" : "inactiveButton")}
                        onClick={() => filter_links('filter', 1)}
                      >Go</div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>
          :

          <>
            <div className="search_total_area">
              <div className="search_right_area">
                <div className="Advance_search" >
                  <div className="Advance_search_top newClass" style={{ paddingRight: '5px' }} >
                    <div style={{ flexBasis: "20%" }} onClick={() => { setSearch_val('Basic search') }} className="Advance_search_text Advance_item">
                      Advanced search <span>
                        <MdKeyboardArrowRight size={16} />
                      </span>
                    </div>

                    <div className="date_area Advance_item responsive_item_link" style={{ flexBasis: '88%', paddingRight: '0px' }}>
                      <span className='calenderIcon'><VscCalendar size={22} /></span>
                      <div className='fromDate_One'>
                        <DatePicker
                          className={error1 === true ? "start_Date errorDate" : "start_Date"}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="From   YYYY-MM-DD"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="CalendarDiv">
                              <button name="decreaseMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              <div > <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(value)}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                                <select
                                  value={months[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <button name="increaseMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </button>
                            </div>
                          )}
                          selected={startDate}
                          onChange={(date) => {

                            if (new Date(date) > new Date(endDate)) {
                              toast.error('Start date should be smaller than  or equal to end date', { duration: 4000 });
                              setStartDate('')
                              setError1(true)
                              setTimeout(() => {
                                setError1(false)
                              }, 4000)
                            } else {
                              setStartDate(date);
                              setError1(false)
                            }
                          }}
                        />
                        {
                          startDate !== '' ?
                            <span className='clearInput' onClick={() => { setStartDate('') }}></span>
                            : ''
                        }
                      </div>
                      {/* <span className='fromTo'>To </span> */}
                      <span className='calenderIcon2'><VscCalendar size={22} /></span>
                      <div className='fromDate_two'>

                        <DatePicker
                          className={error2 === true ? "start_Date errorDate" : "start_Date"}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="To   YYYY-MM-DD"

                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,

                          }) => (
                            <div className="CalendarDiv"
                            >
                              <button name="decreasedMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              <div>
                                <select className="yearDate"
                                  value={getYear(date)}
                                  onChange={({ target: { value } }) => changeYear(value)}
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={months[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <button name="increasedMonth" onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                          selected={endDate}
                          onChange={(date) => {
                            if (new Date(date) < new Date(startDate)) {
                              toast.error('End date should be greater than  or equal to Start date', { duration: 4000 });
                              setError2(true)
                              setEndDate('')
                              setTimeout(() => {
                                setError2(false)
                              }, 4000)
                            } else {
                              setEndDate(date);
                              setError2(false)
                            }
                          }}
                        />

                        {endDate !== '' ?
                          <span className='clearInput' onClick={() => { setEndDate('') }}></span>
                          : ''
                        }
                      </div>

                    </div>
                    {/* <div className="reset_icon Advance_item" style={{ flexBasis: '13%' }}>
                                    
                                </div> */}
                  </div>
                  <div className="Advance_search_bottom" >
                    <div className="searchAndFilterBar LeftSide" style={{ position: 'relative' }}>
                      <div className="searchAndFilter">
                        <div ref={ref}>
                          <input value={searchByRoomVal} onChange={(event) => searchByRoom(event.target.value)} onFocus={() => setRoomListPopup(true)}
                            // onBlur={() => BlurEffect('searchByRoom')} 
                            className="_inputBar searchLeft" type="text" placeholder="Search by room" />
                          {searchByRoomVal === '' ?
                            <div style={{
                              right: '31px',
                              height: '31px',
                              top: '17px'
                            }}
                              className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                            : <span style={{ right: '34px', top: '21px' }} className="clearAllSrcTest" onClick={(event) => searchByRoom('')} data-for="top_head" data-tip="Clear Search"></span>}

                          {roomListPopup &&
                            <div className="tag_room_list">
                              {
                                roomList.filter(e => (e.l_show === undefined || e.l_show) && selectedRooms.indexOf(e.conversation_id) === -1).filter(f => params.conversation_id ? false : true).map((v) =>
                                  <p className="_tag_rooms" onClick={() => selectRooms(v)} key={'conv_' + v.conversation_id}>{v.title}</p>
                                )
                              }
                              {roomList.filter(e => (e.l_show === undefined || e.l_show) && selectedRooms.indexOf(e.conversation_id) === -1).length === 0 && <div className="tagNotFound">Not found</div>}
                            </div>
                          }

                        </div>
                      </div>
                      <div className="selectedRoomCont">
                        {
                          roomList.filter(r => selectedRooms.indexOf(r.conversation_id) > -1).map(v =>
                            <p className="tags_Color !pl-2 !mr-1 !mb-1" key={'conv1' + v.conversation_id}>{v.title}
                              {!params.conversation_id &&
                                <BsXLg size={14} className="tags_Icon_close" onClick={() => setSelectedRooms(selectedRooms.filter(r => r !== v.conversation_id))} />
                              }
                            </p>
                          )
                        }


                      </div>

                    </div>
                    <div className="searchAndFilterBar rightSide" style={{ position: 'relative' }}>
                      <div className="searchAndFilter">
                        <div ref={ref2}>
                          <input value={searchByUploaderVal}
                            onChange={(event) => searchByUploader(event.target.value)}
                            className="_inputBar searchRight" type="text"
                            onFocus={() => setuploaderListPopup(true)}
                            // onBlur={() => BlurEffect('searchByUploaderVal')}
                            placeholder="Uploaded By" />
                          {searchByUploaderVal === '' ?
                            <div style={{
                              right: '31px',
                              height: '31px',
                              top: '17px'
                            }}
                              className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                            : <span style={{ right: '34px', top: '21px' }} className="clearAllSrcTest" onClick={(event) => searchByUploader('')} data-for="top_head" data-tip="Clear Search"></span>}
                          {uploaderListPopup &&
                            <div className="tag_room_list">
                              {
                                Uploader_list.filter(e => (e.l_show === undefined || e.l_show) && selectedCreators.indexOf(e.id) === -1).map((v) =>
                                  <p className="_tag_rooms" key={'tag' + v.id} onClick={() => selectCreate(v)}>{`${v.firstname} ${v.lastname}`}</p>
                                )
                              }
                              {Uploader_list.filter(e => (e.l_show === undefined || e.l_show) && selectedCreators.indexOf(e.id) === -1).length === 0 && <div className="tagNotFound">Not found</div>}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="selectedRoomCont">
                        {
                          Uploader_list.filter(r => selectedCreators.indexOf(r.id) > -1).map(v =>
                            <p className="tags_Color !pl-2 !mr-1 !mb-1" key={'tag1' + v.id}>{`${v.firstname} ${v.lastname}`}
                              <BsXLg className="tags_Icon_close" size={14} onClick={() => setSelectedCreators(selectedCreators.filter(r => r !== v.id))} />
                            </p>
                          )
                        }
                      </div>

                    </div>
                  </div>
                </div>
                <div className="searchAndFilter" style={{ borderBottom: '1px solid #eeeeee', position: 'relative', height: '80px', width: '100%' }}>

                  <input
                    className="_inputSearchBar !w-full"
                    type="text"
                    id="_inputBar_tagdetails"
                    placeholder="Search"
                    value={input_val}
                    onChange={(e) => { setInputVal(e.target.value) }}
                    onKeyUp={handleKeyUp}
                    autoFocus={true} />
                  {input_val === '' ?
                    <div style={{
                      right: '31px',
                      height: '31px'
                    }}
                      className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                    : <span style={{ right: '35px', top: '28px' }} className="clearAllSrcTest" onClick={(event) => { setInputVal(''); setResetCounter((resetCounter + 1)) }} data-for="top_head" data-tip="Clear Search"></span>}
                </div>
              </div>
              <div className="serarchButton area">
                <ButtonArea />
              </div>
            </div>
          </>
        }

        <div className="tagFlieList_body">
          {initialLoader ? <div className="loaderMain" style={{ height: 'calc(100vh - 328px)' }}></div> :
            <div className="tagFlieList_top">
              <div className="taskListTableHead">
                <ul className="fileHeadRow" style={{ borderTop: '1px solid rgb(229, 238, 244)' }}>

                  <li className="_afileMark"></li>
                  <li className={classNames("_afileName ", activesortCol === 'title' ? 'sort_active ' + activeOrdering : 'desc')}
                    style={{ justifyContent: "flex-start", width: "32%", cursor: 'default' }}>
                    <span className="sortIcons" style={{ cursor: 'pointer' }} onClick={(event) => sorting(event, 'title', activesortCol === 'title' ? activeOrdering : 'desc')} ></span>
                    <span className="columnNum" style={{ cursor: 'pointer' }} onClick={(event) => sorting(event, 'title', activesortCol === 'title' ? activeOrdering : 'desc')}>Link title  </span>

                    {/* <span className="total_files" style={{ position: 'absolute', left: '100px',marginLeft:'5px' }}>  {api_data.length} Total Links</span> */}
                  </li>

                  <li className="_afileSize" style={{
                    justifyContent: "flex-start",
                    width: "40%",
                    color: '#858fa3 !important',
                    cursor: 'default',
                    paddingLeft: "3px",
                  }}>

                    <span className="columnNum changeColor">Url</span>
                  </li>
                  <span style={{ width: "1%", justifyContent: "flex-start", cursor: 'default' }}></span>
                  <li className={classNames("_afileDate ", activesortCol === 'uploaded_by' ? 'sort_active ' + activeOrdering : 'desc')}
                  onClick={(event) => sorting(event, 'uploaded_by', activesortCol === 'uploaded_by' ? activeOrdering : 'desc')}
                    // onClick={(event)=>sorting(event,'uploaded_by',activesortCol === 'uploaded_by' ? activeOrdering:'desc')}

                    style={{ justifyContent: "flex-start", width: "19%", cursor: 'default' }}>
                    <span className="sortIcons" style={{ cursor: 'pointer' }}  ></span>
                    <span className="columnNum">Uploaded by</span>
                  </li>

                  <span style={{ width: "1%", justifyContent: "flex-start", }}></span>


                  <li
                    className={classNames("_afileDate ", activesortCol === 'created_at' ? 'sort_active ' + activeOrdering : 'desc')}
                    // onClick={(event)=>sorting(event,'created_at',activesortCol === 'created_at' ? activeOrdering:'desc')}
                    style={{ justifyContent: "flex-start", width: "10%", cursor: 'default', flexBasis: '10%' }}
                  >
                    {/* <span className="sortIcons"></span> */}
                    <span className="columnNum" style={{ cursor: 'default' }}>Date</span>
                  </li>
                </ul>

                {(prevloader || loading_res) && <div className="loaderMain mini_loader" style={{top: '50%'}}></div> }
                <div className={search_val === 'Basic search' ? "fileBody_areaLink" : "fileBody_area linkBody"} onScroll={onScrollLinks} ref={containerRef}>
                  {api_data.length > 0 ? api_data.map((link, index) => (
                    link.url !== '' ?
                      <ul className="fileBodyRow inFileLinkRow" style={{ padding: "3px 0px", height: "auto", position: 'relative', paddingLeft: '8px' }} key={'link_' + index}>
                        <li className="_afileMark">
                        </li>
                        <li className="_afileName hoverListsLink" style={{ justifyContent: "flex-start", width: "32%" }}>
                          <div className="workfreeliFileTextLink" style={{ marginLeft: "0px", marginTop: '5px', fontSize: '12px !important' }}>
                            {link.title ? link.title : <span className="">Add a title</span>}
                          </div>
                          <div className={classNames("gFileHoverListsLink",)} style={{ right: "45px" }}>
                            <div className="msg_hover_optionsLink">
                              {/* <div onClick={()=> add_title(link)} className="opts edit_opts opts_show edit_newpen" data-for="rightSection_tooltip" data-tip="Title" ></div> */}
                              {cacheMe.me.id === link.user_id && <div onClick={() => add_title(link)} id={ link.title ? "editLink" + link.id : "addTitle" + link.id} className="opts edit_opts opts_show edit_newpen"  ></div>}

                              <div className="opts opts_show copy_opts" id={"clipboard" + link.id} onClick={() => copyToClipBoard(link.url)} ></div>

                              {cacheMe.me.id === link.user_id && <div id={"deleteLink" + link.id} onClick={() => deleteLink(link)} className="fileOpts delete_opts"></div>}
                            </div>
                          </div>
                        </li>
                        <li className="_afileSize" style={{ width: "40%" }} >
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '7px 0px' }}>
                            <a href={FormatUrl(link.url)} target="_blank" className="linkTextTable" rel="noopener noreferrer" >{FormatUrl(link.url)}</a>
                          </div>
                        </li>

                        <span style={{ width: "1%" }}></span>
                        <li className="_afileSize" style={{ width: "19%" }} >
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '7px 0px' }}>
                            <p className="linkTextTable">{link.uploaded_by}</p>
                            <p className="linkTextTable" >{"Room : " + link.conversation_title}</p>
                          </div>
                        </li>

                        <span style={{ width: "1%", justifyContent: "flex-start", }}></span>
                        <li className="_afileSize" style={{ width: "10%", flexBasis: '10%' }} >
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '7px 0px', paddingLeft: '5px' }}>
                            <p className="linkTextTable">{moment(Number(link.created_at)).format('DD/MM/YYYY')}</p>
                            <p className="linkTextTable" >{moment(Number(link.created_at)).format('h:mm a')}</p>
                          </div>
                        </li>
                        <Tooltip anchorSelect={"#clipboard" + link.id} className="z-50" content="Copy to clipboard"></Tooltip>
                        <Tooltip className="z-50" anchorSelect={"#deleteLink" + link.id} content="Delete"></Tooltip>
                        {
                          link.title ? <Tooltip className="z-50" anchorSelect={"#editLink" + link.id} content="Edit"></Tooltip>
                          :
                          <Tooltip className="z-50" anchorSelect={"#addTitle" + link.id} content="Add a title"></Tooltip>
                        }
                       
                        {/* <Tooltip delayShow={1000} className="z-50" anchorSelect="#download_opts" float="true" content="Download"></Tooltip>
                         <Tooltip delayShow={1000} className="z-50" anchorSelect="#deleteopts" float="true" content="Delete"></Tooltip> */}
                      </ul>
                      : ''

                  )) : !initialLoader ? <div className="fileNotFound" style={{ height: 'calc(100% - 377px)' }}><h2>No data found matching the criteria! </h2></div> : ''}


                </div>
              </div>
            </div>
          }
        </div>

        {showDeletePopup && <DeleteLink setShowDeletePopup={setShowDeletePopup} setApi_data={setApi_data} api_data={api_data} action_link={action_link} />}
      </div>

    </>
  );
}


export default Links;
