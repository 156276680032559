/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import MessagesContainer from './Messages/MessagesContainer';
import { useSelector } from 'react-redux';
import { getONLYUSERSDATA } from '../../Utils/Common';
import { set_global_action_data, setPopup } from '../../redux/message';
import { useDispatch } from 'react-redux';

const QuickView = ({ setQuickViewPopup, handleMsgAction, data, all_users }) => {
    const active_conv = useSelector(state => state.message.active_conversation)
    const user = useSelector(state => state.message.user)
    const handlePrivateReply = () => {
        close()
        handleMsgAction('reply', data)
    }

    useEffect(() => {
        if (data.secret_user.length === 0) {
            close()
        }
    }, [data.secret_user]);


    const dispatch = useDispatch();
    const addEdit = () => {
        dispatch(set_global_action_data({ type: 'private_msg_update', msg: data }))
        dispatch(setPopup({ data: true, key: 'private_msg' }));
    }
    const close = () => {
        dispatch(set_global_action_data({}))
        setQuickViewPopup(false)
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setQuickViewPopup(false)
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div className='backwrap'>
            <div className='userMiniModal' style={{ minWidth: '500px' }}>
                <div className="userMiniModalHead">
                    <h4 className="popupTitle">Quick view of private message to :</h4>
                    <span className="closeModal" onClick={() => close()}></span>
                </div>

                <div className='userMiniModalBody quick_view_msg !pb-0'>
                    <div className="selectedParticipants" style={{ marginTop: '-10px' }}>
                        {getONLYUSERSDATA(all_users, data.secret_user, 'name').map(u =>
                            <div className="miniItem" key={u}>
                                <div className="miniItem_name">{u}</div>
                            </div>
                        )}
                        {data.sender === user.id && <div className="edit_action_U" onClick={addEdit}>Add/Edit</div>}
                    </div>
                </div>

                <MessagesContainer
                    data={data}
                    key={'_quick_' + data.msg_id}
                    room={active_conv}
                    me={user}
                    thread={true}
                    edit_msg_id={''}
                    all_users={all_users}
                    all_msgs={[]}
                    seperator={''}
                    quickMsg={true}
                />
                <div className="freeliModalFoot">
                    <button className="btnCancel" onClick={() => close()}>Cancel</button>
                    <button className="btnAction" style={{ background: 'rgb(9, 38, 106)' }} onClick={handlePrivateReply}>Reply</button></div>
            </div>
        </div>
    );
};

export default QuickView;