import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputValidationCheck } from '../ValidationCheck';

const InviteNewUser = ({ inputData, setInputData, allusers, isExist, setIsExist }) => {

    // const handleInput = (value, fieldName) => {

    //     if (fieldName === 'email') {
    //         if (value !== '') {
    //             if (allusers.filter(v => v.email === value).length > 0) {
    //                 setIsExist(true)
    //             } else {
    //                 setIsExist(false)
    //             }
    //         }
    //     }
    //     setInputData({ ...inputData, [fieldName]: value });
    // };
    const handleInput = (value, fieldName, isNumber = false) => {
        // Allow clearing the input by skipping validation for empty values
        if (value === "" || !InputValidationCheck(value, isNumber)) {
            // Check for email existence only for non-empty input
            if (fieldName === "email") {
                if (value !== "") {
                    if (allusers.some(user => user.email === value)) {
                        setIsExist(true);
                    } else {
                        setIsExist(false);
                    }
                } else {
                    setIsExist(false);
                }
            }

            // Update state with the validated or cleared input
            setInputData({ ...inputData, [fieldName]: value });
        }
    };

      const [info, setInfo] = useState('');
    
      const URL = "https://api.db-ip.com/v2/free/self";
    
      useEffect(() => {
        const fetchGeolocation = async () => {
          try {
            const response = await fetch(URL);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setInfo(data.countryCode.toLowerCase());
          } catch (error) {
            console.error('Error fetching geolocation data:', error);
          }
        };
    
        fetchGeolocation();
      }, []);

    return (
        <>
            <div className='createUserFrom' style={{ padding: "8px 0px" }}>
                <div className="inputGroup" style={{ paddingRight: "0px" }}>
                    <label className="inputLabel">Email <span style={{ color: 'red' }}> *</span></label>
                    <input value={inputData.email} onChange={(e) => handleInput(e.target.value, 'email')} type="email" className="firstName" name="email" placeholder="User email" required />
                    {isExist && <span style={{ color: 'red', fontSize: '10px' }}>Email already exist</span>}
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="inputGroup">
                        <label className="inputLabel">First name <span style={{ color: 'red' }}> *</span></label>
                        <input value={inputData.firstname} onChange={(e) => handleInput(e.target.value, 'firstname')} type="text" className="firstName" name="firstname" placeholder="First name" required />
                    </div>
                    <div className="inputGroup" style={{ paddingRight: "0px" }}>
                        <label className="inputLabel">Last name <span style={{ color: 'red' }}> *</span></label>
                        <input value={inputData.lastname} onChange={(e) => handleInput(e.target.value, 'lastname')} type="text" className="lastName" name="lastname" placeholder="Last name" required />
                    </div>
                </div>
                <div className="inputGroup" style={{ position: 'relative', paddingRight: "0px" }}>
                    <label className="inputLabel" phone="8">Phone number (optional)</label>
                    <span className="tooltip3 user_Management_title" style={{ marginLeft: '5px', cursor: 'pointer', position: 'absolute', left: '170px', top: '-7px' }}></span>

                    <PhoneInput
                        className="inputClass inputClassRoomPhone userPhone"
                        inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                        enableSearch={true}
                        country={info}
                        value={inputData.phone}
                        onChange={(value) => handleInput(value, 'phone')}
                        inputProps={{
                            name: 'phone',
                            placeholder: "(000) 000-0000",
                            // onKeyUp: numberCheck,
                            // onKeyDown: numberCheck
                        }}
                    />
                </div>
                {/* <div className="inputGroup !mb-0" style={{ paddingRight: "0px" }}>
                    <label className="inputLabel">Company name</label>
                    <input type="text" className="firstName" name="email" placeholder="Enter company name" required />
                </div> */}
            </div>
        </>
    );
};

export default InviteNewUser;
