/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { MdOutlineCancel, MdOutlineClose } from 'react-icons/md';
import userImage from '../../../media/images/img.png';
import sortArrayByKey from '../../../hooks/useSortedArray';

const TaskAssignTo = ({ task, setValue, all_users }) => {
    const [assignToDropdown, setAssignToDropdown] = useState(false);
    const [assignSelectedMember, setSelectedAssignMember] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const task_participants = all_users?.filter(f => task.participants?.indexOf(f.id) > -1)
    const filter_data = task_participants?.filter(f => (f.firstname + ' ' + f.lastname).toLowerCase()?.includes(searchInput?.toLowerCase()))
    const sorted_data = sortArrayByKey(filter_data, 'firstname', 'asc');

    const handleMemberSelect = useCallback((data) => {
        setValue(prev => prev.map(v => v.temp_key === task.temp_key ? { ...v, assign_to: [data.id] } : v));
        setSelectedAssignMember(data.firstname + ' ' + data.lastname);
        setAssignToDropdown(false);
        setHighlightedIndex(-1);
    }, [setValue, task, all_users]);

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
        setHighlightedIndex(-1);
    };

    const handleRemoveMember = () => {
        setSelectedAssignMember('');
        setValue(prev => prev.map(v => v.temp_key === task.temp_key ? { ...v, assign_to: [] } : v));
    };

    const handleKeyDown = useCallback((event) => {
        if (!assignToDropdown) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex(prevIndex => (prevIndex + 1) % sorted_data.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex(prevIndex => (prevIndex - 1 + sorted_data.length) % sorted_data.length);
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            handleMemberSelect(sorted_data[highlightedIndex]);
        }
    }, [assignToDropdown, sorted_data, highlightedIndex, handleMemberSelect]);

    useEffect(() => {
        if (assignToDropdown) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [assignToDropdown, handleKeyDown]);

    const ref = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setAssignToDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='relative w-[44%]'>
            {assignSelectedMember ? (
                <div className='flex  gap-3 cursor-pointer items-center' onClick={() => setAssignToDropdown(!assignToDropdown)}>
                    <img className='w-[28px] h-[28px] rounded-full' src={userImage} alt="user" title='user' />
                    <p className="text-[#0B1F47] text-base font-medium max-w-52 whitespace-nowrap text-ellipsis overflow-hidden">{assignSelectedMember}</p>
                </div>
            ) : (
                <div className='flex gap-3 cursor-pointer' onClick={() => setAssignToDropdown(!assignToDropdown)}>
                    <div className="task_User_icon"></div>
                    <div className='assignedToLabel flex w-full justify-between items-center border border-gray-300 px-3 py-1 rounded-[5px] text-sm text-[#0B1F47]'>
                        <span>Assigned to</span>
                        <GoChevronDown className={`text-[#0B1F47] ${assignToDropdown ? 'rotate-180' : ''}`} />
                    </div>
                </div>
            )}

            {assignToDropdown && (
                <div className='absolute !w-[100%]' ref={ref}>
                    <div className="AddKeywordsPopup_task_settings insightTaskBoard !mt-0 !top-0 bg-white border border-gray-300 rounded-md !w-[calc(100%-30px)] !left-[30px]">
                        <div className="keywordItem">
                            <div className="searchAndFilterBar" style={{ position: 'relative' }}>
                                {assignSelectedMember && (
                                    <div className="selectedRoomCont !pl-1 !pr-1">
                                        <div className='flex gap-2 items-center justify-between bg-[#0B1F47] py-[2px] px-2 rounded-[5px]'>
                                            <p className="text-white text-xs mt-[1px]">{assignSelectedMember}</p>
                                            <MdOutlineClose className='text-white text-xs cursor-pointer' onClick={handleRemoveMember} />
                                        </div>
                                    </div>
                                )}
                                <div className="p-2 !pb-0 relative">
                                    <input className="_inputBar searchLeft" type="text" placeholder="Search assignee" value={searchInput} onChange={handleSearchInputChange} autoFocus />
                                    {
                                        searchInput.length > 0 && <span className="text-red-600 text-xl absolute top-[14px] right-4"><MdOutlineCancel onClick={() => setSearchInput('')} /></span>
                                    }
                                </div>
                                <div className="keyword_list_task_settings workloadUsers">
                                    {sorted_data.filter(f => !task.assign_to?.includes(f.id) && !task.observers?.includes(f.id)).length > 0 ? (
                                        sorted_data.filter(f => !task.assign_to?.includes(f.id) && !task.observers?.includes(f.id)).map((v, index) => (
                                            <p
                                                className={`_tag_rooms ${highlightedIndex === index ? 'bg-[#0C1E47] text-white' : ''}`}
                                                key={index}
                                                onClick={() => handleMemberSelect(v)}
                                                tabIndex="0"
                                            >
                                                {v.firstname + ' ' + v.lastname}
                                            </p>
                                        ))
                                    ) : (
                                        <p className="text-center text-gray-500">No other user found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TaskAssignTo;
