import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Archive_count } from '../../Utils/GraphqlQueries';
import { useDispatch, useSelector } from 'react-redux';

const SettingsOptions = ({ setSettingOptionsPopup, handleChangePasswordPopup, setTipsAndTour, setArchiveRoom, getArchiveConversations, companiesData, setTitleText, setComingSoon }) => {
  const ref1 = useClickAway(() => {
    setSettingOptionsPopup(false)
  });

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login"
  }
  const userMe = useSelector(state => state.message.user)
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    let type = localStorage.getItem("theme");
    if (type === 'dark') {
      setTheme('dark');
      document.querySelector('#root').classList.add('dark');
    } else {
      setTheme('light');
      document.querySelector('#root').classList.remove('dark');
    }
  }, [])

  const selectTheme = (type) => {
    localStorage.setItem("theme", type);
    setTheme(type);
    if (type === 'dark') {
      document.querySelector('#root').classList.add('dark');
    } else {
      document.querySelector('#root').classList.remove('dark');
    }
  }

  const handleLightMood = () => {
    selectTheme('light')
    setSettingOptionsPopup(false)
  }

  const handleDarkMood = () => {
    selectTheme('dark')
    setSettingOptionsPopup(false)
  }

  const handleTipsPopup = () => {
    setSettingOptionsPopup(false)
    setTipsAndTour(true)
  }

  const handleArchiveRoom = () => {
    getArchiveConversations()
    setSettingOptionsPopup(false)
    setArchiveRoom(true)
  }

  const [archiveCounter, setArchiveCounter] = useState(0)

  useQuery(Archive_count, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log("🚀 ~ SettingsOptions ~ data:", data)
      if (data?.archive_count?.status) {
        setArchiveCounter(data?.archive_count?.archive)
      }
    },
    onError: (error) => {
      console.log("🚀 ~ file: SettingsOptions.js ~ line 72 ~ getArchiveCount ~ error", error)
    }
  })

  const clicktoAddShortcut = ()=>{
    debugger
    // //console.log(163,window.deferredPrompt)
    //console.log(155,window.deferredPrompt1)
    window.deferredPrompt1.prompt();
    // if (beforeInstallEvent) beforeInstallPrompt.prompt();
    // pwaInstall({
    //  title: "WorkFreeli",
    //  logo: myLogo,
    //  features: (
    //    <ul>
    //    {/* <li>Cool feature 1</li>
    //    <li>Cool feature 2</li>
    //    <li>Even cooler feature</li>
    //    <li>Works offline</li> */}
    //    </ul>
    //  ),
    //  description: "",
    //   })
      // .then(() => alert("App installed successfully or instructions for install shown"))
      // .catch(() => alert("User opted out from installing"));
  }

  return (
    <>
      <div ref={ref1} className="profilenavMainSection !z-[99999] !display-[unset] md:left-[265px] md:top-[60px] lg:left-[315px] lg:top-[60px] xl:left-[365px] xl:top-[80px]" >
        <div className="profilenavMidleArea">
          <p className="profilenavMidleArea_label">Account details</p>
          <Link to='/connect/profile' title='Edit profile' onClick={() => setSettingOptionsPopup(false)} className="linkAccount">Edit profile</Link>
          <span className="linkAccount" onClick={handleChangePasswordPopup}>Change password</span>
          <div className="profileNavContainer_line"></div>

          <p className="profilenavMidleArea_label">Manage account</p>
          {
            companiesData?.companies?.length > 1 &&
            <Link to='/connect/switch-account' title='Switch accounts' onClick={() => setSettingOptionsPopup(false)} className="linkAccount">Switch accounts</Link>
          }
          <span className="linkAccount theme">Switch theme
            <span className="themeMenu">
              <span className={`themeMode ${theme === 'light' ? 'active' : ''}`} onClick={handleLightMood}>Light mode</span>
              <span className={`themeMode ${theme === 'dark' ? 'active' : ''}`} onClick={handleDarkMood}>Dark mode</span>
            </span>
          </span>
          <div className="profileNavContainer_line"></div>
          <p className="profilenavMidleArea_label">Advanced</p>
          <Link to='/connect/notifications' title='All Notifications' onClick={() => setSettingOptionsPopup(false)} className="linkAccount">All Notifications</Link>
          {archiveCounter > 0 &&
            <span className="nodecoration">
              <span onClick={handleArchiveRoom} className="linkAccount">Archived rooms ({archiveCounter})</span>
            </span>
          }
          {/* <span className="nodecoration">
            <span className="linkAccount"><span>Manage Personal Tags</span></span>
          </span> */}
          <Link to='/connect/workfreeli-ai' title='Admin settings' onClick={() => setSettingOptionsPopup(false)} className="linkAccount">Workfreeli AI</Link>
          <Link to='/connect/workfreeli-db' title='Workfreeli DB' onClick={() => setSettingOptionsPopup(false)} className="linkAccount">Workfreeli DB</Link>
          {userMe.role === "Admin" && (<Link to='/connect/admin-settings' title='Admin settings' onClick={() => { setSettingOptionsPopup(false); setArchiveRoom(false) }} className="linkAccount">Admin settings</Link>)}
          <div className="profileNavContainer_line"></div>
          <p className="profilenavMidleArea_label">Help</p>
          
          <span className="nodecoration" onClick={clicktoAddShortcut}>
            <span className="linkAccount">
            {window.deferredPrompt1 ? 'Install' : 'Installed'} Desktop Application
            </span>
          </span>
          
          <span className="linkAccount" onClick={handleTipsPopup}>Tour for tips and suggestions</span>
          <span className="nodecoration">
            <span className="linkAccount" onClick={() => { setTitleText('Workfreeli support'); setComingSoon(true); }}><span>Workfreeli support</span></span>
          </span>
        </div>
        <div className="profileNavContainer_line"></div>
        <span className="linkAccount signout">Sign out
          <span className="signout_menu">
            <span className="themeMode border_bottom" onClick={() => logout()}>From this device</span>
          </span>
        </span>
      </div>

    </>
  );

};

export default SettingsOptions;