/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './KanbanBoard.css';
import { useApolloClient, useMutation } from '@apollo/client';
import { UPDATE_SINGLE_TASK } from '../../../Utils/GraphqlMutaion';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { BsBellFill, BsSortDown, BsSortUp } from 'react-icons/bs';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import classNames from 'classnames';
import moment from 'moment';
import { GET_ALL_USERS, GET_ME } from '../../../Utils/GraphqlQueries';
import { useTaskContext } from '../TaskContext';
import WarningPopup from '../../Connect/WarningPopup';
import { useNavigate, useParams } from 'react-router-dom';

// Reusable function to reorder array elements
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

// Board component
const KanbanBoard = ({ column_list, tasksData }) => {
    const { fetchByPage, setSelectedTask, setPagination, set_column_id, loading, pagination, setPaginationLoading, paginationLoading, kanban_pagination, viewType, modifyTask } = useTaskContext();
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const { users } = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const [columns, setColumns] = useState(column_list);
    const [tasks, setTasks] = useState(tasksData);
    const [collapsedColumns, setCollapsedColumns] = useState({});
    const [updateTask] = useMutation(UPDATE_SINGLE_TASK);

    useEffect(() => {
        setColumns(column_list)
    }, [column_list])

    useEffect(() => {
        setTasks(tasksData)
    }, [tasksData])

    const isTaskInColumn = (task, columnId) => {
        if (!task || !columnId) return false;

        const dueByQuery = [
            ["unset_due_Date", null],
            ["overdue", { "$lt": moment().startOf('day').toDate() }],
            ["0", { "$gte": moment().startOf('day').toDate(), "$lte": moment().endOf('day').toDate() }],
            ["1", { "$gte": moment().add(1, 'day').startOf('day').toDate(), "$lte": moment().add(1, 'day').endOf('day').toDate() }],
            ["7", { "$gte": moment().add(2, 'day').startOf('day').toDate(), "$lte": moment().add(7, 'day').endOf('day').toDate() }],
            ["30", { "$gte": moment().add(8, 'day').startOf('day').toDate(), "$lte": moment().add(30, 'day').endOf('day').toDate() }],
            ["60", { "$gte": moment().add(31, 'day').startOf('day').toDate(), "$lte": moment().add(60, 'day').endOf('day').toDate() }],
            ["90", { "$gte": moment().add(61, 'day').startOf('day').toDate(), "$lte": moment().add(90, 'day').endOf('day').toDate() }],
            ["180", { "$gte": moment().add(91, 'day').startOf('day').toDate(), "$lte": moment().add(180, 'day').endOf('day').toDate() }],
            ["completed", "Completed"]
        ];

        const columnQuery = dueByQuery.find(([id]) => id === columnId);
        if (!columnQuery) return false;

        const [_, condition] = columnQuery;

        if (columnId === "unset_due_Date") {
            return !task.end_date;
        }

        if (columnId === "completed") {
            return task.status === "Completed";
        }

        if (!task.end_date) return false;
        const taskDate = new Date(task.end_date);

        if (condition["$lt"]) {
            return taskDate < condition["$lt"];
        }

        if (condition["$gte"] && condition["$lte"]) {
            return taskDate >= condition["$gte"] && taskDate <= condition["$lte"];
        }

        return false;
    };

    const due_date_calc = (destColumnId) => {
        let days = parseInt(destColumnId);
        let data = {};
        if (!isNaN(days)) {
            let new_due = moment().endOf('day').add(days, 'day');
            data = { end_date: new_due, save_type: 'duedate' };
            // due_time: new_due
        } else {
            if (destColumnId === "unset_due_Date") {
                data = { end_date: null, save_type: 'duedate' };
                // due_time: ""
            }
            else if (destColumnId === "overdue") {
                let new_due = moment().endOf('day').subtract(1, 'day');
                data = { end_date: new_due, save_type: 'duedate' };
                // due_time: new_due
            }
            else if (destColumnId === "completed") {
                data = { status: destColumnId, };
            }
        };

        return data;
    }

    const onDragEnd = useCallback((result) => {
        const { source, destination, draggableId, type } = result;

        // Return if no destination (dragged outside any valid droppable area)
        if (!destination) return;

        // Handle column reordering
        if (type === 'COLUMN') {
            const newColumns = reorder(columns, source.index, destination.index);
            setColumns(newColumns);
            return;
        }

        // Handle task dragging
        if (type === 'TASK') {

            // (viewType === 'kanban' && task.status === column.id) || (viewType === 'kanban_progress' && task.progress === column.id) || (viewType === 'kanban_dueby' && isTaskInColumn(task, column.id))

            const sourceColumnId = source.droppableId;
            const destColumnId = destination.droppableId;

            const sourceTasks = tasks?.filter((task) => (viewType === 'kanban' && task.status === sourceColumnId) || (viewType === 'kanban_progress' && task.progress === parseInt(sourceColumnId)) || (viewType === 'kanban_dueby' && isTaskInColumn(task, sourceColumnId)));

            if (sourceColumnId === destColumnId) {
                // Reorder tasks within the same column
                const reorderedTasks = reorder(sourceTasks, source.index, destination.index);

                setTasks((prevTasks) => [
                    ...prevTasks.filter((task) => (viewType === 'kanban' && task.status !== sourceColumnId) || (viewType === 'kanban_progress' && task.progress !== parseInt(sourceColumnId)) || (viewType === 'kanban_dueby' && !isTaskInColumn(task, sourceColumnId))),
                    ...reorderedTasks,
                ]);
            } else {
                // Move task to a different column
                // const sourceTasks = tasks?.filter((task) => (viewType === 'kanban' && task.status === sourceColumnId) || (viewType === 'kanban_progress' && task.progress === parseInt(sourceColumnId)));
                const destTasks = tasks?.filter((task) => (viewType === 'kanban' && task.status === destColumnId) || (viewType === 'kanban_progress' && task.progress === parseInt(destColumnId)) || (viewType === 'kanban_dueby' && isTaskInColumn(task, destColumnId)));

                // Remove task from source column
                const [movedTask] = sourceTasks.splice(source.index, 1);

                // Update the task's status to match the destination column
                if (viewType === 'kanban') {
                    movedTask.status = destColumnId;
                } else if (viewType === 'kanban_progress') {
                    movedTask.progress = destColumnId;
                } else if (viewType === 'kanban_dueby') {
                    let data = due_date_calc(destColumnId)
                    movedTask.end_date = data.end_date;
                    // movedTask.due_time = data.due_time;
                }

                // Append the task to the end of the destination column's Task list
                destTasks.push(movedTask);

                setTasks((prevTasks) => [
                    // Update tasks in source and destination columns
                    ...prevTasks.filter((task) => (viewType === 'kanban' && task.status !== sourceColumnId && task.status !== destColumnId) || (viewType === 'kanban_progress' && task.progress !== parseInt(sourceColumnId) && task.progress !== parseInt(destColumnId)) || (viewType === 'kanban_dueby' && !isTaskInColumn(task, sourceColumnId) && !isTaskInColumn(task, destColumnId))),
                    ...sourceTasks,
                    ...destTasks,
                ]);


                if (viewType === 'kanban') {
                    updateTask({
                        variables: {
                            input: {
                                _id: draggableId,
                                status: destination.droppableId  // Update status to match destination column
                            }
                        }
                    });
                } else if (viewType === 'kanban_progress') {
                    updateTask({
                        variables: {
                            input: {
                                _id: draggableId,
                                progress: parseInt(destination.droppableId)  // Update status to match destination column
                            }
                        }
                    });
                } else if (viewType === 'kanban_dueby') {
                    let data = due_date_calc(destColumnId)
                    updateTask({
                        variables: {
                            input: {
                                _id: draggableId,
                                ...data
                            }
                        }
                    });
                }
            }
        }
    }, [columns, tasks, updateTask]);

    const toggleCollapse = useCallback((columnId) => {
        setCollapsedColumns((prev) => ({ ...prev, [columnId]: !prev[columnId] }));
    }, []);

    // const [prevloader, setPrevloader] = useState(false)
    const prevScrollHeights = useRef({}); // Store scroll heights for each column
    const scrollRefs = useRef([]); // Array of refs for each column

    useEffect(() => {
        // Initialize scroll refs for all columns dynamically
        scrollRefs.current = columns?.map(() => React.createRef());
    }, [columns]);

    const mergeRefs = (dragRef, scrollRef) => (node) => {
        if (dragRef) dragRef(node); // Assign drag-and-drop functionality
        if (scrollRef) scrollRef.current = node; // Assign scroll ref
    };

    const onScrollColumn = (columnId, index) => {
        const kb_pagi = kanban_pagination.filter(f => f.kb_row_name === columnId)?.[0];
        const columnRef = scrollRefs.current[index];
        if (columnRef?.current) {
            const { scrollTop, clientHeight, scrollHeight } = columnRef.current;

            // Check if scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                if (!paginationLoading && kb_pagi?.totalPages > kb_pagi?.page) {
                    set_column_id([columnId])
                    console.count("onScrollColumn Calling API...");
                    prevScrollHeights.current[columnId] = scrollHeight; // Update previous scroll height
                    setPaginationLoading(true);
                    setPagination(prev => ({ ...prev, page: kb_pagi.page + 1 }))
                    fetchByPage(kb_pagi.page + 1, [columnId])
                }
            }
        }
    };

    const sorted_task = (tasks ?? []).sort((a, b) => {
        // Determine if the task is flagged with the user ID
        const aHasUid = (a.flag ?? []).includes(user.id);
        const bHasUid = (b.flag ?? []).includes(user.id);

        // Primary sorting: Flagged tasks first
        if (aHasUid !== bHasUid) return aHasUid ? -1 : 1;

        // Secondary sorting: Within flagged/unflagged, sort by last_updated_at (descending order)
        const aUpdatedAt = new Date(a.last_updated_at || 0); // Default to epoch if missing
        const bUpdatedAt = new Date(b.last_updated_at || 0);

        return bUpdatedAt - aUpdatedAt; // Descending order (latest first)
    });

    const [task_to_update, set_task_to_update] = useState(null)
    const closePopUp = () => {
        set_task_to_update(null)
    }

    const completeTask = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        set_task_to_update(data);
    }

    const onConfirm = async () => {
        let apires = await updateTask({
            variables: {
                input: {
                    _id: task_to_update._id,
                    status: 'Completed'  // Update status to match destination column
                }
            }
        });
        modifyTask(apires.data.update_single_task[0])
        closePopUp()
    }

    return (
        <>
            <div className="kanban_section relative">
                {loading && pagination.page === 1 &&
                    <div className='taskLoadBfrore h-full w-full flex items-center justify-center absolute left-0 top-0 z-[1] bg-white/90'>
                        <div className="loaderMain mini_loader"></div>
                    </div>
                }
                <div className="kanban_container">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="all-columns" direction="horizontal" type="COLUMN">
                            {(provided) => (
                                <div className="flex gap-4" style={{ height: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
                                    <div className="flex gap-4 relative" style={{ height: '100%' }}>
                                        {(!loading && sorted_task.length === 0) && <div className=' absolute left-0 top-0 flex items-center justify-center w-full h-full'>No tasks found</div>}
                                        {columns?.map((column, colIndex) => (
                                            <Draggable key={column.id} draggableId={column.id} index={colIndex}>
                                                {(provided) => (
                                                    // kanban-column 
                                                    <div className={`${!collapsedColumns[column.id] ? "kanbanTaskColCon w-[280px]" : ""}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* className="kanban-column-header" */}
                                                        <div style={{ background: column.id === "Not Started" ? "#ECEEF4" : column.id === "In Progress" ? "#D7E1F5" : column.id === "On Hold" ? "#EEDEC6" : column.id === "Completed" ? "#c6e9d8" : column.id === "Canceled" ? "#ebc8c8" : "#eceef4" }} className={`colTileDark flex justify-between p-[10px] text-black items-center rounded ${collapsedColumns[column.id] ? "flex-col gap-2 h-64" : ""}`}>
                                                            <div className={`flex gap-1 ${collapsedColumns[column.id] ? "flex-col" : ""}`}>
                                                                {collapsedColumns[column.id] ? <MdKeyboardArrowRight onClick={() => toggleCollapse(column.id)} className="text-2xl" /> : <MdKeyboardArrowLeft onClick={() => toggleCollapse(column.id)} className="text-2xl" />}
                                                                <div className={`text-sm ${collapsedColumns[column.id] ? "[writing-mode:vertical-lr] pt-[0px] rotate-180" : "pt-[2px]"}`}>
                                                                    <p className={`${collapsedColumns[column.id] ? "ml-[2px]" : ""}`}>{column.title}</p>
                                                                </div>
                                                            </div>
                                                            <span className="h-[20px] w-[20px] rounded-full flex justify-center items-center !bg-[#ffffff] text-[#032e84] text-[12px] ">{tasks.filter((task) => (viewType === 'kanban' && task.status === column.id) || (viewType === 'kanban_progress' && task.progress === parseInt(column.id)) || (viewType === 'kanban_dueby' && isTaskInColumn(task, column.id))).length}</span>
                                                        </div>

                                                        {!collapsedColumns[column.id] && (
                                                            <Droppable droppableId={column.id} type="TASK">
                                                                {(provided) => (
                                                                    <div className={`kanban-tasks ${tasks?.filter((task) => (viewType === 'kanban' && task.status === column.id) || (viewType === 'kanban_progress' && task.progress === parseInt(column.id)) || (viewType === 'kanban_dueby' && isTaskInColumn(task, column.id))).length === 0 ? 'empty' : ''}`}
                                                                        ref={mergeRefs(provided.innerRef, scrollRefs.current[colIndex])}
                                                                        onScroll={() => onScrollColumn(column.id, colIndex)} // Pass column ID and colIndex to handler
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {sorted_task?.filter((task) => (viewType === 'kanban' && task.status === column.id) || (viewType === 'kanban_progress' && task.progress === parseInt(column.id)) || (viewType === 'kanban_dueby' && isTaskInColumn(task, column.id)))
                                                                            .map((task, index) => (
                                                                                <Task task={task} setSelectedTask={setSelectedTask} completeTask={completeTask} users={users} user={user} index={index} key={task._id} />
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            {task_to_update && <WarningPopup messageTitle={'Task'} description={'Mark as completed?'} closePopUp={closePopUp} data={task_to_update} onConfirm={onConfirm} type={"task_update"} />}
        </>
    );
};

const Task = ({ task, users, user, completeTask, index }) => {
    const navigate = useNavigate()
    const params = useParams()
    const colorPlate = ['#732be2', '#078cfe', '#ff9988', '#7ed6d0', '#8b57db', '#2c56ac', '#a6d068', '#69abf9', '#ffaf4c']
    const [showAll, setShowAll] = useState(false);
    const maxVisibleKeywords = 3; // Number of keywords to show initially

    const handleToggle = () => {
        setShowAll(!showAll);
    };

    const visibleKeywords = task.key_words?.slice(0, maxVisibleKeywords) || [];
    const hiddenKeywordsCount = task.key_words?.length - visibleKeywords.length;

    return (
        <Draggable draggableId={task._id} isDragDisabled={task?.observers?.includes(user.id)} index={index}>
            {(provided) => (
                <div onClick={() => navigate(`${params.tasks_tab}/${task._id}`)}
                    className={`kanban-task rounded-[4px] border border-[#cee4f7] group hover:!bg-[#EBEBEB] cursor-grab ${params.task_id === task._id && '!bg-[#eceef4]'}`}
                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className="px-2.5 py-1.5 border-b border-b-[#eceef4] flex justify-between items-center">
                        <div>
                            {task.end_date ?
                                <div className='kanbanDue_date'>
                                    <span className={classNames('dueDateFull', task.end_date && (new Date()).setHours(0, 0, 0, 0) > new Date(task.end_date) && task.columnId !== "others" ? 'text-red-400' : '')}>
                                        {(new Date()).setHours(0, 0, 0, 0) > new Date(task.end_date) ?
                                            <span className='dueEndDate text-xs !text-[#8f8f8f]'>{moment(task.end_date).format('MMMM DD, YYYY')}</span>
                                            : <span className='onlyEndDate text-xs text-[#8f8f8f]'>{moment(task.end_date).format('MMMM DD, YYYY')}</span>
                                        }
                                    </span>
                                </div> : <div className='onlyEndDate text-xs text-[#8f8f8f]'>Due</div>}

                        </div>
                        <div className="flex items-center">
                            {/* <dir className="flex">
                                {getONLYUSERSDATA(users, task.observers, 'all').map((user, index) => (
                                    index < 3 &&
                                    <div className="-ml-1" key={'user_' + index}>
                                        <div key={index}
                                            className="text-[12px] pt-1 !mt-0 w-[24px] h-[24px] justify-center items-center rounded-full text-white font-semibold"
                                            style={{ backgroundColor: colorPlate[index] }}
                                        >
                                            <p className="text-center">{user.fnln}</p>
                                        </div>
                                    </div>
                                ))}
                            </dir> */}
                            {/* <div>
                                {getONLYUSERSDATA(users, task.observers, 'all').length > 3 &&
                                    <p className="text-xs pl-1 text-[#8f8f8f]">{getONLYUSERSDATA(users, task.observers, 'all').length - 3}+</p>
                                }
                            </div> */}
                            {(task.view_update?.indexOf(user.id) > -1 || task.view_checklist?.indexOf(user.id) > -1 || task.view_cost?.indexOf(user.id) > -1 || task.view_description?.indexOf(user.id) > -1 || task.view_hour?.indexOf(user.id) > -1) &&
                                <div className="p-1 bg-[#732BE2] text-xs rounded-full ml-1">
                                    <BsBellFill className="text-white" />
                                </div>
                            }
                        </div>

                    </div>
                    <div className="flex gap-3 p-2.5">
                        {
                            task.status === "Completed" ?
                                <div className="w-[12px] h-[12px] mt-[2px]">
                                    <FaCheckCircle className="text-base w-[15px] h-[15px] text-[#49e688]" />
                                </div> :
                                <div className="w-[12px] h-[12px] mt-[2px] cursor-pointer" onClick={(e) => completeTask(e, task)}>
                                    <FaRegCircle className="text-base text-[#8f8f8f] w-[15px] h-[15px]" />
                                </div>
                        }

                        <div className="my-auto w-full overflow-y-auto overflow-x-hidden whitespace-pre-wrap task_k_title" style={{ overflow: "hidden auto", color: "rgb(3, 46, 132)", }}>
                            <div>
                                <div className="taskCanbanTitle -mt-[1px] !text-[14px]">
                                    {
                                        task.status === "Completed" ? <span className="text-[#012B82] darkColor">
                                            <del className="pt-1 !text-[14px]">{task.task_title}</del>
                                        </span> : <span className="text-[#012B82] darkColor !text-[14px]">{task.task_title}</span>
                                    }
                                </div>
                                <div className="pt-1 relative">
                                    <p className="text-[#8f8f8f] text-tiny darkColor">
                                        Project name: <span className="text-[#012B82] darkColor">{task?.project_title}</span>
                                    </p>
                                    <p className="text-[#8f8f8f] text-tiny darkColor">Assigned to: <span className="text-[#012B82] darkColor">{task.assign_to[0] ? getONLYUSERSDATA(users, task.assign_to[0], 'name') : 'N/A'}</span></p>
                                    {task.flag.indexOf(user.id) > -1 &&
                                        <div className="!absolute !right-[0px] !-top-5 fill_flagIcom_Active_Task"></div>
                                    }
                                </div>
                            </div>

                            <div className="keywords_area !left-0 mt-1 gap-1">
                                {visibleKeywords.map((keyword, index) => (
                                    <div key={index} className="singleKeyword !text-[10px] !bg-[#e3f2ff] !text-[#0b1f47] cursor-pointer" >
                                        {keyword}
                                    </div>
                                ))}

                                {hiddenKeywordsCount > 0 && !showAll && (
                                    <div className="singleKeyword !text-[10px] !bg-[#EBEBEB] !text-[#0B1F47] cursor-pointer" onClick={handleToggle}>
                                        +{hiddenKeywordsCount}
                                    </div>
                                )}

                                {showAll && task.key_words?.slice(maxVisibleKeywords).map((keyword, index) => (
                                    <div key={maxVisibleKeywords + index} className="singleKeyword !text-[10px] !bg-[#e3f2ff] !text-[#0b1f47] cursor-pointer" >
                                        {keyword}
                                    </div>
                                ))}

                                {showAll && (
                                    <div className="singleKeyword !text-[10px] !bg-[#f8d7da] !text-[#721c24] cursor-pointer" onClick={handleToggle}>
                                        Show Less
                                    </div>
                                )}
                            </div>
                            {task?.observers?.includes(user.id) &&
                                <p className="text-red-600 text-xs darkColor group-hover:block hidden">Observer is not allow to drag this task</p>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default React.memo(KanbanBoard);
