import React, { useState, useRef } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';



const CreateReportFooter = ({ notes, setNotes }) => {
  // State hooks for different notes
  // const [notes, setNotes] = useState({
  //   operational: '',
  //   briefing: '',
  //   service: '',
  //   kitchen: ''
  // });
  const [operationalNote, setOperationalNote] = useState(false);
  const [briefing, setBriefing] = useState(false);
  const [service, setService] = useState(false);
  const [kitchen, setKitchen] = useState(false);

  const operationalRef = useRef(null);
  const briefingRef = useRef(null);
  const serviceRef = useRef(null);
  const kitchenRef = useRef(null);
  const characterLimit = 300;

  // Event handler to update note state
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     // const characterLimit = 300; // Replace with your actual limit
//    // Validate the input
//    if (validFirstChercterAlsoNuber(value)) {
//     console.warn('Invalid input: Special characters or numbers are not allowed at the start.');
//     return;
//   }
// // Trim spaces from the start of the value
// const trimmedValue = value.trimStart();
  
// // Ensure the note is not empty or only spaces
// if (!trimmedValue) {
//   console.warn('Note cannot be empty or contain only spaces.');
//   return;
// }

//     setNotes((prevNotes) => ({
//       ...prevNotes,
//       [name]: trimmedValue.length > characterLimit ? trimmedValue.substring(0, characterLimit) : trimmedValue
//     }));
//   };
const handleChange = (e) => {
  const { name, value } = e.target;
  const characterLimit = 300; // Define the character limit

  // Validate the input: Ensure first character is not a special character or number
  if (validFirstChercterAlsoNuber(value)) {
    console.warn('Invalid input: Special characters or numbers are not allowed at the start.');
    return;
  }

  // Trim spaces from the start
  const trimmedValue = value.replace(/^\s+/, ''); // Ensures only leading spaces are removed

  // Ensure the note is not empty or only spaces
  if (!trimmedValue) {
    console.warn('Note cannot be empty or contain only spaces.');
    return;
  }

  let finalValue = trimmedValue;

  // If the character limit is exceeded, simply slice without removing full words
  if (trimmedValue.length > characterLimit) {
    finalValue = trimmedValue.substring(0, characterLimit);
  }

  setNotes((prevNotes) => ({
    ...prevNotes,
    [name]: finalValue
  }));
};


  const clearOperational = () => {
    setNotes((prevNotes) => ({ ...prevNotes, operational: '' }));
    if (operationalRef.current) {
      operationalRef.current.focus();
    }
  };
  const clearbriefing = () => {
    // Clear the value in the state
    setNotes((prevNotes) => ({ ...prevNotes, briefing: '' }));

    // Wait for the state update to take effect, then reset rows
    setTimeout(() => {
      if (briefingRef.current) {
        briefingRef.current.rows = 2; // Reset rows after clearing the value
        briefingRef.current.focus(); // Optionally, focus on the textarea
      }
    }, 0);
  };
  const clearskitchen = () => {
    setNotes((prevNotes) => ({ ...prevNotes, kitchen: '' }));

    setTimeout(() => {
      if (kitchenRef.current) {
        kitchenRef.current.rows = 2; // Reset rows after clearing the value
        kitchenRef.current.focus(); // Optionally, focus on the textarea
      }
    }, 0);

  };
  const clearservice = () => {
    setNotes((prevNotes) => ({ ...prevNotes, service: '' }));
    setTimeout(() => {
      if (serviceRef.current) {
        serviceRef.current.rows = 2; // Reset rows after clearing the value
        serviceRef.current.focus(); // Optionally, focus on the textarea
      }
    }, 0);

  };
  const handleValueChange = (e) => {
    const { name, value } = e.target; // Get the field name and value
  
    // Allow clearing the input
    if (value === '') {
      setNotes((prevNotes) => ({
        ...prevNotes,
        [name]: value,
      }));
      return;
    }
  
    // Validate the input
    if (validFirstChercterAlsoNuber(value)) {
      console.warn('Invalid input: Special characters or numbers are not allowed at the start.');
      return;
    }
  
    // Trim spaces from the start of the value
    const trimmedValue = value.trimStart();
  
    // Ensure the note is not empty or only spaces
    if (!trimmedValue) {
      console.warn('Note cannot be empty or contain only spaces.');
      return;
    }
  
    // Update the state for the specific note
    // setNotes((prevNotes) => ({
    //   ...prevNotes,
    //   [name]: trimmedValue,
    // }));
    setNotes((prevNotes) => ({
      ...prevNotes,
      [name]: trimmedValue.length > characterLimit ? trimmedValue.substring(0, characterLimit) : trimmedValue
    }));
  };
  

  return (
    <div className="footer_area bg-blue-50 eodBackground">
      {/* Operational Notes */}
      {!operationalNote ?
        <div className='note_top_area' >
          <span className='notelables' onClick={() => setOperationalNote(!operationalNote)}>Operational notes </span>
          <span className='noteRightArrow' onClick={() => setOperationalNote(!operationalNote)}><MdKeyboardArrowRight size={22} /></span>
        </div>
        :
        <div className="operational_note allNotes">
          <p className="notelables" onClick={() => setOperationalNote(!operationalNote)}>Operational notes  <span className='noteRightArrow'><MdKeyboardArrowDown size={22} /></span></p>
          <p><span className={`charecteLeft forNote ${characterLimit - notes.operational.length === 0 ? "redColor" : ""}`} >(Characters left: {characterLimit - notes.operational.length})</span></p>
          <textarea
            className="youtnotesTextArea"
            name="operational"
            rows="6"
            placeholder="Add operational note"
            value={notes.operational}
            onChange={handleChange}
            ref={operationalRef}
          />
          {
            notes.operational !== '' &&
            <span
              className="removeFile"
              data-for="clearOperational"
              data-tip="Clear"
              onClick={clearOperational}
              style={{ top: "50%", right: '10px', width: '16px', height: '16px' }}
            />
          }
        </div>
      }

      {/* Briefing Notes */}

      {!briefing ?
        <div className='note_top_area' >
          <span className='notelables' onClick={() => setBriefing(!briefing)}>Briefing notes </span>
          <span className='noteRightArrow' onClick={() => setBriefing(!briefing)}><MdKeyboardArrowRight size={22} /></span>
        </div>
        :
        <div className="briefing_note allNotes">
          <p className="notelables" onClick={() => setBriefing(!briefing)}>
            Briefing notes
            <span className='noteRightArrow'>
              <MdKeyboardArrowDown size={22} />
            </span>
          </p>
          <p>
            <span
              className={`charecteLeft forNote ${characterLimit - notes.briefing.length <= 0 ? "redColor" : ""}`}
            >
              (Characters left: {characterLimit - notes.briefing.length})
            </span>
          </p>
          <textarea
            className="youtnotesTextArea"
            name="briefing"
            placeholder="Add briefing note"
            value={notes.briefing}
            onChange={handleValueChange}
            rows={notes.briefing ? Math.max(notes.briefing.split('\n').length, 2) : 2}
            onFocus={(e) => { e.target.rows = Math.max(notes.briefing.split('\n').length, 6); }}
            onBlur={(e) => {
              if (e.relatedTarget?.classList.contains('removeFile')) return;
              e.target.rows = notes.briefing ? Math.max(notes.briefing.split('\n').length, 2) : 2;
            }}
          />
          {notes.briefing !== '' && (
            <span
              className="removeFile"
              onClick={(e) => {
                e.preventDefault();
                clearbriefing();
              }}
              tabIndex={-1}
              style={{ top: "50%", right: '10px', width: '16px', height: '16px' }}
            />
          )}
        </div>

      }

      {/* Service Notes PM */}
      {!service ?
        <div className='note_top_area' >
          <span className='notelables' onClick={() => setService(!service)}>Service notes PM </span>
          <span className='noteRightArrow' onClick={() => setService(!service)}><MdKeyboardArrowRight size={22} /></span>
        </div>
        :
        <div className="service_note allNotes">

          <p className="notelables" onClick={() => setService(!service)}>Service notes PM  <span className='noteRightArrow'><MdKeyboardArrowDown size={22} /></span></p>
          <p><span className={`charecteLeft forNote ${characterLimit - notes.service.length === 0 ? "redColor" : ""}`}>(Characters left: {characterLimit - notes.service.length})</span></p>
          <textarea
            className="youtnotesTextArea"
            name="service"

            placeholder="Add service note PM"
            value={notes.service}
            // onChange={(e) => setNotes({ ...notes, service: e.target.value })}
            onChange={handleValueChange}
            ref={serviceRef}
            rows={notes.service ? Math.max(notes.service.split('\n').length, 2) : 2}
            onFocus={(e) => { e.target.rows = Math.max(notes.service.split('\n').length, 6); }}
            onBlur={(e) => {
              // Prevent blur if the clear button is clicked
              if (e.relatedTarget?.classList.contains('removeFile')) return;
              e.target.rows = notes.service ? Math.max(notes.service.split('\n').length, 2) : 2;
            }}
          />
          {
            notes.service !== '' &&
            <span
              className="removeFile"
              data-for="clearOperational"
              data-tip="Clear"
              onClick={(e) => {
                e.preventDefault(); // Ensure no default actions interfere
                clearservice();
              }}
              tabIndex={-1}
              style={{ top: "50%", right: '10px', width: '16px', height: '16px' }}
            />
          }
        </div>
      }

      {/* Kitchen Notes PM */}

      {!kitchen ?
        <div className='note_top_area' >
          <span className='notelables' onClick={() => setKitchen(!kitchen)}>Kitchen notes PM  </span>
          <span className='noteRightArrow' onClick={() => setKitchen(!kitchen)}><MdKeyboardArrowRight size={22} /></span>
        </div>
        :
        <div className="kitchen_note allNotes">
          <p className="notelables" onClick={() => setKitchen(!kitchen)}>Kitchen notes PM   <span className='noteRightArrow'><MdKeyboardArrowDown size={22} /></span></p>
          <p><span className={`charecteLeft forNote ${characterLimit - notes.kitchen.length === 0 ? "redColor" : ""}`}>(Characters left: {characterLimit - notes.kitchen.length})</span></p>
          <textarea
            className="youtnotesTextArea"
            name="kitchen"
            placeholder="Add kitchen note PM"
            value={notes.kitchen}
            ref={kitchenRef}
            // onChange={(e) => setNotes({ ...notes, kitchen: e.target.value })}
            onChange={handleValueChange}
            rows={notes.kitchen ? Math.max(notes.kitchen.split('\n').length, 2) : 2}
            onFocus={(e) => { e.target.rows = Math.max(notes.kitchen.split('\n').length, 6); }}
            onBlur={(e) => {
              // Prevent blur if the clear button is clicked
              if (e.relatedTarget?.classList.contains('removeFile')) return;
              e.target.rows = notes.kitchen ? Math.max(notes.kitchen.split('\n').length, 2) : 2;
            }}
          />
          {
            notes.kitchen !== '' &&
            <span
              className="removeFile"
              data-for="clearOperational"
              data-tip="Clear"
              onClick={(e) => {
                e.preventDefault(); // Ensure no default actions interfere
                clearskitchen();
              }}
              tabIndex={-1}
              style={{ top: "50%", right: '10px', width: '16px', height: '16px' }}
            />
          }
        </div>
      }
    </div>
  );
};

export default CreateReportFooter;