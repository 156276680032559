import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { USER_ROLE_ACCESS } from '../../../Utils/GraphqlMutaion';
import { FIND_ROLES } from '../../../Utils/GraphqlQueries';
import { useMutation, useQuery } from '@apollo/client';

const UpdateRolePopup = ({ setUpdateRolePopup, thisUser }) => {
    const [isSearchable] = useState(true);
    // const [isSearchable, setIsSearchable] = useState(true);
    const [selectedAccess, setSelectedAccess] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateReq, setUpdateReq] = useState(false);
    // const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
    // const accessOptions = []
    const { data: rolesData } = useQuery(FIND_ROLES, { variables: { company_id: thisUser.company_id }, fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (rolesData) {
            setRoles(rolesData.roles.data)
        }
    }, [rolesData])

    useEffect(() => {
        // console.log('rolesData 25', roles, thisUser);
        if (roles.length > 0) {
            let rn = []
            let an = []
            
            for(let i=0; i<roles.length; i++){
                rn.push({value: roles[i].role_title, label: roles[i].role_title})
                for(let j=0; j<roles[i].role_access.length; j++){
                    an.push({value: roles[i].role_access[j], label: roles[i].role_access[j]})
                }
                if(thisUser.role === roles[i].role_title){
                    changeUpdateAccess(roles[i])
                }
            }
            setRoleList(rn)
            setAccessList(an)
        }
    }, [roles, thisUser]);


    const changeUpdateAccess = (oneRole) =>{
        // console.log(45, oneRole, thisUser.access)
        let sa = []
        debugger
        setSelectedRole({value: oneRole.role_title, label: oneRole.role_title, data: oneRole})
        if(Array.isArray(thisUser.access) && thisUser.access.length>0 && updateReq === false){
            for(let j=0; j<thisUser.access.length; j++){
                sa.push({value: thisUser.access[j], label: thisUser.access[j], data: thisUser.access})
            }
        }else{
            for(let j=0; j<oneRole.role_access.length; j++){
                sa.push({value: oneRole.role_access[j], label: oneRole.role_access[j], data: oneRole})
            }
        }
        setSelectedAccess(sa)
    };

    const onChangeUpdateAccess = (r) =>{
        // console.log(55, r)
        for(let i=0; i<roles.length; i++){
            if(r === roles[i].role_title){
                changeUpdateAccess(roles[i])
                setUpdateReq(true)
            }
        }
    }

    const isUpdateButtonActive = selectedRole !== null && selectedAccess.length > 0;

    const [update] = useMutation(USER_ROLE_ACCESS);

    const handleUserAccessUpdate = async () => {
        setUpdateRolePopup(false)
        // console.log(52, thisUser)
        setLoading(true);
        try {
            const { data: userRoleAccessData } = await update({
                variables: {id: thisUser.id, role: selectedRole.value, access: selectedAccess ? selectedAccess.map((t) => t.value) : []}
            });
            const { status, data } = userRoleAccessData.update_user_access;
            if (status) {
                // console.log(62, data)
                
                // setRoles((prevRoles) => [...prevRoles.map((e)=> e.role_id === data.role_id ? {...data} : e)]);
                // setThisRole({...data})
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="backwrap">
            <div className="createUserModal">
                <div className="createUserModalHead">
                    <h4 className="popupTitle">Role Update</h4>
                    <span className="closeModal" onClick={() => setUpdateRolePopup(false)}></span>
                </div>
                <div className="createUserModalBody !pb-2">
                    <div className="createUserFormContainer">
                        <form className="createUserFrom">
                            {/* <div className="cu_inputGroup !mb-0 mt-0">
                                <label>Select Role<span className="red_star">*</span></label>
                                <input
                                    type="text"
                                    className="createUser_email"
                                    placeholder="Select specific role"
                                />
                            </div> */}

                            <div className="cu_inputGroup !mb-0 cu_inputGroup_select mt-6">
                                <label>Select Role<span className="red_star">*</span></label>
                                <Select
                                    className="select-ecosystem"
                                    value={selectedRole}
                                    onChange={(e)=>onChangeUpdateAccess(e.value)}
                                    options={roleList}
                                    menuPlacement="top"
                                    placeholder="Select specific role"
                                />
                            </div>

                            <div className="cu_inputGroup !mb-0 cu_inputGroup_select mt-6">
                                <label>Customize role access<span className="red_star">*</span></label>
                                <Select
                                    className="select-ecosystem"
                                    value={selectedAccess}
                                    onChange={setSelectedAccess}
                                    options={accessList}
                                    isSearchable={isSearchable}
                                    isMulti
                                    menuPlacement="top"
                                    placeholder="Select specific access"
                                />
                            </div>

                            <div className="createUserModalFoot !px-0">
                                <button className="btnCancel" onClick={() => setUpdateRolePopup(false)}>
                                    Cancel
                                </button>
                                <button
                                    className={`btnAction ${loading ? 'btn_loader' : ''}`}
                                    onClick={() => handleUserAccessUpdate()}
                                    disabled={!isUpdateButtonActive}
                                >
                                    {loading ? 'Updating...' : 'Update Role'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateRolePopup;
