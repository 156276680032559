import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ACTIVE_DEVICE_LIST } from '../../Utils/GraphqlQueries';
import { logoutFromAll, UPDATE_USER_MUTATION } from '../../Utils/GraphqlMutaion';
import Moment from 'react-moment';
import { toast } from "react-hot-toast";

const SecuritySettings = ({userdata}) => {
    const deviceId = localStorage.getItem("xmpp_tokenid");
    const [securityActivity, setSecurityActivity] = useState([]);
    const [update] = useMutation(UPDATE_USER_MUTATION);
    const { data: common_res } = useQuery(ACTIVE_DEVICE_LIST, { fetchPolicy: 'no-cache' });
    const [logoutData] = useMutation(logoutFromAll);
    const [isOn, setIsOn] = useState(userdata.sso ? userdata.sso : false);

    useEffect(() => {
        if (common_res?.active_device_list?.status && common_res?.active_device_list?.data) {
            setSecurityActivity(common_res.active_device_list.data);
        }
    }, [common_res, securityActivity]);
    
    const [trustedDevice, setTrustedD] = useState(null)
	const TrastedDevice = (device) => {
		//console.log(494, device)
		setTrustedD(device)

	}
    const notTrustedDevice = (device)=>{
		//console.log(499, device)
		// removeOtherDevice({
		// 	...device,
        //     token: device.device_id, 
        //     device: 'web'
		// }).then((res) => {
		// 	//console.log(506, res)
		// 	if(res.status){
		// 		setActiveDevice(activeDevice.filter((e)=>e.device_id !== device.device_id))
		// 	}
		// 	// setActiveDevice(res.status && res.data.length ? res.data : [])
		// });
		setTrustedD(null)
	}

    const logoutallDevices = async ()=>{
		try{
			let APIres = await logoutData();
			toast.success('All devices logout succeeded.', {duration: 4000});
			//console.log(503,APIres)
		}catch(error){
			console.log(24);
		}
	}

    const handleToggle = async () => { 
		if(! isOn && securityActivity.length > 1)
			toast.error('Please logout from all other device, then enable this fetures.', {duration: 4000});
		else{
			try{
                console.log(59, userdata)
                let a = !isOn;
                await update({
                    variables: {
                        input: {
                            firstname: userdata.firstname,
                            lastname: userdata.lastname,
                            img: userdata.img,
                            sso: a,
                            user_id: userdata.id
                        }
                    }
                });
                setIsOn(a);
			}catch(e){
                debugger
				toast.error(e, {duration: 4000});
			}
		}
	};

    return (
        <>
            {trustedDevice &&
                <div className="backwrap">
                    <div className="userMiniModal ">
                        <div className="userMiniModalHeadSecurity">
                            <h5 className=""><Moment format="MMM Do, YYYY h:mm A">{trustedDevice.time}</Moment> <span className='newDevice'> New</span></h5>
                            <span className="closeModal" data-for="rightSection_tooltip" data-tip="Close" onClick={() => setTrustedD(null)}></span>
                        </div>
                        <div className="security_body" >

                            <div className="body1">
                                New Sign-in on {trustedDevice.os_name}
                            </div>

                            <div className='HasColor'>
                                Your Account is at risk if this wasn't you!
                            </div>

                            <div className="deviceInfo">
                                <img className='deviceInfo1' src="/media/images/loginBefore/freeliLaptop.png" alt='freeliLaptop1.png' />


                                <div className='deviceInfo2'>
                                    <div style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                        {trustedDevice.os_name}
                                    </div>
                                    <div className='HasColor'>
                                        <p className='HasColor' style={{ marginBottom: '3px' }}>{trustedDevice.source}</p>
                                        <p className='HasColor'>{trustedDevice.city}, {trustedDevice.countryName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="HasColor">
                                Do you recognize this activity?
                            </div>

                            
                            <div className="ButtonGroup">
                                <div className="NotSecure" onClick={()=>notTrustedDevice(trustedDevice)}>
                                    <span className="noIcon"></span>Not secure (Logout)
                                </div>
                                <div className="YesSecure" onClick={() => setTrustedD(null)}>
                                    <span className="yesIcon"></span> Yes, it's me
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
			<div className="userProfileArea">
                <div className="security">
                    <div className="security_head">
                        Active device lists
                        {securityActivity.length > 1 ? 
                            <span onClick={logoutallDevices} style={{float: 'right', fontSize: '15px', cursor: 'pointer'}}>Logout from all device</span> : ''}
                    </div>
                    <table>
                        {securityActivity.map((item) => (
                            <tr key={item._id} onClick={() => TrastedDevice(item)}>
                                <td style={{width: "30%"}}>{item.ua_client} ({item.os_name}) {item.device_id === deviceId ? '(Active)' : ''}</td>
                                <td style={{width: "50%"}}><Moment format="MMM Do, YYYY h:mm A">{item.time}</Moment>, {item.countryName}</td>
                                <td style={{width: '5%', paddingRight:'20px'}}>
                                    <i className="fa fa-chevron-right custom_icon open}"></i>
                                </td>
                            </tr>
                        ))}
                    </table>
                    <div className='review_onOff' style={{ padding:'10px 0px' }}>
                        <div className="toggle-button-cover" style={{ left: '0px' }}>
                            <input className='cm-toggle'
                            checked={isOn}
                            onChange={handleToggle}
                            style={{float: 'left'}}
                            type='checkbox' /> <label style={{position: 'absolute', left: '85px', top: '3px', color: '#000'}} className="inputLabel" > Single sign-on (SSO) enable?</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecuritySettings;
