import React from 'react'
import TaskCard from './TaskCard'

const CalendarEvents = () => {
  return (
    <div className='cal_events_container'>
      <div>
        <p>Upcoming Events</p>
      </div>
      <div>
        <TaskCard />
        <TaskCard />
        <TaskCard />
        <TaskCard />
        <TaskCard />
        <TaskCard />
        <TaskCard />
      </div>
    </div>
  )
}

export default CalendarEvents