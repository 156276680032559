/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { GET_ME } from '../../Utils/GraphqlQueries';
import { useNavigate } from "react-router-dom"
import { GET_TAGS } from '../../Utils/GraphqlQueries';
import { useQuery, useApolloClient, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { setPopup } from '../../redux/message';
import { CREATE_UPDATE_TAG, TAG_DELETE, TAG_INTO_FILE } from '../../Utils/GraphqlMutaion';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';

export const TagPopup = ({ type, viewType, tag_list, setTag_popup, upload_type = '', ...otherProps }) => {
    const dispatch = useDispatch()
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const Navigate = useNavigate();
    const action_file = useSelector(state => state.message.action_file)
    const [changesMade, setChangesMade] = useState(false);
    const [tab_state, setTab_state] = useState('public');
    const [src_string, setSrc_string] = useState('');

    const [all_tags, setAll_tags] = useState([]);
    const [task_tag, set_task_tag] = useState([]);
    const [miniList, setMiniList] = useState([]);
    const [selected_ids, setSelected_ids] = useState([]);

    const { loading: allTagsLoading } = useQuery(GET_TAGS, {
        variables: { company_id: cacheMe.me.company_id, upload_type: upload_type },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res) {
                let tags = [...res.tags.private, ...res.tags.public, ...res.tags.task_tag]
                let taskTag = otherProps.from === '#TASK' ? res.tags.task_tag.filter(f => f.title === otherProps.from) : res.tags.task_tag;
                setAll_tags(tags)
                set_task_tag(taskTag)
                if (tag_list.length > 0) {
                    setMiniList(tags.filter(v => tag_list.indexOf(v.tag_id) > -1))
                    setSelected_ids(tag_list)
                }
                if (type === 'file') {
                    let old_tag = action_file?.tag_list || []
                    let taskTagIds = taskTag.map(m => m.tag_id);
                    if (old_tag.filter(f => taskTagIds.indexOf(f) > -1).length > 0) {
                        setMiniList([...tags.filter(f => old_tag.indexOf(f.tag_id) > -1)]);
                        setSelected_ids([...old_tag])
                    } else {
                        setMiniList([...taskTag, ...tags.filter(f => old_tag.indexOf(f.tag_id) > -1)]);
                        setSelected_ids([...taskTagIds, ...old_tag])
                    }
                }
            }
        }
    });

    const removeFromMini = (item) => {
        setSelected_ids(selected_ids.filter(f => f !== item.tag_id))
        setMiniList(miniList.filter(f => f.tag_id !== item.tag_id))
        setChangesMade(true);
    }

    const minidiv = miniList.map((item, i) =>
        <div className={'miniItem'} style={{ backgroundColor: item.tag_color }} key={'_mini_' + i}>
            <div className={'miniItem_name'}>{item.title}</div>
            {(item.title !== '#TASK-COST' && item.title !== '#TASK') &&
                <div className={'miniItem_remove'} onClick={() => removeFromMini(item)}></div>
            }
        </div>
    )


    const [hoveredElements, setHoveredElements] = useState({});
    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    const select_tag = (tag) => {
        setMiniList([...miniList, tag]);
        setSelected_ids([...selected_ids, tag.tag_id])
        setChangesMade(true);
    }

    const [tag_into_file] = useMutation(TAG_INTO_FILE)

    const removeKey = (arry, keyToRemove) => {
        return arry.map(obj => {
            const { [keyToRemove]: _, ...rest } = obj; // Destructure the object, excluding the specified key
            return rest; // Return the modified object without the specified key
        });
    };

    const update_file_tag = () => {
        try {
            let old_tag = action_file?.tag_list || []
            let a_ids = selected_ids.filter((i) => old_tag.indexOf(i) === -1);
            let r_ids = old_tag.filter((i) => selected_ids.indexOf(i) === -1);
            let a_data = all_tags.filter((i) => selected_ids.indexOf(i.tag_id) > -1 && old_tag.indexOf(i.tag_id) === -1);
            let r_data = all_tags.filter((i) => selected_ids.indexOf(i.tag_id) === -1 && old_tag.indexOf(i.tag_id) > -1);

            tag_into_file({
                variables: {
                    input: {
                        conversation_id: action_file?.conversation_id,
                        msg_id: action_file?.msg_id,
                        file_id: action_file?.id,
                        is_reply: action_file.main_msg_id ? 'yes' : 'no',
                        participants: action_file?.participants,
                        newtag: a_ids,
                        removetag: r_ids,
                        newtag_tag_data: removeKey(a_data, '__typename'),
                        removetag_tag_data: removeKey(r_data, '__typename'),
                    }
                }
            })
            dispatch(setPopup({ data: false, key: 'tag_popup' }));
        } catch (error) {
            console.log("🚀 ~ TagPopup ~ error:", error)
        }
        setChangesMade(true);
    }

    const submit_tag = async () => {
        switch (type) {
            case 'file':
                update_file_tag()
                break;
            case 'create_room':
                otherProps.setTag_list(miniList)
                Navigate(-1)
                break;
            case 'update_room':
                otherProps.setTag_list(miniList)
                Navigate(-1)
                break;
            default:
                break;
        }
    }

    const [popupCloseType, setPopupCloseType] = useState(true);

    const close_popup = () => {
        setPopupCloseType(true);
        switch (type) {
            case 'file':
                // setPopupCloseType(true);
                dispatch(setPopup({ data: false, key: 'tag_popup' }));
                break;
            case 'tag':
                setPopupCloseType(true);
                dispatch(setPopup({ data: false, key: 'tag_popup' }));
                break;
            case 'update_room':
                setPopupCloseType(true);
                Navigate(-1);
                break;
            case 'file_upload':
                setTag_popup(false);
                Navigate(-1);
                break;
            case 'private_msg':
                dispatch(setPopup({ data: false, key: 'private_msg' }))
                break;
            case 'create_room':
                Navigate(-1)
                break;
            default:
                setPopupCloseType(true);
                break;
        }
    }

    const [tagEditInput, setTagEditInput] = useState('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape' && popupCloseType) {
                close_popup();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const [create_update] = useMutation(CREATE_UPDATE_TAG)
    const [edit_title, setEdit_title] = useState('');

    const updateTagTitle = async (e, d) => {
        if (!d) {
            try {
                let data = {
                    title: src_string.toUpperCase(),
                    tag_id: '',
                    tagged_by: cacheMe.me.id,
                    tag_type: "private",
                    tag_color: "#e82a87",
                    team_list: []
                }

                let apires = await create_update({ variables: { input: data } });

                setSrc_string('')
                setAll_tags([apires.data.create_update_tag.tag, ...all_tags])

            } catch (error) {
                console.log("🚀 ~ updateTagTitle ~ error:", error)
            }
        } else {

            if (e.keyCode === 13 && edit_title.trim() !== '') {
                try {
                    let data = {
                        title: edit_title.trim().toUpperCase(),
                        tag_id: d.tag_id,
                        tagged_by: d.tagged_by,
                        tag_type: d.tag_type,
                        tag_color: d.tag_color,
                        team_list: d.team_list
                    }
                    let apires = await create_update({ variables: { input: data } });
                    if (apires?.data?.create_update_tag?.status) {
                        toast.success(apires?.data?.create_update_tag?.message)
                        setAll_tags(all_tags.map(v => v.tag_id === d.tag_id ? { ...apires?.data?.create_update_tag.tag } : v))
                        setTagEditInput('')
                    } else {
                        toast.error(apires?.data?.create_update_tag?.message)
                    }
                } catch (error) {
                    console.log("🚀 ~ updateTagTitle ~ error:", error)
                }
            }
        }
    }

    const [delete_TAG] = useMutation(TAG_DELETE)

    const delete_confirm = async (id) => {
        let apires = await delete_TAG({
            variables: {
                input: {
                    tag_id: id
                }
            }
        })

        if (apires.data.tag_delete.status) {
            setAll_tags(prev => prev.filter(f => f.tag_id !== id))
            toast.success(apires.data.tag_delete.message, { duration: 4000 });
        } else {
            toast.error(apires.data.tag_delete.message, { duration: 4000 });
        }
    }


    return (
        <div className='backwrap'>
            <Toaster />
            <div className='tagModal'>
                <div className="tagModalHead">
                    <h4 className="popupTitle">{(type === 'file_upload' || type === 'file') ? 'Assign tag(s) to the file' : type === 'room_tag' ? 'Shared team tag(s)' : 'Manage tag(s)'}</h4>
                    <span onClick={close_popup} className="closeModal"></span>
                </div>
                <div className='tagModalBody'>
                    <ul className="tagTab">
                        {(viewType === 'public' || viewType === 'all') && <li className={`${tab_state === 'public' ? "active" : ""}`} onClick={() => setTab_state('public')}>Shared team tag(s)</li>}
                        {(viewType === 'public' || viewType === 'all') && <span
                            onMouseEnter={() => handleMouseHover('element1')}
                            onMouseLeave={() => handleMouseLeave('element1')}
                            className="tooltip2 user_Management_title" style={{ marginLeft: '3px', cursor: 'pointer' }}>
                            <span className="info_tooltip_new"></span>
                            {
                                hoveredElements['element1'] &&
                                <span className="tooltiptext2 teamtags" >
                                    <p>Team tags are created by Admin. Sort and search files by tags in each room or use the files dashboard to easily find documents across all rooms. Multiple team and personal tags can be used for any file.</p>
                                    <span className="tooltipClose"></span>
                                </span>
                            }
                        </span>}
                        {/* {(viewType === 'private' || viewType === 'all') && <li className={`${tab_state === 'private' ? "active" : ""}`} onClick={() => setTab_state('private')} style={{ marginLeft: '32px' }}>Personal tag(s)</li>} */}
                        {/* {(viewType === 'private' || viewType === 'all') && <span
                            onMouseEnter={() => handleMouseHover('element2')}
                            onMouseLeave={() => handleMouseLeave('element2')}
                            className="tooltip2 user_Management_title" style={{ marginLeft: '3px', cursor: 'pointer' }}>
                            <span className="info_tooltip_new"></span>
                            {
                                hoveredElements['element2'] &&
                                <span className="tooltiptext2 personal">
                                    <p>Personal Tags can help you manage your documents in your own way. They are unique and always remain private to each user.</p>
                                    <span className="tooltipClose"></span>
                                </span>
                            }
                        </span>} */}
                    </ul>

                    <div className="tagAddSearch public_tag">
                        <input className="participantsSearch" value={src_string} autoFocus={true} onChange={(e) => setSrc_string(e.target.value)} type="text" placeholder="Search tag" />
                    </div>
                    <div className="selectedParticipants tag_container_mini">{minidiv}</div>
                    <div className="tagContainer">
                        {allTagsLoading ? <div className="btn_loader !border-0 !h-[170px]"></div>
                            : all_tags.filter(f => f.title !== '#TASK-COST' && f.title !== '#TASK' && f.title !== 'UNTAGGED FILES')
                            .filter(f => f.title.toLowerCase().trim().indexOf(src_string.toLowerCase().trim()) > -1)
                            .filter(f => f.tag_type === tab_state && selected_ids.indexOf(f.tag_id) === -1).map((v, i) =>
                                <div className={`tagItem ${tagEditInput === v.tag_id && 'editable_tag'}`} style={{ borderLeft: "6px solid", borderLeftColor: v.tag_color }} key={'_tags_' + i}>
                                    {tagEditInput !== v.tag_id ?
                                        <p onClick={() => select_tag(v)} className={'tagTitle'}>{v.title}</p>
                                        :
                                        <input type="text" className='tag_edit_input' onChange={(e) => setEdit_title(e.target.value)} onKeyUp={(e) => updateTagTitle(e, v)} onBlur={() => { setEdit_title(''); setTagEditInput('') }} defaultValue={v.title} autoFocus />
                                    }
                                    {
                                        v.title !== "PERSONAL FILES" && tab_state !== 'public' &&
                                        <div className="tagHoverOptions">
                                            <span onClick={() => { setEdit_title(v.title); setTagEditInput(tagEditInput === v.tag_id ? null : v.tag_id) }} className="tagOpt editTag dark"></span>
                                            <span className="tagOpt deleteTag" onClick={() => delete_confirm(v.tag_id)}></span>
                                        </div>
                                    }
                                </div>
                            )}
                    </div>
                </div>
                <div className="tagModalFoot">
                    <button className={'btnCancel'} onClick={close_popup}>Cancel</button>
                    {tab_state === 'private' && src_string?.trim() !== '' && all_tags.filter(f => f.tag_type === tab_state && selected_ids.indexOf(f.tag_id) === -1)?.filter((e) => e.title.toUpperCase() === src_string?.trim().toUpperCase()).length === 0 ?
                        <button className={`btnAction`} onClick={updateTagTitle}>Create</button>
                        :
                        type === 'file_upload' || type === 'private_msg' ?
                            selected_ids.length > 0 ?
                                <button className={'btnAction'} onClick={() => otherProps.checkStatusUpload('upload', 'tag', selected_ids)} >Continue with tag(s)</button>
                                :
                                <button className={'btnAction'} onClick={() => otherProps.checkStatusUpload('skip', 'tag')} >Continue without tag(s)</button>
                            : changesMade &&
                            <button className={`btnAction hover:!bg-[#318FFF]`} onClick={() => submit_tag()}>
                                {type === 'file' && (tag_list.length > 0 || action_file?.tag_list?.length > 0) ? 'Update' : 'Add'}
                            </button>
                    }


                </div>
            </div>
        </div>
    );
};