import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { set_project_ids } from '../../redux/taskSlice';
import { isValidConvId } from '../../Utils/Common';

const LeftBarProject = ({ searchStr, setSearchStr }) => {
    const dispatch = useDispatch()
    const projects = useSelector(state => state.task.all_projects) || [];
    const project_ids = useSelector(state => state.task.project_ids) || [];
    const user = useSelector(state => state.message.user);
    const navigate = useNavigate();
    const filteredProjects = projects.filter((project) => {
        return (
            project.project_title &&
            project.project_title.toLowerCase().includes(searchStr.toLowerCase())
        );
    });

    const handleBack = () => {
        const last_conv = localStorage.getItem('last_active_conv')
        const resetState = () => {
            setSearchStr('');
        };
        resetState();
        let id = isValidConvId(last_conv) ? last_conv : user?.id
        navigate(`/connect/${id}`);
    };


    return (
        <div className='border-t border-t-[#475674]'>
            <div onClick={handleBack} className="bactToAllProject border-b border-b-[#475674] !px-[20px] font-bold thread_back text-[#BFDCFF] ">
                <span className="project_icon"></span>
                <span className='font-bold text-[#BFDCFF]'>Projects</span>
            </div>
            <div className='group_container mt-[10px] archiveLastChild projectListTask !h-[calc(100vh-480px)] !overflow-y-auto'>
                <Scrollbars
                    className="custom-scrollbars"
                    renderThumbVertical={({ style, ...props }) => (
                        <div className="track"
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: "#318fff",
                                borderRadius: "50px",
                            }}
                        />
                    )}
                    autoHide={false}
                >
                    {filteredProjects.length > 0 ? (
                        filteredProjects.map((v) => (
                            <li key={'_pro' + v._id} onClick={() => dispatch(set_project_ids([v._id]))} className={`conv_list !pl-[20px] ${project_ids?.[0] === v._id ? 'selected' : ''}`}>
                                <span className="project_icon"></span>
                                <span className="conv_list_title" title={v.project_title}>
                                    {v.project_title}
                                </span>
                            </li>
                        ))
                    ) : (
                        <p className="no-projects-message text-[#fff] text-center pt-10">No projects found.</p>
                    )}
                </Scrollbars>
            </div>
        </div>
    );
};

export default LeftBarProject;
