/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useEffect, useState } from 'react';
import { VscCalendar } from "react-icons/vsc";
import { BiReset } from "react-icons/bi";
import { BsArrowRight, BsXLg } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { useClickAway } from "@uidotdev/usehooks";
import TaskList from './TaskList';
import AdvanceSearchStatus from './AdvanceSearchStatus';
import AdvanceSearchProgress from './AdvanceSearchProgress';
import { TiExport } from "react-icons/ti";
import { useSelector } from 'react-redux';
import { useTaskContext } from '../TaskContext';
import { CSVLink } from "react-csv";
import { MdOutlineCancel } from 'react-icons/md';
import sortArrayByKey from '../../../hooks/useSortedArray';

const TaskListView = memo(() => {
    const { fetchByFilter, loading, pagination, set_filter, keywords, assign_to_list, tasks, users, setPagination, set_column_id } = useTaskContext()
    const conversations = useSelector(state => state.message.conversations)
    const [assignee_list, set_assignee_list] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedProgress, setSelectedProgress] = useState([]);
    const [task_title, set_task_title] = useState('')
    const [startDate, setStartDate] = useState(null); // State for the Start date
    const [endDate, setEndDate] = useState(null); // State for the end date
    // const [error2, setError2] = useState(false);
    const [button_status, set_button_status] = useState(false);

    const [showEnter, setShowEnter] = useState(false);
    const [showEnter2, setShowEnter2] = useState(false);

    const csv_headers = [
        { key: 'project_title', label: 'Project name' },
        { key: 'task_title', label: 'Task title' },
        { key: 'assign_to', label: 'Assigned to' },
        { key: 'start_date', label: 'Start date' },
        { key: 'end_date', label: 'Due date' },
        { key: 'status', label: 'Status' },
        { key: 'progress', label: 'Progress' },
        { key: 'forecasted_cost', label: 'Forecasted hours' },
        { key: 'actual_hours', label: 'Actual hours ' },
        { key: 'hours_variance', label: 'Hour variance ' },
        { key: 'forecasted_hours', label: 'Forecasted cost ' },
        { key: 'actual_cost', label: 'Actual cost' },
        { key: 'cost_variance', label: 'Cost variance' },
        { key: 'key_words', label: 'Keywords' },
        { key: 'priority', label: 'Priority' },
        { key: 'created_by', label: 'Created by' },
        { key: 'review', label: 'Review pending' },
        { key: 'observers', label: 'Observer' },
        { key: 'conversation_name', label: 'Room name' },
    ];

    const csv_data = [...tasks];


    useEffect(() => {
        if (assign_to_list) {
            let ass = users?.filter(f => assign_to_list.includes(f.id)).map(m => ({ ...m, name: m.firstname + ' ' + m.lastname }))
            set_assignee_list(ass)
        }
    }, [assign_to_list])

    const handleStartDateChange = (date) => {
        if (endDate && date > endDate) {
            // setError2(true);
        } else {
            setStartDate(date);
            // setError2(false);
        }
    };

    const handleEndDateChange = (date) => {
        if (startDate && date < startDate) {
            // setError2(true);
        } else {
            setEndDate(date);
            // setError2(false);
        }
    };


    // Room List select
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [roomListVisible, setRoomListVisible] = useState(false);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setRoomListVisible(true);
    };

    const handleSelectRoom = (room) => {
        if (!selectedRooms.includes(room.conversation_id)) {
            setSelectedRooms([...selectedRooms, room.conversation_id]);
        }
        setRoomListVisible(false);
    };

    const handleRemoveRoom = (id) => {
        setSelectedRooms(selectedRooms.filter((f) => f !== id));
    };

    // const filteredRoomData = roomData.filter((room) =>
    //     room.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    // Assign List select
    const [searchAssignQuery, setSearchAssignQuery] = useState('');
    const [selectedAssign, setSelectedAssign] = useState([]);
    const [assignListVisible, setAssignListVisible] = useState(false);

    const handleAssignSearch = (e) => {
        setSearchAssignQuery(e.target.value);
        setAssignListVisible(true);
    };

    const handleSelectAssign = (assign) => {
        setSelectedAssign(assign.id);
        setAssignListVisible(false);
    };

    const handleRemoveAssign = () => {
        setSelectedAssign([]);
    };

    // const filteredAssignData = assignData.filter((assign) =>
    //     assign.toLowerCase().includes(searchAssignQuery.toLowerCase())
    // );

    // keyWord List select
    const [searchKeyWordQuery, setSearchKeyWordQuery] = useState('');
    const [selectedKeyWord, setSelectedKeyWord] = useState([]);
    const [keyWordListVisible, setKeyWordListVisible] = useState(false);

    const handleKeyWordSearch = (e) => {
        setSearchKeyWordQuery(e.target.value);
        setKeyWordListVisible(true);
    };

    const handleSelectKeyWord = (keyword) => {
        if (!selectedKeyWord.includes(keyword)) {
            setSelectedKeyWord([...selectedKeyWord, keyword]);
        }
        setKeyWordListVisible(false);
    };

    const handleRemoveKeyWord = (keyword) => {
        setSelectedKeyWord(selectedKeyWord.filter((prev) => prev !== keyword));
    };

    // const filteredKeyWordData = keyWordData.filter((keyword) =>
    //     keyword.toLowerCase().includes(searchKeyWordQuery.toLowerCase())
    // );

    const ref2 = useClickAway(() => {
        setAssignListVisible(false)
    });
    const ref3 = useClickAway(() => {
        setRoomListVisible(false)
    });
    const ref4 = useClickAway(() => {
        setKeyWordListVisible(false)
    });

    useEffect(() => {
        let state = false
        if (selectedAssign?.length > 0) {
            state = true
        } else if (selectedKeyWord?.length > 0) {
            state = true
        } else if (selectedRooms?.length > 0) {
            state = true
        } else if (selectedStatus?.length > 0) {
            state = true
        } else if (selectedProgress?.length > 0) {
            state = true
        } else if (task_title.trim()?.length > 0) {
            state = true
        } else if (startDate && endDate) {
            state = true
        } else {
            state = false;
        }
        set_button_status(state)
    }, [selectedAssign, selectedKeyWord, selectedRooms, selectedStatus, selectedProgress, task_title, startDate, endDate])

    const searchFilter = () => {
        let state = []
        if (task_title?.trim()?.length > 0) {
            state = [...state, ['task_title', task_title]]
        }
        if (selectedAssign?.length > 0) {
            state = [...state, ['assignees', selectedAssign]]
        }
        if (selectedKeyWord?.length > 0) {
            state = [...state, ['keywords', ...selectedKeyWord]]
        }
        if (selectedRooms?.length > 0) {
            state = [...state, ['room', ...selectedRooms]]
        }
        if (selectedStatus?.length > 0) {
            state = [...state, ['status', ...selectedStatus]]
        }
        if (selectedProgress?.length > 0) {
            state = [...state, ['progress', ...selectedProgress]]
        }
        if (startDate && endDate) {
            state = [...state, ['dateRange', startDate, endDate]]
        }
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(state)
        fetchByFilter(state)
    }

    const handleKeyup = (e) => {
        if (e.key === 'Enter') {
            searchFilter()
        }
    }

    const clearFilter = () => {
        setSelectedProgress([])
        setSelectedStatus([])
        setSelectedKeyWord([])
        setSelectedAssign([])
        setSelectedRooms([])
        set_task_title('')
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter([])
        fetchByFilter([])
        setStartDate(null)
        setEndDate(null)
    }

    const [advancedSearchExpand, setAdvancedSearchExpand] = useState(false);
    const filtered_assignee = assignee_list?.filter(f => f.name?.toLowerCase()?.includes(searchAssignQuery?.toLowerCase()))?.filter(f => !selectedAssign.includes(f.id))
    const sorted_assignee = sortArrayByKey(filtered_assignee, 'name', 'asc');
    const filtered_room = conversations?.filter(f => f.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()))?.filter(f => !selectedRooms.includes(f.conversation_id))
    const sorted_room = sortArrayByKey(filtered_room, 'title', 'asc');
    const filtered_keyword = keywords?.filter(f => f.keywords_title?.toLowerCase()?.includes(searchKeyWordQuery?.toLowerCase()))?.filter(f => !selectedKeyWord.includes(f.keywords_title))
    const sorted_keyword = sortArrayByKey(filtered_keyword, 'keywords_title', 'asc');

    return (
        <div className='right_container1 tagFileArea advancedFilePanel' style={{ height: 'calc(100% - 117px)', width: '100%' }}>
            <div className="task_fulsearch_area">
                {loading && pagination.page === 1 &&
                    <div className='h-full w-full flex items-center justify-center absolute left-0 top-0 z-[12] bg-white/90'>
                        <div className="loaderMain mini_loader"></div>
                    </div>
                }
                <div className="task_fulsearch_left !w-[83%]">
                    <div className="task_basicSearch_area">
                        <div className="task_advance_button" onClick={() => setAdvancedSearchExpand(!advancedSearchExpand)}>
                            <div className="task_filterIcon"></div>
                            <div className="task_advance_text" data-for="taskList_tooltip" data-tip="Click to advanced search" currentitem="false">Advanced</div>
                            <div className={`${advancedSearchExpand ? 'task_downArrowIcon up' : 'task_downArrowIcon'}`}></div>
                        </div>
                        <div className="task_input_area">
                            <div className="searchAndFilterNormal" style={{ position: "relative", width: "100%", borderBottom: "unset" }}>
                                <input id="search_for_a_tag" className="!w-[100%] _inputSearchBar searchMargin" value={task_title} onChange={(e) => set_task_title(e.target.value)} onKeyUp={handleKeyup} type="text" placeholder="Search for a task" style={{ marginLeft: "0px", marginRight: "2px" }} autoFocus />
                                {task_title && <MdOutlineCancel onClick={() => set_task_title("")} className="text-red-500 text-xl cursor-pointer absolute right-12 top-2" />
                                }

                                <div className="srcBtnSection !top-[1px] " data-for="top_head" data-tip="Search" style={{ right: "2px", height: "30px" }}></div>
                            </div>
                        </div>
                    </div>
                    {
                        advancedSearchExpand && <div className="task_advacedSearch_area">
                            <div className="first_row" style={{ paddingLeft: "45px" }}>
                                <div className="Advance_search_top_Task newclassName" style={{ paddingRight: "30px", paddingLeft: "0px", flexWrap: "nowrap", gap: "20px", borderBottom: "unset" }}>
                                    <div className="col1" style={{ width: "36%" }}>
                                        <div className="row1" style={{ paddingRight: "0px", marginLeft: "0px" }}>
                                            <span className="calenderIcon_task">
                                                <VscCalendar size={22} />
                                            </span>
                                            <div onMouseEnter={() => setShowEnter(true)} onMouseLeave={() => setShowEnter(false)} className="fromDate_One_task taskListAdvance col1_row2" style={{ width: "100%" }}>
                                                <DatePicker
                                                    className="start_Date_task Focus"
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="MMMM dd, yyyy"
                                                    placeholderText="Due date"
                                                    maxDate={endDate}
                                                />
                                                {showEnter &&
                                                    <> {
                                                        startDate === null ?
                                                            "" :
                                                            <span style={{ right: '15px', top: '8px', display: 'block' }} onClick={() => setStartDate(null)} className='removeFile' ></span>
                                                    }
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col1" style={{ width: "36%" }}>
                                        <div className="row1" style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                                            <span className="calenderIcon_task">
                                                <VscCalendar size={22} />
                                            </span>
                                            <div className="fromDate_One_task taskListAdvance col1_row2">
                                                <div onMouseEnter={() => setShowEnter2(true)} onMouseLeave={() => setShowEnter2(false)} className="react-datepicker-wrapper">
                                                    <DatePicker
                                                        className="start_Date_task Focus"
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        dateFormat="MMMM dd, yyyy"
                                                        placeholderText="Due date"
                                                        minDate={startDate}
                                                    />
                                                    {showEnter2 ?
                                                        <> {
                                                            endDate === null ?
                                                                "" :
                                                                <span style={{ right: '15px', top: '8px', display: 'block' }} onClick={() => setEndDate(null)} className='removeFile' ></span>
                                                        }
                                                        </> :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <AdvanceSearchStatus selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
                                    <AdvanceSearchProgress selectedProgress={selectedProgress} setSelectedProgress={setSelectedProgress} />
                                </div>
                            </div>
                            <div className="secound_row">
                                <div className="search_item leftSide" style={{ position: 'relative' }}>
                                    <div className='searchAndFilter !p-0'>
                                        <div ref={ref2}>
                                            <input
                                                className="_inputBar searchLeft"
                                                type="text"
                                                placeholder="Assigned to"
                                                value={searchAssignQuery}
                                                onClick={() => setAssignListVisible(true)}
                                                onChange={handleAssignSearch}
                                            />
                                            <div style={{ right: '1px', height: '31px', top: '5px', }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                                            {assignListVisible && (
                                                <div className="tag_room_list">
                                                    {assignee_list?.length === 0 ? <div className="tagNotFound">Not found</div>
                                                        :
                                                        sorted_assignee.map((assign, index) => (
                                                            <div className='' key={index} onClick={() => handleSelectAssign(assign)}>
                                                                <p className="_tag_rooms">{assign.name}</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {selectedAssign?.length > 0 &&
                                        assignee_list.filter(f => selectedAssign.includes(f.id)).map((v, i) =>
                                            <div className="selectedRoomCont" key={'ass_' + i}>
                                                <span className='tags_Color items-center'>{v.name}
                                                    <BsXLg className='TaskSerachListSelected' onClick={() => handleRemoveAssign(v.id)} />
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="search_item rightSide" style={{ position: "relative" }}>
                                    <div className='searchAndFilter !p-0'>
                                        <div ref={ref3}>
                                            <input
                                                className="_inputBar searchLeft"
                                                type="text"
                                                placeholder="Search room"
                                                value={searchQuery}
                                                onClick={() => setRoomListVisible(true)}
                                                onChange={handleSearch}
                                            />
                                            <div style={{ right: '1px', height: '31px', top: '5px', }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                                            {roomListVisible && (
                                                <div className="tag_room_list">
                                                    {conversations?.length === 0 ? <div className="tagNotFound">Not found</div> :
                                                        sorted_room.map((room, index) => (
                                                            <div className='' key={index} onClick={() => handleSelectRoom(room)}>
                                                                <p className="_tag_rooms">{room.title}</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="selectedRoomCont">
                                        {conversations.filter(f => selectedRooms.includes(f.conversation_id)).map((room, index) => (
                                            <span key={index} className='tags_Color items-center'>{room.title}
                                                <BsXLg className='TaskSerachListSelected' onClick={() => handleRemoveRoom(room.conversation_id)} />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="third_row" style={{ paddingTop: "0px" }}>
                                <div className="searchAndFilterBar LeftSide" style={{ position: "relative", paddingLeft: "10px", paddingTop: "0px" }}>
                                    <div className='searchAndFilter'>
                                        <div ref={ref4}>
                                            <input
                                                className="_inputBar searchLeft"
                                                type="text"
                                                placeholder="Search keyword"
                                                value={searchKeyWordQuery}
                                                onClick={() => setKeyWordListVisible(true)}
                                                onChange={handleKeyWordSearch}
                                            />
                                            <div style={{
                                                right: '31px',
                                                height: '31px',
                                                top: '17px',
                                            }} className="srcBtnSection" data-for="top_head" data-tip="Search" ></div>
                                            {keyWordListVisible && (
                                                <div className="tag_room_list">
                                                    {keywords?.length === 0 ? <div className="tagNotFound">Not found</div>
                                                        :
                                                        sorted_keyword.map((keyword, index) => (
                                                            <div className='' key={index} onClick={() => handleSelectKeyWord(keyword.keywords_title)}>
                                                                <p className="_tag_rooms">{keyword.keywords_title}</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="selectedRoomCont">
                                        {selectedKeyWord.map((keyword, index) => (
                                            <span key={index} className='tags_Color items-center'>{keyword}
                                                <BsXLg className='TaskSerachListSelected' onClick={() => handleRemoveKeyWord(keyword)} />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <TaskList advancedSearchExpand={advancedSearchExpand} csv_data={csv_data} csv_headers={csv_headers} />

                </div>
                <div className="task_fulsearch_right !w-[17%]">
                    <div className="buttonList !p-3 !pb-0">
                        <div className={`buttonsDesign !h-[34px] reset_button ${button_status ? '' : 'dissableReset'}`} onClick={clearFilter}>
                            <span className="goIconArea flex items-center !justify-center">
                                <span className="goText" style={{ position: "relative", lineHeight: "34px" }}>Clear</span>
                                <BiReset className="arrow_go_icon" size={20} />
                            </span>
                        </div>
                        <div className={`buttonsDesign !h-[34px] go_button  ${button_status ? '' : 'dissableReset'} `} onClick={searchFilter}>
                            <span className="goIconArea flex items-cente !justify-center">
                                <span className="goText" style={{ position: "relative", lineHeight: "34px" }}>Search</span>
                                <BsArrowRight className="arrow_go_icon" size={20} />
                            </span>
                        </div>
                        <div className="buttonsDesign !h-[34px] go_button">
                            <CSVLink data={csv_data} headers={csv_headers} filename={"task-list.csv"}>
                                <span className="goIconArea flex items-center !justify-center">
                                    <span className="goText" style={{ position: "relative", lineHeight: "34px" }}>Export</span>
                                    <TiExport className="arrow_go_icon" size={20} />
                                </span>
                            </CSVLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default TaskListView;