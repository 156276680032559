
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import range from "lodash/range";
import { getMonth, getYear, parseISO } from 'date-fns';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';


const TaskStartEndDate = () => {
    const { selectedTask, OnUpdateTask, isObserver } = useTaskContext()
    const [todayDate, setTodayDate] = useState(new Date());
    // Parse dates from the context if they are strings

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setStartDate(selectedTask?.start_date ? parseISO(selectedTask?.start_date) : null);
        setEndDate(selectedTask?.end_date ? parseISO(selectedTask?.end_date) : null);
    }, [selectedTask])

    const [error1] = useState(false);
    const [error2] = useState(false);
    const [showEnter, setShowEnter] = useState(false);
    const [showEnter2, setShowEnter2] = useState(false);

    const years = range(2000, getYear(new Date()) + 10, 1);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return (
        <>
            <div className='date_area'>
                <p className='date_label'>Start date</p>
                <div onMouseEnter={() => setShowEnter(true)} onMouseLeave={() => setShowEnter(false)} className={`date_area_div taskStartEndDiv ${isObserver() && 'pointer-events-none'}`}>
                    <DatePicker
                        id="startDate"
                        className={error1 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}
                        dateFormat="MMMM dd, yyyy"
                        placeholderText="Start date"
                        maxDate={endDate}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className="CalendarDiv">
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                <div >
                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                            </div>
                        )}
                        selected={startDate}
                        selectsStart
                        onChange={(date) => {
                            OnUpdateTask({ start_date: date?.toISOString() || null, save_type: 'startdate' });
                            setStartDate(date);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    {showEnter ?
                        <> {
                            startDate === null ?
                                <span style={{ top: '24px', right: '16px' }} className='newCalenderIcon' ></span>
                                :
                                <span style={{ right: '14px', top: '35px', display: 'block' }} onClick={() => { OnUpdateTask({ start_date: null, save_type: 'startdate' }); setStartDate(null) }} className='removeFile' ></span>
                        }
                        </> :
                        <span style={{ top: '24px', right: '16px' }} className='newCalenderIcon' ></span>
                    }
                </div>
            </div>

            <div className='date_area'>
                <p className='date_label'>Due date</p>
                <div onMouseEnter={() => setShowEnter2(true)} onMouseLeave={() => setShowEnter2(false)} className={`date_area_div ${isObserver() && 'pointer-events-none'}`}>

                    <DatePicker
                        id="dueDate"
                        className={`${ endDate < todayDate && '!text-red-600'} ${error2 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}`}
                        dateFormat="MMMM dd, yyyy"
                        placeholderText="Due date"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className="CalendarDiv">
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                <div >
                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>

                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                            </div>
                        )}
                        minDate={startDate}
                        selected={endDate}
                        selectsEnd
                        onChange={(date) => {
                            OnUpdateTask({ end_date: date?.toISOString() || null, save_type: 'duedate' });
                            setEndDate(date);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                    />

                    {showEnter2 ?
                        <> {
                            endDate === null ?
                                <span style={{ top: '24px', right: '16px' }} className='newCalenderIcon' ></span>
                                :
                                <span style={{ right: '14px', top: '35px', display: 'block' }} onClick={() => { OnUpdateTask({ end_date: null, save_type: 'duedate' }); setEndDate(null) }} className='removeFile' ></span>
                        }
                        </> :
                        <span style={{ top: '24px', right: '16px' }} className='newCalenderIcon' ></span>
                    }
                </div>
            </div>
            <Tooltip delayShow={1000} anchorSelect="#startDate" plate="top" content="Click to update start date"></Tooltip>
            <Tooltip delayShow={1000} anchorSelect="#dueDate" plate="top" content="Click to update due date"></Tooltip>
        </>
    );
};

export default TaskStartEndDate;