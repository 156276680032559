import React, { useState } from 'react';
import Discussion from './Discussion';
import FooterFileList from './FooterFileList';
import { MdOutlineCancel } from 'react-icons/md';
import { MdSearch } from "react-icons/md";

const ActivityAndFilesFooter = () => {
    const [footerTab, setFooterTab] = useState("activity");
    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    return (
        <div className="toggleHideClick ml-5 mt-6 cursor-pointer">
            <div className='flex justify-between'>
                <div className="flex gap-3 items-center">
                    <div className="writeNoteTab addTab flex gap-1 items-center">
                        <p onClick={() => setFooterTab("activity")} className={`fileTab addTab text-[#0b1f47] text-base hover:text-[#e91e63] ${footerTab === 'activity' ? 'border-b-[4px] border-b-[#0b1f47] hover:border-b-[#e91e63] font-bold' : ''} `}>Activity/Conversations</p>
                    </div>
                    <div onClick={() => setFooterTab("files")} className={`fileTab addTab text-[#0b1f47] text-base ml-3 hover:text-[#e91e63] ${footerTab === 'files' ? 'border-b-[4px] border-b-[#0b1f47] hover:border-b-[#e91e63] font-bold' : ''} `} >Files(0)</div>
                </div>
                {
                    footerTab === 'files' &&
                    <div className="searchareaDiv mr-4 relative w-56">
                        <input
                            className="_inputBar searchLeft"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleChange}
                        />
                        {searchTerm ?
                            <MdOutlineCancel
                                className="absolute text-red-600 right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                size={20}
                                onClick={handleClearSearch}
                                title="Clear search"
                            /> :
                            <MdSearch
                                className="absolute text-[#0B1F47] right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                size={20}
                                title="Clear search"
                            />
                        }
                    </div>
                }
            </div>
            {
                footerTab === 'activity' ?
                    <Discussion /> : <FooterFileList />
            }
        </div>
    );
};

export default ActivityAndFilesFooter;