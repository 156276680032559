import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import ModuleDetails from './ModuleDetails';
import { useClickAway } from '@uidotdev/usehooks';
import CreateEditModule from './CreateEditModule';
import WarningModule from './WarningModule';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FIND_MODULES, GET_ME } from '../../../Utils/GraphqlQueries';
import { MODULE_ADD } from '../../../Utils/GraphqlMutaion';

const demoModules = [];

const ModuleManagementHome = () => {
    const [createPopup, setCreatePopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [modules, setModules] = useState(demoModules);
    const [selectedModule, setSelectedModule] = useState(null);
    const [moduleDetailsPopup, setModuleDetailsPopup] = useState(false);
    const [deleteModulePopup, setDeleteModulePopup] = useState(false);
    const [moduleToDelete, setModuleToDelete] = useState(null);
    const [openOptions, setOpenOptions] = useState(null);
    const [editModule, setEditModule] = useState(null); // Added state for managing module edit
    const [sortKey, setSortKey] = useState('module_title');
    const [sortOrder, setSortOrder] = useState('asc');
    const client = useApolloClient();
    // const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const cacheMe = client.readQuery({ query: GET_ME });
    const navigate = useNavigate();

    const [update] = useMutation(MODULE_ADD);

    const { loading: modulesLoading, data: modulesData } = useQuery(FIND_MODULES, { variables: { company_id: cacheMe.me.company_id }, fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (modulesData) {
            // console.log('modulesData', modulesData.modules.data.length);
            setModules(modulesData.modules.data)
        }
    }, [modulesData])

    useEffect(()=>{
        // console.log(39,modules)
    }, [modules])

    const handleDeleteModule = async (module) => {
        debugger
        setModuleToDelete(module);
        setLoader(true);
        // try {
        //     const { data: moduleAddData } = await update({
        //         variables: {company_id: cacheMe.me.company_id, module_title: moduleName, is_sub_module: false}
        //     });
        //     const { status, message, data } = moduleAddData.module_add;
        //     if (status) {
        //         console.log(45, data)
        //         setModules((prevModules) => [...prevModules, data]);
        //     }
        // } catch (error) {
        //     // Handle error if needed
        // } finally {
        //     setLoader(false);
        // }
        setDeleteModulePopup(true);
        setOpenOptions(null);
    };


    // console.log('modulesLoading', modulesData);

    const handleGoBack = () => {
        navigate(-1);
    };

    const ref = useClickAway(() => {
        setOpenOptions(null);
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleModuleClick = (module) => {
        setSelectedModule(module);
        setModuleDetailsPopup(true);
    };

    const handleSortChange = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const handleMoreOptionsClick = (moduleId) => {
        setOpenOptions((prev) => (prev === moduleId ? null : moduleId));
    };

    const handleEditModule = (module) => {
        setEditModule(module);
        setCreatePopup(true);
        setOpenOptions(null);
    };

    // Filter and sort modules
    const filteredModules = modules
        .filter(module => module.module_title?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            const aValue = a[sortKey];
            const bValue = b[sortKey];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        // console.log(94, filteredModules)
    return (
        <>
            { modulesLoading === true || loader ?
                <div className="switchLoaderMain"></div> :
                <div className='right_container'>
                    <div className='adminRightHead'>
                        <span className="user_Management_title text-5xl">Module Management</span>
                    </div>
                    <div className='usersTabSection'>
                        <div className="usersTabSecDevide leftSide" style={{ gap: '10px' }}>
                            <div className={`usersTabList teamManagementTitle`}>
                                <div className='cursor-pointer'><p>Total Modules ({modules.length})</p></div>
                            </div>
                        </div>
                        <div className='usersTabSecDevide rightSide'>
                            <span className='relative'>
                                <input
                                    className="userSearchInput"
                                    type="text"
                                    placeholder="Search modules"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <span onClick={() => setSearchTerm('')} className="leftSearchCloseBtn remove"></span>
                            </span>
                            <button onClick={() => { setEditModule(null); setCreatePopup(true); }} className="userCreateBtn tooltip5">
                                Create Module
                            </button>
                        </div>
                    </div>
                    <div className='usersTableContainer'>
                        <div className="userTableHead">
                            <ul>
                                <li className={`_userName ${sortKey === 'module_title' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`}
                                    style={{ width: "45%" }} onClick={() => handleSortChange('module_title')}>
                                    <span className="sortIcons"></span>Module Name
                                </li>
                                <li className={`_userEmail !justify-center ${sortKey === 'subModulesCount' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`} style={{ width: "25%" }}>
                                    Sub Module No.
                                </li>
                                <li className={`_userRole !pl-0 ${sortKey === 'created_at' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''} sort_active`} style={{ width: "25%" }}>
                                    Created Date
                                </li>
                                <li className="_userAction" style={{ width: "5%" }}>Action</li>
                            </ul>
                        </div>

                        <div className='userTableBody'>
                            {filteredModules.length > 0 ? (
                                filteredModules.map((module) => (
                                    <div key={module.module_id} className='relative'>
                                        <ul className="_userList">
                                            <li className="_userName !w-[45%]" onClick={() => handleModuleClick(module)}>
                                                <span className="_userNameTxt">{module.module_title}</span>
                                            </li>
                                            <li className="_userName !text-center !block !w-[25%]">
                                                <span className="_userNameTxt">{module.sub_module.length}</span>
                                            </li>
                                            <li className="_userName !pl-0 !w-[25%]">
                                                <span className="_userNameTxt">{new Date(module.created_at).toLocaleDateString()} <br />
                                                    <span className="_userNameTxt !text-gray-400">by {module.created_by_name}</span>
                                                </span>
                                            </li>
                                            <li onClick={() => handleMoreOptionsClick(module.module_id)} className="_userAction !text-center relative !justify-center !w-[5%]">
                                                <span className="userActionIcon"></span>
                                            </li>
                                        </ul>

                                        {openOptions === module.module_id && (
                                            <ul ref={ref} className="userActionPopup !absolute">
                                                <li onClick={() => handleEditModule(module)} className="!text-start">Edit Module</li>
                                                <li onClick={() => handleDeleteModule(module)} className="!text-start">Delete Module</li>
                                            </ul>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="noResults !text-center mt-48">
                                    <p>No modules found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            {createPopup && (
                <CreateEditModule
                    setCreatePopup={setCreatePopup}
                    modules={modules}
                    company_id={cacheMe.me.company_id}
                    setModules={setModules}
                    editModule={editModule}
                    setEditModule={setEditModule}
                />
            )}
            {selectedModule && moduleDetailsPopup &&
                <ModuleDetails
                    selectedModule={selectedModule}
                    modules={modules}
                    setModules={setModules}
                    setSelectedModule={setSelectedModule}
                    setModuleDetailsPopup={setModuleDetailsPopup}
                />
            }
            {deleteModulePopup &&
                <WarningModule
                    modules={modules}
                    setModules={setModules}
                    setModuleToDelete={setModuleToDelete}
                    moduleToDelete={moduleToDelete}
                    setDeleteModulePopup={setDeleteModulePopup} />}
        </>
    );
};

export default ModuleManagementHome;
