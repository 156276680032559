/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import classNames from "classnames";
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_TEAMS } from '../../../Utils/GraphqlQueries';
import { CREATE_UPDATE_TAG } from '../../../Utils/GraphqlMutaion';
import { InputValidationCheck } from '../../ValidationCheck';

function TagColor(props) {
    const colorCode = ['#0A256A', '#B246FF', '#732BE2', '#0036B6', '#FF53D3', '#DF1E39', '#41D82F', '#078CFE', '#FFE026', '#28C8BD', '#FF991D'];

    const changeColor = (color_code) => {
        props.setTagData({ ...props.tagData, color: color_code });
    }

    return (
        <div className='ColorList'>
            {colorCode.map(v =>
                <span key={v} className={classNames('color_desgin', props.tagData.color === v ? 'active' : '')} style={{ backgroundColor: v }} onClick={() => changeColor(v)}></span>
            )}
        </div>
    )
}

function CreateTag({ popUpType, setCreateNewTag, action_data, tags, user, setAllTags }) {
    // console.log("🚀 ~ CreateTag ~ action_data:", action_data)
    const [loader, setLoader] = useState(false);
    const [createStatus, setCreateStatus] = useState(false);
    const [uniqName, setUniqName] = useState(true);




    const [tagData, setTagData] = useState({
        title: action_data ? action_data.title : '',
        color: action_data ? action_data.tag_color : '#0A256A',
        team_list: action_data ? action_data.team_list : [] // Change initial state to empty array instead of null
    });

    const { loading: team_loader, data: team_data } = useQuery(GET_ALL_TEAMS);
    const [allTeams, setAllTeams] = useState([]);

    useEffect(() => {
        if (team_data?.teams) {
            let forTeams = team_data.teams.map(t => ({
                value: t.team_id,
                label: t.team_title,
                data: t.team_id,
                participants: t.participants
            }));

            setAllTeams(forTeams);

            if (popUpType === "create") {
                setTagData(prev => ({
                    ...prev,
                    team_list: []
                }));
            } else if (action_data) {
                const selectedTeams = forTeams.filter(f =>
                    action_data.team_list.some(teamId => teamId === f.value)
                );
                setTagData(prev => ({ ...prev, team_list: selectedTeams }));
            }
        }
    }, [team_data, action_data, popUpType]);





    const checkTitle = () => {
        let allTitle = [];
        for (let i of tags) {
            if (action_data) {
                if (action_data.title.toUpperCase() !== i.title.toUpperCase()) {
                    allTitle.push(i.title.toUpperCase());
                }
            } else {
                allTitle.push(i.title.toUpperCase());
            }
        }

        if (allTitle.indexOf(tagData.title.toUpperCase()) > -1) {
            setUniqName(false);
            return false;
        } else {
            setUniqName(true);
            return true;
        }
    }

    useEffect(() => {
        let uniqName = checkTitle();
        if (tagData.title.trim() !== '' && uniqName) {
            setCreateStatus(true);
        } else {
            setCreateStatus(false);
        }
    }, [tagData])

    const [create_tag] = useMutation(CREATE_UPDATE_TAG)


    const createTagClick = async () => {
        try {
            if (createStatus) {
                setLoader(true);

                let data = {
                    title: tagData.title.toUpperCase(),
                    tagged_by: user.id,
                    tag_type: "public",
                    tag_color: tagData.color,
                    team_list: tagData?.team_list?.map(m => m.value)
                };

                if (action_data) {
                    data['tag_id'] = action_data.tag_id;
                    data['tag_type'] = action_data.tag_type;
                }

                let apires = await create_tag({ variables: { input: data } });
                // console.log("🚀 ~ createTagClick ~ apires:", apires);
                if (apires.data.create_update_tag.status) {
                    // console.log("create 147");
                    const updatedTag = apires.data.create_update_tag.tag;

                    // console.log(132, updatedTag);

                    if (popUpType === "create") {
                        if (team_data?.teams) {
                            const team_names = team_data.teams
                                .filter(team => updatedTag.team_list.includes(team.team_id))
                                .map((data) => data.team_title);
                            updatedTag.team_list_name = team_names;
                            setAllTags((prevTags) => [updatedTag, ...prevTags]);
                        }
                    }


                    if (popUpType === "update") {
                        setAllTags(prev => {
                            return prev.map(tag =>
                                tag.tag_id === updatedTag.tag_id
                                    ? {
                                        ...tag,
                                        title: updatedTag.title,
                                        team_list: updatedTag.team_list,
                                        team_list_name: updatedTag.team_list_name
                                    }
                                    : tag
                            );
                        });
                    }
                    toast.success(apires.data.create_update_tag.message, { duration: 4000 });
                } else {
                    toast.error(apires.data.create_update_tag.message, { duration: 4000 });
                }
                setCreateStatus(false);
                setLoader(false);
                setCreateNewTag(false);
            }
        } catch (error) {
            setLoader(false);
            toast.error('An error occurred while creating the tag');
        }
    };

    // const updateTagTitle = (e, type) => {
    //     if (e.target.value.length < 40) {
    //         setTagData({ ...tagData, [type]: e.target.value });
    //     }
    // };
    const updateTagTitle = (e, type) => {
        const { value } = e.target;
    
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setTagData({ ...tagData, [type]: value });
            return;
        }
    
        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (InputValidationCheck(value)) {
            console.warn('Invalid input for tag title'); // Can log or display an error message here
            return;
        }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the value is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Tag title cannot be empty or contain only spaces');
            return;
        }
    
        // Restrict the input to 40 characters
        if (trimmedValue.length <= 40) {
            setTagData({ ...tagData, [type]: trimmedValue });
        } else {
            console.warn('Tag title cannot exceed 40 characters');
        }
    };

    const selectTeams = (e) => {
        // Filter out empty or null values from selected teams
        const filteredTeams = e.filter(team => team.value && team.value !== null && team.value !== "");
        setTagData({ ...tagData, team_list: filteredTeams });
    };

    // Filter out empty or null value from allTeams for options
    const filteredAllTeams = allTeams.filter(team => team.value && team.value !== null && team.value !== "");



    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setCreateNewTag(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div className="backwrap">
                <div className="createUserModal" style={{ width: '420px' }}>
                    <div className="createUserModalHead">
                        <h4 className="popupTitle">{action_data ? 'Edit tag' : 'Create new tag'}</h4>
                        <span className="closeModal" onClick={() => setCreateNewTag(false)}></span>
                    </div>
                    <div className="createTeamModalBody">
                        <div className="crTeam_inputGroup" style={{ width: '100%', float: 'left' }}>
                            <label>Tag name</label>
                            <input
                                style={{ borderLeft: '8px solid ' + tagData.color, borderColor: tagData.color }}
                                type="text" placeholder="Tag name"
                                value={tagData.title}
                                onChange={(event) => updateTagTitle(event, 'title')}
                                maxLength={25}
                                autoFocus
                            />
                            {!uniqName ? <span className="rgInputMsg" style={{ color: 'red', fontSize: '12px', margin: '-8px 0px 4px 0px' }}>Tag name already exist. </span> : ''}
                        </div>
                        <div className="crTeam_inputGroup" style={{ width: '100%', float: 'left' }}>
                            <label>Color</label>
                            <TagColor tagData={tagData} setTagData={setTagData}></TagColor>
                        </div>
                        <div className="crTeam_inputGroup">
                            <label>Select team</label>
                            <Select
                                className="select-ecosystem tagSelect"
                                value={tagData.team_list.filter(team => team.value && team.value !== "")} // Filter empty values in selected items
                                isMulti
                                closeMenuOnSelect={true}
                                options={allTeams.filter(team => team.value && team.value !== "")} // Filter empty values in options
                                isSearchable
                                onChange={selectTeams}
                                placeholder={<span className="selectPlaceholder">Assign to specific Team(s) or leave empty for all</span>}
                            />
                        </div>
                    </div>
                    <div className="createUserModalFoot">
                        <button className="btnCancel" onClick={() => setCreateNewTag(false)}>Cancel</button>
                        {loader ?
                            <button className="btnAction btn_loader" style={{ 'color': 'transparent' }}>Create</button>
                            : <button className="btnAction" style={!createStatus ? { pointerEvents: 'none', opacity: '0.5' } : {}} onClick={createTagClick} >{action_data ? 'Update' : 'Create'}</button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateTag;
