/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_TEAM_DATA } from '../../../Utils/GraphqlQueries';
import { useState } from 'react';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import Moment from 'react-moment';
import { useClickAway } from '@uidotdev/usehooks';
import TeamWarnPopup from './TeamWarnPopup';
import { CREATE_UPDATE_TEAM } from '../../../Utils/GraphqlMutaion';
import { UserSelection } from '../../GlobalPopups/UserSelection'
import toast from 'react-hot-toast';
import { set } from 'date-fns';

const TeamDetails = ({ data, setTeamDetails, all_users, user, setallTeams }) => {
    const [loader, setLoader] = useState(true)
    const [srcString, setSrcString] = useState('');
    const [activeOption, setActiveOption] = useState('');
    const [remove_user, setRemoveUser] = useState(false);
    const [add_member, setAddMember] = useState(false);
    const [make_admin, setMake_admin] = useState(false);
    const [revoke_admin, setRevoke_admin] = useState(false);
    const [action_data, setAction_data] = useState(null);
    const { loading: team_loader, data: team_data } = useQuery(GET_TEAM_DATA, { variables: { team_id: data.team_id }, fetchPolicy: 'no-cache' });
    const [team_users, setTeam_users] = useState([])
    const [team, setTeam] = useState(null)
    const ref = useClickAway(() => {
        setActiveOption('')
    });

    useEffect(() => {
        if (team_data?.team) {
            setTeam_users(all_users.filter(f => team_data.team.participants.indexOf(f.id) > -1))
            setTeam(team_data?.team)
            setLoader(false)
        }
    }, [team_data])

    useEffect(() => {
        if (team_users?.length && team_data?.team) {
            const team_id = team_data.team.team_id;
            const participants = team_users.map(p => p.id);
    
            setallTeams(prev =>
                prev.map(team =>
                    team.team_id === team_id && team.participants.length !== participants.length
                        ? { ...team, participants }
                        : team
                )
            );
        }
    }, [team_users, team_data]);    

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setTeamDetails(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const [create_update_team] = useMutation(CREATE_UPDATE_TEAM)
    // const remove_confirm = async () => {
    //     let apires = await create_update_team({
    //         variables: {
    //             input: {
    //                 title: team?.team_title,
    //                 type: 'update_conv_also',
    //                 participants: team?.participants.filter(f => f !== action_data?.id),
    //                 remove_user: action_data?.id,
    //                 team_id: team?.team_id
    //             }

    //         }
    //     })
    //     console.log("🚀 ~ constremove_confirm= ~ apires:", apires)
    //     setRemoveUser(false)
    // }
    const remove_confirm = async () => {
        // console.log("🚀 ~ remove_confirm ~ action_data:", action_data)
        try {
            const apires = await create_update_team({
                variables: {
                    input: {
                        title: team?.team_title,
                        type: 'update_conv_also',
                        participants: team?.participants.filter(f => f !== action_data?.id),
                        remove_user: action_data?.id,
                        team_id: team?.team_id
                    }
                }
            });

            // Check the mutation response status
            if (apires.data.create_update_team.status) {
                // Update team state to remove the user instantly
                setTeam_users(prevUsers => prevUsers.filter(user => user.id !== action_data?.id));
                setTeam(prevTeam => ({
                    ...prevTeam,
                    participants: prevTeam.participants.filter(f => f !== action_data?.id)
                }));
                // Show success toast
                toast.success('Member removed from the team.', { duration: 4000 });
            }
            // Close the remove user popup
            setRemoveUser(false);
        } catch (error) {
            // In case of error, handle the error and show an error toast
            toast.error('Failed to remove the member. Please try again.', { duration: 4000 });
        }
    }

    const remove_conv = async () => {
        try {
            const apires = await create_update_team({
                variables: {
                    input: {
                        title: team?.team_title,
                        type: 'update_conv_also',
                        participants: team?.participants.filter(f => f !== action_data?.id),
                        remove_user: action_data?.id,
                        team_id: team?.team_id
                    }
                }
            });
            // console.log("🚀 ~ constremove_confirm= ~ apires:", apires)
            setRemoveUser(false)
        } catch (error) {
            console.log("🚀 ~ constremove_confirm= ~ error:", error)
        }
    }

    const admin_confirm = async () => {
        let apires = await create_update_team({
            variables: {
                input: {
                    title: team?.team_title,
                    type: 'update_conv_also',
                    participants: team?.participants,
                    admin: make_admin ? [...team?.admin, action_data?.id] : [...team?.admin.filter(f => f !== action_data?.id)],
                    team_id: team?.team_id
                }

            }
        })
        if (apires.data.create_update_team.status) {
            toast.success(apires.data.create_update_team.message, { duration: 4000 })
            if (make_admin) {
                setTeam({ ...team, admin: [...team.admin, action_data?.id] })
                setMake_admin(false)
            } else {
                setTeam({ ...team, admin: [...team?.admin.filter(f => f !== action_data?.id)] })
                setRevoke_admin(false)
            }
        }
    }
    const [miniList, setMiniList] = useState([]);

    return (
        <div className='right_containerr'>
            <div className="userInfoModal">
                <div className="adminRightHead" onClick={() => setTeamDetails(false)}>
                    <span className="closeAdminPanel">Back to Team management</span>
                </div>
                <div className="teamInfoNameSection">
                    <div className="teamInfoName">{team?.team_title} ({team_users.length})</div>
                    <div className="teamInfoDate">
                        <span>Created by: {getONLYUSERSDATA(all_users, team?.created_by, 'name')} On: <Moment format="MMM Do, YYYY">{team?.updated_at}</Moment></span> |
                        <span>Last updated by: {getONLYUSERSDATA(all_users, team?.updated_by, 'name')} On: <Moment format="MMM Do, YYYY h:mm A">{team?.updated_at}</Moment></span>
                    </div>
                </div>
                <div className="tagInfoActionSection">
                    <span style={{ width: '400px', position: 'relative', marginRight: '12px' }}>
                        <input className="searchTeamUser" autoFocus={true} value={srcString} onChange={(e) => setSrcString(e.target.value)} type="text" placeholder="Search team member" autoComplete="new-password" />
                        <span className="leftSearchCloseBtn remove" onClick={() => setSrcString('')} style={{ top: '8px', right: '10px' }}></span>
                    </span>
                    {team?.admin?.indexOf(user.id) > -1 && <span className="addUserToTeamBtn" onClick={() => setAddMember(true)}>Add teammate(s)</span>}
                </div>
                <div className="teamUsersTableContainer">
                    <div className="userTableHead">
                        <ul>
                            <li className="_userName active" style={{ width: '40%' }}>Name</li>
                            <li className="_userEmail" style={team?.admin?.indexOf(user.id) > -1 ? { width: '25%' } : { width: '35%' }}>Login email</li>
                            <li className="_userStatus" style={team?.admin?.indexOf(user.id) > -1 ? { width: '15%' } : { width: '20%' }}>Team role</li>
                            <li className="_userStatus" style={team?.admin?.indexOf(user.id) > -1 ? { width: '10%' } : { width: '25%' }}>Status</li>
                            {team?.admin?.indexOf(user.id) > -1 && <li className="_userStatus !flex !justify-center" style={{ width: '10%' }}>Action</li>}
                        </ul>
                    </div>
                    <div className="userTableBody">
                        <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}>
                            <div style={{ position: 'absolute', inset: '0px', overflow: 'scroll', marginRight: '-7px', marginBottom: '-7px' }}>
                                {loader ? ''
                                    :
                                    team_users.filter(f => (f.firstname + ' ' + f.lastname).toLowerCase().includes(srcString.toLowerCase()) || (f.email).toLowerCase().includes(srcString.toLowerCase())).map(v =>
                                        <div key={'t_u_' + v.id} style={{ position: 'relative' }}>
                                            <ul className="_userList">
                                                <li className="_userName" style={{ width: '40%' }}>
                                                    <span className="_userImgage"><span className="nameLetters">{v.fnln}</span></span>
                                                    <span className="_userNameTxt">{`${v.firstname} ${v.lastname}`}</span>
                                                </li>
                                                <li className="_userEmail" style={team?.admin?.indexOf(user.id) > -1 ? { width: '25%' } : { width: '35%' }}>
                                                    <span className="userEmailText" title="mji.jahirulislam505@gmail.com">{v.email}</span>
                                                </li>
                                                <li className="_userStatus" style={team?.admin?.indexOf(user.id) > -1 ? { width: '15%' } : { width: '20%' }}>{team?.admin.indexOf(v.id) > -1 ? 'Admin' : 'Member'}</li>
                                                <li className="_userStatus" style={team?.admin?.indexOf(user.id) > -1 ? { width: '10%' } : { width: '25%' }}>{v.is_active === 1 ? 'Active' : 'Deactivated'}</li>
                                                {team?.admin?.indexOf(user.id) > -1 &&
                                                    <li className="_userStatus !flex !justify-center" style={{ width: '10%' }}>
                                                        <span className="userActionIcon" onClick={() => setActiveOption(prev => prev === v.id ? '' : v.id)}></span>
                                                    </li>
                                                }
                                            </ul>
                                            {
                                                activeOption === v.id &&
                                                <ul className="userActionPopup" style={{ position: "absolute", top: '78px' }} ref={ref}>
                                                    {
                                                        v.is_active === 1 ?
                                                            <>
                                                                {team.admin.indexOf(v.id) > -1 ?
                                                                    v.id !== user.id && <li className='!text-start' onClick={() => { setAction_data(v); setRevoke_admin(true) }}>Revoke team admin</li>
                                                                    :
                                                                    <li className='!text-start' onClick={() => { setAction_data(v); setMake_admin(true) }}>Make team admin</li>
                                                                }
                                                                {team.admin.indexOf(v.id) === -1 && <li className='!text-start' onClick={() => { setAction_data(v); setRemoveUser(true) }}>Remove from team</li>}
                                                            </>
                                                            :
                                                            <>
                                                                {team.admin.indexOf(v.id) === -1 && <li className='!text-start' onClick={() => { setAction_data(v); setRemoveUser(true) }}>Remove from team</li>}
                                                            </>
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {remove_user && <TeamWarnPopup title={'Remove user ?'} remove_conv={remove_conv} desc={'Are you sure you want to remove user from this team ?'} onClose={() => setRemoveUser(false)} onConfirm={remove_confirm} />}
            {make_admin && <TeamWarnPopup title={'Make admin ?'} desc={'Are you sure you want to make this user Admin ?'} onClose={() => setMake_admin(false)} onConfirm={admin_confirm} />}
            {add_member && <UserSelection team={team} type="team_member_add" miniList={miniList} setMiniList={setMiniList} setAddMember={setAddMember} team_users={team_users.map(v => v.id)} setTeam_users={setTeam_users} />}
            {revoke_admin && <TeamWarnPopup title={'Revoke admin ?'} desc={'Are you sure you want to Revoke admin role ?'} onClose={() => setRevoke_admin(false)} onConfirm={admin_confirm} />}
        </div>
    );
};

export default TeamDetails;