import React, { useState,useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_LINK } from '../../Utils/GraphqlMutaion';
import toast, { Toaster } from 'react-hot-toast';

function DeleteLink({ setShowDeletePopup, setApi_data, api_data, action_link }) {
	// console.log("🚀 ~ DeleteLink ~ action_link:", action_link)
	const [loader, setLoader] = useState(false);
	const [radioValue, setRadioValue] = useState('only_link')

	const [delete_link] = useMutation(DELETE_LINK)

	const deleteBtn = async () => {
		setLoader(true);
		try {

			delete_link({
				variables: {
					input: {
						url_ids: [action_link.url_id],
						delete_type: radioValue,
					}
				}
			})

			setLoader(false);
			setApi_data(api_data.filter(l => l.url_id !== action_link.url_id));
			toast.success('Link deleted successfully');
			close_popup()
		} catch (error) {
			setLoader(false);
			console.log(error.response);
		}
	}

	const close_popup = () => {
		setShowDeletePopup(false)
	}
	useEffect(() => {
		const handleKeyPress = (event) => {
		  if (event.key === 'Escape') {
			setShowDeletePopup(false)
		  }
		};
		document.addEventListener('keydown', handleKeyPress);
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	  }, [setShowDeletePopup]);
	  

	return (
		<>
		<Toaster/>
			<div className="backwrap">
				<div className="freeliModal">
					<div className="freeliModalHead">
						<h4 className="popupTitle">Delete Link</h4>
						<span className="closeModal" data-for="rightSection_tooltip" data-tip="Close" onClick={close_popup} ></span>
					</div>
					<div className="freeliModalBody" style={{ padding: '20px 30px' }}>
						<p className="delDesc" style={{ textAlign: 'left' }}>Are you sure you want to delete this link? This cannot be <span className="undone">undone</span>.</p>
						<div className="container_selectButton">
							<div className='radioButtons'>
								<label>
									<input type="radio" value={'only_link'} name="radio" checked={radioValue === "only_link"} onChange={(event) => setRadioValue(event.target.value)} />
									<span>Delete from File hub. </span>
								</label>
								<label>
									<input type="radio" value={'link_msg'} name="radio" checked={radioValue === "link_msg"} onChange={(event) => setRadioValue(event.target.value)} />
									<span>Delete from File hub and message. </span>
								</label>
							</div>
						</div>

					</div>
					<div className="freeliModalFoot">
						<button className="btnCancel" onClick={close_popup} >Cancel</button>
						{loader ? <button className="btnAction btn_loader" style={{ color: 'transparent', opacity: '0.5' }} >Delete</button> : <button className="btnAction" onClick={() => deleteBtn()}>Delete</button>}
					</div>
				</div>
			</div>
		</>
	)
}


export default DeleteLink;