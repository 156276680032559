import React, { useState, useEffect, useRef } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'; // Make sure to install moment if you're using it
// import CreateSalesForm from './CreateSalesForm';
import CreateHourlyArea from './CreateHourlyArea';
import CreateReportFooter from './CreateReportFooter';
import CreatePayments from './CreatePayments';
import CreateTaxes from './CreateTaxes';
import CreateTotalSales from './CreateTotalSales';
import CreateCash from './CreateCash';
import CreateNetSales from './CreateNetSales';
import { silverware_pos } from '../../../Utils/Common';
import { CREATE_EOD_REPORT, DELETE_EOD_REPORT } from '../../../Utils/GraphqlMutaion';
import { GETVENULIST, GET_ME } from '../../../Utils/GraphqlQueries';
import {useApolloClient, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import { set_report_list, update_report_list } from '../../../redux/eodSlice';


const CreateAndEditReport = ({ report: reportData, isPopupOpen, onClose, eodData, setEodData, totalCount, setTotalCount, cleanObject, handleEodTabs, fetchData}) => {
    
    const dispatch = useDispatch();
    const [report, setReport] = useState(reportData);
    const [Submitter, setSubmitter] = useState([]);
    const [Reopener, setReopener] = useState([]);
    
    const selected_venue_title = useSelector(state => state.eod.selected_venue_title) || '';
    const selected_venue_id = useSelector(state => state.eod.selected_venue_id) || '';
    const report_one_update = useSelector(state => state.eod.report_one_update);

    // console.log("EOD:report:", report, selected_venue_id, selected_venue_title);
    // console.log('CreateAndEditReport',report);

    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const [venues, setVenuesList] = useState([]);
    const { data: venue_list } = useQuery(GETVENULIST,{ fetchPolicy: 'no-cache' })

    useEffect(() => {
        if (venue_list?.get_venue_list?.status) {
            setVenuesList(venue_list?.get_venue_list.result)
        }
    }, [venue_list])

      // const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    // const [isChanged, setIsChanged] = useState(false);
    // const [isDraftDisabled, setIsDraftDisabled] = useState(false);

    
    const [deletePopup, setdeletePopup] = useState(false);
    const [POSData, setPOSData] = useState(null); 
    // console.log('POSData',POSData);
    const [POSDataStatus, setPOSDataStatus] = useState(false);
    const [permissionMode, setPermissionMode] = useState('');
    const [searchVenueByVal, setSearchVenueByVal] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [venueData, setVenueData] = useState(null);

    const [_id] = useState(report?._id || '');
    const [status, setStatus] = useState(report?.status || '');
    const [created_by] = useState(report?.created_by || '');
    const [salesData, setsalesData] = useState(report?.sales_data || []);
    const [paymentsData, setPaymentsData] = useState(report?.payment_data || []);
    const [taxesData, setTaxesData] = useState(report?.tax_data || []);
    const [cashData, setCashData] = useState(report?.cash_data || []);
    const [statisticData, setStatisticData] = useState(report?.statistic_data || {});
    const [reportingDate, setReportingDate] = useState(moment(report?.reporting_date || new Date()).toDate());
    const [selectedVenue, setSelectedVenue] = useState(report?.notes_for ? report?.notes_for : selected_venue_title || '');
    const [selectedVenueId, setSelectedVenueId] = useState(report?.venue_id ? report?.venue_id : selected_venue_id || '');
    // console.log('EOD:selectedVenue',selectedVenue,selectedVenueId);

    const [totalData, setTotalData] = useState({
        title: report?.real_time_data || 'Dinner',
        netSales: report?.net_sales || '',
        comps: report?.comps || '',
        total: report?.total || '',
    })
    
    const [notes, setNotes] = useState({
        operational: report?.operational_note || '',
        briefing: report?.briefing_note || '',
        service: report?.service_note || '',
        kitchen: report?.kitchen_note || ''
    });

    const [labourData, setLabourData] = useState([
        {   labour: "FOH-Hourly", 
            forecast: report?.fohHourly?.forecast || 0, 
            actual: report?.fohHourly?.actual || 0, 
            labourNetSales: report?.fohHourly?.total || 0, 
            discounts: report?.fohHourly?.discount || 0 
        },
        {   labour: "FOH-Salary", 
            forecast: report?.fohSalary?.forecast || 0, 
            actual: report?.fohSalary?.actual || 0, 
            labourNetSales: report?.fohSalary?.total || 0, 
            discounts: report?.fohSalary?.discount || 0 
        },
        {   labour: "BOH-Hourly", 
            forecast: report?.bohHourly?.forecast || 0, 
            actual: report?.bohHourly?.actual || 0, 
            labourNetSales: report?.bohHourly?.total || 0, 
            discounts: report?.bohHourly?.discount || 0 
        },
        {   labour: "BOH-Salary", 
            forecast: report?.bohSalary?.forecast || 0, 
            actual: report?.bohSalary?.actual || 0, 
            labourNetSales: report?.bohSalary?.total || 0, 
            discounts: report?.bohSalary?.discount || 0 
        },
    ]);

    useEffect(() => {
        // console.log('report_one_update',report_one_update);
        if(report_one_update && report_one_update._id === _id){
            // debugger
            setReport(report_one_update);
            setStatus(report_one_update.status);
            setsalesData(report_one_update.sales_data);
            setPaymentsData(report_one_update.payment_data);
            setTaxesData(report_one_update.tax_data);
            setCashData(report_one_update.cash_data);
            setReportingDate(moment(report_one_update.reporting_date).toDate());
            setSelectedVenue(report_one_update.notes_for);
            setSelectedVenueId(report_one_update.venue_id);
            setTotalData({
                title: report_one_update?.real_time_data || 'Dinner',
                netSales: report_one_update?.net_sales || '',
                comps: report_one_update?.comps || '',
                total: report_one_update?.total || '',
            })
            setNotes({
                operational: report_one_update?.operational_note || '',
                briefing: report_one_update?.briefing_note || '',
                service: report_one_update?.service_note || '',
                kitchen: report_one_update?.kitchen_note || ''
            })
            setLabourData([
                {   labour: "FOH-Hourly", 
                    forecast: report_one_update?.fohHourly?.forecast || 0, 
                    actual: report_one_update?.fohHourly?.actual || 0, 
                    labourNetSales: report_one_update?.fohHourly?.total || 0, 
                    discounts: report_one_update?.fohHourly?.discount || 0 
                },
                {   labour: "FOH-Salary", 
                    forecast: report_one_update?.fohSalary?.forecast || 0, 
                    actual: report_one_update?.fohSalary?.actual || 0, 
                    labourNetSales: report_one_update?.fohSalary?.total || 0, 
                    discounts: report_one_update?.fohSalary?.discount || 0 
                },
                {   labour: "BOH-Hourly", 
                    forecast: report_one_update?.bohHourly?.forecast || 0, 
                    actual: report_one_update?.bohHourly?.actual || 0, 
                    labourNetSales: report_one_update?.bohHourly?.total || 0, 
                    discounts: report_one_update?.bohHourly?.discount || 0 
                },
                {   labour: "BOH-Salary", 
                    forecast: report_one_update?.bohSalary?.forecast || 0, 
                    actual: report_one_update?.bohSalary?.actual || 0, 
                    labourNetSales: report_one_update?.bohSalary?.total || 0, 
                    discounts: report_one_update?.bohSalary?.discount || 0 
                },
            ])
            
        }

    },[report_one_update])

    const [create_eod_report] = useMutation(CREATE_EOD_REPORT)
    const [delete_eod_report] = useMutation(DELETE_EOD_REPORT)
    
    const inputVenueRef = useRef(null);
    const venueOpen = useClickAway(() => {
        setIsOpen(false)
    })

    useEffect(() => {
        // debugger
        // ; 
        // if(props.popup_report_data.reporting_date) return;
        // const reportingDateObj = report?.reporting_date ? new Date(report?.reporting_date) : new Date();
        const today = new Date(reportingDate.getFullYear(), reportingDate.getMonth(), reportingDate.getDate());
        // Define 6 AM today in local time
        const sixAmToday = new Date(today);
        sixAmToday.setHours(6, 0, 0, 0);
        // Calculate yesterday
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        // Get current time components from reportingDate
        const currentHours = reportingDate.getHours();
        const currentMinutes = reportingDate.getMinutes();
        const currentSeconds = reportingDate.getSeconds();
        const currentMilliseconds = reportingDate.getMilliseconds();

        if (status) {
            // For edit operations, don't adjust the date
            setReportingDate(reportingDate);
        } else {
            // For new submissions, adjust the date if it's between 12 AM and 6 AM
            if (reportingDate >= today && reportingDate < sixAmToday) {
                yesterday.setHours(currentHours, currentMinutes, currentSeconds, currentMilliseconds);
                setReportingDate(yesterday);
            } else {
                setReportingDate(reportingDate);
            }
        }
    }, []);

   
    
    const getPOSData = async (reportingDate, endpointURL, endpointToken) => {
        
        // setsalesData([]);
        let data = await silverware_pos({
            BusinessDateFrom: moment(reportingDate).startOf('day'), 
            BusinessDateTo: moment(reportingDate).endOf('day'),
            endpointURL,
            endpointToken
        });
        setPOSDataStatus(data?.status || false);
        
        if(data?.status){
            setPOSData(data.data);
            // console.log('POS:', POSData);
            let sales_data = []; // Sales data
            let payment_data = [];  // Payments data
            let tax_data = []; // Taxes data
            // let statistic_data = data.data.Statistics.map(({ PeriodStart, PeriodEnd, _id, ...rest }) => rest);
            // debugger
            const { PeriodStart, PeriodEnd, ...statistic_data } = data.data.Statistics;
            // console.log('statistic_data',statistic_data);
            if(data?.data?.Sales?.Items?.length){
                data?.data?.Sales?.Items.forEach(item => {
                    sales_data.push({
                        sales: item.Name,
                        qty: item.Quantity,
                        grossAmount: item.GrossAmount,
                        refunds: item.RefundAmount,
                        discounts: item.DiscountAmount,
                        amount: item.NetAmount
                    })
                });
            }
            if(data?.data?.Payments?.Items?.length){
                data?.data?.Payments?.Items.forEach(item => {
                    payment_data.push({
                        type: item.Name,
                        qty: item.Quantity,
                        amount: item.Amount
                    })
                });
            }
            if(data?.data?.Taxes?.Items?.length){
                data?.data?.Taxes?.Items.forEach(item => {
                    tax_data.push({
                        type: item.Name,
                        qty: item.Quantity,
                        amount: item.Amount
                    })
                });
            }
            setsalesData(sales_data);
            setPaymentsData(payment_data);
            setTaxesData(tax_data);
            setCashData([
                { type: "Cash Payments", qty: 0, amount: data?.data?.NetCashOwing?.CashPaymentsAmount || 0 },
                { type: "Non-Cash Tips", qty: 0, amount: data?.data?.NetCashOwing?.NonCashTips || 0 }
            ]);
            setStatisticData(statistic_data)

        }else{
            setsalesData([]);
            setPaymentsData([]);
            setTaxesData([]);
            setCashData([])
        }
        
    }
    // useEffect(() => {
    //     // debugger;
    //     if(!_id) getPOSData(reportingDate)
    // }, [_id, reportingDate]);


    // useEffect(() => {
    //     // if (!_id) 
    //         getPOSData(reportingDate);
    // }, [reportingDate, venueData]);

    useEffect(() => {
        if(reportingDate && venueData && venueData?.endpointURL && venueData?.endpointToken){
            getPOSData(reportingDate, venueData.endpointURL, venueData.endpointToken)
        }
        
    }, [reportingDate, venueData]);

    useEffect(() => {
        // debugger;
        if(selectedVenueId){
            let data = venues.filter(venue => venue.venue_id === selectedVenueId );
            
            if(data?.length){
                setSubmitter(data[0].eod_submitters);
                setReopener(data[0].eod_reopen);
                // setVenueData(data[0]);
            }

        }
        
        
        // return () => {
        //     props.set_report_one_update("");
        // };
    }, [selectedVenueId,venues])

    useEffect(() => {
        // 
        if (Reopener.includes(cacheMe.me.id) && Submitter.includes(cacheMe.me.id)) {
            if (status === "" || status === "DRAFT") {
                setPermissionMode('submitter')
            } else {
                setPermissionMode('reopener')
            }
        } else if (Reopener.includes(cacheMe.me.id)) {
            setPermissionMode('reopener');
        } else if (Submitter.includes(cacheMe.me.id)) {
            setPermissionMode('submitter');
        }


    }, [Submitter, Reopener, cacheMe, status])

    const getSubmitStatus = () => {
        // debugger
        // if(!status) return { text: "Submit", button: false, form: false }
        if (_id) { // after submit
            if (status === "SUBMIT") {
                if (Reopener.includes(cacheMe.me.id) && permissionMode === 'reopener') { // admin user
                    return { text: "Re-open", button: true, form: false }
                }
                else { // normal user
                    return { text: "Submit", button: false, form: false }
                }
            }
            else if (status === "REOPEN") {
                if (Submitter.includes(cacheMe.me.id) && permissionMode === 'submitter') { // admin user
                    return { text: "Submit", button: true, form: true }
                }
                else { // normal user
                    return { text: "Re-open", button: false, form: false }
                }
            }
            else if (status === "DRAFT") {
                if (created_by === cacheMe.me.id || permissionMode === 'submitter') { // draft user
                    return { text: "Submit", button: true, form: true }
                }
                else { // normal user
                    return { text: "Submit", button: false, form: false }
                }

            }

        }
        else { // before submit

            if (Submitter.includes(cacheMe.me.id)) {

                return { text: "Submit for approval", button: true, form: true }
            } else {
                return { text: "Submit for approval", button: false, form: false }
            }

        }
    }
    
    
    
    //Total labour costs calculation 
    const transformLabourData = (data) => ({
        forecast: data.forecast,
        actual: data.actual,
        total: data.labourNetSales, // Mapping labourNetSales to total
        discount: data.discounts,   // Mapping discounts to discount
    });


    
    // console.log("labourData",labourData);
    // Handle input changes
    const handleLabourCostsChange = (index, key, value) => {
        
        const updatedData = [...labourData];
        updatedData[index][key] = parseFloat(value) || 0; // Update the specific value
        if(key === "actual") {
            updatedData[index]["labourNetSales"] = Number(totalData.netSales) ? ((Number(value) / Number(totalData.netSales)) * 100): 0;
        }
        setLabourData(updatedData);
    }

    useEffect(()=>{
        // debugger;
        const updatedData = labourData.map(item => ({
        ...item,
        labourNetSales: Number(totalData.netSales)
            ? ((Number(item.actual) / Number(totalData.netSales)) * 100) // Optional: Format to 2 decimal places
            : 0,
        }));

        // Update the state with the recalculated data
        setLabourData(updatedData);
    },[totalData.netSales])

    const calculateTotal = (key) => labourData.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);
    // console.log('fohHourly',fohHourly);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectVenue = (venue) => { 
        // debugger;
        setSelectedVenue(venue.venue_title);
        setSelectedVenueId(venue.venue_id);
        setVenueData(venue)
        setIsOpen(false);
        setPOSData(null);
        // setsalesData([]);
        setPOSDataStatus(false);
        let data = venues.filter(v => v.venue_id === venue.venue_id );
        if(data?.length) setVenueData(data[0]);
        // getPOSData(reportingDate, venue.venue_title)
    };

    const searchByVenueList = (v) => {
        setSearchVenueByVal(v);

    }

    const onSubmit = async (status) => {
        // debugger;
        try {
            let data = {
                _id: _id || '',
                venue_id: selectedVenueId,
                notes_for: selectedVenue,
                reporting_date: reportingDate,
                reporting_date_string: moment(reportingDate).format('DD/MM/YYYY'),
                real_time_data: totalData.title,
                net_sales: Number(totalData.netSales),
                comps: Number(totalData.comps),
                total: Number(totalData.total),
                
                fohHourly: transformLabourData(labourData[0]), 
                fohSalary: transformLabourData(labourData[1]),
                bohHourly: transformLabourData(labourData[2]),
                bohSalary: transformLabourData(labourData[3]),
                
                forecasted_labour: calculateTotal("forecast"),
                actual_labour: calculateTotal("actual"),
                total_percent: calculateTotal("labourNetSales"),
                total_discount: calculateTotal("discounts"),

                operational_note: notes.operational,
                briefing_note: notes.briefing,
                service_note: notes.service,
                kitchen_note: notes.kitchen,

                status: status,
                timezone: moment.tz.guess(),
                device: 'web',

                sales_data: salesData.map(({ highlightSales, highlightQty, _id, ...rest }) => rest),
                payment_data: paymentsData,
                tax_data: taxesData,
                cash_data: cashData,
                statistic_data: statisticData
            }

            // console.log("create_eod_report:submit:",data);
            
            let apires = await create_eod_report({ variables: { input: data } });
            // console.log(apires);

            // console.log("create_eod_report:success",apires);
            
            
            // let submit_req = await set_reports_data(data);
            if (apires.data.create_eod_report.status) {
                let result = cleanObject(apires.data.create_eod_report.result);
                if(_id){
                    // setEodData(eodData.map(report => report?._id === result._id  ? result  : report ));
                    // dispatch(set_report_list(eodData.map(report => report?._id === result._id  ? result  : report )));
                    dispatch(update_report_list(result) );
                }else{
                    dispatch(set_report_list([result, ...eodData] ));
                    // setEodData([result, ...eodData]);
                    setTotalCount(totalCount+1);
                }
                
                //     set_status(submit_req.report_data?.status);
                //     set_id(submit_req.report_data?._id);
            }
            toast.success(apires.data.create_eod_report.message, { duration: 4000 });
            
            // setTimeout(()=>{
            //     // onClose()
            //     handleEodTabs('entry');

            // },100)

            fetchData('initial')
            

        } catch(err){
            toast.success(err);

        }
        
        

    }


    useEffect(() => {
        if (isOpen && inputVenueRef.current) {
            inputVenueRef.current.focus();
        }
    }, [isOpen]);

    const getVenuePermit = (venue) => {
        if (venue.venue_title === 'All') return false;

        let Submitter = venue?.eod_submitters;
        let Reopener = venue?.eod_reopen;

        if (Reopener?.includes(cacheMe.me.id) || Submitter?.includes(cacheMe.me.id)) { return true }
        else { return false }

        // if (Reopener?.includes(cacheMe.me.id) && Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Reopener?.includes(cacheMe.me.id)) { return false }
        // else { return false }


    }

    const filterVenues = () => {
        return venues
        .filter(venue => getVenuePermit(venue))
        .filter(venue =>
            venue.venue_title.toLowerCase().includes(searchVenueByVal.toLowerCase())
        );
    };

    const DeletedComponent = () => {
        return <><div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">Delete report</h4>
                    <span className="closeModal" data-for="rightSection_tooltip" data-tip="Close" onClick={() => {  setdeletePopup(false) }}></span>
                </div>
                <div className="freeliModalBody">
                    <p className="delDesc">Are you sure you want to delete this report? This cannot be <span className="undone">undone</span>.</p>
                </div>
                <div className="freeliModalFoot">
                    <button className="btnCancel" onClick={() => setdeletePopup(false)}>Cancel</button>
                    <button className="btnAction" onClick={() => DeleteMsgClick('for_me')}>Delete</button>

                    {/* <button className="btnAction">Delete for all</button> */}

                </div>
            </div>
        </div></>;
    };

    const DeleteMsgClick = async () => {
        // ;
        // debugger
        // ReactTooltip.hide();
        setdeletePopup(false);
        // setdeletePopupID('');
        await delete_eod_report({ variables: { input: {_id} } });
        setEodData([...eodData.filter(f => f._id !== _id)])
        onClose()

        // props.set_report_list([...props.report_list.filter(f => f._id != deletePopupID)])

        // fetchData('initial');
    }

    const checkReportDB = async () => {
        // 
        if (getSubmitStatus().text === "Re-open") {
            onSubmit('REOPEN');
        } else {
            onSubmit('SUBMIT');
        }

    }

    const preventInvalidKeyPress = (e) => {
        if (e.key === '-' || e.key === 'e' || e.key === 'E') {
            e.preventDefault(); // Prevent typing '-' or exponential notation
        }
      };

    return (
        <div className='popUpRightContainer'>
            {deletePopup && <DeletedComponent />}
            <div className='salesReport adminRightHead taskHeader !pl-5'>
                <div onClick={onClose} className="backToMainProperties flex items-center">
                    {/* <span className="closeAdminPanelReport"></span> */}
                    <span
                        className="backToChat"
                        id="closeModal"
                       
                    />
                    <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#closeModal"  place='left' float="true" content="Back" /> 

                    <span className="backtoProperties cursor-pointer">{isPopupOpen === true ? 'Edit report' : "Sales report"}</span>
                </div>
               
                <span onClick={onClose} className="closeReportProperties !top-[22px] !mr-4" id='closeProperties'></span>
                <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#closeProperties"  place='left' float="true" content="Close" /> 
            </div>
            <div className='task_body_area inReportArea'>
                <div className='reportingHead'>
                    <div className='reportingDate inSalesReport'>
                        <p className='reportLabel'>
                            Date<span className='requiredLabel'>*</span>
                        </p>
                        <DatePicker
                            className={classNames("reportingDate Focus", !getSubmitStatus().form ? "readonlycursor" : "")}
                            dateFormat="MMMM dd, yyyy"
                            placeholderText="Enter a date"
                            selected={reportingDate}
                            onChange={(date) => {
                                setReportingDate(date);
                                setPOSData(null);
                                // setsalesData([]);
                                setPOSDataStatus(false);
                                // getPOSData(date,selectedVenue)
                            }}
                            readOnly={!getSubmitStatus().form}
                        />
                    </div>
                    <div className='reportingNotes salesReportVenueArea'>
                        <p className='reportLabel'>
                            Venue (e.g., patria) <span className='requiredLabel'>*</span>
                        </p>
                        <div>
                            <div ref={venueOpen} 
                            // className={`custom_dropdown_task inSalesReportVenue ${selectedVenue === '' ? 'red_border' : ''}`}
                            className={`VenueInputArea `}
                            >
                                {/* <div
                                    className='custom_dropdown-header'
                                    style={{ borderRadius: '4px' }}
                                    onClick={handleDropdownToggle}
                                >
                                    {selectedVenue !== '' ? selectedVenue : <span className='selectVenueLabel'>Select a venue</span>}
                                    <i className={`fa fa-chevron-right custom_icon ${isOpen ? 'open' : ''}`}></i>
                                </div> */}
                                <input 
                                    placeholder='Pick a venue' 
                                    // readOnly={true} 
                                    type='text' 
                                    value={selectedVenue === '' || selectedVenue === 'All' ? '' : selectedVenue} 
                                    className={ `venueTitle ${selectedVenue === '' ? 'red_border' : ''} ${selectedVenue === '' ? 'red_border' : ''}`} 
                                    onClick={handleDropdownToggle}
                                    readOnly={!getSubmitStatus().form}
                                />
                                {selectedVenue !== '' && (
								<span className="!absolute !right-[6px] !h-[18px] !top-[6px] !transform-translate-y-1/2 !cursor-pointer removeFile"
									onClick={() => setSelectedVenue("")}

								>
								</span>
							)}
                                {isOpen && (
                                    <div  className='custom_dropdown-body_task inReport open'>
                                        <div className="searchareaDiv"
                                            style={{ position: 'sticky', top: '-6px', backgroundColor: '#fff' }}
                                        >
                                            <input
                                                ref={inputVenueRef}
                                                style={{ width: '100%' }}
                                                value={searchVenueByVal}
                                                onChange={(event) => {
                                                    searchByVenueList(event.target.value)

                                                }}
                                                className="_inputBar searchLeft popupserach"
                                                type="text"
                                                placeholder="Search"
                                                autoFocus
                                            />
                                            {searchVenueByVal === "" ? <div className="srcSectionPopup" data-for="top_head" data-tip="Search"></div> : ''}
                                            {searchVenueByVal !== "" ? (
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        height: "18px",
                                                        float: "right",
                                                        marginTop: "-35px",
                                                        display: "block",
                                                        top: "42px",
                                                    }}
                                                    className="removeFile"
                                                    onClick={() => setSearchVenueByVal("")}
                                                    data-for="member_tooltip"
                                                    data-tip="Clear Search"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        {filterVenues().length > 0 ? (
                                            filterVenues().map((venue) => (
                                                <div
                                                    key={venue.id} // Use the unique `id` as the key.
                                                    className='custom_dropdown-item'
                                                    onClick={() => 
                                                        handleSelectVenue(venue)
                                                        
                                                    } // Pass `venue.name` to the selection handler.
                                                >
                                                    {venue.venue_title} {/* Display the name property */}
                                                </div>
                                            ))
                                        ) : (
                                            <div className='no-results'>
                                                Venue not found
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <CreateSalesForm
                    sales={sales} setSales={setSales}
                    netSales={netSales} setNetSales={setNetSales}
                    comps={comps} setComps={setComps}
                    total={total} setTotal={setTotal}
                /> */}
                <CreateNetSales totalData={totalData} setTotalData={setTotalData} getSubmitStatus={getSubmitStatus} preventInvalidKeyPress={preventInvalidKeyPress} />
                <CreateTotalSales POSDataStatus={POSDataStatus} salesData={salesData} setsalesData={setsalesData} getSubmitStatus={getSubmitStatus} preventInvalidKeyPress={preventInvalidKeyPress} />
                <CreateHourlyArea labourData={labourData} calculateTotal={calculateTotal} handleLabourCostsChange={handleLabourCostsChange} getSubmitStatus={getSubmitStatus} preventInvalidKeyPress={preventInvalidKeyPress}  />
                <CreateReportFooter notes={notes} setNotes={setNotes} getSubmitStatus={getSubmitStatus} />
                <CreatePayments paymentsData={paymentsData} setPaymentsData={setPaymentsData} paddingTopBotom={3} getSubmitStatus={getSubmitStatus} />
                <CreateTaxes taxesData={taxesData} setTaxesData={setTaxesData} getSubmitStatus={getSubmitStatus}  />
                <CreateCash cashData={cashData} getSubmitStatus={getSubmitStatus}  />

                <div className="tagCoupleBtn pb-4">
                <div className='deleteBtnEod !cursor-pointer' onClick={(e) => { setdeletePopup(true)}}>
                    Delete
                </div>
                {(status === "" || status === "DRAFT") && permissionMode === 'submitter' ?
                <div onClick={() => onSubmit("DRAFT") } 
                    className={`viewFilesButton createRep  !w-[122px] !h-[30px] ${ selectedVenue === ''  ? 'buttonDisabled' : ''}`}>
                    Save as draft
                </div> : ''}
                {/* {status !== 'SUBMIT' ? */}
                <div onClick={() => { checkReportDB() }} 
                    className={`viewFilesButton createSubmit !w-[162px] !h-[30px] 
                        ${(getSubmitStatus().text === "Re-open" && getSubmitStatus().button) || 
                        (selectedVenue && totalData.netSales && parseFloat(totalData.netSales) > 0 && getSubmitStatus().button) 
                        ? '' : 'buttonDisabled'}` }>
                    {getSubmitStatus().text}
                </div>
                {/* // : ''} */}
                    
                <div className="footerLabelticket p-0">
                    {status && status !== "DRAFT" && Reopener.includes(cacheMe.me.id) && Submitter.includes(cacheMe.me.id) && permissionMode === "reopener" ?
                        <span className="reportLabel ticket">
                            <span>You are now in re-opener mode.</span>
                            <span className='switchMore' onClick={() => { setPermissionMode('submitter') }}> Switch to submitter mode</span>
                        </span> : ''}
                    {status && status !== "DRAFT" && Reopener.includes(cacheMe.me.id) && Submitter.includes(cacheMe.me.id) && permissionMode === "submitter" ?
                        <span className="reportLabel ticket">
                            <span>You are now in submitter mode.</span>
                            <span className='switchMore' onClick={() => { setPermissionMode('reopener'); }}>Switch to re-opener mode</span>
                        </span>
                    : ""}
                </div>
                </div>
            </div>
        </div>
    );
};

export default CreateAndEditReport;
