import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from "react-redux";
import { setPopup, set_action_file, set_all_tags, set_downloading_file, set_downloading_progress, set_global_action_data, set_related_tag_popup, set_selected_tag, set_share_link_popup, set_view_tag_files, set_selected_files_data, set_multi_file_ids } from "../../redux/message";
import Axios from "axios";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

const FileDownload = require('js-file-download');

function FloatingActionFiles(props) {
    const [rightSwap, setRightSwap] = useState(false);
    const downloadRef = useRef(null);
    const dispatch = useDispatch()

    const selected_files_data = useSelector(state => state.message.selected_files_data);
    const downloading_progress = useSelector(state => state.message.downloading_progress);
    const multi_file_ids = useSelector(state => state.message.multi_file_ids);

    
    // function downloadAll() {
    //     if (downloadRef.current) {
    //         downloadRef.current.link.click();
    //     }
    // }
    const AfileDownload = (url, filename) => {
    dispatch(set_downloading_file([filename]))
    if (downloading_progress[filename]) return
    dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
    Axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
      }
    }).then((response) => {
    //   console.log("🚀 ~ AfileDownload ~ response:", response)
      FileDownload(response.data, filename);
      let d_progress = { ...downloading_progress };
      d_progress = { ...(delete d_progress[filename] && d_progress) }
      dispatch(set_downloading_progress(d_progress))
    });
  }

    function downloadAll() {
        
        let urls = selected_files_data;
        // //console.log(15, urls)
        var zip = new JSZip();
        var count = 0;
        var zipFilename = ""

        if (urls.length === 1) {
            AfileDownload(urls[0].url ,urls[0].originalname);
        } else {
            
            // if (props.popup.galleryPopup) {
                // zipFilename = "Gallery_"+ new Date().getTime() + ".zip";
            // } else {
                zipFilename = "File_Hub_"+ new Date().getTime() + ".zip";
            // }
            
            // props.setPopup({type: 'floatingAction', data: false});
            // props.setPopup({type:'downloadingPopup', data: true});
            set_downloading_file([zipFilename]);
            let d = [];
            urls.forEach(function(item){
                var filename = item.originalname;
                if(d.indexOf(item.originalname) > -1){
                    filename = new Date().getTime() +item.originalname;
                }else{
                    d.push(item.originalname)
                }
                // loading a file and add it in a zip file
                JSZipUtils.getBinaryContent(item.location ? item.location:item.url, function (err, data) {
                if(err) {
                    throw err; // or handle the error
                }
                zip.file(filename, data, {binary:true});
                count++;
                if (count === urls.length) {
                    zip.generateAsync({type:'blob'}).then(function(content) {
                        saveAs(content, zipFilename);
                        props.setPopup({type:'downloadingPopup', data: false});
                    });
                }
                });
            });
        }
        // props.set_selected_gallery_files([])
        set_selected_files_data([])
        set_multi_file_ids([])

    }

    const closeFloatingAction = () => {
        props.setMarkIteam([]);
        props.setFloatingAction(false);

    }

    const showForward = ()=>{
        // props.set_popup_action_data({multi_file:true});
        // props.setPopup({type:'participants_popup',data:true});

        dispatch(set_global_action_data({ msg: null, forward_type: 'file', multi_file:true, multi_file_ids}));
        dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
    }

    

    return (
        <>

            <div className={classNames("floatingActionContainer", rightSwap ? 'rightSwap' : '')}>
                <span className="floatingSwapBtn" onClick={() => setRightSwap(!rightSwap)}></span>
                <div className="floatingActions _actionForward" id='forwardFiles' onClick={showForward}>Forward</div>
                <div className="floatingActions _actionDownload" id='DownloadFiles' onClick={() => downloadAll()} >Download</div>
                <div className="floatingActions _actionClose" id='closeFiles' onClick={closeFloatingAction}>Close</div>
                {rightSwap &&(
                <>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#forwardFiles" float="true" content="Forward"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#DownloadFiles" float="true" content="Download"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#closeFiles" float="true" content="close"></Tooltip>
</>)}
            </div>
           
        </>
    )
}


export default FloatingActionFiles;