import { useState, createContext } from "react";

export const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const [selected_ids, setSelected_ids] = useState([]);

    return (
        <UploadContext.Provider value={{ selected_ids, setSelected_ids }}>
            {children}
        </UploadContext.Provider>
    );
};
