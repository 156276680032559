import React,  { useState, useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GETVENULIST, GET_ME } from '../../Utils/GraphqlQueries';
import {useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { set_venue_ids, set_selected_venue_id, set_selected_venue_title, set_venue_list } from '../../redux/eodSlice';


const LeftBarEod = ({searchStr,setSearchStr }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selected_venue_id = useSelector(state => state.eod.selected_venue_id) || "";
    // console.log('selected_venue_id',selected_venue_id)

    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });

    const handleBack = () => {
        setSearchStr('');
        navigate(-1);
    }
    const [venueList, setVenuesList] = useState([]);
    const { data: venue_list } = useQuery(GETVENULIST,{ fetchPolicy: 'no-cache' })
    // console.log('LeftBarEod',venue_list,venueList)
    useEffect(() => {
        if (venue_list?.get_venue_list?.status) {
            setVenuesList(venue_list?.get_venue_list.result);
            set_venue_list(venue_list?.get_venue_list.result)
        }
    }, [venue_list])
    
    // const filteredVenu = venueList?.filter((venue) => {
    //     return (
    //         venue.venue_title &&
    //         venue.venue_title.toLowerCase().includes(searchStr.toLowerCase())
    //     );
    // });

     const getVenuePermit = (venue) => {
        if (venue.venue_title === 'All') return true;

        let Submitter = venue?.eod_submitters;
        let Reopener = venue?.eod_reopen;

        if (Reopener?.includes(cacheMe.me.id) || Submitter?.includes(cacheMe.me.id)) { return true }
        else { return false }
        


        // if (Reopener?.includes(cacheMe.me.id) && Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Reopener?.includes(cacheMe.me.id)) { return false }


    }

    const filterVenues = () => {
        return venueList
        .filter(venue => getVenuePermit(venue))
        .filter(venue =>
            venue.venue_title.toLowerCase().includes(searchStr.toLowerCase())
        );
    };
    

    return (
        <div className='mt-4 pt-[10px] border-t border-t-[#475674]'>
            <div
                onClick={handleBack}
                className="bactToAllProject !px-[20px] font-bold thread_back text-[#BFDCFF] "
            >
                <span className="project_icon"></span>
                <span className='font-bold text-[#BFDCFF]'>Venues</span>
            </div>
            <div className='group_container mt-[10px] archiveLastChild projectListTask !h-[calc(100vh-480px)] !overflow-y-auto'>
                <Scrollbars
                    className="custom-scrollbars"
                    renderThumbVertical={({ style, ...props }) => (
                        <div className="track"
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: "#318fff",
                                borderRadius: "50px",
                            }}
                        />
                    )}
                    autoHide={false}
                >
                  
                     {filterVenues().length > 0 ? (
                        filterVenues().map((v) => (
                            <li key={'_venue' + v.id} onClick={() => {
                                // debugger
                                dispatch(set_selected_venue_id(v.venue_id));
                                dispatch(set_selected_venue_title(v.venue_title));
                            }} 
                            // className="conv_list !pl-[20px]"
                            className={`conv_list !pl-[20px] ${selected_venue_id === v.venue_id ? 'selected' : ''}`}
                            >
                                <span className="project_icon"></span>
                                <span className="conv_list_title" title={v.venue_title}>
                                    {v.venue_title}
                                </span>
                            </li>
                        ))
                    ) : (
                        <p  className="no-projects-message text-[#fff] text-center pt-10">No venue found.</p>
                    )}
                </Scrollbars>
            </div>
        </div>
    );
};

export default LeftBarEod;
