import { useClickAway } from '@uidotdev/usehooks';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Moment from 'react-moment';
import { ROLE_ACCESS } from '../../../Utils/GraphqlMutaion';
import { FIND_MODULES } from '../../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';


const RolesDetails = ({ selectedRole, setRoles, setRoleDetailsPopup }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortKey, setSortKey] = useState('name'); // default sorting by name
    const [sortOrder, setSortOrder] = useState('asc'); // default sorting order
    const [accessNameForSearch, setAccessNameForSearch] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [createAccess, setCreateAccess] = useState(false);
    const [openOptions, setOpenOptions] = useState(null);
    const [thisRole, setThisRole] = useState(selectedRole);
    const [modules, setModules] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [loader, setLoader] = useState(false);

    const { loading: modulesLoading, data: modulesData } = useQuery(FIND_MODULES, { variables: { company_id: thisRole.company_id }, fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (modulesData) {
            setModules(modulesData.modules.data)
        }
    }, [modulesData])
    
    useEffect(() => {
        // console.log('modulesData 30', modules);
        if (modules.length > 0) {
            let al = []
            for(let i=0; i<modules.length; i++){
                al.push({value: modules[i].module_title, label: modules[i].module_title})
                for(let j=0; j<modules[i].sub_module.length; j++){
                    al.push({value: modules[i].sub_module[j], label: modules[i].module_title +'->'+ modules[i].sub_module[j]})
                }
            }
            // console.log('modules:',modules);
            setAccessList(al)
        }
    }, [modules])

    const [update] = useMutation(ROLE_ACCESS);

    const ref = useClickAway(() => {
        setOpenOptions(false);
    });

    if (!thisRole) return null;

    // Filter subModules based on search query
    const filteredAccess = thisRole.role_access.filter(accessList =>
        accessList.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Sort filteredSubModules
    const sortedAccess = filteredAccess.sort((a, b) => {
        const aValue = a[sortKey];
        const bValue = b[sortKey];

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleMoreOptionsClick = (accessId) => {
        setOpenOptions((prev) => (prev === accessId ? null : accessId));
    };

    // Handle sort change
    const handleSortChange = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const handleCreateSubModule = async () => {
        setCreateAccess(false)
        // console.log(56, thisRole)
        setLoader(true);
        try {
            const { data: roleAddData } = await update({
                variables: {company_id: thisRole.company_id, role_access: selectedTeam ? selectedTeam.map((t) => t.value) : [], role_id: thisRole.role_id, req_type: 'add'}
            });
            const { status, message, data } = roleAddData.role_add;
            if (status) {
                // console.log(70, data)
                
                setRoles((prevRoles) => [...prevRoles.map((e)=> e.role_id === data.role_id ? {...data} : e)]);
                setThisRole({...data})
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            <div className='right_containerr'>
                <div className="userInfoModal">
                    <div className="adminRightHead" onClick={() => setRoleDetailsPopup(false)}>
                        <span className="closeAdminPanel">Back to Role management</span>
                    </div>
                    <div className="teamInfoNameSection">
                        <div className="teamInfoName">{thisRole?.role_title} ({thisRole?.role_access?.length})</div>
                        <div className="teamInfoDate">
                            <span>Created by: {thisRole?.created_by_name} On: <Moment format="MMM Do, YYYY">{thisRole?.created_at}</Moment></span> |
                            <span> Last updated by: {thisRole?.createdBy} On: <Moment format="MMM Do, YYYY h:mm A">{thisRole?.created_at}</Moment></span>
                        </div>
                    </div>
                    <div className="tagInfoActionSection">
                        <div style={{ width: '400px', position: 'relative', marginRight: '12px' }}>
                            <input
                                className="searchTeamUser"
                                type="text"
                                placeholder="Search access"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                autoFocus={true}
                            />
                            <span onClick={() => setSearchQuery('')} className="leftSearchCloseBtn remove" style={{ top: '8px', right: '10px' }}></span>
                        </div>
                        <div onClick={() => setCreateAccess(true)}>
                            <button className="userCreateBtn tooltip5 !mr-0">Add new access</button>
                        </div>
                    </div>
                    <div className='usersTableContainer'>
                        <div className="userTableHead">
                            <ul className="">
                                <li className={`_userName !w-[95%] ${sortOrder === 'asc' ? 'asce' : 'desc'} sort_active`}
                                    onClick={() => handleSortChange('name')}>
                                    <span className={`sortIcons`}></span>Name
                                </li>
                                <li className="_userAction !justify-center !w-[5%]">
                                    Action
                                </li>
                            </ul>
                        </div>
                        <div className='userTableBody'>
                            {sortedAccess.length > 0 ? (
                                sortedAccess.map((access, akey) => (
                                    <div className='relative' key={akey}>
                                        <ul className="_userList">
                                            <li className="_userName" style={{ width: "95%" }}>
                                                <span className="_userNameTxt">{access}</span>
                                            </li>
                                            <li onClick={() => handleMoreOptionsClick(access.id)} className="_userAction !justify-center" style={{ width: "5%" }}>
                                                <span className="userActionIcon"></span>
                                            </li>
                                        </ul>

                                        {openOptions === akey && (
                                            <ul ref={ref} className="userActionPopup !absolute">
                                                <li className="!text-start">Active access</li>
                                                <li className="!text-start">Edit access</li>
                                                <li className="!text-start">Delete access</li>
                                            </ul>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="noResults !text-center mt-48">
                                    <p>No access found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {createAccess &&
                <div className="backwrap">
                    <div className="createTeamModal">
                        <div className="createTeamModalHead">
                            <h4 className="popupTitle">Create access</h4>
                            <span className="closeModal" onClick={() => setCreateAccess(false)}></span>
                        </div>
                        <div className="createTeamModalBody">
                            {/* <div className="crTeam_inputGroup">
                                <label htmlFor="moduleName">New access name</label>
                                <input value={accessNameForSearch} onChange={(e) => setAccessNameForSearch(e.target.value)} type="text" maxLength="25" placeholder="Write access name" />
                            </div> */}
                            <div className="cu_inputGroup !mb-0 cu_inputGroup_select mt-2">
                                <label>Select access</label>
                                <Select
                                    className="select-ecosystem"
                                    value={selectedTeam}
                                    onChange={setSelectedTeam}
                                    options={accessList}
                                    isMulti
                                    menuPlacement="top"
                                    placeholder="Assign to specific Team(s) or leave empty for all"
                                />
                            </div>
                            <div className='createTeamModalFoot !px-0'>
                                <button onClick={() => handleCreateSubModule()} className={`${selectedTeam ? 'btnAction' : 'btnAction opacity-50 pointer-events-none'}`}>
                                    Add Access
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default RolesDetails;
