import React, { useEffect } from 'react';
import { UPDATE_USER_MUTATION, DELETE_USER_MUTATION, SET_NEW_PASSWORD } from '../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import toast, { Toaster } from 'react-hot-toast';

const AdminWarningPopup = ({ onClose, title, type, desc, actionuserData, setall_user_data }) => {
    const [update_user_m] = useMutation(UPDATE_USER_MUTATION)
    const [delete_user_m] = useMutation(DELETE_USER_MUTATION)
    const [resetPassword] = useMutation(SET_NEW_PASSWORD)
    
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onClose]);


    const Confirm = () => {
        if (type === 'make_admin') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        role: 'Admin'
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, role: 'Admin' } : u))
            toast.success('User updated successfully');

        } else if (type === 'active_eod') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        eod_report: true
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, eod_report: true } : u))
            toast.success('User updated successfully');
        } else if (type === 'deactive_eod') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        eod_report: false
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, eod_report: false } : u))
            toast.success('User updated successfully');
        }
        else if (type === 'active_user') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        is_active: "1"
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, is_active: "0" } : u))
            toast.success('User updated successfully');
        } else if (type === 'deactive_user') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        is_active: "0"
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, is_active: "0" } : u))
            toast.success('User updated successfully');
        } else if (type === 'remove_admin') {
            update_user_m({
                variables: {
                    input: {
                        user_id: actionuserData.id,
                        role: 'Member'
                    }
                }
            });
            setall_user_data(prev => prev.map(u => u.id === actionuserData.id ? { ...u, role: 'Member' } : u))
            toast.success('User updated successfully');

        } else if (type === 'user_delete') {
            setall_user_data(prev => prev.filter(u => u.id !== actionuserData.id))
            // console.log(actionuserData)
            delete_user_m({
                variables: {
                    input: {
                        id: actionuserData.id
                    }
                }
            });
            toast.success('User deleted successfully');
        } else if (type === 'reset_password') {
            resetPassword({
                variables: {
                    input: {
                        email: actionuserData.email,
                        password: 'a123456',
                        password2: 'a123456',
                    }
                }
            });
            toast.success('Password reset successfully');
        }

        setTimeout(() => {
            onClose();
        }, 500)

    }
    return (
        <>
            <div><Toaster /></div>
            <div className="backwrap">
                <div className="freeliModal">
                    <div className="freeliModalHead">
                        <h4 className="popupTitle">{title}</h4>
                        <span className="closeModal" data-for="rightSection_tooltip" data-tip="Close" currentitem="false" onClick={onClose}></span>
                    </div>
                    <div className="freeliModalBody">
                        <p className="delDesc">{desc}</p>
                    </div>
                    <div className="freeliModalFoot">
                        <button className="sweetCancel mr-3" onClick={onClose}>Cancel</button>
                        <button className="sweetSave hover:!bg-[#318EFA]" onClick={Confirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default AdminWarningPopup;