import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';

const SearchRoomOptions = ({ setSearchRoomListOptions, setHeadSearchOptions }) => {
    const navigate = useNavigate()
    const handleClickAway = () => {
        setSearchRoomListOptions(false)
    };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            {/* <ul className="searchSelectionPopup">
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=all' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">All file(s)</li>
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=image' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">Image(s)</li>
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=video' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">Video(s)</li>
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=voice' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">Voice(s)</li>
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=audio' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">Audio(s)</li>
                <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=docs' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="">Docs(s)</li>
            </ul> */}
            <div className="tooltip w-[200px] z-50 py-2 h-auto -left-[30px] top-[65px] mt-2 bg-white border border-gray-200 rounded-lg">
                <ul className="text-gray-700">
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=all' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        All file(s)
                    </li>
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=image' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Image(s)
                    </li>
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=video' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Video(s)
                    </li>
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=voice' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Voice(s)
                    </li>
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=audio' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Audio(s)
                    </li>
                    <li onClick={() => { navigate({ pathname: 'file_hub/files', search: '?file_type=docs' }); setSearchRoomListOptions(false); setHeadSearchOptions(false) }} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Docs(s)
                    </li>
                </ul>
            </div>
        </ClickAwayListener>
    );
};

export default SearchRoomOptions;