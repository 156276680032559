import React, { useState } from 'react'
import { MdOutlineError } from "react-icons/md";
import { InputValidationCheck } from '../../ValidationCheck';


const TeamWarnPopup = ({ desc, onConfirm, onClose, remove_conv, venueSingle }) => {
    const [inputValue, setInputValue] = useState("");


    const handleConfirm = () => {

        if (inputValue === venueSingle.venue_title) {
            onConfirm(venueSingle);
        } else {
            alert("Please type the correct name to confirm.");
        }
    };

 const handleTitleChange = (e) => {
        const { value } = e.target;
    
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setInputValue(value);
            return;
        }
    
        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (InputValidationCheck(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }
    
        // Update state with the validated value
        setInputValue(trimmedValue);
    };


    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">Are you absolutely sure?</h4>
                    <span className="closeModal" data-for="rightSection_tooltip" onClick={onClose}></span>
                </div>
                <div className="freeliModalBody !pb-0">
                    {/* <p className="delDesc">{desc}</p> */}
                    <div className="p-2 bg-[#fef8f5]">
                        <p className="text-[#0b1f47] text-sm mb-4 text-left font-semibold">
                            You are about to delete the venue{" "}
                            <span className="font-bold">"{venueSingle.venue_title}"</span>, which may contain
                            ticket(s) and file(s).{" "}
                            <span className="text-[#5e5ed7] cursor-pointer">
                                View details
                            </span>
                        </p>
                        <div className="flex items-start gap-2 mb-4">
                            <span className="text-red-500 text-lg"><MdOutlineError /></span>
                            <p className="text-[#0b1f47] text-sm text-left">
                                This action is irreversible. Please type{" "}
                                <span className="font-bold px-1 bg-gray-100 border border-gray-300">
                                    {venueSingle.venue_title}
                                </span>{" "}
                                to confirm.
                            </p>
                        </div>
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleTitleChange}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm"
                            placeholder={`Type "${venueSingle.venue_title}" to confirm`}
                        />
                    </div>

                </div>

                <div className="freeliModalFoot">
                    <button className="sweetCancel mr-3 !text-[14px]" onClick={onClose}>Cancel</button>
                    {/* <button className="sweetSave !text-[13px] !w-[140px] !leading-4 !mr-2" onClick={remove_conv} >Remove from conversation(s)</button> */}                    
                    {venueSingle.venue_title === inputValue ?
                        (<button className="sweetSave !text-[14px]" onClick={handleConfirm}>Confirm</button>)
                        :
                        (<button className="sweetSave" style={{ opacity: '0.5' }}>Confirm</button>)
                    }


                </div>
            </div>
        </div>

    )
}

export default TeamWarnPopup;