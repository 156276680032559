import classNames from "classnames";
import { EditorBox } from './EditorBox'
import { useSelector } from "react-redux";
import AISendBox from "../Ai/AISendBox";

export const MessageSendBox = (props) => {
    const { msg_ai_active, reply_main_msg } = useSelector(state => state.message)
    const ai_active = msg_ai_active || reply_main_msg?.msg_type.includes('aiReplyReq')
    return (
        <>
            <div id="bottomBox" style={{ zIndex: 1 }} className={classNames("bottom_bar", props.is_reply_msg === 'yes' ? 'bottomBox_reply' : props.room?.group !== 'yes' ? 'private_conv' : 'group_conv', ai_active ? '!pr-0 !pl-0' : '', (!ai_active && props.is_reply_msg === 'yes') && '!pl-[50px]')}>
                {/* <div className="send_msg_info"></div> */}
                {/* <PrivateMsgUsers /> */}
                {ai_active ?
                    <AISendBox type={'msg'} />
                    :
                    <EditorBox room={props.room} type={'conv'} me={props.me} is_reply_msg={props.is_reply_msg} editorRef={''} sendMessageFun={''} startTypingFun={''} discussion={false} set_clear_text_icon={() => console.log()} />
                }
            </div >
        </>
    )
}