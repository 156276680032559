/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { useParams, Outlet, useLocation, useNavigate } from "react-router-dom";
import { GET_ME, GET_SINGLE_ROOM, GET_ROOM_MESSAGES, GET_ALL_USERS, GET_FILTER_MESSAGES, GET_RUNNING_STATUS } from '../../Utils/GraphqlQueries';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { RightTop } from "./RightTop";
import { MessageSendBox } from "./MessageSendBox";
import { modifyMyMsg, GET_SEPERATOR, scrollToBottom, IsGroupMsg, isValidConvId } from "../../Utils/Common";
import { useSelector, useDispatch } from 'react-redux';
import FilterStatus from "./Messages/FilterStatus";
import { set_active_conversation, set_allUsers, set_messages, set_user, setPopup, set_conv_calling_data } from "../../redux/message";
import { toast, Toaster } from "react-hot-toast";
import Downloading from "./Messages/Downloading";
import SharePopup from "./Messages/SharePopup";
import { UserSelection } from "../GlobalPopups/UserSelection";
import { TagPopup } from '../GlobalPopups/TagPopup.js';
import RoomStatus from "./Messages/RoomStatus";
import CustomTitle from "./CustomTitle.js";
import ComingSoon from './ComingSoon';
import { PrivateMsg } from "./PrivateMsg.js";
import { UploadProvider } from "../../context/UploadContext.js";
import FileUploadNew from "./FileUpload/FileUploadNew.js";
import VoiceMessage from "./Messages/VoiceMessage.js";
import debounce from "lodash.debounce";
import UserMiniProfile from "./UserMiniProfile.js";

const MessagesContainer = React.lazy(() => import('./Messages/MessagesContainer'));

export const ConversationSection = () => {
    const params = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const [miniForwardList, setMiniForwardList] = useState([])
    const { kick_out_data, tag_popup, private_msg, refresh, downloading_progress, forward_msg_popup, share_link_popup, custom_title_popup, upload_popup, voice_record } = useSelector(state => state.message)
    const messages = useSelector((state) => state.message.msgs);
    const active_conv = useSelector(state => state.message.active_conversation)
    const client = useApolloClient();
    const { me } = client.readQuery({ query: GET_ME });
    const { users } = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: me.company_id } });
    const [online_users, setonline_users] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, totalPages: 1, total: 0 });
    const [topSearchBarPopUp, setTopSearchBarPopUp] = useState(true);
    // const [allMessageLoaded, setAllMessageLoaded] = useState(false);
    const [comingSoon, setComingSoon] = useState(false);
    const [ThisRoom, setThisRoom] = useState(null);
    const [msgLoader, setMsgLoader] = useState(false)
    const [filter_loader, set_filter_loader] = useState(false)

    const msgContainerRef = useRef(null);
    const prevScrollHeight = useRef(0);

    const SCROLL_THRESHOLD = 400;

    useEffect(() => {
        if (me) {
            dispatch(set_user(me))
        }
    }, [me])

    useEffect(() => {
        if (users) {
            dispatch(set_allUsers(users))
        }
    }, [users])



    const { loading: ThisRoomLoading } = useQuery(GET_SINGLE_ROOM, {
        variables: isValidConvId(params.conversation_id) ? { conversation_id: params.conversation_id } : undefined,
        skip: !isValidConvId(params.conversation_id),
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.room) {
                setThisRoom(data.room)
                dispatch(set_active_conversation(data.room))
            }
        },
        onError: (error) => {
            console.log("🚀 ~ error", error)
        },
    });

    const [fetchMessages, { loading: MessagesLoading, error: MessagesError, data: Messages }] = useLazyQuery(GET_ROOM_MESSAGES, { fetchPolicy: 'no-cache' });
    const [fetchRunningCall, { data: RunningCall }] = useLazyQuery(GET_RUNNING_STATUS, { fetchPolicy: 'no-cache' });

    const [fetchFilterMessages] = useLazyQuery(GET_FILTER_MESSAGES, { fetchPolicy: 'no-cache' });
    const scrollToRef = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(()=>{
        if(RunningCall?.jitsi_running_status?.status){
            dispatch(set_conv_calling_data(RunningCall?.jitsi_running_status?.voip_busy_conv))
        }
    },[RunningCall])

    const initialMethod = async () => {
        set_filter_loader(true);

        try {
            const { conversation_id, filter_name, search_string } = params;
            if (conversation_id === 'tasks') return;
            const fetchMessagesData = async (variables) => {
                if (filter_name) {
                    return await fetchFilterMessages({ variables });
                } else {
                    return await fetchMessages({ variables });
                }
            };

            let variables = {
                conversation_id,
                page: 1,
            };

            if (filter_name) {
                variables.type = filter_name;
                if (filter_name === 'str') {
                    variables.str = search_string;
                }
            }

            const res = await fetchMessagesData(variables);
            // Uncomment and implement this if needed
            afterFetchMethod(res, !!filter_name, 1);

            fetchRunningCall({
                variables: { conversation_id: conversation_id }
                
            })

        } catch (error) {
            console.error("Error fetching messages:", error);
        } finally {
            set_filter_loader(false);
        }
    };


    useEffect(() => {
        setPagination({ page: 1, totalPages: 1, total: 0 })
        dispatch(set_messages({ data: [], is_reply_msg: 'no', page: 1 }))
        initialMethod();
    }, [params.conversation_id, params.filter_name, params.search_string])

    useEffect(() => {
        if (refresh > 0) {
            setPagination({ page: 1, totalPages: 1, total: 0 })
            dispatch(set_messages({ data: [], is_reply_msg: 'no', page: 1 }))
            initialMethod();
        }
    }, [refresh])


    let l = 0;
    const onScrollMsg = (e) => {
        if (msgLoader) return false;
        if (e.target.firstChild !== null) {
            let container = msgContainerRef.current;
            const { scrollTop, scrollHeight } = container;
            if (scrollTop > l) {

            } else {
                const THRESHOLD = SCROLL_THRESHOLD * (pagination.page > 8 ? 3 : pagination.page > 1 ? 2 : 1);
                // if (scrollTop < (e.target.scrollHeight / 2) && elemScrollTop !== 1 && l !== 0) {
                if (scrollTop < THRESHOLD && scrollTop !== 1 && l !== 0) {

                    // if (!allMessageLoaded) {
                    console.log("🚀 ~ onScrollMsg ~ pagination:", pagination)
                    if (pagination.totalPages > pagination.page) {
                        prevScrollHeight.current = scrollHeight;
                        setMsgLoader(true)
                        loadPreviousMessages()
                    }
                    // }
                }
            }
            l = scrollTop;
        }
    }


    // onScrollMsg version 1
    // const onScrollMsg = useCallback(
    //     debounce((e) => {
    //         if (msgLoader) return; // Prevent if already loading
    //         const container = msgContainerRef.current;
    //         if (container) {
    //             const THRESHOLD = SCROLL_THRESHOLD * (pagination.page > 8 ? 3 : pagination.page > 1 ? 2 : 1);
    //             const { scrollTop, scrollHeight } = container;

    //             // Check if near the top of the scroll container

    //             if (scrollTop < THRESHOLD && !allMessageLoaded) {
    //                 if (pagination.totalPages > pagination.page) {
    //                     prevScrollHeight.current = scrollHeight; // Save scroll height before loading
    //                     loadPreviousMessages();
    //                 }
    //             }
    //         }
    //     }, 200), // Debounce with 200ms delay
    //     [msgLoader, allMessageLoaded, pagination]
    // );

    const loadPreviousMessages = async () => {
        // allMessageLoaded || 
        if (msgLoader) return; // Prevent multiple calls
        setMsgLoader(true);

        try {
            let variables = {
                conversation_id: params.conversation_id,
                page: pagination.page + 1,
            };

            if (params.filter_name) {
                variables['type'] = params.filter_name
                if (params.filter_name === 'str') {
                    variables['str'] = params.search_string
                }
            }
            console.log("🚀 ~ loadPreviousMessages ~ variables:", variables)
            console.log("🚀 ~ loadPreviousMessages ~ params:", params)
            console.log("🚀 ~ loadPreviousMessages ~ params.filter_name:", params.filter_name)

            const res = params.filter_name
                ? await fetchFilterMessages({ variables })
                : await fetchMessages({ variables });

            // Ensure afterFetchMethod is called only once
            afterFetchMethod(res, !!params.filter_name, pagination.page + 1);

            let res_d = !!params.filter_name ? res.data.filter : res.data.messages;

            if (res_d.pagination.totalPages <= pagination.page + 1) {
                // setAllMessageLoaded(true);
                toast.success('All messages are loaded')
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMsgLoader(false); // Ensure flag is reset in both success and error
        }
    };

    const afterFetchMethod = async (res, isFilter, currentPage) => {
        if (!res || !res.data) return; // Ensure valid response

        const data = isFilter ? res.data.filter : res.data.messages;
        const res_msgs = await modifyMyMsg(data.msgs);
        setPagination({ ...data.pagination });
        dispatch(set_messages({ data: res_msgs, is_reply_msg: "no", page: currentPage }));

        // Restore scroll position
        const container = msgContainerRef.current;
        if (currentPage > 1) {
            // Restore scroll position for newly prepended items
            requestAnimationFrame(() => {
                container.scrollTop = container.scrollHeight - prevScrollHeight.current;
            });
        } else {
            setTimeout(() => {
                scrollToBottom("main_msg_scroll_div");
            }, 300);
        }
        setMsgLoader(false); // Ensure loader is reset
    };

    useEffect(() => {
        dispatch(setPopup({ key: 'upload_popup', data: false }))
        dispatch(setPopup({ key: 'voice_record', data: false }))
        dispatch(setPopup({ key: 'private_msg', data: false }))
    }, [params])

    useEffect(() => {
        if (kick_out_data?.kickOut_type === 'conversation') {
            if (params.conversation_id === kick_out_data.conversation_id) {
                toast.success('You have been removed from this room')
                navigate('/connect/' + me.id)
            } else {
                console.log('Room has been removed');
            }
        }
    }, [kick_out_data])

    return (
        <div className="right_container">
            <Toaster />
            {!ThisRoomLoading && ThisRoom &&
                <>
                    <RightTop
                        room={ThisRoom}
                        me={me}
                        online_users={online_users}
                        setComingSoon={setComingSoon}
                        setTopSearchBarPopUp={setTopSearchBarPopUp}
                        topSearchBarPopUp={topSearchBarPopUp}
                        params={params.filter_name}
                    />
                    {params.filter_name && <FilterStatus type={params.filter_name} conversation_id={params.conversation_id} setTopSearchBarPopUp={setTopSearchBarPopUp} />}
                    {(!filter_loader && msgLoader) && <div className="loaderMain mini_loader"></div>}
                    <div className={`msg_Container relative ${params.filter_name && '!h-[calc(100%-80px)]'} `} id="main_msg_scroll_div" onScroll={onScrollMsg} ref={msgContainerRef} style={(active_conv?.close_for !== 'yes' && active_conv?.archive !== 'yes') ? {} : { height: 'calc(100% - 120px)' }}>

                        {messages.map((m, k) =>
                            <MessagesContainer
                                data={m}
                                room={ThisRoom}
                                me={me}
                                thread={false}
                                edit_msg_id={''}
                                all_users={users}
                                all_msgs={messages}
                                seperator={GET_SEPERATOR(messages[k - 1], m)}
                                is_group_msg={IsGroupMsg(messages[k - 1], m)}
                                key={m.msg_id}
                                filterHead={params.filter_name}
                            />
                        )}

                        {(messages.length === 0 && !params.filter_name && !MessagesLoading) ?
                            <div className="notFound_container empty_msg">
                                <p><i className="fas fa-lock"></i> Transmission of all messages, files and calls are point-to-point encrypted.</p>
                                <h2>Write your first message or upload a file to start with.</h2>
                            </div>
                            : (messages.length === 0 && !params.filter_name && !MessagesLoading) &&
                            <div className="notFound_container empty_msg">
                                <h2>No message found matching this filter criteria.</h2>
                            </div>
                        }
                        {(messages.length === 0 && params.filter_name && !MessagesLoading && !filter_loader) &&
                            <div className="notFound_container empty_msg">
                                <h2>No message found matching this filter criteria.</h2>
                            </div>
                        }
                        {(filter_loader) &&
                            <div className={`msg_screen_loader fixed w-[calc(100%-346px)] ${params.filter_name ? 'h-[calc(100%-76px)]' : 'h-[calc(100%-178px)]'} flex items-center justify-center right-0 top-[76px] bg-[#f9f9f9b0]`}>
                                <div className="loaderMain mini_loader"></div>
                            </div>
                        }
                    </div>
                    {location.hash?.includes('#mention_') && <UserMiniProfile />}
                    {Object.keys(downloading_progress).length !== 0 && <Downloading />}
                    {(forward_msg_popup && location.pathname.indexOf('file_hub') === -1) && <UserSelection type="forward_msg" setMiniList={setMiniForwardList} miniList={miniForwardList} />}
                    {(share_link_popup && location.pathname.indexOf('file_hub') === -1) && <SharePopup />}
                    {(custom_title_popup && location.pathname.indexOf('file_hub') === -1) && <CustomTitle />}
                    {(tag_popup && location.pathname.indexOf('file_hub') === -1) && <TagPopup type='file' viewType={'all'} tag_list={[]} />}
                    {private_msg && <PrivateMsg />}
                    <RoomStatus room={ThisRoom} me={me} _msgs={messages} />
                    {(active_conv?.close_for !== 'yes' && active_conv?.archive !== 'yes' && !params.filter_name) &&
                        <MessageSendBox
                            room={ThisRoom}
                            me={me}
                            online_users={online_users}
                            is_reply_msg='no'
                        />}
                </>

            }
            {
                comingSoon && <ComingSoon setComingSoon={setComingSoon} />
            }
            {ThisRoom && <Outlet context={[ThisRoom, setThisRoom]} />}
            {upload_popup && <UploadProvider><FileUploadNew is_reply_msg={params.msg_id ? 'yes' : 'no'} type="file_upload" /> </UploadProvider>}
            {voice_record && <VoiceMessage />}

        </div>

    )
}