import React, { useEffect, useState } from 'react';
// import toast from 'react-hot-toast';
import Select from 'react-select';
import { GET_TEAMS } from '../../../Utils/GraphqlQueries';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_VENUE } from '../../../Utils/GraphqlMutaion';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from "react-redux";

import { venue_create_update_api } from "./Venue_Create_update_Api";
import { InputValidationCheck } from '../../ValidationCheck';



function CreateEditVenue({ type, setCreateVenue, setVenuesList, venueSingle, setVenueDetails }) {
    const [loader] = useState(false);
    // const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState('');
    const [endpointURL, setEndpointURL] = useState('');
    const [endpointToken, setEndpointToken] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(null); // State to manage selected team
    const [teamOptions, setTeamOptions] = useState([]);
    const { data: teamsData } = useQuery(GET_TEAMS);
    const all_venue = useSelector(state => state.admin.all_venue);
    // const createTeam = async () => {
    //     setLoader(true)

    //     setCreateCategory('')
    // };

    // useEffect(() => {
    //     if (teamsData?.teams) {
    //         let team = [];
    //         for (const iterator of teamsData.teams) {
    //             team.push({ value: iterator.team_id, label: iterator.team_title });
    //         }
    //         setTeamOptions(team);
    //     }
    // }, [teamsData]);



    useEffect(() => {
        if (teamsData?.teams) {
            const teamOptions = teamsData.teams.map((iterator) => ({
                value: iterator.team_id,
                label: iterator.team_title
            }));
            setTeamOptions(teamOptions);
        }

        if (type === 'edit' && teamsData?.teams) {
            const teamTitle = teamsData.teams.find((a) => a.team_id === venueSingle.team_id);
            if (teamTitle) {
                setSelectedTeam({ value: teamTitle.team_id, label: teamTitle.team_title });
                setTitle(venueSingle.venue_title);
                setEndpointURL(venueSingle.endpointURL || "")
                setEndpointToken(venueSingle.endpointToken || "")
            }
        }
    }, [teamsData, type, venueSingle]);




    useEffect(() => {

        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setCreateVenue(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [setCreateVenue]);


    const [create_venue] = useMutation(CREATE_VENUE)


    const venue_Create_Update = async (types) => {
        try {
            const response = await venue_create_update_api(types, create_venue, all_venue, title, selectedTeam.value, endpointURL, endpointToken);

            if (response?.actionType === "create" && response?.response?.data?.create_update_venue?.status) {
                toast.success("Venue created successfully", { duration: 4000 });
                setVenuesList((prev) => [
                    response.response.data.create_update_venue.result,
                    ...prev,
                ]);
                setCreateVenue(false);
            } else if (response?.actionType === "update" && response?.response?.data?.create_update_venue?.status) {
                toast.success("Venue updated successfully", { duration: 4000 });
                setVenuesList((prev) => {
                    const newVenue = {
                        ...response.response.data.create_update_venue.result,
                        _id: venueSingle._id,
                    };
                    return prev.map((venue) =>
                        venue._id === venueSingle._id ? newVenue : venue
                    );
                });
                setCreateVenue(false);
                setVenueDetails((prev) => (prev === true ? false : prev));
            } else {
                toast.error("Venue title already exists!", { duration: 4000 });
                setCreateVenue(false);
                if (types === "update") {
                    setVenueDetails((prev) => (prev === true ? false : prev));
                }
            }
        } catch (error) {
            console.error("Error in venueUpdate:", error);
            toast.error("An unexpected error occurred. Please try again later.", { duration: 4000 });
            setCreateVenue(false);
        }
    };

    const checkmess = () => {
        if (type === 'edit') {
            return (
                venueSingle?.team_id === selectedTeam?.value &&
                venueSingle?.venue_title === title &&
                venueSingle?.endpointURL === endpointURL &&
                venueSingle?.endpointToken === endpointToken
            );
        }
        return true;
    };
    const handleTitleChange = (e) => {
        const { value } = e.target;
    
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setTitle(value);
            return;
        }
    
        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (InputValidationCheck(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }
    
        // Update state with the validated value
        setTitle(trimmedValue);
    };

    const handleEndpointURLChange = (e) => {
        const { value } = e.target;
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setEndpointURL(value);
            return;
        }
    
        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        // if (InputValidationCheck(value)) {
        //     console.warn('Invalid input for title'); // Can log or display an error message here
        //     return;
        // }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }
    
        // Update state with the validated value
        setEndpointURL(trimmedValue);
    };

    const handleEndpointTokenChange = (e) => {
        const { value } = e.target;
    
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setEndpointToken(value);
            return;
        }
    
        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        // if (InputValidationCheck(value)) {
        //     console.warn('Invalid input for title'); // Can log or display an error message here
        //     return;
        // }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }
    
        // Update state with the validated value
        setEndpointToken(trimmedValue);
    };

    

    return (
        <>
            <Toaster />
            <div className="backwrap">
                <div className="createTeamModal !w-[520px]">
                    <div className="createTeamModalHead">
                        <h4 className="popupTitle">{type === 'edit' ? 'Edit venue' : 'Create venue'}</h4>
                        <span className="closeModal" onClick={() => setCreateVenue(false)}></span>
                    </div>
                    <div className="createTeamModalBody">
                        <div className="crTeam_inputGroup">
                            <label>Venue name</label>
                            <input autoFocus type="text" value={title} maxLength={25} onChange={handleTitleChange} placeholder="Venue name" />
                        </div>
                        <div className="bg-white cu_inputGroup mb-[10px] cu_inputGroup_select VenueSelectTeam mt-2 ">
                            <label>Team</label>
                            <Select
                                className="select-ecosystem inVenueSelectTeam"
                                value={selectedTeam}
                                onChange={setSelectedTeam}
                                options={teamOptions}
                                menuPlacement="top"
                                // placeholder="Assign to specific Team(s) or leave empty for all"
                                placeholder="Select the team"
                            />
                        </div>
                        <div className="crTeam_inputGroup mt-[10px] mb-[10px]">
                            <label>Endpoint URL</label>
                            <input autoFocus type="text" value={endpointURL} maxLength={1000} onChange={handleEndpointURLChange} placeholder="Endpoint URL" />
                        </div>
                        <div className="crTeam_inputGroup mt-2 mb-[10px]">
                            <label>Endpoint token</label>
                            <input autoFocus type="text" value={endpointToken} maxLength={1000} onChange={handleEndpointTokenChange} placeholder="Endpoint token" />
                        </div>
                    </div>
                    <div className="createTeamModalFoot">
                        {loader ? (
                            <button className="btnAction btn_loader" style={{ color: 'transparent' }}>
                                {type === 'edit' ? 'Update' : 'Create category'}
                            </button>
                        ) : (
                            <>
                                {type === 'create' ? (
                                    title.trim() !== '' && selectedTeam !== null ? (
                                        <button className="btnAction" onClick={() => venue_Create_Update("create")}>Create venue</button>
                                    ) : (
                                        <button className="btnAction" style={{ opacity: '0.5' }}>Create venue</button>
                                    )
                                ) : type === 'edit' ? (
                                    title.trim() !== '' && selectedTeam !== null ? (
                                        <button className="btnAction" onClick={() => venue_Create_Update("update")} disabled={checkmess()}>Update</button>
                                    ) : (
                                        <button className="btnAction" style={{ opacity: '0.5' }}>Update</button>
                                    )
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateEditVenue;
