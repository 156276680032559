import React, { useState } from 'react';
import { FaStar } from "react-icons/fa6";

const FooterFileList = () => {
    const filesData = [
        // {
        //     fileId: 'bd6de8f0-d802-4ea0-822a-28ca26c62aec_file_id_1',
        //     fileName: '.jpg',
        //     fileSize: '402 KB',
        //     uploadDate: '31/10/2024',
        //     uploadTime: '1:00 pm',
        //     uploadedBy: 'Md. Sadequr ss Rahman',
        //     uploadedRoom: 'Room : Md. Sadequr ss Rahman',
        //     referenceId: '5',
        //     tags: ['Test One tag', 'Test One tag', 'Test One tag', 'Test One tag'],
        //     imageUrl: 'https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/thumb-@.jpg1730358044235',
        // },
        // {
        //     fileId: 'bd6de8f0-d802-4ea0-822a-28ca26c62aec_file_id_2',
        //     fileName: '.png',
        //     fileSize: '150 KB',
        //     uploadDate: '01/11/2024',
        //     uploadTime: '3:30 pm',
        //     uploadedBy: 'John Doe',
        //     uploadedRoom: 'Room : John Doe',
        //     referenceId: '3',
        //     tags: ['Test One tag', 'Test One tag', 'Test One tag'],
        //     imageUrl: 'https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/thumb-@.jpg1730358044235',
        // },
        // {
        //     fileId: 'bd6de8f0-d802-4ea0-822a-28ca26c62aec_file_id_3',
        //     fileName: '.png',
        //     fileSize: '150 KB',
        //     uploadDate: '01/11/2024',
        //     uploadTime: '3:30 pm',
        //     uploadedBy: 'John Doe',
        //     uploadedRoom: 'Room : John Doe',
        //     referenceId: '3',
        //     tags: ['Test One tag', 'Test One tag'],
        //     imageUrl: 'https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/thumb-@.jpg1730358044235',
        // },
        // {
        //     fileId: 'bd6de8f0-d802-4ea0-822a-28ca26c62aec_file_id_4',
        //     fileName: '.png',
        //     fileSize: '150 KB',
        //     uploadDate: '01/11/2024',
        //     uploadTime: '3:30 pm',
        //     uploadedBy: 'John Doe',
        //     uploadedRoom: 'Room : John Doe',
        //     referenceId: '3',
        //     tags: ['Test One tag'],
        //     imageUrl: 'https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/thumb-@.jpg1730358044235',
        // }
    ];
    const [activeTagFileId, setActiveTagFileId] = useState(null);

    const handleTagClick = (fileId) => {
        // Toggle the visibility of the tags popup for the clicked file
        setActiveTagFileId((prev) => (prev === fileId ? null : fileId));
    };
    return (
        <>
            <div className='taskListTableHead !p-0 !w-auto mt-5 border border-[#e5eef4] mr-5'>
                <ul style={{borderBottom: '1px solid #e5eef4'}} className="fileHeadRow !border-t-0">
                    <li className="_afileMark"></li>

                    <li
                        className="_afileName sort_active asce"
                        style={{ justifyContent: 'flex-start', paddingLeft: '8px', width: '35%', paddingRight: '0px', cursor: 'default' }}>
                        <span className="sortIcons" style={{ cursor: 'pointer' }}></span>
                        <span className="columnNum" style={{ cursor: 'pointer' }}>File name</span>
                    </li>

                    <li
                        className="_afileSize"
                        style={{ justifyContent: 'flex-start', paddingLeft: '0px', width: '5%', paddingRight: '30px', cursor: 'default' }}>
                        <span className="columnNum changeColor">Starred</span>
                    </li>

                    <li
                        className="_afileSize desc"
                        style={{ width: '10%', marginLeft: '0px', paddingLeft: '16px', cursor: 'default' }}>
                        <span className="columnNum changeColor">Size</span>
                    </li>

                    <li
                        className="_afileDate desc"
                        style={{ justifyContent: 'flex-start', width: '10%', paddingLeft: '0px', cursor: 'default' }}>
                        <span className="columnNum">Date</span>
                    </li>

                    <li
                        className="_afileDate sort_active desc"
                        style={{ justifyContent: 'flex-start', width: '30%', paddingLeft: '0px', paddingRight: '30px', cursor: 'default' }}>
                        <span className="columnNum">Uploaded by</span>
                    </li>
                    {/* 
                <li
                    className="_afileSize"
                    style={{ justifyContent: 'flex-start', width: '9%', paddingLeft: '0px', paddingRight: '0px', flexBasis: '9%', cursor: 'default' }}>
                    <span className="columnNum changeColor">Reference ID</span>
                </li> */}

                    <li
                        className="_afileSize"
                        style={{ justifyContent: 'flex-start', width: '6%', paddingLeft: '0px', paddingRight: '0px', cursor: 'default', flexBasis: '6%' }}>
                        <span className="columnNum changeColor">Tags</span>
                    </li>
                </ul>
                {/* <div  className='fileBody_area advanceFlieList_body !h-[calc(100vh-380px)] !overflow-auto'>
                    {filesData.map((fileData) => (
                        <ul
                            key={fileData.fileId}
                            className="fileBodyRow inFileTagRow"
                            id={fileData.fileId}
                            style={{ padding: '10px 0px', height: 'auto', position: 'relative' }}
                        >
                            <li className="_afileMark ovalClick">
                                <span className="ovalCircle"></span>
                            </li>

                            <li className="_afileName fileName_list" style={{ justifyContent: 'flex-start', paddingLeft: '8px', width: '35%', paddingRight: '30px', overflow: 'hidden' }}>
                                <span className="_fileIcon" style={{ marginRight: '10px' }}>
                                    <img
                                        src={fileData.imageUrl}
                                        alt="File Thumbnail"
                                        data-link={fileData.imageUrl}
                                        style={{ maxWidth: '100%' }}
                                    />
                                </span>
                                <div className="filenameAndIcon">
                                    <span className="workfreeliFileText" style={{ marginLeft: '0px', top: '-10px', width: 'auto' }}>
                                        {fileData.fileName}
                                    </span>
                                </div>
                                <p className="workfreeliDOCX">image</p>
                                <div className="gFileHoverLists" style={{ right: '25px', top: '0px' }}>
                                    <div data-for={fileData.fileId} className="fileOpts tag_opts"></div>
                                    <div data-for={fileData.fileId} className="fileOpts forward_opts"></div>
                                    <div data-for={fileData.fileId} className="fileOpts share_opts"></div>
                                    <div data-for={fileData.fileId} className="fileOpts download_opts"></div>
                                    <div className="fileOpts delete_opts"></div>
                                </div>
                            </li>

                            <li className="_afileSize" style={{ width: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaStar className='text-[#d8d8d8] h-4 w-4'/>
                            </li>

                            <li className="_afileSize" style={{ width: '10%' }}>
                                <span style={{ fontWeight: 'normal', color: 'rgb(3, 46, 132)', fontSize: '12px', paddingLeft: '20px' }}>
                                    {fileData.fileSize}
                                </span>
                            </li>

                            <li className="_afileSize" style={{ width: '10%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '7px 0px 7px 8px' }}>
                                    <p className="linkTextTable newText">
                                        <time dateTime={fileData.uploadDate}>{fileData.uploadDate}</time>
                                    </p>
                                    <p className="linkTextTable newText">
                                        <time dateTime={fileData.uploadTime}>{fileData.uploadTime}</time>
                                    </p>
                                </div>
                            </li>

                            <li className="_afileSize uploadedByClm" style={{ width: '30%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '7px 0px 7px 8px' }}>
                                    <p className="linkTextTable newText">{fileData.uploadedBy}</p>
                                    <p className="linkTextTable newText">{fileData.uploadedRoom}</p>
                                </div>
                            </li>

                            <li className="_afileSize" style={{ flexBasis: '6%', paddingRight: '10px' }}>
                                <p className="timeAndDate" style={{ paddingLeft: '10px' }}>
                                    {fileData.tags.length}
                                </p>
                                <ul className="colorGroup" onClick={() => handleTagClick(fileData.fileId)}>
                                    {fileData.tags.map((tagColor, index) => (
                                        <li key={index} className="colorGroupList" style={{ background: '#0A256A' }}></li>
                                    ))}
                                </ul>
                            </li>
                            {activeTagFileId === fileData.fileId &&
                                <div className="backwrap">
                                    <div className="customTitleModal">
                                        <div className="customTitleHead">
                                            <h4 className="popupTitle" style={{ marginBottom: '15px' }}>
                                                File tags
                                            </h4>
                                            <span className="closeModal" onClick={() => setActiveTagFileId(false)}></span>
                                        </div>
                                        <div className='p-5 flex flex-wrap'>
                                            {fileData.tags.map((tag, index) => (
                                                <p key={index} className='text-white text-sm py-1 px-2 rounded mr-2 mb-2' style={{ background: 'rgb(10, 37, 106)' }}>#{tag}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                    ))}
                </div> */}
            </div>

        </>
    );
};

export default FooterFileList;