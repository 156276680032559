/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import CreateCategory from './CreateCategory';
import { useMutation, useQuery } from '@apollo/client';
import { CATEGORIES, GET_ME } from '../../../Utils/GraphqlQueries';
import Moment from 'react-moment';
import { DELETE_CATEGORY } from '../../../Utils/GraphqlMutaion';
import TeamWarnPopup from '../TeamManagement/TeamWarnPopup';
import { Toaster, toast } from 'react-hot-toast';
import Scrollbars from 'react-custom-scrollbars';
import { Tooltip } from 'react-tooltip';
import { InputValidationCheck } from '../../ValidationCheck';

function RoomCategoryHome() {
    const [loader, setLoader] = useState(true)
    const [categories, setCategories] = useState([])
    const [action_data, setAction_data] = useState(null);
    const [srcString, setSrcString] = useState('');
    const [user_data, setUser_data] = useState(null);
    const { loading: u_loader, data: user } = useQuery(GET_ME);
    useEffect(() => {
        setUser_data(user.me)
    }, [user])

    const { data: cate_data } = useQuery(CATEGORIES)

    useEffect(() => {
        if (cate_data?.categories) {
            setCategories(cate_data?.categories)
            setLoader(false)
        }
    }, [cate_data])


    const [hoveredElements, setHoveredElements] = useState({});
    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    const [createCategory, setCreateCategory] = useState('');
    const [deleteRoomCategory, setDeleteRoomCategory] = useState(false);
    const [selectedUsersOptions, setSelectedUsersOptions] = useState(false);
    const handleSelectedOptions = (e) => {
        setSelectedUsersOptions(e);
    }

    const [settingsOptions, setSettingsOptions] = useState('');

    const ref = useClickAway(() => {
        setSettingsOptions('')
    });

    const [delete_category] = useMutation(DELETE_CATEGORY)

    const delete_confirm = async () => {
        let apires = await delete_category({
            variables: {
                input: {
                    unit_id: action_data?.unit_id
                }
            }
        })

        if (apires.data.deleteCategory.status) {
            setCategories(prev => prev.filter(f => f.unit_id !== action_data?.unit_id))
            toast.success("Category deleted successfully", { duration: 4000 });
        } else {
            toast.error(apires.data.deleteCategory.message, { duration: 4000 });
        }
        setDeleteRoomCategory(false)
    }


    // table in filter start
    const [sortRedActive, setSortRedActive] = useState(false);
    const [sortIconName, setSortIconName] = useState(false);
    const [sortIconCreateOn, setSortIconCreateOn] = useState(false);
    const [sortRedbyDefault, setSortRedByDefault] = useState(true);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSortList = (type) => {
        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        if (type === 'byCatName') {
            setSortRedActive(type);
            setSortIconName(!sortIconName);
            setSortRedByDefault(false);

            const sortedCategories = [...categories].sort((a, b) => {
                const nameA = a.unit_name.toLowerCase();
                const nameB = b.unit_name.toLowerCase();
                return newSortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            });
            setCategories(sortedCategories);
        } else if (type === 'byCreateOn') {
            setSortRedActive(type);
            setSortIconCreateOn(!sortIconCreateOn);
            setSortRedByDefault(false);

            const sortedCategories = [...categories].sort((a, b) => {
                const createdatA = a.created_at.toLowerCase();
                const createdatB = b.created_at.toLowerCase();
                return newSortOrder === 'asc'
                    ? createdatA.localeCompare(createdatB)
                    : createdatB.localeCompare(createdatA);
            });
            setCategories(sortedCategories);
        }
    };
    // table in filter end
    const [create_categoryShow, setCreate_categoryShow] = useState(false);
    const CreateCategoryShow = () => {
        return (
            create_categoryShow &&
            <span style={{ cursor: 'pointer' }} className="user_Management_title createTeam">
                <span className="tooltiptext5">
                    <p> Create a category. </p>
                    <span onClick={e => { setCreate_categoryShow(false); }} className='tooltipClose'></span>
                </span>
            </span>
        )
    }
    const [showCannotAccess, setShowCannotAccess] = useState(false);

    const close_popup = () => {
        setShowCannotAccess(false);
    };
    const CanNotAccess = ({ close_popup }) => {
        return (
            <div className="backwrap">
                <div className="freeliModal">
                    <div className="freeliModalHead">
                        <span className="popupTitle"></span>
                        <span className="closeModal" id="close_popup" onClick={close_popup}></span>
                    </div>

                    <div className="freeliModalBody" style={{ padding: '20px 30px' }}>
                        <p className="text-center text-[16px] font-light" >
                            You cannot edit or remove this category as some rooms are associated with it.
                        </p>
                    </div>
                    <div className="freeliModalFoot">
                        <button className="btnCancel" onClick={close_popup}>
                            Cancel
                        </button>
                    </div>
                </div>
                <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#close_popup" place='left' float="true" content="Back" />
            </div>
        );
    }

    const onClickHan = (unit_id) => {
        // setSettingsOptions(prev => prev === v.unit_id ? '': v.unit_id)
        setSettingsOptions(prev => prev === unit_id ? '' : unit_id);
    }



    const actionMe = (v) => {
        if (v.total_use !== 0) {
            setShowCannotAccess(!showCannotAccess);
        } else {
            setSettingsOptions(v.unit_id);
        }
    }
    const handleCategoryChange = (e) => {
            const { value } = e.target;
        
            // If the value is empty or the user is clearing the input (backspace case)
            if (value === '') {
                // Allow clearing the input
                setSrcString(value);
                return;
            }
        
            // Use InputValidationCheck to validate the input (assuming title is not a number field)
            if (InputValidationCheck(value)) {
                console.warn('Invalid input for title'); // Can log or display an error message here
                return;
            }
        
            // Trim spaces from the start of the value
            const trimmedValue = value.trimStart();
        
            // If the title is empty or contains only spaces, we prevent further updates
            if (!trimmedValue) {
                console.warn('Title cannot be empty or contain only spaces');
                return;
            }
        
            // Update state with the validated value
            setSrcString(trimmedValue);
        };


    return (
        <>
            <Toaster />
            {showCannotAccess &&
                <CanNotAccess close_popup={close_popup} />
            }
            <div className='right_container'>
                <div className='adminRightHead'>
                    <span className="user_Management_title text-5xl">Room category</span>
                    <span onMouseEnter={() => handleMouseHover('element1')} onMouseLeave={() => handleMouseLeave('element1')} className="tooltip user_Management_title"><span className="info_tooltip_new"></span>
                        {hoveredElements['element1'] &&
                            <span className="tooltiptext">
                                <p>You need to select a room category when creating a new Room. This is a useful feature to help sort and categorize data such as files, images, links and working documents using the files dashboard on Workfreeli. Create custom room categories as needed.</p>
                                <span className="tooltipClose"></span>
                            </span>}
                    </span>
                    {
                        selectedUsersOptions &&
                        <div className="miniItem" style={{ marginLeft: "15px" }}>
                            <div className="miniItem_image" style={{ margin: "0px" }}>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="miniItem_name" style={{ marginLeft: "3px" }}>Filter Active Users only</div>
                            <div onClick={() => handleSelectedOptions(false)} className="miniItem_remove"></div>
                        </div>
                    }
                </div>

                <div className='usersTabSection'>
                    <div className="usersTabSecDevide lefttSide" style={{ gap: '10px' }}>
                        <div className={`usersTabList teamManagementTitle`} >
                            <div style={{ cursor: 'pointer' }}>
                                <p>Room category ({categories.length})</p>
                            </div>
                        </div>
                    </div>
                    <div className='usersTabSecDevide rightSide'>
                        <span className='relative'>
                            <input className="userSearchInput" value={srcString} onChange={handleCategoryChange} type="text" autoFocus={true} placeholder="Search category" />
                            <span className="leftSearchCloseBtn remove !right-5" onClick={() => setSrcString('')}></span>
                        </span>
                        <button
                            style={{ marginRight: '0px' }}
                            // onMouseEnter={() => handleMouseHover('element2')}
                            // onMouseLeave={() => handleMouseLeave('element2')}
                            onClick={() => setCreateCategory('create')}
                            className="userCreateBtn tooltip5"
                            onMouseEnter={() => setCreate_categoryShow(true)}
                            onMouseLeave={() => setCreate_categoryShow(false)}
                        >Create category</button>
                        <CreateCategoryShow />

                    </div>
                </div>
                <div className='usersTableContainer'>
                    <div className="userTableHead">
                        <ul className="">
                            <li className={`_userName ${sortRedActive === "byCatName" ? 'sort_active' : ''} ${!sortIconName ? 'asce' : 'desc'}`} style={{ width: "20%" }}>
                                <span onClick={() => handleSortList("byCatName")} className="sortIcons"></span>Category name
                            </li>
                            <li className="_userEmail desc " style={{ width: "20%" }}>No. of rooms</li>
                            <li className={`_userRole ${sortRedbyDefault && 'sort_active'} ${sortRedActive === "byCreateOn" ? 'sort_active' : ''} ${!sortIconCreateOn ? 'asce' : 'desc'}`} style={{ width: "30%" }}>
                                <span onClick={() => handleSortList("byCreateOn")} className="sortIcons"></span>Created on
                            </li>
                            <li className="_userStatus desc" style={{ width: "30%" }}>Created by</li>
                            <li className="_userAction" style={{ width: "5%" }}>Action</li>
                        </ul>

                    </div>
                    <div className='userTableBody'>
                        <Scrollbars autoHide className="scrollBarClass">
                            {loader ? ('')
                                :
                                (
                                    categories.filter(f => f.unit_name.toLowerCase().includes(srcString.toLowerCase())).length === 0 ? (
                                        <div style={{ position: "absolute", inset: 0, overflow: "scroll", marginRight: "-8px", marginBottom: "-8px", textAlign: "center", marginTop: "20%" }}>
                                            No data found
                                        </div>
                                    ) :
                                        (
                                            categories.filter(f => f.unit_name.toLowerCase().includes(srcString.toLowerCase())).map((v) => {
                                                return (
                                                    <div key={v.unit_id} style={{ position: 'relative' }}>
                                                        <ul className="_userList">
                                                            <li className="_userName" style={{ width: "25%" }}>
                                                                <span className="_userNameTxt">{v.unit_name}</span>
                                                            </li>
                                                            <li className="_userEmail" style={{ width: "15%" }}>
                                                                <span className="userEmailText" title="rakib15020@gmail.com">{v.total_use}</span>
                                                            </li>
                                                            <li className="_userRole" style={{ width: "30%" }}>
                                                                <span className=""><Moment className="userDateText" format="MMM Do, YYYY">{v.created_at}</Moment> </span>
                                                            </li>
                                                            <li className="_userStatus" style={{ width: "30%" }}>
                                                                <time className="userDateText">{v.created_by_name}</time>
                                                            </li>

                                                            <li onClick={() => actionMe(v)}
                                                                className="_userAction" style={{ width: "5%", position: "relative", pointerEvents: settingsOptions === v.unit_id ? "none" : "" }}>
                                                                <span className="userActionIcon"></span>
                                                            </li>
                                                        </ul>
                                                        {
                                                            settingsOptions === v.unit_id &&
                                                            <ul ref={ref} className="userActionPopup" style={{ position: "absolute" }}>
                                                                {<li onClick={() => { setAction_data(v); setCreateCategory('edit') }}>Edit category</li>}
                                                                {/* {v.created_by === user_data?.id && <li onClick={() => { setAction_data(v); setDeleteRoomCategory(true) }}>Delete Category</li>} */}
                                                                {<li onClick={() => { setAction_data(v); setDeleteRoomCategory(true) }}>Delete category</li>}
                                                            </ul>

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                )}
                        </Scrollbars>
                    </div>
                </div>
            </div>

            {createCategory !== '' && <CreateCategory categories={categories} type={createCategory} setCreateCategory={setCreateCategory} setCategories={setCategories} action_data={action_data} />}
            {deleteRoomCategory && <TeamWarnPopup title={'Delete category ?'} desc={'Are you sure you want to delete this category ?'} onClose={() => setDeleteRoomCategory(false)} onConfirm={delete_confirm} />}
        </>
    );
}

export default RoomCategoryHome;