import React, { Fragment, useState } from 'react';
import TaskEditorBox from './TaskEditorBox';
import Moment from 'react-moment';
import 'moment-timezone';
import { GET_SEPERATOR, IsGroupMsg } from '../../../Utils/Common';
import { useSelector } from 'react-redux';
import MessagesContainer from '../../Connect/Messages/MessagesContainer';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';

const TaskChatSections = ({ me }) => {
    const { users, selectedTask } = useTaskContext()
    const [activeTab, setActiveTab] = useState('Discussion'); // Use state to track the active tab
    const discussion = useSelector(state => state.task.active_discussion)
    const conversations = useSelector(state => state.message.conversations)
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [chatExpand, setChatExpand] = useState(false);
    const [deleteMsgIndex, setDeleteMsgIndex] = useState(false);
    const active_conv = conversations.filter(f => f.conversation_id === selectedTask?.conversation_id)?.[0]

    return (
        <div className={`task_chat_section ${discussion.length === 0 ? "!minn-h-[100px]" : ""} ${chatExpand ? "taskChatExpand" : ""}`} >
            <div className='taskChatBox h-full w-full'>
                <div className="taskChatBoxHeader forChatbox">
                    <div className={`taskHeadlabel ${activeTab === 'Discussion' ? 'active' : ''}`} style={{ marginRight: '32px' }}>
                        <span className="tasklabel active text-[14px]" onClick={() => handleTabClick('Discussion')}>Discussion</span>
                        {/* <span className="task_read !ml-4"></span> */}
                    </div>
                    <div className="lineVertical"></div>
                    <div className={`taskHeadlabel ${activeTab === 'Notifications' ? 'active' : ''}`} style={{ paddingLeft: '16px' }}>
                        <span className="tasklabel text-[14px]" onClick={() => handleTabClick('Notifications')}>Notifications</span>
                    </div>
                    <div className="taskHeadExpand_icon" style={{ position: 'relative', right: '10px' }}>
                    {chatExpand ? (
                            <>
                                <span onClick={() => setChatExpand(false)} id="minimizeIcon" className="collapse_icon_chat"></span>
                                <Tooltip anchorSelect="#minimizeIcon" content="Click to minimize" />
                            </>
                        ) : (
                            <>
                                <span onClick={() => setChatExpand(true)} id="expandIcon" className="expand_icon_chat"></span>
                                <Tooltip anchorSelect="#expandIcon" content="Click to expand" />
                            </>
                        )}
                    </div>
                </div>
                {
                    activeTab === 'Discussion' ?
                        <div className={`discussionArea ${chatExpand === true && "!h-[calc(100vh-108px)]"} ${discussion.length === 0 ? "!h-0" : ""}`} >
                            <div className="messageDiv">
                                {discussion?.map((m, k) =>
                                    <MessagesContainer
                                        data={m}
                                        key={m.msg_id}
                                        room={active_conv}
                                        me={me}
                                        type='discussion'
                                        thread={true}
                                        edit_msg_id={''}
                                        all_users={users || []}
                                        all_msgs={discussion}
                                        seperator={GET_SEPERATOR(discussion[k - 1], m)}
                                        is_group_msg={IsGroupMsg(discussion[k - 1], m)}
                                        setDeleteMsgIndex={setDeleteMsgIndex}
                                    />
                                )}
                            </div>
                            <div id="bottomBox" className="bottom_bar bottomBox_reply taskChatArea bottomBox_task">
                                <TaskEditorBox task={selectedTask} me={me} />
                            </div>
                        </div>
                        :
                        <div className="discussionArea">
                            {selectedTask?.notification?.map(m => ({ ...m, created_at: m.created_at }))?.map((v, k) => {
                                let separator = GET_SEPERATOR(selectedTask?.notification?.[k - 1], v)
                                return (
                                    <Fragment key={'noti_' + k}>
                                        {separator !== '' && <div className="msgSeparator" ><p className="z-10">{separator}</p></div>}
                                        <div key={v.created_at} className="user_msg !w-full" style={{ padding: '7px 14px' }}>
                                            <div className="msg_info">
                                                <div className="sender_info flex !justify-between">
                                                    <p className="sender_name !text-sm">{v?.created_by_name}</p>
                                                    <p className="msg_time !text-xs">
                                                        <Moment format="h:mm A">{v?.created_at}</Moment>
                                                    </p>
                                                </div>
                                                <div className="msg_body">{v?.title}</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }
                            )}
                        </div>
                }

            </div>
        </div >
    );
};

export default TaskChatSections;