import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { GET_PRODUCT, GET_PRODUCTS_MAIN } from '../../Utils/GraphqlQueries';
import styles from './ProductPopup.module.css'
import { Add_New_Product_Mutation } from '../../Utils/GraphqlMutaion';
import { useEffect, useState } from 'react';
const ProductPopupMain = () => {
    
    const [all_products, setAllProducts] = useState([]);
    const [singleData, setSingleData] = useState(null);
    const [addNewProduct, { data: addnewproduct, loading: addnewproductloading, error: erroraddnewproduct }] = useMutation(Add_New_Product_Mutation);
    const { loading: initialLoading, error: initialError, data: initialData } = useQuery(GET_PRODUCTS_MAIN);


    useEffect(() => {
        if (initialData && !initialLoading) {
            setAllProducts(initialData.products_main)
        }

    }, [initialData, initialLoading])
    const [get_single_product, { loading: singleLoading, error: errorSingle, data: singleproduct }] = useLazyQuery(GET_PRODUCT);

    // addNewProduct  is a method for add new product when you submit. 
    // like "await addnewprodut({product_name:product_name})"
    // initialData means all products 
    // get_single_product This method will be called when to use only show single product
    // like "await get_single_product({product_name:product_name})"

    const [form_data, setForm_data] = useState({
        product_name: '',
        price: 0,
    })

    const save_data = async () => {
        console.log(form_data);
        // if (form_data.name.trim() !== '' && form_data.price.trim() !== '') {
        try {
            let apires = await addNewProduct({ variables: { input: { ...form_data, price: Number(form_data.price) } } })
            // apires.data.addProduct
            if (apires?.data?.addProduct) {
                setAllProducts(prev => [...prev, { _id: apires.data.addProduct._id, ...form_data }]);
                setForm_data({
                    product_name: '',
                    price: 0,
                })
            }

        } catch (error) {
            console.log(error);
        }
        // }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm_data({ ...form_data, [name]: value })
        console.log("🚀 ~ handleChange ~ value:", name, value)
    }
    const view = async (item) => {
        try {
            let product = await get_single_product({ variables: { product_name: item.product_name } });
            if (product?.data?.product) {
                setSingleData(product.data.product)
            } else {
                window.location.reload();
            }


        } catch (error) {
            console.log(error);
        }

    }

    if (singleData !== null) {

        return (
            <div className={styles.wrapper}>
                <div className={styles.popup_container}>
                    <div className={styles.header_container}>
                        <button onClick={() => setSingleData(null)}>Back</button>
                        <h3>Product</h3>
                    </div>

                    <div className="card" style={{ "width": "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title">Product Name : {singleData.product_name} </h5>
                            <p className="card-text">Price: {singleData.price}</p>
                            <p className="card-text">#ID: {singleData._id}</p>
                        </div>
                    </div>

                </div>

            </div>
        )

    } else {
        return (
            <div className={styles.wrapper}>
                {singleLoading ? <div>
                    Loading.... <br />
                    <button><span onClick={() => window.location.reload()}>Reload</span></button>
                </div> :
                    <div className={styles.popup_container}>
                        <div className={styles.header_container}>

                            <h3>Product Table Manipulated</h3>

                        </div>
                        <div className={styles.data_container}>
                            {/* <div className={styles.data_column}></div> */}
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Product ID</th>
                                        <th>Product Name</th>
                                        <th>Original Price</th>
                                        <th>Manipulated Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {initialLoading ?
                                        'Loading... '
                                        : all_products.map((item, index) => (
                                            <tr key={index} >
                                                <td>{item._id}</td>
                                                <td>{item.product_name}</td>
                                                <td>{item.original_price}</td>
                                                <td>{item.price}</td>
                                                
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

            </div>
        )
    }

}

export default ProductPopupMain;