/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import range from "lodash/range";
import { getMonth, getYear } from 'date-fns';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';
import { useMutation } from '@apollo/client';
import { Read_task_notification } from '../../../Utils/GraphqlMutaion';
import { preventInvalidKeyPress, preventPlusSign, validFirstChercterAlsoNuber } from '../../ValidationCheck';

const TrackHourPopup = ({ setTrackHourPopup }) => {
    const { setSelectedTask, set_tasks, selectedTask, OnUpdateTask, isObserver, user } = useTaskContext()
    const [all_breakdown, set_all_breakdown] = useState(
        selectedTask?.hour_breakdown?.map(({ fdate, tdate, forecasted_hours, actual_hour, note, status }) => ({
            fdate,
            tdate,
            forecasted_hours,
            actual_hour,
            note,
            status,
        })) || []
    );

    useEffect(() => {
        set_all_breakdown(selectedTask?.hour_breakdown?.map(({ fdate, tdate, forecasted_hours, actual_hour, note, status }) => ({
            fdate,
            tdate,
            forecasted_hours,
            actual_hour,
            note,
            status,
        })) || [])
    }, [selectedTask?.hour_breakdown])


    const [hour_breakdown, set_hour_breakdown] = useState({
        fdate: null,
        tdate: null,
        forecasted_hours: 0,
        actual_hour: 0,
        note: '',
        status: true
    });

    const [startDate] = useState(new Date());
    const [endDate] = useState(null)
    const [error1] = useState(false);
    const [error2] = useState(false);
    const [showEnter, setShowEnter] = useState(true);
    const [showEnter2, setShowEnter2] = useState(true);

    // Dummy data for demonstration purposes
    const years = range(2000, getYear(new Date()) + 10, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const totalHours = all_breakdown?.reduce((totals, item) => {
        totals.forecasted_hours += Number(item.forecasted_hours) || 0;
        totals.actual_hour += Number(item.actual_hour) || 0;
        return totals;
    }, { forecasted_hours: 0, actual_hour: 0 });

    const handleAddRow = () => {
        let { fdate, tdate, forecasted_hours, actual_hour } = hour_breakdown;
        if (fdate && tdate && (forecasted_hours > 0 || actual_hour > 0)) {
            let updatedForecastedHours = Number(totalHours?.forecasted_hours) + Number(hour_breakdown.forecasted_hours);
            let updatedActualHours = Number(totalHours?.actual_hour) + Number(hour_breakdown.actual_hour);

            OnUpdateTask({
                hour_breakdown: [
                    ...(all_breakdown?.map(({ fdate, tdate, forecasted_hours, actual_hour, note, status }) =>
                        ({ fdate, tdate, forecasted_hours, actual_hour, note, status }))) || [],
                    hour_breakdown
                ],
                forecasted_hours: updatedForecastedHours,
                actual_hours: updatedActualHours,
                hours_variance: updatedForecastedHours - updatedActualHours,
                save_type: 'hour'
            });

            // Reset the hour breakdown form
            set_hour_breakdown({ fdate: null, tdate: null, forecasted_hours: 0, actual_hour: 0, note: '', status: true });
        }
    };

    const handleBlur = () => {
        let break_down = all_breakdown.map(({ fdate, tdate, forecasted_hours, actual_hour, note, status }) => ({ fdate, tdate, forecasted_hours, actual_hour, note, status }))
        const updatedCostBreakdown = [...break_down];
        // You can add an additional task update here if needed
        OnUpdateTask({
            hour_breakdown: updatedCostBreakdown,
            forecasted_hours: updatedCostBreakdown.reduce((total, item) => total + Number(item.forecasted_hours), 0),
            actual_hours: updatedCostBreakdown.reduce((total, item) => total + Number(item.actual_hour), 0),
            hours_variance: updatedCostBreakdown.reduce((total, item) => total + (Number(item.forecasted_hours) - Number(item.actual_hour)), 0),
            save_type: 'hour',
        });
    };

    const handleInputChange = (index, value, name) => {
        // Ensure value is not negative for specific fields
        let val;
        if (name === 'forecasted_hours' || name === 'actual_hour') {
            val = parseInt(value) || 0;
            if (val < 0) {
                console.warn("Negative values are not allowed.");
                return;
            }
        } else {
            val = value;
        }

        set_all_breakdown((prev) =>
            prev.map((m, i) => (i === index ? { ...m, [name]: val } : m))
        );
    };

    const handleNoteMultiChange = (index, value, name) => {
        // Allow clearing the input (backspace case)
        if (value === "") {
            set_all_breakdown((prev) =>
                prev.map((m, i) => (i === index ? { ...m, [name]: value } : m))
            );
            return;
        }

        // Validate the input
        if (validFirstChercterAlsoNuber(value)) {
            console.warn("Invalid input"); // Log the error or display a warning
            return; // Prevent invalid input
        }
        else {
            // Update state with valid input
            set_all_breakdown((prev) =>
                prev.map((m, i) => (i === index ? { ...m, [name]: value } : m))
            );
        }

        // Update state with valid input

    };


    const handleRemoveRow = (v, index) => {
        const updatedCostBreakdown = [...all_breakdown];
        updatedCostBreakdown.splice(index, 1);

        let forecasted_hours = Number(totalHours.forecasted_hours) - Number(v.forecasted_hours);
        let actual_hour = Number(totalHours.actual_hour) - Number(v.actual_hour);
        OnUpdateTask({
            hour_breakdown: [
                ...(updatedCostBreakdown?.map(({ fdate, tdate, forecasted_hours, actual_hour, note, status }) =>
                    ({ fdate, tdate, forecasted_hours, actual_hour, note, status }))) || []
            ],
            forecasted_hours: forecasted_hours,
            actual_hours: actual_hour,
            hours_variance: Number(forecasted_hours - actual_hour),
            save_type: 'hour'
        });
    };

    const [read_notification] = useMutation(Read_task_notification, {
        onCompleted: (res) => {
            if (res.read_task_notification.success === 'true') {
                setSelectedTask(prev => ({ ...prev, view_hour: [] }))
                set_tasks(prev => prev.map(m => m._id === selectedTask._id ? { ...m, view_hour: [] } : m))
            }
        }
    });

    useEffect(() => {
        if (selectedTask && selectedTask?.view_hour?.indexOf(user?.id) > -1) {
            read_notification({
                variables: { input: { _id: selectedTask?._id, type: 'view_hour' } }
            }); // Read the notification
        }
    }, [selectedTask, user, read_notification]);

    const [noteView, setNoteView] = useState(false);
    const handleNoteChange = (e) => {
        const { name, value } = e.target;
        if (value === "") {
            set_hour_breakdown((prev) => ({ ...prev, [name]: value }));
            return;
        }
        // Validate the input
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input'); // Log the error or display a warning
            return; // Prevent invalid input
        } else {
            // Update state with valid input
            set_hour_breakdown((prev) => ({ ...prev, [name]: value }));
        }


    };


    return (

        <div className='trackCostPopUpCon'>
            <div className='costsPopUp'>
                <div className='TrackHoursPopUp'>
                    <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
                        <div className="backToMainProperties">
                            <span className="closeAdminPanel" onClick={() => setTrackHourPopup(false)}></span>
                            <span className="backtoProperties">Track hours</span>
                        </div>
                    </div>

                    <div className='Track_footer_upper'>
                        <div className='add_entryText !pb-0 !pt-2'>Add an entry below</div>
                        {isObserver() && <p className='text-red-600 text-sm'>These fields are not accessible to your user role</p>}
                        <div className={`footer_up ${isObserver() && 'pointer-events-none'}`}>
                            <div className='Track_col fd_bottom' style={{ width: '40%', marginLeft: '0px' }}>
                                <p>From</p>
                                <div onMouseEnter={() => setShowEnter(false)} onMouseLeave={() => setShowEnter(true)} className='date_area_div date_area_div_width'>
                                    <DatePicker
                                        className={`start_Date_task Focus ${error1 === true && 'errorDate'}`}
                                        dateFormat="MMMM dd, yyyy"
                                        placeholderText="Date"
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div className="CalendarDiv"
                                            >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                                <div >
                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                                        {years.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                            </div>
                                        )}
                                        selected={hour_breakdown.fdate}
                                        onChange={(date) => set_hour_breakdown(prev => ({ ...prev, fdate: date }))}
                                        selectsStart
                                        startDate={startDate}
                                        minDate={selectedTask?.start_date || ''}  // Set the min date to the Start date of the task
                                        maxDate={endDate ? endDate : selectedTask?.end_date || ''}
                                    />
                                    {!showEnter && hour_breakdown.fdate ?
                                        <span style={{ right: '8px', top: '29px', display: 'block' }} onClick={() => set_hour_breakdown(prev => ({ ...prev, fdate: null }))} className='removeFile' ></span>
                                        :
                                        <span style={{ top: '20px', right: '8px' }} className='newCalenderIcon' ></span>
                                    }
                                </div>
                            </div>
                            <div className='Track_col td_bottom' style={{ width: '40%', marginLeft: '0px' }}>
                                <p>To</p>

                                <div onMouseEnter={() => setShowEnter2(false)} onMouseLeave={() => setShowEnter2(true)} className='date_area_div date_area_div_width'>

                                    <DatePicker
                                        className={error2 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}
                                        dateFormat="MMMM dd, yyyy"
                                        placeholderText="Date"
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div className="CalendarDiv">
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                                <div >
                                                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                                        {years.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                            </div>
                                        )}

                                        selected={hour_breakdown.tdate}
                                        onChange={(date) => set_hour_breakdown(prev => ({ ...prev, tdate: date }))}
                                        selectsEnd
                                        endDate={endDate}
                                        minDate={hour_breakdown?.fdate || selectedTask?.start_date || new Date()}
                                        maxDate={selectedTask?.end_date || ''}
                                    />
                                    {!showEnter2 && hour_breakdown.tdate ?
                                        <span style={{ right: '8px', top: '29px', display: 'block' }} onClick={() => set_hour_breakdown(prev => ({ ...prev, tdate: null }))} className='removeFile' ></span>
                                        :
                                        <span style={{ top: '20px', right: '8px' }} className='newCalenderIcon' ></span>
                                    }
                                </div>
                            </div>
                            <div className='Track_col' style={{ width: '12%', marginLeft: '0px' }}>
                                <p>Forcasted</p>
                                <input type='number'
                                    value={hour_breakdown.forecasted_hours}
                                    onChange={(e) => {
                                        const { name, value } = e.target;

                                        // Allow empty value for clearing input
                                        if (value === "") {
                                            set_hour_breakdown((prev) => ({ ...prev, [name]: Number(value) }));
                                            return;
                                        }

                                        // Ensure the value is a number and not negative
                                        if (!isNaN(value) && parseFloat(value) >= 0) {
                                            set_hour_breakdown((prev) => ({ ...prev, [name]: Number(value) }));
                                        } else {
                                            console.warn("Negative values are not allowed.");
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        preventPlusSign(e); // Prevent plus sign key
                                        preventInvalidKeyPress(e); // Other invalid key press prevention logic
                                    }}
                                    // onChange={(e) => set_hour_breakdown((prev) => ({ ...prev, forecasted_hours: Number(e.target.value) }))} 
                                    name='forecasted_hours' className='hours_count trackTime'
                                    placeholder='0' />
                            </div>
                            <div className='Track_col' style={{ width: '12%', marginLeft: '0px' }}>
                                <p>Actual</p>
                                <input type='number'
                                    value={hour_breakdown.actual_hour}
                                    // onChange={(e) => set_hour_breakdown(prev => ({ ...prev, actual_hour: Number(e.target.value) }))} 
                                    onChange={(e) => {
                                        const { name, value } = e.target;

                                        // Allow empty value for clearing input
                                        if (value === "") {
                                            set_hour_breakdown((prev) => ({ ...prev, [name]: Number(value) }));
                                            return;
                                        }

                                        // Ensure the value is a number and not negative
                                        if (!isNaN(value) && parseFloat(value) >= 0) {
                                            set_hour_breakdown((prev) => ({ ...prev, [name]: Number(value) }));
                                        } else {
                                            console.warn("Negative values are not allowed.");
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        preventPlusSign(e); // Prevent plus sign key
                                        preventInvalidKeyPress(e); // Other invalid key press prevention logic
                                    }}
                                    name='actual_hour'
                                    className='hours_count trackTime'
                                    placeholder='0' />
                            </div>
                        </div>
                        <div className='flex gap-1 items-center mt-1' onClick={() => setNoteView(!noteView)}>
                            <div className='text-sm text-[#0079d1]'>{noteView ? "Close note" : "View note"}</div>
                            <div>
                                <MdOutlineKeyboardArrowDown className='text-xl text-[#0079d1]' style={{ transform: noteView ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                            </div>
                        </div>
                        {noteView &&
                            <div className={`Track_col editContant footer_down !pb-0 ${isObserver() && 'pointer-events-none'}`}>
                                <textarea className="youtnotesTextArea" value={hour_breakdown.note}
                                    onChange={handleNoteChange}
                                    name="note" id="notesTextArea"
                                    placeholder="Add a note"
                                ></textarea>
                                <span className='removeFile'></span>
                            </div>
                        }
                        <div className='flex justify-end'>
                            <button className={`hrsAddBtn ${hour_breakdown.fdate && hour_breakdown.tdate && (hour_breakdown.forecasted_hours > 0 || hour_breakdown.actual_hour > 0) ? '':'opacity-[0.5] pointer-events-none'}`} onClick={handleAddRow} style={isObserver() ? { opacity: '0.5' } : {}}>Add</button>
                        </div>
                    </div>

                    <div className="Track_body Track_body_hour bodyArea">

                        {all_breakdown?.length > 0 ?
                            <div className='Track_fields' >
                                {all_breakdown?.map((v, index) => (
                                    <TaskHourGroup v={v} index={index} isObserver={isObserver} handleRemoveRow={handleRemoveRow} handleInputChange={handleInputChange} handleNoteMultiChange={handleNoteMultiChange} handleBlur={handleBlur} years={years} months={months} key={index} />
                                ))}
                            </div>
                            : <div className='notFoundRow'>Please add a row</div>

                        }
                    </div>
                    <div className='trackHourFooter'>
                        <div className="Track_footer_bottom Track_f_last" style={{ gap: '20px' }}>
                            <div className="Track_totalHoures_label">Total</div>
                            <div className="Track_totalHoures">{totalHours?.forecasted_hours}hrs 0.00mins</div>
                            <div className="Track_totalHoures" style={{ textAlign: 'left' }}>{totalHours?.actual_hour}hrs 0.00mins</div>
                        </div>
                        <div className="Track_body_headding foot_middle Track_hours" style={{ order: 4 }}>
                            <div className="Track_variance_text">variance</div>
                            <div className="Track_variance_amount">{totalHours?.forecasted_hours - totalHours?.actual_hour}hrs 0.00mins</div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

const TaskHourGroup = ({ v, index, isObserver, handleRemoveRow, handleInputChange, handleNoteMultiChange, handleBlur, years, months }) => {

    const [error1] = useState(false);
    const [error2] = useState(false);
    const [showEnter, setShowEnter] = useState(true);
    const [showEnter2, setShowEnter2] = useState(true);
    const [noteView, setNoteView] = useState(false);

    return (
        <div className='Track_input_fields hoursTrackInner'>
            <div className={`top_area ${isObserver() && 'pointer-events-none'}`}>
                <span className="task_unread"></span>
                <div className='Track_col formdate' style={{ width: '40%' }}>
                    <div onMouseEnter={() => setShowEnter(false)} onMouseLeave={() => setShowEnter(true)} className='date_area_div date_area_div_width dateForHour'>
                        <DatePicker
                            className={error1 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}
                            dateFormat="MMMM dd, yyyy"
                            placeholderText="date"
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div className="CalendarDiv">
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                    <div >
                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                            {years.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                </div>
                            )}
                            selected={v.fdate}
                            onChange={(date) => handleInputChange(index, date, 'fdate')}
                            onBlur={handleBlur}
                            minDate={v.fdate}
                            maxDate={v.tdate}
                            selectsStart
                            startDate={v.fdate}
                        />
                        {showEnter ?
                            <span style={{ top: '0px', right: '8px' }} className='newCalenderIcon' ></span>
                            :
                            <span style={{ right: '8px', top: '9px', display: 'block' }} className='removeFile' ></span>
                        }
                    </div>
                </div>
                <div className='Track_col todate' style={{ width: '40%', marginLeft: '0px' }}>
                    <div onMouseEnter={() => setShowEnter2(false)} onMouseLeave={() => setShowEnter2(true)} className='date_area_div date_area_div_width'>

                        <DatePicker
                            className={error2 === true ? "start_Date_task errorDate Focus" : "start_Date_task Focus"}
                            dateFormat="MMMM dd, yyyy"
                            placeholderText="date"
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div className="CalendarDiv">
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                    <div >
                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                            {years.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                </div>
                            )}

                            selected={v.tdate}
                            onChange={(date) => handleInputChange(index, date, 'tdate')}
                            onBlur={handleBlur}
                            minDate={v.fdate}
                            maxDate={v.tdate}
                            selectsEnd
                        />
                        {showEnter2 ?
                            <span style={{ top: '0px', right: '8px' }} className='newCalenderIcon' ></span>
                            :
                            <span style={{ right: '8px', top: '9px', display: 'block' }} className='removeFile' ></span>
                        }
                    </div>
                </div>
                <div className='Track_col' style={{ width: '13%', marginLeft: '0px' }}>
                    <input type='number'
                        name='forecasted_hours'
                        value={v.forecasted_hours}
                        onChange={(e) => handleInputChange(index, e.target.value, 'forecasted_hours')}
                        onKeyDown={(e) => {
                            preventPlusSign(e); // Prevent plus sign key
                            preventInvalidKeyPress(e); // Other invalid key press prevention logic
                        }}
                        onBlur={handleBlur}
                        className='hours_count trackTime'
                        placeholder='0' />
                </div>
                <div className='Track_col' style={{ width: '10%', marginLeft: '0px' }}>
                    <input type='number'
                        name='actual_hours'
                        value={v.actual_hour}
                        onChange={(e) => handleInputChange(index, e.target.value, 'actual_hour')}
                        onBlur={handleBlur}
                        className='hours_count trackTime'
                        placeholder='0'
                        onKeyDown={(e) => {
                            preventPlusSign(e); // Prevent plus sign key
                            preventInvalidKeyPress(e); // Other invalid key press prevention logic
                        }}
                    />
                </div>
                <div className='Track_col' style={{ marginLeft: '0px' }}>
                    {!isObserver() && <div onClick={() => handleRemoveRow(v, index)} className="fileOpts delete_opts" style={{ position: 'absolute', right: '-34px', top: '-18px' }}></div>}
                </div>
            </div>
            <div className='flex gap-1 items-center mt-1' onClick={() => setNoteView(!noteView)}>
                <div className='text-sm text-[#0079d1]'>{noteView ? "Close note" : "View note"}</div>
                <div>
                    <MdOutlineKeyboardArrowDown className='text-xl text-[#0079d1]' style={{ transform: noteView ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </div>
            </div>
            {noteView && <div className='Track_col notesField' style={{ width: '100%', marginLeft: '0px', display: 'block' }}>
                <textarea className="youtnotesTextArea" value={v.note} onChange={(e) => handleNoteMultiChange(index, e.target.value, 'note')} onBlur={handleBlur} name="notes" placeholder="Add a note" readOnly={isObserver()}></textarea>
            </div>
            }
        </div>
    )
}

export default TrackHourPopup;