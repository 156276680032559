import { useState } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { Get_My_Counter } from '../../Utils/Common';
import { useSelector } from 'react-redux';
import Scrollbars from "react-custom-scrollbars";

export const LeftRoomsList = (props) => {
	const CountersData = useSelector((state) => state.message.countersdata);
	const OnlineUsers = useSelector((state) => state.message.online_users);
	const filterLeft = useSelector(state => state.message.filterLeft);
	const conv_calling_data = useSelector(state => state.message.conv_calling_data); 
	const navigate = useNavigate();
	const params = useParams();
	const [defaultSelect] = useState('')
	const liClassIssue = (data) => {

		let classN = ' ';
		if (data.conversation_id === params.conversation_id) {
			classN = classN + ' selected'
		}
		// if (data.temp_user.indexOf(props.user_id) > -1) {
		// 	classN = classN + ' temp_user';
		// }
		if (data.system_conversation === 'Everyone') {
			classN = classN + ' everyOne system_converstion';
		}
		if (data.system_conversation.indexOf('team') > -1) {
			classN = classN + ' channel system_converstion';
		}

		if (data.group === 'yes') {
			classN = classN + ' private';
		} else {
			classN = classN + (OnlineUsers.indexOf(data.friend_id) > -1 || data.conversation_id === props.user_id ? ' online' : ' offline');
		}
		if (conv_calling_data && conv_calling_data.some(({ conv_id }) => conv_id === data.conversation_id)) {
			classN += ' join_call';

		}
		if (data.close_for === 'yes') {
			classN += ' line_through';
		}
		if (data.has_mute?.indexOf(props.user_id) > -1) {
			classN += ' mute';
		}
		if (defaultSelect !== 'ShowALL') {
			// debugger;
			if (defaultSelect === 'CreatedByMe') {
				if (data.created_by && data.created_by !== props.user_id) {
					classN += ' display_flase';
				}
			} else if (defaultSelect === 'CreatedByOthers') {
				if (data.created_by && data.created_by === props.user_id) {
					classN += ' display_flase';
				}
			} else if (defaultSelect === 'Rooms') {
				if (data.group !== 'yes') {
					classN += ' display_flase';
				}
			} else if (defaultSelect === 'DirectMessages') {
				if (data.group === 'yes') {
					classN += ' display_flase';
				}
			}
		}

		return classN;
	}
	let extraUrlString = props.type === 'threaded' ? '/filter/unread_reply' : props.type === 'flagged' ? '/filter/flag' : '';
	const startConveration = (v) => {
		if (filterLeft === 'normal') {
			localStorage.setItem('last_active_conv', v.conversation_id)
		}
		let url = "/connect/" + v.conversation_id + extraUrlString
		navigate(url)
	}
	return (
		<ul>
		
			{props.rooms.map(v =>
				<li className={'conv_list ' + liClassIssue(v)} onClick={() => startConveration(v)} key={v.conversation_id + '_left_conversation'}>
					<span className="conv_list_title own" >{v.title} {props.type === 'my' ? '(You)' : ''}</span>
					<span className="conv_list_Opt">
						{
							props.type !== 'flagged' &&
							<span className="unreadCounter" >
								{
									props.type === 'threaded' ?
										Get_My_Counter(CountersData, 'scru', v.conversation_id)
										: Get_My_Counter(CountersData, 'scnu', v.conversation_id)
								}
							</span>
						}
					</span>
				</li>
			)}
			
		</ul>
	)

}