import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineAdd, MdOutlineCancel } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { useTaskContext } from '../TaskContext';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
import { CREATE_NEW_KEY_WORD } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import sortArrayByKey from '../../../hooks/useSortedArray';

const AddKeyword = ({ setValue, task }) => {
    const { keywords } = useTaskContext();
    const [all_keywords_data, setAllKeyWordsData] = useState(keywords);
    const [showAddKeyword, setShowAddKeyword] = useState(false);
    const [newKeyword, setNewKeyword] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef(null);

    const [create_keyword] = useMutation(CREATE_NEW_KEY_WORD);

    // const addKeyword = async () => {
    //     if (newKeyword.trim() !== '') {
    //         try {
    //             let apires = await create_keyword({
    //                 variables: {
    //                     input: {
    //                         keywords_title: newKeyword
    //                     }
    //                 }
    //             });
    //             if (apires.data.create_new_key_word.status) {
    //                 let { _id, keywords_title } = apires.data.create_new_key_word.data;
    //                 // Update keywords state to reflect the new keyword
    //                 const newKeywordObj = { _id, keywords_title };
    //                 setAllKeyWordsData(prev => [newKeywordObj, ...prev]);
    //                 toast.success(apires.data.create_new_key_word.message);

    //                 // Automatically add the new keyword to filteredKeywords
    //                 if (!task.key_words.includes(keywords_title)) {
    //                     setValue(prev => prev.map(v => v.temp_key === task.temp_key ? {
    //                         ...v,
    //                         key_words: [...v.key_words, keywords_title]
    //                     } : v));
    //                 }
    //             } else {
    //                 toast.error(apires.data.create_new_key_word.message);
    //             }
    //             console.log("🚀 ~ addKeyword ~ apires:", apires);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //         setNewKeyword('');
    //         setSearchTerm('');
    //     }
    // };
    const addKeyword = async () => {
        const trimmedKeyword = newKeyword.trim();
    
        if (trimmedKeyword !== '') {
            // Check if the keyword already exists (case-insensitive and ignoring spaces)
            const keywordExists = all_keywords_data.some(
                (keyword) => keyword.keywords_title.trim().toLowerCase() === trimmedKeyword.toLowerCase()
            );
    
            if (keywordExists) {
                toast.error('Keyword already exists');
                return;
            }
    
            try {
                let apires = await create_keyword({
                    variables: {
                        input: {
                            keywords_title: trimmedKeyword
                        }
                    }
                });
    
                if (apires.data.create_new_key_word.status) {
                    let { _id, keywords_title } = apires.data.create_new_key_word.data;
                    setAllKeyWordsData(prev => [{ _id, keywords_title }, ...prev]);
                    toast.success(apires.data.create_new_key_word.message);
                } else {
                    toast.success(apires.data.create_new_key_word.message);
                }
            } catch (error) {
                console.log(error);
            }
            setNewKeyword('');
        }
    };

    const handleSelectKeyword = (keyword) => {
        if (!task.key_words.includes(keyword.keywords_title)) {
            setValue(prev => prev.map(v => v.temp_key === task.temp_key ? {
                ...v,
                key_words: [...v.key_words, keyword.keywords_title]
            } : v));
        }
        setShowAddKeyword(false);
    };

    const handleRemoveKeyword = (keyword) => {
        setValue(prev => prev.map(v => v.temp_key === task.temp_key ? {
            ...v,
            key_words: [...v.key_words.filter(f => f !== keyword)]
        } : v));
    };

    const handleInputChange = (e) => {
        const { value } = e.target;

        if (value === '') {
            setNewKeyword(value);
            return;
        }

        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title');
            return;
        }

        const trimmedValue = value.trimStart();

        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        setNewKeyword(trimmedValue);
        setSearchTerm(trimmedValue);
        setHighlightedIndex(-1);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (highlightedIndex >= 0) {
                handleSelectKeyword(filteredKeywords[highlightedIndex]);
            } else {
                addKeyword();
            }
        }
    };

    const handleKeyDown = (event) => {
        if (!showAddKeyword) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredKeywords.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredKeywords.length) % filteredKeywords.length);
        }
    };

    const filteredKeywords = all_keywords_data.filter(keyword =>
        !task.key_words.includes(keyword.keywords_title) && keyword.keywords_title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const ref = useClickAway(() => {
        setShowAddKeyword(false);
    });


    useEffect(() => {
        if (showAddKeyword) {
            inputRef.current.focus();
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showAddKeyword, highlightedIndex, filteredKeywords]);

    return (
        <>
            <div className='relative'>
                <div className='flex flex-wrap gap-2'>
                    {task.key_words.length > 0 &&
                        <div className="flex flex-wrap gap-2" style={{ left: '0px' }}>
                            {task.key_words.map((keyword, index) => (
                                <div key={index} className='bg-[#E3F2FF] flex gap-2 h-6 py-1 px-2 items-center rounded-[5px]'>
                                    <div>
                                        <span className="text-black !text-sm"> {keyword}</span>
                                    </div>
                                    <div onClick={() => handleRemoveKeyword(keyword)}>
                                        <MdOutlineCancel className='text-red-600 text-sm ' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    <div>
                        {
                            !showAddKeyword ?
                                <div id="addKeyword" onClick={() => setShowAddKeyword(!showAddKeyword)} className='w-[145px] bg-[#F1F6FA] !text-gray-400 hover:!text-white hover:bg-[#0B1F47] flex gap-2 h-6 py-1 px-2 items-center rounded-[5px]'>
                                    <div><MdOutlineAdd className='text-sm' /></div>
                                    <div><span className="newAddText !text-sm ">Add a keyword</span></div>
                                </div>
                                :
                                <div ref={ref} className=" border border-[#d6d6d6] mb-3 relative bg-[#e3f2ff] flex gap-2 h-6 py-1 px-2 items-center rounded-[5px]">
                                    <div><MdOutlineAdd className='text-sm text-[#0079d1]' /></div>
                                    <div>
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            className="addKeyWordForCreateTask bg-[#e3f2ff] text-sm"
                                            placeholder="Add a keyword"
                                            maxLength="32"
                                            value={newKeyword}
                                            onChange={handleInputChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <div className="AddKeywordsPopup_task_settings !left-0 !top-[22px] border border-gray-300 insightTaskBoard keywordsLists"
                                            style={{
                                                width: '100%',
                                                maxHeight: '295px',
                                                overflowY: 'auto',
                                            }}>

                                            {sortArrayByKey(filteredKeywords, 'keywords_title', 'asc').map((keyword, index) => (
                                                <div className='addKeyLastChild' key={index}>
                                                    <p className={`cursor-pointer _tag_rooms border-b border-b-gray-300 ${highlightedIndex === index ? 'bg-[#0C1E47] text-white' : ''}`}
                                                        onClick={() => handleSelectKeyword(keyword)}>{keyword.keywords_title}</p>
                                                </div>
                                            ))}
                                            {
                                                filteredKeywords.length === 0 &&
                                                <div onClick={addKeyword} className="searchareaDiv">
                                                    <div className="saveArea p-[5px] text-sm">Create keyword</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Tooltip anchorSelect="#addKeyword" place='top' content="Add a keyword"></Tooltip>
        </>
    );
};

export default AddKeyword;
