/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { CREATE_PROJECT, PROJECT_UPDATE } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { useClickAway } from '@uidotdev/usehooks';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useTaskContext } from '../TaskContext';
import { set_all_projects } from '../../../redux/taskSlice';
import { GoSearch } from "react-icons/go";
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
import sortArrayByKey from '../../../hooks/useSortedArray';

const TaskProject = ({ setProjectPopup, projectEdit, set_projectEdit }) => {
    const dispatch = useDispatch();
    const { selectedTask, OnUpdateTask } = useTaskContext();
    const all_projects = useSelector((state) => state.task.all_projects);

    const [create_project_title] = useMutation(CREATE_PROJECT);
    const [project_update] = useMutation(PROJECT_UPDATE);

    const [title, setTitle] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selected_project, setSelected_project] = useState(null);
    const [editTitle, setEditTitle] = useState(selectedTask?.project_title || '');

    // Filtered and sorted projects
    const filteredProjects = all_projects?.filter(
        (project) =>
            project._id !== 'all' &&
            project.project_title.toLowerCase().includes(searchTerm.trim().toLowerCase() || title.trim().toLowerCase())
    );
    const sorted_data = sortArrayByKey(filteredProjects, 'project_title', 'asc');

    useEffect(() => {
        if (all_projects?.length) {
            setSelected_project(all_projects.find((f) => f._id === selectedTask?.project_id) || null);
        }
    }, [all_projects, selectedTask]);

    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            handleAddTitle();
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleAddTitle = async () => {
        try {
            const normalizedTitle = title.trim().toLowerCase();
            if (!all_projects.some((f) => f.project_title.toLowerCase() === normalizedTitle)) {
                const { data } = await create_project_title({
                    variables: { input: { project_title: title } },
                });
                toast.success(data.create_project.message);
                let d = {
                    _id: data.create_project.data[0]._id,
                    project_title: data.create_project.data[0].project_title,
                }
                dispatch(set_all_projects([d, ...all_projects]));
                setTitle('');
                handleSelectTitle(d);
            } else {
                toast.error(`${title} already exists.`);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to add the project. Please try again.');
        }
    };

    const handleSelectTitle = (project) => {
        setSelected_project(project);
        OnUpdateTask({
            project_id: project._id,
            project_title: project.project_title,
            save_type: 'project_title',
        });
    };

    const handleKeyup = async (event) => {
        if (event.key === 'Enter' && editTitle.trim()) {
            const normalizedEditTitle = editTitle.trim().toLowerCase();
            if (!all_projects.some((f) => f.project_title.toLowerCase() === normalizedEditTitle)) {
                try {
                    const { data } = await project_update({
                        variables: {
                            input: {
                                project_id: selectedTask?.project_id,
                                project_title: editTitle.trim(),
                            },
                        },
                    });
                    if (data.project_update.status) {
                        toast.success(data.project_update.message);
                        dispatch(
                            set_all_projects(
                                all_projects.map((project) =>
                                    project._id === selectedTask?.project_id
                                        ? { ...project, project_title: editTitle.trim() }
                                        : project
                                )
                            )
                        );
                        OnUpdateTask({
                            project_id: selectedTask?.project_id,
                            project_title: editTitle.trim(),
                            save_type: 'project_title',
                        });
                    }
                } catch (error) {
                    console.error(error);
                    toast.error('Failed to update the project title. Please try again.');
                }
            } else {
                toast.error(`${editTitle} already exists.`);
            }
        }
    }

    const ref = useClickAway(() => {
        setTitle('');
    });

    const handleTitleChange = (event) => {
        const value = event.target.value;

        if (value === '') {
            setTitle('');
            return;
        }

        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title');
            return;
        }

        const trimmedValue = value.trimStart();
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        setTitle(trimmedValue);
    };

    return (
        <div className="trackCostPopUpCon">
            <div className="adminRightHead taskHeader !justify-between" style={{ paddingLeft: '20px' }}>
                <div className="backToMainProperties">
                    <span
                        onClick={() => setProjectPopup(false)}
                        className="closeAdminPanel mt-2"
                        data-for="descloseTooltip"
                        data-tip="Back"
                    ></span>
                    <span className="backtoProperties">Task projects</span>
                </div>
                <div className="searchareaDiv" style={{ paddingLeft: "96px" }}>
                    <input
                        type="text"
                        className="_inputBar searchLeft inObserverSearch !bg-[#F1F6FA]"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search a project title"
                        style={{ width: "200px" }}
                    />
                    {searchTerm ?
                        <span
                            onClick={() => setSearchTerm("")}
                            className="removeFile"
                            data-for="member_tooltip"
                            data-tip="Clear Search"
                            style={{
                                position: 'absolute',
                                right: '10px',
                                height: '18px',
                                float: 'right',
                                marginTop: '-30px',
                                display: 'block',
                                top: '38px'
                            }}
                        ></span> :
                        <GoSearch style={{
                            position: 'absolute',
                            right: '10px',
                            height: '18px',
                            float: 'right',
                            marginTop: '-30px',
                            display: 'block',
                            top: '38px'
                        }} className='text-[#102565]' />
                    }
                </div>
            </div>
            <div className="relative">
                <div className="px-4 py-3 flex items-center projectTitleInput relative h-[58px]" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="w-full border border-[#e3f2ff] h-[32px] rounded-sm pl-2 px-1 pr-8"
                        value={title}
                        onChange={handleTitleChange}
                        onKeyUp={handleInputChange}
                        id="taskProjectTitle"
                        placeholder="Add a new project title or pick from below"
                        maxLength="64"
                        autoFocus={!projectEdit}
                    />
                    {title && (
                        <MdOutlineCancel
                            className="text-red-500 top-[18px] absolute right-[50px] text-xl"
                            onClick={() => setTitle('')}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                    <span
                        className="checkList_plusIcon"
                        style={{ position: 'absolute', top: '18px', cursor: 'pointer', right: '22px', width: '20px', height: '18px', zIndex: '9999' }}
                        onClick={handleAddTitle}
                        data-tip="Add project title"
                        data-for="addtooltip"
                    >
                        <i className="fa fa-plus custom_icon" style={{ top: '0px', right: '0px' }}></i>
                    </span>
                </div>
                <div className="!h-[calc(100vh-130px)] w-full overflow-auto">
                    {filteredProjects.length > 0 ? (
                        sorted_data.filter(f => f._id !== 'all' && f.project_title?.trim() !== '').map((project, index) => (
                            <div className={`flex items-center border-b border-b-[#e3f2ff] hover:bg-[#ECEEF4] h-[45px] ${projectEdit && selected_project?._id === project?._id ? 'bg-[#ECEEF4]' : ''}`} key={index}>
                                <p className="w-[10%] flex justify-start items-center">
                                    {selected_project?._id === project?._id ? (
                                        <FaCheckCircle className="text-lg ml-5 text-[#0b1f47]" />
                                    ) : (
                                        <FaCircle className="text-lg ml-5 text-[#E3F2FF]" />
                                    )}
                                </p>
                                {projectEdit && selected_project?._id === project?._id ? (
                                    <input
                                        ref={ref}
                                        value={editTitle}
                                        onChange={(e) => setEditTitle(e.target.value)}
                                        onKeyUp={handleKeyup}
                                        onBlur={() => set_projectEdit('')}
                                        type="text"
                                        className="w-[85%] h-[80%] whitespace-nowrap overflow-ellipsis text-[#0C2568] border border-[#e3f2ff] px-2"
                                        autoFocus
                                    />
                                ) : (
                                    <p
                                        onClick={() => handleSelectTitle(project)}
                                        className="w-[90%] py-[10px] whitespace-nowrap overflow-ellipsis text-[#0C2568] overflow-hidden flex justify-between items-center px-4 !pl-0"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {project.project_title}
                                    </p>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-500 py-32">No projects found.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskProject;
