import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

const CreateTaxes = ({taxesData = [], setTaxesData, type}) => {
  // const [taxesData, setTaxesData] = useState([
  //   { type: "HST 13% NQ", qty: 44, amount: 358.83 },
  //   { type: "HST 13%", qty: 40, amount: 496.23 },
  // ]);



  const calculateTotal = (key) =>
    taxesData.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);

  return (
    <div className="p-6 bg-[#F1F6FA] rounded-lg eodBackground">
   
      <h2 className="text-[#0b1f47] text-[32px]  mb-4">Taxes</h2>
      <div className="w-full rounded-lg overflow-hidden border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
        
      <div className="flex gap-[20px] justify-between text-sm  pb-[13px] pt-[33px] px-[20px]">
          <div className="w-[50%] text-[18px] text-left text-[#0b1f47]">Type</div>
          <div className="w-[25%] text-[18px] text-left text-[#0b1f47]">Qty</div>
          <div className="w-[25%] text-[18px] text-left text-[#0b1f47]">Amount</div>
         
        </div>

        {/* Table Rows */}
        {taxesData.map((tax, index) => (
          <div
            key={index}
            className="flex gap-[20px] justify-between items-center bg-white pb-[13px] px-[20px]"
          >
          <div className="w-[50%] text-left text-[14px] text-[#0b1f47]">{tax.type}</div>
          <div className="w-[25%] text-left text-[14px] text-[#0b1f47]">{tax.qty.toFixed(2)}</div>
          <div className="w-[25%] text-left text-[14px] text-[#0b1f47]">
            ${tax.amount.toFixed(2)}
          </div>
          </div>
        ))}

        {/* Total Row */}
        <div className={`flex gap-[20px] justify-between text-sm  py-3 mx-[20px] ${type === "dashboard" ?  "border-t border-t-[#0b1f47]" :""  }`}>
          <div className={`w-[50%] font-medium text-[18px] text-[#0b1f47] ${type === "dashboard" ?   "" :"border-t border-t-[#0b1f47]" }`}>Total</div>
          <div className={`w-[25%] text-left text-[18px] text-[#0b1f47] ${type === "dashboard" ?   "" :"border-t border-t-[#0b1f47]" }`}>{calculateTotal("qty").toFixed(2)}</div>
          <div className={`w-[25%] text-left text-[18px] text-[#0b1f47] ${type === "dashboard" ?   "" :"border-t border-t-[#0b1f47]" }`}>
            ${calculateTotal("amount").toFixed(2)}
          </div>
        </div>

        {/* Total On-Hand Row */}
        <div className={`flex gap-[20px] justify-between text-sm bg-white pb-[33px] mx-[20px]  ${type === "dashboard" ?  "border-t border-t-[#0b1f47]" :""  }`}>
          <div className={`w-[50%] font-medium text-[18px] text-[#0b1f47] ${type === "dashboard" ?   "" :"border-t border-t-[#0b1f47]" }`}>Total On-Hand</div>
          <div className={`w-[25%] ${type === "dashboard" ?  "" :"border-t border-t-[#0b1f47]"  }`}></div>
          <div className={`w-[25%] text-left text-[18px] text-[#0b1f47] ${type === "dashboard" ?  "" :"border-t border-t-[#0b1f47]"  }`}>
            ${calculateTotal("amount").toFixed(2)}
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default CreateTaxes;
