export default function ImageLoader() {
    return (
        <div className="ai_loader">
            <svg
                version="1.1"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 200 100"
                xmlSpace="preserve"
            >
                {/* Circle 1 */}
                <circle fill="#d8d8d8" stroke="none" cx="15" cy="40" r="12">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.1s"
                    />
                </circle>

                {/* Circle 2 */}
                <circle fill="#d8d8d8" stroke="none" cx="55" cy="40" r="12"> {/* Increased cx by 10px */}
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.2s"
                    />
                </circle>

                {/* Circle 3 */}
                <circle fill="#d8d8d8" stroke="none" cx="95" cy="40" r="12"> {/* Increased cx by 10px */}
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.3s"
                    />
                </circle>

                {/* Circle 4 */}
                <circle fill="#d8d8d8" stroke="none" cx="135" cy="40" r="12"> {/* Increased cx by 10px */}
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.4s"
                    />
                </circle>
            </svg>
        </div>
    )
};