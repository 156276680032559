/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from 'react';
// import { useTaskContext } from '../TaskContext';
// import { useDispatch } from 'react-redux';
// import { set_action_file, set_downloading_file, set_downloading_progress, setPopup, update_messages } from '../../../redux/message';
// import { useSelector } from 'react-redux';
// import { useMutation } from '@apollo/client';
// import { ATTACHMENT_STAR_MUTATION } from '../../../Utils/GraphqlMutaion';
// import Axios from "axios";
// import WarningPopup from '../../Connect/WarningPopup';
// const FileDownload = require('js-file-download');

// const TaskFilePopup = ({ setTaskFilesPopup }) => {
//     const dispatch = useDispatch()
//     const { selectedTask, OnUpdateTask } = useTaskContext()
//     const change_file_data = useSelector(state => state.task.change_file_data)
//     const downloading_progress = useSelector(state => state.message.downloading_progress);
//     const user = useSelector(state => state.message.user);
//     const [searchTaskFiles, setSearchTaskFiles] = useState("");
//     const [taskFileList, set_taskFileList] = useState(selectedTask?.files);
//     const [action_file, setaction_file] = useState(null)

//     function niceBytes(x) {
//         const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//         let l = 0, n = parseInt(x, 10) || 0;
//         while (n >= 1024 && ++l) {
//             n = n / 1024;
//         }
//         return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
//     }

//     useEffect(() => {
//         if (change_file_data) {
//             console.log('change_file_data:', change_file_data);
//             if (change_file_data.xmpp_type === 'star_file') {
//                 set_taskFileList(prev => prev.map(f => f.id === change_file_data.file_id ? { ...f, star: change_file_data.star } : f))
//             } else if (change_file_data.xmpp_type === 'delete_msg' || change_file_data.xmpp_type === 'delete_one_file') {
//                 set_taskFileList(prev => prev.filter(f => f.id !== change_file_data.file_id))
//             } else if (change_file_data.xmpp_type === 'add_remove_tag_into_msg') {
//                 set_taskFileList(prev => prev.map((f) =>
//                     f.id === change_file_data.file_id || change_file_data.file_id === change_file_data.conversation_id ?
//                         {
//                             ...f,
//                             tag_list: f.tag_list ? [...f.tag_list.filter((t) => change_file_data.removetag.indexOf(t) === -1), ...change_file_data.newtag.filter(t => f.tag_list.indexOf(t) === -1)] : [...change_file_data.newtag],
//                             tag_list_details: (f.tag_list_details && f.tag_list) ? [...f.tag_list_details.filter((t) => change_file_data.removetag.indexOf(t.tag_id) === -1), ...change_file_data.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)] : [...change_file_data.newtag_tag_data],
//                         } : f
//                 ))
//             } else if (change_file_data.xmpp_type === 'file_share_link_create') {
//                 set_taskFileList(prev => prev.map(f => f.id === change_file_data.id ? { ...f, url_short_id: change_file_data.url_short_id } : f))
//             }
//         }
//     }, [change_file_data])

//     const [starMutation] = useMutation(ATTACHMENT_STAR_MUTATION);
//     const starMarkFile = (file, msg) => {
//         starMutation({
//             variables: {
//                 input: {
//                     file_id: file.id,
//                     is_reply_msg: file.main_msg_id ? 'true' : 'false'
//                 }
//             }
//         })
//     }

//     const AfileDownload = (url, filename) => {
//         dispatch(set_downloading_file([filename]))
//         if (downloading_progress[filename]) return
//         dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
//         Axios({
//             url: url,
//             method: 'GET',
//             responseType: 'blob',
//             onDownloadProgress(progressEvent) {
//                 let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
//                 dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
//             }
//         }).then((response) => {
//             FileDownload(response.data, filename);
//             let d_progress = { ...downloading_progress };
//             d_progress = { ...(delete d_progress[filename] && d_progress) }
//             dispatch(set_downloading_progress(d_progress))
//         });
//     }

//     const [file_delete, setFile_delete] = useState(false)

//     const handleFileAction = (type, _f) => {
//         console.log("🚀 ~ handleFileAction ~ _f:", _f)
//         dispatch(set_action_file(_f))
//         setaction_file(_f)
//         if (type === 'star') {
//             starMarkFile(_f)
//         } else if (type === 'tag') {
//             dispatch(setPopup({ data: true, key: 'tag_popup' }));
//         } else if (type === 'download') {
//             AfileDownload(_f.location, _f.originalname)
//         } else if (type === 'delete') {
//             setFile_delete(true)
//         }
//     }

//     const handleDelPopup = () => {
//         setFile_delete(false)
//     }

//     return (
//         <>
//             <div className="trackCostPopUpCon">
//                 <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
//                     <div className="backToMainProperties">
//                         <span onClick={() => setTaskFilesPopup(false)} className="closeAdminPanel mt-2" data-for="descloseTooltip" data-tip="Back" ></span>
//                         <span className="backtoProperties">Files</span>
//                     </div>
//                     <div className="searchareaDiv !mr-8" style={{ paddingLeft: '260px' }}>
//                         <input
//                             value={searchTaskFiles}
//                             onChange={(e) => setSearchTaskFiles(e.target.value)}
//                             className="_inputBar searchLeft forfile" type="text" placeholder="Search file" />
//                         {
//                             searchTaskFiles && <span onClick={() => setSearchTaskFiles("")} className="removeFile" data-for="member_tooltip" data-tip="Clear Search" style={{ position: 'absolute', right: '7px', height: '18px', float: 'right', marginTop: '-30px', display: 'block', top: '38px' }}></span>
//                         }
//                     </div>
//                     <span className="checkList_plusIcon" data-for="checkList_tip" data-tip="Click to add files" style={{ top: '20px', right: '20px', position: 'absolute' }}><i className="fa fa-plus custom_icon"></i></span>
//                 </div>
//                 <div className="Track_body bodyArea">
//                     <div className="middle_area" style={{ height: 'calc(-65px + 100vh)' }}>
//                         {
//                             taskFileList.length > 0 && taskFileList.map((item, index) => {
//                                 return (
//                                     <div key={index} className="mediaContainer chatScreenArea !bg-white" style={{ paddingBottom: '0px', paddingLeft: '20px', flexDirection: 'column', paddingRight: '10px' }}>
//                                         <div className="single_file !mb-[0px]">
//                                             <div className="file_hover_option">
//                                                 <div onClick={() => handleFileAction('star', item)} data-for="rightSection_tooltip" data-tip="Add to starred" className={`fileOpts star_opts ${item.star?.indexOf(user?.id) > -1 ? 'active' : ''}`}></div>
//                                                 <div onClick={() => handleFileAction('tag', item)} className="fileOpts tag_opts" data-for="rightSection_tooltip" data-tip="Add to tag"></div>
//                                                 <div onClick={() => handleFileAction('download', item)} className="fileOpts download_opts" data-for="rightSection_tooltip" data-tip="Download" data-name={item.fileName} data-link={item.downloadLink}></div>
//                                                 <div onClick={() => handleFileAction('delete', item)} className="fileOpts delete_opts" data-for="rightSection_tooltip" data-tip="Delete to tag"></div>
//                                             </div>
//                                             <div className="fileContent audioFiles">
//                                                 <div className="img_holder">
//                                                     <img className="" src={item.location} data-link={item.location} alt="galleryImage" title='galleryImage' />
//                                                 </div>
//                                                 <div className="file_info">
//                                                     <p className="file_name" title={item.originalname} style={{ textAlign: 'left' }}>{item.originalname}</p>
//                                                     <p className="file_size">{niceBytes(item.file_size)}</p>
//                                                 </div>
//                                                 <div className="fileTagHolder">
//                                                     {item.tag_list_details.map((tag, index) => (
//                                                         <span key={index} className="fileTag" style={{ backgroundColor: tag.tag_color }}>{tag.title}</span>
//                                                     ))}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 )
//                             })
//                         }
//                         {
//                             taskFileList.length === 0 && <div className='flex h-screen justify-center items-center flex-col -mt-[50px]'>
//                                 <div className="middle_area">
//                                     <div className="file_area">
//                                         <div className="file_icon"></div>
//                                         <div className="file_text">This task has no files</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         }
//                     </div>
//                 </div>
//             </div>
//             {file_delete && <WarningPopup messageTitle={'Delete File'} description={'Are you sure you want to delete this file?'} closePopUp={handleDelPopup} data={action_file} type={"file_delete"} />}
//         </>

//     );
// };

// export default TaskFilePopup;


import React, { Fragment, useEffect, useState } from 'react';
import { useTaskContext } from '../TaskContext';
import { useDispatch } from 'react-redux';
import { set_action_file, set_downloading_file, set_downloading_progress, setPopup } from '../../../redux/message';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { ATTACHMENT_STAR_MUTATION } from '../../../Utils/GraphqlMutaion';
import Axios from "axios";
import WarningPopup from '../../Connect/WarningPopup';
import { fileExtension } from '../../../Utils/Common';
const FileDownload = require('js-file-download');

const TaskFilePopup = ({ setTaskFilesPopup }) => {
    const { selectedTask, setTaskFileUpload } = useTaskContext();

    const [searchTaskFiles, setSearchTaskFiles] = useState("");
    const [taskFileList, setTaskFileList] = useState(selectedTask?.files);

    useEffect(() => {
        setTaskFileList(selectedTask?.files);
    }, [selectedTask?.files]);


    const filteredTaskFiles = taskFileList?.filter(file =>
        file.originalname.toLowerCase().includes(searchTaskFiles.toLowerCase())
    );

    return (
        <>
            <div className="trackCostPopUpCon">
                <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
                    <div className="backToMainProperties">
                        <span onClick={() => setTaskFilesPopup(false)} className="closeAdminPanel mt-2 cursor-pointer" data-for="descloseTooltip" data-tip="Back"></span>
                        <span className="backtoProperties">Files</span>
                    </div>

                    <div className="searchareaDiv !mr-8" style={{ paddingLeft: '260px' }}>
                        <input
                            value={searchTaskFiles}
                            onChange={(e) => setSearchTaskFiles(e.target.value)}
                            className="_inputBar searchLeft forfile"
                            type="text"
                            placeholder="Search file"
                        />
                        {searchTaskFiles && (
                            <span
                                onClick={() => setSearchTaskFiles("")}
                                className="removeFile"
                                data-for="member_tooltip"
                                data-tip="Clear Search"
                                style={{
                                    position: 'absolute',
                                    right: '7px',
                                    height: '18px',
                                    float: 'right',
                                    marginTop: '-30px',
                                    display: 'block',
                                    top: '38px'
                                }}
                            ></span>
                        )}
                    </div>

                    <span className="checkList_plusIcon" onClick={() => setTaskFileUpload(true)} data-for="checkList_tip" data-tip="Click to add files" >
                        <i className="fa fa-plus custom_icon"></i>
                    </span>
                </div>
                <div className="Track_body bodyArea">
                    <div className="middle_area px-[20px]" style={{ height: 'calc(-50px + 100vh)' }}>
                        <RenderFiles files={filteredTaskFiles} />
                    </div>
                </div>
            </div>

        </>
    );
};

export const RenderFiles = ({ files }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.message.user);
    const { setSelectedTask } = useTaskContext();
    const change_file_data = useSelector(state => state.task.change_file_data);
    const [action_file, setActionFile] = useState(null);
    const [file_delete, setFileDelete] = useState(false);
    const [starMutation] = useMutation(ATTACHMENT_STAR_MUTATION);
    const downloading_progress = useSelector(state => state.message.downloading_progress);


    useEffect(() => {
        if (change_file_data) {
            if (change_file_data.xmpp_type === 'star_file') {
                setSelectedTask(prev => ({
                    ...prev,
                    files: prev.files.map(f => f.id === change_file_data.file_id ? { ...f, star: change_file_data.star } : f)
                }));
            } else if (change_file_data.xmpp_type === 'delete_msg' || change_file_data.xmpp_type === 'delete_one_file') {
                setSelectedTask(prev => ({ ...prev, files: prev.files.filter(f => f.id !== change_file_data.file_id) }));
            } else if (change_file_data.xmpp_type === 'add_remove_tag_into_msg') {
                setSelectedTask(prev => ({
                    ...prev,
                    files: prev.files.map(f =>
                        f.id === change_file_data.file_id || change_file_data.file_id === change_file_data.conversation_id ?
                            {
                                ...f,
                                tag_list: f.tag_list ? [...f.tag_list.filter(t => change_file_data.removetag.indexOf(t) === -1), ...change_file_data.newtag.filter(t => f.tag_list.indexOf(t) === -1)] : [...change_file_data.newtag],
                                tag_list_details: (f.tag_list_details && f.tag_list) ? [...f.tag_list_details.filter(t => change_file_data.removetag.indexOf(t.tag_id) === -1), ...change_file_data.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)] : [...change_file_data.newtag_tag_data],
                            } : f
                    )
                }));
            } else if (change_file_data.xmpp_type === 'file_share_link_create') {
                setSelectedTask(prev => ({ ...prev, files: prev.files.map(f => f.id === change_file_data.id ? { ...f, url_short_id: change_file_data.url_short_id } : f) }));
            }
        }
    }, [change_file_data]);

    function niceBytes(x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    const handleDelPopup = () => {
        setFileDelete(false);
    };

    const starMarkFile = (file) => {
        starMutation({
            variables: {
                input: {
                    file_id: file.id,
                    is_reply_msg: file.main_msg_id ? 'true' : 'false'
                }
            }
        });
    };

    const AfileDownload = (url, filename) => {
        dispatch(set_downloading_file([filename]));
        if (downloading_progress[filename]) return;
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }));
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
                dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }));
            }
        }).then((response) => {
            FileDownload(response.data, filename);
            let d_progress = { ...downloading_progress };
            d_progress = { ...(delete d_progress[filename] && d_progress) };
            dispatch(set_downloading_progress(d_progress));
        });
    };

    const handleFileAction = (type, _f) => {
        dispatch(set_action_file(_f));
        setActionFile(_f);
        if (type === 'star') {
            starMarkFile(_f);
        } else if (type === 'tag') {
            dispatch(setPopup({ data: true, key: 'tag_popup' }));
        } else if (type === 'download') {
            AfileDownload(_f.location, _f.originalname);
        } else if (type === 'delete') {
            setFileDelete(true);
        }
    };

    const getThumImage = (l) => {
        let ar = l.split('/');
        let n = 'thumb-' + ar[ar.length - 1];
        ar[ar.length - 1] = n;
        return ar.join('/');
    }


    return (
        <Fragment>
            {
                files?.filter(f => !f.has_delete?.includes(user.id))?.length > 0 ? (
                    files?.filter(f => !f.has_delete?.includes(user.id))?.map((item, index) => (
                        <div key={index} className="mediaContainer chatScreenArea !bg-white"
                            style={{
                                paddingBottom: '0px',
                                flexDirection: 'column',
                            }}
                        >
                            <div className="single_file !mb-[0px]">
                                <div className="file_hover_option">
                                    <div
                                        onClick={() => handleFileAction('star', item)}
                                        data-for="rightSection_tooltip"
                                        data-tip="Add to starred"
                                        className={`fileOpts star_opts ${item.star?.indexOf(user?.id) > -1 ? 'active' : ''}`}
                                    ></div>
                                    <div
                                        onClick={() => handleFileAction('tag', item)}
                                        className="fileOpts tag_opts"
                                        data-for="rightSection_tooltip"
                                        data-tip="Add to tag"
                                    ></div>
                                    <div
                                        onClick={() => handleFileAction('download', item)}
                                        className="fileOpts download_opts"
                                        data-for="rightSection_tooltip"
                                        data-tip="Download"
                                        data-name={item.fileName}
                                        data-link={item.downloadLink}
                                    ></div>
                                    <div
                                        onClick={() => handleFileAction('delete', item)}
                                        className={`fileOpts delete_opts ${item.user_id !== user.id && 'opacity-[0.5] pointer-events-none'}`}
                                        data-for="rightSection_tooltip"
                                        data-tip="Delete"
                                    ></div>
                                </div>
                                <div className="fileContent audioFiles">
                                    <div className="img_holder">

                                        {item.location.includes('blob:') ?
                                            <img src={item.location} width={50} height={45} loading='lazy' alt="img" />
                                            :
                                            <img src={
                                                item.file_type?.indexOf('image') > -1 && item.file_type?.indexOf('svg') === -1 ?
                                                    getThumImage(item.location)
                                                    : item.file_category?.indexOf('voice') > -1 ?
                                                        `/media/images/light_theme/voice.svg`
                                                        : `/media/images/light_theme/${fileExtension(item?.originalname)}.svg`
                                            } width={50} height={45} loading='lazy' alt="file ext" title="sender" className="file_ext" />
                                        }
                                    </div>
                                    <div className="file_info">
                                        <p
                                            className="file_name"
                                            title={item.originalname}
                                            style={{ textAlign: 'left' }}
                                        >
                                            {item.originalname}
                                        </p>
                                        <p className="file_size">{niceBytes(item.file_size)}</p>
                                    </div>
                                    <div className="fileTagHolder">
                                        {item.tag_list_details?.map((tag, index) => (
                                            <span
                                                key={index}
                                                className="fileTag"
                                                style={{ backgroundColor: tag.tag_color }}
                                            >
                                                {tag.title}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="flex h-screen justify-center items-center flex-col -mt-[50px]">
                        <div className="middle_area">
                            <div className="file_area">
                                <div className="file_icon"></div>
                                <div className="file_text">This task has no files</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {file_delete && (
                <WarningPopup
                    messageTitle={'Delete File'}
                    description={'Are you sure you want to delete this file?'}
                    closePopUp={handleDelPopup}
                    data={action_file}
                    type={'file_delete'}
                />
            )}
        </Fragment>
    )
}

export default TaskFilePopup;
