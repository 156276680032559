/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { IoMdAdd } from "react-icons/io";
import NotificationsTabHome from './NotificationsTab/NotificationsTabHome';
import ReviewTabHome from './ReviewTab/ReviewTabHome';
import TaskListView from './TasksTab/TaskListView';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useClickAway } from "@uidotdev/usehooks";
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { TfiClose } from "react-icons/tfi";
import { GoSearch } from "react-icons/go";
import { useDispatch } from 'react-redux';
import { set_task_search_val } from '../../redux/taskSlice';
import { useSelector } from 'react-redux';
import { BsPinFill } from 'react-icons/bs';
import KeywordFilter from './TaskFilterPopup/KeywordFilter';
import AssigneeFilter from './TaskFilterPopup/AssigneFilter';
import { MdOutlineClose } from 'react-icons/md';
import RoomFilter from './TaskFilterPopup/RoomFilter';
import ObserverFilter from './TaskFilterPopup/ObserverFilter';
import ProjectFilter from './TaskFilterPopup/ProjectFilter';
import DateRangeFilter from './TaskFilterPopup/DateRangeFilter';
import KanbanHome from './KanbanViewTab/KanbanHome';
import { TaskProvider, useTaskContext } from './TaskContext';
import TaskPropertiesCon from './TaskProperties/TaskPropertiesCon';
import { TagPopup } from '../GlobalPopups/TagPopup';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { isValidConvId } from '../../Utils/Common';
import { setCountersData } from '../../redux/message';

const filterOptions = [
    { id: 'keywords', name: 'Keywords' },
    { id: 'assignees', name: 'Assignees' },
    { id: 'overdues', name: 'Overdues' },
    { id: 'unreads', name: 'Unreads' },
    { id: 'complete', name: 'Completed' },
    { id: 'dateRange', name: 'Date range' },
    { id: 'room', name: 'Room' },
    { id: 'observer', name: 'Observer' },
    { id: 'projects', name: 'Projects' },
    { id: 'created_by', name: 'Task created by me' },
    { id: 'created_by_other', name: 'Task created by others' },
    { id: 'assign_to_me', name: 'Task assign to me' },
    { id: 'assign_today', name: 'Today\'s assigned task(s)' },
];

const TaskManagement = ({ from }) => {
    const dispatch = useDispatch()
    const params = useParams()
    const _redux = useSelector(state => state.message)
    const { fetchByFilter, fetchByViewType, setSelectedTask, user, setPagination, set_column_id, viewType, setViewType, filter, set_filter, trackCostPopup, set_read_all } = useTaskContext()
    const last_conv = localStorage.getItem('last_active_conv')
    const task_search_val = useSelector(state => state.task.task_search_val)
    const countersdata = useSelector(state => state.message.countersdata)
    const active_conversation = useSelector(state => state.message.active_conversation)
    
    let location = useLocation();
    const [tasksTab, setTasksTab] = useState("");
    const [kanbanOptions, setKanbanOptions] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [filterPopup, setFilterPopup] = useState(false);
    const [searchTopOpen, setSearchTopOpen] = useState(false);

    const [keywordFilterPopup, setKeywordFilterPopup] = useState(false);
    const [assigneeFilterPopup, setAssigneeFilterPopup] = useState(false);
    const [roomFilterPopup, setRoomFilterPopup] = useState(false);
    const [observerFilterPopup, setObserverFilterPopup] = useState(false);
    const [projectFilterPopup, setProjectFilterPopup] = useState(false);
    const [dateFilterPopup, setDateFilterPopup] = useState(false);
    const [read_button, set_read_button] = useState(true);

    const ref = useClickAway(() => {
        setKanbanOptions(false)
    });

    const ref2 = useClickAway(() => {
        setShowOptions(false);
    });

    const ref3 = useClickAway(() => {
        setFilterPopup(false);
    });

    const ref4 = useClickAway(() => {
        setSearchTopOpen(false);
    });


    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(`/connect/${isValidConvId(last_conv) ? last_conv : user?.id}`)
    };

    const taskTabForStatus = (tab) => {
        setTasksTab('KanbanStatus')
        setKanbanOptions(false)
    }


    const [tab_state, setTab_state] = useState(params.tasks_tab ? params.tasks_tab : 'kanban-view')

    useEffect(() => {
        if (params.tasks_tab) {
            setTab_state(params.tasks_tab)
        }
    }, [params])

    const handleTaskFilter = (type) => {
        if (type === "overdues" || type === "unreads" || type === "complete" || type === "created_by" || type === "created_by_other" || type === "assign_to_me" || type === "assign_today") {
            setPagination(prev => ({ ...prev, page: 1 }))
            set_column_id([])
            let filteredArray = filterArray(filter, type);
            set_filter(prev => {
                return [...filteredArray, type]
            })
            fetchByFilter([...filteredArray, type]);
            set_read_button(true)
        } else if (type === 'keywords') {
            setKeywordFilterPopup(!keywordFilterPopup)
        } else if (type === 'assignees') {
            setAssigneeFilterPopup(!assigneeFilterPopup)
        } else if (type === 'dateRange') {
            setDateFilterPopup(!dateFilterPopup)
        } else if (type === 'room') {
            setRoomFilterPopup(!roomFilterPopup)
        } else if (type === 'observer') {
            setObserverFilterPopup(!observerFilterPopup)
        } else if (type === 'projects') {
            setProjectFilterPopup(!projectFilterPopup)
        }
        setFilterPopup(false)
    }

    const handleSearch = (e) => {
        if (e.keyCode === 13) {
            if (task_search_val.trim().length > 0) {
                setPagination(prev => ({ ...prev, page: 1 }))
                set_column_id([])
                let filteredArray = filterArray(filter, 'task_title');
                set_filter(prev => {
                    return [...filteredArray, ['task_title', task_search_val]]
                })
                fetchByFilter([...filteredArray, ['task_title', task_search_val]]);
            }
        }
    }
    function filterArray(myArray, type) {
        return myArray.filter(item => {
            // Check if item is an array and first element matches the type
            if (Array.isArray(item) && item[0] === type) {
                return false; // Remove this item (don't include in the filtered array)
            }
            // Check if item is not an array and the item itself matches the type
            if (!Array.isArray(item) && item === type) {
                return false; // Remove this item (don't include in the filtered array)
            }
            // Otherwise, keep the item
            return true;
        });
    }

    const removeFilterType = (type) => {
        let filteredArray = filterArray(filter, type);
        set_read_all('no');
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(filteredArray);
        fetchByFilter(filteredArray);
    };

    const [pinned_filter, set_pinned_filter] = useState([]);

    // Retrieve pinned_filter from localStorage on component mount
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('pinned_filter')) || [];
        set_pinned_filter(savedFilters);
    }, []);

    // Save pinned_filter to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('pinned_filter', JSON.stringify(pinned_filter));
    }, [pinned_filter]);

    const pinFilter = (e, type) => {
        e.stopPropagation();
        if (pinned_filter.includes(type)) {
            const filteredArray = pinned_filter.filter(item => item !== type);
            set_pinned_filter(filteredArray);
        } else {
            set_pinned_filter([...pinned_filter, type]);
        }
    };

    const changeViewType = (type) => {
        setSelectedTask(null)
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        setViewType(type)
        fetchByViewType(type)
    }

    const read_task = () => {
        setPagination(prev => ({ ...prev, page: 1 }))
        set_read_all('yes');
        dispatch(setCountersData({ ...countersdata, taskunreadcount: 0 }));
        set_read_button(false)
    }

    const filters = filter.map((type) => Array.isArray(type) ? type[0] : type)

    return (
        <div className='right_container_task task lg:!left-[0px] relative' style={from === 'room' ? { width: '100%', position: 'absolute', top: '0px', right: '0px' } : {}}>
            <Toaster />
            <div className="taskTopHead relative">
                <div className='flex gap-6 items-center'>
                    <div className='relative' onClick={handleGoBack}>
                        <span className="backToChat !absolute !l-[5px] !-top-[10px]"></span>
                    </div>
                    <ol className="custom_breadcrumb">
                        <li className="breadcrumb-item">Task </li>
                        <li className="breadcrumb-item">
                            {
                                !params.conversation_id && (params.tasks_tab === 'list-view' || params.tasks_tab === 'kanban-view') ? "Tasks from all rooms" :
                                    params.conversation_id && (params.tasks_tab === 'list-view' || params.tasks_tab === 'kanban-view') ? "Tasks from Room: " + active_conversation?.title :
                                        params.tasks_tab === 'notifications' ? "Notifications" : null
                            }
                        </li>
                    </ol>
                </div>
                <div className='flex gap-4 items-center'>
                    <div className='bg-[#f1f6fa]  hover:bg-[#0D2150] rounded-full w-[26px] h-[26px] cursor-pointer' onClick={() => setSearchTopOpen(true)}>
                        <GoSearch className='text-[#2196f3] hover:!text-white mt-[5px] ml-[5px]' id='Searchtop_head' />
                        <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#Searchtop_head" place='left' float="true" content="Search" />
                    </div>
                    {
                        searchTopOpen && <div ref={ref4} className="w-[400px] right-[50px] absolute flex justify-end items-center forTagFilter">
                            <input className="_inputSearchBar mainSerach !ml-0 !mr-0" type="text" value={task_search_val} onChange={(e) => dispatch(set_task_search_val(e.target.value))} onKeyUp={handleSearch} placeholder="Search" style={{ width: '100%' }} autoFocus />
                            {task_search_val.length > 0 && <span className="clearAllSrcTest !right-10" data-for="top_head" data-tip="Clear Search" onClick={() => { dispatch(set_task_search_val('')); removeFilterType('task_title') }}></span>}
                            <div className="srcBtnSection !bg-transparent" data-for="top_head" data-tip="Search" style={{ right: '2px', height: '31px' }}></div>
                        </div>
                    }
                    <button onClick={handleGoBack}>
                        <TfiClose id="close-tasks" className="text-2xl text-[#032E84]" />
                    </button>
                    <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#close-tasks" place='top' float="true" content="Close" />
                </div>
                {(filter.length > 0 && tab_state !== "list-view") &&
                    <div className='absolute left-[35%]  filteredLists'>
                        <div className='flex gap-2 items-center '>
                            <div className="filterKanbanIconTask inKeywordFiltered relative"></div>
                            <p className='text-[#002E98] text-xs'>Filtered by :</p>
                            <div className='flex bg-[#002E98] rounded-full'>
                                {filter.map((type, i) => {
                                    let item_key = Array.isArray(type) ? type[0] : type;
                                    return (
                                        <div key={'filter_' + i} className={`flex gap-1 text-white py-1 px-3 items-center ${filter.length > 1 ? 'border-r last:border-r-[0px]' : 'rounded-full'} `}>
                                            <p key={item_key} className='text-xs'>
                                                {
                                                    item_key === 'task_title' ? 'Title'
                                                        : item_key === 'keywords' ? 'Keyword'
                                                            : item_key === 'assignees' ? 'assignees'
                                                                : item_key === 'overdues' ? 'overdues'
                                                                    : item_key === 'unreads' ? 'unreads'
                                                                        : item_key === 'complete' ? 'completed'
                                                                            : item_key === 'dateRange' ? 'date'
                                                                                : item_key === 'room' ? 'rooms'
                                                                                    : item_key === 'observer' ? 'observers'
                                                                                        : item_key === 'projects' ? 'projects'
                                                                                            : item_key === 'created_by' ? 'created by me'
                                                                                                : item_key === 'created_by_other' ? 'created by others'
                                                                                                    : item_key === 'assign_to_me' ? 'assigned to me'
                                                                                                        : item_key === 'assign_today' ? 'assigned today'
                                                                                                            : ''
                                                }
                                            </p>
                                            <MdOutlineClose id='back-tasks' className=' cursor-pointer' onClick={() => removeFilterType(item_key)} />
                                            <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#back-tasks" place='top' float="true" content="Close" />
                                        </div>
                                    )
                                }
                                )}

                            </div>
                            {(filters.includes('unreads') && countersdata?.taskunreadcount > 0 && read_button) &&
                                <div className='flex gap-1 text-[#032E84] py-1 px-3 items-center rounded-full'>
                                    <p className='text-xs cursor-pointer' onClick={read_task}>Mark all read</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="taskHeadSection topHead">
                <div className="task_column ">
                    <ul className="task_menu_Lists">
                        <Link to="kanban-view" title='Tasks' onClick={() => changeViewType('kanban')}>
                            <li className={`taskLists_tab ${tab_state === "kanban-view" || tab_state === "list-view" || location.pathname === "/connect/tasks/list-view" ? 'activeTaskMenu' : ''}`}>
                                Tasks {countersdata?.taskunreadcount > 0 && <span className='task_unread'></span>}
                            </li>
                        </Link>
                        <Link to="notifications" title='Notifications' onClick={() => setSelectedTask(null)}>
                            <li className={`taskLists_tab ${tab_state === "notifications" ? 'activeTaskMenu' : ''}`}>Notifications</li>
                        </Link>
                    </ul>
                </div>
                <div className="task_column button_area">
                    {tasksTab === 'tasks' ?
                        <div className='relative'>
                            <button onClick={() => setKanbanOptions(true)}
                                className={`${tasksTab === 'tasks' || tasksTab === 'KanbanStatus' ? '!bg-[#BFDCFF] !text-[#0c1e47]' : ''} flex justify-center runReportButton gap-1 items-center !bg-[#BFDCFF] !w-[100px]`}>Kanban
                                <FaChevronDown className={` ${kanbanOptions ? 'rotate-180' : ''} !text-xs`} /></button>
                            {
                                kanbanOptions && <ul ref={ref} className="userActionPopup !w-[100px] showAll checklistPopup top-9 !left-0 !mt-0" >
                                    <li className={`${tasksTab === 'KanbanStatus' ? '!bg-[#BFDCFF] !text-white' : ''} text-center`} onClick={taskTabForStatus}> Status</li>
                                    <li className='text-center' onClick={() => setTasksTab('KanbanProgress')}> Progress</li>
                                    <li className='text-center' onClick={() => setTasksTab('KanbanProgress')}> Keywords</li>
                                </ul>
                            }
                        </div> : ""
                    }
                    {(tab_state !== "list-view" && tab_state !== "notifications") &&
                        <>
                            {pinned_filter.length > 0 &&
                                <div className='relative'>
                                    <div className='flex bg-[#002E98] rounded-full'>
                                        {pinned_filter.map((type, i) =>
                                            <div key={i} onClick={() => handleTaskFilter(type)} className={`flex gap-1 text-white py-1 px-3 items-center ${pinned_filter.length > 1 ? 'border-r last:border-r-[0px]' : 'rounded-full'} `}>
                                                <p key={type} className='text-xs cursor-pointer'>  {filterOptions.find(f => f.id === type).name}</p>
                                                <BsPinFill id='back-tasks' className=' cursor-pointer text-[13px]' onClick={(e) => pinFilter(e, type)} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className='relative'>
                                <div className="forKanbanView filtered relative" onClick={() => setFilterPopup(!filterPopup)}>
                                    <div className="filterKanbanIcon" id='allfilteredList'></div>
                                </div>
                                <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#allfilteredList" place='left' float="true" content="Click to view filter menus" />
                                {
                                    filterPopup && <div ref={ref3} className='filteredPopup_div'>
                                        <div className="filterActionPopupKanban">
                                            <p className="profilenavMidleArea_label">Filter</p>
                                            <ul className="filterActionList">
                                                {filterOptions.filter(f => !filters.includes(f.id)).map((type, i) =>
                                                    <li key={i} onClick={() => handleTaskFilter(type.id)} className="filter_list filterListKanbanLi group last:border-b-0">
                                                        <span className="filterTypeLabels">{type.name}</span>
                                                        <BsPinFill onClick={(e) => pinFilter(e, type.id)} className={`text-[#89CFF0] group-hover:block absolute right-2 ${pinned_filter.includes(type.id) ? 'block' : 'hidden'}`} />
                                                    </li>
                                                )}
                                            </ul>
                                        </div >
                                    </div >
                                }

                            </div >
                        </>
                    }


                    {tab_state !== "notifications" &&
                        <div className=" relative">
                            {location.pathname === "/connect/tasks/list-view" || location.pathname === "/connect/" + params.conversation_id + "/tasks/list-view"?
                                <Link to='kanban-view' title='Kanban View' onClick={() => changeViewType('kanban')}>
                                    <button onClick={() => setTab_state("kanban-view")} className=' bg-[#0c1e47] text-white hover:bg-[#318FFF] hover:text-white h-[33px] rounded-[50px] text-[12px] pl-4 flex justify-between items-center pr-4 !w-full text-center'>
                                        Kanban {location.pathname === "/connect/tasks/list-view" || location.pathname === "/connect/" + params.conversation_id + "/tasks/list-view"?  "" : <FaChevronDown className='mr-3 ml-2' />}
                                    </button>
                                </Link>
                                :
                                <button onClick={toggleOptions} className='bg-[#318FFF] h-[33px] rounded-[50px] flex justify-center items-center !w-[148px] text-[12px] pl-1 pr-1 text-white gap-1 text-center darkColorBtn'>
                                    Kanban : {viewType === 'kanban_dueby' ? 'Due by' : viewType === 'kanban' ? 'Status' : 'Progress'} {showOptions ? <FaChevronUp className='' /> : <FaChevronDown className='' />}
                                </button>
                            }

                            {showOptions && (
                                <div ref={ref2}>
                                    <ul className="userActionPopup !w-40 !rounded-lg showAll checklistPopup kanbanList">
                                        <li className={`kanbanList ${viewType === 'kanban_dueby' && 'active'}`} style={{ borderRadius: '4px' }} onClick={() => { changeViewType('kanban_dueby'); setShowOptions(false) }}>Due by</li>
                                        <li className={`kanbanList ${viewType === 'kanban' && 'active'}`} style={{ borderRadius: '4px' }} onClick={() => { changeViewType('kanban'); setShowOptions(false) }}>Status</li>
                                        <li className={`kanbanList ${viewType === 'kanban_progress' && 'active'}`} style={{ borderRadius: '4px' }} onClick={() => { changeViewType('kanban_progress'); setShowOptions(false) }}>Progress</li>

                                    </ul>
                                </div>
                            )}
                        </div>
                    }

                    <Link to={"list-view"} onClick={() => changeViewType('list')} className={`${location.pathname === "/connect/tasks/list-view" || location.pathname === "/connect/" + params.conversation_id + "/tasks/list-view" ? '!bg-[#318FFF] !text-white' : '!bg-[#0c1e47] !text-white hover:!bg-[#318FFF] hover:!text-white'} createTaskButton !w-[90px] flex justify-center gap-1 items-center`}>
                        <p id="list-view" className=''>Task list</p>
                    </Link>

                    <Link to={"create-task"} className="!bg-[#0c1e47] hover:!bg-[#318FFF] hover:!text-white !text-white createTaskButton flex justify-center gap-1 items-center">
                        <p id='createaTask'>Create a task</p>
                        <IoMdAdd />
                    </Link>

                </div >
            </div >

            {tab_state === "kanban-view" && <KanbanHome />}
            {tab_state === "notifications" && <NotificationsTabHome />}
            {tab_state === "list-view" && <TaskListView />}
            {tab_state === "review" && <ReviewTabHome />}
            {params.task_id && <TaskPropertiesCon />}
            {/* {selectedTask && <TaskPropertiesCon />} */}
            {keywordFilterPopup && <KeywordFilter setKeywordFilterPopup={setKeywordFilterPopup} />}
            {assigneeFilterPopup && <AssigneeFilter setAssigneeFilterPopup={setAssigneeFilterPopup} />}
            {roomFilterPopup && <RoomFilter setRoomFilterPopup={setRoomFilterPopup} />}
            {observerFilterPopup && <ObserverFilter setObserverFilterPopup={setObserverFilterPopup} />}
            {projectFilterPopup && <ProjectFilter setProjectFilterPopup={setProjectFilterPopup} />}
            {dateFilterPopup && <DateRangeFilter setDateFilterPopup={setDateFilterPopup} />}
            {_redux.tag_popup && <TagPopup type='file' viewType={'all'} tag_list={[]} upload_type={'task'} from={trackCostPopup ? '#TASK-COST' : '#TASK'} />}
            <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#createaTask" place='top' float="true" content="Click to Create a task" />
            <Tooltip delayShow={1000} className="!z-999999" anchorSelect="#list-view" place='top' float="true" content="Click to view tasks list" />

            <Outlet />
        </div >
    );
};

const TaskManagementHome = ({ from = '' }) => {
    return (
        <TaskProvider>
            <TaskManagement from={from} />
        </TaskProvider>
    )
}

export default TaskManagementHome;