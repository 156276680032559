/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import InviteGuestList from './InviteGuestList';
import InviteNewUser from './InviteNewUser';

import { useApolloClient } from '@apollo/client';
import { GET_ME, GET_ALL_USERS } from '../../Utils/GraphqlQueries';
import { useNavigate } from 'react-router-dom';

const InviteRoomGuest = ({ type, setInviteGuestRoomPopup, seletedGuestuser, setSelectedguest }) => {
    const [isExist, setIsExist] = useState(false);
    const [addvalidation, setaddvalidation] = useState(false);
    const [inputData, setInputData] = useState({ id: new Date().toString(), img: "img.png", role: 'Guest', new: true, firstname: '', lastname: '', email: '', phone: '' });
    const [userData, setUserData] = useState([]);
    const [tagsTab, setTagsTab] = React.useState('inviteGuest');
    const [selectedUsers, setSelectedUsers] = useState(seletedGuestuser);
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const navigate = useNavigate();


    useEffect(() => {
        if (cacheAllUsers?.users) {
            setUserData(cacheAllUsers.users.filter(u => u.role === 'Guest'))
        }

    }, [cacheAllUsers])

    useEffect(() => {
        if (isExist) {
            setaddvalidation(false)
        } else {
            if (inputData.firstname !== '' && inputData.lastname !== '' && inputData.email !== '') {
                setaddvalidation(true)
            } else {
                setaddvalidation(false)
            }
        }

    }, [inputData])
    // Function to handle mouse hover
    const addGuest = () => {
        setTagsTab('inviteGuest');
        setSelectedUsers(prev => [inputData, ...prev]);
        setInputData({ id: new Date().toString(), img: "img.png", role: 'Guest', new: true, firstname: '', lastname: '', email: '', phone: '' })
        // onClose()
    }

    // const [popupCloseTypes, setPopupCloseTypes] = useState(false);

    const onClose = () => {
        setInviteGuestRoomPopup(false);
        // setPopupCloseTypes(true);
        // navigate(-1);
        switch (type) {
            case 'create_room':
                navigate(-1)
                break;
            case 'update_room':
                navigate(-1)
                break;
            default:
                break;
        }
    }

    const handleInvite = () => {
        setSelectedguest(selectedUsers);
        setInviteGuestRoomPopup(false)
        onClose()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    

    return (
        <div className='backwrap'>
            <div className='tagModal'>
                <div className="tagModalHead">
                    <h4 className="popupTitle">Invite guest(s) to this room</h4>
                    <span onClick={onClose} className="closeModal"></span>
                </div>
                <div className='tagModalBody'>
                    <ul className="tagTab">
                        <li className={`${tagsTab === 'inviteGuest' ? "active" : ""}`} onClick={() => setTagsTab('inviteGuest')}>Invite guest</li>
                        <span className="tooltip2 user_Management_title" style={{ marginLeft: '3px', cursor: 'pointer' }}> </span>
                        <li className={`${tagsTab === 'addInviteGuest' ? "active" : ""}`} onClick={() => setTagsTab('addInviteGuest')} style={{ marginLeft: '32px' }}>Add a new guest to Invite</li>
                        <span className="tooltip2 user_Management_title" style={{ marginLeft: '3px', cursor: 'pointer' }}> </span>
                    </ul>
                    {
                        tagsTab === 'inviteGuest' ?
                            <InviteGuestList selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} userData={userData} setUserData={setUserData} />
                            :
                            <InviteNewUser isExist={isExist} setIsExist={setIsExist} inputData={inputData} setInputData={setInputData} allusers={cacheAllUsers.users} />
                    }
                </div>
                <div className="tagModalFoot">
                    <button className="btnCancel" onClick={onClose}>Cancel</button>
                    {tagsTab === 'inviteGuest' ? selectedUsers.length > 0 ? <button className="btnAction hover:!bg-[#2196f3]" onClick={handleInvite}>Invite Guest</button> : <button className="btnAction" style={{ opacity: 0.5, pointerEvents: 'none' }}>Invite Guest</button> : ''}
                    {tagsTab === 'addInviteGuest' ? addvalidation ? <button className="btnAction hover:!bg-[#2196f3]" onClick={addGuest}>Add Guest</button> : <button className="btnAction" style={{ opacity: 0.5, pointerEvents: 'none' }}>Add Guest</button> : ''}
                </div>
            </div>
        </div>
    );
};

export default InviteRoomGuest;
