import React, { useState, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import sortArrayByKey from "../../../hooks/useSortedArray";

const CreateTaskProject = ({ setValue, task }) => {
  const [inputValue, setInputValue] = useState("");
  const [showProjectPopup, setshowProjectPopup] = useState(false);
  const all_projects = useSelector((state) => state.task.all_projects);
  const ref = useRef(null);

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  // Clear search input
  const clearSearch = () => {
    setInputValue("");
  };

  // Handle creating a new title
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && inputValue.trim() !== "") {
  //     const newTitle = { id: Date.now(), name: inputValue };
  //     setProjectTitles((prevTitles) => [...prevTitles, newTitle]);
  //     setselectedProjectTitle(newTitle);
  //     setshowProjectPopup(false);
  //     setInputValue("");
  //     toast.success("Project title created successfully");
  //   }
  // };
  const handleKeyDown = (e) => {
    // if (e.key === "Enter" && inputValue.trim() !== "") {
    //   // Check if the project title already exists (case insensitive)
    //   const isDuplicate = all_projects?.some(p => p.project_title?.toLowerCase() === inputValue.trim().toLowerCase());

    //   if (isDuplicate) {
    //     toast.error("This project title already exists!");
    //     return;
    //   }

    //   const newTitle = { id: Date.now(), name: inputValue.trim() };
    //   setProjectTitles(prev => (Array.isArray(prev) ? [...prev, newTitle] : [newTitle]));
    //   setselectedProjectTitle(newTitle);
    //   setshowProjectPopup(false);
    //   setInputValue("");
    //   toast.success("Project title created successfully");
    // }
  };
  // Filter titles based on search input
  const filteredTitles = all_projects.filter(f => f._id !== 'all' && f.project_title.toLowerCase().includes(inputValue.toLowerCase()));
  const sorted_data = sortArrayByKey(filteredTitles, 'project_title', 'asc');
  // Handle title selection
  const [selected_project, set_selected_project] = useState('')
  const handleTitleSelect = (d) => {
    // setselectedProjectTitle(v.project_title);
    set_selected_project(d.project_title)
    setValue(prev => prev.map(v => v.temp_key === task.temp_key ? { ...v, project_id: d._id } : v));
    setshowProjectPopup(false);
  };

  return (
    <>
      {!showProjectPopup ? (
        <div className='flex gap-4 text-[#0079d1] cursor-pointer contentHover' onClick={() => setshowProjectPopup(true)}>
          <p className='text-sm'>Project: {selected_project || "Select a project"}</p>
          <div className='editImage' id='updateProjectTitle'></div>
        </div>
      ) : (
        <OutsideClickHandler onOutsideClick={() => setshowProjectPopup(false)}>
          <div className='relative' ref={ref}>
            <div className="border h-[28px] w-[240px] border-gray-300 flex justify-between rounded-[5px] items-center">
              <div className="userDropDownArea pl-[10px]">
                <div className="ellipsis text-sm cursor-pointer">
                  <span className="selectOne">{selected_project || "Select a Project"}</span>
                </div>
              </div>
              <div className="dropdown" style={{ backgroundColor: 'transparent' }}>
                <div className="dropdown-header">
                  <i className="fa fa-chevron-right icon open" style={{ color: 'black' }}></i>
                </div>
              </div>
            </div>
            <div className="AddKeywordsPopup_task_settings bg-white border border-gray-300 !mt-0 !top-[28px] !w-[240px]">
              <div className="searchAndFilterBar">
                <div className="selectedProjectTitleCont !pl-2 !pr-1">
                 {selected_project && <span className="tags_Color roomColor !mr-1 !mb-1 rounded-[4px] !bg-[#732be2] !text-white !px-1 py-[1px] text-xs h-[23px] flex items-center !w-max !mt-[7px]"> {selected_project ? `Project: ${selected_project}` : ''}</span>}
                </div>
                <div className="searchareaDiv">
                  <div className='p-2'>
                    <input className="_inputBar searchLeft" type="text" placeholder="Search a project" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} autoFocus />

                    {inputValue !== '' && <span className='clearInput inv' style={{ top: '30px', right: '-8px' }} onClick={clearSearch}></span>}
                  </div>
                  <div className="keyword_list_task_settings workloadUsers">
                    {sorted_data.map((v) => (
                      <p key={v._id} className="_tag_rooms cursor-pointer" onClick={() => handleTitleSelect(v)}>
                        {v.project_title}
                      </p>
                    ))}
                    {sorted_data.length === 0 && <p className="text-center border-b border-b-[#e3f2ff] text-sm">No project found</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {!showProjectPopup && (
        <Tooltip anchorSelect="#updateProjectTitle" place='top' content="Click to update project title"></Tooltip>
      )}
    </>
  );
};

export default CreateTaskProject;
