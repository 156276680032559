import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_ROOM_MUTATION } from '../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_ALL_USERS, GET_ME } from '../../Utils/GraphqlQueries';

const UserMiniProfile = () => {
  const Navigate = useNavigate();
  const location = useLocation()
  const [createDirect] = useMutation(CREATE_ROOM_MUTATION);
  const client = useApolloClient();
  const { me } = client.readQuery({ query: GET_ME });
  const { users } = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: me.company_id } });
  const [user_data, set_user_data] = useState(null)

  useEffect(() => {
    if (location.hash) {
      if (location.hash.includes('#mention_')) {
        const _id = location.hash.split('_')?.[location.hash.split('_').length - 1]
        console.log("🚀 ~ useEffect ~ _id:", _id)
        const user = users.filter(u => u.id === _id)?.[0]
        console.log("🚀 ~ useEffect ~ user:", user)
        set_user_data(user)
      }
    }
  }, [location, users])

  const createDirectConversation = async () => {
    console.log("createDirect", createDirect)
    try {
      let res = await createDirect(
        {
          variables: {
            input: {
              title: 'Personal',
              participants: [me.id, user_data?.id],
              company_id: me.company_id,
              group: 'no'
            }
          }
        }
      );
      console.log("res.data.create_room.data.conversation_id", res)
      Navigate('/connect/' + res.data.create_room.data.conversation_id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        Navigate(-1)
      }
    });
  }, [Navigate]);

  return (
    <div className="backwrap">
      <div className="userMiniModal">
        <div className="userMiniModalHead">
          <h4 className="popupTitle">Profile</h4>
          <span onClick={() => Navigate(-1)} className="closeModal" data-for="rightSection_tooltip" data-tip="Close" currentitem="false"></span>
        </div>
        <div className="userMiniModalBody">
          <div className="userImageSection">
            <img src={user_data?.img} alt="userImage" title='userImage' />
          </div>
          <div className="userInfoSection">
            <div className="userNameSection">
              <p className="userNameTitle">{user_data?.firstname + ' ' + user_data?.lastname}</p>
            </div>
            <div className="userEmailSection">{user_data?.email}</div>
            <div className="userActionSection" data-active="true">
              <span className="voiceToUser"></span>
              <span onClick={createDirectConversation} title='View Profile' className="msgToUser"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMiniProfile;