/* eslint-disable react-hooks/exhaustive-deps */
import { GET_ME, GET_ALL_USERS } from '../../Utils/GraphqlQueries';
import { useApolloClient, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CREATE_ROOM_MUTATION, FORWARD_MSG_MUTATION, FORWARD_FILE_MSG_MUTATION, EDIT_PARTICIPANTS_UPDATE } from '../../Utils/GraphqlMutaion';
import { useDispatch, useSelector } from 'react-redux';
import { setPopup, set_global_action_data } from '../../redux/message';
import { toast } from 'react-hot-toast';
import ForwardMessageTo from '../Connect/Messages/ForwardMessageTo';
import { CREATE_UPDATE_TEAM } from '../../Utils/GraphqlMutaion';

export const UserSelection = ({ type, miniList, setMiniList, ...otherprops }) => {
    const conversations = useSelector(state => state.message.conversations)
    const OnlineUsers = useSelector((state) => state.message.online_users);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const params = useParams();
    const client = useApolloClient();
    const { me } = client.readQuery({ query: GET_ME });
    const { users } = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: me.company_id } });
    const [src_string, setSrc_string] = useState('');
    const [selected_ids, setSelected_ids] = useState([]);
    const [loader] = useState(false);
    const [btnloader] = useState(false);
    const [updatePrivateParticipantsMethod] = useMutation(EDIT_PARTICIPANTS_UPDATE);
    const updatePrivateParti = () => {
        let old = otherprops.global_action_data.msg.secret_user
        let a_ids = selected_ids?.filter((i) => old.indexOf(i) === -1);
        let r_ids = old.filter((i) => selected_ids?.indexOf(i) === -1);
        updatePrivateParticipantsMethod({
            variables: {
                input: {
                    conversation_id: otherprops.global_action_data.msg.conversation_id,
                    msg_id: otherprops.global_action_data.msg.msg_id,
                    add_secret_user: a_ids,
                    remove_secret_user: r_ids
                }
            }
        });

        dispatch(setPopup({ data: false, key: 'private_msg' }));
    }
    useEffect(() => {
        // let isMounted = true
        function initial() {
            switch (type) {
                case 'private_msg':
                    setSelected_ids(miniList.map(v => v.id))
                    break;
                case 'private_msg_update':
                    setSelected_ids(otherprops.global_action_data.msg.secret_user);
                    let u = all_users.filter(v => otherprops.global_action_data.msg.secret_user.indexOf(v.id) > -1 && me.id !== v.id)
                    setMiniList(u)
                    break;
                case 'create_room':
                    setSelected_ids(miniList.map(v => v.id))
                    break;
                case 'update_room':
                    setSelected_ids(miniList.map(v => v.id))
                    break;
                case 'manage_restricted': {
                    setSelected_ids(otherprops?.team?.team_system_conversation_off_sms)
                    setMiniList(users.filter(u => otherprops?.team?.team_system_conversation_off_sms?.indexOf(u.id) > -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname })))
                    break;
                }
                default:
                    break;
            }

        }
        initial();
    }, [])

    const close = () => {
        switch (type) {
            case 'private_msg':
                dispatch(set_global_action_data(null))
                dispatch(setPopup({ data: false, key: 'private_msg' }));
                break;
            case 'private_msg_update':
                dispatch(set_global_action_data(null))
                dispatch(setPopup({ data: false, key: 'private_msg' }));
                break;
            case 'create_room':
                Navigate(-1)
                break;
            case 'update_room':
                Navigate(-1)
                break;
            case 'direct_conv':
                otherprops.setStartDiscussion(false)
                break;
            case 'forward_msg':
                setMiniList([]);
                dispatch(setPopup({ data: false, key: 'forward_msg_popup' }));
                break;
            case 'team_member_add':
                setMiniList([]);
                otherprops.setAddMember(false);
                break;
            case 'manage_restricted':
                setMiniList([]);
                otherprops.setRestrictUser(false);
                break;
            default:
                break;
        }
    }

    const urlId = params.conversation_id

    const returnAllusers = () => {
        switch (type) {
            case 'private_msg':
                return users.filter(f => f.id !== me.id).filter(u => otherprops.ThisRoom.participants.indexOf(u.id) > -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'private_msg_update':
                return users.filter(f => f.id !== me.id).filter(u => otherprops.ThisRoom.participants.indexOf(u.id) > -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'create_room':
                return users.filter(f => f.id !== me.id && f.role !== 'Guest').filter(u => otherprops.participants.indexOf(u.id) > -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'update_room':
                return users.filter(f => f.id !== me.id && f.role !== 'Guest').filter(u => otherprops.participants.indexOf(u.id) > -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'direct_conv':
                return users.filter(f => f.id !== me.id).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'forward_msg':
                return conversations.filter(f => f.conversation_id !== me.id).map(v => ({ ...v, display_title: v.title, id: v.conversation_id, img: v.conv_img, fnln: v.title.slice(0, 1) })).filter(u => u.conversation_id !== urlId);
            case 'team_member_add':
                return users.filter(f => f.id !== me.id).filter(u => otherprops.team_users.indexOf(u.id) === -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            case 'manage_restricted':
                return users.filter(f => f.id !== me.id).filter(u => otherprops.team_users.indexOf(u.id) > -1 && otherprops?.team?.admin?.indexOf(u.id) === -1).map(v => ({ ...v, display_title: v.firstname + ' ' + v.lastname }));
            default:
                return [];
        }
    }

    // const all_users = returnAllusers();
    const all_users = useMemo(() => returnAllusers(), [type, me, otherprops]);

    const removeFromMini = (item) => {
        setMiniList(miniList.filter(f => f.id !== item.id))
        setSelected_ids(selected_ids?.filter(f => f !== item.id))
    }
    const select_user = (user) => {
        if (type === 'direct_conv') {
            setMiniList([user]);
            setSelected_ids([user.id])
        } else {
            // setMiniList([...miniList, user]);
            setSelected_ids([...selected_ids, user.id])
        }
        setSrc_string('')
    }

    // const minidiv = miniList.map((item, i) =>
    const minidiv = all_users.filter(f => selected_ids?.indexOf(f.id) > -1).map((item, i) =>
        <div className={'miniItem'} key={i}>
            <div className="miniItem_image">
                <img src={type === 'forward_msg' ? item.conv_img : item.img} alt="img" title='img' />
            </div>
            <div className={'miniItem_name'}>{item.display_title}</div>
            <div className={'miniItem_remove'} onClick={() => removeFromMini(item)}></div>
        </div>
    )

    const global_action_data = useSelector(state => state.message.global_action_data)
    const [forward] = useMutation(FORWARD_MSG_MUTATION)
    const [forwardFile] = useMutation(FORWARD_FILE_MSG_MUTATION)

    const forwardAmessageMethod = async (msg_body = '') => {
        // debugger;
        let { msg, forward_type, file, multi_file, multi_file_ids } = global_action_data;
        try {
            let input = {
                conversation_lists: selected_ids
            }

            if (forward_type === 'file') {
                if (multi_file) {
                    input['file_ids'] = multi_file_ids
                } else {
                    input['file_ids'] = [file.id]
                }

                input['msg_body'] = msg_body
                forwardFile({
                    variables: {
                        input: input
                    }
                });
            } else {
                input['conversation_id'] = msg.conversation_id
                input['msg_id'] = msg.msg_id
                input['is_reply_msg'] = msg.is_reply_msg
                forward({
                    variables: {
                        input: input
                    }
                });
            }

            toast.success('Message forwarded!')
            dispatch(setPopup({ data: false, key: 'forward_msg_popup' }))
        } catch (error) {
            console.log(error)
        }
    }
    const [createDirect] = useMutation(CREATE_ROOM_MUTATION);
    const [updateTeam] = useMutation(CREATE_UPDATE_TEAM);
    const createDirectConversation = async () => {
        try {
            let res = await createDirect(
                {
                    variables: {
                        input: {
                            title: 'Personal',
                            participants: [me.id, ...selected_ids],
                            company_id: me.company_id,
                            group: 'no'
                        }
                    }
                }
            );
            otherprops.setStartDiscussion(false)
            Navigate('/connect/' + res.data.create_room.data.conversation_id)
        } catch (error) {
            console.log(error)
        }
    }

    const SubmitAddTeamMember = async () => {
        try {
            await updateTeam(
                {
                    variables: {
                        input: {
                            title: otherprops.team?.team_title,
                            type: 'update_conv_also',
                            participants: [...otherprops.team?.participants, ...selected_ids],
                            team_id: otherprops.team.team_id
                        }
                    }
                }
            );

            toast.success('User updated successfully');
            otherprops.setTeam_users(prev => [...prev, ...all_users.filter(u => selected_ids?.indexOf(u.id) > -1)]);

            close();
        } catch (error) {
            console.error(error);
            toast.error('Failed to update user');
        }
    }

    const [forward_extra, setforward_extra] = useState(false);
    const submit = () => {
        switch (type) {
            case 'private_msg':
                setMiniList(all_users.filter(f => selected_ids?.indexOf(f.id) > -1));
                otherprops.setStep(2);
                break;
            case 'private_msg_update':
                updatePrivateParti()
                break;
            case 'create_room':
                setMiniList(all_users.filter(f => selected_ids?.indexOf(f.id) > -1));
                Navigate(-1)
                break;
            case 'update_room':
                setMiniList(all_users.filter(f => selected_ids?.indexOf(f.id) > -1));
                Navigate(-1)
                break;
            case 'direct_conv':
                createDirectConversation();
                break;
            case 'team_member_add':
                SubmitAddTeamMember();
                break;
            case 'manage_restricted':
                // otherprops.manage_restricted(miniList.map(v => v.id));
                otherprops.manage_restricted(selected_ids);
                break;
            case 'forward_msg':
                if (global_action_data.forward_type === 'file') {
                    setforward_extra(true)
                } else {
                    forwardAmessageMethod()
                    setMiniList([]);
                }
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                dispatch(setPopup({ data: false, key: 'forward_msg_popup' }));
                close();

            }
            // else if (event.key === 'Escape' && dispatch(setPopup({ data: true, key: 'private_msg' }))) {
            //     close();
            // }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const sortByName = (data) => {
        if (!Array.isArray(data)) {
            throw new TypeError('Input must be an array');
        }

        return data.sort((a, b) =>
            a.display_title.localeCompare(b.display_title, undefined, { sensitivity: 'base' })
        );
    };

    const isOnline = (v) => {
        if (type === 'forward_msg') {
            if (v.group === 'no') {
                let u = v.participants.filter(f => f !== me.id)[0]
                return OnlineUsers?.includes(u)
            }
        } else {
            return OnlineUsers?.includes(v.id)
        }
    }

    return (
        <>
            {
                forward_extra ?
                    <ForwardMessageTo forwardAmessageMethod={forwardAmessageMethod} next={selected_ids?.length > 0 ? true : false} minidiv={minidiv} close={close} setforward_extra={setforward_extra} />
                    :
                    <div className="backwrap" style={{ display: 'flex' }}>
                        <div className="participantsModal">
                            <div className="participantsModalHead">
                                <h4 className="popupTitle">
                                    {type === 'direct_conv' ? 'Select a teammate for a direct message' :
                                        (type === 'private_msg' || type === 'private_msg_edit' || type === 'private_msg_update') ? 'Select member(s) to start a private message' :
                                            type === 'forward_msg' ? 'Select member(s) to forward' :
                                                'Add members'}
                                </h4>
                                <span className="closeModal" onClick={close}></span>
                            </div>
                            <div className="participantsModalBody !pl-[32px]">
                                <div className="searchParticipants !mb-0">
                                    <span style={{ width: 'calc(100% - 130px)', position: 'relative', float: 'left' }}>
                                        <input
                                            type="text"
                                            className="participantsSearch"
                                            placeholder="Search user"
                                            style={{ width: '100%' }}
                                            value={src_string}
                                            onChange={(e) => setSrc_string(e.target.value)}
                                            autoFocus={true}
                                        />
                                        <span className="leftSearchCloseBtn remove" style={{ top: '14px', right: '10px' }} onClick={() => setSrc_string('')}></span>
                                    </span>
                                    <button onClick={submit} className={`paricipantsSubmit !border-0 !bg-[#0D1E45] hover:!bg-[#0069d9] ${selected_ids?.length > 0 ? 'active' : 'inactive'}`}>
                                        {btnloader ? 'Loading...' :
                                            type === 'direct_conv' || type === 'private_msg' || type === 'private_msg_edit' ? 'Start' :
                                                type === 'forward_msg' ? 'Forward' : 'Submit'}
                                    </button>
                                    <div className="selectedParticipants">
                                        {minidiv}
                                    </div>
                                </div>
                                <div className="participantsContainer fgbh">
                                    {loader ? 'Loading..' :
                                        all_users.filter(f => f.display_title.toLowerCase().trim().indexOf(src_string.toLowerCase().trim()) > -1).length > 0 ?
                                            sortByName(all_users)?.filter(f => selected_ids?.indexOf(f.id) === -1 && f.display_title.toLowerCase().trim().indexOf(src_string.toLowerCase().trim()) > -1)
                                                .map((v, i) => (
                                                    <div key={'user_' + i} className="participantItem active" onClick={() => select_user(v)}>
                                                        <div className={`participantImage ${isOnline(v) ? "online" : "offline"} ${(v.group && v.group === 'yes') && 'group'}`}>

                                                            {/* {v.img === "" ? <h3 className="nameLetters">{v.display_title.slice(0, 1).toUpperCase()}</h3> :
                                                                <img src={type === 'forward_msg' ? v.conv_img : v.img} alt="img" title='img' />} */}

                                                            {v.img?.includes('img.png') || v.img?.includes('feelix.jpg') ?
                                                                <h3 className="nameLetters">{v.fnln.toUpperCase()}</h3>
                                                                :
                                                                <img src={v.img} alt="user" title="user" />
                                                            }

                                                        </div>
                                                        <p className="participantTitle">{v.display_title}</p>
                                                        <p className="participantEmail">{v.email}</p>
                                                    </div>
                                                )) :
                                            <div className={'_not_found'}>No data found!</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}