import React from 'react'
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import ReactTooltip from 'react-tooltip';

function PlayerPopup(props) {
    // console.log ( 'PlayerPopup' , props);

    const close_popup = () => {
        props.setVideo_data(null)
    }

    return (
        <>
            <div className="backwrap">
                <div className="playerContainer">
                    <div className="playerHeader">
                        <div style={{fontSize:'18px', fontWeight: '600'}}>Player</div>
                        <span className="closePopup" onClick={()=> {
                            // ReactTooltip.hide(); 
                            close_popup()
                        }} 
                        data-for="rightSection_tooltip" data-tip="Close">
                        </span>
                    </div>
                    <div className="playerBody">
                    <ReactPlayer 
                    onReady={() => console.log('')}
                    playing={true}
                    url={props.video_data.location}
                    width="100%"
                    // height="390px" 
                    height="100%"
                    controls={true}
                    />
                    </div>
                </div>
            </div>
        </>
    )
}

// popup_video

// const mapStateToProps = (state) => {
//     return {
//         popup_video:state.popupReducer.popup_video

//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setPopup:(data)=>{
//             dispatch({type:'setPopup',payload:data})
//         }
//     }
// }
export default PlayerPopup;