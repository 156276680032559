/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ALL_USERS, GET_ME } from '../../../Utils/GraphqlQueries';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import AdminWarningPopup from '../AdminWarningPopup';
import ChangePassword from '../../Connect/ChangePassword';
import Moment from 'react-moment';
import UpdateRolePopup from './UpdateRolePopup';
import UserRolePage from './UserRolePage';
import { Toaster } from 'react-hot-toast';
import InviteNewUser from './InviteNewUser';
import UserManagementHeader from './UserMangementHeader';
import Scrollbars from 'react-custom-scrollbars';

function UserManagementHome() {
    const [userTab, setUserTab] = useState('only_user');

    const [usersOptions, setUsersOptions] = useState(false);
    const [guestsOptions, setGuestsOptions] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editPopup, setEditPopup] = useState(false);

    const [selectedUsersOptions, setSelectedUsersOptions] = useState('all');
    const [filterText, setFilterText] = useState('');

    const handleSelectedOptions = (e) => {
        if (e === 'all') {
            setSelectedUsersOptions(e);
        } else {
            setSelectedUsersOptions(e);
        }
        setUsersOptions(false);
        setGuestsOptions(false);
    };

    const ref = useClickAway(() => {
        setUsersOptions(false)
    });
    const ref2 = useClickAway(() => {
        setGuestsOptions(false)
    });

    const [user_data, setUser_data] = useState(null);
    const [all_user_data, setall_user_data] = useState([]);

    useEffect(() => {
        // console.log("🚀 ~ useEffect ~ all_user_data:", all_user_data);

    }, [all_user_data])


    const { loading: initialLoading, error: initialError, data: user } = useQuery(GET_ME);
    useEffect(() => {
        setUser_data(user.me)
    }, [user])

    const [fetchUsers, { loading: UsersLoading, error: UsersError, data: AllUsers }] = useLazyQuery(GET_ALL_USERS);

    useEffect(() => {
        if (AllUsers?.users) {
            if (selectedUsersOptions !== 'all') {
                if (selectedUsersOptions === 'admin') {
                    setFilterText('Filter Admin Users only')
                    setall_user_data(AllUsers.users.filter(v => v.role === 'Admin'))
                } else if (selectedUsersOptions === 'member') {
                    setFilterText('Filter Member Users only')
                    setall_user_data(AllUsers.users.filter(v => v.role === 'Member'))
                } else if (selectedUsersOptions === 'active') {
                    setFilterText('Filter Active Users only')
                    setall_user_data(AllUsers.users.filter(v => v.is_active === 1))
                } else if (selectedUsersOptions === 'inactive') {
                    setFilterText('Filter Inactive Users only')
                    setall_user_data(AllUsers.users.filter(v => v.is_active === 0))
                }
            } else {
                setall_user_data(AllUsers.users)
            }
        }
    }, [selectedUsersOptions])

    useEffect(() => {
        if (AllUsers?.users) {
            setall_user_data(AllUsers.users)
        }
    }, [AllUsers])

    useEffect(() => {
        if (user_data) {
            fetchUsers({ variables: { company_id: user_data.company_id } });
        }
    }, [user_data])

    // const [filteredUsers, setFilteredUsers] = useState(all_user_data);
    // const [filteredGuests, setFilteredGuests] = useState(all_user_data.filter(v => v.role === 'Guest'));
    // const [filterContact, setFilterContact] = useState(all_user_data.filter(v => v.role === 'Recipient'));

    // console.log("🚀 ~ file: UserManagementHome.js:UserManagementHome ~ filteredUsers:", filteredGuests)

    // useEffect(() => {
    //     setFilteredUsers(all_user_data);
    // }, [all_user_data]);
    // useEffect(() => {
    //     setFilteredGuests(all_user_data.filter(v => v.role === 'Guest'));
    // }, [all_user_data]);
    // useEffect(() => {
    //     setFilterContact(all_user_data.filter(v => v.role === 'Recipient'));
    // }, [all_user_data]);

    const [filteredUsers, setFilteredUsers] = useState(
        [...all_user_data].sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
    );

    const [filteredGuests, setFilteredGuests] = useState(
        [...all_user_data]
            .filter(v => v.role === 'Guest')
            .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
    );

    const [filterContact, setFilterContact] = useState(
        [...all_user_data]
            .filter(v => v.role === 'Recipient')
            .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
    );

    useEffect(() => {
        setFilteredUsers([...all_user_data].sort((a, b) => new Date(b.createdat) - new Date(a.createdat)));
    }, [all_user_data]);

    useEffect(() => {
        setFilteredGuests(
            [...all_user_data]
                .filter(v => v.role === 'Guest')
                .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
        );
    }, [all_user_data]);

    useEffect(() => {
        setFilterContact(
            [...all_user_data]
                .filter(v => v.role === 'Recipient')
                .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
        );
    }, [all_user_data]);

    const [sortOrder, setSortOrder] = useState('asc');

    const [sortRedActive, setSortRedActive] = useState(false);
    const [sortIconName, setSortIconName] = useState(false);
    const [sortIconEmail, setSortIconEmail] = useState(false);
    const [sortIconAddedOn, setSortIconAddedOn] = useState(false);
    const [sortRedbyDefault, setSortRedByDefault] = useState(true);

    const handleSortList = (type) => {
        const sortByField = {
            'byName': (item) => `${item.firstname} ${item.lastname}`.toLowerCase(),
            'byEmail': (item) => item.email.toLowerCase(),
            'byAddedOn': (item) => item.createdat,
        };

        // console.log(sortByField.byAddedOn)

        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setSortRedActive(type);
        setSortRedByDefault(false);

        const sortField = sortByField[type];

        const listToSort = userTab === 'only_user' ? filteredUsers :
            userTab === 'guest' ? filteredGuests :
                userTab === 'contacts' ? filterContact : [];

        const sortedList = [...listToSort].sort((a, b) => {
            const aField = sortField(a);
            const bField = sortField(b);
            return sortOrder === 'asc' ? aField.localeCompare(bField) : bField.localeCompare(aField);
        });

        if (userTab === 'only_user') {
            setFilteredUsers(sortedList);
        } else if (userTab === 'guest') {
            setFilteredGuests(sortedList);
        } else if (userTab === 'contacts') {
            setFilterContact(sortedList);
        }

        if (type === 'byName') setSortIconName(!sortIconName);
        if (type === 'byEmail') setSortIconEmail(!sortIconEmail);
        if (type === 'byAddedOn') setSortIconAddedOn(!sortIconAddedOn);
    };


    const [selectedEditUser, setSelectedEditUser] = useState();


    const handleEditPopup = (item) => {
        setSelectedEditUser(item);
        setEditPopup(true);
    }

    return (
        <>
            <Toaster />
            <div className='right_container '>
                <UserManagementHeader
                    filteredUsers={filteredUsers}
                    setFilteredUsers={setFilteredUsers}
                    all_user_data={all_user_data}
                    selectedUsersOptions={selectedUsersOptions}
                    handleSelectedOptions={handleSelectedOptions}
                    userTab={userTab}
                    setUserTab={setUserTab}
                    setGuestsOptions={setGuestsOptions}
                    setUsersOptions={setUsersOptions}
                    setall_user_data={setall_user_data}
                    filteredGuests={filteredGuests}
                    setFilteredGuests={setFilteredGuests}
                    filterText={filterText}
                    setFilterText={setFilterText}
                    setFilterContact={setFilterContact}
                    filterContact={filterContact}
                />

                <div className='usersTableContainer '>
                    <div className="userTableHead">
                        <ul className="">

                            <li className={`_userName ${sortRedActive === "byName" ? 'sort_active' : ''} ${!sortIconName ? 'asce' : 'desc'}`} style={{ width: "20%" }}> <span onClick={() => handleSortList("byName")} className="sortIcons"></span>Name</li>

                            <li className={`_userEmail ${sortRedActive === "byEmail" ? 'sort_active' : ''} ${!sortIconEmail ? 'asce' : 'desc'}`} style={{ width: "25%" }}><span onClick={() => handleSortList("byEmail")} className="sortIcons"></span>{userTab === 'contacts' ? 'Contact email' : 'Login email'}</li>
                            {
                                userTab === 'contacts' ?
                                    <li className="_userRole" style={{ width: "15%" }} >Phone</li> :
                                    <li className="_userRole" style={{ width: "7%" }} >Role</li>
                            }
                            <li className={`_userAddedOn ${sortRedbyDefault && 'sort_active'} ${sortRedActive === "byAddedOn" ? 'sort_active' : ''} ${!sortIconAddedOn ? 'asce' : 'desc'}`} style={userTab === 'contacts' ? { width: "15%" } : { width: "13%" }}><span onClick={() => handleSortList("byAddedOn")} className="sortIcons"></span> Added on</li>

                            <li className="_userStatus" style={{ width: "19%" }}>Added by</li>
                            {
                                userTab === 'contacts' ? "" : <li className="_userStatus" style={{ width: "10%" }}>Status</li>
                            }
                            <li className="_userAction" style={{ width: "5%" }}>Action</li>
                        </ul>
                    </div>
                    <div className='userTableBody'>
                        <Scrollbars autoHide className="scrollBarClass">
                            {
                                userTab === 'only_user' ? <>
                                    {filteredUsers
                                        .filter(f =>
                                            userTab === 'only_user'
                                                ? f.role !== 'Public User' && f.role !== 'Guest' && f.role !== 'Recipient'
                                                : userTab === 'guest'
                                                    ? f.role === 'Guest' && f.role !== 'Public User'
                                                    : userTab === 'contacts'
                                                        ? f.role === 'Recipient' && f.role !== 'Public User'
                                                        : ""
                                        )
                                        .map((v, i) =>
                                            <UserList v={v}
                                                allusers={AllUsers.users}
                                                key={'file_' + i}
                                                setall_user_data={setall_user_data}
                                                setSelectedUser={setSelectedUser}
                                                setEditPopup={setEditPopup}
                                                handleEditPopup={handleEditPopup}
                                                userTab={userTab} />
                                        )}
                                    {
                                        filteredUsers.length === 0 &&
                                        <div className="noDataFound flex justify-center items-center mt-48 m-auto">No data found</div>
                                    }
                                </> :
                                    userTab === 'guest' ? <>
                                        {filteredGuests.map((v, i) =>
                                            <UserList v={v}
                                                allusers={AllUsers.users}
                                                key={'file_' + i}
                                                setall_user_data={setall_user_data}
                                                setSelectedUser={setSelectedUser}
                                                setEditPopup={setEditPopup}
                                                handleEditPopup={handleEditPopup}
                                                userTab={userTab} />
                                        )}

                                        {
                                            filteredGuests.length === 0 &&
                                            <div className="noDataFound flex justify-center items-center mt-48 m-auto">No data found</div>
                                        }
                                    </> :
                                        userTab === 'contacts' ? <>
                                            {filterContact.map((v, i) =>
                                                <UserList v={v}
                                                    allusers={AllUsers.users}
                                                    key={'file_' + i}
                                                    setall_user_data={setall_user_data}
                                                    setSelectedUser={setSelectedUser}
                                                    setEditPopup={setEditPopup}
                                                    handleEditPopup={handleEditPopup}
                                                    userTab={userTab} />
                                            )}

                                            {
                                                filterContact.length === 0 &&
                                                <div className="noDataFound flex justify-center items-center mt-48 m-auto">No data found</div>
                                            }
                                        </> : ""
                            }
                        </Scrollbars>
                    </div>
                </div>
            </div>
            {
                usersOptions &&
                <div ref={ref}>
                    <ul className="userActionPopup showAll absolute" style={{ top: "150px", left: "380px" }}>
                        <li className="" onClick={() => handleSelectedOptions("admin")}>Admins</li>
                        <li className="" onClick={() => handleSelectedOptions("member")}>Members</li>
                        <li className="" onClick={() => handleSelectedOptions("active")}>Active</li>
                        <li className="" onClick={() => handleSelectedOptions("inactive")}>Inactive</li>
                        <li className="active" onClick={() => handleSelectedOptions("all")}>Show All</li>
                    </ul>
                </div>
            }
            {
                guestsOptions &&
                <div ref={ref2}>
                    <div>
                        <ul className="userActionPopup active absolute" style={{ top: "150px", left: "500px" }}>
                            <li className="active" onClick={() => handleSelectedOptions("active")}>Active</li>
                            <li className="" onClick={() => handleSelectedOptions("inactive")}>Inactive</li>
                            <li className="" onClick={() => handleSelectedOptions("all")}>Show All</li>
                        </ul>
                    </div>
                </div>
            }
            {
                editPopup && <InviteNewUser selectedEditUser={selectedEditUser}
                    setall_user_data={setall_user_data} all_user_data={all_user_data} onClose={() => setEditPopup(false)} popup_type={'editPopup'} />
            } 
            {
                selectedUser && <UserRolePage user={selectedUser} setSelectedUser={setSelectedUser} />
            }
        </>
    );
}

const UserList = ({ v, allusers, setall_user_data, setSelectedUser, userTab, handleEditPopup }) => {

    const [gustsSettingsOptions, setGustsSettingsOptions] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [activeUser, setActiveUser] = useState(false);
    const [makeAdmin, setMakeAdmin] = useState(false);
    const [activeEod, setActiveEod] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [updateRolePopup, setUpdateRolePopup] = useState(false);
    const handleSettingsOptions = (id) => {
        setGustsSettingsOptions(gustsSettingsOptions === id ? null : id);
    }

    const ref = useClickAway(() => {
        setGustsSettingsOptions(false)
    });

    return (
        <>
            <ul className="_userList">
                <li className="_userName" style={{ width: "20%" }} onClick={() => setSelectedUser(v)}>
                    <span className="_userImgage">
                        <span className="nameLetters">{v.firstname.slice(0, 1)}</span>
                    </span>
                    <span className={`_userNameTxt ${v.is_active === 0 && "!text-[#8f8f8f]"}`}>{`${v.firstname} ${v.lastname}`}</span>
                </li>
                <li className="_userEmail" style={{ width: "25%" }}>
                    <span className={`userEmailText ${v.is_active === 0 && "!text-[#8f8f8f]"}`} title={v.email}>{v.email}</span>
                </li>
                {
                    userTab === 'contacts' ?
                        <li className="_userRole" style={{ width: "15%" }}>
                            <span> {v.phone.length ? v.phone : <span className={' italic text-[#a9a9a9] text-sm'}>Not define</span>} </span>
                        </li> :
                        <li className="_userRole" style={{ width: "7%" }}>
                            <span className={`${v.is_active === 0 && "!text-[#8f8f8f]"}`}> {v.role} </span>
                        </li>
                }
                <li className="_userStatus ml-2" style={userTab === 'contacts' ? { width: "15%" } : { width: "13%" }}>
                    <Moment className={`userDateText ${v.is_active === 0 && "!text-[#8f8f8f]"}`} format="MMM Do, YYYY">{v.createdat}</Moment>
                </li>
                <li className="_userStatus" style={{ width: "19%" }}>
                    <span className={`_userNameTxt ${v.is_active === 0 && "!text-[#8f8f8f]"}`}>{getONLYUSERSDATA(allusers, v.created_by, 'name')}</span>
                </li>
                {
                    userTab === 'contacts' ? "" : <li className={`_userStatus ${v.is_active === 0 && "!text-[#8f8f8f]"}`} style={{ width: "10%" }}>{v.login_total === 0 ? 'Progress' : v.is_active === 1 ? 'Active' : 'Deactivated'}</li>
                }
                <li onClick={() => handleSettingsOptions(v.id)} className="_userAction" style={{ width: "5%", position: "relative", pointerEvents: gustsSettingsOptions === v.id ? "none" : "" }}>
                    <span className="userActionIcon"></span>
                </li>
                {
                gustsSettingsOptions === v.id &&
                <div ref={ref}>
                    <ul className="userActionPopup" style={{ position: "absolute" }}>
                        {userTab === 'only_user' || userTab === 'guest' ?
                            <>
                                <li className='!text-start' onClick={() => setResetPassword(true)}>Reset password</li>
                                <li className='!text-start' onClick={() => setChangePassword(true)}>Change password</li>
                            </> : ""
                        }
                        {userTab === 'only_user' &&
                            <li className='!text-start' onClick={() => setMakeAdmin(true)}>{v.role === 'Member' ? "Make admin" : "Remove admin role"}</li>
                        }
                        {/* <li className='!text-start' onClick={() => setUpdateRolePopup(true)} >Update Role</li> */}
                        {userTab === 'contacts' &&
                            <li className='!text-start' onClick={() => handleEditPopup(v)}>Edit</li>
                        }
                        {
                            v.login_total === 0 ? <li className='!text-start' onClick={() => setDeleteUser(true)}>Delete</li> :
                                <>
                                    <li className='!text-start' onClick={() => setActiveUser(true)}>{v.is_active === 0 ? "Active user" : "Deactivate account"}</li>
                                </>
                        }
                        {/* <li className='!text-start' onClick={() => setActiveUser(true)}>Delete</li> */}
                        {userTab === 'only_user' || userTab === 'guest' ?
                            <li onClick={() => setActiveEod(true)} className='!text-start'>{v.eod_report === false ? "Active sales report" : "Deactive sales report"}</li> : ""
                        }
                    </ul>
                </div>
            }
            </ul>

            {
                resetPassword && <AdminWarningPopup
                    actionuserData={v}
                    setall_user_data={setall_user_data}
                    type={'reset_password'}
                    title={'Reset password ?'}
                    desc={'Are you sure you want to reset password ?'}
                    onClose={() => setResetPassword(false)} />
            }
            {
                deleteUser && <AdminWarningPopup
                    actionuserData={v}
                    setall_user_data={setall_user_data}
                    type={'user_delete'}
                    title={'Delete user ?'}
                    desc={'Are you sure you want to delete this user ?'}
                    onClose={() => setDeleteUser(false)} />
            }
            {
                activeEod && <AdminWarningPopup
                    actionuserData={v}
                    setall_user_data={setall_user_data}
                    type={v.is_active === false ? 'active_eod' : 'deactive_eod'}
                    title={v.eod_report === false ? 'Active sales report ?' : 'Deactive sales report ?'}
                    desc={v.eod_report === false ? 'Are you sure you want to active sales report to this user?' : 'Are you sure you want to deactive sales report to this user?'}
                    onClose={() => setActiveEod(false)} />
            }
            {
                activeUser && <AdminWarningPopup
                    actionuserData={v}
                    setall_user_data={setall_user_data}
                    type={v.is_active === 0 ? 'active_user' : 'deactive_user'}
                    title={v.is_active === 0 ? 'Active user ?' : 'Deactive user ?'}
                    desc={v.is_active === 0 ? 'Are you sure you want to active this user?' : 'Are you sure you want to disable this user?'}
                    onClose={() => setActiveUser(false)} />
            }
            {
                makeAdmin && <AdminWarningPopup
                    actionuserData={v}
                    setall_user_data={setall_user_data}
                    type={v.role === 'Member' ? 'make_admin' : 'remove_admin'}
                    title={v.role === 'Member' ? 'Make admin ?' : 'Remove admin?'}
                    desc={v.role === 'Member' ? 'Are you sure you want to make this user admin ?' : 'Are you sure you want to make this user member ?'}
                    onClose={() => setMakeAdmin(false)} />
            }
            {
                changePassword && <ChangePassword onClose={() => setChangePassword(false)} user={v} from_admin={true} />
            }
            {
                updateRolePopup && <UpdateRolePopup thisUser={v} setUpdateRolePopup={setUpdateRolePopup} />
            }
        </>
    );
}

export default UserManagementHome;
