import React from 'react'
import FullCalendarUI from './FullCalendarUI'
import CalendarEvents from './CalendarEvents'
import { FcGoogle } from 'react-icons/fc'
import { FaMicrosoft } from 'react-icons/fa6'
import { useGoogleLogin } from "@react-oauth/google";

const Cal_popup_Layout = ({ setopen }) => {
    // const login = useGoogleLogin({
    //     onSuccess: (tokenResponse) => {
    //         alert(tokenResponse.access_token)
    //         // localStorage.setItem("gtoken", tokenResponse.access_token);
    //         //console.log(tokenResponse);
    //         // window.location.reload();
    //     },
    //     onError: () => {
    //         alert("Login Failed");
    //     },
    // });
    return (

        <div className='right_container'>
            <div className='full_cal_nav'>

                <div className='flexCenter'>
                    <div className='cal_nav_logo_bg flexCenter'>
                        <FcGoogle size={20} />
                    </div>
                    Connect to Google
                </div>

                {/* <div className='flexCenter'>
                    <FaMicrosoft size={18} style={{ color: "#fff" }} />Connect to Outlook</div> */}


            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    width: "100%",
                    margin: "15px 0",
                    padding: "0 15px",
                    gap: "20px"
                }}
            >
                {/* <FullCalendarUI />  */}
                <CalendarEvents />
            </div>
        </div>

    )
}

export default Cal_popup_Layout