import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FIND_ROLES } from '../../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';

const RoleAccess = ({thisUser}) => {
    const [selectedRole, setSelectedRole] = useState(null);
    const [roleList, setRoleList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [updateReq, setUpdateReq] = useState(false);

    const { loading: rolesLoading, data: rolesData } = useQuery(FIND_ROLES, { variables: { company_id: thisUser.company_id }, fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (rolesData) {
            setRoles(rolesData.roles.data)
        }
    }, [rolesData])

    useEffect(() => {
        // console.log('rolesData 25', roles, thisUser);
        if (roles.length > 0) {
            let rn = []
            let an = []
            
            for(let i=0; i<roles.length; i++){
                rn.push({value: roles[i].role_title, label: roles[i].role_title})
                for(let j=0; j<roles[i].role_access.length; j++){
                    an.push(roles[i].role_access[j])
                }
                if(thisUser.role === roles[i].role_title){
                    changeUpdateAccess(roles[i])
                }
            }
            setRoleList(rn)
            // setAccessList(an)
        }
    }, [roles])

    const onChangeUpdateAccess = (r) =>{
        // console.log(55, r)
        for(let i=0; i<roles.length; i++){
            // console.log(44, r, roles[i].role_title)
            if(r === roles[i].role_title){
                changeUpdateAccess(roles[i])
                setUpdateReq(true)
            }
        }
    }

    const changeUpdateAccess = (oneRole) =>{
        // console.log(45, oneRole, thisUser.access)
        debugger
        let sa = []
        setSelectedRole({value: oneRole.role_title, label: oneRole.role_title, data: oneRole})
        if(Array.isArray(thisUser.access) && thisUser.access.length>0 && updateReq === false){
            for(let j=0; j<thisUser.access.length; j++){
                sa.push(thisUser.access[j])
            }
        }else{
            for(let j=0; j<oneRole.role_access.length; j++){
                sa.push(oneRole.role_access[j])
            }
        }
        setAccessList(sa)
    }


    return (
        <div className="userProfileArea">
            <div className="security" style={{ height: 'calc(100% - 100px)' }}>
                {/* <div className="security_head">Role and Access</div> */}
                <div className="cu_inputGroup !mb-0 cu_inputGroup_select mt-6">
                    <label>Select Role<span className="red_star">*</span></label>
                    <Select
                        className="select-ecosystem"
                        value={selectedRole}
                        onChange={(e)=>onChangeUpdateAccess(e.value)}
                        options={roleList}
                        menuPlacement="bottom"
                        placeholder="Select specific role"
                    />
                </div>
                <p><br /></p>
                <table style={{height: '50px', background: '#ddd', paddingBottom: '0px'}}>
                    <tr key='h000'>
                        <td style={{ width: '60%' }}>Access Name</td>
                        <td style={{ width: '10%' }}>V</td>
                        <td style={{ width: '10%' }}>C</td>
                        <td style={{ width: '10%' }}>U</td>
                        <td style={{ width: '10%' }}>D</td>
                    </tr>
                </table>
                <table>
                    {accessList.map((item, index) => (
                        <tr key={index}>
                            <td style={{ width: '60%' }}>{item}</td>
                            <td style={{ width: '10%' }}><input className="checkGroupCall" type="checkbox" /></td>
                            <td style={{ width: '10%' }}><input className="checkGroupCall" type="checkbox" /></td>
                            <td style={{ width: '10%' }}><input className="checkGroupCall" type="checkbox" /></td>
                            <td style={{ width: '10%' }}><input className="checkGroupCall" type="checkbox" /></td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default RoleAccess;
