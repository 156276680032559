import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  conversations: [],
  archive_conversations: [],
  active_conversation: null,
  user: null,
  allUsers: [],
  msgs: [],
  reply_msgs: [],
  reply_main_msg: null,
  countersdata: null,
  downloading_file: [],
  downloading_progress: {},
  share_link_popup: false,
  forward_msg_popup: false,
  custom_title_popup: false,
  popup_action_data: null,
  global_action_data: null,
  voice_file: null,
  connect_files: null,
  action_file: null,
  change_file_data: null,
  change_link_data: null,
  online_users: [],
  refresh: 0,
  filterLeft: 'normal',
  all_tags: [],
  view_tag_files: {
    state: false,
    tag_ids: []
  },
  selected_tag: null,
  related_tag_popup: false,
  callringpopup: { status: false },
  raw_message_text: '',
  private_msg: false,
  merge_msg: { msg: '' },
  venue_particeprnt_popup: false,
  venue_contact_popup: false,
  eod_related_venue_popup: false,
  eod_dateFiltered_popup: false,
  selected_files_data: [],
  multi_file_ids: [],
  upload_popup: false,
  voice_record: false,
  msg_ai_active: false,
  ai_res_waiting: false,
  generating_image: false,
  kick_out_data: null,
  aiFilesLoader: false,
  aiCompletedFiles: [],
  updateVersion_data: 'no',
  conv_calling_data: [],
  deleted_msg: null,
  triggerUpload: false,
}

export const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setPopup: (state, action) => { state[action.payload.key] = action.payload.data },
    setBooleanState: (state, action) => { state[action.payload.key] = action.payload.data },
    set_aiCompletedFiles: (state, action) => {
      return ({
        ...state,
        aiCompletedFiles: action.payload
      })
    },
    setRefresh: (state, action) => {
      return ({
        ...state,
        refresh: action.payload > 0 ? (state.refresh + 1) : state.refresh
      })
    },
    set_conversations: (state, action) => {
      return ({
        ...state,
        conversations: [...action.payload]
      })
    },
    update_msg_link: (state, action) => {
      const { msg_id, link_data, is_reply_msg } = action.payload;
      return ({
        ...state,
        reply_msgs: is_reply_msg === 'yes' ? state.reply_msgs.map((msg) => msg.msg_id === msg_id ? { ...msg, link_data } : msg) : state.reply_msgs,
        msgs: is_reply_msg === 'no' ? state.msgs.map((msg) => msg.msg_id === msg_id ? { ...msg, link_data } : msg) : state.msgs
      })
    },
    set_reply_main_msg: (state, action) => {
      return ({
        ...state,
        reply_main_msg: action.payload
      })
    },
    set_archive_conversations: (state, action) => {
      return ({
        ...state,
        archive_conversations: [...action.payload]
      })
    },
    add_conversations: (state, action) => {
      return ({
        ...state,
        conversations: [action.payload, ...state.conversations,]
      })
    },
    set_kick_out_data: (state, action) => {
      return ({
        ...state,
        kick_out_data: action.payload,
        conversations: action.payload?.kickOut_type === 'conversation' ? [...state.conversations.filter(u => u.conversation_id !== action.payload.conversation_id)] : state.conversations
      })
    },
    set_active_conversation: (state, action) => {
      return ({
        ...state,
        active_conversation: { ...action.payload }
      })
    },
    set_raw_message_text: (state, action) => {
      return ({
        ...state,
        raw_message_text: action.payload
      })
    },
    update_room_data: (state, action) => {
      if (state.active_conversation.conversation_id === action.payload.conversation_id) {
        return ({
          ...state,
          active_conversation: { ...action.payload },
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...action.payload } : v)
        })
      } else {
        return ({
          ...state,
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...action.payload } : v)
        })
      }
    },
    set_user: (state, action) => {
      return ({
        ...state,
        user: { ...action.payload }
      })
    },
    set_allUsers: (state, action) => {
      return ({
        ...state,
        allUsers: [...action.payload]
      })
    },
    setMuteDataOnProps: (state, action) => {
      const { data, active, conversation_id, has_mute } = action.payload;
      if (action.payload.type === 'add' || action.payload.type === 'update') {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            has_mute: (active === 'yes' ? [state.user.id] : has_mute),
            mute: (active === 'yes' ? typeof data === 'string' ? [data] : data : state.active_conversation.mute)
          },
          conversations: state.conversations.map((conv) => conv.conversation_id === conversation_id ? { ...conv, "mute": [data], "has_mute": [state.user.id] } : conv),
        }
      } else {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            has_mute: (active === 'yes' ? [] : has_mute),
            mute: (active === 'yes' ? [] : state.active_conversation.mute)
          },
          conversations: state.conversations.map((conv) => (conv.conversation_id === conversation_id ? { ...conv, "mute": [], "has_mute": [] } : conv)),
        }
      }
    },
    set_pin_unpin: (state, action) => {
      return {
        ...state,
        active_conversation: { ...state.active_conversation, pin: (action.payload.action === 'pin' ? [state.user?.id] : []) },
        conversations: state.conversations.map(m => m.conversation_id === action.payload.conversation_id ? { ...m, pin: action.payload.action === 'pin' ? [state.user?.id] : [] } : m),
      }
    },
    setLeftFilter: (state, action) => {
      return {
        ...state,
        filterLeft: action.payload,
      }
    },
    setMergeMsg: (state, action) => {
      return {
        ...state,
        merge_msg: action.payload,
      }
    },
    set_view_tag_files: (state, action) => {
      return {
        ...state,
        view_tag_files: action.payload,
      }
    },
    set_selected_files_data: (state, action) => {
      return {
        ...state,
        selected_files_data: action.payload,
      }
    },
    set_multi_file_ids: (state, action) => {
      return {
        ...state,
        multi_file_ids: action.payload,
      }
    },
    set_all_tags: (state, action) => {
      return {
        ...state,
        all_tags: action.payload,
      }
    },
    set_change_link_data: (state, action) => {
      return {
        ...state,
        change_link_data: action.payload,
      }
    },
    set_selected_tag: (state, action) => {
      return {
        ...state,
        selected_tag: action.payload,
      }
    },
    set_related_tag_popup: (state, action) => {
      return {
        ...state,
        related_tag_popup: action.payload,
      }
    },
    set_venue_particeprnt_popup: (state, action) => {
      return {
        ...state,
        venue_particeprnt_popup: action.payload,
      }
    },
    set_venue_contact_popup: (state, action) => {
      return {
        ...state,
        venue_contact_popup: action.payload,
      }
    },
    set_eod_related_venue_popup: (state, action) => {
      return {
        ...state,
        eod_related_venue_popup: action.payload,
      }
    },
    set_eod_dateFiltered_popup: (state, action) => {
      return {
        ...state,
        eod_dateFiltered_popup: action.payload,
      }
    },
    set_messages: (state, action) => {
      const old_msg_ids = state.msgs.map(v => v.msg_id);

      if (action.payload.is_reply_msg === 'no') {
        if (!action.payload.new) {
          if (action.payload.page > 1) {
            return {
              ...state,
              msgs: [
                ...action.payload.data.filter(v => !old_msg_ids.includes(v.msg_id)),
                ...state.msgs,
              ],
            };
          } else {
            return {
              ...state,
              msgs: action.payload.data,
            };
          }
        }

        if (action.payload.my_new_msg) {
          return {
            ...state,
            conversations: state.conversations
              .map((c) =>
                c.conversation_id === state.active_conversation.conversation_id
                  ? { ...c, last_msg_time: action.payload.created_at }
                  : c
              )
              .sort((a, b) => (a.last_msg_time > b.last_msg_time ? -1 : 1)),
          };
        }
      } else {
        if (!action.payload.new) {
          if (action.payload.page > 1) {
            return {
              ...state,
              reply_msgs: [...action.payload.data, ...state.reply_msgs],
            };
          } else {
            return {
              ...state,
              reply_msgs: action.payload.data,
            };
          }
        }
      }

      return state; // Default case to prevent returning undefined
    },

    update_messages: (state, action) => {
      const { conversation_id, msg_id, fields, is_reply_msg } = action.payload;

      if (is_reply_msg === 'no') {
        const messageToUpdate = state.msgs.find(msg => msg.conversation_id === conversation_id && msg.msg_id === msg_id);
        Object.assign(messageToUpdate, fields);
      } else {
        const replyMessageToUpdate = state.reply_msgs.find(msg => msg.conversation_id === conversation_id && msg.msg_id === msg_id);
        if (replyMessageToUpdate) {
          Object.assign(replyMessageToUpdate, fields);
        }
      }
    },
    delete_single_item_form_array: (state, action) => {
      const { state_key, item_key, value } = action.payload;
      state[state_key] = state[state_key].filter(v => v[item_key] !== value);
    },
    setCountersData: (state, action) => {
      state.countersdata = action.payload;
    },
    update_CountersData: (state, action) => {
      const { taskunreadcount } = action.payload;
      return ({
        ...state,
        countersdata: { ...state.countersdata, taskunreadcount: taskunreadcount }
      })
    },
    add_new_room: (state, action) => {
      if (action.payload.single === 'yes') {
        action.payload['title'] = action.payload.conv_name_img[state.user.id].title;
        action.payload['conv_img'] = action.payload.conv_name_img[state.user.id].img;
      }
      return ({
        ...state,
        conversations: [action.payload, ...state.conversations.filter(u => u.conversation_id !== action.payload.conversation_id)]
      })
    },
    updateRoom: (state, action) => {
      if (!state.active_conversation) {
        return {
          ...state,
          conversations: state.conversations.map((v, i) =>
            v.conversation_id === action.payload.conversation_id ?
              {
                ...v,
                b_unit_id: action.payload.b_unit_id,
                b_unit_id_name: action.payload.b_unit_id_name,
                group_keyspace: action.payload.group_keyspace,
                participants: action.payload.participants,
                participants_admin: action.payload.participants_admin,
                participants_guest: action.payload.participants_guest,
                privacy: action.payload.privacy,
                title: action.payload.title,
                conv_img: action.payload.conv_img
              }
              : v
          ),
        }
      } else {
        return {
          ...state,
          active_conversation: state.active_conversation.conversation_id === action.payload.conversation_id ?
            {
              ...state.active_conversation,
              b_unit_id: action.payload.b_unit_id,
              b_unit_id_name: action.payload.b_unit_id_name,
              group_keyspace: action.payload.group_keyspace,
              participants: action.payload.participants,
              participants_admin: action.payload.participants_admin,
              participants_guest: action.payload.participants_guest,
              privacy: action.payload.privacy,
              title: action.payload.title,
              conv_img: action.payload.conv_img
            }
            : state.active_conversation,
          conversations: state.conversations.map((v, i) =>
            v.conversation_id === action.payload.conversation_id ?
              {
                ...v,
                b_unit_id: action.payload.b_unit_id,
                b_unit_id_name: action.payload.b_unit_id_name,
                group_keyspace: action.payload.group_keyspace,
                participants: action.payload.participants,
                participants_admin: action.payload.participants_admin,
                participants_guest: action.payload.participants_guest,
                privacy: action.payload.privacy,
                title: action.payload.title,
                conv_img: action.payload.conv_img
              }
              : v
          ),
        }
      }
    },
    sendOneMessage: (state, action) => {
      const { conversation_id, sender, mention_user, created_at } = action.payload;
      const isUserMentioned = mention_user?.includes(state.user?.id);
      const isActiveConversation = state.active_conversation?.conversation_id === conversation_id;

      const updateConversations = (conversations) =>
        conversations.map((c) =>
          c.conversation_id === conversation_id
            ? {
              ...c,
              urmsg: sender !== state.user?.id ? (c.urmsg || 0) + 1 : c.urmsg,
              mention_user: isUserMentioned ? 1 : c.mention_user,
              last_msg_time: created_at,
            }
            : c
        )
          .sort((a, b) => (a.last_msg_time > b.last_msg_time ? -1 : 1));

      const updateCountersData = (countersdata) => {
        const conversationExists = countersdata.conversations.some(
          (c) => c.conversation_id === conversation_id
        );

        const updatedConversations = conversationExists
          ? countersdata.conversations.map((c) =>
            c.conversation_id === conversation_id
              ? { ...c, urmsg: c.urmsg ? c.urmsg + 1 : 1 }
              : c
          )
          : [...countersdata.conversations, { conversation_id, msgsids: [], urmsg: 1, urreply: 0 }];

        return {
          ...countersdata,
          total_unread_msg: countersdata.total_unread_msg + 1,
          conversations: updatedConversations,
        };
      };

      if (isActiveConversation) {
        if (state.idle_status) {
          action.payload.msg_status = [state.user?.id];
        }

        return {
          ...state,
          msgs: [...state.msgs, action.payload],
          conversations: updateConversations(state.conversations),
        };
      }

      return {
        ...state,
        countersdata: updateCountersData(state.countersdata),
        conversations: updateConversations(state.conversations),
      };
    },
    setOneReplyData: (state, action) => {
      let updatedConversations = [];
      let countersDataClone = JSON.parse(JSON.stringify(state.countersdata));

      // Handle cases where conversations exist
      if (countersDataClone.conversations.length > 0) {
        const conversationIndex = countersDataClone.conversations.findIndex((c) => c.conversation_id === action.payload.conversation_id);
        if (conversationIndex !== -1) {
          // Conversation exists
          const conversation = countersDataClone.conversations[conversationIndex];
          const messageIndex = conversation.msgids.findIndex((m) => m.msgid === action.payload.reply_for_msgid);

          if (messageIndex !== -1) {
            // Message exists; update unread count
            updatedConversations = countersDataClone.conversations.map((c) =>
              c.conversation_id === action.payload.conversation_id
                ? {
                  ...c,
                  urreply: c.urreply + 1,
                  msgids: c.msgids.map((m) =>
                    m.msgid === action.payload.reply_for_msgid
                      ? { ...m, unread: m.unread + 1 }
                      : m
                  ),
                }
                : c
            );
          } else {
            // Message doesn't exist; add a new message entry
            updatedConversations = countersDataClone.conversations.map((c) =>
              c.conversation_id === action.payload.conversation_id
                ? {
                  ...c,
                  urreply: c.urreply + 1,
                  msgids: [
                    ...c.msgids,
                    { msgid: action.payload.reply_for_msgid, unread: 1 },
                  ],
                }
                : c
            );
          }
        } else {
          // Conversation doesn't exist; create a new conversation
          updatedConversations = [
            ...countersDataClone.conversations,
            {
              conversation_id: action.payload.conversation_id,
              urmsg: 0,
              urreply: 1,
              msgids: [
                {
                  msgid: action.payload.reply_for_msgid,
                  unread: 1,
                },
              ],
            },
          ];
        }
      } else {
        // No conversations exist; initialize first conversation
        updatedConversations = [
          {
            conversation_id: action.payload.conversation_id,
            msgids: [{ msgid: action.payload.reply_for_msgid, unread: 1 }],
            urmsg: 0,
            urreply: 1,
          },
        ];
      }

      // Mentioned user logic
      const isMentioned = action.payload.mention_user && action.payload.mention_user.includes(state.user?.id);

      // Update active thread
      let newState = { ...state };

      if (state.reply_main_msg && state.reply_main_msg.msg_id === action.payload.reply_for_msgid) {
        // Update active thread messages
        const msg = state.msgs.find((m) => m.msg_id === action.payload.reply_for_msgid);
        const hasReplyAttach = msg && action.payload.all_attachment.length ? (msg.has_reply_attach || 0) + 1 : msg?.has_reply_attach || 0;

        newState = {
          ...state,
          msgs: state.msgs.map((m) =>
            m.msg_id === action.payload.reply_for_msgid
              ? {
                ...m,
                has_reply: (m.has_reply || 0) + 1,
                has_reply_attach: hasReplyAttach,
                last_reply_time: action.payload.created_at,
                last_reply_name: action.payload.sendername,
              }
              : m
          ),
          reply_msgs: action.payload.sender !== state.user.id ? [...state.reply_msgs, action.payload] : state.reply_msgs,
        };
      } else if (action.payload.conversation_id === state.active_conversation?.conversation_id) {
        // Update active conversation
        newState = {
          ...state,
          msgs: state.msgs.map((m) =>
            m.msg_id === action.payload.reply_for_msgid
              ? {
                ...m,
                unread_reply:
                  action.payload.sender !== state.user?.id
                    ? (m.unread_reply || 0) + 1
                    : m.unread_reply,
                has_reply: (m.has_reply || 0) + 1,
                last_reply_time: action.payload.created_at,
                last_reply_name: action.payload.sendername,
              }
              : m
          ),
          countersdata: {
            ...state.countersdata,
            total_unread_reply:
              action.payload.sender !== state.user?.id
                ? state.countersdata.total_unread_reply + 1
                : state.countersdata.total_unread_reply,
            conversations:
              action.payload.sender !== state.user?.id
                ? updatedConversations
                : state.countersdata.conversations,
          },
          conversations: state.conversations.map((conv) =>
            conv.conversation_id === action.payload.conversation_id
              ? {
                ...conv,
                r_mention_user:
                  action.payload.sender !== state.user?.id && isMentioned
                    ? 1
                    : conv.r_mention_user,
                urreply:
                  action.payload.sender !== state.user?.id
                    ? conv.urreply + 1
                    : conv.urreply,
              }
              : conv
          ),
        };
      } else {
        // Update other conversations
        newState = {
          ...state,
          countersdata: {
            ...state.countersdata,
            total_unread_reply:
              action.payload.sender !== state.user?.id
                ? state.countersdata.total_unread_reply + 1
                : state.countersdata.total_unread_reply,
            conversations:
              action.payload.sender !== state.user?.id
                ? updatedConversations
                : state.countersdata.conversations,
          },
          conversations: state.conversations.map((conv) =>
            conv.conversation_id === action.payload.conversation_id
              ? {
                ...conv,
                r_mention_user:
                  action.payload.sender !== state.user?.id && isMentioned
                    ? 1
                    : conv.r_mention_user,
                urreply:
                  action.payload.sender !== state.user?.id
                    ? conv.urreply + 1
                    : conv.urreply,
              }
              : conv
          ),
        };
      }

      return newState;
    },

    set_deleted_msg: (state, action) => {
      return {
        ...state,
        deleted_msg: action.payload,
      };
    },

    updateOneMsg: (state, action) => {

      let data = [];
      let entry = false;
      let url_base_title = false;
      let updatedata = [];
      let countersdataLET = JSON.parse(JSON.stringify(state.countersdata));

      const { field, conversation_id, msg_id, is_reply_msg, delete_type, msg_body, checklist, reply_for_msgid } = action.payload;

      // Determine the data and entry conditions based on the field
      switch (field) {
        case 'has_delete':
          data = [state.user?.id];
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            if (delete_type === 'for_all') {
              data = [...state.active_conversation?.participants, 'for_all'];
            }
          } else {
            updatedata = countersdataLET.conversations.map(c =>
              c.conversation_id === conversation_id ? { ...c, urmsg: Math.max(c.urmsg - 1, 0) } : c
            );
          }
          break;

        case 'edit_history':
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            data = action.payload.edit_history;
            url_base_title = true;
          }
          break;

        case 'has_emoji':
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            data = action.payload.has_emoji;
          }
          break;

        case 'has_flagged':
        case 'has_star':
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            data = action.payload.has_flagged === 'yes' ? [state.user?.id] : [];
          }
          break;

        case 'secret_user_name':
        case 'secret_user_details':
        case 'secret_user':
        case 'is_secret':
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            data = action.payload[field];
          }
          break;

        case 'msg_type':
          if (conversation_id === state.active_conversation?.conversation_id) {
            entry = true;
            data = 'checklist';
          }
          break;

        case 'full_delete':
          if (conversation_id === state.active_conversation?.conversation_id) {
            let { delete_type, user_id } = action.payload; //"for_me"/"for_all"
            entry = true;
            if (is_reply_msg === 'no') {
              state.msgs = state.msgs.filter(msg => msg.msg_id !== msg_id);
              state.deleted_msg = action.payload;
            } else {
              state.reply_msgs = delete_type === 'for_all' ?
                state.reply_msgs.filter(msg => msg.msg_id !== msg_id)
                :
                state.reply_msgs.map(msg => msg.msg_id === msg_id ? {
                  ...msg, msg_body: 'You deleted this message', has_delete: [user_id]
                } : msg);
              state.msgs = state.msgs.map(msg => msg.msg_id === reply_for_msgid ? { ...msg, has_reply: msg.has_reply > 0 ? Number(msg.has_reply) - 1 : 0 } : msg);
            }
          }
          break;

        default:
          break;
      }

      // Apply updates if entry is true
      if (entry) {
        const updateMessages = (messages) =>
          messages.map(msg =>
            msg.msg_id === msg_id
              ? {
                ...msg,
                [field]: data,
                msg_body: msg_body !== undefined ? msg_body : msg.msg_body,
                checklist: data === 'checklist' ? checklist : msg.checklist,
              }
              : msg
          );

        if (is_reply_msg === 'yes') {
          if (url_base_title) {
            state.reply_msgs = state.reply_msgs.map(msg =>
              msg.msg_id === msg_id ? { ...action.payload.msg } : msg
            );
          } else {
            state.reply_msgs = updateMessages(state.reply_msgs);
          }
        } else {
          if (url_base_title) {
            state.msgs = state.msgs.map(msg =>
              msg.msg_id === msg_id ? { ...action.payload.msg } : msg
            );
          } else {
            state.msgs = updateMessages(state.msgs);
          }
        }
      } else if (updatedata.length) {
        state.countersdata = {
          ...state.countersdata,
          total_unread_msg: state.countersdata.total_unread_msg - 1,
          conversations: updatedata,
        };
      }
    },
    updateArchiveConv: (state, action) => {
      if (state.active_conversation) {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            archive: action.payload.conversation_id === state.active_conversation.conversation_id ? action.payload.action : state.active_conversation.archive
          },
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.action } : v)
        }
      } else {
        return {
          ...state,
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.action } : v)
        }
      }
    },
    // updateArchiveConv: (state, action) => {
    //   const { conversation_id, action: archiveStatus } = action.payload;
    //   const updatedConversations = state.conversations.map(v => v.conversation_id === conversation_id ? { ...v, archive: archiveStatus } : v);

    //   if (!state.active_conversation) {
    //     return { ...state, conversations: updatedConversations };
    //   }

    //   return {
    //     ...state,
    //     active_conversation: state.active_conversation.conversation_id === conversation_id ? { ...state.active_conversation, archive: archiveStatus } : state.active_conversation,
    //     archive_conversations: updatedConversations.filter(v => v.archive === 'yes'),
    //     // conversations: updatedConversations
    //   };
    // },
    closeConversation: (state, action) => {
      if (state.active_conversation) {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            close_for: action.payload.conversation_id === state.active_conversation.conversation_id ? action.payload.action : state.active_conversation.close_for
          },
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.action } : v),
        }
      } else {
        return {
          ...state,
          conversations: state.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.action } : v),
        }
      }
    },
    removeMessage: (state, action) => {
      return {
        ...state,
        msgs: state.msgs.filter(v => v.msg_id !== action.payload.msg_id)
      }
    },
    removeAttachment: (state, action) => {
      if (state.active_conversation) {
        if (state.active_conversation.conversation_id === action.payload.conversation_id) {
          return {
            ...state,
            msgs: state.msgs.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.filter(f => f.id !== action.payload.file_id) } : v),
            change_file_data: action.payload
          }
        } else {
          return {
            ...state,
            change_file_data: action.payload
          }
        }
      } else {
        return {
          ...state,
          change_file_data: action.payload
        }
      }
    },
    file_share_link_create: (state, action) => {
      if (state.active_conversation) {
        if (state.active_conversation.conversation_id === action.payload.conversation_id) {
          return {
            ...state,
            msgs: state.msgs.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : v),
            // active_thread: state.active_thread && state.active_thread?.msg_id === action.payload.msg_id ? { ...state.active_thread, all_attachment: state.active_thread?.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : state.active_thread,
            reply_msgs: state.reply_msgs.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : v),
            change_file_data: action.payload
          }
        } else {
          return {
            ...state,
            change_file_data: action.payload
          }
        }
      } else {
        return {
          ...state,
          change_file_data: action.payload
        }
      }
    },


    // updateMsgTag: (state, action) => {

    //   if (state.active_conversation) {
    //     if (action.payload.conversation_id === state.active_conversation.conversation_id) {
    //       return {
    //         ...state,
    //         msgs: state.msgs.map((v) =>
    //           v.msg_id === action.payload.msg_id ? {
    //             ...v,
    //             all_attachment: v.all_attachment.map((f) =>
    //               f.id === action.payload.file_id || action.payload.file_id === action.payload.conversation_id ? {
    //                 ...f,
    //                 tag_list_details: f.tag_list_details ? [...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1), ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)] : [...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)],
    //                 tag_list: [...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1), ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)]
    //               } : f
    //             )
    //           } : v
    //         ),
    //         reply_msgs: action.payload.is_reply === 'yes' ? [
    //           ...state.reply_msgs.map((v) => v.msg_id === action.payload.msg_id ? {
    //             ...v,
    //             all_attachment: v.all_attachment.map((f) => f.id === action.payload.file_id || action.payload.file_id === action.payload.conversation_id ?
    //               {
    //                 ...f,
    //                 tag_list_details: [
    //                   ...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1),
    //                   ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)
    //                 ], tag_list: [
    //                   ...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1),
    //                   ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)
    //                 ]
    //               }
    //               : f)
    //           } : v)
    //         ] : [...state.reply_msgs],
    //         change_file_data: action.payload
    //       }
    //     } else {
    //       return {
    //         ...state,
    //         change_file_data: action.payload
    //       }
    //     }
    //   } else {
    //     return {
    //       ...state,
    //       change_file_data: action.payload
    //     }
    //   }
    // },
    updateMsgTag: (state, action) => {
      const { payload } = action;
      const {
        conversation_id,
        msg_id,
        file_id,
        is_reply,
        removetag,
        newtag,
        newtag_tag_data,
      } = payload;

      const updateAttachments = (attachments) =>
        attachments.map((f) =>
          f.id === file_id
            ? {
              ...f,
              tag_list_details: [
                ...(f.tag_list_details || []).filter((t) => !removetag.includes(t.tag_id)),
                ...newtag_tag_data.filter((t) => !f.tag_list.includes(t.tag_id)),
              ],
              tag_list: [
                ...f.tag_list.filter((t) => !removetag.includes(t)),
                ...newtag.filter((t) => !f.tag_list.includes(t)),
              ],
            }
            : f
        );

      const updateMessages = (messages) =>
        messages.map((v) =>
          v.msg_id === msg_id
            ? {
              ...v,
              all_attachment: updateAttachments(v.all_attachment),
            }
            : v
        );

      if (state.active_conversation) {
        if (conversation_id === state.active_conversation.conversation_id) {
          return {
            ...state,
            msgs: updateMessages(state.msgs),
            reply_msgs: is_reply === 'yes' ? updateMessages(state.reply_msgs) : state.reply_msgs,
            change_file_data: payload,
          };
        }
      }

      return {
        ...state,
        change_file_data: payload,
      };
    },
    updateMessageStar: (state, action) => {
      if (state.active_conversation) {
        if (action.payload.is_reply_msg === 'yes') {
          let reply_msgs = state.reply_msgs.map(v =>
            v.msg_id === action.payload.msg_id ?
              {
                ...v,
                all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f)
              }
              : v
          );
          return {
            ...state,
            reply_msgs: reply_msgs,
            change_file_data: action.payload
          }
        } else {
          return {
            ...state,
            msgs: state.msgs.map(v =>
              v.msg_id === action.payload.msg_id ?
                {
                  ...v,
                  all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f)
                }
                : v
            ),
            change_file_data: action.payload
          }
        }
      } else {
        return {
          ...state,
          change_file_data: action.payload
        }
      }
    },
    updateFlagCounter: (state, action) => {
      state.countersdata.all_flag_conversation = action.payload.all_flag_conversation
    },
    set_downloading_file: (state, action) => { state.downloading_file = action.payload },
    set_downloading_progress: (state, action) => { state.downloading_progress = action.payload },
    set_share_link_popup: (state, action) => { state.share_link_popup = action.payload },
    set_popup_action_data: (state, action) => { state.downloading_progress = action.payload },
    set_global_action_data: (state, action) => { state.global_action_data = action.payload },
    set_voice_file: (state, action) => { state.voice_file = action.payload },
    set_connect_files: (state, action) => {
      state.connect_files = action.payload
    },
    set_action_file: (state, action) => { state.action_file = action.payload },
    updateMsgCounter: (state, action) => {
      if (action.payload.is_reply_msg === 'yes') {
        return {
          ...state,
          countersdata: {
            ...state.countersdata,
            total_unread_reply: (state.countersdata.total_unread_reply - action.payload.read),
            conversations: state.countersdata.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, msgids: v.msgids.map(m => m.msgid === action.payload.root_msg_id ? { ...m, unread: (m.unread - action.payload.read) } : action.payload.root_msg_id === "" ? { unread: 0 } : m), urreply: (v.urreply - action.payload.read) } : v),
          }
        }
      } else {
        return {
          ...state,
          countersdata: {
            ...state.countersdata,
            total_unread_msg: (state.countersdata.total_unread_msg - action.payload.read),
            conversations: state.countersdata.conversations.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, urmsg: (v.urmsg - action.payload.read) } : v)
          }

        }
      }
    },
    set_online_users: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          online_users: [...action.payload]
        }
      } else {
        return state;
      }
    },
    set_callringpopup: (state, action) => {
      return {
        ...state,
        callringpopup: action.payload
      }
    },
    setUserShortUrl: (state, action) => {
      if (state.active_conversation) {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            short_id: action.payload.short_id
          },

        }
      }
    },
    setConvShortUrl: (state, action) => {
      if (state.active_conversation) {
        return {
          ...state,
          active_conversation: {
            ...state.active_conversation,
            short_id: action.payload
          },

        }
      }
    },
    setUpdateVersion: (state, action) => {
      return {
        ...state,
        updateVersion_data: action.payload
      }
    },
    set_conv_calling_data: (state, action) => {
      // debugger
      return {
        ...state,
        conv_calling_data: action.payload
      }
    },


  },
})

// Action creators are generated for each case reducer function
export const { update_room_data, add_conversations, set_conversations, set_active_conversation, set_user, set_allUsers, setMuteDataOnProps, set_pin_unpin, updateArchiveConv,
  closeConversation, set_messages, update_messages, setCountersData, sendOneMessage, updateOneMsg, removeAttachment, file_share_link_create,
  set_downloading_file, set_downloading_progress, set_share_link_popup, set_popup_action_data, set_action_file, setPopup, set_voice_file, set_connect_files,
  set_global_action_data, delete_single_item_form_array, setOneReplyData, add_new_room, updateRoom, set_online_users, updateMsgTag, updateMessageStar,
  removeMessage, setRefresh, updateMsgCounter, setLeftFilter, setMergeMsg, updateFlagCounter, set_view_tag_files, set_selected_tag, set_related_tag_popup,
  set_venue_particeprnt_popup, set_venue_contact_popup, set_eod_dateFiltered_popup, set_eod_related_venue_popup, set_callringpopup, set_archive_conversations, setUserShortUrl, set_all_tags, setConvShortUrl, set_raw_message_text, set_change_link_data,
  set_multi_file_ids, set_selected_files_data, set_reply_main_msg, update_CountersData, setBooleanState, set_kick_out_data, setUpdateVersion, update_msg_link, set_aiCompletedFiles, set_conv_calling_data, set_deleted_msg
} = message.actions

export default message.reducer