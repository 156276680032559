import React from 'react';
import { useParams } from 'react-router-dom';

const TaskWarningPopup = ({ setTaskDeletePopup, onConfirm, type, setTaskRoomUpdateWarning }) => {
    const params = useParams()
    const roomChange = params.conversation_id ? 'Are you sure you want to change this room? If change, tasks will reset and disappear from this room.' : 'Are you sure you want to change this room?'
    return (
        <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
            <div className="taskHeader_right_area justify-end">
                <div className="deletedPopupEditTask inproperties group">
                    <div className="deletedPopup_text">{
                        type === "delete" ? "Are you sure you want to delete this task?" : roomChange}</div>
                    <div className='flex gap-2'>
                        {
                            type === "delete" ?
                                <div className="deletedPopup_delete_btn cursor-pointer hover:bg-red-700" onClick={() => onConfirm(type)}>Delete</div>
                                :
                                <div className="deletedPopup_delete_btn !w-[90px] cursor-pointer !bg-[#318fff]" onClick={() => onConfirm(type)}>Confirm</div>
                        }
                        {
                            type === "delete" ? <div className="deletedPopup_cancel_btn cursor-pointer hover:bg-red-600 hover:border-none" onClick={() => setTaskDeletePopup(false)} >Cancel</div> :
                                <div className="deletedPopup_cancel_btn cursor-pointer hover:bg-red-600 hover:border-none" onClick={() => setTaskRoomUpdateWarning(false)} >Cancel</div>
                        }


                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskWarningPopup;