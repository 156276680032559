import React, { useState, useEffect } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import Scrollbars from 'react-custom-scrollbars';
import CreateEditVenue from './CreateEditVenue';
import UpdateVenue from './UpdateVenue';
import { Toaster, toast } from 'react-hot-toast';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { GETVENULIST, GET_ME, GET_ALL_USERS } from '../../../Utils/GraphqlQueries';
import { Delete_venue } from '../../../Utils/GraphqlMutaion';
import Moment from 'react-moment';
import VenueWarnPopup from './VenueWarnPopup';
import { useDispatch } from "react-redux";
import { all_venue_result, all_Users } from '../../../redux/adminSlice';

function VenueManager(props) {
    const [createVenue, setCreateVenue] = useState(false)
    const [venueDetails, setVenueDetails] = useState(false)
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    // const [VenuListAll, { data, loading, error }] = useLazyQuery(GETVENULIST);
    // const [fetchUsers, { loading: UsersLoading, data: AllUsers }] = useLazyQuery(GET_ALL_USERS);
    const [venuesList, setVenuesList] = useState([]);
    const [srcString, setSrcString] = useState('');
    const [deleteVenue, setDeleteVenue] = useState(false);
    const [gustsSettingsOptions, setGustsSettingsOptions] = useState(true);

    const dispatch = useDispatch()



    const { data: venueData } = useQuery(GETVENULIST, {
        fetchPolicy: 'no-cache',
    });

    const { data: userData } = useQuery(GET_ALL_USERS, {
        variables: { company_id: user.company_id },
        fetchPolicy: 'no-cache',
    });


    useEffect(() => {
        if (venueData && venueData.get_venue_list && venueData.get_venue_list.result) {
            const updatedList = venueData.get_venue_list.result.slice(1);
            // console.log("vanue", updatedList);
            setVenuesList(updatedList);
        }
        if (userData && userData.users) {
            // console.log("user", userData.users);
            dispatch(all_Users(userData.users));
        }
    }, [venueData, userData, dispatch]);



    // useEffect(() => {
    //     const fetchData = async () => {
    //         await VenuListAll();
    //         if (data && data.get_venue_list && data.get_venue_list.result) {
    //             const updatedList = data.get_venue_list.result.slice(1);
    //             const allUser = await fetchUsers({ variables: { company_id: updatedList[0].company_id } });
    //             dispatch(all_Users(allUser.data.users));
    //             setVenuesList(updatedList);
    //         }
    //     };
    //     fetchData();
    // }, [data]);



    const handleSettingsOptions = (venue, id) => {
        // console.log(user.id == venue.created_by);        
        if (user.id === venue.created_by) {
            setGustsSettingsOptions(gustsSettingsOptions === id ? null : id);
        } else {
            toast.error("Only creator can use this action.", { duration: 4000 });
        }
    }

    const ref = useClickAway(() => {
        setGustsSettingsOptions(false)
    });


    const [sortActiveType, setSortActiveType] = useState(null);
    const [sortIconVenueName, setSortIconVenueName] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');

    const [popUpType, setPopUpType] = useState('');
    const [venueSingle, setVenueSingle] = useState([]);

    const handleSortListadd = (type) => {
        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        if (type === 'byVenuename') {
            setSortActiveType(type);
            setSortIconVenueName(!sortIconVenueName);
            const sortedTags = [...venuesList].sort((a, b) => {
                const nameA = a.venue_title.toLowerCase();
                const nameB = b.venue_title.toLowerCase();
                return newSortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            });
            setVenuesList(sortedTags);
        }
    };


    const actionPopUp = (type, venue) => {
        if (type === 'create') {
            setPopUpType(type)
            setCreateVenue(!createVenue);
        }
        if (type === 'edit') {
            dispatch(all_venue_result(venue));
            setPopUpType(type);
            setCreateVenue(!createVenue);
            setVenueSingle(venue);
        }
    }

    const [delete_SingleVenue] = useMutation(Delete_venue);

    const deleteat = (venu) => {
        setDeleteVenue(!deleteVenue);
        setVenueSingle(venu);
    }

    const delete_Venue = async (venu) => {
        try {
            // console.log("103", venu);
            const data = { _id: venu._id };
            const apires = await delete_SingleVenue({ variables: { input: data } });
            if (apires?.data?.delete_venue?.status) {
                toast.success("Venue deleted successfully", { duration: 4000 });
                const venuesDeleteList = venuesList.filter((item) => item._id !== venu._id);
                setVenuesList(venuesDeleteList);
                setDeleteVenue(prev => !prev);
            } else {
                const errorMessage = apires?.data?.delete_venue?.message || "An error occurred";
                toast.error(errorMessage, { duration: 4000 });
                setDeleteVenue(prev => !prev);
            }
        } catch (error) {
            console.error("Error:", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong. Please try again later.";
            toast.error(errorMessage, { duration: 4000 });
            setDeleteVenue(prev => !prev);
        }
    };


    const actionDetails = async (venue) => {
        try {
            dispatch(all_venue_result(venue));
            setVenueSingle(venue);
            setVenueDetails(!venueDetails);
        } catch (error) {
            console.error("Error in actionDetails:");
        }
    };
    const [style, setStyle] = useState(false);
    const MyTooltip = () => {
        return (
            <span style={{ marginLeft: '5px', cursor: 'pointer' }} className="tooltip1 user_Management_title">
                <span className="tooltiptext1 tooltipVenue" >
                    <p>
                        Venue management that enables users to create, edit, and update venues, assign roles, and manage venue-related tasks efficiently with role-based access and seamless integration into the application.
                    </p>

                    <span onClick={e => { setStyle(false) }} className='tooltipClose'></span>
                </span>
            </span>
        )
    }
    const [create_venueShow, setCreate_venueShow] = useState(false);
    const CreateVenueShow = () => {
        return (
            create_venueShow &&
            <span style={{ cursor: 'pointer' }} className="user_Management_title createTeam">
                <span className="tooltiptext5">
                    <p> Create a venue. </p>
                    <span onClick={e => { setCreate_venueShow(false); }} className='tooltipClose'></span>
                </span>
            </span>
        )
    }

    return (
        <>
            <Toaster />
            {deleteVenue && <VenueWarnPopup title={'Are you absolutely sure?'} venueSingle={venueSingle} onClose={() => setDeleteVenue(!deleteVenue)} onConfirm={delete_Venue} />}
            <div className="right_container admin_right innervenue">
                <div className="adminRightHead">
                    <span className="user_Management_title">Venue management
                        <span className='info_tooltip_new'
                            onMouseEnter={() => { setStyle(true) }}
                            onMouseLeave={() => { setStyle(false) }}
                        ></span>
                    </span>
                    {style && <MyTooltip />}

                </div>
                <div className="teamManagementContainer">
                    <div className="teamScrollContainer">
                        <div className="usersTabSection !p-0" >
                            <div className="usersTabSecDevide">
                                <div className="teamManagementTitle">
                                    Venues ({venuesList.length})
                                </div>
                            </div>
                            <div className="usersTabSecDevide flex justify-end">
                                <span className="w-[300px] relative mr-3">
                                    <input
                                        className="userSearchInput"
                                        type="text"
                                        value={srcString}
                                        placeholder="Search a venue"
                                        onChange={(e) => setSrcString(e.target.value)}
                                        autoFocus={true}
                                    />
                                    <span className="leftSearchCloseBtn remove" style={{ top: 8, right: 10 }} onClick={() => setSrcString("")} />
                                </span>
                                <button
                                    className="userCreateBtn"
                                    onClick={() => actionPopUp('create', [])}
                                    onMouseEnter={() => setCreate_venueShow(true)}
                                    onMouseLeave={() => setCreate_venueShow(false)}
                                >Create a venue</button>
                                <CreateVenueShow />
                            </div>
                        </div>
                        <div className="usersTableContainer !p-0" >
                            <div className="userTableHead">
                                <ul className="">
                                    <li onClick={() => handleSortListadd("byVenuename")} className={`_userRole !w-1/4 ${sortActiveType === "byVenuename" ? 'sort_active' : ''} ${!sortIconVenueName ? 'asce' : 'desc'}`}>
                                        <span className="sortIcons" />
                                        Venue name
                                    </li>
                                    <li className="_userCBy teamCBY  !w-1/4" >
                                        Created by
                                    </li>
                                    <li className="_userCBy teamCBY  !w-1/4">
                                        Updated by
                                    </li>
                                    <li className="_userCBy teamCBY  !w-1/5">
                                        Updated at
                                    </li>
                                    <li className="_userAction !w-1/20 relative flex !justify-center">
                                        Action
                                    </li>
                                </ul>
                            </div>

                            <div className="userTableBody">
                                <Scrollbars autoHide className="scrollBarClass">
                                    {
                                        venuesList.filter(f => f.venue_title.toLowerCase().includes(srcString.toLowerCase())).length === 0 ?
                                            (
                                                <div style={{ position: "absolute", inset: 0, overflow: "scroll", marginRight: "-8px", marginBottom: "-8px", textAlign: "center", marginTop: "20%" }}>
                                                    No data found
                                                </div>
                                            )
                                            :
                                            (
                                                venuesList.filter(f => f.venue_title.toLowerCase().includes(srcString.toLowerCase())).map(venue => (
                                                    <ul key={venue.id} className="_userList flex cursor-pointer relative">
                                                        <li className="_userName !w-1/4" onClick={() => actionDetails(venue)}>
                                                            <span className="_userNameTxt">{venue.venue_title}</span>
                                                        </li>
                                                        <li className="_userCBy TeamAddDetails !w-1/4" onClick={() => setVenueDetails(!venueDetails)}>
                                                            <span>{venue.created_by_name}</span>
                                                        </li>
                                                        <li className="_userCBy TeamAddDetails !w-1/4" onClick={() => setVenueDetails(!venueDetails)}>
                                                            <span>{venue.updated_by_name}</span>
                                                        </li>
                                                        <li className="_userCBy TeamAddDetails !w-1/5">
                                                            <span>
                                                                <Moment format="DD/MM/YYYY h:mm a">{venue.updated_at}</Moment>
                                                            </span>
                                                        </li>
                                                        <li className="_userAction !w-1/20 relative flex" onClick={() => handleSettingsOptions(venue, venue.id)}>
                                                            <span className="userActionIcon" />
                                                        </li>
                                                        {
                                                            gustsSettingsOptions === venue.id &&
                                                            (<ul ref={ref} className="userActionPopup" style={{ position: "absolute", top: "50px" }}>
                                                                <li onClick={() => actionPopUp('edit', venue)}>Edit venue</li>
                                                                <li onClick={() => deleteat(venue)}>Delete venue</li>
                                                            </ul>)
                                                        }
                                                    </ul>
                                                ))
                                            )
                                    }
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {createVenue && <CreateEditVenue type={popUpType} venueSingle={venueSingle} createVenue={createVenue} setVenuesList={setVenuesList} setCreateVenue={setCreateVenue} setVenueDetails={setVenueDetails} />}
            {venueDetails && <UpdateVenue setVenuesList={setVenuesList} actionPopUp={actionPopUp} venueSingle={venueSingle} venueDetails={venueDetails} setVenueDetails={setVenueDetails} />}

        </>

    )
}

export default VenueManager