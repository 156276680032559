import React, { useRef, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import moment from 'moment';

const EODStartEnd = ({ type }) => {
    // Demo states
    const [week_day_from, set_week_day_from] = useState(null);
    const [week_day_to, set_week_day_to] = useState(null);
    const [day_from, set_day_from] = useState(null);
    const [day_to, set_day_to] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [isHoveredStart, setIsHoveredStart] = useState(false);
    const [isHoveredEnd, setIsHoveredEnd] = useState(false);

    const datepickerRefStartDate = useRef(null);
    const datepickerRefEndDate = useRef(null);

    const handleClickDatepickerIconStart = () => {
        datepickerRefStartDate.current.setFocus(true);
    };

    const handleClickDatepickerIconEnd = () => {
        datepickerRefEndDate.current.setFocus(true);
    };

    const handleDateFrom = (date) => {
        if (date && endDate && date > endDate) {
            toast.error('Start date should be smaller than or equal to end date', { duration: 4000 });
            setError1(true);
            setTimeout(() => setError1(false), 4000);
            return;
        }

        setStartDate(date);
        if (type === 'bar') {
            set_week_day_from(date);
        } else {
            set_day_from(date);
        }
    };

    const handleDateTo = (date) => {
        if (date && startDate && date < startDate) {
            toast.error('End date should be greater than or equal to Start date', { duration: 4000 });
            setError2(true);
            setTimeout(() => setError2(false), 4000);
            return;
        }

        setEndDate(date);
        if (type === 'bar') {
            set_week_day_to(date ? moment(date).endOf('day').toDate() : null);
        } else {
            set_day_to(date ? moment(date).endOf('day').toDate() : null);
        }
    };

    const handleBarDateCancel = () => {
        setStartDate(null);
        set_week_day_from(null);
    };

    const years = [...Array(20).keys()].map(i => new Date().getFullYear() - 10 + i);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <>
            <div className='salesProgressBarDate' onMouseEnter={() => setIsHoveredStart(true)} onMouseLeave={() => setIsHoveredStart(false)}>
                <p className='salesProgressBarDateText'>From</p>
                <div className="datePickerCon">
                    {isHoveredStart ? (
                        <MdCancel className='calenderIconDateClose'
                            onClick={() => {
                                handleBarDateCancel();
                            }} />
                    ) : (
                        <BiCalendar className='calenderIconDate' size={22} onClick={handleClickDatepickerIconStart} />
                    )}
                </div>
                <div className='fromDate_Sales'>
                    <DatePicker
                        className={error1 ? "start_Date errorDate fromDate_SalesHover" : "start_Date fromDate_SalesHover"}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Start date"
                        ref={datepickerRefStartDate}
                        maxDate={endDate || new Date()}
                        selected={type === 'bar' ? week_day_from : day_from}
                        onChange={handleDateFrom}
                        renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                            <div className="CalendarDiv">
                                <button name="decreaseMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                <div>
                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <button name="increaseMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className='salesProgressBarDate' onMouseEnter={() => setIsHoveredEnd(true)} onMouseLeave={() => setIsHoveredEnd(false)}>
                <p className='salesProgressBarDateText'>To</p>
                <div className="datePickerCon">
                    {isHoveredEnd ? (
                        <MdCancel className='calenderIconDateClose' onClick={() => handleDateTo(null)} />
                    ) : (
                        <BiCalendar className='calenderIconDate' size={22} onClick={handleClickDatepickerIconEnd} />
                    )}
                </div>
                <div className='fromDate_Sales'>
                    <DatePicker
                        className={error2 ? "start_Date errorDate fromDate_SalesHover" : "start_Date fromDate_SalesHover"}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="End date"
                        ref={datepickerRefEndDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        selected={type === 'bar' ? week_day_to : day_to}
                        onChange={handleDateTo}
                        renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                            <div className="CalendarDiv">
                                <button name="decreaseMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                <div>
                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <button name="increaseMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                            </div>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default EODStartEnd;
