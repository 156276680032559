import React, { useState } from 'react'

function CheckTrusted(props) {
  const [radioval,setRadioVal] = useState('yes');
  const getRadioVal = (event)=>{
    let val = event.target.value;
    setRadioVal(val);
  }
  return (
    <div className='checkTrusted_area'>
    
        <div className="container_selectButton">
            <div className='radioButtons'>
                <label>
                    <input type="radio" name="radio" 
                    value={'yes'}
                    checked={radioval === 'yes'}
                    onChange={(event)=>getRadioVal(event)}
                    />
                    <span>Make this device/browser trusted. </span>
                </label>
                <label>
                    <input type="radio" name="radio"
                    value={'no'}
                    checked={radioval === 'no'}
                    onChange={(event)=>getRadioVal(event)}
                    />
                    {/* <span>Send OTP to my email for every signin with this device/browser(2-step verification). </span> */}
                    <span>Send OTP every time. </span>
                </label>
                
            </div>
            
        </div>
        <div className="sign-in" >						
				<button className="sendButton active" onClick={()=>{localStorage.setItem('isTrusted',radioval); props.afterSave()}}>Continue</button>						
			</div>
    
    </div>
  )
}

export default CheckTrusted