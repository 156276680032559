import React from 'react';
import './barchart.css';
import EODStartEnd from './EODStartEnd';

const SalesBarChart = ({ data, date }) => {

    const demoData = {
        weekDayBreakdown: [
            { day: 'Monday', totalActual: 150, totalEstimate: 120 },
            { day: 'Tuesday', totalActual: 200, totalEstimate: 180 },
            { day: 'Wednesday', totalActual: 300, totalEstimate: 250 },
            { day: 'Thursday', totalActual: 250, totalEstimate: 220 },
            { day: 'Friday', totalActual: 400, totalEstimate: 380 },
            { day: 'Saturday', totalActual: 500, totalEstimate: 450 },
            { day: 'Sunday', totalActual: 350, totalEstimate: 300 },
        ],
        dateRangeTotal: {
            totalActualLabour: 1500,
            totalNetSales: 3000,
        },
    };

    const weekDayBreakdown = data?.weekDayBreakdown || demoData.weekDayBreakdown;

    // Calculate the total for each day
    const totalPerDay = weekDayBreakdown.map(v => v.totalActual + v.totalEstimate);
    const overallMaxTotal = Math.max(...totalPerDay);

    // Define minimum width percentage
    const minWidthPercentage = 8;

    return (
        <>
            <div className='salesProgressBarHead'>
                <p className='salesProgressBarText'>Total sales by month</p>
                <EODStartEnd _date={date} type='bar' />
            </div>

            <div className='barChartHeader'>
                <div className='barChartSalesHead'>
                    <p className='barChartCostText'>
                        {`Labour cost: $${new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Math.round(demoData.dateRangeTotal.totalActualLabour || 0))}`}
                    </p>
                </div>
                <div className='barChartCostHead'>
                    <p className='barChartCostText'>
                        {`Net sales: $${new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Math.round(demoData.dateRangeTotal.totalNetSales || 0))}`}
                    </p>
                </div>
            </div>

            <div className='barChartCon' style={{ marginTop: '30px' }}>
                {weekDayBreakdown.map((v, index) => {
                    const totalWidth = v.totalActual + v.totalEstimate;
                    const normalizedWidth = overallMaxTotal ? (totalWidth / overallMaxTotal) * 100 : 0;

                    // Apply minimum width logic
                    const actualWidth = v.totalActual > 0 ? Math.max((v.totalActual / totalWidth) * normalizedWidth || 0, minWidthPercentage) : 0;
                    const estimateWidth = v.totalEstimate > 0 ? Math.max((v.totalEstimate / totalWidth) * normalizedWidth || 0, minWidthPercentage) : 0;

                    return (
                        <div className='barChartMainCon' key={index}>
                            <div className='barChartDayNameCon'>
                                <p className='barChartDayName'>{v.day}</p>
                            </div>
                            <div className='barChartMainBg'>
                                {actualWidth > 0 && (
                                    <div
                                        className='barChartCost'
                                        style={{
                                            width: `${actualWidth}%`,
                                            background: v.totalActual > 0 ? undefined : '#DDD',
                                        }}
                                    >
                                        <p style={{ textAlign: `${estimateWidth > 0 ? '' : 'center'}` }} className='barChartCostText'>
                                            ${new Intl.NumberFormat('en-US', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }).format(Math.round(v.totalActual) || 0)}
                                        </p>
                                    </div>
                                )}
                                {estimateWidth > 0 && (
                                    <div
                                        className='barChartSales'
                                        style={{
                                            width: `${estimateWidth}%`,
                                            background: v.totalEstimate > 0 ? undefined : '#DDD',
                                            marginLeft: `${actualWidth > 0 ? '-15px' : '0px'}`,
                                        }}
                                    >
                                        <p style={{ textAlign: `${actualWidth > 0 ? '' : 'center'}` }} className='barChartSalesText'>
                                            ${new Intl.NumberFormat('en-US', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }).format(Math.round(v.totalEstimate) || 0)}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SalesBarChart;
