/*eslint-disable react-hooks/exhaustive-deps*/
import React, {useState, useRef, useEffect } from 'react';

// import { Scrollbars } from 'react-custom-scrollbars';
import validator from 'validator';
import classNames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

// import toast, { Toaster } from 'react-hot-toast';
import Summary from './Summary';

import CheckoutForm from './CheckoutForm';
import { GET_STRIPE_CARD } from '../../../Utils/GraphqlQueries';



var stripePromise = null;
const getAmountForStorage = (s) => {
    let data = [0,20, 50, 100, 200, 500, 1000, 2000];
    let price = [0,3.00, 7.00, 14.00, 20.00, 50.00, 100.00, 200.00];
    let index = data.indexOf(s);
    return price[index];
}
function SupportAndBillingPayment(props) {
    const fonts = [{ cssSrc: "https://cacdn02.freeli.io/fonts/SourceSansPro-Regular.ttf" }]

    const [fetchCard, { loading: cardLoading, data: AllCards }] = useLazyQuery(GET_STRIPE_CARD);

    const [loader, setLoader] = useState(true);
    const [btnloader, setbtnLoader] = useState(false);
    const [inputFields,] = useState([]);
    const [inputBorderColor, setInputBorderColor] = useState({ borderColor: 'rgb(230, 230, 230)' });
    const [inputBorderColor2, setInputBorderColor2] = useState({ msg: '', style: { borderColor: 'rgb(230, 230, 230)' } });
    const [inputBorderColor3, setInputBorderColor3] = useState({ msg: '', style: { borderColor: 'rgb(230, 230, 230)' } });
    const [inputBorderColor4, setInputBorderColor4] = useState({ msg: '', style: { borderColor: 'rgb(230, 230, 230)' } });
    const [inputBorderColor5, setInputBorderColor5] = useState({ msg: '', style: { borderColor: 'rgb(230, 230, 230)' } });
    const [inputBorderColor6, setInputBorderColor6] = useState({ borderColor: 'rgb(230, 230, 230)' });
    const [inputBorderColor7, setInputBorderColor7] = useState({ borderColor: 'rgb(230, 230, 230)' });
    const [inputBorderColor8, setInputBorderColor8] = useState({ borderColor: 'rgb(230, 230, 230)' });

    const submitRef = useRef();
    const [billingCardNumber, setbillingCardNumber] = useState('');
    const [billingCardHolderName, setbillingCardHolderName] = useState('');
    const [cardAddressStreet, setcardAddressStreet] = useState('');
    const [cardAddressCity, setcardAddressCity] = useState('');
    const [cardAddressProvince, setcardAddressProvince] = useState('');
    const [billingExpirationDate, setbillingExpirationDate] = useState('');
    const [billingSecurityCode, setbillingSecurityCode] = useState('');
    const [billingCountry, setbillingCountry] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [StripeOptions, setStripeOptions] = useState(null);

 
    const onClickNextHandler = () => {
        //console.log('clicked');
        props.setActiveTab(3);
    }

    const cardPatterns = {
        visa: /^4\d{0,15}$/, // Visa: starts with 4, up to 16 digits
        mastercard: /^5[1-5]\d{0,14}$/, // Mastercard: starts with 51-55, up to 16 digits
        amex: /^3[47]\d{0,13}$/, // American Express: starts with 34 or 37, up to 15 digits
        discover: /^6(?:011|5\d{2})\d{0,12}$/, // Discover: starts with 6011 or 65, up to 16 digits
    };

    const detectCardType = (number) => {
        if (cardPatterns.visa.test(number)) return 'Visa';
        if (cardPatterns.mastercard.test(number)) return 'Mastercard';
        if (cardPatterns.amex.test(number)) return 'American Express';
        if (cardPatterns.discover.test(number)) return 'Discover';
        return null;
    };
    const validateCardNumber = (number) => {
        const sanitizedNumber = number.replace(/\s+/g, '');
        const cardType = detectCardType(sanitizedNumber);
        if (!cardType) return false;

        const isValidLength = (cardType === 'American Express' && sanitizedNumber.length === 15) ||
            (cardType !== 'American Express' && sanitizedNumber.length === 16);
        return isValidLength;
    };
    const validateExpiryDate = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/\d{2}$/; // MM/YY format
        if (!regex.test(date)) return false;

        const [month, year] = date.split('/').map(Number);
        const currentDate = new Date();
        const expirationDate = new Date(`20${year}`, month - 1); // Convert YY to 20YY
        return expirationDate >= currentDate; // Check if the date is in the future
    };
    const validateCvc = (code) => {
        const regex = /^\d{3,4}$/;
        return regex.test(code);
    };


    const handleFormChange = (event, type) => {
        //console.log('handleFormChange',event, type)
        //let data = [...inputFields];

        if (type === 'billingCardNumber') {

            const value = event.target.value.replace(/\s+/g, '').replace(/\D/g, ''); // Filter out non-numeric characters
            const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim(); // Add spaces after every 4 digits


            if (/^\d+$/.test(value.replace(/\s+/g, '')) || value === '' || detectCardType(value)) {
                setInputBorderColor({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' },
                });
            } else {
                setInputBorderColor({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' },
                });
            }

            setbillingCardNumber(formattedValue);
        }
        else if (type === 'billingExpirationDate') {

            let value = event.target.value.replace(/[^\d]/g, ''); // Only allow digits
            if (value.length > 4) value = value.slice(0, 4); // Limit to 4 digits

            if (value.length > 2) {
                value = value.slice(0, 2) + '/' + value.slice(2); // Add slash after MM
            }

            if (validateExpiryDate(value) || value === '') {
                setInputBorderColor6({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' },
                });
            } else {
                setInputBorderColor6({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' },
                });
            }
            setbillingExpirationDate(value)
        }
        else if (type === 'billingCardHolderName') {
            if (validator.isAlpha(event.target.value.charAt(0))) {
                setInputBorderColor3({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' }
                });
            } else {

                setInputBorderColor3({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' }
                });
            }
            setbillingCardHolderName(event.target.value);

        }
        else if (type === 'cardAddressStreet') {
            if (validator.isEmpty(event.target.value)) {

                setInputBorderColor4({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' }
                });
            } else {

                setInputBorderColor4({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' }
                });
            }
            setcardAddressStreet(event.target.value);

        }
        else if (type === 'cardAddressCity') {
            if (validator.isEmpty(event.target.value)) {

                setInputBorderColor5({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' }
                });
            } else {

                setInputBorderColor5({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' }
                });
            }
            setcardAddressCity(event.target.value);


        }
        else if (type === 'cardAddressProvince') {
            if (validator.isEmpty(event.target.value)) {

                setInputBorderColor2({
                    msg: '',
                    style: { borderColor: 'red' }
                });
            } else {

                setInputBorderColor2({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230' }
                });
            }
            setcardAddressProvince(event.target.value);

        }
        else if (type === 'billingSecurityCode') {
            let value = event.target.value.replace(/[^\d]/g, ''); // Only allow digits
            if (value.length > 4) value = value.slice(0, 4); // Limit to 4 digits

            if (validateCvc(value) || value === '') {
                setInputBorderColor7({
                    msg: '',
                    style: { borderColor: 'rgb(230, 230, 230)' },
                });
            } else {
                setInputBorderColor7({
                    msg: '',
                    style: { border: '2px solid #df1b41', boxShadow: 'unset' },
                });
            }
            setbillingSecurityCode(value)
        }
        // else if (type === 'billingCountry') {
        //     if (validator.isEmpty(event.target.value)) {

        //         setInputBorderColor8({
        //             msg: '',
        //             style: { borderColor: 'red' }
        //         });
        //     } else {

        //         setInputBorderColor8({
        //             msg: '',
        //             style: { borderColor: 'rgb(230, 230, 230' }
        //         });
        //     }
        //     setbillingCountry(event.target.value);

        // }

    }


    const onClickHandler = () => {
        debugger;
        var status = true;

        // if (!validateCardNumber(billingCardNumber) || validator.isEmpty(billingCardNumber)) {

        //     setInputBorderColor({
        //         msg: 'Invalid card number',
        //         style: { border: '2px solid #df1b41', boxShadow: 'unset' }
        //     });
        //     status = false;
        // }
        // if (!validateExpiryDate(billingExpirationDate) || validator.isEmpty(billingExpirationDate)) {

        //     setInputBorderColor6({
        //         msg: 'IInvalid expiration date',
        //         style: { border: '2px solid #df1b41', boxShadow: 'unset' }
        //     });
        //     status = false;
        // }

        if (validator.isEmpty(billingCardHolderName)) {
            // toast.error('Card Holder Name should not be empty');
            setInputBorderColor3({
                msg: 'Card Holder Name should not be empty',
                style: { border: '2px solid #df1b41', boxShadow: 'unset' }
            });
            status = false;
        }
        if (validator.isEmpty(cardAddressStreet)) {

            setInputBorderColor4({
                msg: 'Street Address should not be empty',
                style: { border: '2px solid #df1b41', boxShadow: 'unset' }
            });
            status = false;

        }
        if (validator.isEmpty(cardAddressCity)) {

            setInputBorderColor5({
                msg: 'City name should not be empty',
                style: { border: '2px solid #df1b41', boxShadow: 'unset' }
            });
            status = false;

        }


        if (status){
            submitRef.current.click();
            //  props.setActiveTab(5);
        }



    }

    useEffect(() => {
        setLoader(true);
        // console.log('loader:payment',true);
        let amount = 0;
        amount = (amount + getAmountForStorage(Number(props.billingData.choose_plan.storage)));
        amount = ((Number(props.billingData.choose_plan.user) * 4) + amount);
        
        fetchCard({
            variables: {},
            fetchPolicy: 'network-only', // Ensures it fetches fresh data from the server
          }).then((res)=>{
            debugger
            let response = res.data.stripeGetCard;
            
            if(response.Stripe_Publishable_Key){
                stripePromise = loadStripe(response.Stripe_Publishable_Key);
            }
            if(response.client_secret){
                props.setBillingData({
                    ...props.billingData,
                    setupIntents: response.setupIntents
                });
                var stripe_opts = {
                    clientSecret: response.client_secret,
                    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source%20Sans%20Pro'}],
                    appearance: {
                        variables: {
                            fontFamily: 'Source Sans Pro',
                            fontSizeBase	: '16px',
                            fontSizeSm: '16px'
                        },
                        //   theme: 'none',
                        rules: {
                          '.Input': {
                                border: '2px solid var(--p-colorBackgroundDeemphasize10)',
                            },
                            '.Label': {
                                color: '#002e98',
                                fontFamily: 'Source Sans Pro',
                                fontWeight: '600',
                                padding: '0 0 12px 0'
                            }
                        }
                      },
                }
                let theme = localStorage.getItem("theme");
                if (theme === 'dark') {
                    stripe_opts.appearance.rules['.Label'] = {
                        color: '#fff',
                        fontFamily: 'Source Sans Pro',
                        fontWeight: '600',
                        padding: '0 0 12px 0'

                    }
                }
                setStripeOptions(stripe_opts);
            }
            
            if(response.status && response.cardObj.length){
                setbillingCardHolderName(response.cardObj[0].billingCardHolderName);
                setcardAddressStreet(response.cardObj[0].cardAddressStreet);
                setcardAddressCity(response.cardObj[0].cardAddressCity);
                setcardAddressProvince(response.cardObj[0].cardAddressProvince);

                // for(let card of response.cardObj){
                //     card.billingExpirationDate = new Date(card.billingExpirationDate);
                // }
                // setInputFields(response.cardObj);
            }else{
                // setInputFields([{
                //     billingCardID: "",
                //     billingCardNumber:"",
                //     billingCardHolderName:"",
                //     billingExpirationDate: 0,
                //     billingSecurityCode:"",
                //     primaryCardCheckBox: true,
                //     cardAddressCity: "",
                //     cardAddressProvince: "",
                //     cardAddressZip: "",
                //     cardAddressStreet: ""
                // }]);
            }
            setLoader(false);
            // console.log('loader:payment',false);
        });
        // voip_send_msg('stripe_get_card',{total_amount: amount});
     }, []);


    return (

        <>
            {/* {loader ? <div className='btn_loader for_loader'></div>: '' }   */}
            <div className={classNames("supportAndBillingContainer upgradePlan checkout")} >
                <div className='UpgradePlanLeftArea' style={{ borderTop: 'unset', paddingTop: '0px' }}>
                    <div className='PlanLeftAreaInner checkout'>
                        <div className='billingCheckout'>
                            <h3 className='billingAddress font-semibold'>Card holder details for payment</h3>
                        </div>
                        {StripeOptions ? 
                        <Elements stripe={stripePromise} options={StripeOptions} fonts={fonts}>
                            <CheckoutForm
                                handleFormChange={handleFormChange}
                                inputBorderColor={inputBorderColor}
                                inputBorderColor2={inputBorderColor2}
                                inputBorderColor3={inputBorderColor3}
                                inputBorderColor4={inputBorderColor4}
                                inputBorderColor5={inputBorderColor5}
                                inputBorderColor6={inputBorderColor6}
                                inputBorderColor7={inputBorderColor7}
                                inputBorderColor8={inputBorderColor8} 
                                setInputBorderColor8={setInputBorderColor8}
                                billingCardNumber={billingCardNumber}
                                billingCardHolderName={billingCardHolderName}
                                cardAddressStreet={cardAddressStreet}
                                cardAddressCity={cardAddressCity}
                                cardAddressProvince={cardAddressProvince}
                                setcardAddressProvince={setcardAddressProvince}
                                billingExpirationDate={billingExpirationDate}
                                billingSecurityCode={billingSecurityCode}
                                billingCountry={billingCountry}
                                setbillingCountry={setbillingCountry}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                                inputFields={inputFields}
                                submitRef={submitRef}
                                setMainLoader={setLoader}
                                setLoader={setbtnLoader}
                                loader={btnloader}
                                setShow={props.setShow}
                                onClickHandler={onClickHandler}
                                billingData={props.billingData}
                                setBillingData={props.setBillingData}
                                setActiveTab={props.setActiveTab}
                            />
                        </Elements> : ''}

                    </div>

                </div>
                <Summary onClickNextHandler={onClickNextHandler} submitRef={submitRef} billingData={props.billingData} setLoader={setbtnLoader} loader={btnloader} onClickHandler={onClickHandler} btn={'Review'} />
            </div>
        </>
    )
}





export default SupportAndBillingPayment;