import React from 'react';

const MergeWarning = ({ company_name, onCancel, onMerge, company_id }) => {
    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">Merge Confirmation</h4>
                    <span className="closeModal" onClick={onCancel} data-for="rightSection_tooltip" data-tip="Close" currentitem="false"></span>
                </div>
                <div className="freeliModalBody">
                    <p className="delDesc !text-base !text-justify !px-[10px]">
                        Are you sure you want to merge the "{company_name}" company with your current company?
                        If yes, all data, including users, conversations, and messages, will be merged.
                    </p>
                </div>
                <div className="freeliModalFoot">
                    <button className="btnCancel" onClick={onCancel}>No</button>
                    <button className="btnActionConfirm" style={{ backgroundColor: 'rgb(255, 85, 85)' }} onClick={() => onMerge(company_id)}>Yes</button>
                </div>
            </div>
        </div>
    );
};

export default MergeWarning;