import React, { useState, useEffect } from "react";
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MODULE_DELETE } from '../../../Utils/GraphqlMutaion';


const WarningModule = ({ moduleToDelete, setDeleteModulePopup, setModuleToDelete, modules, setModules }) => {
    const [loader, setLoader] = useState(false);
    const [update] = useMutation(MODULE_DELETE);
    // console.log(10, moduleToDelete)
    const confirmDeleteModule = async () => {

        if (moduleToDelete) {
            try {
                setLoader(true);
                const { data: moduleDeleteData } = await update({
                    variables: {company_id: moduleToDelete.company_id, module_title: moduleToDelete.module_title, is_sub_module: false, module_id: moduleToDelete.module_id}
                });
                const { status, message, data } = moduleDeleteData.module_delete;
                if (status) {
                    // console.log(20, data)
                    setModules(modules.filter(module => module.module_id !== moduleToDelete.module_id)); // Remove module
                }
            } catch (error) {
                // Handle error if needed
                console.log(25, error)
            } finally {
                setLoader(false);
            }
            
            setModuleToDelete(null);
            setDeleteModulePopup(false); // Close the popup
        }
    };

    const cancelDeleteModule = () => {
        setModuleToDelete(null);
        setDeleteModulePopup(false); // Close the popup
    };
    return (
        <div className="backwrap">
            <div className="createTeamModal">
                <div className="createTeamModalHead">
                    <h4 className="popupTitle">Delete Module</h4>
                    <span className="closeModal" onClick={cancelDeleteModule}></span>
                </div>
                <div className="createTeamModalBody">
                    <p>Are you sure you want to delete the module "{moduleToDelete?.module_title}"?</p>
                </div>
                <div className='createTeamModalFoot !px-0 gap-3 mr-6'>
                    <button className="btnAction !bg-red-600" onClick={confirmDeleteModule}>Delete Module</button>
                    <button className="btnCancel hover:!border-[#0c1e47] " onClick={cancelDeleteModule}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default WarningModule;