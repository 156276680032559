/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import validator from 'validator'
import Carousel from './Carousel';
import { loginMutation } from '../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import OTPVerification from './OTPVerification'
import axios from "axios";
import DeviceId from '../../Utils/DeviceId';

function Login(props) {
	const params = useParams()
	const deviceId = DeviceId();
	console.log("🚀 ~ Login ~ deviceId:", deviceId)
	const [pageState, setPageState] = useState('initial');
	const [hrefLocation, sethrefLocation] = useState('');
	const [loginInput, { data, loading, error }] = useMutation(loginMutation);
	const [verifiedCode, setVerifiedCode] = useState(null)
	const [locationData, setLocationData] = useState(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const geolocation = async () => {
		return new Promise((resolve, reject) => {
			axios.get("https://api.db-ip.com/v2/free/self")
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	useEffect(() => {
		const fetchLocation = async () => {
			try {
				const data = await geolocation();
				setLocationData(data);
			} catch (err) {
				console.log(err.message || "Something went wrong");
			}
		};

		fetchLocation();
	}, []);

	useEffect(() => {

		if (data) {
			if (!loading) {
				if (data.login.message === 'Please check your email, to verify its you...' || data.login.message === 'Workfreeli signin OTP code send successfully') {
					setPageState('otp_state')
				} else if (data.login.companies.length > 0) {
					setPageState('company_state')
				} else if (data.login.token) {
					localStorage.setItem('token', data.login.token);
					localStorage.setItem('refresh_token', data.login.refresh_token);

					sethrefLocation("/connect")
				}
			}
		}
	}, [loading])


	const [errorCounter] = useState(localStorage.getItem('errorCounter') !== null ? Number(localStorage.getItem('errorCounter')) : 0);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loader, setLoader] = useState(false);
	const [rememberMe, setRememberME] = useState(false);
	const [status, setStatus] = useState(false);
	const [passValidStatus, setPassValidStatus] = useState(false);

	useEffect(() => {
		document.getElementById("email").focus();
		let remember = localStorage.getItem('remember_me');
		let emailRe = localStorage.getItem('remember_email');
		let passwordRe = localStorage.getItem('remember_password');
		if (remember) {
			setEmail(emailRe)
			setPassword(passwordRe)
			localStorage.setItem('remember_me', true)
			localStorage.setItem('remember_email', emailRe)
			localStorage.setItem('remember_password', passwordRe)
			setRememberME(true)
			setPassValidStatus(true);
		}
		localStorage.removeItem('company_users')

		return () => {
		};
	}, [])

	const strongPasswordValidate = (type, target) => {
		// (?=.*[!@#\$%\^&\*]) Special character validation
		// (?=.*[A-Z]) Uppercase validation
		let reg = /^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/;
		let test = reg.test(target.value);
		if (test) {
			if (target.value !== '') {
				target.classList.remove('error');
			}
			setPassValidStatus(true);
		} else {
			setPassValidStatus(false);
		}
	}

	const handleSignin = (company_id = null, code = null) => {
		// alert('ok');
		return new Promise(async (resolve, reject) => {
			try {
				let xmpp_token = localStorage.getItem("xmpp_tokenid")
				let location = { device_type: 'web', device_id: xmpp_token, countryName: locationData.countryName, city: locationData.city, ipAddress: locationData.ipAddress, time: new Date().toLocaleString() }
				setLoader(true);
				let res;
				if (company_id) {
					let inputCustom = { email: email, device_id: xmpp_token, company_id: company_id }
					if (verifiedCode) {
						inputCustom['code'] = verifiedCode
					} else {
						inputCustom['password'] = password
					}
					res = await loginInput({ variables: { input: inputCustom } })

				} else if (code) {
					res = await loginInput({ variables: { input: { email: email, password: password, code: code, ...location } } })

				} else if (props.type === 'otp_with_email') {
					res = await loginInput({ variables: { input: { email: params.email, type: 'signin_with_otp', ...location } } })
				} else if (props.type === 'otp_login') {
					res = await loginInput({ variables: { input: { email: email, type: 'signin_with_otp', ...location } } })
				} else {
					res = await loginInput({ variables: { input: { email: email, password: password, ...location } } })
				}
				if (res.data.login.message.indexOf('Incorrect') > -1 || res.data.login.message.indexOf('not') > -1) {
					toast.error(res.data.login.message);
					resolve(false)
				} else {
					toast.success(res.data.login.message)
					resolve(true)
				}
				setLoader(false);
			} catch (error) {
				setLoader(false);
				console.log(113, error)
				toast.error(error.message);
				reject(error)
			}
		})



	}

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.value.trim();
		const name = target.getAttribute('name');

		if (name === 'email' && validator.isEmail(value)) {
			target.classList.remove('error')
		} else {
			target.classList.add('error')
		}

		if (value === '' || value === ' ') {
			target.classList.remove('error');
		}

		if (name === 'email') {
			setEmail(value);
		} else {
			strongPasswordValidate('password', event.target);
			setPassword(value);
		}
	}

	const handleInputKeyUp = (e) => {
		if (e.keyCode === 13) {
			if (e.target.getAttribute('name') === 'email') {
				if (e.target.value !== '') {
					document.querySelector('#password').focus();
				}
			} else {
				if (e.target.value !== '') {
					if (document.querySelector('.sendButton').classList.contains('active')) {
						handleSignin()
					} else {
						// document.querySelector('.recaptcha-checkbox').focus();
					}
				}
			}
		}
	}

	useEffect(() => {

		if (props.type !== 'otp_login') {
			if (validator.isEmail(email) && passValidStatus) {
				setStatus(true);
			} else {
				setStatus(false);
			}
		} else {
			if (validator.isEmail(email)) {
				setStatus(true);
			} else {
				setStatus(false);
			}
		}
	}, [email, password])

	const tickPrivacyAccept = (e) => {
		setRememberME(!rememberMe)
	}

	const previewPassword = (e) => {
		let target = e.target;

		if (target.classList.contains('active')) {
			target.classList.remove('active');
			document.querySelector('#password').setAttribute('type', 'password');
		} else {
			target.classList.add('active');
			document.querySelector('#password').setAttribute('type', 'text');
		}
	}

	const [theme, setTheme] = useState('light');
	useState(() => {
		let type = localStorage.getItem("theme");
		if (type === 'dark') {
			setTheme('dark');
			document.querySelector('#root').classList.add('dark');
		} else {
			setTheme('light');
			document.querySelector('#root').classList.remove('dark');
		}
	}, [])

	const selectTheme = (type) => {
		localStorage.setItem("theme", type);
		setTheme(type);
		if (type === 'dark') {
			document.querySelector('#root').classList.add('dark');
		} else {
			document.querySelector('#root').classList.remove('dark');
		}
	}
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		if (props.type === 'otp_with_email') {
			setEmail(params.email);
			handleSignin(null, null)
		}
	}, []);
	const handleSubmit = () => {
		setIsSubmitted(true); // Mark form as submitted

		// Prevent submission if fields are empty
		if (email.trim() === '' || password.trim() === '') {
			return; // Prevent form submission if fields are empty
		}
	};
	useEffect(() => {

	}, [email, password])


	if (hrefLocation) {
		return <Navigate to={"/" + hrefLocation} />
	} else {
		return (
			<>
				<div><Toaster position="top-center" /></div>
				<ReactTooltip id="loginTooltip" place="bottom" type="dark" />
				<div className={`login_container ${isMounted ? 'fade-in' : ''}`} style={{ overflow: 'hidden' }}>
					<div className="leftFeb">
						<div className="FebHeader">
							<img src="/media/images/loginBefore/Workfreeli-logo-1.webp" height={60} width={190} alt="Workfreeli" title='img' />
						</div>
						<Carousel />
					</div>
					{pageState === 'initial' && <div className="form_container">
						<div className="loginTheme">
							<span className={classNames("loginThemeModes", theme === 'light' ? 'active' : '')} onClick={() => selectTheme('light')}>Light</span>
							<span className={classNames("loginThemeModes", theme === 'dark' ? 'active' : '')} onClick={() => selectTheme('dark')}>Dark</span>
						</div>
						<form action="/" method="POST" style={{ margin: '0 auto' }}>

							<div className="formHeader" style={{ marginBottom: '18px' }}>Hello! Welcome back.</div>
							<div className="formHeader" style={{ fontSize: '16px', color: '#565656' }}>Sign into your account here</div>

							<div className="loginBody" style={errorCounter >= 3 ? { marginBottom: '25px' } : {}}>
								<div className="form-field email-address">
									<label>Your email</label>
									<span className="emailAt"></span>
									<input aria-labelledby="email" className={isSubmitted && email.trim() === '' ? 'red_border' : ''} type="text" name="email" id="email" value={email} placeholder="youremail@email.com" onChange={handleInputChange} onKeyUp={handleInputKeyUp} autoFocus autoComplete="new-password" required />
									<span className="errorLabel" style={{ position: 'relative' }}>Invalid email address.</span>
									{isSubmitted && email.trim() === '' && (
										<span className="errorLabel" style={{ position: 'relative' }}>Invalid email address.</span>
									)}
								</div>
								{props.type !== 'otp_login' &&
									<div className="form-field password">
										<label htmlFor="password" >Your password</label>
										<span className="passwordLock"></span>
										<span className="passwordView" style={password === '' ? { pointerEvents: 'none' } : {}} onClick={previewPassword} data-for="loginTooltip" data-tip="Click to view the password as plain text"></span>
										<input style={{ marginBottom: '22px' }} className={isSubmitted && password.trim() === '' ? 'red_border' : ''} type="password" name="password" id="password" value={password} placeholder="" onChange={handleInputChange} onKeyUp={handleInputKeyUp} autoComplete="new-password" required />

										{password.length === 0 && <span className="errorLabel" style={{ position: 'relative', top: '-18px' }}></span>}
										{password.length === 0 ? "" :
											passValidStatus ? "" : <span className="errorLabel" style={{ position: 'relative', top: '-18px' }}>Invalid password.</span>
										}
										<span className="passwordPlaceholder" onClick={() => document.querySelector('#password').focus()}>......</span>
										<br />
										<span className="passwordInfoLabel" style={{ position: 'relative', top: '-18px' }}>Minimum 6 Characters, One Lowercase & One Number.</span>
										{isSubmitted && password.trim() === '' && (
											<span className="errorLabel" style={{ position: 'relative', top: '-18px' }}>Invalid password.</span>
										)}

									</div>
								}

								{props.type !== 'otp_login' &&
									<div className="remember-me">
										<button type="button" className={classNames("policyCheck", rememberMe ? 'active' : '')} aria-label="Remember me" name="remember-me" onClick={tickPrivacyAccept}></button>
										<span className="policyText"> Remember me</span>
										<Link className="forgotPass otp" to="/signin_with_otp" title='Sign in with OTP'>Sign in with OTP ?</Link>
										<Link className="forgotPass" to="/forgot-password" title='Forgot your Password ?'>Forgot your Password ?</Link>
									</div>
								}
							</div>

						</form>
						<div className="sign-in" style={{ margin: '0 auto' }}>
							{loader === true ?
								<button name="button" className="sendButton btn_loader"></button>
								:
								!status ? <button name="button" className="sendButton" onClick={handleSubmit}>{props.type === 'otp_login' ? 'Continue' : 'Sign In'}</button> :
									<button name="button" className="sendButton active" onClick={() => handleSignin()}>{props.type === 'otp_login' ? 'Continue' : 'Sign In'}</button>
							}
						</div>
						<div className="sign-up" style={{ margin: '20px auto 0' }}>
							Don't have an account? <Link to="/signUp" title='Sign Up'>Sign Up</Link>
						</div>
						<div className="sign-up" style={{ margin: '20px auto 0' }}>
							<Link to="/" title='Privacy Policy'>Privacy Policy</Link> <span style={{ fontWeight: 'bold', margin: '0 3px' }}>|</span> <Link to="/" title='Contact'>Contact</Link>
						</div>
					</div>}
					{pageState === 'otp_state' && <div className="form_container">
						<div className="loginTheme">
							<span className={classNames("loginThemeModes", theme === 'light' ? 'active' : '')} onClick={() => selectTheme('light')}>Light</span>
							<span className={classNames("loginThemeModes", theme === 'dark' ? 'active' : '')} onClick={() => selectTheme('dark')}>Dark</span>
						</div>
						<OTPVerification
							handleSignin={handleSignin}
							email={email}
							setLoader={setLoader}
							loader={loader}
							error={error}
							setVerifiedCode={setVerifiedCode}
						/>

					</div>}

					{pageState === 'company_state' && <div className="form_container">
						<div className="formHeader">Please select a business account to continue</div>
						<div style={{ width: '400px' }}>
							{loader ? <div className="dot_loader"></div> :
								<div className="companyContainer">
									{data.login.companies.map((v, i) => (
										i === 0 ?
											<button name="companyitem" className="companyItem" key={v.company_id} onClick={() => handleSignin(v.company_id)} autoFocus>{v.company_name}</button>
											:
											<button name="companyitem" className="companyItem" key={v.company_id} onClick={() => handleSignin(v.company_id)}>{v.company_name}</button>
									))}
								</div>
							}
							{!loader && <button name="companyitem" className="companyItem" onClick={() => setPageState('initial')}>Sign out</button>}
						</div>
					</div>}
				</div>
			</>
		)
	}
}


export { Login };
