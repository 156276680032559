
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, ApolloProvider, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getRefreshToken, logout } from './Utils/Common';
import { baseURL, wsbaseURL } from './config/config';
import { onError } from '@apollo/client/link/error';
import { Provider } from 'react-redux'
import { store } from './redux';
import { getMainDefinition } from "@apollo/client/utilities";
// setting configuration for websocket connect for subscription
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import toast from 'react-hot-toast';


const wsLink = new WebSocketLink(
  new SubscriptionClient(wsbaseURL + "/graphql", {
    connectionParams: {
      authToken: `Bearer ${localStorage.getItem("token")}`
    }
  })
);

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: wsbaseURL+"/graphql", // backend link, check backend console for link
//     reconnect:true,
//     connectionParams: {
//       authToken: `Bearer ${localStorage.getItem("token")}`,
//     },
//   })
// );

const httpLink = createHttpLink({
  uri: baseURL + '/workfreeli',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink, // web socket connection for subscriptions
  httpLink // http connection for query and mutation
);

function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const errorLink = onError(async ({ graphQLErrors, networkError }) => {
  console.log(16, graphQLErrors, networkError)
  if (graphQLErrors) {
    if (graphQLErrors[0].message === "Context creation failed: jwt expired") {
      try {
        await getRefreshToken();
        window.location.reload();
      } catch (error) {
        logout();
        console.log(error)
      }
    } else {
      if (isJSONString(graphQLErrors[0].message)) {
        // + JSON.parse(graphQLErrors[0].message).user_id
        console.log(76, JSON.parse(graphQLErrors[0].message));
        window.location.href = window.location.origin + '/connect/' + JSON.parse(graphQLErrors[0].message).user_id;
      } else {
        toast.error(graphQLErrors[0].message);
      }
    }

    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) {
    // localStorage.clear();
    // window.location.href="/login";
    console.error(`[Network error]: ${networkError.data}`);
  }
});


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// Concatenate the authentication link and error link
const combinedLink = ApolloLink.from([authLink, errorLink, splitLink]);
const client = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache()
});
// import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
// const client = new ApolloClient({
//   uri: 'http://localhost:4001/workfreeli',
//   cache: new InMemoryCache(),
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client} >
    <Provider store={store}>
    <App />
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
