import React, { useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';

const DiscussionReplyPopup = ({ setReplyPopup }) => {
    // Initialize a static editorState with some sample content
    const contentState = ContentState.createFromText('This is a sample message/comment.');
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

    const editorRef = useRef(null);

    // Static suggestions for mentions
    const returnSuggestions = () => {
        return [
            { text: 'John Doe', value: 'john_doe' },
            { text: 'Jane Smith', value: 'jane_smith' },
            { text: 'Someone Else', value: 'someone_else' }
        ];
    };

    return (
        <div className="backwrap">
            <div className="customTitleModal">
                <div className="customTitleHead">
                    <h4 className="popupTitle" style={{ marginBottom: '15px' }}>
                        Reply / Query
                    </h4>
                    <span className="closeModal" onClick={() => setReplyPopup(false)}></span>
                </div>
                <div className='px-5'>
                    <Editor
                        toolbarHidden
                        editorRef={(ref) => (editorRef.current = ref)}
                        // editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName={"editorMaintenanceComment"}
                        placeholder={"Write a message/comment"}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: returnSuggestions()
                        }}
                        onEditorStateChange={setEditorState} // Ensure state update on editor change
                    />
                </div>
                <div className="customTitleFoot !px-[25px] !py-6">
                    <button className="btnCancel" onClick={() => setReplyPopup(false)}>
                        Cancel
                    </button>
                    {/* <button className="btnSave">
                        Save
                    </button> */}
                    <button className="saveTitle inactive">Submit</button>
                </div>
            </div>
        </div>
    );
};

export default DiscussionReplyPopup;
