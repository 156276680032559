
import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';

const TaskStatus = () => {
    const { selectedTask, OnUpdateTask, isObserver } = useTaskContext()
    const [status, setStatus] = useState(selectedTask?.status);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        if (selectedTask) {
            setStatus(selectedTask?.status)
        }
    }, [selectedTask])

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
        setDropdownOpen(false);
        OnUpdateTask({ status: newStatus, save_type: 'status' })
        // You can perform any additional actions based on the new status here
    };
    const ref = useClickAway(() => {
        setDropdownOpen(false)
    })
    return (
        <>
            <div className="status_area" style={{ display: 'block', width: '43%', position: 'relative' }}>
                <p className="date_label">Status</p>
                <div className={`dropdown ${isObserver() && 'pointer-events-none'}`} tabIndex="0">
                    <div id="taskStatus"
                        className={`dropdown-header rounded forProgress !text-[#0B1F47] ${dropdownOpen ? 'open' : ''}`}
                        style={status === 'In Progress' ? { backgroundColor: '#D7E1F5' } : status === 'Not Started' ? { backgroundColor: '#ECEEF4' } : status === 'Completed' ? { backgroundColor: '#C6E9D8' } : status === 'On Hold' ? { backgroundColor: '#EEDEC6' } : status === 'Cancelled' ? { backgroundColor: '#EBC8C8' } : { backgroundColor: '#EBC8C8' }}
                        onClick={() => setDropdownOpen(!dropdownOpen)}>
                        {status}
                        <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'right'} icon`} />
                    </div>
                    {dropdownOpen && (
                        <div ref={ref}>
                            <div className="dropdown-body open">
                                <div className={`dropdown-item ${status === 'Not Started' ? 'selected' : ''}`} id="Not Started" onClick={() => handleStatusChange('Not Started')}>
                                    <span className="dropdown-item-dot selected"></span>Not Started
                                </div>
                                <div className={`dropdown-item ${status === 'In Progress' ? 'selected' : ''}`} id="In Progress" onClick={() => handleStatusChange('In Progress')}>
                                    <span className="dropdown-item-dot false"></span>In Progress
                                </div>
                                <div className={`dropdown-item ${status === 'Completed' ? 'selected' : ''}`} id="Completed" onClick={() => handleStatusChange('Completed')}>
                                    <span className="dropdown-item-dot false"></span>Completed
                                </div>
                                <div className={`dropdown-item ${status === 'On Hold' ? 'selected' : ''}`} id="On Hold" onClick={() => handleStatusChange('On Hold')}>
                                    <span className="dropdown-item-dot false"></span>On Hold
                                </div>
                                <div className={`dropdown-item ${status === 'Canceled' ? 'selected' : ''}`} id="Canceled" onClick={() => handleStatusChange('Canceled')}>
                                    <span className="dropdown-item-dot false"></span>Canceled
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Tooltip delayShow={1000} anchorSelect="#taskStatus" place="top" content="Click to update status"></Tooltip>
        </>
    );
};

export default TaskStatus;