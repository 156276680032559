import React, { useEffect, useState } from 'react';
// import { FILE_SHARE_LINK } from '../../Utils/GraphqlQueries';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

const FileHubUrl = (props) => {
    const params = useParams();

    const [Loader, setLoader] = useState(true);
    const [Url, setUrl] = useState("");

    useEffect(() => {
    	// debugger;

        // if (props.match.url === "/file") {
            let data = localStorage.getItem('file_view');
            data = JSON.parse(data);
            setUrl(data.location)
            setLoader(false);
        // } else {
            // sharedFileUrl({url_short_id:props.match.params.id}, (response)=>{
            //     // console.log(10, response.fullpath);
            //     setLoader(false);
            //     setUrl(response.fullpath);
            // });
        // }
        //eslint-disable-next-line
    }, [])
   
    return (
        <div>
            {Loader ? <div className="loaderMain"></div> : <FileDesign url={Url}></FileDesign>}
        </div>
    )
}
const FileDesign = (props) => {
    const [loader,] = useState(false);
    const [exist,] = useState(true);
    const [pageLoad, setPageLoad] = useState(false);
    
    const loadEvent = () => {
        setPageLoad(false)
    }

    return (
        <>
            {pageLoad ? <div className="loaderMain" style={{ height: '100vh' }}></div> : ''}
            {!loader && exist ?
                <div className='fileDiv'>
                    {!props.url ? <span className='middleContent' >This link is no longer valid.</span> : props.url.indexOf('/Photos/') > -1 ?
                        <img src={props.url} alt="File Url" title='File Url' onLoad={(event) => loadEvent(event)} />
                        : props.url.indexOf('/Videos/') > -1 ?
                            <video controls><source src={props.url} type="video/mp4" /></video>
                            : props.url.indexOf('/Audios/') > -1 || props.url.indexOf('/Music/') > -1 ?
                                <audio controls><source src={props.url} type="audio/mpeg" /></audio>
                                : props.url.indexOf('.pdf') > -1 ?
                                    <iframe src={props.url} className='custom' title="1"></iframe>
                                    : props.url.indexOf('.Pdf') > -1 ?
                                        <iframe src={props.url} className='custom' title="2"></iframe>
                                        : props.url.indexOf('.PDF') > -1 ?
                                            <iframe src={props.url} className='custom' title="3"></iframe>
                                            : <span className="download_btn"
                                                onClick={() => window.location.replace(props.url)}>Browser does not support the File. Click here to download.</span>}
                </div>
                : !loader && !exist ? <span className='middleContent' >This link is no longer valid.</span> : ''}

        </>
    )
}

export default FileHubUrl;