/* eslint-disable no-loop-func */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import './ai.css'
import { GET_ME, Get_workai } from '../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { HiArrowSmDown } from "react-icons/hi";
import { TfiClose } from "react-icons/tfi";
import { LiaLongArrowAltLeftSolid } from "react-icons/lia";
import { GoTrash } from "react-icons/go";
import Moment from 'react-moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { Delete_workfreeliai } from '../../Utils/GraphqlMutaion';
import ImageLoader from './ImageLoader';
import AISendBox from './AISendBox';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { set_downloading_file, set_downloading_progress } from '../../redux/message';
import Axios from "axios";
import { useSelector } from 'react-redux';
import Downloading from '../Connect/Messages/Downloading';
const FileDownload = require('js-file-download');

const WorkFreeliAI = () => {
    const dispatch = useDispatch()
    const containerRef = useRef(null);
    const client = useApolloClient();
    const { me: user } = client.readQuery({ query: GET_ME });
    const downloading_progress = useSelector(state => state.message.downloading_progress);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [active_ques, set_active_ques] = useState(null);
    const [all_ques, set_all_ques] = useState([]);
    const [response, setResponse] = useState([]);
    const [initialLoader, setInitialLoader] = useState(false);
    const navigate = useNavigate();
    const [msgscreen, setMsgscreen] = useState(true);
    const [rmsgscreen, setRmsgscreen] = useState(false);
    const [chunk_img_loader, set_chunk_img_loader] = useState(false)
    const [root_id, set_root_id] = useState('');

    const variables = useMemo(() => {
        let param = { root_id: root_id }
        return { ...param };
    }, [root_id]);

    useQuery(Get_workai, {
        variables,
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            console.log("🚀 ~ WorkFreeliAI ~ res:", res.get_workai.result)
            if (variables.root_id === '') {
                set_all_ques(res.get_workai.result)
            } else {
                setResponse(prev => [...prev, ...res.get_workai.result])
            }
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        },
    });

    const get_response = (data) => {
        set_active_ques(data)
        setResponse([data])
        setMsgscreen(false);
        setRmsgscreen(true);
    }

    const handleStream = (chunk, id) => {
        // setResponse2((prev) => prev + chunk); // Append new content
        setResponse((prev) =>
            prev.map((item) =>
                item._id === id ? { ...item, ans: item.ans + chunk } : item
            )
        );

        scrollToBottom();
    };

    const close_ai = () => {
        set_root_id('')
        if (rmsgscreen) {
            set_active_ques(null);
            setMsgscreen(true);
            setRmsgscreen(false);
        } else {
            navigate(-1)
        }
    }

    // Scroll to the bottom with smooth scrolling
    const scrollToBottom = () => {
        if (containerRef.current) {
            setShowScrollButton(false)
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
                // Show button if not at bottom, hide if at the bottom
                setShowScrollButton(scrollTop + clientHeight < scrollHeight - 5); // Adjust threshold if necessary
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function customMarkdownParser(text) {
        // Convert headers
        text = text.replace(/###\s?(.*?)(?=\n|$)/g, '<h3>$1</h3>');

        // Convert bold text
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Replace multiple newlines with a single <br>
        // text = text.replace(/\n\s*\n/g, '<br>'); // For multiple line breaks
        // text = text.replace(/\n/g, ' '); // For single line breaks, replace with space

        return text;
    }
    const [delete_ai_msg] = useMutation(Delete_workfreeliai)
    const handleDelete = async (event, v, type) => {
        event.preventDefault()
        event.stopPropagation()

        let { data } = await delete_ai_msg({
            variables: {
                input: { _id: v._id }
            }
        })
        console.log("🚀 ~ handleDelete ~ apires:", data)
        if (data.delete_workfreeliai.status) {
            if (type === 'q') {
                set_all_ques(prev => prev.filter(f => f._id !== v._id))
            } else {
                setResponse(prev => prev.filter(f => f._id !== v._id))
            }
        }
        toast.success(data.delete_workfreeliai.message)
    }


    function getImageNameFromUrl(url) {
        // Extract the last part of the URL after the last '/'
        const lastSegment = url.split('/').pop();
        // Remove query parameters or fragments
        const imageName = lastSegment.split('?')[0].split('#')[0];
        return imageName;
    }

    const AfileDownload = (url) => {
        let filename = getImageNameFromUrl(url);
        console.log(url, filename);
        dispatch(set_downloading_file([filename]))
        if (downloading_progress[filename]) return
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
                dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
            }
        }).then((response) => {
            FileDownload(response.data, filename);
            let d_progress = { ...downloading_progress };
            d_progress = { ...(delete d_progress[filename] && d_progress) }
            dispatch(set_downloading_progress(d_progress))
        });
    }

    const handleClickEvent = (event, v) => {
        console.log("🚀 ~ handleClickEvent ~ v:", v)
        // Check if the click target is the dynamically generated image wrapper
        if (event.target.classList.contains("genarated_img_wraper")) {
            console.log("Wrapper clicked");
        }

        // Check if the click target is the dynamically generated image
        if (event.target.classList.contains("genarated_img")) {
            console.log("Image clicked");
        }

        if (event.target.classList.contains("download_ai_img")) {
            console.log("Image Download clicked");
            if (v.img_url) {
                AfileDownload(v.img_url);
            }
        }
    };

    return (
        <div className="right_container" style={{ zIndex: "9999" }}>
            <Toaster />
            <div className="ai_header">
                <div className="ai_header_part">
                    <span className="ai_back" data-for="backAi" data-tip="Back" currentitem="false" onClick={close_ai} >
                        <LiaLongArrowAltLeftSolid />
                    </span>
                    <div className="action_list"> WorkFreeli AI </div>
                </div>
                <div className="ai_header_part" data-for="backAiClose" data-tip="Close">
                    <span className="ai_close" onClick={close_ai} ><TfiClose /></span>
                </div>
                {/* <ReactTooltip id={"backAi"} type="dark" place="right" /> */}
                {/* <ReactTooltip id={"backAiClose"} type="dark" place="left" /> */}
            </div>

            <div className="ai_body">
                <div className="qns-response">
                    <div className='response_section' ref={containerRef}>
                        {msgscreen && all_ques?.map((e, i) => (
                            <div className={`ai_main_question`} key={i}>
                                <div className='ai_response' style={{ cursor: 'pointer' }} onClick={() => { set_root_id(e._id); get_response(e) }}>
                                    <div className={`sender_img ${user && user.img.indexOf('img.png') > -1 ? 'nameL' : ''}`}>
                                        {user && user.img.indexOf('img.png') > -1 ?
                                            <span className="nameLetters">{user.fnln}</span>
                                            :
                                            <LazyLoadImage
                                                height='auto'
                                                width='auto'
                                                effect=""
                                                src={user.img}
                                                alt="user"
                                            />
                                        }
                                    </div>
                                    <div className="msg_info">
                                        <div className="sender_info">
                                            <p className="sender_name">{user.firstname + ' ' + user.lastname}</p>
                                            <p className="msg_time !ml-1"> <Moment format="MMM Do, YYYY  h:mm A">{parseInt(e.created_at, 10)}</Moment></p>
                                        </div>
                                        <div className="msg_body msgBodyArea">
                                            <div className="msg_txt 1">{e.ques}</div>
                                            {e.childCount > 0 &&
                                                <div className="divReplySection">
                                                    <div className="msgReplySection">
                                                        <span className="rep_left">View threaded chat ({e.childCount}) </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="ai_msg_hover">
                                        <div className='ai_msg_delete' onClick={(event) => handleDelete(event, e, 'q')}><GoTrash /></div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {!msgscreen && response?.map((e, i) => (
                            <div className='ai_item' key={i}>
                                <div className='ai_response'>
                                    <div className={`sender_img ${user && user.img.indexOf('img.png') > -1 ? 'nameL' : ''}`}>
                                        {user && user.img.indexOf('img.png') > -1 ?
                                            <span className="nameLetters">{user.fnln}</span>
                                            :
                                            <LazyLoadImage
                                                height='auto'
                                                width='auto'
                                                effect=""
                                                src={user.img}
                                                alt="user"
                                            />
                                        }
                                    </div>
                                    <div className="msg_info">
                                        <div className="sender_info">
                                            <p className="sender_name">{user.firstname + ' ' + user.lastname}</p>
                                            <p className="msg_time !ml-1"> <Moment format="MMM Do, YYYY  h:mm A">{parseInt(e.created_at, 10)}</Moment></p>
                                        </div>
                                        <div className="msg_body msgBodyArea">
                                            <div className="msg_txt 1">{e.ques}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='ai_response'>
                                    <div className='gpt_icon'></div>
                                    <div className="msg_info">
                                        <div className="sender_info">
                                            <p className="sender_name">Workfreeli AI</p>
                                            <p className="msg_time !ml-1"> <Moment format="MMM Do, YYYY  h:mm A">{parseInt(e.created_at, 10)}</Moment></p>
                                        </div>
                                        <div className="msg_body msgBodyArea">
                                            {chunk_img_loader && response.length - 1 === i &&
                                                <div className='genarated_img_wraper' style={{ height: '400px', backgroundColor: '#f9f9f9' }}>
                                                    <ImageLoader />
                                                </div>
                                            }

                                            <div className='ai_response_ans' onClick={(ev) => handleClickEvent(ev, e)} dangerouslySetInnerHTML={{ __html: customMarkdownParser(e?.ans?.replace(/```html|```/g, '')?.trim()) }} />
                                        </div>
                                    </div>
                                    {i !== 0 &&
                                        <div className="ai_msg_hover">
                                            <div className='ai_msg_delete' onClick={(event) => handleDelete(event, e, 'a')}><GoTrash /></div>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {(showScrollButton) && (
                        <button onClick={scrollToBottom} className='scroll_button'><HiArrowSmDown /></button>
                    )}
                </div>
                <AISendBox
                    type='ai'
                    ai_props={{
                        user,
                        setResponse,
                        setMsgscreen,
                        setRmsgscreen,
                        scrollToBottom,
                        response,
                        set_active_ques,
                        active_ques,
                        set_all_ques,
                        set_chunk_img_loader,
                        setInitialLoader,
                        initialLoader,
                        handleStream,
                    }}
                />
                {Object.keys(downloading_progress).length !== 0 && <Downloading />}
            </div>
        </div>
    )
};

export default WorkFreeliAI;