import React from 'react';

const ComingSoon = ({ titleText, setComingSoon }) => {
    return (
        <div className="backwrap">
            <div className="shareLinkModal">
                <div className="shareLinkModalHead">
                    <h4 className="popupTitle">{titleText}</h4>
                    <span onClick={() => setComingSoon(false)} className="closeModal"></span>
                </div>
                <div className="shareLinkModalBody">
                    <p className=" text-center pt-10 text-3xl">Coming soon !!</p>
                </div>
                <div className="shareLinkModalFoot" onClick={() => setComingSoon(false)}>
                <button className="btnCancel">Cancel</button>
            </div>
            </div>
        
        </div>
    );
};

export default ComingSoon;