import React, { useEffect, useState, useRef } from 'react'
// import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Toaster } from 'react-hot-toast';
import { FaAngleRight } from "react-icons/fa";
import SubscriptionBillingTab from './SubscriptionBillingTab';
import SubscriptionChoosePlanTab from './SubscriptionChoosePlanTab';
import SupportAndBillingInvoice from './SupportAndBillingInvoice';
import SupportAndBillingPayment from './SupportAndBillingPayment';
import SupportAndBillingReview from './SupportAndBillingReview';
import SupportAndBillingSuccess from './SupportAndBillingSuccess';
import SupportAndBillingHistory from './SupportAndBillingHistory';
import Scrollbars from 'react-custom-scrollbars';



const getAmountForStorage = (s) => {
	let data = [0, 20, 50, 100, 200, 500, 1000, 2000];
	let price = [0, 3.00, 7.00, 14.00, 20.00, 50.00, 100.00, 200.00];
	let index = data.indexOf(s);
	return price[index];
}


function SubscriptionBillingHome(props) {

	const tabs = [
		{ id: 1, label: "Subscription and billing" },
		{ id: 2, label: "Choose plan" },
		{ id: 3, label: "Billing address for invoice/receipt" },
		{ id: 4, label: "Payment" },
		{ id: 5, label: "Review" },
	];

	const [activeTab, setActiveTab] = useState(1);
	const [completedTabs, setCompletedTabs] = useState([1]);
	// Function to handle tab click
	const handleTabClick = (tabId) => {
		if (tabId <= activeTab) { // Allow click only on the current or previous tabs
			setActiveTab(tabId);
			// Mark the current tab and all previous tabs as completed
			setCompletedTabs((prevCompletedTabs) => {
				const newCompletedTabs = [...prevCompletedTabs];
				for (let i = tabId; i <= tabs.length; i++) {
					if (!newCompletedTabs.includes(i)) {
						newCompletedTabs.push(i);
					}
				}
				return newCompletedTabs;
			});
		}
	};

	const [billingData, setBillingData] = useState({
		plan_name: "essential",
		plan_user_limit: "20",
		plan_storage_limit: "245",
		storage: 0,
		active_users: 163,
		inactive_users: 26,
		choose_plan: {
			user: 0,
			storage: 0
		},
		plan_user_limit: 20,
		storage: 0,

		total_amount: 100,
		active_users: 0,
		plan_name: '',
		totalAditionl: 0,
		storage_purchase_history: [],
		payment_info: [],
		plan_storage_limit: 245,
		payment: {
			all_pay: [
				{
					"_id": "67569b9276fee3a1ab9467e7",
					"id": "e95b7efb-9b1a-43f1-801a-0ecb1084293b",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "MahfuzurRahman",
					"transection_id": "sub_1QKeJoDocUNtxmyix3Zm9T4P",
					"payment_by": "cce83797-b863-4dcc-b220-f090e2020247",
					"amount": "100",
					"created_by": "cce83797-b863-4dcc-b220-f090e2020247",
					"plan_name": "essential",
					"plan_user_limit": "20",
					"plan_storage_limit": "245",
					"customer_id": "cus_RD4Ss0vRV2OeN5",
					"additional_storage": "200",
					"transection_date": "2024-12-09T07:26:10.267Z",
					"__v": 0
				},
				{
					"_id": "67321d1609947f389756f95e",
					"id": "a5d5a98e-f908-47e2-8ade-34e6e712064b",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Mahfuz Hossain Sujon",
					"transection_id": "sub_1QJz7IDocUNtxmyiZ7UJMnfL",
					"payment_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"amount": "43",
					"created_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RCNsFgkex2d5yr",
					"additional_storage": "20",
					"transection_date": "2024-11-11T15:04:54.445Z",
					"__v": 0
				},
				{
					"_id": "6729fc21f46ea1e4dde9736d",
					"id": "9e413dd7-1952-4677-8fb4-5180c66de46a",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Md. Sadequr ssRahman",
					"transection_id": "sub_1QHkaKDocUNtxmyi1hZUnf4O",
					"payment_by": "f0e60056-a52b-432a-be60-f71b81e4d356",
					"amount": "43",
					"created_by": "f0e60056-a52b-432a-be60-f71b81e4d356",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RA4iTF4PwtvxKb",
					"additional_storage": "20",
					"transection_date": "2024-11-05T11:06:09.551Z",
					"__v": 0
				},
				{
					"_id": "67321c6d09947f389756f94f",
					"id": "5b4ad962-f20f-4d92-8766-2d1d2634b042",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Mahfuz Hossain Sujon",
					"transection_id": "sub_1QJz7IDocUNtxmyiZ7UJMnfL",
					"payment_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"amount": "43",
					"created_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RCNsFgkex2d5yr",
					"additional_storage": "20",
					"transection_date": "2024-11-11T15:02:05.602Z",
					"__v": 0
				},
				{
					"_id": "6734873713144474ad285959",
					"id": "402609b2-7777-46ed-ab1a-6131a260ad82",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Dalim Chowdhury",
					"transection_id": "sub_1QKeJoDocUNtxmyix3Zm9T4P",
					"payment_by": "9180bce6-6d87-44bd-916b-32dc880a891f",
					"amount": "43",
					"created_by": "9180bce6-6d87-44bd-916b-32dc880a891f",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RD4Ss0vRV2OeN5",
					"additional_storage": "20",
					"transection_date": "2024-11-13T11:02:15.239Z",
					"__v": 0
				},
				{
					"_id": "673486f113144474ad2858cb",
					"id": "0cb9d9fb-5dd4-49ab-97da-9e9091f9241a",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Mahfuz Hossain Sujon",
					"transection_id": "sub_1QKeJoDocUNtxmyix3Zm9T4P",
					"payment_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"amount": "43",
					"created_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RD4Ss0vRV2OeN5",
					"additional_storage": "20",
					"transection_date": "2024-11-13T11:01:05.772Z",
					"__v": 0
				},
				{
					"_id": "67321c4509947f389756f940",
					"id": "08d74f63-3150-492a-b626-84ffd26f8fb4",
					"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
					"card_no": "4242",
					"card_holder_name": "Mahfuz Hossain Sujon",
					"transection_id": "sub_1QJz7IDocUNtxmyiZ7UJMnfL",
					"payment_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"amount": "43",
					"created_by": "62adaa8c-6686-428d-b958-1fe75b946222",
					"plan_name": "essential",
					"plan_user_limit": "10",
					"plan_storage_limit": "45",
					"customer_id": "cus_RCNsFgkex2d5yr",
					"additional_storage": "20",
					"transection_date": "2024-11-11T15:01:25.601Z",
					"__v": 0
				}
			],
			storage_purchase_history: [
				{
					"transection_date": "2024-12-09T07:26:10.267Z",
					"plan_storage_limit": "245",
					"additional_storage": 200
				},
				{
					"transection_date": "2024-11-11T15:04:54.445Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				},
				{
					"transection_date": "2024-11-05T11:06:09.551Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				},
				{
					"transection_date": "2024-11-11T15:02:05.602Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				},
				{
					"transection_date": "2024-11-13T11:02:15.239Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				},
				{
					"transection_date": "2024-11-13T11:01:05.772Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				},
				{
					"transection_date": "2024-11-11T15:01:25.601Z",
					"plan_storage_limit": "45",
					"additional_storage": 20
				}
			],
			cardObj: {
				"exp_year": 2025,
				"exp_month": 12,
				"last4": "4242",
				"brand": "visa",
				"fullname": "test",
				"amount": "100"
			}
		},
		company: {
			"_id": "64788c9c76797327afbb7583",
			"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
			"company_name": "ITL Dev",
			"created_at": "2022-10-26T12:40:15.639Z",
			"updated_at": "2022-10-26T12:40:15.639Z",
			"created_by": "f4051cb4-2fce-4cb0-b397-b622cb3d68b4",
			"updated_by": "",
			"class": [],
			"campus": [],
			"section": [],
			"company_img": "",
			"industry": "",
			"domain_name": "@gmail.com",
			"plan_name": "essential",
			"plan_user_limit": "20",
			"plan_storage_limit": "245",
			"is_deactivate": 0,
			"plan_access": [],
			"plan_id": "",
			"subscription_id": "sub_1QKeJoDocUNtxmyix3Zm9T4P",
			"product_id": "prod_RD4STbgmZcxqno",
			"price_id": "",
			"__v": 0,
			"customer_id": "cus_RD4Ss0vRV2OeN5",
			"updatedAt": "2024-12-03T15:19:27.957Z"
		},
		address: {
			"_id": "6757fc4b76fee3a1ab9497e3",
			"id": "6810037e-c572-4414-a7d9-29c23deb4d3c",
			"company_id": "57620630-552b-11ed-bc02-3d08415c9794",
			"first_name": "mahfuz",
			"last_name": "hossain",
			"email": "sujon.xpress@gmail.com",
			"street_address": "767",
			"apt_number": "",
			"province": "",
			"zip": "1207",
			"created_by": "f0e60056-a52b-432a-be60-f71b81e4d356",
			"updated_at": "2024-12-10T08:31:07.863Z",
			"updated_by": "cce83797-b863-4dcc-b220-f090e2020247",
			"country": "Bangladesh",
			"company": "ascent",
			"city": "dhaka",
			"created_at": "2024-12-10T08:31:07.863Z",
			"__v": 0
		}
	})
	const [totalAditionl, setTotalAditional] = useState(0)
	const totalAmount = () => {
		let amount = 0;
		amount = (amount + getAmountForStorage(Number(billingData.choose_plan.storage)));
		amount = ((Number(billingData.choose_plan.user) * 4) + amount);
		let total_additional_storage = 0;
		for (let t of billingData.payment.storage_purchase_history) {
			amount = (amount + getAmountForStorage(Number(t.additional_storage)));
			total_additional_storage = total_additional_storage + Number(t.additional_storage)
		}
		// //console.log(64,amount)
		// setTotal(amount.toFixed(2));

		setTotalAditional(billingData.payment.storage_purchase_history.length > 0 ? billingData.payment.storage_purchase_history[0].additional_storage : 0);
	}
	useEffect(() => {
		if (billingData.choose_plan !== undefined) {

			totalAmount();
		}
	}, [billingData])


	// const [bstyle, setBStyle] = useState({ display: 'none' });
	// const BussinessTooltip = () => {
	// 	return (
	// 		<span style={{ cursor: 'pointer' }} onMouseEnter={e => { setBStyle({ display: 'block' }); }} onMouseLeave={e => { setBStyle({ display: 'none' }); }} className="tooltip user_Management_title"><span className="info_tooltip_new"></span>
	// 			<span style={bstyle} className="tooltiptext forInBussinessAccount" >
	// 				<p>
	// 					For the Workfreeli application, a business account provides teams and organizations with a dedicated space to collaborate, manage projects, and streamline communication. It includes enhanced features like file sharing, task management, real-time messaging, and secure storage, allowing businesses to centralize workflows and improve productivity. Designed for both small and large teams, a Workfreeli business account supports seamless collaboration with tools for organizing workspaces, setting permissions, and tracking project progress, making it ideal for efficient team management and coordinated project execution.
	// 				</p>
	// 				<span onClick={e => { setBStyle({ display: 'none' }); }} className='tooltipClose'></span>
	// 			</span>
	// 		</span>
	// 	)
	// }

	return (
		<>
			<Toaster />
			<div className="right_container admin_right">
				<div className="adminRightHead">
					<div className="flex  gap-[5px] items-center space-x-2 text-sm">
						{tabs.map((tab, index) => (
							<div key={tab.id} className="flex gap-[5px] items-center !ml-0">
								<button
									onClick={() => handleTabClick(tab.id)}
									className={`subScripttionTab ${activeTab >= tab.id
										? "font-bold text-[#0c1e47] cursor-pointer"
										: "font-bold text-[#9BA5BC] cursor-not-allowed"
										} focus:outline-none`}
									disabled={tab.id > activeTab} // Disable forward tabs
								>
									{tab.label}
								</button>
								{/* Arrow Separator */}
								{index < tabs.length - 1 && (
									<span className="font-semibold text-[#9ba5bc]">
										<FaAngleRight className="text-[#9ba5bc]" size={16} />
									</span>
								)}
							</div>
						))}
					</div>

				</div>

				<div className="usersTableContainer !p-0 !h-screen overflow-y-auto">
					<Scrollbars autoHide className="scrollBarClass">
						{activeTab === 1 && (
							<SubscriptionBillingTab activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />
						)}
						{activeTab === 2 &&
							<SubscriptionChoosePlanTab activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />
						}
						{activeTab === 3 && (
							<SupportAndBillingInvoice activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />
						)}
						{activeTab === 4 && <SupportAndBillingPayment activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />}
						{activeTab === 5 && <SupportAndBillingReview activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />}
						{activeTab === 6 && <SupportAndBillingSuccess activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />}
						{activeTab === 7 && <SupportAndBillingHistory activeTab={activeTab} setActiveTab={setActiveTab} billingData={billingData} setBillingData={setBillingData} />}
					</Scrollbars>
				</div>
			</div>


		</>
	)
}




export default SubscriptionBillingHome;