import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { MdOutlineCancel } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';

const TaskNotesPopup = ({ setTaskNotesPopup }) => {
  const { OnUpdateTask, selectedTask } = useTaskContext()
  // const { OnUpdateTask, selectedTask, user } = useTaskContext()
  const textareaRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(selectedTask?.notes);

  useEffect(() => {
    setNotes(selectedTask?.notes);
  }, [selectedTask]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    const focusTextarea = () => {
      if (isEditing) {
        textareaRef.current.focus();
      }
    };
    const timeoutId = setTimeout(focusTextarea, 0);
    return () => clearTimeout(timeoutId);
  }, [isEditing]);

  useEffect(() => {
    if (textareaRef.current) {
      const textLength = notes.length;
      textareaRef.current.selectionStart = textLength;
      textareaRef.current.selectionEnd = textLength;
      textareaRef.current.focus();
    }
  }, [notes]);

  const handleBlur = () => {
    if (isEditing) {
      OnUpdateTask({ notes: notes?.trim(), save_type: 'note' });
    }
  };
     const handleNoteChange = (e) => {
            const { value } = e.target;
          
            // If the value is empty or the user is clearing the input (backspace case)
            if (value === '') {
              setNotes(value); // Allow clearing the input
              return;
            }
          
            // Validate the input
            if (validFirstChercterAlsoNuber(value)) {
              console.warn('Invalid input for title'); // Log or show an error message
              return;
            }
          
            // Trim spaces from the start of the value
            const trimmedValue = value.trimStart();
          
            // If the title is empty or contains only spaces, prevent further updates
            if (!trimmedValue) {
              console.warn('Title cannot be empty or contain only spaces');
              return;
            }
          
            // Update state with the validated value
            setNotes(trimmedValue);
          };

  return (
    <div className="trackCostPopUpCon">
      <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
        <div className="backToMainProperties">
          <span onClick={() => setTaskNotesPopup(false)} className="closeAdminPanel mt-2" data-for="descloseTooltip" data-tip="Back"></span>
          <span className="backtoProperties">Your notes</span>
        </div>
      </div>
      <div className={classNames('Track_body bodyArea mt-3')} onClick={handleEditClick}>
        <div className="middle_area" style={{ height: 'calc(100vh - 65px)', padding: '20px', paddingTop: '0' }}>
          <div className={`yourNoteIndex overlay-container ${isEditing ? 'editing' : ''}`}>
            {!isEditing && <div className="edit-icon">Click to update notes</div>}
            <textarea
              ref={textareaRef}
              className="youtnotesTextArea forNoteAndDes"
              value={notes}
              onChange={handleNoteChange}
              onBlur={handleBlur}
              rows={Math.max(notes?.split('\n')?.length, 20)}
              placeholder="Add a note"
              readOnly={!isEditing}
            ></textarea>
            <div className="voiceIconForNotes" data-for="top_head" data-tip="Click voice to text. 'send', => message send, 'clear' => clear text, 'Enter' => new line"></div>
            {notes !== '' ? <MdOutlineCancel className="absolute right-0 top-[45%] text-red-600 text-xl" onClick={() => setNotes('')} /> : ''}
            {!isEditing && <div className="overlay"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskNotesPopup;
