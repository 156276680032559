import React, { useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import { TbArrowBackUp } from 'react-icons/tb';
import { MdOutlineCancel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useTaskContext } from '../TaskContext';
import sortArrayByKey from '../../../hooks/useSortedArray';

const ProjectFilter = ({ setProjectFilterPopup }) => {
    const all_projects = useSelector(state => state.task.all_projects)
    const { fetchByFilter, filter, set_filter, setPagination, set_column_id } = useTaskContext()
    const [selected_projects, set_selected_projects] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleChange = (v) => {
        set_selected_projects(prev => prev.includes(v._id) ? prev.filter(f => f !== v._id) : [...prev, v._id]);
    };

    const handleSelectAllChange = () => {
        set_selected_projects(selected_projects.length === all_projects.length ? [] : all_projects.map(m => m._id));
    };

    const view_filter = () => {
        let f_array = [...selected_projects]; // get array of ids
        f_array.unshift('projects'); // modify f_array to add 'keywords' at the beginning
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(prev => [...prev, f_array]); // spread and update the task_filter
        fetchByFilter([...filter, f_array]);
        setProjectFilterPopup(false)
    }

    const filteredProjects = all_projects.filter(f => f.project_title.toLowerCase().includes(searchQuery.toLowerCase()));
    const sorted_data = sortArrayByKey(filteredProjects, 'project_title', 'asc');
    const ref = useClickAway(() => {
        setProjectFilterPopup(false);
    });

    return (
        <div ref={ref}>
            <div className='tagRelatedPopup forKeywords !z-[9999] !w-[560px]'>
                <div className='tagToggle_sidebar'>
                    <div className="tagToggle_sidebar_head justify-between !py-6 !px-7">
                        <div className='flex items-center gap-2'>
                            <TbArrowBackUp className='text-xl text-[#318FFF] cursor-pointer' onClick={() => setProjectFilterPopup(false)} />
                            <div className="tagsText !text-[#2c56ac] !text-sm">Projects</div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className="searchareaDiv relative">
                                <input
                                    id='popupserachFilter'
                                    className="_inputBar searchLeft relative"
                                    type="text"
                                    placeholder="Search project"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {searchQuery.length > 0 ?
                                    <MdOutlineCancel onClick={() => setSearchQuery("")} className=' cursor-pointer !right-2 absolute text-red-600 top-2 text-xl' /> : <div className="srcSectionPopup !right-2" data-for="top_head" data-tip="Search"></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="selectAllArea !py-4 !px-7">
                        <div className="checkboxes__item">
                            <label className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={selected_projects.length === filteredProjects.length}
                                    onChange={handleSelectAllChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Select all</div>
                            </label>
                        </div>
                    </div>
                    <div className="allTagsArea">
                        <ul className="alltagList !h-[calc(100vh-140px)]">
                            {sorted_data.map(v => (
                                <li key={v._id} className='!py-4 !px-7'>
                                    <div className="tagsRelateLists">
                                        <div className="checkboxes__item">
                                            <label className="checkbox style-c">
                                                <input
                                                    type="checkbox"
                                                    checked={selected_projects.includes(v._id)}
                                                    onChange={() => handleChange(v)}
                                                />
                                                <div className="checkbox__checkmark"></div>
                                                <div className="checkbox__body">{v.project_title}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tagCoupleBtn !bottom-5">
                        <div className="skipButton" onClick={() => setProjectFilterPopup(false)}>Skip</div>
                        <div className="skipButton !w-[80px]" onClick={() => set_selected_projects([])}>Reset</div>
                        <div className="viewFilesButton !w-[75px]" onClick={view_filter}>View</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectFilter;
