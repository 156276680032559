/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { ARCHIVE_MUTATION, CLOSE_MUTATION, PIN_UNPIN_MUTATION, READ_ALL_MUTATION, sendMsgMutation } from '../../../Utils/GraphqlMutaion';
import { aesEncrypt, modifyMyMsg, scrollToBottom } from '../../../Utils/Common';
import { useDispatch, useSelector } from 'react-redux';
import { closeConversation, set_messages, set_pin_unpin, updateArchiveConv } from '../../../redux/message';
import { useEffect } from 'react';
import ClickAwayListener from "react-click-away-listener";

const ChatRoomMoreOptions = ({ setRoomMoreOptions, me, room }) => {
    const params = useParams()
    const navigate = useNavigate()
    let { conversation_id } = params;
    const dispatch = useDispatch()
    const messages = useSelector(state => state.message.msgs)
    const active_conv = useSelector(state => state.message.active_conversation)
    const [send_msg_input,] = useMutation(sendMsgMutation);

    const handleSendMsg = (msg_body) => {
        return new Promise(async (resolve, reject) => {
            try {
                let send_msg_data = await send_msg_input({
                    variables: {
                        input: {
                            conversation_id: conversation_id,
                            company_id: me.company_id,
                            sender: me.id,
                            msg_body: aesEncrypt(msg_body),
                            participants: active_conv?.participants,
                            is_reply_msg: 'no',
                            reply_for_msgid: ''
                        }
                    }
                }
                );
                let dycrepetMsg = await modifyMyMsg([send_msg_data.data.send_msg.msg])
                dispatch(set_messages({ data: [...messages, ...dycrepetMsg], is_reply_msg: 'no' }))
                setTimeout(() => {
                    scrollToBottom('main_msg_scroll_div')
                }, 300);
                resolve(true)
            } catch (error) {
                toast.error(error.message);
                reject(error)
            }
        })
    }

    const shareLocationLink = async () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        function success(pos) {
            let crd = pos.coords;
            let msg_body = '<a href="https://www.google.com/maps/search/?api=1&query=' + crd.latitude + ',' + crd.longitude + '" target="_blank">Current location </a>'
            handleSendMsg(msg_body)
            setRoomMoreOptions(false)
        }

        function errors(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }
        if (navigator.permissions && navigator.permissions.query) {
            if (navigator.geolocation) {
                navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                    if (result.state === "granted") {
                        // alert('Location permission Allowed Successfully')
                        // console.log (result.state);
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                        // setpermissionPopup(true);
                        navigator.geolocation.getCurrentPosition(success, errors, options);

                    } else if (result.state === "denied") {
                        // setpermissionPopup(true);
                        // console.log (result.state);
                        // console.log ('Geolocation is not enabled');
                        //If denied then you have to show instructions to enable location
                    }
                    result.onchange = function () {
                        // console.log (result.state);
                    };
                });
            } else {
                // props.setPopup({ type: 'headmorepop', data: false });
                toast.error("Sorry Location service Not available!");
            }
        }
    }

    const [pin_unpin, { data: pin_unpin_res }] = useMutation(PIN_UNPIN_MUTATION);
    useEffect(() => {
        if (pin_unpin_res) {
            if (pin_unpin_res.pin_unpin.status) {
                dispatch(set_pin_unpin({
                    conversation_id: conversation_id,
                    action: active_conv?.pin.indexOf(me.id) > -1 ? 'unpin' : 'pin',
                    user_id: me.id
                }))
            }
        }
    }, [pin_unpin_res])

    const handlePinUnpin = () => {
        try {
            pin_unpin({
                variables: {
                    input: {
                        conversation_id: conversation_id,
                        action: active_conv?.pin.indexOf(me.id) > -1 ? 'unpin' : 'pin'
                    }
                }
            })
            setRoomMoreOptions(false)
        } catch (error) {
            console.log("🚀 ~ handlePinUnpin ~ error:", error)
        }
    }

    const [read_all, { data: read_res }] = useMutation(READ_ALL_MUTATION);
    console.log("🚀 ~ ChatRoomMoreOptions ~ read_res:", read_res)
    const handleReadAll = () => {
        try {
            read_all({ variables: { input: { conversation_id: conversation_id } } })
            setRoomMoreOptions(false)
        } catch (error) {
            console.log("🚀 ~ handleReadAll ~ error:", error)
        }
    }

    const [room_archive, { data: archive_res }] = useMutation(ARCHIVE_MUTATION);
    useEffect(() => {
        if (archive_res) {
            if (archive_res.room_archive.status) {
                dispatch(updateArchiveConv({
                    conversation_id: conversation_id,
                    archive: active_conv?.archive === 'yes' ? 'no' : 'yes'
                }))
            }
        }
    }, [archive_res])
    const handleArchive = () => {
        try {
            room_archive({
                variables: {
                    input: {
                        conversation_id: conversation_id,
                        action: active_conv?.archive === 'yes' ? 'no' : 'yes'
                    }
                }
            })
            setRoomMoreOptions(false)
        } catch (error) {
            console.log(error);
        }
    }
    const [room_close, { data: close_res }] = useMutation(CLOSE_MUTATION);
    useEffect(() => {
        if (close_res) {
            if (close_res.close_room.status) {
                dispatch(closeConversation({
                    conversation_id: conversation_id,
                    close_room: active_conv?.archive === 'yes' ? 'no' : 'yes'
                }))
            }
        }
    }, [close_res])
    const handleRoomClose = () => {
        try {
            room_close({
                variables: {
                    input: {
                        conversation_id: conversation_id,
                        action: active_conv?.close_for === 'yes' ? 'no' : 'yes'
                    }
                }
            })
            setRoomMoreOptions(false)
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickAway = () => {
        setRoomMoreOptions(false);
    };

    return (
       <ClickAwayListener onClickAway={handleClickAway}>
            {/* <ul>
                {(active_conv?.has_mute && active_conv?.has_mute.indexOf(me.id) === -1) && <li className="moreOpt_list _muteConv" onClick={() => navigate('mute_conversation')}>Mute this room</li>}

                {active_conv?.pin.indexOf(me.id) === -1 && <li className="moreOpt_list _pingConv" onClick={handlePinUnpin}>{active_conv?.pin.indexOf(me.id) > -1 ? 'Unpin' : 'Pin'} this room</li>}

                <li className="optionalList moreOpt_list mark" onClick={handleReadAll}>Mark all as read in this room</li>

                <li className="optionalList moreOpt_list location" onClick={shareLocationLink}>Share location</li>

                {active_conv.group === 'yes' && active_conv.participants_admin.indexOf(me.id) > -1 && <li className="optionalList moreOpt_list lock" onClick={handleRoomClose}>{active_conv.close_for === 'yes' ? 'Unlock' : 'Lock'} this room</li>}
                {active_conv.group === 'yes' && active_conv.participants_admin.indexOf(me.id) > -1 && <li className="optionalList moreOpt_list moveIcon" onClick={handleArchive}>{active_conv.archive === 'yes' ? 'Restore' : 'Archive'} this room</li>}
            </ul> */}
            <div className="tooltipMore w-[200px] z-50 right-[30px] top-[84px] mt-2 bg-white border border-gray-200 rounded-lg">
                <p className='text-xs text-[#5b6477] p-5 !pb-1'>More options</p>
                <ul className="text-gray-700 pb-2">

                    {(active_conv?.has_mute && active_conv?.has_mute.indexOf(me.id) === -1) && <li className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer" onClick={() => navigate('mute_conversation')}>Mute room</li>}

                    {active_conv?.pin.indexOf(me.id) === -1 && <li className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer" onClick={handlePinUnpin}>{active_conv?.pin.indexOf(me.id) > -1 ? 'Unpin' : 'Pin'} this room</li>}

                    <li onClick={handleReadAll} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Mark all read
                    </li>
                    <li onClick={shareLocationLink} className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer">
                        Share location
                    </li>
                    {active_conv.group === 'yes' && active_conv.participants_admin.indexOf(me.id) > -1 && <li className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer" onClick={handleRoomClose}>{active_conv.close_for === 'yes' ? 'Unlock' : 'Lock'} this room</li>}

                    {active_conv.group === 'yes' && active_conv.participants_admin.indexOf(me.id) > -1 && <li className="px-5 py-1 text-sm hover:bg-blue-50 cursor-pointer" onClick={handleArchive}>{active_conv.archive === 'yes' ? 'Restore' : 'Archive'} this room</li>}
                </ul>
            </div>
        </ClickAwayListener>
    );
};

export default ChatRoomMoreOptions;