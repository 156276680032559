/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { update_messages,setPopup, set_downloading_progress, set_downloading_file, set_share_link_popup, set_action_file } from "../../../redux/message";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { doesFileExist } from '../../Utils/API';
const FileDownload = require('js-file-download');

function ImgPreviewPopup({ setPreview_data, active_file, all_file, user }) {
	const dispatch = useDispatch();
	const [activeAttachment, setActiveAttachment] = useState(active_file); console.log('activeAttachment',activeAttachment);
	const [data, setData] = useState(all_file);
	const downloading_progress = useSelector(state => state.message.downloading_progress);

	const [prev, setPrev] = useState(false);
	const [next, setNext] = useState(false);
	const getFile = async () => {
		// setLoader(true)
		// try {
		// 	await doesFileExist(active_file.location);
		// 	setLoader(false)
		// 	setExist(true)
		// } catch (error) {
		// 	setExist(false)
		// 	setLoader(false);
		// }
	}

	const slideImg = (type) => {
		let newData = [];
		let nextI;
		for (const [i, value] of [...data].entries()) {
			if (value.id === activeAttachment.id) {
				if (type === 'next') {
					nextI = i + 1;
				} else {
					nextI = i - 1;
				}

			}
			if (i === nextI) {
				setActiveAttachment(value);
			}
			newData.push(value);
		}
		if (type === 'prev') {
			setActiveAttachment(newData[nextI]);
		}

		setData([...newData]);
		document.getElementById("resetZoom").click()
	}


	const checkPrev = () => {
		if (data.length > 0) {
			let prevT = false;
			let nextT = false;
			for (const [i, value] of [...data].entries()) {
				if (activeAttachment.id === value.id) {
					if (i === 0) {
						nextT = true;
					} else {
						prevT = true;
						nextT = true;
					}
					if (i === (data.length - 1)) {
						nextT = false;
						if (i !== 0) {
							prevT = true;
						}
					}

				}

			}
			setPrev(prevT);
			setNext(nextT);
		}
	}

	useEffect(() => {
		// console.log (63,activeAttachment)

		if (activeAttachment.user_name === undefined) {
			// props.logindata.all_users.map((u) => {
			// 	if (activeAttachment.user_id === u.id) {
			// 		setActiveAttachment({ ...activeAttachment, user_name: u.firstname + ' ' + u.lastname })
			// 		// activeAttachment['user_name'] = u.firstname+' '+u.lastname;

			// 	}
			// 	return false
			// })
		}

		checkPrev();
		getFile();
	}, [activeAttachment])

	function niceBytes(x) {
		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let l = 0, n = parseInt(x, 10) || 0;
		while (n >= 1024 && ++l) {
			n = n / 1024;
		}
		return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
	}

	let [url_loading, setUrlLoading] = useState(true)
	const onComplete = () => {
		setUrlLoading(false)
	}

	const AfileDownload = (url, filename) => {
        dispatch(set_downloading_file([filename]))
        if (downloading_progress[filename]) return
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
                dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
            }
        }).then((response) => {
            FileDownload(response.data, filename);
            let d_progress = { ...downloading_progress };
            d_progress = { ...(delete d_progress[filename] && d_progress) }
            dispatch(set_downloading_progress(d_progress))
        });
    }

	// const fileTag = (activeAttachment) => {

	// 	// console.log (68,activeAttachment)
	// 	// if (props.popup.tagPopup) {
	// 	// 	props.set_popup_action_data({});
	// 	// 	props.setPopup({ type: 'tagPopup', data: false });
	// 	// } else {
	// 	// 	props.set_popup_action_data(activeAttachment);
	// 	// 	props.setPopup({ type: 'tagPopup', data: true });
	// 	// }
	// }
	   const fileTag = (type, _f) => {
			dispatch(set_action_file(_f))
			if (type === 'tag') {
					dispatch(setPopup({ data: true, key: 'tag_popup' }));
				}else{
					console.log("tagPopu", _f);
				}
			
		}

		

	document.onkeydown = function (event) {
		if (event.keyCode === 27) {
			
			dispatch(setPopup({ data: false, key: 'tag_popup' }));

		}
	}

	const close_popup = () => {
		setPreview_data(null)
	}

	
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
				close_popup();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

	return (
		<>
			<div className="previewModal">
				<div className="previewModalHead">
					<span className="backToChat" onClick={close_popup} ></span>
					<div className="previewHeadLabel" style={{ display: 'flex' }}>
						<div className="previewTitle">
							<h2 className="createConv_title">{'Chat'}</h2>
						</div>
						<div className="previewInfo">
							<p className="nameInfo">{activeAttachment.originalname} ({niceBytes(activeAttachment.file_size)})</p>
							<p className="dateInfo">Uploaded by {activeAttachment.uploaded_by} on <Moment format="DD/MM/YYYY h:mm a">{activeAttachment.created_at}</Moment></p>
						</div>
						<div className='previewTags'>
							{(activeAttachment.tag_list_details !== null && activeAttachment.tag_list_details !== undefined) ? activeAttachment.tag_list_details.length > 0 ?
								<div className="fileTagHolder">
									{activeAttachment.tag_list_details.filter(f => f.tag_type === 'public' || (f.tag_type === 'private' && f.tagged_by === user.id)).map((t, i) =>
										(i < 3) ?
											<span onClick={() => { fileTag(activeAttachment); }}
												style={{ backgroundColor: t.tag_type === 'public' ? t.tag_color : '#e82a87' }}
												className="fileTag"
												key={t.tag_id}>{t.title}</span>
											: (i === 3)
												? <span style={{ backgroundColor: t.tag_color }}
													className="fileTag"
													key={t.tag_id}
													onClick={() => { fileTag('tag',activeAttachment); }}> + {activeAttachment.tag_list_details.filter(f => f.tag_type === 'public' || (f.tag_type === 'private' && f.tagged_by === user.id)).length - 3}</span> : ''
									)}
								</div>
								:
								'' : ""
							}

						</div>
					</div>
					<span className="closeModal" onClick={close_popup} ></span>
				</div>
				<div className="previewModalBody">
					{prev ? <div className="slideLeft !left-[40px]" onClick={() => slideImg('prev')}></div> : ''}
					{next ? <div className="slideRight hover:bg-[#EC4A81]" onClick={() => slideImg('next')}></div> : ''}
					<div className="previewImgContainer">
						<TransformWrapper
							// initialScale={1}
							// disablePadding={true}
							limitToBounds={true}
							centerZoomedOut={true}
							centerOnInit={true}
							// initialPositionX={200}
							// initialPositionY={300}
							smooth={true}>

							{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
								<React.Fragment>
									<div className="tools">
										<button onClick={() => zoomIn()}>Zoom In +</button>
										<button onClick={() => zoomOut()}>Zoom Out -</button>
										<button id="resetZoom" onClick={() => resetTransform()}>Reset</button>
										<button onClick={() => AfileDownload(activeAttachment.location, activeAttachment.originalname)}>Download</button>
										{/* {activeAttachment.user_id === user.id && user.role !== 'Guest' ? <button onClick={() => { props.setPopup({ type: 'shareLinkPopup', data: true }); props.set_popup_action_data(activeAttachment); }}>Share</button> : ''} */}
									</div>
									<TransformComponent >
										{url_loading && <p>Loading...</p>}
										{/* <LazyLoad > */}
										<img onLoad={onComplete} src={activeAttachment.location} alt="preview" title="preview" style={{ cursor: 'pointer' }} />
										{/* </LazyLoad> */}

									</TransformComponent>
								</React.Fragment>
							)}
						</TransformWrapper>
					</div>
					<div className=""></div>
				</div>
			</div>
		</>
	)
}
// const mapStateToProps = (state) => {
// 	return {
// 		popup: state.popupReducer.popup,
// 		popup_action_data: state.popupReducer.popup_action_data,
// 		active_file: state.popupReducer.active_file,
// 		logindata: state.rootReducer.logindata,
// 	}
// }

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setPopup: (data) => {
// 			dispatch({ type: 'setPopup', payload: data })
// 		},
// 		set_popup_action_data: (data) => {
// 			dispatch({ type: 'set_popup_action_data', payload: data })
// 		},
// 		set_downloading_file: (data) => {
// 			dispatch({ type: 'set_downloading_file', payload: data })
// 		},
// 	}
// }

export default ImgPreviewPopup;