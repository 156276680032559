export const venue_create_update_api = async (type, create_venue, venueDetails, title, team_id, endpointURL, endpointToken) => {
    try {

        let typename = null;

        if (type === "create") {
            const createData = {
                venue_title: title,
                team_id: team_id,
                endpointURL: endpointURL,
                endpointToken: endpointToken
            };

            const response = await create_venue({ variables: { input: createData } });
            console.log("Create response:", response);  // Checking the response from create_venue

            typename = {
                actionType: "create",
                response: response
            };

            return typename;
        }

        if (type === "update") {
            if (!venueDetails || !venueDetails._id) {
                throw new Error("Venue details are required for update.");
            }

            const update_data = {
                _id: venueDetails._id,
                venue_title: title || venueDetails.venue_title,
                team_id: team_id || venueDetails.team_id,
                eod_participants: venueDetails.eod_participants,
                eod_submitters: venueDetails.eod_submitters,
                eod_reopen: venueDetails.eod_reopen,
                eod_email: venueDetails.eod_email,
                maintenance_participants: venueDetails.maintenance_participants,
                maintenance_submitter: venueDetails.maintenance_submitter,
                maintenance_approver: venueDetails.maintenance_approver,
                maintenance_observer: venueDetails.maintenance_observer,
                maintenance_memail: venueDetails.maintenance_memail,
                eod_contact_participants:venueDetails.eod_contact_participants,
                maintenance_contact_participants:venueDetails.maintenance_contact_participants,
                recipients:venueDetails.recipients,
                maintenance_recipients:venueDetails.maintenance_recipients,
                endpointURL: endpointURL,
                endpointToken: endpointToken
            };

            console.log("Update data:", update_data);  // Checking the update data

            const response = await create_venue({ variables: { input: update_data } });
            console.log("Update response:", response);  // Checking the response from create_venue

            typename = {
                actionType: "update",
                response: response
            };
        }

        // If type is neither 'create' nor 'update', return an error type
        if (!typename) {
            throw new Error("Invalid action type");
        }

        return typename;

    } catch (error) {
        console.error("Error in venue_create_update_api:", error);
        return { actionType: "error", error };
    }
};
