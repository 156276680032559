
import React, { useState } from "react";
import 'moment-timezone';
import moment from "moment-timezone";
import Moment from 'react-moment';
import userImage from "../../../media/images/img.png";
import { useQuery } from "@apollo/client";
import { TaskNotification } from "../../../Utils/GraphqlQueries";

function NotificationsTabHome() {
    const [notifications, set_notifications] = useState([]);
    const [pagination, set_pagination] = useState({ page: 1 });

    // Fetch keywords
    const { loading } = useQuery(TaskNotification, {
        pagination,
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            set_notifications(res.taskNotification.notification)
        },
    });

    const notify = notifications.reduce((groups, data) => {
        const date = moment(data.created_at).format('MMM Do YYYY');
        if (!groups[date]) { groups[date] = []; }

        groups[date].push(data);
        return groups;
    }, {});
    // Edit: to add it in the array format instead
    const taskNotify = Object.keys(notify).map((date) => {
        return { date, notify_data: notify[date] };
    });
    return (
        <div className='taskNotify_full_area'>
            {loading ?
                <div className='h-full w-full flex items-center justify-center absolute z-[1] bg-white/80'>Loading...</div>
                :
                notifications.length > 0 ?
                    taskNotify.map((data, index) => (
                        <div className="msg_indivisual_box" key={index} >
                            <div className="msgSeparator" ><p className="z-10">{data.date}</p></div>
                            {data.notify_data.map((noti, i) => (
                                <div className="user_msg !float-none" key={i} style={{ padding: '7px 30px' }}>
                                    <div className="sender_img">
                                        <img src={userImage} width={50} height={50} alt="user" title="user" />
                                    </div>
                                    <div className="msg_info">
                                        <div className="sender_info">
                                            <p className="sender_name mr-1">{noti.created_by_name} </p><p className="msg_time"><i className="fas fa-clock custom_clock_size" ></i> <Moment fromNow>{noti.created_at}</Moment></p></div>
                                        <div className="msg_body">{noti.title}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                    : <p className="notFound">Notifications not found</p>}

        </div>
    )
}

export default NotificationsTabHome;