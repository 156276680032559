import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import EODStartEnd from './EODStartEnd';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const demoData = {
//     pia: [{
//         totalFohHourlyActual: 2000,
//         totalFohSalaryActual: 3000,
//         totalBohHourlyActual: 1500,
//         totalBohSalaryActual: 2500,
//     }],
//     dateRangeTotal: {
//         totalNetSales: 15000,
//     },
// };

const EodPieChart = ({ demoData : v, date }) => {
    
    const [theme, setTheme] = useState('');

    useEffect(() => {
        const type = localStorage.getItem("theme");
        setTheme(type === 'dark' ? 'dark' : 'light');
    }, []);

    const totals = {
        fohHourly: v?.pia[0]?.totalFohHourlyActual || 0,
        fohSalary: v?.pia[0]?.totalFohSalaryActual || 0,
        bohHourly: v?.pia[0]?.totalBohHourlyActual || 0,
        bohSalary: v?.pia[0]?.totalBohSalaryActual || 0,
    };

    const totalCost = Object.values(totals).reduce((acc, val) => acc + val, 0);
    // const netSales = v?.dateRangeTotal?.totalNetSales || 0;
    const netSales = v?.pia[0]?.totalLabourNetSales || 0;


    const dataPoints = [
        { y: (totals.fohHourly / totalCost) * 100, label: "FOH Hourly", value: totals.fohHourly, color: "#ffaf4c" },
        { y: (totals.fohSalary / totalCost) * 100, label: "FOH Salary", value: totals.fohSalary, color: "#64a6f4" },
        { y: (totals.bohHourly / totalCost) * 100, label: "BOH Hourly", value: totals.bohHourly, color: "#732be2" },
        { y: (totals.bohSalary / totalCost) * 100, label: "BOH Salary", value: totals.bohSalary, color: "#2c56ac" },
    ].filter(point => point.y > 0); // Remove points with 0 value
    // console.log('EodPieChart', v, dataPoints,totals);

    const options = {
        animationEnabled: true,
        backgroundColor: "transparent",
        exportEnabled: true,
        yValueFormatString: "##0.00\"%\"",
        data: [{
            type: "pie",
            indexLabelFontSize: 14,
            indexLabelFontColor: theme === "dark" ? "white" : "",
            startAngle: 0,
            radius: 110,
            fontFamily: "poppins",
            dataPoints: dataPoints.map(point => ({
                ...point,
                indexLabel: `${point.label}: (${point.y.toFixed(2)}%)`
            }))
        }]
    };

    return (
        <>
        <div><p className='salesProgressBarText text-[#0b1f47] text-[32px] px-6  mb-4'>Total labour costs</p></div>
        <div className='eodBackground rounded-[10px] border-b-[#f1f6fa] shadow-lg shadow-[rgba(0,0,0,0.1)] bg-white border-[#f1f6fa] mb-5 m-6'>
            <div className='salesProgressBarHead forChartHead' >

                <EODStartEnd _date={date} type={'doughnut'} />
            </div>
            {
                totalCost === 0 ? 
                <div><p style={{ textAlign: 'center', padding: '50px' }}>No data available</p></div> :
                <div className='doughnut_chart_eod'>
                    <div style={{ width: '75%', marginLeft: '-50px' }}>
                        <CanvasJSChart options={options} />
                        <div className='totalCostEODMain'>
                            <p className='totalCostEOD'>
                                Labor cost: <span className='totalCostEODShort'>
                                    ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(Math.round(totalCost))}</span>&nbsp;
                            </p>
                            <p className='totalCostEODCon'>
                                {/* <span className='totalCostEODShort'> ({((totalCost / netSales) * 100).toFixed(2)}%</span> */}
                                <span className='totalCostEODShort'>
                                        ({netSales === 0 ? '0%' : ((totalCost / netSales) * 100).toFixed(2) + '%'}
                                    </span>
                                &nbsp;of net sales:&nbsp;
                                <span className='totalCostEODShort'>
                                    ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(Math.round(netSales))})
                                </span>
                            </p>
                        </div>
                    </div>

                    <div>
                        {Object.entries(totals).map(([key, value], index) => {
                            const labelMap = {
                                fohHourly: "FOH Hourly",
                                fohSalary: "FOH Salary",
                                bohHourly: "BOH Hourly",
                                bohSalary: "BOH Salary",
                            };
                            const colorMap = {
                                fohHourly: '#ffaf4c',
                                fohSalary: '#64a6f4',
                                bohHourly: '#732be2',
                                bohSalary: '#2c56ac',
                            };
                            const percentage = ((value / totalCost) * 100).toFixed(2);

                            return (
                                <div className="chartRightSide" style={{ marginBottom: '35px' }} key={index}>
                                    <div className="roundCircleColor" style={{ backgroundColor: colorMap[key] }}></div>
                                    <div className="chartRightSideText">
                                        <p>{labelMap[key]}</p>
                                        <p>
                                            ${new Intl.NumberFormat('en-US', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }).format(Math.round(value || 0))}
                                            {` (${percentage}%)`}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </div>
        </>
    );
};

export default EodPieChart;
