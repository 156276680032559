import React, { useState } from 'react';
import { VscCalendar } from "react-icons/vsc";
import DatePicker from "react-datepicker";
import { useClickAway } from "@uidotdev/usehooks";
import { MdOutlineCancel } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';

const DateRangeFilter = ({ setDateFilterPopup }) => {
    const { fetchByFilter, filter, set_filter, setPagination, set_column_id } = useTaskContext()
    const [startDate, setStartDate] = useState(null); // State for the Start date
    const [endDate, setEndDate] = useState(null); // State for the end date
    // const [error2, setError2] = useState(false);

    const handleStartDateChange = (date) => {
        if (endDate && date > endDate) {
            // setError2(true);
        } else {
            setStartDate(date);
            // setError2(false);
        }
    };

    const handleEndDateChange = (date) => {
        if (startDate && date < startDate) {
            // setError2(true);
        } else {
            setEndDate(date);
            // setError2(false);
        }
    };

    const clearStartDate = () => setStartDate(null);
    const clearEndDate = () => setEndDate(null);

    const view_filter = () => {
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(prev => [...prev, ['dateRange', startDate, endDate]]); // spread and update the task_filter
        fetchByFilter([...filter, ['dateRange', startDate, endDate]]);
        setDateFilterPopup(false)
    }

    const ref = useClickAway(() => {
        setDateFilterPopup(false);
    });

    return (
        <div ref={ref} className='tagRelatedPopup forDateRange'>
            <div className='flex items-center h-[55px]'>
                <div className='pl-10 text-[#032e84] text-sm'>
                    <p>Search: </p>
                </div>
                <div className="!ml-[-130px] Advance_search_top_Task newclassName !justify-center" style={{ gap: "0px", borderBottom: "unset" }}>
                    <div className="col1 !w-[27%]">
                        <div className="row1 relative group" style={{ paddingRight: "0px", marginLeft: "0px" }}>
                            <p className='text-[#032e84] text-sm mt-2 mr-2'>From</p>
                            <span className="calenderIcon_task">
                                <VscCalendar size={22} />
                            </span>
                            <div className="fromDate_One_task col1_row2 ">
                                <DatePicker
                                    className="start_Date_task Focus"
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="MMMM dd, yyyy"
                                    placeholderText="From date"
                                    maxDate={endDate}
                                />
                                {startDate && (
                                    <MdOutlineCancel
                                        className='absolute group-hover:block hidden text-red-600 text-xl right-3 top-[8px] cursor-pointer'
                                        onClick={clearStartDate}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col1 !w-[27%]">
                        <div className="row1 relative group" style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                            <p className='text-[#032e84] text-sm mt-2 mr-2'>To</p>
                            <span className="calenderIcon_task">
                                <VscCalendar size={22} />
                            </span>
                            <div className="fromDate_One_task col1_row2">
                                <DatePicker
                                    className="start_Date_task Focus"
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="MMMM dd, yyyy"
                                    placeholderText="To date"
                                    minDate={startDate}
                                />
                                {endDate && (
                                    <MdOutlineCancel
                                        className='absolute group-hover:block hidden text-red-600 text-xl right-3 top-[8px] cursor-pointer'
                                        onClick={clearEndDate}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tagCoupleBtn !top-[25px]">
                    <div className="skipButton" onClick={() => setDateFilterPopup(false)}>Skip</div>
                    <div className={`skipButton !w-[80px] ${startDate || endDate ? '' : ' opacity-50 pointer-events-none'}`} onClick={() => { clearStartDate(); clearEndDate() }}>Reset</div>
                    <div className={`viewFilesButton activeView !w-[75px] ${startDate && endDate ? '' : ' opacity-50 pointer-events-none'}`} onClick={view_filter}>View</div>
                </div>
            </div>
        </div>
    );
};

export default DateRangeFilter;
