/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway } from "@uidotdev/usehooks";
import TaskListKeywordPopup from './TaskListKeywordPopup';
import Moment from "react-moment";
import { TbFlag3Filled } from "react-icons/tb";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsSortDown, BsSortUp } from 'react-icons/bs';
import { useTaskContext } from '../TaskContext';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import { useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from "react-csv";


const initialColumns = [
    { id: 'project_title', content: 'Project name' },
    { id: 'task_title', content: 'Task title' },
    { id: 'assign_to', content: 'Assigned to' },
    { id: 'start_date', content: 'Start date' },
    { id: 'end_date', content: 'Due date' },
    { id: 'status', content: 'Status' },
    { id: 'progress', content: 'Progress' },
    { id: 'forecasted_cost', content: 'Forecasted hours' },
    { id: 'actual_hours', content: 'Actual hours ' },
    { id: 'hours_variance', content: 'Hour variance ' },
    { id: 'forecasted_hours', content: 'Forecasted cost ' },
    { id: 'actual_cost', content: 'Actual cost' },
    { id: 'cost_variance', content: 'Cost variance' },
    { id: 'key_words', content: 'Keywords' },
];

const extraColumns = [
    { id: 'priority', content: 'Priority' },
    { id: 'created_by', content: 'Created by' },
    { id: 'review', content: 'Review pending' },
    { id: 'observers', content: 'Observer' },
    { id: 'conversation_name', content: 'Room name' },
];

const TaskList = memo(({ advancedSearchExpand, csv_data, csv_headers }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { fetchByPage, tasks, set_flag, flag, users, user, set_tasks, kanban_pagination, paginationLoading, setPaginationLoading, setPagination } = useTaskContext();
    const scrollerRef = useRef(null);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [scrollRightVisible, setScrollRightVisible] = useState(true);
    const [viewTableName, setViewTableName] = useState(false);
    const [KeyWordPopup, set_KeyWordPopup] = useState(null);

    const [taskFilterList, setTaskFilterList] = useState(false);

    const ref = useClickAway(() => {
        setTaskFilterList(false)
    });


    const [columns, setColumns] = useState(initialColumns);
    const [availableColumns, setAvailableColumns] = useState(extraColumns);

    const renderUser = useCallback((ids) => {
        return <div style={{ width: "100%", position: 'relative' }} className='flex'>
            {ids.map((u, i) => (
                <li key={'assign_' + i} className="ObsImageTask">{getONLYUSERSDATA(users, u, 'all')?.fnln}</li>
            ))}
        </div>
    }, []);


    const getCellValue = useCallback((row, colId) => {
        switch (colId) {
            case 'project_title':
                return row.project_title && row.project_title !== 'Not Defined' ? row.project_title : <span className={' italic text-[#a9a9a9] text-'}>Not defined</span>
            case 'task_title':
                return row.task_title || 'N/A';
            case 'assign_to':
                return (
                    row.assign_to?.length > 0 ? renderUser(row.assign_to) : "Unassigned"
                )
            case 'start_date':
                return row.start_date ?
                    <Moment format="MMM DD, YYYY">{row?.start_date}</Moment> :
                    <span className={' italic text-[#a9a9a9] text-'}>Not defined</span>
            case 'end_date':
                return row.end_date ?
                    <Moment format="MMM DD, YYYY">{row?.end_date}</Moment> :
                    <span className={' italic text-[#a9a9a9] text-'}>Not defined</span>
            case 'status':
                return row.status || 'N/A';
            case 'progress':
                return `${row.progress}%`;
            case 'forecasted_hours':
                return row.forecasted_hours || '0';
            case 'actual_hours':
                return row.actual_hours || '0';
            case 'hours_variance':
                return row.hours_variance || '0';
            case 'forecasted_cost':
                return `$${row.forecasted_cost || '0'}`;
            case 'actual_cost':
                return `$${row.actual_cost || '0'}`;
            case 'cost_variance':
                return `$${row.cost_variance || '0'}`;
            case 'priority':
                return row.priority || 'Low';
            case 'created_by':
                // return row.created_by || 'Unknown';
                return (
                    <div style={{ width: "20%" }}>
                        <span>
                            <li className="ObsImageTask" style={{ marginTop: '0px', width: '32px', height: '32px', marginLeft: '5px', alignItems: 'center', backgroundColor: 'rgb(44, 86, 172)' }}>{getONLYUSERSDATA(users, row.created_by, 'all')?.fnln}</li>
                        </span>
                    </div> || 'Unknown'
                )
            case 'review':
                return row.review ? 'Pending' : 'N/A';
            case 'observers':
                return row.assign_to?.length > 0 ? renderUser(row.assign_to) : "No Observer"
            case 'conversation_name':
                return row.conversation_name || 'No Room';
            case 'key_words':
                return row.key_words?.length > 0 ?
                    <div style={{ width: "20%" }}>
                        <span data-for="rightSection_tooltip" data-tip="Click to view all Keywords." style={{ display: 'flex' }}>
                            <p className="timeAndDate">{row.key_words?.length}</p>
                            <ul onClick={(e) => { e.preventDefault(); e.stopPropagation(); set_KeyWordPopup(row.key_words) }} className="colorGroup" style={{ margin: '0px 0px 0px 5px' }}>
                                {row.key_words?.map((v, i) =>
                                    <li key={i} className="colorGroupList" style={{ background: 'rgb(11, 31, 71)' }}></li>
                                )}
                            </ul>
                        </span>
                    </div>
                    : <span className={' italic text-[#a9a9a9] text-'}>Not defined</span>;
            default:
                return 'N/A';
        }
    }, []);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // Dropped outside any droppable area
        if (!destination) return;

        // Reordering within table columns
        if (source.droppableId === 'tableColumns' && destination.droppableId === 'tableColumns') {
            const reorderedColumns = Array.from(columns);
            const [moved] = reorderedColumns.splice(source.index, 1);
            reorderedColumns.splice(destination.index, 0, moved);
            setColumns(reorderedColumns);
        }

        // Moving from available columns to table columns
        if (source.droppableId === 'availableColumns' && destination.droppableId === 'tableColumns') {
            const newAvailableColumns = Array.from(availableColumns);
            const [movedColumn] = newAvailableColumns.splice(source.index, 1);
            const updatedColumns = Array.from(columns);
            // Insert the moved column at the specific destination index
            updatedColumns.splice(destination.index, 0, movedColumn);
            setAvailableColumns(newAvailableColumns);
            setColumns(updatedColumns);
        }

        // Moving from table columns to available columns
        if (source.droppableId === 'tableColumns' && destination.droppableId === 'availableColumns') {
            const newColumns = Array.from(columns);
            const [movedColumn] = newColumns.splice(source.index, 1);
            setColumns(newColumns);
            setAvailableColumns([...availableColumns, movedColumn]);
        }
    };

    const handleViewTableNameClick = () => {
        setViewTableName(!viewTableName);
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollLeft = scrollerRef.current.scrollLeft;
            const maxScrollLeft = scrollerRef.current.scrollWidth - scrollerRef.current.clientWidth;

            setShowBackToTop(scrollLeft > 0);
            if (scrollLeft === maxScrollLeft) {
                setScrollRightVisible(false);
            } else {
                setScrollRightVisible(true);
            }
        };

        const scrollerElement = scrollerRef.current;

        if (scrollerElement) {
            scrollerElement.addEventListener('scroll', handleScroll);

            return () => {
                scrollerElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const scrollToTop = () => {
        scrollerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    const scrollLeft = () => {
        scrollerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const [sortConfig, setSortConfig] = useState({ field: null, order: "asc" });

    const sortTasks = (tasks, field, order = "asc") => {
        return [...tasks].sort((a, b) => {
            let valueA = a[field];
            let valueB = b[field];

            // If sorting by created_by, resolve user IDs to names
            if (field === "created_by") {
                valueA = getONLYUSERSDATA(users, valueA, 'name') || valueA; // Resolve user ID to name or keep the ID if not found
                valueB = getONLYUSERSDATA(users, valueB, 'name') || valueB;
            }

            // Handle date sorting
            if (field.includes("date") || field.includes("time")) {
                return order === "asc"
                    ? new Date(valueA) - new Date(valueB)
                    : new Date(valueB) - new Date(valueA);
            }

            // Handle number sorting
            if (typeof valueA === "number" && typeof valueB === "number") {
                return order === "asc" ? valueA - valueB : valueB - valueA;
            }

            if (typeof valueA === "string" && typeof valueB === "string") {
                return order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }

            // Handle boolean sorting
            if (typeof valueA === "boolean" && typeof valueB === "boolean") {
                return order === "asc" ? valueA - valueB : valueB - valueA;
            }

            // Handle array sorting (e.g., assignee)
            if (Array.isArray(valueA) && Array.isArray(valueB)) {
                // Map user IDs to user names
                const namesA = valueA.map(id => getONLYUSERSDATA(users, id, 'fnln') || id).sort();
                const namesB = valueB.map(id => getONLYUSERSDATA(users, id, 'fnln') || id).sort();

                // Compare the first names in the sorted list
                const firstNameA = namesA[0] || "";
                const firstNameB = namesB[0] || "";

                return order === "asc"
                    ? firstNameA.localeCompare(firstNameB)
                    : firstNameB.localeCompare(firstNameA);
            }

            // Handle default sorting
            if (valueA < valueB) {
                return order === "asc" ? -1 : 1;
            }
            if (valueA > valueB) {
                return order === "asc" ? 1 : -1;
            }

            // Default case (no sorting applied)
            if (valueA === valueB) {
                return 0;
            }

            return 0; // Default case (no sorting applied)

        });
    };

    const handleSort = useCallback((field) => {
        // console.log("handleSort called with field:", field);
        const newOrder = sortConfig.field === field && sortConfig.order === 'asc' ? 'desc' : 'asc';
        set_tasks(prev => sortTasks(prev, field, newOrder))
        setSortConfig({ field, order: newOrder });
    }, [sortConfig, set_tasks]);

    const flagFilter = () => {
        setPagination(prev => ({ ...prev, page: 1 }))
        set_flag(prev => prev.length > 0 ? [] : [user.id])
    }

    const prevScrollHeights = useRef({});
    const onScrollColumn = () => {
        const [pagination] = kanban_pagination;
        if (scrollerRef?.current) {
            const { scrollTop, clientHeight, scrollHeight } = scrollerRef.current;

            // Check if scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                if (!paginationLoading && pagination?.totalPages > pagination.page) {
                    console.count("Calling API...");
                    prevScrollHeights.current = scrollHeight; // Update previous scroll height
                    setPaginationLoading(true);
                    setPagination(prev => ({ ...prev, page: pagination.page + 1 }))
                    fetchByPage(pagination.page + 1, [])
                }
            }
        }
    };

    return (
        <div className='taskMainListTableCon'>
            {/* overflow-auto */}
            <div className="back-to-top"
                data-tip='Get back to first column' data-for='KanbanViewTip'
                onClick={scrollToTop}
                style={{ display: showBackToTop ? 'flex' : 'none' }}>
                <span className="createConv_back forkanban"></span>
            </div>
            <div className="TaskListSlideLeft" onClick={scrollLeft} style={{ display: showBackToTop ? 'block' : 'none' }}>
            </div>
            {scrollRightVisible && (
                <div className="TaskListSlideRight" onClick={scrollRight}>
                </div>
            )}
            <div className='w-full'>
                <button className={`toggleDropDown hover:text-[#2196F3] p-2 ml-9`} onClick={handleViewTableNameClick}>Click here to view and drag more columns to your list.</button>
                <DragDropContext onDragEnd={onDragEnd}>
                    {
                        viewTableName &&
                        <div className="p-2.5 h-[70px] border border-[#e5eef4] ml-10 mr-2 mb-2">
                            <Droppable droppableId="availableColumns" direction="horizontal">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className='flex-wrap'
                                        style={{ display: 'flex', gap: '10px' }}
                                    >
                                        {availableColumns?.map((col, index) => (
                                            <Draggable key={col.id} draggableId={col.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        {col.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    }

                    {/* Main Table */}

                    <div className={`absolute ${viewTableName && !advancedSearchExpand ? '!top-[190px]' : viewTableName && advancedSearchExpand ? '!top-[360px]' : advancedSearchExpand ? '!top-[290px]' : '!top-[160px]'} w-full taskListTable overflow-scroll bg-white px-5 !pr-0 !pt-0`}>
                        <div id='taskListTable' onScroll={onScrollColumn} ref={scrollerRef} className={`w-full bg-white taskListTableDivArea ${viewTableName && !advancedSearchExpand ? 'h-[calc(100vh-310px)]' : viewTableName && advancedSearchExpand ? 'h-[calc(100vh-480px)]' : advancedSearchExpand ? 'h-[calc(100vh-410px)]' : 'h-[calc(100vh-280px)]'} overflow-auto`} >
                            <Droppable droppableId="tableColumns" direction="horizontal">
                                {(provided) => (
                                    <table
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{
                                            width: '100%',
                                            minWidth: '1800px', // Ensure table is wide enough to trigger horizontal scroll
                                            borderCollapse: 'collapse',
                                            tableLayout: 'auto', // Allow columns to size dynamically
                                        }}
                                    >
                                        <thead className='sticky tableTopHeader top-0 flex gap-2 mt-0 !mb-0 p-3 m-2 !ml-0 z-10 !bg-white border border-[#e5eef4]'>

                                            <th className='flex gap-5 items-center'>
                                                <button className="adminUserFilterTask" onClick={() => setTaskFilterList(true)}></button>
                                                {
                                                    taskFilterList &&
                                                    <ul ref={ref} className="userActionPopup showAll checklistPopup ForTaskList !top-9">
                                                        {/* <li>Incomplete tasks</li>
                                                        <li>Completed tasks</li>
                                                        <li>High-priority tasks</li> */}
                                                        <li className='!text-left'>Show deleted tasks only</li>
                                                        <li className='!text-left'><CSVLink data={csv_data} headers={csv_headers} filename={"task-list.csv"}>Export tasks </CSVLink></li>
                                                    </ul>
                                                }
                                                <button onClick={flagFilter}>
                                                    <TbFlag3Filled className={`text-[18px] hover:text-red-500 ${flag.length > 0 ? 'text-red-500' : 'text-[#858FA3]'}`} />
                                                </button>
                                            </th>
                                            <th className='flex relative'>
                                                {columns?.map((col, index) => (
                                                    <Draggable key={col.id} draggableId={col.id} index={index}>
                                                        {(provided) => (
                                                            <td
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                                className='w-44 flex gap-1 items-center relative'>
                                                                {sortConfig.field === col.id ?
                                                                    <>
                                                                        {
                                                                            sortConfig.order === "asc" ?
                                                                                <BsSortDown className='cursor-pointer text-[#0c1e47] text-lg' onClick={() => handleSort(col.id)} />
                                                                                :
                                                                                <BsSortUp onClick={() => handleSort(col.id)} className='cursor-pointer text-[#0c1e47] text-lg' />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <BsSortUp onClick={() => handleSort(col.id)} className='cursor-pointer text-[#0c1e47] text-lg' />
                                                                }

                                                                {sortConfig.field === col.id && <td className={`-top-1 w-2 h-2 rounded-full bg-red-600 absolute`}></td>}
                                                                <td className=' text-[#858fa3]'>
                                                                    {col.content}
                                                                </td>
                                                            </td>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </th>

                                        </thead>
                                        <tbody>
                                            {tasks?.map((row, i) => (
                                                <tr className={`flex gap-1 items-center TaskBody ${params.task_id === row._id && '!bg-[#eceef4]'}`} key={'task_' + i} onClick={() => navigate(`${params.tasks_tab}/${row._id}`)}>
                                                    <td className='flex gap-5 items-center'>
                                                        <td className="task_circle"></td>
                                                        {/* {singleTaskFlagToggle ?
                                                            <button onClick={() => setSingleTaskFlagToggle(!singleTaskFlagToggle)} className="fill_flagIcon_task active !ml-[6px] mt-[2px]" style={{ backgroundSize: '14px' }}></button>
                                                            :
                                                            <button onClick={() => setSingleTaskFlagToggle(!singleTaskFlagToggle)} className="fill_flagIcon_task  !ml-[6px] mt-[2px]" style={{ backgroundSize: '14px' }}></button>
                                                        } */}

                                                        {
                                                            row.flag.indexOf(user.id) > -1 ?
                                                                <button>
                                                                    <TbFlag3Filled className={`!ml-[4px] mt-[2px] text-[18px] hover:text-red-500 text-red-500`} />
                                                                </button> :
                                                                <button >
                                                                    <TbFlag3Filled className={`!ml-[4px] mt-[2px] text-[18px] !text-transparent`} />
                                                                </button>
                                                        }

                                                    </td>
                                                    <td key={row._id} className='flex items-center'>
                                                        {columns?.map((col, i) => (
                                                            <Fragment key={'col' + i}>
                                                                <td key={col.id} className='w-44 ml-[2px] !text-[#032e84]'>
                                                                    {getCellValue(row, col.id)}
                                                                </td>
                                                            </Fragment>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </div>
                    </div>

                </DragDropContext>

                {KeyWordPopup && <TaskListKeywordPopup KeyWordPopup={KeyWordPopup} set_KeyWordPopup={set_KeyWordPopup} />}
            </div>
        </div>
    );
});


export default TaskList;