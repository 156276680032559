import React, { useState, useMemo, useEffect } from 'react';
import ReportHeader from './ReportHeader';
import CreateAndEditReport from './CreateEdit/CreateAndEditReport';
import { useNavigate } from 'react-router-dom';
import EodDashboardTab from './EodDashboardTab';
import { GET_EOD_LIST } from '../../Utils/GraphqlQueries';
import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { set_eod_related_venue_popup, set_eod_dateFiltered_popup } from '../../redux/message';
import EodVanuesRelatedPopup from './EodVanuesRelatedPopup';
import EodDateRangeFilterPopup from './EodDateRangeFilterPopup';
import { set_report_list, update_report_list } from '../../redux/eodSlice';

let pagination = 0; // Declared outside the component

const EodReportHome = (props) => {
    const dispatch = useDispatch();
    const [sortField, setSortField] = useState('venue');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedReport, setSelectedReport] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isCreatePopupEod, setIsCreatePopupEod] = useState(false);
    const [eodTabs, setEodTabs] = useState('entry');
    // const [eodData, setEodData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalStats, setTotalStats] = useState({
        totalNetSales: 0,
        totalComps: 0,
        totalTotal: 0,
        totalForecastedLabour: 0,
        totalActualLabour: 0
    });
    
    const [startDateFilter, setStartDateFilter] = useState('')
    const [endDateFilter, setEndDateFilter] = useState('');
    const [venueTopFilter, setVenueTopFilter] = useState(null)
    const [dateTopFilter, setDateTopFilter] = useState(null)
    const [dataLoaded, setdataLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    // const [venueListFilter, setvenueListFilter] = useState();
    const [venueLists, setvenueLists] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const selected_venue_id = useSelector(state => state.eod.selected_venue_id);
    const eodData = useSelector(state => state.eod.report_list); // console.log('eodData',eodData);
   
    {/* updated By manzu*/ }
    
    const _redux = useSelector(state => state.message);
    const close_related_venue_popup = (type) => {
        dispatch(set_eod_related_venue_popup(false))
    };
    const close_dateFilitered_popup = (type) => {
        dispatch(set_eod_dateFiltered_popup(false))
    };

    const [fetchEODList, { loading: eodLoading, error: eodfetchError, data: eodReportsData }] = useLazyQuery(GET_EOD_LIST, {
        fetchPolicy: 'no-cache' 
    });
    // console.log('eodData', eodData);

    const handleEodTabs = (type) => {
        setEodTabs(type);
    }

    const handleSort = (field) => {
        setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
        setSortField(field);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // Filter data based on search term
    const filteredData = eodData.filter(row => {
        const values = Object.values(row);
        return values.some(value => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()));
    });

    // Sort filtered data
    const sortData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const aValue = a[sortField];
            const bValue = b[sortField];
            if (aValue === undefined || bValue === undefined) return 0;

            if (sortField === 'reporting_date') {
                return sortOrder === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
            }

            const parseValue = (value) => {
                if (typeof value === 'string') {
                    if (value.startsWith('$')) return parseFloat(value.replace('$', '').replace(',', ''));
                    if (value.endsWith('%')) return parseFloat(value.replace('%', ''));
                }
                return value;
            };

            const aParsed = parseValue(aValue);
            const bParsed = parseValue(bValue);

            if (typeof aParsed === 'number' && typeof bParsed === 'number') {
                return sortOrder === 'asc' ? aParsed - bParsed : bParsed - aParsed;
            }

            // Handle string sorting for 'venue' and 'status'
            const compareStrings = (aStr, bStr) => {
                const strA = aStr?.toString() || "";
                const strB = bStr?.toString() || "";
                return strA.localeCompare(strB, undefined, { sensitivity: 'base' });
            };

            return sortOrder === 'asc' ? compareStrings(aValue, bValue) : compareStrings(bValue, aValue);
        });

        return sortedData;
    };

    const sortedData = useMemo(() => sortData(filteredData), [filteredData, sortField, sortOrder]);

    // const handleEditReport = () => {
    //     setReportEdit(true);
    // };

    const handleOpenPopup = (report, type) => {
        if (type === 'editReport') {
            setSelectedReport(report);
            setIsPopupOpen(true);
        }
        setIsCreatePopupEod(true);
    };
    const handleClosePopup = () => {

        setIsPopupOpen(false);
        setSelectedReport(null);
        setIsCreatePopupEod(false);
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
        // close_related_venue_popup()
        // close_dateFilitered_popup()
    };
    // const totals = sortedData.reduce(
    //     (acc, row) => {
    //         acc.net_sales += parseFloat(row.net_sales || 0);
    //         acc.comps += parseFloat(row.comps || 0);
    //         acc.total += parseFloat(row.total || 0);
    //         acc.forecasted_labour += parseFloat(row.forecasted_labour || 0);
    //         acc.actual_labour += parseFloat(row.actual_labour || 0);
    //         return acc;
    //     },
    //     { net_sales: 0, comps: 0, total: 0, forecasted_labour: 0, actual_labour: 0 }
    // );

    const cleanObject = (obj) => {
        if (Array.isArray(obj)) {
            // If the object is an array, clean each element
            return obj.map(cleanObject);
        } else if (typeof obj === 'object' && obj !== null) {
            // If the object is a plain object, clean it
            const { __typename, ...rest } = obj; // Remove __typename
            // Recursively clean nested properties
            Object.keys(rest).forEach((key) => {
                rest[key] = cleanObject(rest[key]);
            });
            return rest;
        }
        return obj; // Return the value if it's not an object or array
    };

    const fetchData = async (type) => {
        try {
            debugger;
            // if (props.selected_venue_id == null) return;
            setLoader(true);
            if (type === 'initial') {
                pagination = 0;
                setdataLoaded(false);
            }
            let filter_list = [...venueLists.filter(v => v.isChecked).map((k) => k.venue_id)];
            // if (props.venueFilter) {
            //     venueListFilter = [...venueLists.filter(v => v.isChecked).map((k) => k.venue_id)];
            // }
            let data = await fetchEODList({ variables: { 
                venue_id: selected_venue_id,
                page: ++pagination,
                filter_list: filter_list,
                filter_startdate: startDateFilter,
                filter_enddate: endDateFilter,
                timezone: moment.tz.guess()
            } });
            // console.log('fetchData:EOD:',data.data.get_eod_list.loaded);
            // let data = await get_reports_data(++pagination, props.selected_venue_id, searchByVal, { venueListFilter, startDateFilter: props.startDateFilter, endDateFilter: props.endDateFilter });
            if (data?.data?.get_eod_list?.status) {
                if (type === 'initial') {
                    if (data.data.get_eod_list.result) {
                        let eod_result = data.data.get_eod_list.result.map(cleanObject);
                        // setEodData(eod_result);
                        dispatch(set_report_list(eod_result));
                        setTotalCount(data.data.get_eod_list.totalCount);
                        setTotalStats(data.data.get_eod_list.totals);
                    }
                }
                else if (type === 'scroll') {
                    dispatch(set_report_list([...eodData, ...data.data.get_eod_list.result.map(cleanObject)]));
                    // setEodData([...eodData, ...data.data.get_eod_list.result.map(cleanObject)]);
                    
                }

            }
            if (data.data.get_eod_list.loaded) {
                setdataLoaded(true)
            }
            setLoader(false);
            // setpaginaion(paginaion + 1);
        } catch (error) {
            setLoader(false);
            console.error("Error fetching reports data:", error);
        }
    };

    const messageOnScroll = (e) => {
        // debugger
        // if (programmaticScroll) return; // Skip if triggered by scrollTo
        if (eodData?.length >= totalCount || loader) return;
            var elemScrollTop = e.target.scrollTop;
            var scrollHeight = e.target.scrollHeight;
            var clientHeight = e.target.clientHeight;
            // console.log('scroll',elemScrollTop + clientHeight,'>=', scrollHeight)
            if (elemScrollTop + clientHeight >= scrollHeight - 10) { // 10 is a threshold to trigger a bit earlier
                if (!loader) fetchData('scroll');
            }
    }

    const resetCheckboxes = () => {
        const updatedVenues = venueLists.map((projects) => ({
            ...projects,
            isChecked: false,
        }));
        setvenueLists(updatedVenues);
        setSelectAll(false);
    };

    // useEffect(() => {
    //     debugger
    //     if (eodReportsData && eodReportsData?.get_eod_list?.status) {
    //         setEodData([...eodData, ...eodReportsData.get_eod_list.result]);
    //         setTotalCount(eodReportsData.get_eod_list.totalCount)
    //     }
    // }, [eodReportsData])

    useEffect(() => {
        fetchData('initial');
        
    },[])

    useEffect(() => {
        
        if(venueTopFilter !== null || dateTopFilter !== null){
            fetchData('initial');
        }

    },[venueTopFilter, dateTopFilter])

    // useEffect(() =>{
    //     // debugger
    //     // if(eodTabs === "entry"){
    //         fetchData('initial');
    //     // }

    // },[eodTabs])

    useEffect(() => {
        
        if(selected_venue_id !== null){
            fetchData('initial');
            close_related_venue_popup();
            close_dateFilitered_popup();
            resetCheckboxes();
            setVenueTopFilter(false);
            setStartDateFilter('');
            setEndDateFilter('');
            setDateTopFilter(false);
        }

    },[selected_venue_id])

    return (
        <>

            <div className='right_container task'>
                <ReportHeader
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    handleClearSearch={handleClearSearch}
                    eodTabs={eodTabs}
                    handleEodTabs={handleEodTabs}
                    handleOpenPopup={handleOpenPopup}
                    handleGoBack={handleGoBack}
                    totalCount={totalCount}
                    eodData={eodData}
                    venueTopFilter={venueTopFilter} setVenueTopFilter={setVenueTopFilter}
                    dateTopFilter={dateTopFilter} setDateTopFilter= {setDateTopFilter}
                    startDateFilter={startDateFilter} setStartDateFilter={setStartDateFilter}
                    endDateFilter={endDateFilter} setEndDateFilter={setEndDateFilter}
                    resetCheckboxes={resetCheckboxes}
                    selectAll={selectAll} setSelectAll={setSelectAll}
                    props={props}

                />
                {_redux.eod_related_venue_popup &&
                    <EodVanuesRelatedPopup
                        close_related_venue_popup={close_related_venue_popup}
                        venueTopFilter={venueTopFilter} setVenueTopFilter={setVenueTopFilter}
                        // venueListFilter={venueListFilter} setvenueListFilter={setvenueListFilter}
                        venueLists={venueLists} setvenueLists={setvenueLists}
                        resetCheckboxes={resetCheckboxes}
                        selectAll={selectAll} setSelectAll={setSelectAll}
                    
                    />
                }
                {_redux.eod_dateFiltered_popup &&
                    <EodDateRangeFilterPopup
                        startDateFilter={startDateFilter} setStartDateFilter={setStartDateFilter}
                        endDateFilter={endDateFilter} setEndDateFilter={setEndDateFilter}
                        close_dateFilitered_popup={close_dateFilitered_popup}
                        dateTopFilter={dateTopFilter} setDateTopFilter= {setDateTopFilter}
                        fetchData={fetchData}
                    />}
                {
                    eodTabs === 'sales' ? <EodDashboardTab /> :
                    
                        
                        <div className="task_body_area inReportArea" >
                            {loader && <div className="loaderMain mini_loader" style={{top: '50%'}}></div> }
                            <div className="divTable eodTable" >
                                <div className="divTableBody" onScroll={messageOnScroll}>
                                    
                                    <div className="divTableRow divTableFirstRow">
                                        <div className={`divTableCell ${sortField === 'notes_for' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('notes_for')}>
                                            <p className="columnNum"><span className={`${sortField === 'notes_for' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Venue</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'reporting_date' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('reporting_date')}>
                                            <p className="columnNum dateDivReport"><span className={`${sortField === 'reporting_date' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Date</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'net_sales' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('net_sales')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'net_sales' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Net sales</p>
                                                {/* <p className='columnNum'>($10.00)</p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'comps' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('comps')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'comps' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Comp</p>
                                                {/* <p className='columnNum'>($10.00)</p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'total' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('total')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'total' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Total sales</p>
                                                {/* <p className='columnNum'>($10.00)</p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'forecasted_labour' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('forecasted_labour')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'forecasted_labour' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Labour forecast</p>
                                                {/* <p className='columnNum'>($10.00)</p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'actual_labour' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('actual_labour')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'actual_labour' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Labour actual</p>
                                                {/* <p className='columnNum'>($10.00)</p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'total_percent' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('total_percent')}>
                                            <div className=''>
                                                <p className="columnNum"><span className={`${sortField === 'total_percent' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Labour % net sale</p>
                                                {/* <p className='columnNum'></p> */}
                                            </div>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'status' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('status')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Status</p>
                                        </div>
                                        <div className={`divTableCell !w-[140px] lastupdatedCol ${sortField === 'updated_by_name' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('updated_by_name')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Last updated by</p>
                                        </div>
                                        {/* <span className="downloadIcon" style={{ position: 'absolute', right: '0px', top: '5px' }}>
                                            <span className="exportIcon"></span>
                                        </span> */}
                                    </div>
                                    {sortedData.length > 0 ? (
                                        sortedData.map((row, index) => (
                                            <div onClick={() => handleOpenPopup(row, "editReport")}
                                                key={index}
                                                className={`divTableRow ${index % 2 === 0 ? 'even' : 'even'}`}
                                            >
                                                <div className="divTableCell">{row.notes_for}</div>
                                                <div className="divTableCell">{moment(row.reporting_date).format("MMMM D, YYYY")}</div>
                                                <div className="divTableCell">
                                                    <p className='net_sales_text'> {row.net_sales?.toFixed(2)}</p>
                                                </div>
                                                <div className="divTableCell">
                                                    <p className='comps_text'>  {row.comps?.toFixed(2)}</p>
                                                </div>
                                                <div className="divTableCell">
                                                    <p className='net_sales_text'>{row.total?.toFixed(2)}</p>
                                                </div>
                                                <div className="divTableCell">{row.forecasted_labour?.toFixed(2)}</div>
                                                <div className="divTableCell">{row.actual_labour?.toFixed(2)}</div>
                                                <div className="divTableCell">
                                                    <p className='net_sales_text'>{row.total_percent?.toFixed(2)}</p>
                                                </div>
                                                <div className="divTableCell">
                                                    <p className={row.status === 'SUBMIT' ? 'completeColor' : 'draftColor'}>{row.status}</p>
                                                </div>
                                                <div className="divTableCell lastupdatedCol">
                                                    <div className="lastUpdated_text">
                                                        <p className="user">{row.updated_by_name}</p>
                                                        <p className="userUpdateDate">{moment(row.updated_at).format("MMMM D, YYYY")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                    ) : (
                                        <div className="flex justify-center items-center text-xl font-bold">
                                            <p className='mt-32 no_files_available'>Sorry! No search result found matching this combination</p>
                                        </div>
                                    )}
                                    {sortedData.length > 0 &&
                                        <div className="stickyBottomRowContainer">
                                            <div className="divTableRow totalRow">
                                                <div className="divTableCell">
                                                    <p className="totalUpdate">Total</p>
                                                </div>
                                                <div className="divTableCell" /> {/* Empty cell for date */}
                                                <div className="divTableCell">
                                                    ${totalStats.totalNetSales.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                                </div>
                                                <div className="divTableCell">
                                                    ${totalStats.totalComps.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                                </div>
                                                <div className="divTableCell">
                                                    ${totalStats.totalTotal.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                                </div>
                                                <div className="divTableCell">
                                                    ${totalStats.totalForecastedLabour.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                                </div>
                                                <div className="divTableCell">
                                                    ${totalStats.totalActualLabour.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                                </div>
                                                <div className="divTableCell" /> {/* Empty cell for netSalePercentage */}
                                                <div className="divTableCell" /> {/* Empty cell for status */}
                                                <div className="divTableCell" /> {/* Empty cell for lastUpdated */}
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
            {isPopupOpen || isCreatePopupEod ?
                <CreateAndEditReport
                    report={selectedReport}
                    onClose={handleClosePopup}
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                    eodData={eodData}
                    // setEodData={setEodData}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    cleanObject={cleanObject}
                    handleEodTabs={handleEodTabs}
                    fetchData={fetchData}

                /> : ""
            }
        </>
    );
};

export default EodReportHome;

