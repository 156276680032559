import React from 'react';

import { EditorBox } from '../../Connect/EditorBox'
import { useQuery } from '@apollo/client';
import { GET_SINGLE_ROOM } from '../../../Utils/GraphqlQueries';
import { useTaskContext } from '../TaskContext';

const TaskEditorBox = ({ me }) => {
    const { selectedTask, setTaskFileUpload } = useTaskContext()
    const { data: ThisRoom_data } = useQuery(GET_SINGLE_ROOM, { variables: { conversation_id: selectedTask?.conversation_id }, fetchPolicy: 'no-cache', skip: !selectedTask, });

    return (
        <EditorBox setTaskFileUpload={setTaskFileUpload} room={ThisRoom_data?.room} type={'task'} task={selectedTask} me={me} is_reply_msg={'false'} editorRef={''} sendMessageFun={''} startTypingFun={''} discussion={false} set_clear_text_icon={() => console.log()} />
    );
};

export default TaskEditorBox;