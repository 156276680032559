import { useClickAway } from '@uidotdev/usehooks';
import { useQuery, useApolloClient } from '@apollo/client';
import React, { useEffect, useState, useRef } from 'react';
import { getONLYUSERSDATA, ring_calling } from '../../Utils/Common';
import { GET_ME, GET_ALL_USERS } from '../../Utils/GraphqlQueries';
import { useSelector } from 'react-redux';


const GroupCallPopup = ({ setGroupCallPopup, active_conv, fetchCalling, getCallingAccept }) => {
  const client = useApolloClient();
  // const active_conv = useSelector(state => state.message.active_conversation)
  const cacheMe = client.readQuery({ query: GET_ME });
  const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [users, setusers] = useState([]);
  const [get_callMembers, set_callMembers] = useState([]);
  const [groupPopup, setGroupPopup] = useState(false);
  const groupCallBtn = useRef(null);

  useEffect(() => {
    if (cacheAllUsers?.users) {
      setusers(getONLYUSERSDATA(cacheAllUsers.users, active_conv.participants, 'all'))
    }
  }, [cacheAllUsers]);

  useEffect(() => {
    if (selectedUsers.length) {
      groupCallBtn.current.textContent = 'Make a call';
      // $('#btnMakecall').attr('data-type', 'window').text('Open Window');
    } else {
      groupCallBtn.current.textContent = 'Open Window';
      // $('#btnMakecall').attr('data-type', 'ring').text('Make a call');
    }

    if (users && Array.isArray(users) && users.length) {
      if (users.length === selectedUsers.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [selectedUsers])

  const filteredUsers = users.filter(user => {
    if (cacheMe.me.id === user.id) return false;
    return user.name.toLowerCase().includes(searchValue.toLowerCase())
  });

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleCheckboxChange = (event, userId) => {
    if (event.target.checked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allUserIds = users.map(user => user.id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
    setSelectAll(!selectAll);
  };

  const ref = useClickAway(() => {
    setGroupCallPopup(false);
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setGroupCallPopup(false);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const GroupPopupToggle = () => {
    setGroupPopup(!groupPopup)
    setGroupCallPopup(false)
  }

  const ringGroupCall = async () => {
    var checked_list = document.querySelectorAll('#memberHolderCall input:checked');
    var arr_participants = [cacheMe.me.id];
    checked_list.forEach(ls => { arr_participants.push(ls.getAttribute('data-uid')) });
    // debugger
    // let active_conv_details = active_conv.details;
    await ring_calling({
      user_id: cacheMe.me.id,
      conversation_type: 'group',
      arr_participants: arr_participants,
      participants_all: active_conv.participants,
      company_id: cacheMe.me.company_id,
      conversation_id: active_conv.conversation_id,
      convname: active_conv.title,
      call_link: window.location.origin + '/c/' + active_conv.short_id,
      call_option: arr_participants.length > 1 ? 'ring' : 'window',
      set_callMembers, GroupPopupToggle, fetchCalling, getCallingAccept
    });
    
    GroupPopupToggle();
  }


  return (
    <div className="videoCallMenuPopup" ref={ref}>
      <div id="callAdvanceOption">
        <div className="call-advance-option-div">
          <p>CHOOSE ROOM MEMBERS</p>
          <div className="channel-member-search">
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
            />
            <p className="call-users-count" style={{ visibility: 'hidden' }}>
              User(s) online: <span className="call-users-online">1</span> | offline: <span className="call-users-offline">7</span>
            </p>
          </div>
          <div className="added-channel-members" style={{ height: 'auto', maxHeight: '250px', overflowY: 'auto', marginTop: '5px' }}>
            <ul id="memberHolderAll">
              <li>
                <label>
                  <span className="user-name-text">Select All</span>
                  <input
                    className="checkGroupCall"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
            </ul>
            <ul id="memberHolderCall">
              {filteredUsers.map(user => (
                <li key={user.id}>
                  <label>
                    <span className="user-name-text">{user.name}</span>
                    <input
                      className="checkGroupCall"
                      type="checkbox"
                      data-uid={user.id}
                      checked={selectedUsers.includes(user.id)}
                      onChange={(event) => handleCheckboxChange(event, user.id)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="todo-btn-group">
            <button type="button" className="call-cancel-btn" onClick={() => setGroupCallPopup(false)}>Cancel</button>
            <button
              type="button"
              id="btnMakecall"
              data-type="window"
              className="call-create-btn"
              onClick={(e) => ringGroupCall()}
              ref={groupCallBtn}
            // onClick={() => console.log('Opening window', selectedUsers)}
            >
              Open Window
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCallPopup;