import React, { useEffect, useState } from 'react';
import WorkfreeliLogo from '../../media/images/WorkfreeliLogo.png';
import InitialOnboardingImageSlide1 from '../../media/images/first_time/initial/InitialOnboardingImageSlide1.png';

const InitialOnboardingImpressions = ({ setTipsAndTour }) => {

  const [slideStep, setSlideStep] = useState(1);

  const sectionStyle = {
    width: "700px",
    height: "470px",
    backgroundImage: "url(/media/images/first_time/pop_Up_Background_Large.png)",
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat'
  };

  const handleNext = () => {
    setSlideStep(prevStep => prevStep + 1);
  };

  const handlePrev = () => {
    setSlideStep(prevStep => prevStep - 1);
  };



  const handleGetStarted = () => {
    localStorage.setItem('getStarted', true);
    setTipsAndTour(false);
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setTipsAndTour(false);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [setTipsAndTour]);

  return (
    <div className='popupArea' id='initial_popupArea' >
      <div className="backwrap initialOnboarding" style={{ position: 'absolute' }}>
        <div className='wf_Onboarding'>
          <div className='slider'>
            {
              slideStep === 1 ?
                <div style={sectionStyle} className='slide initialSlide active'>
                  <div className="slide__skipText" onClick={() => setTipsAndTour(false)}>Skip</div>
                  <h3 className="slide__welcome initialheading">Welcome To</h3>
                  <div className='flex justify-center flex-col gap-10'>
                    <div className="flex justify-center">
                      <img title="img" src={InitialOnboardingImageSlide1} alt="InitialOnboardingImageSlide1" className="w-[250px] mt-[120px]" /></div>
                    <div className="flex justify-center">
                      <img title="img" src={WorkfreeliLogo} alt="WorkfreeliLogo" className="w-[300px] -mt-5" />
                    </div>
                  </div>
                </div> : slideStep === 2 ?
                  <div style={sectionStyle} className='slide initialSlide active'>
                    <div className="slide__skipText" onClick={() => setTipsAndTour(false)}>Skip</div>
                    <h4 className="slide__heading initialheading">Features at your fingertips</h4>
                    <h3 className="slide__pharese initialpharese">send messages and hover over any message to find options to</h3>
                    <div className="iconFullArea areaid1" id="iconFullArea1">
                      <div className="slide_icons" id="slide_icons_one1">
                        <div className="iconPortion icon1 flex justify-center">
                          <img title="img" src="/media/images/first_time/initial/InitialOnboardingFlag.png" alt="/media/images/first_time/initial/InitialOnboardingFlag.png" className="icon11" />
                        </div>
                        <div className="iconTextPortion">Flag it for later</div>
                      </div>
                      <div className="slide_icons ">
                        <div className="iconPortion icon2 flex justify-center">
                          <img title="img" src="/media/images/first_time/initial/InitialOnboardingReply.png" alt="/media/images/first_time/initial/InitialOnboardingReply.png" className="icon2" />
                        </div>
                        <div className="iconTextPortion">Reply to it</div>
                      </div>
                      <div className="slide_icons">
                        <div className="iconPortion icon3 flex justify-center">
                          <img title="img" src="/media/images/first_time/initial/InitialOnboardingReaction.png" alt="/media/images/first_time/initial/InitialOnboardingReaction.png" className="icon3" />
                        </div>
                        <div className="iconTextPortion last1">Add a quick reaction</div>
                      </div>
                    </div>
                  </div> : slideStep === 3 ?
                    <div style={sectionStyle} className='slide initialSlide active'>
                      <div className="slide__skipText" onClick={() => setTipsAndTour(false)}>Skip</div>
                      <h4 className="slide__heading initialheading">Talk to teammates and guests</h4>
                      <div className="iconFullArea areaid2" id="iconFullArea2">
                        <div className="slide_icons">
                          <div className="iconPortion icon1 flex justify-center">
                            <img title="img" src="/media/images/first_time/initial/InitialOnboardingPhoneIcon.png" alt="/media/images/first_time/initial/InitialOnboardingPhoneIcon.png" className="icon12" />
                          </div>
                          <div className="iconTextPortion">Start a call in any conversation by clicking on the phone icon.</div>
                        </div>
                        <div className="slide_icons">
                          <div className="iconPortion icon2 flex justify-center">
                            <img title="img" src="/media/images/first_time/initial/InitialOnboardingLinkIcon.png" alt="/media/images/first_time/initial/InitialOnboardingLinkIcon.png" className="icon2" />
                          </div>
                          <div className="iconTextPortion">Invite additional teammates and guests to a call by sharing the call link</div>
                        </div>
                      </div>
                    </div> : slideStep === 4 ?
                      <div style={sectionStyle} className='slide initialSlide active'>
                        <div className="slide__skipText" onClick={() => setTipsAndTour(false)}>Skip</div>
                        <h4 className="slide__heading initialheading">Manage your files</h4>
                        <div className="iconFullArea areaid3" id="iconFullArea3">
                          <div className="slide_icons">
                            <div className="iconPortion icon1 flex justify-center">
                              <img title="img" src="/media/images/first_time/initial/InitialOnboardingFilesIcon.png" alt="/media/images/first_time/initial/InitialOnboardingFilesIcon.png" className="icon13" />
                            </div>
                            <div className="iconTextPortion">Manage files in the files hub with search, filter, and tagging options</div>
                          </div>
                          <div className="slide_icons">
                            <div className="iconPortion icon2 flex justify-center">
                              <img title="img" src="/media/images/first_time/initial/InitialOnboardingSharedTeamTag.png" alt="/media/images/first_time/initial/InitialOnboardingSharedTeamTag.png" className="icon2" />
                            </div>
                            <div className="iconTextPortion">Use Shared team tags and Personal Tags to organize files</div>
                          </div>
                          <div className="slide_icons">
                            <div className="iconPortion icon3 flex justify-center">
                              <img title="img" src="/media/images/first_time/initial/InitialOnboardingLinkIcon.png" alt="/media/images/first_time/initial/InitialOnboardingLinkIcon.png" className="icon3" />
                            </div>
                            <div className="iconTextPortion last3">Create a file share link to share a file with anyone.</div>
                          </div>
                        </div>
                      </div> : slideStep === 5 ?
                        <div style={sectionStyle} className='slide initialSlide active'>
                          <div className="slide__skipText" onClick={() => setTipsAndTour(false)}>Skip</div>
                          <h4 className="slide__heading initialheading">Keep everything secure and private</h4>
                          <div className="iconFullArea areaid4" id="iconFullArea4">
                            <div className="slide_icons">
                              <div className="iconPortion icon1 flex justify-center">
                                <img title="img" src="/media/images/first_time/initial/InitialOnboardingPasswordIcon.png" alt="/media/images/first_time/initial/InitialOnboardingPasswordIcon.png" className="icon14" />
                              </div>
                              <div className="iconTextPortion">Add a password to protect your voice and video calls.</div>
                            </div>
                            <div className="slide_icons">
                              <div className="iconPortion icon2 flex justify-center">
                                <img title="img" src="/media/images/first_time/initial/InitialOnboardingLockIcon.png" alt="/media/images/first_time/initial/InitialOnboardingLockIcon.png" className="icon2" />
                              </div>
                              <div className="iconTextPortion">Use private messages to chat and share files with select members in a room</div>
                            </div>
                            <div className="slide_icons">
                              <div className="iconPortion icon3 flex justify-center">
                                <img title="img" src="/media/images/first_time/initial/InitialOnboardingChatIcon.png" alt="/media/images/first_time/initial/InitialOnboardingChatIcon.png" className="icon3" />
                              </div>
                              <div className="iconTextPortion last4">Take notes and upload files for yourself in your personal room.</div>
                            </div>
                          </div>
                        </div> : ""

            }
            <div className="slideBottom">
              <div className={`prev initialprev ${slideStep === 1 ? 'inactive' : ''}`} onClick={handlePrev}><span className="leftArrows"></span></div>
              <div className="totalSlides initialTotal">{slideStep}/5</div>
              {slideStep < 5 ?
                <div className="next initialnext" onClick={handleNext}>
                  <span className="rightArrows"></span>
                </div> :
                <div className="settingUp initialsettings" style={{ top: '407px' }} onClick={handleGetStarted}>Get Started!</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialOnboardingImpressions;