import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import Summary from './Summary';


const getAmountForStorage = (s) => {
    let data = [0, 20, 50, 100, 200, 500, 1000, 2000];
    let price = [0, 3.00, 7.00, 14.00, 20.00, 50.00, 100.00, 200.00];
    let index = data.indexOf(s);
    return price[index];
}
function SupportAndBillingReview(props) {
    //console.log('review',props);

    const [loader, setLoader] = useState(false);

    const successAlert = () => {
     
        props.setActiveTab(6);


    }
    const onClickEditBlingAddress = () => {
        props.setActiveTab(3)

    }
    const onClickEditPyment = () => {
        props.setActiveTab(4)

    }
    const onClickNextHandler = () => {
        //console.log('clicked');
        props.setActiveTab(4);
    }


    return (

        <div className="supportAndBillingContainer upgradePlan checkout">
            <div className='UpgradePlanLeftArea review'>
                {props.billingData.address.length !== 0 ?
                    <div className='reviewArea'>
                        <div className='billingreview'>
                            <h3 className='billingAddress'>Billing Address</h3>
                            <p className='editAddress' onClick={onClickEditBlingAddress}>
                                edit billing address
                            </p>
                        </div>
                        <div className='billingReviewName'>
                            {props.billingData.address.first_name + ' ' + props.billingData.address.last_name}
                        </div>
                        <div className='billingReviewAddress'>
                            {props.billingData.address.street_address}<br /> {props.billingData.address.province},<br /> {props.billingData.address.zip}
                        </div>

                    </div>
                    : ''}
                {
                    props.billingData.payment_info.map((card, index) => {
                        return (
                            <div className='reviewArea'>
                                <div className={classNames("billingreview", index > 0 ? 'hidden' : 'visible')}>
                                    <h3 className='billingAddress'>Payment Method</h3>
                                    <p className='editAddress' onClick={onClickEditPyment}>
                                        edit payment method
                                    </p>
                                </div>
                                <div className='billingReviewName'>
                                    {card.billingCardHolderName}
                                    <p className='cardExpirayDate'>
                                        Visa ending in:***{card.billingCardNumber.substr(-4)}<br />
                                        Exp: {card.billingExpirationDate.getMonth() + 1} / {card.billingExpirationDate.getFullYear()}
                                    </p>
                                </div>
                                <div className='billingReviewAddress'>
                                    <div className='cardImage'>
                                        <img style={{ width: '50px' }} src="/media/images/visaCard.png" alt="Visa card" />

                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
            <Summary onClickNextHandler={onClickNextHandler} billingData={props.billingData} loader={loader} onClickHandler={successAlert} btn="" />
        </div>




    )
}





export default SupportAndBillingReview;