/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { All_archive, GET_ALL_COMPANIES, GET_LEFT_ROOMS } from '../../Utils/GraphqlQueries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { LeftRoomsList } from './LeftRoomsList';
import StartDiscussionPopup from '../Room/StartDiscussionPopup';
import SettingsOptions from './SettingsOptions';
import LeftFilterOptions from './LeftFilterOptions';
import { useDispatch, useSelector } from 'react-redux';
import { set_archive_conversations, set_conversations } from '../../redux/message';
import ComingSoon from './ComingSoon';
import ChangePassword from './ChangePassword';
import { setLeftFilter } from '../../redux/message'
import { IoChevronDown } from "react-icons/io5";
import { Scrollbars } from 'react-custom-scrollbars';
import { Tooltip } from 'react-tooltip';
import InitialOnboardingImpressions from '../TipsImpressions/InitialOnboardingImpressions';
import LeftBarProject from './LeftBarProject';
import { BiSolidReport } from "react-icons/bi";
import { GrVmMaintenance } from "react-icons/gr";
import LeftBarEod from '../EodReport/LeftBarEod';
import LeftBarMaintenance from '../Maintenance/LeftBarMaintenance';
import { MdAdminPanelSettings } from "react-icons/md";
import { isValidConvId } from '../../Utils/Common';
import { MUTE_CONVERSATION_MUTATION } from '../../Utils/GraphqlMutaion';
import usePolling from '../../hooks/usePolling';

export const LeftSection = ({ me }) => {
	const params = useParams();
	const userMe = useSelector(state => state.message.user)
	const filterLeft = useSelector(state => state.message.filterLeft);
	const dispatch = useDispatch();
	const countersdata = useSelector(state => state.message.countersdata);
	const updateVersion_data = useSelector(state => state.message.updateVersion_data);
	const archive_conversations = useSelector(state => state.message.archive_conversations);
	const unreadConversation_ids = countersdata?.conversations?.filter(v => v.urreply > 0).map(c => c.conversation_id) || [];
	const all_Conversation_ids = countersdata?.all_flag_conversation || [];
	const { data: companiesData } = useQuery(GET_ALL_COMPANIES, { variables: { email: me.email }, fetchPolicy: 'no-cache' });
	const { loading: LeftRoomsLoading, data: LeftRooms } = useQuery(GET_LEFT_ROOMS, { variables: { userId: me.id }, fetchPolicy: 'no-cache' });
	const conversations = useSelector(state => state.message.conversations)
	const [leftSearch, setLeftSearch] = useState(false);
	const [startDiscussion, setStartDiscussion] = useState(false);
	const [searchStr, setSearchStr] = useState('');
	const [filter_status, setFilter_status] = useState('');
	const [comingSoon, setComingSoon] = useState(false);
	const [titleText, setTitleText] = useState(false);
	const [archiveRoom, setArchiveRoom] = useState(false);
	const [clearMute] = useMutation(MUTE_CONVERSATION_MUTATION);

	const handleMuteCheck = async () => {
		const mutedConv = conversations
			?.filter(f => f.has_mute?.includes(me?.id))
			?.map(f => f.mute)
			?.flat();

		const parsedMuteData = mutedConv
			?.map(item => {
				try {
					// Parse the string as a JSON array
					return JSON.parse(`[${item}]`);
				} catch (error) {
					console.error("Failed to parse mute data:", item, error);
					return null;
				}
			})
			.flat() // Flatten the parsed arrays into a single array
			.filter(Boolean); // Remove any null values

		// console.log("🚀 ~ handleMuteCheck ~ parsedMuteData:", parsedMuteData);

		const currentTimeUnix = Math.floor(Date.now() / 1000);

		for (const mute of parsedMuteData) {
			const { conversation_id, mute_by, mute_unix_end } = mute;

			if (mute_by === me?.id && currentTimeUnix > parseInt(mute_unix_end, 10)) {
				console.log(`Conditions matched for conversation: ${conversation_id}. Calling unmute function.`);

				const input = {
					conversation_id,
					type: "delete",
				};

				try {
					let apires = await clearMute({ variables: { input } });
					console.log("🚀 ~ handleMuteCheck ~ apires:", apires.data.mute_conversation.message);
				} catch (error) {
					console.error("Error clearing mute:", error);
				}
			}
		}
	};
	usePolling(handleMuteCheck, 60000); // Call every 1 minutes


	useEffect(() => {
		if (LeftRooms) {
			dispatch(set_conversations(LeftRooms?.rooms))
		}
	}, [LeftRooms])

	const clearSearch = () => {
		setSearchStr('');
	};

	const [settingOptionsPopup, setSettingOptionsPopup] = useState(false);
	const [filterOptions, setFilterOptions] = useState(false);
	const [visible_convs, setVisibleConv] = useState([]);

	useEffect(() => {
		if (conversations) {
			setVisibleConv(conversations?.filter(f => (filter_status === 'single' && f.group === 'no') || (filter_status === 'group' && f.group === 'yes') || (filter_status === 'createdByMe' && f.created_by === me?.id) || (filter_status === 'createdByOther' && f.created_by !== me?.id) || (filter_status === '' && true)).filter(r => r.archive === 'no'))
		}
	}, [conversations, filter_status])

	const location = useLocation();
	const isAdminSettingsRoute = location.pathname === '/connect/admin-settings' || location.pathname === '/connect/team-management' || location.pathname === '/connect/room-category' || location.pathname === '/connect/shared-team-tags' || location.pathname === '/connect/marge-company' || location.pathname === '/connect/rollback-company' || location.pathname === '/connect/role-manage' || location.pathname === '/connect/module-management' || location.pathname === '/connect/venue-management' || location.pathname === '/connect/business-management' || location.pathname === '/connect/subscription-billing';

	const navigate = useNavigate()

	const handleGoBack = () => {
		navigate('/connect/' + userMe.id)
	}

	const backToNormal = () => {
		let last_conv = localStorage.getItem('last_active_conv')
		let _id = isValidConvId(last_conv) ? last_conv : params.conversation_id
		dispatch(setLeftFilter('normal'))
		setArchiveRoom(false)
		navigate(`/connect/${_id}`)
	}

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Escape') {
				setComingSoon(false);
			}
		};
		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const [changePasswordPopup, setChangePasswordPopup] = React.useState(false);
	const handleChangePasswordPopup = () => {
		setSettingOptionsPopup(false);
		setChangePasswordPopup(true);
	}

	useEffect(() => {
		if (filterLeft !== 'normal') {
			if (filterLeft === 'threaded') {
				let threaded = visible_convs?.filter(r => unreadConversation_ids.indexOf(r.conversation_id) > -1)
				if (threaded.length > 0) navigate(`/connect/${threaded?.[0]?.conversation_id}/filter/unread_reply`);
			} else {
				let flagged = visible_convs?.filter(r => all_Conversation_ids.indexOf(r.conversation_id) > -1)
				if (flagged.length > 0) navigate(`/connect/${flagged?.[0]?.conversation_id}/filter/flag`);
			}
		}

	}, [filterLeft])

	useEffect(() => {
		if (params.filter_name && params.filter_name === 'unread_reply') {
			dispatch(setLeftFilter('threaded'))
		}
	}, [params.filter_name])


	const [pinListOpen, setPinListOpen] = useState(true)

	const [theme, setTheme] = useState('light');

	useEffect(() => {
		let type = localStorage.getItem("theme");
		if (type === 'dark') {
			setTheme('dark');
			document.querySelector('#root').classList.add('dark');
		} else {
			setTheme('light');
			document.querySelector('#root').classList.remove('dark');
		}
	}, [])

	const [tipsAndTour, setTipsAndTour] = useState(false);

	// const [connectPageTips, setConnectPageTips] = useState(false);
	// const [tipPopup, setTipPopup] = useState("one");

	// let type = localStorage.getItem("getStarted");

	// useEffect(() => {
	// 	if (type === 'true') {
	// 		setConnectPageTips(true);

	// 		console.log('setConnectPageTips', connectPageTips)
	// 	}
	// 	else {
	// 		setConnectPageTips(false);
	// 	}
	// }, [type])

	// const handleCloseTips = () => {
	// 	setConnectPageTips(false);
	// 	localStorage.setItem("getStarted", false);
	// }

	const handleStartDiscussion = () => {
		setStartDiscussion(true);
		clearSearch();
	}


	const [getArchiveConv] = useLazyQuery(All_archive, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			console.log("🚀 ~ LeftSection ~ data:", data)
			if (data.all_archive.status) {
				dispatch(set_archive_conversations(data.all_archive.data))
				if (data.all_archive.data.length > 0) {
					navigate(`/connect/${data.all_archive?.data[0]?.conversation_id}`)
				}
			}
		}
	})

	const getArchiveConversations = async () => {
		try {
			getArchiveConv()
		} catch (error) {
			console.log("🚀 ~ getArchiveConversations ~ error:", error)
		}
	}

	const handleFlaggedLeftFilter = () => {
		dispatch(setLeftFilter('flagged'))
	}

	const handleThreadedMessagesFilter = () => {
		dispatch(setLeftFilter('threaded'))
	}

	const getTaskLinkClass = () => {
		const activePaths = [
			'/connect/tasks/kanban-view',
			'/connect/tasks/list-view',
			'/connect/tasks/notifications',
			'/connect/tasks',
			'/connect/tasks/create-task'
		];
		return activePaths.includes(location.pathname) ? '!bg-[#435574]' : '';
	};

	const getFileHubLinkClass = () => {
		const activePaths = [
			'/connect/file_hub/tags',
			'/connect/file_hub/files',
			'/connect/file_hub/links',
			'/connect/file_hub',
		];
		return activePaths.includes(location.pathname) ? '!bg-[#435574]' : '';
	};
	const getEODClass = () => {
		const activePaths = [
			'/connect/daily-report',
		];
		return activePaths.includes(location.pathname) ? '!bg-[#435574]' : '';
	};
	const getMaintenanceClass = () => {
		const activePaths = [
			'/connect/maintenance',
		];
		return activePaths.includes(location.pathname) ? '!bg-[#435574]' : '';
	};
	const getExpensesClass = () => {
		const activePaths = [
			'/connect/expenses',
		];
		return activePaths.includes(location.pathname) ? '!bg-[#435574]' : '';
	};

	// List of routes where the div should be visible
	const visibleRoutes = [
		'/connect/tasks/kanban-view',
		'/connect/tasks/list-view',
		'/connect/tasks/notifications',
		'/connect/tasks',
		'/connect/tasks/create-task',
		'/connect/file_hub/tags',
		'/connect/file_hub/files',
		'/connect/file_hub/links',
		'/connect/file_hub',
		'/connect/daily-report',
		'/connect/maintenance',
	];

	// Check if the current path matches any of the visible routes
	const shouldFilteredDiv = visibleRoutes.includes(location.pathname);

	return (
		<>
			<div className="left_container">
				<div className="left_top_bar">
					<div className="logoSection">
						<div className="freeli_logo" onClick={() => navigate('/connect')} />
						<div className='switch_section'>
							{companiesData?.companies?.length > 1 && <Link to="/connect/switch-account" className="avatar-group-area" title='Switch Account'>
								<span className="ovel_circle"></span>
								<div className="avatar" style={{ backgroundColor: 'rgb(166, 208, 104)' }}>I</div>
								<div className="avatar" style={{ backgroundColor: 'rgb(255, 153, 136)' }}>A</div>
							</Link>}
							{companiesData?.companies?.length > 1 && <Link to="/connect/switch-account" title='Switch Account'><div className="companySwitch"></div> </Link>}
						</div>

					</div>
					{updateVersion_data === "yes" ? (
						<div className="backwrap">
							<div className="upgrade_section">
								<div className="reloadIcon"></div>
								<div className="upgrade_title">
									New application version detected
								</div>
								{/* <div className="upgrade_body"> Clearing old browser cache and reloading user data.</div> */}
								<div className="upgrade_btn">
									<button
										name="upgrade_btn"
										onClick={() => {
											window.location.reload(true);
											setInterval(() => window.location.reload(true), 3000);
										}}
									>
										{" "}
										Update{" "}
									</button>
								</div>
							</div>
						</div>
					) : (
						""
					)}
					<div className="filtrSection">

						{!leftSearch && (
							<div className={'userLogo'}><img src={me.img} alt="user" title='user' /></div>
						)}
						<div className="userInfo_area" >
							<p className="userName_area">
								{`${me.firstname} ${me.lastname}`}
							</p>
							<p className="userName_company">{me.company_name}</p>
						</div>
						{!leftSearch && (
							<span className="flipNavIcon tooltip5"
								onClick={() => setSettingOptionsPopup(true)}></span>
						)}
					</div>
					<div className="leftBtn_area">

						<Link to="#">
							<div onClick={() => { setTitleText('Dashboard'); setComingSoon(true); }} className="Rectangle-btn calendar !justify-start">
								<div className="dashboardIconForLeft"></div>
								<div className="textLabel">Dashboard</div>
							</div>
						</Link>
						{/* {cacheMeData.me.access.indexOf('CALENDAR') > -1 ?  */}
						<Link to="#">
							<div onClick={() => { setTitleText('Calendar'); setComingSoon(true); }}>
								<div className="Rectangle-btn calendar !justify-start">
									<div className="calendarIconForLeft"></div>
									<div className="textLabel">Calendar</div>
								</div>
							</div>
						</Link>
						{/* : ''} */}

						{/* {cacheMeData.me.access.indexOf('TASK') > -1 ?  */}
						<Link to="/connect/tasks/kanban-view">
							<div className='nodecoration'>
								<div className={`Rectangle-btn !justify-start tasks ${getTaskLinkClass()}`}>
									<div className="taskIconForLeft"></div>
									<div className="textLabel">Tasks</div>
									{countersdata?.taskunreadcount > 0 && <span className="notificationsCircleTask"></span>}
								</div>
							</div>
						</Link>
						{/* : ''} */}
						<Link to="/connect/file_hub/tags">
							<div className={`Rectangle-btn !justify-start filehub ${getFileHubLinkClass()}`}>
								<div className="fileHubIconForLeft"></div>
								<div className="textLabel">File Hub</div>
							</div>
						</Link>
						{/* {cacheMeData.me.access.indexOf('EOD') > -1 ?  */}
						<Link to="/connect/daily-report">
							<div className={`Rectangle-btn !justify-start filehub ${getEODClass()}`}>
								<div className=""><BiSolidReport className='text-[22px] text-white' /></div>
								<div className="textLabel">Sales</div>
							</div>
						</Link>
						{/* : ''} */}
						{/* <Link to="/connect/maintenance" className="nodecoration"> */}
						<Link to="#" className="nodecoration">
							<div onClick={() => { setTitleText('R & M'); setComingSoon(true); }} className={`Rectangle-btn !justify-start filehub ${getMaintenanceClass()}`}>
								<div className=""><GrVmMaintenance className='text-[22px] text-white' /></div>
								<div className="textLabel">R & M</div>
							</div>
						</Link>
						<Link to="#" className="nodecoration">
							<div onClick={() => { setTitleText('Expenses'); setComingSoon(true); }} className={`Rectangle-btn !justify-center filehub ${getExpensesClass()}`}>
								<div className="expensesIcon"></div>
								<div className="textLabel">Expenses</div>
							</div>
						</Link>
					</div>

					<div className='left_bottom_section'>
						{/* Left search section  */}
						{filterLeft === 'normal' || filterLeft === 'threaded' || filterLeft === 'flagged' ?
							<div className='left_top_bar maiLeftBar !border-b-0'>
								{!isAdminSettingsRoute &&
									<div className="leftSearchCon">
										<div className={`leftSearchLeft ${shouldFilteredDiv ? 'fullWidth' : ''}`}>
											<span className="leftSearchActionBtn"></span>
											<input className="leftSearchInput" type="text" placeholder="Search" value={searchStr} onChange={(e) => setSearchStr(e.target.value)} />
											{searchStr && (
												<span className="leftSearchCloseBtn remove !top-[6px]" data-for="left_tooltip" data-tip="Clear Search" onClick={clearSearch} currentItem="false"></span>
											)}
										</div>
										{!shouldFilteredDiv &&
											<div className={`leftSearchRight ${filter_status !== '' && 'filtered'}`} onClick={() => setFilterOptions(!filterOptions)}></div>
										}
									</div>
								}
							</div> : ""
						}

						{(location.pathname.indexOf('/connect/tasks') > -1) ?
							<LeftBarProject
								setSearchStr={setSearchStr}
								searchStr={searchStr}
							/>
							:
							(location.pathname === '/connect/daily-report') ?
								<LeftBarEod
									setSearchStr={setSearchStr}
									searchStr={searchStr}
								/>
								:
								(location.pathname === '/connect/maintenance') ?
									<LeftBarMaintenance
										setSearchStr={setSearchStr}
										searchStr={searchStr}
									/>
									:
									<div className='room_list_area'>
										{!archiveRoom ?
											<>
												{filterLeft === 'normal' && !isAdminSettingsRoute ?
													<div className='border-b border-b-[#475674] '>
														<ul>
															<li className="conv_list strict flagPanel" onClick={handleFlaggedLeftFilter}>All Flagged Messages</li>
														</ul>
														{countersdata?.total_unread_reply > 0 &&
															<div className="threadMsgBar threadedMessages" onClick={handleThreadedMessagesFilter}>
																<div className="thread_button"></div>
																<span className="threadMsgText">Threaded Messages </span>
																<span className="unreadCounter">{countersdata.total_unread_reply}</span>
															</div>
														}
													</div> : ""
												}
											</>
											:
											<>
												<div className='archiveBorderTop'></div>
												<div onClick={backToNormal} className="!mt-4 !-mb-2 list_container_title bactToAllArchive !pl-[60px]">
													<div className="archive_ico !-ml-10 !mr-2"></div>
													Archives Room
												</div>
											</>
										}
										{!archiveRoom ?
											<>
												{filterLeft !== 'normal' && !isAdminSettingsRoute &&

													<div className="left_adminList">

														<div onClick={() => backToNormal()} className="!mt-4 !-mb-2 borderTop list_container_title bactToAll">
															<span className="backTomain"></span>
															<div className={` ${filterLeft === 'threaded' ? 'filterThreadedMessages' : 'filterFlaggedMessages -mt-1'} `}></div>
															{filterLeft === 'threaded' ? 'Threaded Messages' : 'All Flagged Messages'}
														</div>

														<div className="borderLine"></div>

														<div className="group_container adminLists" style={{ height: 'calc(100vh - 562px)' }} >
															<ul className="list_container settingsList !h-[100%]">
																<Scrollbars className="custom-scrollbars threadAndFllaged" renderThumbVertical={({ style, ...props }) => (
																	<div className="track" {...props} style={{ ...style, backgroundColor: "#318fff", borderRadius: "50px", }} />
																)} autoHide={false}>
																	{filterLeft === 'threaded' ?
																		<>
																			<LeftRoomsList user_id={me.id} rooms={visible_convs?.filter(r => unreadConversation_ids.indexOf(r.conversation_id) > -1)?.filter(f => (f.title.toLowerCase().includes(searchStr.trim().toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.trim().toLowerCase())).length > 0))} type="threaded" />
																			{
																				visible_convs?.filter(r => unreadConversation_ids.indexOf(r.conversation_id) > -1)?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0)).length === 0 &&
																				<h2 className="text-center text-sm text-white mt-10">No matching result found</h2>
																			}
																		</>
																		:
																		<>
																			<LeftRoomsList user_id={me.id} rooms={visible_convs?.filter(r => all_Conversation_ids.indexOf(r.conversation_id) > -1)?.filter(f => (f.title.toLowerCase().includes(searchStr.trim().toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.trim().toLowerCase())).length > 0))} type="flagged" />
																			{
																				visible_convs?.filter(r => all_Conversation_ids.indexOf(r.conversation_id) > -1)?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0)).length === 0 &&
																				<h2 className="text-center text-sm text-white mt-10">No matching result found</h2>
																			}
																		</>
																	}
																</Scrollbars>
																<div onClick={backToNormal} className='absolute w-full bottom-[-48px] bg-[#2F2A6B] text-white p-3 z-50 cursor-pointer'>
																	<div className='flex gap-1 pb-[1px]'>
																		<div className='bactToAllArchiveBottom'></div>
																		<div className='text-sm'>Back to navigation</div>
																	</div>
																</div>
															</ul>

														</div>

													</div>


												}

												{isAdminSettingsRoute ?
													<div className="left_adminList">
														<div onClick={handleGoBack} className="!mt-4 !-mb-2 borderTop list_container_title bactToAll">
															<span className="backTomain"></span>
															<MdAdminPanelSettings className=' absolute left-[20px] text-[#ffffff] text-[20px]' />
															Admin settings
														</div>


														<div className="borderLine"></div>
														<div style={{ height: 'calc(100vh - 472px)' }} className={'group_container_admin'}>
															<Scrollbars
																className="custom-scrollbars"
																renderThumbVertical={({ style, ...props }) => (
																	<div className="track" {...props} style={{ ...style, backgroundColor: "#318fff", borderRadius: "50px", }} />
																)}
																autoHide={false}
															>
																<div className="pb-3">
																	<Link to='/connect/admin-settings' title='User management' className={`conv_list ${location.pathname === '/connect/admin-settings' ? 'selected' : ''}`}>User management</Link>
																	<Link to='/connect/team-management' title='Team management' className={`conv_list ${location.pathname === '/connect/team-management' ? 'selected' : ''}`}>Team management</Link>
																	<Link to='/connect/room-category' title='Room categories' className={`conv_list ${location.pathname === '/connect/room-category' ? 'selected' : ''}`}>Room categories</Link>
																	<Link to='/connect/venue-management' title='Venue management' className={`conv_list ${location.pathname === '/connect/venue-management' ? 'selected' : ''}`}>Venue management</Link>
																	<Link to='/connect/shared-team-tags' title='Shared team tags' className={`conv_list ${location.pathname === '/connect/shared-team-tags' ? 'selected' : ''}`}>Shared team tags</Link>
																	<Link to='/connect/subscription-billing' title='Subscription and billing' className={`conv_list ${location.pathname === '/connect/subscription-billing' ? 'selected' : ''}`}>Subscription and billing</Link>
																	<Link to='/connect/business-management' title='Business management' className={`conv_list ${location.pathname === '/connect/business-management' ? 'selected' : ''}`}>Business account</Link>

																</div>
															</Scrollbars>
														</div>
													</div>
													:
													<>
														{filterLeft === 'normal' &&
															<div className={'group_container'} style={countersdata?.total_unread_reply > 0 ? { height: 'calc(100vh - 553px)', overflowY: 'auto' } : { height: 'calc(100vh - 514px)', overflowY: 'auto' }}>
																<Scrollbars
																	className="custom-scrollbars"
																	renderThumbVertical={({ style, ...props }) => (
																		<div className="track"
																			{...props}
																			style={{ ...style, backgroundColor: "#318fff", borderRadius: "50px", }}
																		/>
																	)}
																	autoHide={false}
																>
																	<div className="list_container pinned_list">
																		<h3 className="list_container_title pinBar" onClick={() => setPinListOpen(!pinListOpen)}>Pinned
																			<span className="downArrowLeftSide">
																				<IoChevronDown style={{ transform: `rotate(${pinListOpen ? 180 : 0}deg)` }} className='text-2xl' />
																			</span>
																		</h3>
																		{
																			pinListOpen && <>
																				{!LeftRoomsLoading &&
																					<LeftRoomsList user_id={me.id} rooms={visible_convs?.filter(r => r.conversation_id === me.id)?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0))} type="my" />
																				}
																				{!LeftRoomsLoading &&
																					<LeftRoomsList user_id={me.id} rooms={visible_convs?.filter(r => r.pin.indexOf(me.id) > -1 && r.conversation_id !== me.id)?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0))} type="pin" />
																				}
																			</>
																		}

																	</div>
																	<div className="list_container create_conv_list">
																		<h3 className="list_container_title create_conv ">
																			<span> Rooms</span>
																			{me.role !== 'Guest' &&
																				<span onClick={handleStartDiscussion} className="createIcon tooltip5" id='startDiscussion'></span>
																			}
																		</h3>
																		{!LeftRoomsLoading &&
																			<LeftRoomsList user_id={me.id} rooms={visible_convs?.filter(r => r.pin.indexOf(me.id) === -1 && r.conversation_id !== me.id)?.filter(f => (f.title.toLowerCase().includes(searchStr.trim().toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.trim().toLowerCase())).length > 0)).filter(f => f.archive === 'no')} type="pin" />
																		}

																		{
																			visible_convs?.filter(r => r.pin.indexOf(me.id) === -1 && r.conversation_id !== me.id)?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0)).length === 0 &&
																			<h2 className="text-center text-sm text-white">No matching result found</h2>
																		}
																	</div>
																</Scrollbars>
															</div>
														}
													</>
												}
											</>
											:
											<>
												<div className='group_container archiveLastChild' style={{ height: 'calc(100vh - 400px)', overflowY: 'auto' }}>
													<Scrollbars className="custom-scrollbars archiveRoomScroll" renderThumbVertical={({ style, ...props }) => (
														<div className="track" {...props} style={{ ...style, backgroundColor: "#318fff", borderRadius: "50px", }} />
													)} autoHide={false}>

														{archive_conversations?.filter(f => (f.title.toLowerCase().includes(searchStr.toLowerCase()) || f.participants_name?.filter(n => n.toLowerCase().includes(searchStr.toLowerCase())).length > 0)).map((v, i) =>
															<Link to={"/connect/" + v.conversation_id} title='TITLE' className={`archive_room_con`} key={v.conversation_id + '_archive_conversation'}>
																<ul>
																	<li className={`conv_list private ${params.conversation_id === v.conversation_id && 'selected'}`}>
																		<span className="conv_list_title own">{v.title} </span>
																		<span className="conv_list_Opt"></span>
																		<span className="conv_list_Opt">
																			<span className="archive_ico"></span>
																		</span>
																	</li>
																</ul>
															</Link>
														)}
													</Scrollbars>
												</div>
												<div onClick={backToNormal} className='absolute w-full bottom-[100px] bg-[#2F2A6B] text-white p-3'>
													<div className='flex gap-1 pb-[1px]'>
														<div className='bactToAllArchiveBottom'></div>
														<div className='text-sm'>Back to navigation</div>
													</div>
												</div>
											</>
										}
									</div>
						}
					</div>
				</div>
			</div>
			{startDiscussion && <StartDiscussionPopup setStartDiscussion={setStartDiscussion} />}
			{settingOptionsPopup && <SettingsOptions setComingSoon={setComingSoon} setTitleText={setTitleText} titleText={titleText} companiesData={companiesData} setArchiveRoom={setArchiveRoom} getArchiveConversations={getArchiveConversations} setTipsAndTour={setTipsAndTour} setSettingOptionsPopup={setSettingOptionsPopup} handleChangePasswordPopup={handleChangePasswordPopup} />}
			{filterOptions && <LeftFilterOptions setFilterOptions={setFilterOptions} filter_status={filter_status} setFilter_status={setFilter_status} />}
			{comingSoon && <ComingSoon titleText={titleText} setComingSoon={setComingSoon} />}
			{changePasswordPopup && <ChangePassword onClose={() => setChangePasswordPopup(false)} user={me} from_admin={false} />}
			{tipsAndTour && <InitialOnboardingImpressions setTipsAndTour={setTipsAndTour} />}

			<Tooltip className='z-[9999]' anchorSelect="#startDiscussion" float="true" content="Start a discussion"></Tooltip>
		</>
	)
}