


/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { DELETE_CHECKLIST, Read_task_notification, SAVE_UPDATE_CHECKLIST } from '../../../Utils/GraphqlMutaion';
import { getONLYUSERSDATA } from '../../../Utils/Common';
import { toast } from 'react-hot-toast';
import { useTaskContext } from '../TaskContext';
import { GoSearch } from 'react-icons/go';
import { GoTrash } from "react-icons/go";
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';

const TaskCheckListPopup = ({ setTrackCheckListPopup }) => {
    const { setSelectedTask, set_tasks, selectedTask, users, user } = useTaskContext();
    const [checklistFilter, setChecklistFilter] = useState(false);
    const [save_update] = useMutation(SAVE_UPDATE_CHECKLIST);
    const [delete_item] = useMutation(DELETE_CHECKLIST);

    const ref = useClickAway(() => {
        setChecklistFilter(false);
    });

    const [read_notification] = useMutation(Read_task_notification, {
        onCompleted: (res) => {
            if (res.read_task_notification.success === 'true') {
                setSelectedTask(prev => ({ ...prev, view_checklist: [] }))
                set_tasks(prev => prev.map(m => m._id === selectedTask._id ? { ...m, view_checklist: [] } : m))
            }
        }
    });

    useEffect(() => {
        if (selectedTask && selectedTask?.view_checklist?.indexOf(user?.id) > -1) {
            read_notification({
                variables: { input: { _id: selectedTask?._id, type: 'view_checklist' } }
            }); // Read the notification
        }
    }, [selectedTask, user, read_notification]);

    const [editItemId, setEditItemId] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [newText, setNewText] = useState('');

    const [filterType, setFilterType] = useState('all'); // Default: Show all
    const [sortType, setSortType] = useState('');
    const [searchCheckList, setSearchCheckList] = useState('');

    const updateParams = async (prms) => {
        let apires = await save_update({
            variables: {
                input: {
                    task_id: selectedTask?._id,
                    ...prms,
                },
            },
        });

        if (apires.data.save_update_checklist.status) {
            toast.success('Checklist updated successfully!');
            setSelectedTask((prev) => ({
                ...prev,
                checklists: prev.checklists.map((item) => item._id === prms._id ? { ...item, [Object.keys(prms)[1]]: prms[Object.keys(prms)[1]] } : item)
            }));
            setEditItemId('');
            setEditTitle('');
        }
    };

    const handleEditItem = (item) => {
        setEditItemId(item._id);
        setEditTitle(item.item_title);
    };

    const handleRemoveItem = async (itemId) => {
        await delete_item({
            variables: {
                input: { _id: itemId },
            },
        });
        setSelectedTask((prev) => ({
            ...prev,
            checklists: prev.checklists.filter((item) => item._id !== itemId)
        }));
    };

    const handleAddItem = async () => {
        if (newText.trim() !== '') {
            let apires = await save_update({
                variables: {
                    input: {
                        task_id: selectedTask?._id,
                        item_title: newText.trim(),
                    },
                },
            });

            if (apires.data.save_update_checklist.status) {
                setSelectedTask((prev) => ({
                    ...prev,
                    checklists: [...prev.checklists, apires.data.save_update_checklist.data]
                }));
            }
            setNewText('');
        }
    };

    const handleKeyup = (e, type) => {
        if (e.key === 'Enter') {
            if (type === 'add') {
                handleAddItem();
            } else if (editTitle.trim() !== '') {
                updateParams({ _id: editItemId, item_title: editTitle.trim() });
            }
        }
    };

    const filteredChecklists = selectedTask.checklists.filter((item) => {
        if (filterType === 'created_by_me') {
            return item.created_by === user?.id;
        } else if (filterType === 'created_by_others') {
            return item.created_by !== user?.id;
        }
        return true;

    }).filter((item) =>
        item.item_title.toLowerCase().includes(searchCheckList.toLowerCase())
    );

    const sortedChecklists = [...filteredChecklists].sort((a, b) => {
        if (sortType === 'asc') {
            return a.item_title.localeCompare(b.item_title);
        } else if (sortType === 'desc') {
            return b.item_title.localeCompare(a.item_title);
        }
        return new Date(a.created_at) - new Date(b.created_at);
    });

    const complete = ((selectedTask.checklists.filter((f) => f.status === 'complete').length / selectedTask.checklists.length) * 100).toFixed(0);

    // Export checklist to CSV
    const exportChecklist = () => {
        const csvContent = [
            ['Item Title', 'Status', 'Created By', 'Created At'].join(','), // Header row
            ...filteredChecklists.map((item) =>
                [
                    item.item_title,
                    item.status,
                    getONLYUSERSDATA(users, item.created_by, 'name'), // Get created_by name
                    new Date(item.created_at).toLocaleString(),
                ].join(',')
            ),
        ].join('\n');

        // Create a blob and trigger a download
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'checklist.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        setChecklistFilter(false);
    };

    const handleChecklistInputChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            setNewText(value); // Allow clearing the input
            return;
        }

        // Validate the input
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title'); // Log or show an error message
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setNewText(trimmedValue);
    };

    const handleEditInputChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            setEditTitle(value); // Allow clearing the input
            return;
        }

        // Validate the input
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title'); // Log or show an error message
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setEditTitle(trimmedValue);
    };

    const completed = sortedChecklists.filter((f) => f.status === 'complete')
    const inCompleted = sortedChecklists.filter((f) => f.status === 'incomplete')

    return (
        <div className="trackCostPopUpCon">
            <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
                <div className="backToMainProperties">
                    <span
                        onClick={() => setTrackCheckListPopup(false)}
                        className="closeAdminPanel mt-2 cursor-pointer"
                        data-for="descloseTooltip"
                        data-tip="Back"
                    ></span>
                    <span className="backtoProperties">Checklist ({selectedTask.checklists.length})</span>
                </div>

                <div className="searchareaDiv" style={{ paddingLeft: '80px' }}>
                    <input
                        type="text"
                        className="_inputBar searchLeft inObserverSearch !bg-[#F1F6FA]"
                        value={searchCheckList}
                        onChange={(e) => setSearchCheckList(e.target.value)}
                        placeholder="Search a checklist"
                        style={{ width: '180px' }}
                    />
                    {searchCheckList ?
                        <span onClick={() => setSearchCheckList("")} className="removeFile !absolute !right-[10px] !h-[18px] !float-right !mt-[-30px] !block !top-[38px]"
                        ></span> :
                        <GoSearch className='text-[#102565] !absolute !right-[10px] !h-[18px] !float-right !mt-[-30px] !block !top-[38px]' />
                    }
                </div>

                {
                    sortedChecklists.length > 0 &&
                    <div className="Track_save checkExport preventDefault absolute top-4 right-11 !w-[65px]" onClick={exportChecklist}>Export </div>
                }

                <span onClick={() => setChecklistFilter(!checklistFilter)} className="adminUserFilter forChecklist" style={{ top: '15px', right: '5px', left: 'unset' }}></span>
                {checklistFilter && (
                    <div ref={ref} className="absolute top-0 right-[245px]">
                        <ul className="userActionPopup showAll checklistPopup !top-[45px]">
                            <li onClick={() => { setFilterType('all'); setChecklistFilter(false); }} className={`btn !text-left ${filterType === 'all' ? 'active' : ''}`} >Show all</li>
                            <li onClick={() => { setFilterType('created_by_me'); setChecklistFilter(false); }} className={`btn !text-left ${filterType === 'created_by_me' ? 'active' : ''}`} >Created by me</li>
                            <li onClick={() => { setFilterType('created_by_others'); setChecklistFilter(false); }} className={`btn !text-left ${filterType === 'created_by_others' ? 'active' : ''}`} > Created by others </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className="checkList_first_row">
                {
                    sortedChecklists.length > 0 &&
                    <div className={`_userName ${sortType === 'desc' ? 'asce sort_active' : 'desc sort_active'}`}
                        style={{ width: '5%' }}
                        onClick={() => setSortType((prev) => (prev === '' || prev === 'desc' ? 'asc' : 'desc'))}
                    >
                        <span className="sortIcons" style={{ cursor: 'pointer' }}></span>
                    </div>
                }

                <div className="yourProgress taskProgress">{`${isNaN(complete) ? 0 : complete} % Complete`}</div>
                <div className="progress_bar taskBar" style={{ width: '65%' }}>
                    <div style={{ height: '10px', width: '100%', backgroundColor: 'rgb(227, 242, 254)', borderRadius: '13px' }}>
                        <div style={{
                            height: '100%',
                            width: `${isNaN(complete) ? 0 : complete}%`,
                            backgroundColor: 'rgb(166, 208, 104)',
                            borderRadius: '13px',
                        }}
                        ></div>
                    </div>
                </div>
                <div className="checkList_fr_left taskCount" style={{ width: '5%' }}>
                    <div className="checkList_items !mt-[-10px]">
                        <span className="checkList_checked_items">
                            {`${filteredChecklists.filter((f) => f.status === 'complete').length}/${filteredChecklists.length}`}
                        </span>
                    </div>
                </div>
            </div>

            <div className="checkList_area taskCheckListDiv overflow-y-auto" style={{ height: 'calc(100% - 168px)' }}>
                {
                    sortedChecklists.length > 0 ?
                        <>
                            <p className="checklist_status_area">Completed <span className="unreadCounter colorChange">{completed.length}</span></p>
                            {completed.length > 0 ?
                                completed.map(item =>
                                    <Checklist
                                        key={item._id}
                                        user={user}
                                        item={item}
                                        updateParams={updateParams}
                                        editItemId={editItemId}
                                        handleEditItem={handleEditItem}
                                        handleRemoveItem={handleRemoveItem}
                                        editTitle={editTitle}
                                        handleEditInputChange={handleEditInputChange}
                                        handleKeyup={handleKeyup}
                                        setEditItemId={setEditItemId}
                                        setEditTitle={setEditTitle}
                                    />
                                )
                                :
                                <p className="notFoundData !mt-[10px]">No completed checklist found</p>
                            }
                            <p className="checklist_status_area incompleted">Not Completed <span className="unreadCounter colorChange">{inCompleted.length}</span></p>
                            {inCompleted.length > 0 ?
                                inCompleted.map(item =>
                                    <Checklist
                                        key={item._id}
                                        user={user}
                                        item={item}
                                        updateParams={updateParams}
                                        editItemId={editItemId}
                                        handleEditItem={handleEditItem}
                                        handleRemoveItem={handleRemoveItem}
                                        editTitle={editTitle}
                                        handleEditInputChange={handleEditInputChange}
                                        handleKeyup={handleKeyup}
                                        setEditItemId={setEditItemId}
                                        setEditTitle={setEditTitle}
                                    />
                                )
                                :
                                <p className="notFoundData !mt-[10px]">No checklist found</p>
                            }
                        </>
                        :
                        <div className="text-center text-gray-500 py-32">No checklist items found.</div>
                }
            </div>

            <div className="inputvalAtewa absolute bottom-0">
                <div className="inputCheckBoxVal">
                    <input
                        className="addCheckbox checklist"
                        onChange={handleChecklistInputChange}
                        onKeyUp={(e) => handleKeyup(e, 'add')}
                        value={newText}
                        id="checkListInput"
                        placeholder="Enter a checklist item"
                    />
                    <span
                        onClick={handleAddItem}
                        className="checkList_plusIcon !top-[10px]"
                        data-for="checkList_tooltip"
                        data-tip="Click to create checklist"
                    >
                        <i className="fa fa-plus custom_icon"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

const Checklist = ({ user, item, updateParams, editItemId, handleEditItem, handleRemoveItem, editTitle, handleEditInputChange, handleKeyup, setEditItemId, setEditTitle }) => {
    return (
        <div className={`checklistArea relative ${item.status === 'complete' && '!border-b !border-b-[#e5eef4]'}`}>
            <div className="markCheckList">
                <input
                    type="checkbox"
                    className="checklistMarker"
                    id={`chkbox_${item._id}`}
                    checked={item.status === 'complete'}
                    onChange={() =>
                        updateParams({
                            _id: item._id,
                            status: item.status === 'complete' ? 'incomplete' : 'complete',
                        })
                    }
                    disabled={editItemId === item._id}
                />
            </div>
            <div className="checklist_label">
                {editItemId === item._id ? (
                    <input
                        value={editTitle}
                        onChange={handleEditInputChange}
                        onKeyUp={(e) => handleKeyup(e, 'edit')}
                        onBlur={() => {
                            setEditItemId('');
                            setEditTitle('');
                        }}
                        type="text"
                        className="w-[95%] h-[100%] text-[#0C2568] border border-[#e3f2ff] px-2"
                        autoFocus
                    />
                ) : (
                    <p className="labelText" id={`itemtitle_${item._id}`}>
                        {item.item_title}
                    </p>
                )}
            </div>

            {(editItemId !== item._id && user?.id === item.created_by) &&
                <div className="checklistAction !border-none">
                    {item.status !== 'complete' && (
                        <div className="editFileTaskCheck" onClick={() => handleEditItem(item)} ></div>
                    )}
                    <GoTrash className=" absolute right-2 text-red-600" onClick={() => handleRemoveItem(item._id)} ></GoTrash>
                </div>
            }
        </div>
    )
}

export default TaskCheckListPopup;
