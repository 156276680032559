import React, { useState, useRef, useEffect } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import VenueUserSettting from './VenueUserSettting';
import { useQuery } from '@apollo/client';
import { GET_TEAMS } from '../../../Utils/GraphqlQueries';

function UpdateVenue({ setVenuesList, props, venueDetails, setVenueDetails, venueSingle, actionPopUp }) {
    const [maxLengthExceeded] = useState(false);
    const [readOnly] = useState(false);
    const [activeTabMenu, setActiveTabMenu] = useState('eodTab');
    const inputRef = useRef(null);

    const { data: teamsData } = useQuery(GET_TEAMS);
    const [title, setTitle] = useState('');
    const [selectedTeam, setSelectedTeam] = useState({ value: '', label: '' });


    useEffect(() => {
        if (teamsData?.teams) {
            const teamTitle = teamsData.teams.find((a) => a.team_id === venueSingle.team_id);
            if (teamTitle) {
                setSelectedTeam({ value: teamTitle.team_id, label: teamTitle.team_title });
                setTitle(venueSingle.venue_title);
            }
        }
    }, [teamsData, venueSingle]);


    const updateed = () => {
        actionPopUp('edit', venueSingle);
    }



    return (
        <div className='createGroupConv venueIndivisual'>
            <div className="createConv_head !h-[80px] border-b border-gray-300">
                <span data-tip="Back" className="createConv_back profileBack" onClick={() => setVenueDetails(!venueDetails)} />
                <p className="profileTitle !top-[22px]" >
                    <span className="userProfileName"> Update venue </span>
                </p>
                <span data-tip="Close" onClick={() => setVenueDetails(!venueDetails)} className="createConv_close profileClose" />
            </div>
            <div className="createConv_body venue_create py-2 px-[30px] !h-[calc(100%-80px)]">
                <div className="inputGroup inCreateReport relative">
                    <label className="inputLabel">Title of venue<span className="red_star">*</span>
                        {readOnly ? <BiEditAlt data-for="venueTitleEdit" data-tip="Your role does not allow access to this section" className='venueTitleEditIcon' /> :
                            <BiEditAlt data-for="venueTitleEdit" data-tip="Click to update title" className='venueTitleEditIcon' onClick={() => updateed()} style={{ cursor: 'pointer' }} />
                        }
                    </label>

                    <input
                        value={title}
                        ref={inputRef}
                        autoFocus={false}
                        type="text" name="team-name" className="team-name isDisable" maxLength="75" placeholder="Name of the venue"
                    />

                    {maxLengthExceeded && (
                        <span className="rgInputMsg" style={{ color: 'red' }}>Maximum length 75 characters.</span>
                    )}
                </div>
                <div className="inputGroup inCreateReport relative">
                    <label className="inputLabel">Team<span className="red_star">*</span>
                        {readOnly ? <BiEditAlt data-for="venueTitleEdit" data-tip="Your role does not allow access to this section" className='venueTitleEditIcon' /> :
                            <BiEditAlt data-for="venueTitleEdit" data-tip="Click to update title" className='venueTitleEditIcon' onClick={() => updateed()} style={{ cursor: 'pointer' }} />
                        }
                    </label>
                    <input
                        value={selectedTeam?.label || ''}
                        autoFocus={false}
                        type="text" name="team-name" className="team-name isDisable" maxLength="75" placeholder="Name of the team"
                    />


                </div>
                <VenueUserSettting setVenuesList={setVenuesList} venueSingle_Old={venueSingle} setVenueDetails={setVenueDetails} activeTabMenu={activeTabMenu} setActiveTabMenu={setActiveTabMenu} />
            </div>
        </div>
    )
}

export default UpdateVenue