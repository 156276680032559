/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import OutsideClickHandler from 'react-outside-click-handler';
// import ReactTooltip from 'react-tooltip';
// import validator from 'validator';
import short_uuid from 'short-uuid';
import {
    voip_send_msg, getXmppToken, getCallingUrl
    // getReloadData, CallEnd   
} from '../../Utils/Common';
// import UploadFile from './Popup/UploadFile';
// import FileUpload from '../Popups/FileUpload';
// import './Call.css';
// import Callingchat from './Calling_chat';
import { connect } from 'react-redux';
// import Jitsi from 'react-jitsi';
// import JitsiLoader from './JitsiLoader.js';
// import socket from './../socket';
// import UpgradePlanPopup from './../Popups/UpgradePlanPopup';
// import CallDeviceChange from './../Popups/CallDeviceChange';

// import GuestJoinPopup from './../Popups/GuestJoinPopup';
// import UserJoinPopup from './../Popups/UserJoinPopup';
import { aesEncrypt } from './../../Utils/Common';
import { useParams, useLocation } from "react-router-dom";
import { GET_ME, GET_CALLING_URL, GET_RING_USER, GET_CALLING_REJECT } from '../../Utils/GraphqlQueries';
import { sendMsgMutation } from '../../Utils/GraphqlMutaion';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { set_user } from "../../redux/message";
import DeviceId from '../../Utils/DeviceId';
import { JitsiMeeting } from "@jitsi/react-sdk";
// import { SendMsg, sendAckMsg } from '../../Utils/SOCKET';
// import {openCallWindow} from '../../Utils/CustomFun';
// import {getCallingUrl} from '../../Utils/popupAPI';
// import CallingShareScreen from '../UserFirstImpressions/CallingShareScreen'
// import CallingChatMsg from '../UserFirstImpressions/CallingChatMsg'
// import CallingCamera from '../UserFirstImpressions/CallingCamera'
// import CallingAddInvite from '../UserFirstImpressions/CallingAddInvite';
// import CallingUserFirst from '../UserFirstImpressions/CallingUserFirst';
// import CallingAdminFirst from '../UserFirstImpressions/CallingAdminFirst';
// import CallingAdminSecurity from '../UserFirstImpressions/CallingAdminSecurity';
// import CallingAdminPresentation from '../UserFirstImpressions/CallingAdminPresentation';
// import CallingAdminLobby from '../UserFirstImpressions/CallingAdminLobby';


const { return_data } = require('../../config/config');

// const { client, xml, jid } = window.XMPP;
const { client, xml } = require("@xmpp/client");
var xmpp = null;
var api = null;
var jitsi_id = null;
var msg_id = '';
var company_id = '';
var convname = '';
var callee_avatar = '';
var participants_all = [];
var hold_status = false;
var switch_status = false;
var hangup_status = false;
var plan_name = null;
var lobby_status = false;
const audio_tone = new Audio('https://wfss001.freeli.io/common/short_tone.mp3');

var xmpp_domain = return_data.xmpp_domain;
const jitsi_domain = return_data.jitsi_domain ? return_data.jitsi_domain : 'wfvs001.freeli.io';
// console.log('jitsi_domain', jitsi_domain,'xmpp_domain', xmpp_domain);

var all_users = [];
var all_users_fill = [];
var voip_conv = null;
var online_users = [];
var conversation_id = null;
var mic_alert = false;
// var toolbar_user = [
//     'microphone', 'camera','invite','participants-pane','closedcaptions', 'desktop', 'fullscreen',
//     'fodeviceselection', 'hangup', 'chat', 'settings', 'raisehand',
//     'videoquality', 'filmstrip', 'stats', 'shortcuts','livestreaming',
//     'tileview', 'select-background', 'mute-everyone', 'mute-video-everyone', 'security'
//     ];
var toolbar_user = [
    'microphone', 'camera', 'invite', 'participants-pane', 'desktop',
    'fodeviceselection', 'hangup', 'chat', 'settings', 'raisehand',
    'tileview'
];
var toolbar_guest = [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'chat', 'raisehand',
    'videoquality', 'tileview',
];

var user_id = short_uuid.uuid();
var user_fullname = ' ';
var user_email = 'guest@guest.com';
var user_img = 'https://wfss001.freeli.io/profile-pic/Photos/img.png';
var displayName = '';
var prejoin = false;

function Calling_meeting(props) {
    const params = useParams();
    const deviceId = DeviceId();
    // console.log("🚀 ~ calling ~ deviceId:", deviceId)
    const location = useLocation();
    const dispatch = useDispatch();
    // const { data: initialData } = useQuery(GET_ME);
    const [initialData, setinitialData] = useState(null);
    const [send_msg_input] = useMutation(sendMsgMutation);
    const [getCallingURL,] = useLazyQuery(GET_CALLING_URL);
    const [getRingUser,] = useLazyQuery(GET_RING_USER);
    const [getCallingReject] = useLazyQuery(GET_CALLING_REJECT);
    const [getMe] = useLazyQuery(GET_ME);
    // console.log('Calling_meeting',initialData, location, params,props);
    const [invite, setInvite] = useState(false);
    const [conv_history, set_conv_history] = useState(false);
    // const [onhold, set_onhold] = useState(true);
    const [connect_status, set_connect_status] = useState('Loading');
    const [online_user_lists, set_online_user_lists] = useState([]);
    const [active_participants, set_active_participants] = useState([]);
    const [participants_status, set_participants_status] = useState([]); //console.log('participants_status',participants_status);
    // const [sendFile, setSendFile] = useState(true);
    const [callingLoader, set_callingLoader] = useState(true);
    // const [rep_msg_id, set_msg_id] = useState(' ');
    const [searchText, setSearchText] = useState(undefined);
    // const [all_users, set_all_users] = useState([]);
    const [cur_users, set_cur_users] = useState([]);
    // console.log('cur_users',cur_users);

    const [loading, setLoading] = useState(true);
    const [jwt_token, set_jwt_token] = useState('');

    const [hand_raised, set_hand_raised] = useState(0);
    const [hand_raised_view, set_hand_raised_view] = useState(true);
    // const [tooltip_count, set_tooltip_count] = useState(1);
    const [, set_tooltip_view] = useState('');
    // const [tooltip_view, set_tooltip_view] = useState('');
    // const devicechangepopup = useSelector(state=>state.popupReducer.popup.devicechange);
    const jitsiContainerStyle = {
        display: (loading ? 'block' : 'block'),
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    }
    const handleChange = value => {
        setSearchText(value);
        // filterData();
    };
    function showParticipantPanel() {
        // api.executeCommand('toggleParticipantsPane');
        api.getIFrame().contentWindow.postMessage({ type: 'show_participant_pane' }, '*');
    }
    async function memAddCall(memUUID, data) {
        // console.log('memAddCall',data);
        if (memUUID === user_id) return;
        if (active_participants.indexOf(memUUID) > -1) return;

        var arr_participants = [user_id, memUUID];
        let add_status = await voip_send_msg('jitsi_add_member', {
            user_id: user_id,
            sender_name: user_fullname,
            user_img: user_img,
            member_id: memUUID,
            conversation_id: conversation_id,
            convname: convname,
            arr_participants: arr_participants,

        });
        if (add_status.status === 'upgrade') {
            props.setPopup({ type: 'upgradePlan', data: true });
        }

    }
    async function mergeHoldCall() {

        // if (active_participants.indexOf(memUUID) > -1) return;

        // var arr_participants = [user_id, memUUID];
        voip_send_msg('jitsi_merge_hold', {
            user_id: user_id,
            user_fullname: user_fullname,
            sender_name: user_fullname,
            conversation_id: conversation_id,
        });

        set_conv_history(false);


    }
    async function startConference(conversation_id, user) {
        // debugger;
        // var jwt_token = localStorage.getItem('token').split(" ")[1];
        try {
            let apires = await getRingUser({
                variables: {
                    user_id: user.id,
                    conversation_id: conversation_id,
                },
                fetchPolicy: 'network-only', // Ensures it fetches fresh data from the server
            });

            let data = apires?.data?.jitsi_ring_users;
            if (data.status) {
                voip_conv = data.voip_conv;
                if (voip_conv.participants_admin.indexOf(user_id) > -1) { set_tooltip_view('admin'); } else { set_tooltip_view('user'); }
                // setTimeout(() => {
                    if (voip_conv.call_option === 'window') {
                        set_connect_status('');
                    }

                // }, 1000)
                if (voip_conv.call_option !== 'window' && voip_conv.init_id === user_id && voip_conv.connected === false) {
                    set_connect_status('Calling');
                }

                plan_name = voip_conv.plan_name;
                msg_id = voip_conv.msgid;
                company_id = voip_conv.company_id;
                convname = voip_conv.convname;
                participants_all = voip_conv.participants_all;
                online_users = data.online_user_lists;
                all_users = data.users;
                all_users_fill = data.users;
                callee_avatar = data.callee_avatar;
                set_cur_users(all_users_fill);
                set_online_user_lists(data.online_user_lists);
                set_active_participants(data.active_participants);
                set_participants_status(data.participants_status);
                set_conv_history(data.conv_history);
                set_jwt_token(data.jwt_token);
                props.setActiveThread(data.msg_db);
                props.setPopup({ type: 'replyThread', data: true });
                setSearchText('');
            } else {
                props.setPopup({ type: 'guest_call_join', data: true });
                
            }


        } catch (error) {
            console.error('Failed to load Jitsi API', error);
        }
    }
    async function getUser() {
        if ((location.pathname.includes("/u/") || location.pathname.includes("/c/")) && !localStorage.getItem('token')) {

            setinitialData({
                id: short_uuid.uuid(),
                img: 'https://wfss001.freeli.io/profile-pic/Photos/img.png',
                email: 'guest@guest.com',
                firstname: 'Guest',
                lastname: 'User',
                company_id: ''
            })

        } else {
            let apires = await getMe({
                // variables: data,
                fetchPolicy: 'network-only', // Ensures it fetches fresh data from the server
            });
            let cb_data = apires?.data?.me;
            setinitialData(cb_data)

        }

    }

    useEffect(() => {
        if (!localStorage.getItem("hideAreaCall")) localStorage.setItem("hideAreaCall", true);
        getUser()
    }, [])

    useEffect(() => {
        if (initialData) {
            // console.log('Calling_meeting:load',initialData);
            dispatch(set_user(initialData));

            user_id = initialData.id;
            user_fullname = [initialData.firstname, initialData.lastname ? initialData.lastname : ''].join(" ").trim();
            user_email = initialData.email;
            user_img = initialData.img;
            displayName = user_fullname;
            company_id = initialData.company_id;
            var prejoin = false;
            // debugger

            if (location.pathname.includes("/u/")) {
                getCallingUrl({ short_id: params.id, type: 'user' }, getCallingURL).then(async function (response) {
                    if (response && response.data && response.data.conversation_id) {
                        conversation_id = response.data.conversation_id;
                        company_id = response.data.company_id;
                        if (response.data.participants.indexOf(user_id) > -1) prejoin = false; else prejoin = true;
                        if (!response.data.is_running && !prejoin) {
                            // await voip_send_msg('jitsi_ring_calling', {
                            //     user_id: user_id,
                            //     conversation_id: user_id,
                            //     conversation_type: response.data.conversation_type,
                            //     arr_participants: [user_id], // selected ( group call) , all ( personal call)
                            //     participants_all: [user_id], // all members of conversation
                            //     convname: user_fullname,
                            //     call_link: window.location.origin + '/u/' + params.id,
                            //     call_option: 'window',
                            //     company_id: response.data.company_id

                            // });
                        }

                        // if (window.JitsiMeetExternalAPI) 
                            startConference(conversation_id, initialData);
                    } else {
                        alert('Sorry, URL Not Available!!!');
                    }
                    // logindataget();
                });
            }
            else if (location.pathname.includes("/c/")) {
                getCallingUrl({ short_id: params.id, type: 'conv' }, getCallingURL).then(async function (response) {
                    if (response && response.data && response.data.conversation_id) {
                        conversation_id = response.data.conversation_id;
                        company_id = response.data.company_id;
                        if (response.data.participants.indexOf(user_id) > -1) prejoin = false; else prejoin = true;
                        if (!response.data.is_running && !prejoin) {
                            // await voip_send_msg('jitsi_ring_calling', {
                            //     user_id: user_id,
                            //     conversation_id: response.data.conversation_id,
                            //     conversation_type: response.data.conversation_type,
                            //     arr_participants: [user_id], // selected ( group call) , all ( personal call)
                            //     participants_all: response.data.participants, // all members of conversation
                            //     convname: response.data.title,
                            //     call_link: window.location.origin + '/u/' + params.id,
                            //     call_option: 'window',
                            //     company_id: response.data.company_id
                            // });
                        }
                        // if (window.JitsiMeetExternalAPI) 
                            startConference(conversation_id, initialData);
                    } else {
                        alert('Sorry, URL Not Available!!!');
                    }

                });
            } else {
                conversation_id = params.cid;
                // if (window.JitsiMeetExternalAPI) 
                    startConference(conversation_id, initialData);

            }
            

            window.addEventListener('message', function (e) {

                if (e.data === "myevent" || e.message === "myevent") {
                    all_users_fill = all_users.filter((v) => {
                        if (v.role === "Guest") {
                            return false;
                        } else {
                            return true;
                        }
                    });

                    set_cur_users(all_users_fill);
                    setSearchText('');
                    setInvite(true);

                }
                else if (e.data === "lobby_show" || e.message === "lobby_show") {
                    // alert('lobby_show');
                    setInvite(false);
                    navigator.mediaDevices.getUserMedia({ audio: true }).then(function (rstream) {
                        audio_tone.play();
                        rstream.getTracks().forEach(function (track) { track.stop(); });
                    });

                }
                else if (e.data === "lobby_hide" || e.message === "lobby_hide") {
                    // alert('lobby_hide');

                }
                else if (e.data === "lobby_toggle" || e.message === "lobby_toggle") {
                    // alert('lobby_hide');
                    lobby_status = !lobby_status;
                    api.executeCommand('toggleLobby', lobby_status);
                    api.getIFrame().contentWindow.postMessage({ type: 'lobby_status', status: lobby_status }, '*');
                }
                // else if (e.data === "raisehand_hide" || e.message === "raisehand_hide") {
                //     set_hand_raised_view(false);
                //     // document.getElementById("raise-hand-icon").style.visibility = "hidden";

                // }
                // else if (e.data === "raisehand_show" || e.message === "raisehand_show") {
                //     set_hand_raised_view(true);
                //     // document.getElementById("raise-hand-icon").style.visibility = "visible";

                // }
                else if (e.data === "mygroup" || e.message === "mygroup") {

                    all_users_fill = all_users.filter(user => {
                        if (voip_conv.participants_all.indexOf(user.id) > -1) return true;
                        else return false;
                    });

                    set_cur_users(all_users_fill);
                    setSearchText('');
                    setInvite(true);
                }
                else if (e.data === "jitsi_attach" || e.message === "jitsi_attach") {
                    props.setPopup({ type: 'fileuploadpopup', data: true })
                }
                else if (e.data === "loadingdot_hide" || e.message === "loadingdot_hide") {
                    set_callingLoader(false);
                }
                else if (e.data === "loadingdot_show" || e.message === "loadingdot_show") {
                    set_callingLoader(true);
                }
                else if (e.data === "hangup_call" || e.message === "hangup_call") {
                    if (plan_name === 'starter') {
                        api.executeCommand('hangup');
                    }

                }
            });
            window.addEventListener('beforeunload', async (event) => {
                if (hangup_status === true) return;
                // debugger
                if (switch_status === false) {
                    //    getCallingReject({
                    //         variables: {
                    //             user_id: user_id,
                    //             user_fullname: user_fullname,
                    //             conversation_id: conversation_id,
                    //             hold_status: hold_status,
                    //             switch_status: switch_status,
                    //             token: getXmppToken()
                    //         },
                    //         fetchPolicy: 'network-only',
                    //     });
                    // let cb_data = apires?.data?.jitsi_call_hangup;
                    // console.log("callRejectBtn", cb_data);

                    navigator.serviceWorker.controller.postMessage({
                        type: 'jitsi_call_hangup',
                        user_id: user_id,
                        user_fullname: user_fullname,
                        conversation_id: conversation_id,
                        hold_status: hold_status,
                        baseURL: return_data.baseURL,
                        token: getXmppToken(),
                        switch_status: switch_status
                        // reloaded: navtype

                    });
                }

                if (hold_status === false) {

                    window.close();
                    window.location.replace('https://' + jitsi_domain);

                }

                // return false
            });

        }

    }, [initialData])

    useEffect(() => {
        if (searchText !== undefined) {
            if (cur_users) {
                const results = all_users_fill.filter(user => {
                    // if (user_id == user.id) return false;
                    // if(online_user_lists.indexOf(user.id) > -1){
                    if (user && user.firstname) {
                        return user.firstname?.toLowerCase().trim().indexOf(searchText.toLowerCase().trim()) > -1
                    } else {
                        return false;
                    }
                    // }

                });
                // all_users_fill = results;
                set_cur_users(results);
            }
        };

    }, [searchText]);

    // useEffect(() => {
    //     all_users_fill = all_users_fill.sort(function(a,b){
    //         // debugger
    //         if(online_users.indexOf(a.id?.toString()) > -1){
    //           return -1;  
    //         }
    //         if(online_users.indexOf(b.id?.toString()) > -1){
    //             return 1;  
    //         }
    //         return -1;

    //     });

    //     set_cur_users(all_users_fill);

    // },[online_user_lists])


    return (
        <>
            {/* {devicechangepopup ? <CallDeviceChange data={devicechangepopup}/>:''} */}
            {/* <ReactTooltip id="top_head" place="bottom" type="dark"/> */}

            {/* {(!loading && localStorage.getItem('total_login') <= 1 && tooltip_view === 'user' && localStorage.getItem("hideAreaCall") === 'true') ? 
            <CallingUserFirst tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> 
            : ''}
            {(!loading && localStorage.getItem('total_login') <= 1 && tooltip_view === 'user' && localStorage.getItem("hideAreaCall") === 'true') ? 
            tooltip_count === 1 ? <CallingShareScreen tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : 
            tooltip_count === 2 ? <CallingChatMsg tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> :
            tooltip_count === 3 ? <CallingCamera tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : 
            tooltip_count === 4 ? <CallingAddInvite tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : ''
            : ''
            } */}

            {/* {(!loading && localStorage.getItem('total_login') <= 1 && tooltip_view === 'admin' && localStorage.getItem("hideAreaCall") === 'true') ? 
            <CallingAdminFirst tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> 
            : ''}
            {(!loading && localStorage.getItem('total_login') <= 1 && tooltip_view === 'admin' && localStorage.getItem("hideAreaCall") === 'true') ? 
            tooltip_count === 1 ? <CallingAdminSecurity tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : 
            tooltip_count === 2 ? <CallingChatMsg tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : 
            tooltip_count === 3 ? <CallingAdminPresentation tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> :
            tooltip_count === 4 ? <CallingAdminLobby tooltip_count={tooltip_count} set_tooltip_count={set_tooltip_count} /> : ''
            : ''
            } */}

            {/* <div className="call_body"> */}
            {connect_status ?
                <div className={`${!callingLoader ? 'hide_loader' : ''}`}>
                    <div className="connect_status">{connect_status}</div>
                    <div className={`voip_loader_dot ${connect_status === 'Connecting' ? 'rotate180' : ''}`}></div>
                </div> : ''}

            {hand_raised > 0 && hand_raised_view ?
                <div id="raise-hand-icon" className="raise-hand-cls" onClick={() => showParticipantPanel()}>
                    <img className="raise-hand-img" src="/media/images/raised-hand.svg" alt="raise" title='raise' />
                    <div className="raise-hand-count">{hand_raised}</div>

                </div> : ''}

            {invite &&
                <div className="right_content">
                    <div className="right_div_top">
                        <div className="member_text">
                            Members
                            <span className="grey_text"></span>
                        </div>
                        <div data-for="top_head" data-tip="Close" data-place="bottom" className="close_btn" style={{ marginTop: '3px' }} onClick={() => setInvite(!invite)}></div>
                        {conv_history ? <div className="merge_call_btn" style={{ marginTop: '3px', right: '45px' }} onClick={() => mergeHoldCall()}></div> : ''}
                        <input className="member_search" type="text" name="" autoFocus value={searchText} placeholder="Search by name" onChange={e => handleChange(e.target.value)} />
                    </div>

                    <div className="friend_section" >
                        {
                            cur_users.map((d, i) => {
                                let online_status = online_user_lists.indexOf(d.id) > -1 ? 'online' : 'offline';
                                const participant = participants_status.find(p => p.user_id === d.id);
                                const statusText = participant ? `(${participant.status})` : '';

                                return (
                                    <div className={`list_friend ${online_status}`} key={d.id} onClick={() => memAddCall(d.id, d)}>
                                        <div className="image">
                                            <img src={`${d.img}`} alt="img" title='img' />
                                        </div>
                                        <div data-userid={d.id} className="friend_name">
                                            <span>{d.firstname} {d.lastname}</span>
                                            {/* Display status if found */}
                                            <span id={`inv_usr_${d.id}`} className="friend_status">
                                                {statusText}
                                            </span>
                                            {/* Display loader for 'Calling...' or 'Connecting...' */}
                                            {participant && (participant.status === 'Calling...' || participant.status === 'Connecting...') && (
                                                <div className="voip_loader_status"></div>
                                            )}
                                        </div>

                                    </div>
                                )

                            })
                        }
                        {cur_users.length === 0 && <span style={{ margin: '5px auto 5px 10px', color: 'var(PrimaryC', fontWeight: 'bold' }}>No records found to display!</span>}
                    </div>
                </div>}
            {/* {props.popup.fileuploadpopup && <>
                <OutsideClickHandler onOutsideClick={() => props.setPopup({type:'fileuploadpopup',data:false})}><FileUpload /></OutsideClickHandler>
            </>} */}


            {/*loading && <ProgressComponent />*/}
            {/* <div id="jitsi-container" style={jitsiContainerStyle} /> */}
            <div style={jitsiContainerStyle}>
                {/* {loading && <p>Loading Jitsi...</p>} */}
                {jwt_token && <JitsiMeeting
                    // style={jitsiContainerStyle}
                    getIFrameRef = { iframeRef => { 
                        iframeRef.style.height = '100vh'; 
                        iframeRef.style.width = '100vw'; 
                        iframeRef.onload = () => {
                            const style = document.createElement("style");
                            style.innerHTML = `
                                .localvideomenu { display: none !important; }
                                .videocontainer .videomenu { display: none !important; }
                            `;
                            iframeRef.contentWindow.document.head.appendChild(style);
                        };
                    } }
                    domain = { jitsi_domain }
                    roomName={conversation_id?.replace(/-/g, "")}
                    jwt={jwt_token}
                    userInfo={{
                        email: user_email,
                        ...(prejoin === false && { displayName }),
                    }}
                    configOverwrite={{
                        startWithVideoMuted: true,
                        prejoinPageEnabled: prejoin,
                        disableDeepLinking: true,
                        disableThirdPartyRequests: true,
                        enableNoAudioDetection: false,
                        enableNoisyMicDetection: false,
                        disableAudioLevels: true,
                        toolbarButtons: prejoin ? toolbar_guest : toolbar_user,
                        disableRemoteVideoMenu: true, // Hides options on other participants' videos
                        disableLocalVideoMenu: true,
                    }}
                    interfaceConfigOverwrite={{
                        TOOLBAR_TIMEOUT: 60000,
                        DISABLE_VIDEO_MENU: true,
                    }}
                    onApiReady={(api) => {
                        // setLoading(false);
                        
                        api.addEventListener('videoConferenceJoined', function (event) { // self
                            // debugger;
                            mic_alert = true;
                            // console.log('jitsi_mic:videoConferenceJoined:',event);
                            setLoading(false);
                            if (prejoin === false) api.executeCommand('displayName', displayName);
                            api.executeCommand('avatarUrl', user_img);

                            jitsi_id = event.id;
                            // callee_avatar = data.callee_avatar;
                            api.getIFrame().contentWindow.postMessage({ type: 'add_member_group', conversation_type: voip_conv.conversation_type }, '*');
                            api.getIFrame().contentWindow.postMessage({ type: 'conference_timer', time: Date.now() }, '*');

                            setTimeout(() => {
                                api.getIFrame().contentWindow.postMessage({ type: 'change_profile_pic', img: callee_avatar }, '*');
                                if (voip_conv.call_link) api.getIFrame().contentWindow.postMessage({ type: 'call_link', call_link: voip_conv.call_link }, '*');

                            }, 2000);


                            // if(voip_conv.conversation_type == "group"){
                            // api.executeCommand('setTileView',false);
                            // }
                        });
                        api.addEventListener('participantJoined', function (event) { // others
                            // console.log('jitsi_mic:participantJoined:',event);

                            // if(voip_conv.init_id == user_id){
                            // api.executeCommand('toggleLobby', true);
                            // lobby_status = true; 
                            // api.getIFrame().contentWindow.postMessage({ type: 'lobby_status',status:lobby_status}, '*');
                            // }
                            set_connect_status('');

                            setTimeout(() => {
                                
                                // api.executeCommand('setTileView',true)
                                api.getIFrame().contentWindow.postMessage({ type: 'change_profile_pic', img: callee_avatar }, '*');
                                // console.log('jitsi:change_profile__pic:',callee_avatar);
                            }, 2000);
                        });
                        api.addEventListener('toolbarButtonClicked', (event) => {
                            // api.executeCommand('subject', voip_conv.convname);
                            // console.log('jitsi:set:subject:',voip_conv.convname);
                            // alert('event');
                        });
                        api.addEventListener('raiseHandUpdated', (event) => {
                            // api.executeCommand('subject', voip_conv.convname);
                            // console.log('jitsi:raiseHandUpdated:', event);
                            if (event.handRaised) {
                                set_hand_raised(hand_raised => hand_raised + 1);
                            } else {
                                set_hand_raised(hand_raised => hand_raised - 1)
                            }
                            set_hand_raised_view(true);

                            // var x = api.getIFrame().contentWindow;
                            // console.log('bbb',x.document.getElementsByTagName("body"));

                            // api.isParticipantsPaneOpen().then(state => {
                            //       alert(state);
                            // });
                        });
                        api.addEventListener('deviceListChanged', async function (event) {
                            // console.log('jitsi_mic:deviceListChanged',event)
                            if (mic_alert) {
                                props.setPopup({ type: 'devicechange', data: true });

                            }
                        });
                        api.addEventListener('participantLeft', function (event) {
                            setTimeout(() => {
                                api.getIFrame().contentWindow.postMessage({ type: 'change_profile_pic', img: callee_avatar }, '*');
                                // console.log('jitsi:change_profile__pic:',callee_avatar);
                            }, 2000);
                        });
                        api.addEventListener('readyToClose', async function (event) {
                            hangup_status = true;
                            // debugger;
                            if (switch_status === false) {
                                // debugger
                                let apires = await getCallingReject({
                                    variables: {
                                        user_id: user_id,
                                        user_fullname: user_fullname,
                                        conversation_id: conversation_id,
                                        hold_status: hold_status,
                                        switch_status: switch_status,
                                        token: getXmppToken()
                                    },
                                    fetchPolicy: 'network-only',
                                });
                                let cb_data = apires?.data?.jitsi_call_hangup;
                                // console.log("callRejectBtn", cb_data);

                                // await voip_send_msg('jitsi_call_hangup', {
                                //     user_id: user_id,
                                //     user_fullname: user_fullname,
                                //     conversation_id: conversation_id,
                                //     hold_status: hold_status,
                                //     switch_status: switch_status,
                                //     token: getXmppToken()
                                // });

                            }
                            window.close();
                            window.location.replace('https://' + jitsi_domain);

                        });
                        api.addEventListener('outgoingMessage', function (event) {
                            // debugger;
                            if (user_email === 'guest@guest.com') return;
                            // console.log('outgoingMessage', event);
                            // rep_msg_send_fn_call(event);
                            // let data = {
                            //     "msg_id": uuidv1(),
                            //     company_id: company_id,
                            //     conversation_id: conversation_id,
                            //     is_reply_msg: "yes",
                            //     msg_body: aesEncrypt(event.message),
                            //     participants: participants_all,
                            //     reply_for_msgid: msg_id,
                            //     sender: user_id,
                            //     sender_img: user_img,
                            //     sender_name: user_fullname,
                            //     "created_at" : new Date(),
                            // }
                            const json_data = {
                                conversation_id: conversation_id,
                                company_id: company_id,
                                sender: user_id,
                                msg_body: aesEncrypt(event.message),
                                participants: participants_all,
                                is_reply_msg: 'yes',
                                reply_for_msgid: msg_id
                            }

                            // socket.emit('send_reply_msg', data, function(res) {
                            //     console.log(233,res)
                            // });
                            // voip_send_msg('send_reply_msg', data);
                            send_msg_input({ variables: { input: json_data } });
                        });
                        api.addEventListener('incomingMessage', function (event) {
                            // debugger;
                            api.getIFrame().contentWindow.postMessage({ type: 'message', from: event.from }, '*');
                            // rep_msg_send_fn_call(event);
                        });
                        api.addEventListener('participantKickedOut', function (event) {
                            // console.log('jitsi:participantKickedOut:',event);
                            // debugger;
                            if (event.kicked.id === jitsi_id) {
                                api.executeCommand('hangup');
                                // window.close();
                            }
                            // rep_msg_send_fn_call(event);
                        });
                        api.addEventListener('knockingParticipant', function (event) {
                            // console.log('jitsi:knockingParticipant:',event);
                            // debugger
                            // alert('knockingParticipant');
                        });
                        api.addEventListener('participantRoleChanged', async function (event) {
                            // console.log('jitsi:participantRole_Changed:',event);
                            if (event.role === "moderator") {
                                // if(voip_conv.call_option == 'window'){
                                api.executeCommand('toggleLobby', true);
                                // }
                                await voip_send_msg('jitsi_add_admin', {
                                    user_id: user_id,
                                    conversation_id: conversation_id
                                });

                            }
                        });
                        api.addEventListener('log', function (event) {
                            // console.log('jitsi:log:',JSON.stringify(event));
                            // debugger;
                            // if(event.kicked.id == jitsi_id){
                            //   api.executeCommand('hangup');
                            //   // window.close();
                            // }
                            // rep_msg_send_fn_call(event);
                        });

                        const xmpp_register_user = async (user_id) => {
                            let response = await voip_send_msg('xmpp_register_user', { user_id: user_id, token: getXmppToken(), device: 'web' });
                            // console.log('xmpp:status:', response);
                            if (response.status) {
                                // console.log('xmpp:register:voip', response.xmpp_user);
                                xmpp = client({
                                    service: "wss://" + xmpp_domain + ":5443/ws",
                                    domain: xmpp_domain,
                                    // resource: getXmppToken() + '_call',
                                    username: response.xmpp_user,
                                    password: "a123456",
                                });
            
                                xmpp.on('online', async address => {
                                    // console.log('xmpp:online:', response.xmpp_user, address._resource);
                                    // debugger;
                                    await xmpp.send(xml('presence'))
            
                                });
            
                                xmpp.on('stanza', async stanza => {
                                    // console.log('xmpp:stanza', xmpp_domain, stanza);
                                    // 
                                    if (stanza.is('message')) {
                                        if (!stanza.getChild("body")) return;
                                        var data = JSON.parse(stanza.getChild("body").text());
            
                                        if (data.xmpp_type.indexOf('online') === -1) {
                                            // console.log(`xmpp:recv:${data.xmpp_type}`,data);
                                        }
            
                                        // console.log(`xmpp:message: ${data.xmpp_type} : domain:  ${data.CLIENT_BASE_URL} : from:${stanza.attrs.from} : to => ${stanza.attrs.to}`, data);
                                        if (data.xmpp_type === 'update_client_version' || data.xmpp_type.includes('jitsi')) {
                                            // if(data.origin && data.origin !== '*') {
                                            if (window.location.hostname !== data.CLIENT_BASE_URL) {
                                                // console.log('xmpp:reject_origin',data.xmpp_type, data.origin );
                                                return;
                                            }
                                            // }
                                        }
            
                                        switch (data.xmpp_type) {
            
                                            case 'jitsi_send_hangup':
                                                if (data.conversation_id === conversation_id) {
                                                    if (data.hold_status) hold_status = true;
                                                    api.executeCommand('hangup');
                                                }
            
                                                break;
            
                                            case 'jitsi_ring_status': // ring status
                                                // debugger
                                                if (data.conversation_id === conversation_id) {
                                                    // console.log('jitsi_ring_status', data.active_participants);
                                                    set_active_participants(data.active_participants);
                                                    set_participants_status(data.participants_status);
            
                                                }
                                                // });
                                                break;
                                            case 'jitsi_send_accept':
                                                // debugger;
                                                dispatch({ type: 'set_callringpopup', payload: { status: false } });
                                                dispatch({ type: 'busy_call_msg', payload: false });
                                                props.setPopup({ type: 'voipHoldMute', data: false });
                                                props.setPopup({ type: 'mergeconvpopup', data: false });
            
                                                if (data.type && data.type === 'join' && data.user_id === user_id && data.token !== getXmppToken()) {
                                                    switch_status = true;
                                                    lobby_status = false;
                                                    api.executeCommand('toggleLobby', false);
                                                    api.getIFrame().contentWindow.postMessage({ type: 'lobby_status', status: lobby_status }, '*');
            
                                                    api.executeCommand('hangup');
                                                }
                                                break;
            
                                            case 'jitsi_user_accept': // connecting user
                                                if (data.user_id !== user_id) {
                                                    set_connect_status('Connecting');
                                                }
            
                                                break;
            
                                            case 'jitsi_send_merge':
                                                // console.log('jitsi_send_merge', data);
                                                // debugger
                                                if (data.conv_id_old === conversation_id) {
                                                    if (data.hold_status) hold_status = true;
                                                    window.location.replace('/call/' + data.conversation_id);
                                                }
                                                break;
                                            case 'jitsi_send_mute':
                                                // debugger
                                                // console.log('jitsi_send_mute', data);
            
                                                if (data.conv_id_old === conversation_id) {
                                                    // hold_status = true;
                                                    api.isAudioMuted().then(muted => {
                                                        if (muted === false) api.executeCommand('toggleAudio');
                                                    });
            
                                                    // openCallWindow(data.conversation_id,'calling_'+data.conversation_id)
                                                    // window.location.replace('/call/' + data.conversation_id);
                                                }
            
                                                break;
            
                                            case 'jitsi_send_history':
                                                if (data.conv_id_old === conversation_id) {
                                                    set_conv_history(true);
                                                }
                                                break;
                                            case 'online_user_lists':
                                                set_online_user_lists(data.list);
                                                online_users = data.list;
                                                // console.log('online:users:',online_users);
            
            
                                                break;
            
                                            case 'jitsi_guest_join': // join icon
                                                props.setPopup({ type: 'guest_call_join', data: false });
                                                // if (window.JitsiMeetExternalAPI) 
                                                    startConference(conversation_id.replace(/-/g, ""));
                                                break;
            
                                            // case 'jitsi_conv_start': // join icon
            
                                            //     break;
            
                                            // case 'jitsi_conv_end': // remove join + show duration
                                            //     break;
            
                                            // case 'jitsi_busy_status': // profile call icon
                                            //     // debugger
                                            //     console.log('jitsi_busy__status', data);
                                            //     break;
            
                                            default:
                                                // console.log("strange");
                                                break;
                                        }
            
                                        // await xmpp.send(xml('presence', {type: 'unavailable'}))
                                        // await xmpp.stop()
                                    }
                                })
                                xmpp.on('error', err => {
                                    console.error(err)
                                });
                                xmpp.on("status", (status) => {
                                    console.debug('xmpp:status:', xmpp_domain, status);
                                });
            
                                xmpp.on('offline', () => {
                                    // console.log('xmpp:offline')
                                })
            
            
                                xmpp.start().catch(console.error)
                            }
            
                        }
                        xmpp_register_user(user_id);
                    }}
                />
                }
            </div>
            {/* {props.popup.upgradePlan ? <UpgradePlanPopup />:''}
            {props.popup.guest_call_join ? <GuestJoinPopup />:''}
            {props.popup.user_call_join ? <UserJoinPopup />:''} */}

        </>

    );

}

const mapStateToProps = (state) => {
    // console.log('calling_state', state)
    return {
        // active_conv:state.rootReducer.active_conv,
        // conversation_list:state.rootReducer.conversation_list,
        // root_textBox:state.rootReducer.root_textBox,
        // user_data:state.rootReducer.user_data,
        // msg_loader:state.rootReducer.msg_loader,
        // conversation_id:state.rootReducer.conversation_id,
        // logindata:state.rootReducer.logindata,
        // right_side_loader:state.rootReducer.right_side_loader,
        // popup:state.popupReducer.popup,
        // active_thread:state.rootReducer.active_thread,
        // redirect_conv:state.rightSectionReducer.redirect_conv,
        // go_with_noti:state.popupReducer.go_with_noti,
        // noti_click_count:state.popupReducer.noti_click_count,
        // re_click_conv:state.actionReducer.re_click_conv
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setPopup: (data) => {
            dispatch({ type: 'setPopup', payload: data })
        },
        setLogindata: (data) => {
            dispatch({ type: 'setLogindata', payload: data })
        },
        setActiveThread: (data) => {
            dispatch({ type: 'setActiveThread', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Calling_meeting);