/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import InviteRoomGuest from './InviteRoomGuest';
// import RoomTeam from './RoomTeam';

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GET_CATEGORIES, GET_ME, GET_TEAMS, GET_ALL_USERS } from '../../Utils/GraphqlQueries';
import { TagPopup } from '../GlobalPopups/TagPopup';
import { Link } from 'react-router-dom';
import { UserSelection } from '../GlobalPopups/UserSelection';
import { getONLYUSERSDATA } from '../../Utils/Common';
import { upload_obj } from '../../Utils/Common';
import moment from 'moment';
import toast, { Toaster } from "react-hot-toast";
import { CREATE_ROOM_MUTATION } from '../../Utils/GraphqlMutaion';
import { add_conversations } from '../../redux/message';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BsInfoCircle } from "react-icons/bs";
import { MdClose } from 'react-icons/md';
import { validFirstChercterAlsoNuber } from '../ValidationCheck';
import RoomMemberList from './RoomMemberList';
const plus = process.env.PUBLIC_URL + '../../media/images/pluse.webp'
const pluseLarge = process.env.PUBLIC_URL + '../../media/images/plus.webp'

const CreateRoom = () => {

    const [miniList, setMiniList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams()
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
    const { data: teamsData, loading: teamLoading, error: teamError } = useQuery(GET_TEAMS);
    const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = useQuery(GET_CATEGORIES);
    const [participants, setParticipants] = useState([]);
    const [all_teams, setAll_teams] = useState([]);
    const [all_bunits, setAll_bunits] = useState([]);
    const [tag_list, setTag_list] = useState([]);
    const [loader, setLoader] = useState(false)
    const [fileLoader, setFileLoader] = useState(false)
    const [create_status, setCreate_status] = useState(false)
    const [imgFound, setImgFound] = useState(null);
    const [seletedGuestuser, setSelectedguest] = useState([])

    useEffect(() => {
        if (teamsData) {
            let team = []
            for (const iterator of teamsData.teams) {
                team.push({ value: iterator.team_id, label: iterator.team_title, participants_details: getONLYUSERSDATA(cacheAllUsers.users, iterator.participants, 'all') })
            }
            setAll_teams(team)
        }
    }, [teamsData])

    useEffect(() => {
        if (categoriesData) {
            let bunit = []
            for (const iterator of categoriesData.categories) {
                bunit.push({ value: iterator.unit_id, label: iterator.unit_name })
            }
            setAll_bunits(bunit)
        }

    }, [categoriesData])

    const [form_data, setForm_data] = useState({
        title: '',
        participants: [user.id],
        participants_admin: [user.id], //not Req
        participants_guest: [], //not Req
        company_id: user.company_id,
        created_by: user.id,
        single: 'no',
        group: 'yes',
        team_id: '',//team_id
        b_unit_id: '',
        conv_img: '',
        tag_list: [], //not Req

    });
    const makeAdmin = (type, id) => {
        if (type === 'add') {
            setForm_data({
                ...form_data,
                participants_admin: [...form_data.participants_admin, id]
            })
        } else {
            setForm_data({
                ...form_data,
                participants_admin: form_data.participants_admin.filter(v => v !== id)
            })
        }
    }
    const removeParticipants = (id) => {
        setMiniList(prev => prev.filter(f => f.id !== id))
        setForm_data({
            ...form_data,
            participants: form_data.participants.filter(v => v !== id)
        })
    }



    useEffect(() => {
        if (miniList.length > 0) {
            setForm_data({
                ...form_data,
                participants: [user.id, ...miniList.map(v => v.id)],
                participants_admin: form_data.participants_admin.filter(f => miniList.map(v => v.id).indexOf(f) > -1),
                tag_list: tag_list.map(v => v.tag_id)
            })
        } else {
            setForm_data({
                ...form_data,
                participants: [user.id],
                participants_admin: [user.id],
                tag_list: tag_list.map(v => v.tag_id)
            })
        }
    }, [miniList, tag_list])

    useEffect(() => {
        let state = false;

        if (form_data.title === '') {
            state = false
        } else if (form_data.b_unit_id === '') {
            state = false
        } else if (form_data.team_id === '') {
            state = false
        } else {
            state = true
        }
        setCreate_status(state)
    }, [form_data])

    const uploadFiles = async (_f) => {
        setFileLoader(true)
        var fileData = [];
        var data = new FormData();
        data.append("bucket_name", 'room-images-uploads');
        var sl = moment().format('x');
        _f[0]['originalname'] = _f[0].name;
        _f[0]['mimetype'] = _f[0].type;
        _f[0]['fieldname'] = 'file_upload';
        fileData.push(_f[0]);
        data.append("sl", sl);
        data.append("file_upload", _f[0]);
        const config = {}
        upload_obj(data, config, function (res) {
            setForm_data({ ...form_data, conv_img: res.file_info[0].key });
            setImgFound(res.file_info[0].location)
            setFileLoader(false)
        });
    }
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setForm_data({ ...form_data, [name]: value.trim() });
    // }
    const handleInputChange = (e) => {
        const { value } = e.target;
    
        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setForm_data({ ...form_data, title: value });
            return;
        }
    
        // Validate the first character to check if it's special or non-alphanumeric
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title: First character cannot be special');
            return; // Prevent update if first character is special
        }
    
        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();
    
        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return; // Prevent update if the value is empty after trimming
        }
    
        // Check if the value contains consecutive special characters
        if (/[^\w\s][^\w\s]/.test(trimmedValue)) {
            console.warn('Title cannot contain consecutive special characters');
            return; // Prevent update if there are consecutive special characters
        }
    
        // If all validations pass, update state with the validated value
        setForm_data({ ...form_data, title: trimmedValue });
    };
    

    const handleSelect = (e, type) => {
        if (type === 'team_id') {
            let team_participants = all_teams.filter(f => f.value === e.value)[0].participants_details;
            setParticipants(team_participants.map(v => v.id))
        }
        setForm_data({ ...form_data, [type]: e.value })
    }



    const [create, { data, loading, error }] = useMutation(CREATE_ROOM_MUTATION);

    const create_room_submit = async () => {
        setLoader(true);

        try {
            let res = await create(
                {
                    variables: {
                        input: {
                            title: form_data.title,
                            participants: form_data.participants,
                            participants_admin: form_data.participants_admin, //not Req
                            // participants_guest: form_data.participants_guest, //not Req
                            company_id: form_data.company_id,
                            group: 'yes',
                            team_id: form_data.team_id,//team_id
                            b_unit_id: form_data.b_unit_id,
                            conv_img: form_data.conv_img,
                            tag_list: form_data.tag_list, //not Req
                            participants_guest: seletedGuestuser.filter(g => !g.new).map(u => u.id),
                            new_participants_guest: seletedGuestuser.filter(g => g.new).map(u => ({ firstname: u.firstname, lastname: u.lastname, phone: u.phone, email: u.email }))
                        }
                    }
                }
            );
            if (res.data.create_room.status) {
                toast.success(res.data.create_room.message)
                setLoader(false);
                dispatch(add_conversations(res.data.create_room.data))
                navigate('/connect/' + res.data.create_room.data.conversation_id)
            } else {
                setLoader(false);
                toast.error(res.data.create_room.message)
            }

        } catch (error) {
            console.log(error)
        }


    }

    const add_member = () => {
        if (form_data.team_id !== '') {
            // dispatch(set_user_popup(true))
        }
    }

    useEffect(() => {
        let ids = []
        for (const iterator of tag_list) {
            ids.push(iterator.tag_id)
        }
        setForm_data({ ...form_data, tag_list: ids })
    }, [tag_list])

    const removeFromMini = (tag) => {
        setTag_list(tag_list.filter(f => f.tag_id !== tag.tag_id))
    }

    //     <div className={'miniItem'} style={{ backgroundColor: item.tag_color }} key={i}>
    //     <div className={'miniItem_name'}>{item.title}</div>
    //     <div className={'miniItem_remove'} onClick={() => removeFromMini(item)}></div>
    // </div>
    const minidiv = tag_list.map((item, i) =>

        <div key={i} style={{ backgroundColor: item.tag_color }} className='rounded flex gap-1 items-center px-2 py-1'>
            <div className='rounded-full w-3 h-3 border-white border-[2px]'></div>
            <p className='text-xs text-white'>{item.title}</p>
            <MdClose onClick={() => removeFromMini(item)} className='text-white cursor-pointer' />
        </div>

    )

    const [conferenceLink, setConferenceLink] = useState('');

    const handleAddMember = () => {
        // Logic to add default Shared team tags
    };

    const [hoveredElements, setHoveredElements] = React.useState({});

    // Function to handle mouse hover
    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    const handleGoBack = () => {
        navigate(-1)
    };

    const [inviteGuestRoomPopup, setInviteGuestRoomPopup] = useState(false);

    const isFormValid = () => {
        return (
            form_data.title.trim() !== '' &&
            form_data.b_unit_id !== '' &&
            form_data.team_id !== ''
        );
    };

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Escape') {
    //             navigate(-1)
    //         }
    //     };
    //     document.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#f3f4f5",
            cursor: "pointer",
            boxShadow: state.isFocused ? "0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;" : null,
            //   borderColor: state.isFocused ? "yellow" : "green",
        })
    };

    return (
        <>
            <Toaster />
            <div className='createGroupConv'>
                <div className="createConv_head flex justify-between" style={{ height: '80px', borderBottom: '1px solid rgb(219, 219, 219)' }}>
                    {/* <span className="createConv_back updateRoom" data-for="createConv_tooltip" data-tip="Back" onClick={handleGoBack}></span>
                    <span className="createConv_close updateRoom" data-for="createConv_tooltip" data-tip="Close" onClick={handleGoBack}></span>
                    <h2 className="createConv_title" style={{ margin: '0px 0px 0px 60px' }}>Create a room</h2> */}
                    <div className="backToMainProperties flex gap-2 items-center ml-2">
                        <div onClick={handleGoBack} className="closeAdminPanelRoom cursor-pointer mt-2" data-for="descloseTooltip" data-tip="Back"></div>
                        <div className="backtoProperties text-[#318fff]">Create a room</div>
                    </div>
                    <div>
                        <MdClose onClick={handleGoBack} className='text-[#318fff] text-2xl cursor-pointer' />
                    </div>
                </div>
                <div className='createConv_body'>
                    <div className='flex gap-5 items-center'>
                        <div className='w-[40%] flex gap-4 bg-[#f3f4f5] p-5 rounded-[10px] h-[130px]'>
                            <form className="roomImgUp_form" encType="multipart/form-data">
                                <div className="upload_section user_up !mb-0">
                                    <label className="img-div !w-[90px] !h-[90px]">
                                        <div className="content-overlay !w-[90px] !h-[90px] "></div>
                                        <div title="Image" className={'roomUploadImage !w-[90px] !h-[90px]'} alt="img" />
                                        {
                                            imgFound && <img title="Image" src={imgFound && imgFound} className={'roomUploadImage absolute !w-[90px] !h-[90px]'} alt="img" />
                                        }
                                        <div className="removeAndChange">
                                            <div className="changeButton !-top-[12px] !-left-[4px]" style={{ backgroundSize: '32px' }}>
                                                {/* Add logic for changing image */}
                                                <input type="file" id="upload_conv_img" onChange={(e) => uploadFiles(e.target.files)} name="photos" accept="image/x-png,image/jpeg,image/jpg" hidden />
                                                {/* <label htmlFor="upload_conv_img">Change</label> */}
                                            </div>
                                            {/* <div className="removeButton">Remove</div> */}
                                        </div>
                                    </label>
                                </div>
                            </form>
                            <div className=' w-full'>
                                <div className='text-[#0c1e47]'> Display photo</div>
                                <div className='border-[2px] h-[50px] border-dashed bg-[#F1F4FA] border-[#318fff] rounded-lg mt-2'>
                                    <div className="flex gap-2 justify-center items-center p-3 cursor-pointer"><p className="text-[#318fff]">Upload</p><input type="file hi" id="files" multiple hidden /></div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[60%] bg-[#f3f4f5] p-5 rounded-[10px] h-[130px]'>
                            <div className="inputGroup roomConferenceLink !w-full !pr-0 !mb-0 !mt-[5px]">
                                <div className="flex justify-between">
                                    <div><p className='text-[#0c1e47]'> Room call link</p></div>
                                    <div><p className="text-[#5b6477] cursor-pointer" data-for="voip_tooltip" data-tip="Reset call link if you found the link vulnerable">Reset link</p></div>
                                </div>
                                <div className="rgInputMsg !mt-0 !text-[#5b6477]">Share this link with teammates or guests to start a video/voice call.</div>
                                <input
                                    name="roomConferenceLink"
                                    readOnly
                                    type="text"
                                    className="team-name short_id_input !h-[30px] !border-0 !bg-[#E5F0F9] placeholder:!text-[#5b6477] mt-2"
                                    placeholder="The conference link will be created when the room is created"
                                    style={{ fontSize: '14px', pointerEvents: 'none', paddingRight: '16px' }}
                                    value={conferenceLink}
                                />

                            </div>
                        </div>
                    </div>
                    <div className='border border-[#afdaff] rounded-lg p-5 mt-5'>
                        <div className="inputGroup !pr-0 !mb-0">
                            <label className="text-[#0b1f47]">Title of room<span className="red_star">*</span></label>
                            <input type="text" 
                                name='title' 
                                onChange={handleInputChange}
                                autoFocus={true}
                                className="team-name !h-[40px] 
                                placeholder:!text-[#656565] !border-none bg-[#f3f4f5] !mt-1" 
                                maxLength="64" 
                                placeholder="Write a room title here" 
                                value={form_data.title}
                                />
                        </div>
                        <div className="inputGroup mt-5 !pr-0">
                            <div className="text-[#0b1f47] flex gap-2 items-center user_Management_title relative">
                                <p>Choose a room category<span className="red_star">*</span></p>
                                <span
                                    onMouseEnter={() => handleMouseHover('element1')}
                                    onMouseLeave={() => handleMouseLeave('element1')}
                                    className="tooltip2 user_Management_title cursor-pointer">
                                    <span className="info_tooltip_new"></span>
                                    {hoveredElements['element1'] &&
                                        <span className="tooltiptext2 inRoom" >
                                            <p>You need to select a room category when creating a new Room. This is a useful feature to help sort and categorize data such as files, images, links and working documents using the files dashboard on Workfreeli. Create custom room categories as needed.</p>
                                            <span className='tooltipClose'></span>
                                        </span>
                                    }
                                </span>
                            </div>
                            <Select
                                className="select-ecosystem cu_inputGroup_select cu_inputGroup_select_room cursor-pointer"
                                closeMenuOnSelect={true}
                                options={all_bunits}
                                menuPlacement="auto"
                                maxMenuHeight={200}
                                styles={customStyles}
                                isSearchable
                                onChange={(e) => handleSelect(e, 'b_unit_id')}
                                placeholder={<span className="selectPlaceholderRoom">Select a room category</span>}
                            />
                        </div>
                        <div className="addGuest pb-3">
                            <div className="text-[#0b1f47] flex gap-2 items-center user_Management_title relative">
                                <p>Shared team tags</p>
                                <span
                                    onMouseEnter={() => handleMouseHover('element3')}
                                    onMouseLeave={() => handleMouseLeave('element3')}
                                    className="tooltip2 user_Management_title cursor-pointer">
                                    <span className="info_tooltip_new"></span>
                                    {hoveredElements['element3'] &&
                                        <span className="tooltiptext2 inRoom" >
                                            <p>Use Shared team tags to stay organized with your team. These are tags created by an Admin for anyone in your team to use.You can set default tags to be added to all files in this room uploaded by any room members.</p>
                                            <span className='tooltipClose'></span>
                                        </span>
                                    }
                                </span>
                            </div>
                            <Link to={'tag_popup'}>
                                <div className="selectedParticipants tag_container_mini teamTag">
                                    <div className="addArea flex items-center gap-2 bg-[#f3f4f5] !h-[40px] rounded-[4px] !px-2">
                                        {minidiv} <span className='text-[#656565] text-sm'>Click to add</span> 
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="inputGroup !pr-0 !mb-0">
                            <div className="text-[#0b1f47] mt-2">Team<span className="red_star">*</span></div>
                            <div className='mt-1'>
                                <Select
                                    className="select-ecosystem cu_inputGroup_select cu_inputGroup_select_room cursor-pointer"
                                    closeMenuOnSelect={true}
                                    options={all_teams}
                                    isSearchable
                                    menuPlacement="auto"
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    onChange={(e) => handleSelect(e, 'team_id')}
                                    placeholder={<span className="selectPlaceholderRoom">Select a team</span>}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <RoomMemberList/> */}
                    <div className="name_and_profile mt-5">
                        <div className="addMember">
                            <div className="inputGroup" style={{ marginBottom: '12px' }}>
                                <div style={form_data.team_id.length > 0 ? { pointerEvents: 'auto', opacity: 1 } : { pointerEvents: 'none', opacity: 0.5 }}>
                                    <Link to={'users'} title='Users' >
                                        <button className="addMemberBtn">Add member(s) to this room</button>
                                    </Link>
                                </div>

                                <label className="inputLabel" style={{ fontSize: '14px', marginTop: '12px', marginBottom: '0px' }}>
                                    Room member[s]
                                    <span onMouseEnter={() => handleMouseHover('element2')} onMouseLeave={() => handleMouseLeave('element2')} className="info_tooltip"></span>
                                    {hoveredElements['element2'] &&
                                        <span style={{ marginLeft: '5px', cursor: 'pointer' }} className="tooltip2 user_Management_title">
                                            <span className="tooltiptext2 roomMem" >
                                                <p>Add member(s) to this room for team collaboration.</p>
                                                <span className='tooltipClose'></span>
                                            </span>
                                        </span>
                                    }
                                </label>
                            </div>
                            <div className="addedMemberList">
                                <UserList users={cacheAllUsers.users.filter(f => f.id === user?.id)} participants={form_data.participants} participants_admin={form_data.participants_admin} user={user} makeAdmin={makeAdmin} removeParticipants={removeParticipants} me={true} />
                                <UserList users={cacheAllUsers.users.filter(f => f.id !== user?.id)} participants={form_data.participants} participants_admin={form_data.participants_admin} user={user} makeAdmin={makeAdmin} removeParticipants={removeParticipants} />
                            </div>
                        </div>
                    </div>
                    <div className="name_and_profile teamProfile mt-5">
                        <div className="addGuest" style={{ paddingLeft: '20px' }}>
                            <div className="inputGroup guestButton" style={form_data.team_id.length > 0 ? { pointerEvents: 'auto', opacity: 1 } : { pointerEvents: 'none', opacity: 0.5 }}>
                            {/* <div className="inputGroup guestButton" style={form_data.team_id.length > 0 ? { pointerEvents: 'auto', opacity: 1, paddingLeft: '20px', marginBottom: '12px' } : { pointerEvents: 'none', opacity: 0.5, paddingLeft: '20px', marginBottom: '12px' }}> */}
                                <Link to={'invite_guest'} className="addMemberBtn">Invite guest(s) to this room</Link>
                                {/* <button onClick={() => setInviteGuestRoomPopup(true)} className="addMemberBtn" style={{ pointerEvents: 'auto', opacity: 1 }}>Invite guest(s) to this room</button> */}
                                <label className="inputLabel" style={{ fontSize: '16px', marginTop: '12px' }}>Room guest[s]<span className="info_tooltip"></span></label>
                            </div>
                            <div className="addedGuestList teamGuest">
                                <div className="addedMemberList">
                                    {seletedGuestuser.map(u =>
                                        <div className="memberItem">
                                            <div className="memberItemImage"><span className="nameLetters"></span></div>
                                            <div className="memberItemData">
                                                <p className="memberItemTitle">{u.firstname + " " + u.lastname} <span className="memberItemDesignation">[Guest]</span></p>
                                            </div>
                                            <div className="memberItemAction"><span className="removeAdmin" onClick={() => setSelectedguest(prev => prev.filter(g => g.id !== u.id))}>Remove</span></div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isFormValid() && (
                    <div className="createConv_foot">
                        <button
                            className="createConv_create hover:!bg-[#318EFA] !bg-[#0C1F45]"
                            style={{ pointerEvents: 'auto', opacity: 1 }}
                            onClick={create_room_submit}
                        >
                            Create room
                        </button>
                    </div>
                )}
                {/* <div className="createConv_foot">
                    <button className="createConv_create" style={{ pointerEvents: 'auto', opacity: 0.6 }} onClick={() => create_room_submit()}>Create room</button>
                </div> */}

            </div>
            {params.popup === 'tag_popup' && <TagPopup type="create_room" viewType={'public'} tag_list={tag_list.map(v => v.tag_id)} setTag_list={setTag_list} />}
            {params.popup === 'users' && <UserSelection type='create_room' miniList={miniList} setMiniList={setMiniList} participants={participants} />}

            {params.popup === 'invite_guest' && <InviteRoomGuest type='create_room' setInviteGuestRoomPopup={setInviteGuestRoomPopup} seletedGuestuser={seletedGuestuser} setSelectedguest={setSelectedguest} />}
            {/* {inviteGuestRoomPopup && <InviteRoomGuest setInviteGuestRoomPopup={setInviteGuestRoomPopup} seletedGuestuser={seletedGuestuser} setSelectedguest={setSelectedguest} />} */}
        </>
    );
};


const UserList = ({ users, participants, user, makeAdmin, participants_admin, removeParticipants, me }) => {
    const OnlineUsers = useSelector((state) => state.message.online_users);
    return (
        users.filter(f => participants.indexOf(f.id) > -1).map((v, i) =>
            <div className={OnlineUsers.indexOf(v.id) > -1 ? "memberItem online" : "memberItem offline"} key={'member_' + i}>
                <div className="memberItemImage">
                    <img src={v.img} alt="profilepic" width={30} height={30} title='profile' />
                </div>
                <div className="memberItemData">
                    <p className="memberItemTitle">
                        {v.firstname + ' ' + v.lastname} <span className="memberItemDesignation">[{user?.id === v.id ? 'Creator' : participants_admin.indexOf(v.id) > -1 ? 'Admin' : 'Member'}]</span>
                    </p>
                </div>
                {!me &&
                    <div className="memberItemAction">
                        {participants_admin.indexOf(v.id) > -1 ?
                            <span className="removeAdmin" onClick={() => makeAdmin('remove', v.id)}>Remove Admin</span>
                            :
                            <span className="makeAdmin" onClick={() => makeAdmin('add', v.id)}>Make admin</span>
                        }
                        {participants_admin.indexOf(v.id) === -1 &&
                            <span className="removeAdmin" onClick={() => removeParticipants(v.id)}>Remove</span>
                        }

                    </div>
                }

            </div>
        )
    )
}

export default CreateRoom;