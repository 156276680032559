/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import { GET_ME, GET_ALL_USERS, GET_TOTAL_UNREAD, GET_RING_CALLING, SET_CALLING_URL, GET_CALLING_ACCEPT, GET_CALLING_REJECT } from '../../Utils/GraphqlQueries';
import { LeftSection } from "./LeftSection";
import { useQuery, useLazyQuery } from '@apollo/client';
import { useDispatch } from "react-redux";
import { setCountersData, set_user } from "../../redux/message";
import SocketClient from "../../Utils/SocketClient";
import CallRinging from "../Call_window/Popup/CallRinging";
import GroupCallPopup from "../Call/GroupCallPopup";


export const Connect = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { loading: initialLoading, error: initialError, data: initialData } = useQuery(GET_ME);
    const [fetchUsers, { loading: usersLoading, error: usersError, data: usersData }] = useLazyQuery(GET_ALL_USERS);
    const { loading: counterLoading, error: counterError, data: counterData } = useQuery(GET_TOTAL_UNREAD);
    const callringpopup = useSelector(state => state.message.callringpopup);
    const active_conv = useSelector(state => state.message.active_conversation);
    const [getCallingAccept] = useLazyQuery(GET_CALLING_ACCEPT);
    const [getCallingReject] = useLazyQuery(GET_CALLING_REJECT);
    const [fetchCalling] = useLazyQuery(GET_RING_CALLING);
    const [setCallingURL] = useLazyQuery(SET_CALLING_URL);
    const [, setCallRinging] = useState(false);
    const [groupCallPopup, setGroupCallPopup] = useState(false);


    useEffect(() => {
        if (initialData?.me) {
            localStorage.removeItem('role');
            localStorage.setItem('role', initialData?.me?.role);
        }
    }, [initialData]);



    useEffect(() => {
        if (initialData?.me) {
            dispatch(set_user(initialData.me));

            const connectPaths = ['/', '/connect', '/connect/'];
            if (connectPaths.includes(location.pathname)) {
                navigate(`connect/${initialData.me.id}`);
            }
        }
    }, [initialData, dispatch, navigate, location.pathname]);


    useEffect(() => {
        if (!initialLoading && !initialError && initialData) {
            fetchUsers({ variables: { company_id: initialData.me.company_id } });
        }
    }, [initialLoading, initialError, initialData, fetchUsers]);

    useEffect(() => {
        if (!counterLoading && !counterError && counterData) {
            dispatch(setCountersData(counterData.total_unread));
        }
    }, [counterLoading, counterError, counterData, dispatch]);

    useEffect(() => {
        // Set loading to false when initial data, users data, and counter data are loaded
        if (!initialLoading && !usersLoading && !counterLoading) {
            setLoading(false);
        }
    }, [initialLoading, usersLoading, counterLoading]);

    if (loading) {
        return <div className="loaderMain">
            <p className="pt-8 text-center">Please wait as this might take some time. <br />
                Clearing old browser cache and reloading user data.</p>
        </div>;
    }

    if (initialError || usersError || counterError) {
        return <div>Error occurred. Please try again later.</div>;
    }

    return (
        <div className="app_container">
            <SocketClient user={initialData.me} users={usersData?.users} />
            <LeftSection me={initialData.me} />
            {callringpopup.status && <CallRinging data={callringpopup} getCallingAccept={getCallingAccept} getCallingReject={getCallingReject} me={initialData?.me} setCallRinging={setCallRinging} active_conv={active_conv} />}
            {/* {groupCallPopup && <GroupCallPopup fetchCalling={fetchCalling} getCallingAccept={getCallingAccept} getCallingReject={getCallingReject} setGroupCallPopup={setGroupCallPopup} active_conv={active_conv} />} */}
            <Outlet />
        </div>
    );
};
