import React, { useEffect, useState } from 'react';
import InviteNewUser from './InviteNewUser';

const UserManagementHeader = ({ filteredUsers, filteredGuests, all_user_data, setFilteredUsers, setFilteredGuests, setUserTab, selectedUsersOptions, handleSelectedOptions, setGuestsOptions, setUsersOptions, userTab, setall_user_data, filterText, setFilterContact, filterContact }) => {

    const [searchText, setSearchText] = useState('');
    const [searchGuest, setSearchGuest] = useState('');
    const [searchContact, setSearchContact] = useState('');
    const [inviteNewUser, setInviteNewUser] = useState(false);
    const [createNewUser, setCreateNewUser] = useState(false);
    const [createNewGuest, setCreateNewGuest] = useState(false);
    const [createNewContact, setCreateNewContact] = useState(false);
    const [hoveredElements, setHoveredElements] = useState({});

    const handleMouseHover = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: true,
        }));
    };
    const handleMouseLeave = (elementId) => {
        setHoveredElements((prevState) => ({
            ...prevState,
            [elementId]: false,
        }));
    };

    const handleSearch = (searchText) => {
        setSearchText(searchText);
        const filtered = all_user_data.filter(user => {
            const fullName = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
            return fullName.includes(searchText.toLowerCase()) ||
                user.email.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredUsers(filtered);
    };


    const handleSearchGuest = (searchGuest) => {
        setSearchGuest(searchGuest);
        const filtered = all_user_data.filter(v => v.role === 'Guest').filter(user => {
            const fullName = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
            return fullName.includes(searchGuest.toLowerCase()) ||
                user.email.toLowerCase().includes(searchGuest.toLowerCase());
        });
        setFilteredGuests(filtered);
    };

    const handleSearchContact = (searchContact) => {
        setSearchContact(searchContact);
        const filtered = all_user_data.filter(v => v.role === 'Recipient').filter(user => {
            const fullName = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
            return fullName.includes(searchContact.toLowerCase()) ||
                user.email.toLowerCase().includes(searchContact.toLowerCase());
        });
        setFilterContact(filtered);
    };

    // const clearSearchInput = (type) => {
    //     if (type === 'guestSearchClear') {
    //         setSearchGuest('');
    //         setFilteredGuests(all_user_data.filter(v => v.role === 'Guest'));
    //     }
    //     else if (type === 'contactSearchClear') {
    //         setSearchContact('');
    //         setFilterContact(all_user_data.filter(v => v.role === 'Recipient'));
    //     }
    //     else if (type === 'userSearchClear') {
    //         setSearchText('');
    //         setFilteredUsers(all_user_data);
    //     }
    // };
    const clearSearchInput = (type) => {
        const resetFilters = (role) => {
            switch (role) {
                case 'Guest':
                    setSearchGuest('');
                    setFilteredGuests(
                        [...all_user_data].filter(v => v.role === 'Guest')
                            .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
                    );
                    break;
                case 'Recipient':
                    setSearchContact('');
                    setFilterContact(
                        [...all_user_data].filter(v => v.role === 'Recipient')
                            .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
                    );
                    break;
                default:
                    setSearchText('');
                    setFilteredUsers(
                        [...all_user_data]
                            .sort((a, b) => new Date(b.createdat) - new Date(a.createdat))
                    );
            }
        };
    
        if (type === 'guestSearchClear') resetFilters('Guest');
        else if (type === 'contactSearchClear') resetFilters('Recipient');
        else if (type === 'userSearchClear') resetFilters();
    };

    return (
        <>
            <div className='adminRightHead'>
                <span className="user_Management_title text-5xl">User management</span>
                <span
                    onMouseEnter={() => handleMouseHover('element1')}
                    onMouseLeave={() => handleMouseLeave('element1')}
                    className="tooltip user_Management_title"><span className="info_tooltip_new"></span>
                    {
                        hoveredElements['element1'] &&
                        <span className="tooltiptext">
                            <p>Invite your teammates to collaborate with. You can invite any teammate as a Member or as an Admin. Admin users will have full access to your account's Admin Settings and will be able to jointly manage this account with you. Both Admin and Member Users will be able to use all Workfreeli collaboration features.</p>
                            <span className="tooltipClose"></span>
                        </span>}
                </span>
                {
                    selectedUsersOptions !== 'all' &&
                    <div className="miniItem !z-[9999]" style={{ marginLeft: "15px" }}>
                        <div className="miniItem_image" style={{ margin: "0px" }}>
                            <i className="fas fa-users"></i>
                        </div>
                        <div className="miniItem_name" style={{ marginLeft: "3px" }}>{filterText}</div>
                        <div onClick={() => handleSelectedOptions('all')} className="miniItem_remove !cursor-pointer"></div>
                    </div>
                }

            </div>
            <div className='usersTabSection'>
                <div className="usersTabSecDevide lefttSide relative" style={{ gap: '10px' }}>
                    <div onClick={() => { setUserTab('only_user'); clearSearchInput("userSearchClear")}} className={`${userTab === 'only_user' ? "active" : ""} usersTabList teamManagementTitle`} >
                        <div style={{ cursor: 'pointer' }}><p>Users ({filteredUsers.filter(f => f.role === 'Admin' || f.role === 'Member').length})</p></div>
                        <div className='adminUserFilter' onClick={() => setUsersOptions(true)}></div>
                    </div>
                    <div onClick={() => { setUserTab('guest'); clearSearchInput('guestSearchClear');}}  className={`${userTab === 'guest' ? "active" : ""} usersTabList teamManagementTitle`} >
                        <div style={{ cursor: 'pointer' }}><p>Guests ({filteredGuests.filter(f => f.role === 'Guest').length})</p></div>
                        <div className='adminUserFilter' onClick={() => setGuestsOptions(true)}></div>
                    </div>
                    <div onClick={() => { setUserTab('contacts'); clearSearchInput('contactSearchClear');}}  className={`${userTab === 'contacts' ? "active" : ""} usersTabList teamManagementTitle`} >
                        <div style={{ cursor: 'pointer' }}><p>Contacts ({filterContact.filter(f => f.role === 'Recipient').length})</p></div>
                    </div>
                </div>
                <div className='usersTabSecDevide rightSide'>
                    <div className='relative'>
                        {userTab === 'only_user' ? <>
                            <input
                                className="userSearchInput"
                                type="text"
                                placeholder="Search user"
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                                autoFocus={true}
                            />
                            {searchText && <span className="leftSearchCloseBtn remove !right-5" onClick={() => clearSearchInput("userSearchClear")}></span>}
                        </> :
                            userTab === 'guest' ?
                                <>
                                    <input
                                        className="userSearchInput"
                                        type="text"
                                        placeholder="Search guest"
                                        value={searchGuest}
                                        onChange={(e) => handleSearchGuest(e.target.value)}
                                    />
                                    {searchGuest && <span className="leftSearchCloseBtn remove !right-5" onClick={() => clearSearchInput("guestSearchClear")}></span>}
                                </> :
                                userTab === 'contacts' ? <>
                                    <input
                                        className="userSearchInput"
                                        type="text"
                                        placeholder="Search contact"
                                        value={searchContact}
                                        onChange={(e) => handleSearchContact(e.target.value)}
                                    />
                                    {searchContact && <span className="leftSearchCloseBtn remove !right-5" onClick={() => clearSearchInput("contactSearchClear")}></span>}
                                </> : ""
                        }
                    </div>
                    {userTab === 'only_user' ?
                        <button
                            onClick={() => setInviteNewUser(true)}
                            onMouseEnter={() => handleMouseHover('element2')} onMouseLeave={() => handleMouseLeave('element2')}
                            className="!mr-[10px] userCreateBtn hover:!bg-[#318FFF] tooltip5">Invite a new user</button> :
                        userTab === 'guest' ?
                            <button
                                onClick={() => setCreateNewGuest(true)}
                                onMouseEnter={() => handleMouseHover('element4')} onMouseLeave={() => handleMouseLeave('element4')}
                                className=" userCreateBtn hover:!bg-[#318FFF] tooltip5">Invite a new guest</button> :
                            userTab === 'contacts' ?
                                <button
                                    onClick={() => setCreateNewContact(true)}
                                    onMouseEnter={() => handleMouseHover('element5')} onMouseLeave={() => handleMouseLeave('element5')}
                                    className=" userCreateBtn hover:!bg-[#318FFF] tooltip5">Create a contact</button> : ""
                    }

                    {
                        Object.keys(hoveredElements).map((key) => {
                            const tooltips = {
                                element2: { text: "Invite your teammates to collaborate with.", width: "295px" },
                                element4: { text: "Invite a guest user to collaborate with.", width: "270px" },
                                element5: { text: "Invite a contact user to collaborate with.", width: "280px" },
                            };

                            if (hoveredElements[key] && tooltips[key]) {
                                return (
                                    <span key={key} className="user_Management_title">
                                        <span className={`tooltiptext5 !w-[${tooltips[key]?.width}]`}>
                                            <p>{tooltips[key].text}</p>
                                            <span className="tooltipClose"></span>
                                        </span>
                                    </span>
                                );
                            }
                            return null;
                        })
                    }


                    {userTab === 'only_user' &&
                        <div onMouseEnter={() => handleMouseHover('element6')} onMouseLeave={() => handleMouseLeave('element6')} onClick={() => setCreateNewUser(true)} className="opt_icons conv_more addUser"></div>
                    }
                    {
                        hoveredElements['element6'] &&
                        <span className="user_Management_title" >
                            <span className="tooltiptext5 newUserTool" >
                                <p> Create a new user. </p>
                                <span className="tooltipClose"></span>
                            </span>
                        </span>
                    }
                </div>
            </div>
            {
                inviteNewUser && <InviteNewUser all_user_data={all_user_data} setall_user_data={setall_user_data} onClose={() => setInviteNewUser(false)} popup_type={'invite'} />
            }
            {
                createNewUser && <InviteNewUser all_user_data={all_user_data} setall_user_data={setall_user_data} onClose={() => setCreateNewUser(false)} popup_type={'create'} />
            }
            {
                createNewGuest && <InviteNewUser all_user_data={all_user_data} setall_user_data={setall_user_data} onClose={() => setCreateNewGuest(false)} popup_type={'guest'} />
            }
            {
                createNewContact && <InviteNewUser all_user_data={all_user_data} setall_user_data={setall_user_data} onClose={() => setCreateNewContact(false)} popup_type={'contact'} />
            }
        </>
    );
};

export default UserManagementHeader;