/* eslint-disable react-hooks/exhaustive-deps */
import { useClickAway } from '@uidotdev/usehooks';
import React, { useEffect, useState, useRef } from 'react';
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw, Modifier } from 'draft-js';
import { useSelector } from 'react-redux';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { set_raw_message_text } from '../../redux/message';
import { toast } from 'react-hot-toast';
import { BsEmojiSmile } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import { HiMiniMicrophone } from "react-icons/hi2";
import { Tooltip } from 'react-tooltip';
import { validFirstChercterAlsoNuber } from '../ValidationCheck';

export const PrivateMsgTitle = ({ setStep, miniList, setMiniList, form_data, setForm_data }) => {
    const { allUsers: users, user, active_conversation, raw_message_text } = useSelector(state => state.message)
    const dispatch = useDispatch()
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const editorRef = useRef(null);
    const [voice, setVoice] = useState(false)
    const [wrongText] = useState(false);
    const {
        listening,
        transcript,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const ToggleVoice = (type) => {
        if (browserSupportsSpeechRecognition) {
            if (isMicrophoneAvailable) {
                if (listening) {
                    SpeechRecognition.abortListening();
                    resetTranscript();
                } else {
                    SpeechRecognition.startListening({ continuous: true })
                }
            } else {
                toast.error("Mircrophone access denied !", { duration: 2000 });
            }
        } else {
            toast.error("Your Browser doesn't  support this feature", { duration: 2000 });
        }
        setVoice(type)
    }

    useEffect(() => {
        if (transcript.length > 0) {
            var cc = (transcript === 'quotation' || transcript === 'semicolon' || transcript === 'new line' || transcript === "dash" || transcript === "comma" || transcript === 'underscore' || transcript === 'full stop' || transcript === 'dot' || transcript === 'enter' || transcript === 'exclamation mark' || transcript === 'question mark' || transcript === 'square bracket' || transcript === 'round bracket' || transcript === 'apostrophe' || transcript === 'tab' || transcript === 'space' ? '' : ' ') + transcript.replace(/tab/gmi, '\t').replace(/new line|enter/gmi, "\n").replace(/full stop|dot/gmi, ".").replace(/space/gmi, " ").replace(/semicolon/gmi, ";").replace(/underscore/gmi, "_").replace(/hyphen/gmi, "-").replace(/Question mark/gmi, "?").replace(/exclamation mark/gmi, "!").replace(/quotation/gmi, "'").replace(/comma/gmi, ",").replace(/dash/gmi, "-").replace(/square bracket/gmi, "[ ]").replace(/round bracket/gmi, "( )").replace(/apostrophe|appostrophe/gmi, "'")
            const contentBlock = htmlToDraft(cc);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(EditorState.moveFocusToEnd(editorState));
            }
        }
    }, [transcript])

    const onEditorStateChange = (state) => {
        setEditorState(state);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        dispatch(set_raw_message_text(markup))
    };

    const returnMentions = () => {
        if (!active_conversation?.group || active_conversation?.group !== 'yes') return [];

        // Get participant details
        const participantDetails = miniList;
        // users.filter(u =>
        //     active_conversation?.participants.includes(u.id)
        // );

        // Build mention list
        const mentions = participantDetails
            .filter(f => f.id !== user.id) // Exclude the current user
            .map(u => ({
                text: `${u.firstname} ${u.lastname}`,
                value: `${u.firstname} ${u.lastname}`,
                url: `#mention_${u.id}`,
            }));

        // Add "Everyone" mention if there are participants
        if (participantDetails.length > 0) {
            const everyoneId = participantDetails.map(u => u.id).join(',');
            mentions.unshift({
                text: 'Everyone',
                value: 'Everyone',
                url: `#mention_${everyoneId}`,
            });
        }

        return mentions;
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setForm_data({ ...form_data, [name]: value })
    // }
    const handleChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setForm_data({ ...form_data, title: value });
            return;
        }

        // Validate the first character to check if it's special or non-alphanumeric
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title: First character cannot be special');
            return; // Prevent update if first character is special
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return; // Prevent update if the value is empty after trimming
        }

        // Check if the value contains consecutive special characters
        if (/[^\w\s][^\w\s]/.test(trimmedValue)) {
            console.warn('Title cannot contain consecutive special characters');
            return; // Prevent update if there are consecutive special characters
        }

        // If all validations pass, update state with the validated value

        setForm_data({ ...form_data, title: trimmedValue })
    };

    const minidiv = miniList.map((item, i) =>
        <div className={'miniItem'} key={i}>
            <div className="miniItem_image">
                <img src={item.img} alt="userImg" title='user' />
            </div>
            <div className={'miniItem_name'}>{item.display_title}</div>
            {/* <div className={'miniItem_remove'}></div> */}
        </div>
    )

    const isNextButtonActive = form_data.title.trim() !== '' && editorState.getCurrentContent().hasText();

    const close_popup = () => {
        setStep(1);
    };

    const savePrivateMsg = () => {
        setStep(3)
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setStep(1);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [setStep]);

    const [fileCommentEmoji, setFileCommentEmoji] = useState(false)

    const ref = useClickAway(() => {
        setFileCommentEmoji(false)
    })

    const handleEmojiSelect = (emoji) => {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithInsert = Modifier.insertText(currentContent, selection, emoji.native, null);
        const editorWithInsert = EditorState.push(editorState, textWithInsert, emoji.native);

        const newEditorState = EditorState.forceSelection(editorWithInsert, textWithInsert.getSelectionAfter());
        setEditorState(newEditorState);
        // setEmojiView(false);
    };

    useEffect(() => {
        setTimeout(() => {
            if (raw_message_text) {
                const contentBlock = htmlToDraft(raw_message_text);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(EditorState.moveFocusToEnd(editorState));
                }
            }
        }, 100)
    }, [])

    return (
        <div className="backwrap relative" style={{ display: 'flex' }}>
            <div className="participantsModal">
                <div className="participantsModalHead">
                    <h4 className="popupTitle"> Private message to : </h4>
                    <span className="closeModal" onClick={close_popup}></span>
                </div>
                <div className='privateModalBody'>
                    <div className="selectedParticipants">
                        {minidiv}
                        <div className="edit_action_U" onClick={close_popup} >Add/Edit</div>
                    </div>
                    <div className="customTitleBody !pb-0">
                        <label className="inputLabel">Add a title for private message</label>
                        <input type="text" maxLength={64} value={form_data.title} onChange={(e) => handleChange(e)} className="customTitleInput" placeholder={' Add a title for private message '} name='title' />
                        <span className="passwordInfoLabel" style={{ position: 'unset' }}>This title will be visible to private user(s) in this room.</span>
                    </div>

                    <div className='chat-file-upload-comment'>
                        <label>Write a message/comment</label>
                        <div className="secretUserList"></div>
                        <div className="relative">
                            <Editor
                                editorRef={(ref) => (editorRef.current = ref)}
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName={wrongText ? "demo-editor darkBorder file_comment_boxRed" : "demo-editor file_comment_box darkBorder"}
                                placeholder={"Write a message/comment"}
                                mention={{
                                    separator: ' ',
                                    trigger: '@',
                                    suggestions: returnMentions()
                                }}
                                onEditorStateChange={onEditorStateChange}
                            />
                            {/* <div className="send_msg_opt inInputMsgBox !right-[0px] !top-[9px] !h-[22px] w-[50px]">
                                <div className="!m-0 microphone_audio_on_file" data-for="voice" data-tip="Send a voice message."></div>
                                <div onClick={() => setFileCommentEmoji(!fileCommentEmoji)} style={!fileCommentEmoji ? {} : { pointerEvents: 'none' }} data-for="Insertemoji" data-tip="Insert emoji">{!fileCommentEmoji ? <BsEmojiSmile className='text-[#06246B]' /> : <MdOutlineCancel className='text-red-600 text-[18px]' />}</div>
                            </div> */}
                            <div className='absolute !right-[0px] !top-[9px] !h-[22px] w-[50px]'>
                                <div className="flex gap-2">
                                    <HiMiniMicrophone id="voice" className='text-[#06246B] text-lg cursor-pointer' />
                                    <div onClick={() => setFileCommentEmoji(!fileCommentEmoji)} style={!fileCommentEmoji ? {} : { pointerEvents: 'none' }} id="Insertemoji">{!fileCommentEmoji ? <BsEmojiSmile className='text-[#06246B] cursor-pointer' /> : <MdOutlineCancel className='text-red-600 text-[19.5px] -ml-[2px] -mt-[2px] cursor-pointer' />}</div>
                                </div>
                            </div>
                            <div onClick={() => ToggleVoice(!voice)} className={`${voice ? 'voiceIconMsgBox' : 'voiceIcon1MsgBox'} left-[5px] top-[9px]`} id="voice_text"></div>
                        </div>
                    </div>
                    <div className="customTitleFoot">
                        <button className="btnCancel" style={{ marginTop: '0px' }} onClick={close_popup}>Cancel</button>
                        <button className={`saveTitle active bg-[#06246B] hover:bg-[#0069d9] ${isNextButtonActive ? '' : 'disabled'}`} style={isNextButtonActive ? {} : { pointerEvents: 'none', opacity: '0.7' }} onClick={isNextButtonActive ? savePrivateMsg : undefined}>Next</button>
                    </div>
                </div>
                <Tooltip delayShow={1000} anchorSelect="#voice" content="Send a voice message"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#Insertemoji" content="Insert emoji"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#voice_text" content="Click voice to text. 'send', => message send, 'clear' => clear text, 'Enter' => new line"></Tooltip>
            </div>
            {
                fileCommentEmoji &&
                <div className="z-[1000] absolute" ref={ref}>
                    <Picker
                        perLine={9}
                        set={'Apple'}
                        theme="light"
                        data={data}
                        previewPosition="none"
                        onEmojiSelect={handleEmojiSelect}
                    />
                </div>
            }
        </div>

    )
}