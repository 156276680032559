
import React, { useEffect, useState } from 'react';
import { UserSelection } from '../GlobalPopups/UserSelection';
import { PrivateMsgTitle } from '../GlobalPopups/PrivateMsgTitle';
import { useSelector } from 'react-redux';
import FileUploadNew from './FileUpload/FileUploadNew';
import { UploadProvider } from '../../context/UploadContext';
export const PrivateMsg = () => {
    const global_action_data = useSelector(state => state.message.global_action_data);
    const ThisRoom = useSelector(state => state.message.active_conversation);
    const [step, setStep] = useState(1)

    useEffect(() => {
        console.log("🚀 ~ PrivateMsg ~ step:", step)
    }, [step])
    const [miniList, setMiniList] = useState([]);
    const [form_data, setForm_data] = useState({
        title: '',
        msg: ''
    })

    if (global_action_data && global_action_data.type === 'private_msg_update') {
        console.log("🚀 ~ PrivateMsg ~ ThisRoom: 2", ThisRoom)
        return (
            <UserSelection type="private_msg_update" miniList={miniList} setMiniList={setMiniList} setStep={setStep} ThisRoom={ThisRoom} global_action_data={global_action_data} />
        )
    } else {
        return (
            <>
                {step === 1 && <UserSelection type="private_msg" miniList={miniList} setMiniList={setMiniList} setStep={setStep} ThisRoom={ThisRoom} />}
                {step === 2 && <PrivateMsgTitle form_data={form_data} setForm_data={setForm_data} miniList={miniList} setMiniList={setMiniList} setStep={setStep} />}
                {step === 3 && <UploadProvider> <FileUploadNew step={step} setStep={setStep} type="private_msg" form_data={form_data} secret_user={miniList.map(v => v.id)} is_reply_msg={'no'} /></UploadProvider>}
                {/* {step === 4 && <FileUpload step={step} setStep={setStep} type="private_msg" form_data={form_data} secret_user={miniList.map(v => v.id)} is_reply_msg={'no'} />} */}
            </>
        );
    }


};

