import React, {  useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import validator from 'validator';
import { Toaster } from 'react-hot-toast';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Summary from './Summary';
function SupportAndBillingInvoice(props) {
    // //console.log('SupportAndBillingCheckOut',props);
    const [values, setValues] = useState({
        first_name: props.billingData.address.length !== 0 ? props.billingData.address.first_name:'' ,
        last_name: props.billingData.address.length !== 0 ? props.billingData.address.last_name:'' ,
        street_address:props.billingData.address.length !== 0 ? props.billingData.address.street_address:'',
        email:props.billingData.address.length !== 0 ? props.billingData.address.email:'',
        province:props.billingData.address.length !== 0 ? props.billingData.address.province:'',
        // apt_number:props.billingData.address.length !== 0 ? props.billingData.address.apt_number:'',
        zip:props.billingData.address.length !== 0 ? props.billingData.address.zip:'',
        companyName:props.billingData.address.length !== 0 ? props.billingData.address.company:'',
        cityName:props.billingData.address.length !== 0 ? props.billingData.address.city:'',
        countryName:props.billingData.address.length !== 0 ? props.billingData.address.country:'',
        
        // billingCardNumber:"",
        // billingCardHolderName:"",
        // billingExpirationDate:new Date(),
        // billingSecurityCode:"",        
    });
    const [inputfirstName, setInputFirstNameColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputCountryName, setInputCountryNameColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputStreetAddress, setInputStreetAddressColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputEmailAddress, setInputEmailAddressColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputCityName, setInputCityNameColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputProvince , setInputProvinceColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [inputPostalCode, setInputPostalCodeColor] = useState({msg: '', style:{borderColor : 'rgb(230, 230, 230)'}});
    const [checked, setChecked] = useState(false);
 
      const inputHandle = (event, type) => {
            let value = event.target.value;
            if (type === 'first_name') {
                if (validator.isEmpty(value) || validator.isNumeric(value.charAt(0))) {
                    // setInputFirstNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputFirstNameColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                } else {
                    // setInputFirstNameColor({ borderColor:'rgb(230, 230, 230)'});
                    setInputFirstNameColor({
                        msg: '', 
                        style: { borderColor:'rgb(230, 230, 230)'}
                    });
                }
                setValues({ ...values, [type]: value })
            }
            else if (type === 'countryName') {
                if (validator.isEmpty(value)) {
                    // setInputCountryNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputCountryNameColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                } else{
                    // setInputCountryNameColor({ borderColor:'rgb(230, 230, 230)'});
                    setInputCountryNameColor({
                        msg: '', 
                        style: { borderColor:'rgb(230, 230, 230)'}
                    });
                }
                setValues({ ...values, [type]: value })
            }
            else if (type === 'street_address') {
                if (validator.isEmpty(value)) {
                    // setInputStreetAddressColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputStreetAddressColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                }else{
                    // setInputStreetAddressColor({borderColor:'rgb(230, 230, 230)'});
                    setInputStreetAddressColor({
                        msg: '', 
                        style: {borderColor:'rgb(230, 230, 230)'}
                    });
                }
                setValues({ ...values, [type]: value })
            }  
            else if (type === 'email') {
                
                var arrEmail=[];
                if(value.includes(',')){ arrEmail = value.split(","); }
                else if(value.includes(';')){ arrEmail = value.split(";");}
                else{ arrEmail.push(value);}

                var status = 0;
                for(let email of arrEmail){
                    if (validator.isEmail(email)) { status++; }
                }
                if(status === arrEmail.length){
                    setInputEmailAddressColor({
                        msg: '', 
                        style: {borderColor:'rgb(230, 230, 230)'}
                    });

                }else{
                    setInputEmailAddressColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });

                }
                
                setValues({ ...values, [type]: value })
            }  
            else if (type === 'cityName') {
                if (validator.isEmpty(value)) {
                    // setInputCityNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputCityNameColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                }else{
                    // setInputCityNameColor({borderColor:'rgb(230, 230, 230)'});
                    setInputCityNameColor({
                        msg: '', 
                        style: {borderColor:'rgb(230, 230, 230)'}
                    });
                }
                setValues({ ...values, [type]: value })
            } 
            else if (type === 'province') {
                if (validator.isEmpty(value)) {
                    // setInputProvinceColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputProvinceColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                }else{
                    // setInputProvinceColor({borderColor:'rgb(230, 230, 230)'});
                    setInputProvinceColor({
                        msg: '', 
                        style: {borderColor:'rgb(230, 230, 230)'}
                    });
                }
                setValues({ ...values, [type]: value })
            } 
            else if (type === 'zip') {
                // if (validator.isEmpty(value) || !validator.isNumeric(value)) {
                    if (validator.isEmpty(value) ) {
                    // setInputPostalCodeColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                    setInputPostalCodeColor({
                        msg: '', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                }else{
                    // setInputPostalCodeColor({borderColor:'rgb(230, 230, 230)'});
                    setInputPostalCodeColor({
                        msg: '', 
                        style: {borderColor:'rgb(230, 230, 230)'}
                    });
                    // setValidForm(false);
                }
                setValues({ ...values, [type]: value })
            }

            else {
                if (value === '') {
                    // setValidForm(true);
                }else{
                    event.target.classList.remove('error');
                    // setValidForm(false);
                }
                setValues({ ...values, [type]: value })
                
            }
    }     
    const [loader,setLoader] = useState(false)
    
    const onClickHandler = async () => {

            var status = true;
            
            if(!values.first_name) {
                // toast.error('First name should not be empty'); 
                // setInputFirstNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                setInputFirstNameColor({
                    msg: 'First name should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
            }
            if(!values.email) {
                // toast.error('First name should not be empty'); 
                // setInputFirstNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                setInputEmailAddressColor({
                    msg: 'Email address should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
            }else{
                var arrEmail=[];
                if(values.email.includes(',')){ arrEmail = values.email.split(","); }
                else if(values.email.includes(';')){ arrEmail = values.email.split(";");}
                else{ arrEmail.push(values.email);}

                var email_status = 0;
                for(let email of arrEmail){
                    if (validator.isEmail(email)) { email_status++; }
                }
                if(email_status !== arrEmail.length){
                    setInputEmailAddressColor({
                        msg: 'Email address is not valid', 
                        style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                    });
                    status = false;

                }
            }
            if(validator.isNumeric(values.first_name.charAt(0))) {
                setInputFirstNameColor({
                    msg: 'First character should not be a number', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
            }
            
            if(!values.countryName){
                setInputCountryNameColor({
                    msg: 'Country name should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
            }
            if(!values.street_address){
                // toast.error('Street address should not be empty');
                // setInputStreetAddressColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                setInputStreetAddressColor({
                    msg: 'Street address should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
            }
            
            if(!values.cityName){
                // toast.error('City name should not be empty');
                // setInputCityNameColor({border:'2px solid #df1b41',boxShadow: 'unset'});
                setInputCityNameColor({
                    msg: 'City name should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
    
            }
           
            if(!values.zip){
                
                setInputPostalCodeColor({
                    msg: 'Postal code should not be empty', 
                    style: {border:'2px solid #df1b41',boxShadow: 'unset'}
                });
                status = false;
    
            } 
            if(status){
                props.setActiveTab(4);   
            }
          

            
    }
 
 
 

    const onClickNextHandler = () => {
        //console.log('clicked');
        props.setActiveTab(2);
    }

 
    const provinceChange = () => {
        
        if (checked){
            let oldval = props.billingData.address.length !== 0 ? props.billingData.address.province : '';
            setValues({ ...values, province: oldval });
        }else{
            setValues({ ...values, province: 'N/A' });
        }
        setChecked(!checked);
    }
    return (

            <>
          
            <div><Toaster /></div>
                    <Scrollbars autoHide>
                        <form>
                            <div className="supportAndBillingContainer upgradePlan checkout">
                                <div className='UpgradePlanLeftArea'>
                                    <div className='PlanLeftAreaInner checkout'>
                                        <div className='billingCheckout'>
                                            <h3 className='billingAddress font-semibold'>Billing Address for Invoice/Receipt</h3>
                                        </div>
                                        <div className='billingAddressForm'>   
                                            <div className='billingAddressFormInput'>
                                                <div className='billingInput50' style={{marginRight:"20px"}}>
                                                    {/* <p className="inputLabel"> First Name</p> */}
                                                    <label className="inputLabel">First name <span style={{color:'red'}}>*</span></label>
                                                    <input id='first_name' 
                                                    className='paymentInput' 
                                                    placeholder="Enter first name"
                                                     onChange={(event)=> inputHandle(event, 'first_name')} 
                                                     type="text" name="first_name" 
                                                     value={ values.first_name} 
                                                     minLength="3" maxLength="40" 
                                                     autoFocus="autofocus" 
                                                     style={inputfirstName.style}
                                                     />
                                                     <span className='span-text-red'>{inputfirstName.msg}</span>
                                                    {/* <span className="rgInputMsg" style={(values.first_name.length > 39 || values.first_name.length < 3) && values.first_name !=='' ? {color:'red'} :  { color: '#9f9898'}}>Minimum 3 and maximum 40 characters are allowed.</span> */}
                                                </div>
                                                <div className='billingInput50' style={{marginLeft:"20px"}}>
                                                    <p className="inputLabel"> Last name</p>
                                                    <input className='paymentInput' placeholder="Enter last name"  type="text" name="last_name" onChange={(event)=> inputHandle(event, 'last_name')} value={ values.last_name} />
                                                </div>
                                            </div>
                                            <div className='billingAddressFormInput'>
                                                <div className='billingInput100' >
                                                    <p style={{margin: '0px',padding:'0px'}} className="inputLabel"> Email addresses for invoice/receipt
                                                    <span style={{color:'red'}}>*</span>
                                                    </p>
                                                    
                                                    <input id="streetAddress" 
                                                    className='paymentInput'
                                                     placeholder="Enter email address"  
                                                     type="text" name="email_address" 
                                                     onChange={(event)=> inputHandle(event, 'email')} 
                                                     value={ values.email} 
                                                     style={inputEmailAddress.style}
                                                     />
                                                    <p className="rgInputMsg">
                                                        <span style={{ color: 'red' }}>*</span>Use coma(,) between multiple recipients
                                                    </p>
                                                     <span className='span-text-red'>{inputEmailAddress.msg}</span>

                                                </div>
                                            </div>
                                            <div className='billingAddressFormInput'>
                                                <div className='billingInput100' >
                                                    <p className="inputLabel"> Company </p>
                                                    <input id="companyName" className='paymentInput' placeholder="Enter Company name"  type="text" name="companyName" onChange={(event)=> inputHandle(event, 'companyName')} value={ values.companyName} />
                                                </div>
                                            </div>
                                           
                                            
                                            <div className='billingAddressFormInput'>
                                                <div className='billingInput100' >
                                                    <p className="inputLabel"> Street address <span style={{color:'red'}}>*</span></p>
                                                    <input id="streetAddress" 
                                                    className='paymentInput'
                                                     placeholder="Street address"  
                                                     type="text" name="street_address" 
                                                     onChange={(event)=> inputHandle(event, 'street_address')} 
                                                     value={ values.street_address} 
                                                     style={inputStreetAddress.style}
                                                     />
                                                     <span className='span-text-red'>{inputStreetAddress.msg}</span>
                                                </div>
                                            </div>
                                            <div className='billingAddressFormInput cityInfo'>
                                                <div className='billingInput25' >
                                                    <p className="inputLabel"> City <span style={{color:'red'}}>*</span></p>
                                                    <input id='cityName' 
                                                    className='paymentInput' 
                                                    placeholder="City name"  
                                                    type="text" name="cityName" 
                                                    onChange={(event)=> inputHandle(event, 'cityName')} 
                                                    value={ values.cityName} 
                                                    style={inputCityName.style}
                                                    />
                                                    <span className='span-text-red'>{inputCityName.msg}</span>
                                                    
                                                </div>
                                                <div className='billingInput25 relative !w-[35%]'>
                                                        <p className="inputLabel provinceLabel"> Province
                                                            <label className="container_checkbox"><span>Does not apply</span>
                                                            <input type="checkbox" defaultChecked={checked}
                                                                onChange={provinceChange}  />
                                                                
                                                            </label>

                                                        </p>
                                                        
                                                    
                                                    <input id='province' className='paymentInput' 
                                                    placeholder="Enter province" 
                                                    type="text" name="province" 
                                                    onChange={(event)=> inputHandle(event, 'province')} 
                                                    value={ values.province} 
                                                    style={inputProvince.style}
                                                    />
                                                    <span className='span-text-red'>{inputProvince.msg}</span>
                                                   
                                                </div>
                                                <div className='billingInput25' >
                                                    <p className="inputLabel"> Postal code<span style={{color:'red'}}>*</span></p>
                                                    <input id='zip' 
                                                    className='paymentInput' 
                                                    placeholder="Postal code"  
                                                    type="text" 
                                                    name="zip" 
                                                    onChange={(event)=> inputHandle(event, 'zip')} 
                                                    value={ values.zip} 
                                                    style={inputPostalCode.style}
                                                    />
                                                    <span className='span-text-red'>{inputPostalCode.msg}</span>
                                                     
                                                </div>
                                            </div>
                                            <div className='billingAddressFormInput'>
                                                <div className='billingInput100' >
                                                    <p className="inputLabel"> Country <span style={{color:'red'}}>*</span></p>
                                                    <input id='countryName' 
                                                    className='paymentInput' 
                                                    placeholder="Country name"  
                                                    type="text" name="countryName" 
                                                    onChange={(event)=> inputHandle(event, 'countryName')} 
                                                    value={ values.countryName} 
                                                    style={inputCountryName.style}
                                                    
                                                    />
                                                    <span className='span-text-red'>{inputCountryName.msg}</span>
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                        
                                </div>
                            <Summary billingData={props.billingData} loader={loader} onClickNextHandler={onClickNextHandler}  onClickHandler={onClickHandler} btn="" />

                            </div>  
                        </form>
                    </Scrollbars>
                
               
            </>
    )
}





export default SupportAndBillingInvoice;