/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import TagsPopupNew from '../Tags/TagsPopupNew';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/client';
import { GET_ME } from '../../../Utils/GraphqlQueries';
import moment from 'moment';
import { set_connect_files, set_messages, set_raw_message_text, set_voice_file, setBooleanState, setPopup } from '../../../redux/message';
import { aesEncrypt, fileExtension, modifyMyMsg, scrollToBottom, upload_obj } from '../../../Utils/Common';
import { sendMsgMutation } from '../../../Utils/GraphqlMutaion';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import DOMPurify from 'isomorphic-dompurify';
import { UploadContext } from '../../../context/UploadContext';
import { useClickAway } from '@uidotdev/usehooks';
import { Tooltip } from 'react-tooltip';
import { MdAdd } from "react-icons/md";
import { GoTrash } from "react-icons/go";
var all_files = [];

const FileUploadNew = ({ type, ...otherProps }) => {
    const { selected_ids } = useContext(UploadContext)
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const active_conv = useSelector((state) => state.message.active_conversation)
    const messages = useSelector((state) => otherProps.is_reply_msg === 'no' ? state.message.msgs : state.message.reply_msgs)
    const { voice_file, connect_files, raw_message_text, triggerUpload } = useSelector(state => state.message);

    const [voiceSetType, setVoiceSetType] = useState("");
    const [voiceSetTitle, setVoiceSetTitle] = useState("");

    // Use useEffect to update the states when voice_file changes
    // useEffect(() => {
    //     debugger
    //     if (voice_file?.length) {
    //         const firstFile = voice_file[0]; // Access the first file
    //         setVoiceSetType(firstFile.voice_set_type || "");
    //         setVoiceSetTitle(firstFile.voice_set_title || "");
    //     } else {
    //         setVoiceSetType("");
    //         setVoiceSetTitle("");
    //     }
    // }, [voice_file]); // Dependency array ensures it runs when voice_file changes



    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const file_ref = useRef(null)
    const [isEditing, setIsEditing] = useState(null); // Track which file is being edited
    const [isReference, setIsReference] = useState(null);
    const [step, setStep] = useState("upload")
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [referenceType, setReferenceType] = useState('');
    const [progress, setProgress] = useState(0); // Initial progress state
    const [referenceID, setReferenceID] = useState('');
    const [savedReferenceID, setSavedReferenceID] = useState('');

    const onEditorStateChange = (state) => {
        setEditorState(state);
    };

    const [files, setFiles] = useState([]);
    const maxFiles = 10; // Define the maximum upload limit

    const [completed_files, set_completed_files] = useState([])
    const uploadFiles = async (_f) => {
        // Convert FileList to Array
        const fileArray = Array.from(_f);

        const all_files = [...files]; // Ensure this is defined in your component state
        const bucket_name = user.email.replace("@", "-").replace(/\./g, "-");

        // Prepare file metadata
        fileArray.forEach((file, index) => {
            const sl = moment().format('x') + index;
            Object.assign(file, {
                sl,
                originalname: file.name,
                mimetype: file.type,
                fieldname: 'file_upload',
            });

            const sf = {
                sl,
                file_name: file.name,
                mimetype: file.type,
                file_size: file.size,
                status: 0,
                progress: '0%',
                local_url: URL.createObjectURL(file),
            };

            all_files.push(sf);
        });

        setFiles([...all_files]);

        // Upload files
        for (const file of fileArray) {
            const data = new FormData();
            data.append("bucket_name", bucket_name);
            data.append("sl", file.sl);
            data.append("file_upload", file);

            try {
                const response = await upload_obj(data, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        const element = document.getElementById(`file_container_${file.sl}`);
                        if (element) {
                            const progressStatus = element.querySelector('.progressStatus');
                            const progressColor = element.querySelector('.progressColor');

                            if (percentCompleted < 100) {
                                progressStatus.textContent = `${percentCompleted}%`;
                                progressColor.style.width = `${percentCompleted}%`;
                            } else {
                                progressStatus.textContent = "Processing...";
                            }
                        }
                    },
                });

                // Process successful upload
                const completedFile = all_files.find(f => f.sl === response.sl);
                console.log("🚀 ~ uploadFiles ~ completedFile:", completedFile)
                if (completedFile) {
                    const newFile = {
                        ...response.file_info[0],
                        oldname: completedFile.file_name,
                        sl: completedFile.sl,
                        size: response.file_info[0].size,
                    };
                    set_completed_files(prev => [...prev, newFile])
                }

                const element = document.getElementById(`file_container_${response.sl}`);
                if (element) {
                    element.classList.add('upload_completed');
                    const progressContainer = element.querySelector('.progressContainer');
                    if (progressContainer) {
                        progressContainer.style.display = "none";
                    }
                }
            } catch (error) {
                console.error("Upload error:", error);
            }
        }
        // debugger
        if (voice_file?.length) {
            const firstFile = voice_file[0]; // Access the first file
            setVoiceSetType(firstFile.voice_set_type || "");
            setVoiceSetTitle(firstFile.voice_set_title || "");
        } else {
            setVoiceSetType("");
            setVoiceSetTitle("");
        }

        // Clean up
        dispatch(set_voice_file(null));
        // if (file_ref.current) {
        //     file_ref.current.value = null;
        // }

        // Revoke all created object URLs
        all_files.forEach(file => URL.revokeObjectURL(file.local_url));
    };

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ files:", files)
    }, [files])

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ completed_files:", completed_files)
    }, [completed_files])


    useEffect(() => {
        // debugger
        if (voice_file) {
            uploadFiles(voice_file)
        }
    }, [])

    useEffect(() => {
        if (connect_files) {
            uploadFiles(connect_files)
            dispatch(set_connect_files(null))
        }
    }, [connect_files])

    const [edit_title, setEdit_title] = useState('')

    const handleEditToggle = (_f, index) => {
        setEdit_title(_f.file_name.split('.').slice(0, -1).join("."))
        setIsEditing(prev => prev === index ? null : index);
    };

    const handleEditReference = (index) => {
        setIsReference(isReference === index ? null : index);
    };

    const handleSave = (_f, index) => {
        if (edit_title !== '') {
            let ext = _f.file_name.split('.').pop();
            setFiles(files.map(v => v.sl === _f.sl ? { ...v, file_name: edit_title + '.' + ext } : v));
            set_completed_files(prev => prev.map(v => v.oldname === _f.file_name ? { ...v, voriginalName: edit_title + '.' + ext } : v));
            setIsEditing(null);
            setEdit_title('');
        }
    };

    const handleRefIDSave = (index) => {
        setSavedReferenceID(referenceID);
        setIsReference(false);
    };

    const handleCancel = () => {
        setEdit_title('');
        setIsEditing(null);
    };

    const handleClose = () => {
        // complted_files = [];
        set_completed_files([]);
        all_files = [];
        // if (otherProps.is_reply_msg === 'yes') {
        //     navigate(`/connect/${params.conversation_id}/reply/${params.msg_id}`)
        // } else {
        //     navigate(`/connect/${params.conversation_id}`)
        // }
        dispatch(setPopup({ key: 'upload_popup', data: false }))
    };

    function removeExtraBRTags(htmlString) {
        // Remove leading <br> tags
        var regexr = /<\/br>/;
        htmlString = htmlString.replace(regexr, '')
        // Remove trailing <br> tags
        htmlString = htmlString.replace(regexr, '')
        return htmlString.trimEnd();
    }

    function isBlank(str) {
        var newSting = str.replace(/(?:&nbsp;)/gm, '');
        return (!newSting || /^\s*$/.test(newSting));
    }

    const [send_msg_input] = useMutation(sendMsgMutation);

    const handleSendMsg = (data1, with_attachment = false) => {
        return new Promise(async (resolve, reject) => {
            try {
                // debugger;
                const content = removeExtraBRTags(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                let _isEmpty = isBlank(DOMPurify.sanitize(content.replace(/<[^>]*>?/gm, '')))
                var TrimText = content.replace(/[\r\n]/gm, '  </br>');
                var regex = /<\/br>$/;
                var TrimText1 = _isEmpty ? 'No Comments!' : TrimText.replace(regex, '');

                let imgfile = [];
                let audiofile = [];
                let videofile = [];
                let otherfile = [];
                let all_attachment = {};
                let allfiles = completed_files; //complted_files;

                for (let f of completed_files) { //complted_files
                    // console.log(97, f);
                    var mime = f.mimetype;
                    if (mime.indexOf('image') !== -1)
                        imgfile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('video') !== -1)
                        videofile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('audio') !== -1)
                        audiofile.push(f.bucket + '/' + f.key);
                    else
                        otherfile.push(f.bucket + '/' + f.key);
                }
                all_attachment = {
                    imgfile: imgfile,
                    audiofile: audiofile,
                    videofile: videofile,
                    otherfile: otherfile,
                    allfiles: allfiles.map(v => ({
                        originalname: v.originalname,
                        mimetype: v.mimetype,
                        voriginalName: v.voriginalName,
                        size: v.size,
                        bucket: v.bucket,
                        key: v.key,
                        acl: v.acl,
                    })),
                }
                // setLoader(true)
                let input = {
                    conversation_id: active_conv?.conversation_id,
                    company_id: user.company_id,
                    sender: user.id,
                    msg_type: `${with_attachment ? 'media_attachment' : 'text'} ${otherProps.task ? 'discussion' : ''}`,
                    msg_body: aesEncrypt(TrimText1),
                    participants: active_conv?.participants, //ThisRoom.room.participants,
                    is_reply_msg: otherProps.is_reply_msg,
                    referenceId: referenceID,
                    reference_type: referenceType,
                    reply_for_msgid: '',
                    ...data1
                }
                if (otherProps.is_reply_msg === 'yes') {
                    input['reply_for_msgid'] = params.msg_id
                }
                if (otherProps.task) {
                    input['task_id'] = otherProps.task._id
                }

                if (with_attachment) {
                    input = {
                        ...input,
                        attach_files: all_attachment,
                        all_attachment: allfiles.map(v => ({ tag_list: [], has_tag: "" }))
                    }
                }
                // console.log("🚀 ~ returnnewPromise ~ otherProps:", otherProps)
                if (otherProps.cost_id) {
                    input['file_group'] = 'cost'
                    input['cost_id'] = otherProps.cost_id
                }

                // console.log("🚀 ~ returnnewPromise ~ input:", input)
                let send_msg_data = await send_msg_input({
                    variables: {
                        input: input
                    }
                });
                console.log(243, send_msg_data)
                dispatch(set_raw_message_text(''))
                let dycrepetMsg = await modifyMyMsg([send_msg_data.data.send_msg.msg])
                if (!otherProps.task) {
                    dispatch(set_messages({ data: JSON.parse(JSON.stringify([...messages, ...dycrepetMsg])), is_reply_msg: otherProps.is_reply_msg }))
                }

                if (type === 'private_msg') {
                    dispatch(setPopup({ data: false, key: 'private_msg' }))
                } else if (otherProps.task) {
                    otherProps.setTaskFileUpload(false)
                } else {
                    // navigate(-1);
                    // if (otherProps.is_reply_msg === 'yes') {
                    //     navigate(`/connect/${params.conversation_id}/reply/${params.msg_id}`)
                    // } else {
                    //     navigate(`/connect/${params.conversation_id}`)
                    // }
                    handleClose()
                }
                setTimeout(() => {
                    scrollToBottom('main_msg_scroll_div')
                }, 300);
                resolve(true)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    const checkStatusUpload = (from) => {
        let data1 = {}
        let with_attachment = true;
        let statusType = selected_ids.length > 0 ? 'upload' : 'skip';

        switch (statusType) {
            case 'skip':
                switch (from) {
                    case 'file':
                        with_attachment = false;
                        break;
                    default:
                        break;
                }
                break;
            case 'upload':
                switch (from) {
                    case 'file':
                        with_attachment = true;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        switch (type) {
            case 'file_upload':
                data1 = {
                    is_secret: false,
                    tag_list: selected_ids,
                    ...(voiceSetType && { voice_set_type: voiceSetType }),
                    ...(voiceSetTitle && { msg_title: voiceSetTitle }),
                }
                break;
            case 'private_msg':
                data1 = { tag_list: selected_ids, secret_user: otherProps.secret_user, is_secret: true, msg_title: otherProps.form_data.title }
                break;
            default:
                break;
        }
        handleSendMsg(data1, with_attachment);
    }

    const handlePrivateFile = () => {
        if (completed_files.length === files.length && completed_files.length !== 0) { //complted_files
            setStep('tag')
        } else {
            checkStatusUpload('file')
        }
    }

    useEffect(() => () => {
        // complted_files = [];
        set_completed_files([])
        all_files = [];
        dispatch(set_raw_message_text(''))
        dispatch(setBooleanState({ key: 'triggerUpload', data: false }))
    }, [])

    useEffect(() => {
        setTimeout(() => {
            const content = raw_message_text.replace(/<\/?[^>]+(>|$)/g, '')
            // `<p>No Comments!</p>`
            let contentBlock = htmlToDraft((content.trim() === '') ? '' : raw_message_text);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(EditorState.moveFocusToEnd(editorState));
            }
        }, 50)
    }, [raw_message_text])

    useEffect(() => {
        if (triggerUpload) {
            if (completed_files.length === files.length && completed_files.length !== 0) {
                checkStatusUpload('file')
            }
        }
    }, [triggerUpload])

    // const [dragfiles, setDragFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        console.log(421, e.dataTransfer.files);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // setDragFiles((prevFiles) => [...prevFiles, ...e.dataTransfer.files]);
            uploadFiles(e.dataTransfer.files)
            e.dataTransfer.clearData();
        }
    };

    const removeFile = (_f) => {
        setFiles(prev => prev.filter(v => v.sl !== _f.sl))
        all_files = all_files.filter(v => v.sl !== _f.sl)
        // complted_files = complted_files.filter(v => v.oldname !== _f.file_name)
        set_completed_files(prev => prev.filter(v => v.sl !== _f.sl))
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => (prev >= 100 ? 100 : prev + 10));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const ref = useClickAway(() => {
        if (otherProps.task) {
            otherProps.setTaskFileUpload(false)
        }
    });

    const setRefId = (e) => {
        let str = e.target.value;

        if (str === '') {
            // Allow clearing the input
            setReferenceID(str);
            return;
        }

        // Remove HTML tags
        str = str.replace(/<\/?[^>]+(>|$)/g, '');

        // Ensure the first character is not a special character
        while (/^[^\w]/.test(str)) {
            str = str.slice(1);
        }

        // Remove two consecutive special characters
        str = str.replace(/([^\w\s])\1+/g, '$1');

        // Trim the input
        str = str.trim();

        setReferenceID(str);
    };
    //buttonClicked:
    const buttonClicked = (e) => {
        e.stopPropagation();
        // your code here...
    }

    // const isDisabled = !(files.length === 0 || (files.length && completed_files.length === files.length && completed_files.length !== 0));

    const [isLengthCheck, setIsLengthCheck] = useState(false);

    return (
        <>
            {/* <div ref={ref} onClick={() => dispatch(setPopup({ key: 'upload_popup', data: false }))} className={`${!otherProps.taskFileUpload ? 'fixed inset-0 bg-black bg-opacity-5 backdrop-blur-[1px] flex items-center justify-center !left-[346px] !lg:left-[250px] z-50' : ''}`}> */}
            <div onClick={(e) => buttonClicked(e)} className={`fileUploadBottomCon h-[450px] z-10 border-t border-t-[#e3f2ff]  ${otherProps.taskFileUpload ? '!z-[9999] !fixed !w-[550px] !right-0 !h-[450px]' : ''}`}>
                <div className='flex justify-between items-center p-5 !pb-0'>
                    <div ht className='flex gap-2 items-center cursor-pointer' onClick={() => file_ref.current.click()}>
                        <p className='text-base text-[#032e84]'> Upload a file(s) </p>
                        <MdAdd className='text-base text-[#032e84]' />
                    </div>
                    <p className='text-sm text-[#979797]'> Max. {maxFiles} files  |  Max 500MB </p>
                    <div className='uploadedBtns'>
                        {otherProps.taskFileUpload ? <button className='file_opt !pb-0 tooltip2 hover:bg-[#318fff] mr-2' onClick={() => otherProps.setTaskFileUpload(false)}>Cancel</button>
                            : type === "private_msg" ?
                                <button className='file_opt !pb-0 tooltip2 !bg-[#ff9988] !text-[#0C1E47] hover:text-white hover:bg-[#318fff] mr-2' onClick={() => otherProps.setStep(1)}>Cancel</button>
                                : <button className='file_opt !pb-0 tooltip2 !bg-[#ff9988] !text-[#0C1E47] hover:text-white hover:bg-[#318fff]  mr-2' onClick={handleClose}>Cancel</button>
                        }

                        {
                            completed_files.length === files.length && completed_files.length !== 0 &&
                            <button className='file_opt !pb-0 tooltip2 hover:!bg-[#318FFF]' onClick={() => checkStatusUpload('tag')}>Upload</button>
                        }
                        {/* {step === "upload" ?
                            type === 'private_msg' ?
                                <button onClick={handlePrivateFile} className={`file_opt !pb-0 tooltip2 hover:bg-[#318fff] mr-2 ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}>Continue</button>
                                :
                                <button onClick={() => setStep("tag")} className={`file_opt !pb-0 tooltip2 ${(completed_files.length === files.length && completed_files.length !== 0) ? 'hover:!bg-[#318FFF]' : 'pointer-events-none opacity-50'}`}>Next</button>
                            :
                            <button className='file_opt !pb-0 tooltip2 hover:!bg-[#318FFF]' onClick={() => checkStatusUpload('tag')}>Upload</button>
                        } */}
                    </div>
                </div>
                {
                    step === "upload" &&
                    <Fragment>
                        <div className={`px-5 ${files.length === 0 ? '' : 'hidden'} `} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                            <div className='border-[2px] border-dashed border-[#afdaff] rounded-lg mt-5'>
                                <div className='flex gap-2 justify-center items-center p-10 cursor-pointer' onClick={() => file_ref.current.click()}>
                                    <p className='text-[#739fc4]'>Upload from computer or drag & drop files</p>
                                    <input type="file" id="files" ref={file_ref} onChange={(e) => uploadFiles(e.target.files)} multiple hidden />
                                    <MdAddCircleOutline className='text-[#739fc4] text-2xl' />
                                </div>
                            </div>
                        </div>

                        {
                            files.length > 0 &&
                            <div className={`flex justify-between border border-[#d9d9d9] mt-4 ${isLengthCheck === true ? 'h-[250px]' : 'h-[382px]'}`}>
                                <div className={` ${isLengthCheck === true ? 'h-[calc(100%-10px)]' : 'h-[calc(100%-10px)]'}  overflow-auto mt-1 w-[50%]`}>
                                    <div >
                                        {files.map((_f, i) => (
                                            <div id={"file_container_" + _f.sl} key={i + 1} data-status={_f.status} className='mb-2 p-4 roundedd-lg fileUploadListCon relative bgg-[#f5f9fc] border-b-[2px] border-b-[#e3f2ff]'>
                                                <div className='flex justify-between w-full '>
                                                    <div className='flex gap-2 items-center w-[80%]'>
                                                        {_f.mimetype.indexOf('image') > -1 && _f.mimetype.indexOf('svg') === -1 ?
                                                            <img className={'object-cover h-10 rounded float-left'} fill={"true"} sizes='100%' src={_f.local_url} alt="Logo" title="Logo" />
                                                            :
                                                            <img className={'object-cover h-10 rounded float-left'} fill={"true"} sizes='100%' src={require(`../../../../public/media/images/light_theme/${fileExtension(_f.file_name)}.svg`)} title="Logo" alt="Logo" />
                                                        }
                                                        <div className='w-full'>
                                                            {/* <div className='w-full' style={{ width: `${_f.file_name.length + 45}%` }}> */}
                                                            {isEditing === i ? (
                                                                <div className='flex gap-2 items-center'>
                                                                    <input
                                                                        type="text"
                                                                        value={edit_title}
                                                                        onChange={(e) => setEdit_title(e.target.value.trimStart())}
                                                                        onKeyUp={(e) => e.key === 'Enter' && handleSave(_f, i)}
                                                                        className={`border rounded w-full text-xs text-[#0b1f47] h-[28px] px-1 ${edit_title === '' ? 'border-[#ff0000]' : 'border-[#318fff]'
                                                                            }`}
                                                                        autoFocus
                                                                    />
                                                                    <div className='flex gap-1'>
                                                                        <button onClick={handleCancel} className='border leading-[28px] rounded-[19px] px-[18px] text-[14px] border-[#e3f2ff] py-[1px]   bg-white text-[#0b1f47] hover:border-red-500 '>Cancel</button>
                                                                        <button
                                                                            onClick={() => handleSave(_f, i)}
                                                                            className={`text-white bg-[#0b1f47] rounded-[19px] px-[18px] text-[14px] cursor-pointer font-normal box-border leading-[28px] hover:bg-[#318fff] hover:text-white ${edit_title.trim() === _f.file_name.split('.').slice(0, -1).join(".") || edit_title === '' ? 'opacity-30 cursor-not-allowed' : ''}`}
                                                                            disabled={edit_title.trim() === _f.file_name.split('.').slice(0, -1).join(".") || edit_title === ''}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='text-xs text-[#0b1f47] max-w-[300px] text-left text-ellipsis whitespace-nowrap overflow-hidden'>{_f.file_name} </p>
                                                                    <div><span id="edit" className="editFileFileUploadNew" onClick={() => handleEditToggle(_f, i)}></span></div>
                                                                </div>
                                                            )}
                                                            {isReference === i ? (
                                                                <div className='flex gap-2 items-center refConInput mt-[5px]'>
                                                                    <input type="text" placeholder='Add a reference ID' value={referenceID} onChange={(event) => setRefId(event)}
                                                                        className='border placeholder-text-xs border-[#318fff] rounded w-[63%] text-xs text-[#0b1f47] px-1 h-[28px]'
                                                                        autoFocus={true}
                                                                    />
                                                                    <div className='flex gap-1'>
                                                                        <button onClick={() => setIsReference(false)} className='border leading-[28px] rounded-[19px] px-[18px] text-[14px] border-[#e3f2ff] py-[1px]   bg-white text-[#0b1f47] hover:border-red-500 '>Cancel</button>
                                                                        <button onClick={() => handleRefIDSave(i)} className={`text-white bg-[#0b1f47] rounded-[19px] px-[18px] text-[14px] cursor-pointer font-normal box-border leading-[28px] hover:bg-[#318fff] hover:text-white ${referenceID.trim() === "" || referenceID === '' ? 'opacity-30 cursor-not-allowed' : ''}`}
                                                                            disabled={referenceID.trim() === '' || referenceID === ''}
                                                                        >Save</button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='text-[#5b6477] text-xs'>{_f.file_size / 1000}Kb</p>
                                                                    <p onClick={() => handleEditReference(i)} className='text-[#318fff] underline text-xs cursor-pointer'>{savedReferenceID ? <span> Reference ID: {savedReferenceID} </span> : 'Add a reference ID'}</p>
                                                                </div>
                                                            )}
                                                            <div className='progressContainer gap-2 !justify-normal !mt-[2px]' id={`progressContainer_${progress}`}>
                                                                <div className="progressStatus !w-auto !text-xs">{progress}%</div>
                                                                <div className="progressBar !h-3">
                                                                    <span className="progressColor" style={{ width: `${progress}%` }}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-2 items-center upload_file_action'>
                                                        {/* <span id="edit" className="editFileFileUploadNew" onClick={() => handleEditToggle(_f, i)}></span> */}
                                                        <div className='relative'>
                                                            <GoTrash id="remove" onClick={() => removeFile(_f)} className='text-[#5b6477] text-[17px] -mt-[17px] cursor-pointer' />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='flex gap-2'>
                                            {file.fileTags && file.fileTags.map(tag => (
                                                <div key={tag.id} className='bg-[#CABAE4] rounded flex gap-1 mt-2 items-center px-2 py-1'>
                                                    <div className='border-[2px] border-[#8b57db] rounded-full w-3 h-3 bg-[#CABAE4]'></div>
                                                    <p className='text-xs text-[#0b1f47]'>{tag.tagsName}</p>
                                                </div>
                                            ))}
                                        </div> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='border-r border-r-[#d9d9d9]'></div>
                                <div className='w-[50%]'>
                                    <TagsPopupNew
                                        taskFileUpload={otherProps.taskFileUpload}
                                        type={type}
                                        viewType={'all'}
                                        tag_list={[]}
                                        onEditorStateChange={onEditorStateChange}
                                        editorState={editorState}
                                        upload_type={otherProps.upload_type ? otherProps.upload_type : ''}
                                        from={otherProps.from ? otherProps.from : ''}
                                        setIsLengthCheck={setIsLengthCheck}
                                        fileLength={files.length}
                                    />
                                </div>
                            </div>
                        }

                    </Fragment>
                }
                <Tooltip anchorSelect="#edit" content="Edit"></Tooltip>
                <Tooltip anchorSelect="#remove" content="Remove"></Tooltip>
            </div>
            {/* </div> */}
        </>
    );
};

export default FileUploadNew;