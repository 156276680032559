/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import { TbArrowBackUp } from 'react-icons/tb';
import { MdOutlineCancel } from 'react-icons/md';
import { useTaskContext } from '../TaskContext';
import sortArrayByKey from '../../../hooks/useSortedArray';

const ObserverFilter = ({ setObserverFilterPopup }) => {
    const { fetchByFilter, filter, taskMemberFilter, users, set_filter, setPagination, set_column_id } = useTaskContext()
    const [selected_users, set_selected_users] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [observer_list, set_observer_list] = useState([]);

    const handleChange = (v) => {
        set_selected_users(prev => prev?.includes(v.id) ? prev.filter(f => f !== v.id) : [...prev, v.id]);
    };

    useEffect(() => {
        set_observer_list(users?.filter(f => taskMemberFilter?.includes(f.id)).map(m => ({ ...m, name: m.firstname + ' ' + m.lastname })))
    }, [])

    const handleSelectAllChange = () => {
        set_selected_users(selected_users.length === observer_list.length ? [] : observer_list.map(m => m.id));
    };

    const view_filter = () => {
        let f_array = [...selected_users]; // get array of ids
        f_array.unshift('observer'); // modify f_array to add 'keywords' at the beginning
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(prev => [...prev, f_array]); // spread and update the task_filter
        fetchByFilter([...filter, f_array]);
        setObserverFilterPopup(false)
    }

    const filteredUsers = observer_list.filter(f => f.name.toLowerCase()?.includes(searchQuery.toLowerCase()));
    const sorted_data = sortArrayByKey(filteredUsers, 'name', 'asc');
    const ref = useClickAway(() => {
        setObserverFilterPopup(false);
    });

    return (
        <div ref={ref}>
            <div className='tagRelatedPopup forKeywords inRoomFilter !z-[9999] !w-[560px]'>
                <div className='tagToggle_sidebar'>
                    <div className="tagToggle_sidebar_head justify-between !py-6 !px-7">
                        <div className='flex items-center gap-2'>
                            <TbArrowBackUp className='text-xl text-[#318FFF] cursor-pointer' onClick={() => setObserverFilterPopup(false)} />
                            <div className="tagsText !text-[#2c56ac] !text-sm">Observer</div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className="searchareaDiv relative">
                                <input
                                    id='popupserachFilter'
                                    className="_inputBar searchLeft relative"
                                    type="text"
                                    placeholder="Search observer"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {searchQuery.length > 0 ?
                                    <MdOutlineCancel onClick={() => setSearchQuery("")} className=' cursor-pointer !right-2 absolute text-red-600 top-2 text-xl' /> : <div className="srcSectionPopup !right-2" data-for="top_head" data-tip="Search"></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="selectAllArea !py-4 !px-7">
                        <div className="checkboxes__item">
                            <label className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={selected_users.length === observer_list.length}
                                    onChange={handleSelectAllChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Select all</div>
                            </label>
                        </div>
                    </div>
                    <div className="allTagsArea">
                        <ul className="alltagList">
                            {sorted_data.map(v => (
                                <li key={v.id} className='!py-4 !px-7'>
                                    <div className="tagsRelateLists">
                                        <div className="checkboxes__item">
                                            <label className="checkbox style-c">
                                                <input
                                                    type="checkbox"
                                                    checked={selected_users.includes(v.id)}
                                                    onChange={() => handleChange(v)}
                                                />
                                                <div className="checkbox__checkmark"></div>
                                                <div className="checkbox__body">{v.name}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tagCoupleBtn !bottom-5">
                        <div className="skipButton" onClick={() => setObserverFilterPopup(false)}>Skip</div>
                        <div className="skipButton !w-[80px]" onClick={() => set_selected_users([])}>Reset</div>
                        <div className="viewFilesButton !w-[75px]" onClick={view_filter}>View</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObserverFilter;
