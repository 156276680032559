import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  function refreshPage() {
    window.location.reload(false);
  }
  

  return (
    <div className="error_page">
      <div id="error-page">
        <h1 className="oops">Oops!</h1>
        <p className="error_top">Sorry, an unexpected error has occurred.</p>
        <p className="error_bottom">
          <i>{error.statusText || error.message}</i>
        </p>
        <button className="error_reload" onClick={refreshPage}>Reload</button>
      </div>
    </div>
  );
}