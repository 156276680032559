/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {toast} from 'react-hot-toast';
import { set_share_link_popup } from '../../../redux/message';
import { FILE_SHARE_LINK_CREATE, FILE_SHARE_LINK_REMOVE } from '../../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';

const SharePopup = () => {
    const dispatch = useDispatch();
    const [loader] = useState(false)
    const [linkCreate, setLinkCreate] = useState(false);
    const [url, setUrl] = useState('');
    const linkCopyRef = useRef(null);
    const action_file = useSelector(state => state.message.action_file)
    let full_url = window.location.origin;

    useEffect(() => {
        if (action_file?.url_short_id && action_file?.url_short_id !== '') {
            setUrl(full_url + '/f/' + action_file?.url_short_id);
            setLinkCreate(true);
        }
    }, [])

    const copyLink = () => {
        linkCopyRef.current.select()
        document.execCommand("copy");
        toast.success('Copied to clipboard', { duration: 4000 });
        dispatch(set_share_link_popup(false))
        // navigator.clipboard.writeText(shareableLink);
    }
    // setLinkCreate(true)
    const [removeLink] = useMutation(FILE_SHARE_LINK_REMOVE)
    const removeShare = async () => {
        // try {
        //     setLoader(true)
        //     let APIres = await file_share_link_remove({
        //         file_id: popup_action_data?.id,
        //         participants: popup_action_data?.participants
        //     })
        //     console.log(36, APIres);
        //     setLoader(false);
        //     dispatch(set_share_link_popup(false))
        //     toast.success('Shared link has been removed from the file successfully!', { duration: 3000 });
        // } catch (error) {
        //     console.log(error);
        // }

        try {
            let res = await removeLink({
                variables: {
                    input: {
                        file_id: action_file?.id,
                        participants: action_file?.participants
                    }
                }
            });
            console.log("🚀 ~ removeShare ~ res:", res);
           
            toast.success('Shared link has been removed from the file successfully.', {
                style: {
                  fontSize: '14px',
                  maxWidth: '520px'
                },
                duration: 3000
              });
            dispatch(set_share_link_popup(false))
        } catch (error) {
            console.log("🚀 ~ removeShare ~ error:", error)
        }
    }
    const [createLink] = useMutation(FILE_SHARE_LINK_CREATE)
    const CreateNewLink = async () => {
        try {
            let res = await createLink({
                variables: {
                    input: {
                        file_id: action_file.id,
                        participants: action_file.participants
                    }
                }
            });

            console.log("🚀 ~ CreateNewLink ~ res:", res)
            setUrl(full_url + '/f/' + res.data.file_share_link_create);
            setLinkCreate(true);
        } catch (error) {
            console.log(error)
        }

        // try {
        //     setLoader(true)
        //     let APIres = await file_share_link_create({
        //         file_id: popup_action_data?.id,
        //         participants: popup_action_data?.participants
        //     })
        //     console.log(51, APIres);
        //     setUrl(window.location.hostname + '/f/' + APIres.url_short_id);
        //     setLoader(false);
        //     setLinkCreate(true);
        // } catch (error) {
        //     console.log(error)
        // }
    }

    const close_popup = () => {
        dispatch(set_share_link_popup(false))
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                close_popup();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [close_popup]);

    return (
        <div className='backwrap'>
           
            <div className="shareLinkModal">
                <div className="shareLinkModalHead">
                    <h4 className="popupTitle">Share</h4>
                    <span onClick={close_popup} className="closeModal"></span>
                </div>

                <div className="shareLinkModalBody">
                    <p className="shareDesc">Create a shareable url/link and simply paste the link in any channel to share the file with anyone. You can always disable shareable link anytime.</p>
                    {linkCreate &&
                        <>
                            <input className="generateUrlInput" type="text" ref={linkCopyRef} value={url} readOnly />
                            <div className="copyIcon">
                                <img onClick={copyLink} data-tip="Click to copy link" className="copyShareLink" src={'/media/images/copyIconBlack.svg'} alt="copy" title='copy' />
                            </div>
                        </>
                    }
                </div>
                {/* {!linkCreate ?
								loader ? <button className="btnAction btn_loader" style={{color:'transparent'}}>Create link</button>:<button className="btnAction" onClick={()=> CreateNewLink() }>Create link</button>
								:
								<button className="btnAction" onClick={copyLink}>Copy</button>
							} */}
                <div className="shareLinkModalFoot">
                    {linkCreate ?
                        <>
                        <button className={'btnAction'} onClick={removeShare} style={loader ? { backgroundColor: '#ff0000', pointerEvents: 'none' } : { backgroundColor: '#ff0000' }} >{loader ? 'Loading...' : 'Remove Link'}</button>
                        <button className="btnAction" onClick={copyLink}>Copy</button>
                        </>
                        :
                        <button className={'btnAction'} onClick={CreateNewLink} style={loader ? { pointerEvents: 'none' } : {}} >{loader ? 'Loading...' : 'Create Link'}</button>
                    }
                </div>
            </div>
        </div>
    );
};

export default SharePopup;