import React, { useState } from 'react';
import { MdOutlineCancel, MdClose } from 'react-icons/md';
import ClickAwayListener from 'react-click-away-listener';

const AdvanceSearchProgress = ({ selectedProgress, setSelectedProgress }) => {
    const [progressOptionsVisible, setProgressOptionsVisible] = useState(false);
    const [showMoreProgress, setShowMoreProgress] = useState(false);

    const toggleProgressOptions = () => {
        setProgressOptionsVisible(prev => !prev);
    };

    const handleProgressOptionClick = (value) => {
        if (!selectedProgress?.includes(value)) {
            setSelectedProgress(prev => [...prev, value]);
        }
        setProgressOptionsVisible(false);
    };

    const handleRemoveProgress = (progress) => {
        const updatedProgress = selectedProgress?.filter(item => item !== progress);
        setSelectedProgress(updatedProgress);
        if (updatedProgress?.length === 0) {
            setShowMoreProgress(false); // Close the expanded view if no progresses are left
        }
    };

    const handleShowMoreProgress = () => {
        setShowMoreProgress(prev => !prev);
    };

    // Determine which progresses to show
    const progressToShow = selectedProgress?.slice(0, 2);
    const remainingCount = selectedProgress?.length - 2;

    const handleClickAway = () => {
        setProgressOptionsVisible(false);
    };

    return (
        <div className="col4 relative !w-[24%] !ml-0">
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className='custom_dropdown_task'>
                    <button
                        className={`custom_dropdown-header !w-full ${progressOptionsVisible ? 'selected-green' : ''}`}
                        onClick={toggleProgressOptions}
                    >
                        Progress <i className={`fa fa-chevron-right custom_icon ${progressOptionsVisible ? 'open' : 'false'}`}></i>
                    </button>
                    {progressOptionsVisible && (
                        <div className="custom_dropdown-body-task-open">
                            <div
                                className="custom_dropdown-item not-defined-gray"
                                onClick={() => handleProgressOptionClick(0)}
                            >
                                Not Defined
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleProgressOptionClick(25)}
                            >
                                Stage 1
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleProgressOptionClick(50)}
                            >
                                Stage 2
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleProgressOptionClick(75)}
                            >
                                Stage 3
                            </div>
                            <div
                                className="custom_dropdown-item"
                                onClick={() => handleProgressOptionClick(100)}
                            >
                                Final Stage
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>

            <div className='flex flex-wrap gap-1 mt-1'>
                {progressToShow.map(v => (
                    <div
                        key={v}
                        className='flex items-center gap-1 pl-2 pr-1 bg-[#318fff] text-white rounded text-sm'
                    >
                        <div>{v === 0 ? 'Not Defined' : v === 25 ? 'Stage 1' : v === 50 ? 'Stage 2' : v === 75 ? 'Stage 3' : 'Final Stage'}</div>
                        <div onClick={() => handleRemoveProgress(v)}><MdOutlineCancel /></div>
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div
                        onClick={handleShowMoreProgress}
                        className='flex items-center gap-1 pl-2 pr-2 bg-[#318fff] text-white rounded text-sm cursor-pointer'
                    >
                        <div>+{remainingCount}</div>
                    </div>
                )}
            </div>

            {showMoreProgress && (
                <div className='room_list_popup statussPopup'>
                    <span
                        className='absolute top-0 right-0 text-lg'
                        onClick={handleShowMoreProgress}
                    >
                        <MdClose className='cursor-pointer text-[#318FFF]' />
                    </span>
                    {selectedProgress.map(v => (
                        <div key={v} className='flex items-center gap-1 pl-2 pr-1 bg-[#318fff] text-white rounded text-sm'>
                            <div>{v === 0 ? 'Not Defined' : v === 25 ? 'Stage 1' : v === 50 ? 'Stage 2' : v === 75 ? 'Stage 3' : 'Final Stage'}</div>
                            <div onClick={() => handleRemoveProgress(v)}><MdOutlineCancel /></div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AdvanceSearchProgress;