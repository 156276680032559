import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import 'moment-timezone';

function SupportAndBillingReview(props) {
    return (
        <>
         
                <div className="supportAndBillingContainer !p-0 h-[calc(100%-95px)]">
                    {props.billingData.payment.all_pay.length ?
                        <div className="usersTableContainer  !pl-[40px] !pr-[40]">
                            <div className="userTableHead">
                                <ul className="">
                                    <li className="_userAction" style={{ width: '15%' }}>Date</li>
                                    <li className='_userName' style={{ width: '10%' }} >Plan Name</li>
                                    <li className='_userEmail' style={{ width: '10%' }} >User limit</li>
                                    <li className="_userAction" style={{ width: '15%' }}>Storage limit</li>
                                    <li className='_userName' style={{ width: '20%' }} >Card Holder Name </li>
                                    <li className='_userName' style={{ width: '20%' }} >Card Number</li>
                                    <li className="_userAction" style={{ width: '5%' }}>Amount</li>
                                </ul>
                            </div>
                            <div className="userTableBody">
                            <Scrollbars autoHide className="scrollBarClass">
                                {
                                    props.billingData.payment.all_pay.map((pay) =>
                                        <ul key={pay.id} className="_userList " >
                                            <li className="_userName" style={{ width: '15%' }}>{moment(pay.transection_date).format('MMM Do, YYYY')}</li>
                                            <li className="_planName" style={{ width: '10%' }}>{pay.plan_name}</li>
                                            <li className="_userEmail" style={{ width: '10%' }}>{pay.plan_user_limit} Users</li>
                                            <li className="_userAction" style={{ width: '15%' }}>
                                                <span className="_userNameTxt">{pay.plan_storage_limit} GB</span>
                                            </li>
                                            <li className="_cardHolderName" style={{ width: '20%' }}>{pay.card_holder_name}</li>
                                            <li className="_userName" style={{ width: '20%' }}>{'....' + pay.card_no}</li>
                                            <li className="_userAction" style={{ width: '5%', position: 'relative' }}>
                                                ${pay.amount}
                                            </li>
                                        </ul>
                                    )
                                }
                                </Scrollbars>
                            </div>
                        </div>
                        :
                        <h2 className="_userList" style={{ textAlign: "center" }} >
                            No Subscription History Found!

                        </h2>
                    }

                </div>
            
        </>
    )
}





export default SupportAndBillingReview;