/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './FileHub.css'
import FileHubHead from './fileHubHead';
import Tags from './Tags';
import Files from './Files';
import Links from './Links';
import { useParams } from 'react-router-dom';
import { TagPopup } from '../GlobalPopups/TagPopup';
import { useSelector } from 'react-redux';
import { UserSelection } from '../GlobalPopups/UserSelection';
import SharePopup from '../Connect/Messages/SharePopup';
import CustomTitle from '../Connect/CustomTitle';
import TagRelatedPopup from './TagRelatedPopup';
import { useDispatch } from 'react-redux';
import { set_view_tag_files } from '../../redux/message';
import TagsTabHome from './TagsTabHome';
import { Toaster } from 'react-hot-toast';

const FileHub = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const _redux = useSelector(state => state.message)
    const [miniForwardList, setMiniForwardList] = useState([])
    // console.log("🚀 ~ FileHub ~ params:", params)
    const [tab_state, setTab_state] = useState(params.file_hub_tab ? params.file_hub_tab : 'tags')

    const [relatedTag, setrelatedTag] = useState([]);

    useEffect(() => {
        if (params.file_hub_tab) {
            setTab_state(params.file_hub_tab)
        }
    }, [params])

    useEffect(() => {
        return () => {
            dispatch(set_view_tag_files({ state: false, tag_ids: [] }))
        }
    }, [])


    return (
        <div className="right_container_fileHub">
             <Toaster />
            <FileHubHead />
            {tab_state === 'tags' && <TagsTabHome relatedTag={relatedTag} setrelatedTag={setrelatedTag} />}
            {/* {tab_state === 'tags' && <Tags />} */}
            {tab_state === 'files' && <Files type={'files'} />}
            {tab_state === 'links' && <Links />}

            {/* {_redux.related_tag_popup && <TagRelatedPopup relatedTag={relatedTag} setrelatedTag={setrelatedTag} />} */}
            {_redux.forward_msg_popup && <UserSelection type="forward_msg" setMiniList={setMiniForwardList} miniList={miniForwardList} />}
            {_redux.share_link_popup && <SharePopup />}
            {_redux.custom_title_popup && <CustomTitle />}
            {_redux.tag_popup && <TagPopup type='file' viewType={'all'} tag_list={[]} />}
        </div>
    );
};

export default FileHub;