/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import FileUploadComment from '../FileUpload/FileUploadComment';
import { MdAdd, MdClose, MdOutlineCancel } from 'react-icons/md';
import CreateTags from './CreateTags';
import { useQuery } from '@apollo/client';
import { GET_TAGS } from '../../../Utils/GraphqlQueries';
import { useSelector } from 'react-redux';
import { UploadContext } from '../../../context/UploadContext';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
import { IoSearchOutline } from "react-icons/io5";
const TagsPopupNew = ({ type, viewType, tag_list, fileLength, setIsLengthCheck, setTag_popup, upload_type = '', ...otherProps }) => {
    const { selected_ids, setSelected_ids } = useContext(UploadContext)
    const { user, active_conversation } = useSelector(state => state.message)
    const [tab_state, setTab_state] = useState('public');
    const [src_string, setSrc_string] = useState('');
    const [task_tag, set_task_tag] = useState([]);
    const [all_tags, setAll_tags] = useState([]);
    const [changesMade, setChangesMade] = useState(false);
    const [loaderTagsShow, setloaderTagsShow] = useState(true);


    useQuery(GET_TAGS, {
        variables: { company_id: user.company_id, upload_type: upload_type },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res) {
                let tags = [...res.tags.private, ...res.tags.public];
                let taskTag = (otherProps.from && otherProps.from === '#TASK')
                    ? res.tags.task_tag.filter(f => f.title === otherProps.from)
                    : res.tags.task_tag;

                setAll_tags(tags);
                set_task_tag(taskTag);

                if (tag_list.length > 0) {
                    setSelected_ids(tag_list);
                }

                if (type === 'file' || type === 'file_upload') {
                    // let old_tag = action_file?.tag_list || [];
                    let conv_tags = active_conversation.tag_list || [];
                    let taskTagIds = taskTag.map(m => m.tag_id);
                    setSelected_ids([...taskTagIds, ...conv_tags]);
                }
                setloaderTagsShow(false);
            }
        }
    });


    const removeFromMini = (item) => {
        setSelected_ids(selected_ids.filter(f => f !== item.tag_id))
        setChangesMade(true);
    }

    const select_tag = (tag) => {
        if (selected_ids.includes(tag.tag_id)) {
            setSelected_ids(selected_ids.filter(f => f !== tag.tag_id))
        } else {
            setSelected_ids([...selected_ids, tag.tag_id])
        }
        setChangesMade(true);
    }

    useEffect(() => {
        setIsLengthCheck(selected_ids.length > 0 ? true : false);
    }, [selected_ids])


    const minidiv = all_tags.filter(f => selected_ids?.includes(f.tag_id)).map((item, i) =>
        <div key={'_mini_' + i} style={{ backgroundColor: item.tag_color }} className='rounded flex gap-2 items-center px-3 py-2'>
            {/* <div className='rounded-full border-2 border-white w-3 h-3'></div> */}
            <p className='text-xs text-white'>{item.title}</p>
            {(item.title !== '#TASK-COST' && item.title !== '#TASK') && <MdOutlineCancel onClick={() => removeFromMini(item)} className='text-white cursor-pointer' />}
        </div>
    )
    // const minidiv = all_tags.filter(f => selected_ids?.includes(f.tag_id)).map((item, i) =>
    //     <div key={'_mini_' + i} style={{ backgroundColor: item.tag_color }} className='rounded flex gap-1 items-center px-2 py-1'>
    //         <div className='rounded-full border-2 border-white w-3 h-3'></div>
    //         <p className='text-xs text-white'>{item.title}</p>
    //         {(item.title !== '#TASK-COST' && item.title !== '#TASK') && <MdClose onClick={() => removeFromMini(item)} className='text-white cursor-pointer' />}
    //     </div>
    // )

    const [createTagsPopup, setCreateTagsPopup] = useState('')
    const [selectedTags, setSelectedTags] = useState([]);

    const removeTag = (id) => {
        setSelectedTags(selectedTags.filter(tagId => tagId !== id));
    };
    const handleInputChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setSrc_string(value);
            return;
        }

        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setSrc_string(trimmedValue);

    };

    return (
        <div className={`px-5 ${otherProps.taskFileUpload ? "flex-col h-[calc(100%-30px)] overflow-auto !gap-0" : ""}`}>
            <div className={`${otherProps.taskFileUpload ? "w-[100%]" : "w-[100%]"}`}>

                {
                    createTagsPopup === "shared" || createTagsPopup === "personal" ? "" : <p className='mt-4 mb-2 text-[#0b1f47]'>Assign tag(s) to the file</p>
                }

                {/* <div className='flex justify-between bg-[#f7f8f9]'>
                    <div onClick={() => setTab_state("public")} className={`flex w-[50%] p-2.5 cursor-pointer justify-center text-center ${tab_state === "public" && 'border-b-4 border-[#032e84]'}`}>
                        <p className='text-[#0b1f47] text-center'>Shared team tag(s)</p>
                    </div>
                    <div onClick={() => setTab_state("private")} className={`flex w-[50%] p-2.5 cursor-pointer justify-center text-center ${tab_state === "private" && 'border-b-4 border-[#032e84]'}`}>
                        <p className='text-[#0b1f47] text-center'>Personal tag(s)</p>
                    </div>
                </div> */}
                {/* {activeTab === "shared" ?
                    <SharedTeamTags setCreateTagsPopup={setCreateTagsPopup} all_tags={all_tags} /> : <PersonalTags setCreateTagsPopup={setCreateTagsPopup} />
                } */}
                <div className=''>
                    <div className={` ${selected_ids.length > 0 ? "h-[128px] maxx-h-[80px] absolute left-0 px-5 py-3 !pt-3.5 bottom-0 w-full bg-white overflow-auto" : "h-auto"}`}>

                        {selected_ids.length > 0 &&
                            <div>
                                <p className='mb-2 text-[#0b1f47] -mt-1'>Assigned tag(s)</p>
                                <div className='flex flex-wrap gap-1.5'>
                                    {minidiv}
                                </div>
                            </div>
                        }
                    </div>
                    {/* {selected_ids.length > 0 &&
                        <hr className='border border-[#afdaff]' />
                    } */}
                    {
                        createTagsPopup === "shared" || createTagsPopup === "personal" ?
                            <CreateTags createTagsPopup={createTagsPopup} setCreateTagsPopup={setCreateTagsPopup} select_tag={select_tag} srcText={src_string} setAll_tags={setAll_tags} setSrc_string={setSrc_string} /> :
                            <>
                                <div className='flex gap-3 items-center'>
                                    <div className='bg-[#f7f8f9] rounded relative w-[50%]'>
                                        <input className='px-3 py-2 w-full bg-[#f7f8f9] border text-[#0b1f47] rounded' type="text" placeholder='Search tag' autoFocus={true} value={src_string} onChange={handleInputChange} />
                                        {src_string.length > 0 ?
                                            <MdClose onClick={() => { setSrc_string(''); setCreateTagsPopup('') }} className='text-red-600 absolute right-3 top-2 text-2xl cursor-pointer' />
                                            : <IoSearchOutline className='text-[#858fa3] absolute right-3 top-2 text-2xl cursor-pointer' />
                                        }
                                    </div>
                                    {/* {otherProps.taskFileUpload && */}
                                    <>
                                        {(all_tags.filter(f => f.title.toLowerCase().trim().includes(src_string.toLowerCase().trim()) && f.tag_type === tab_state).length === 0 && active_conversation.participants_admin?.includes(user.id)) &&
                                            <div onClick={() => setCreateTagsPopup("shared")} className={`px-4 py-2 cursor-pointer rounded-full bg-[#E3F2FF] flex items-center gap-1 text-[#0b1f47] hover:bg-[#0b1f47] hover:text-white ${createTagsPopup === "shared" || createTagsPopup === "personal" ? 'tagOpecity' : ''}`}>
                                                <p>Create tags</p>
                                                <MdAdd className='' />
                                            </div>
                                        }
                                    </>
                                    {/* } */}
                                </div>
                                <div className={`tagsAllArea my-4 ${selected_ids.length > 0 ? "h-[140px]" : "h-[270px]"} overflow-auto`}>
                                    {loaderTagsShow ? (
                                        <div className="btn_loader leftbar_loader !h-[300px]"></div>
                                    ) : (
                                        (() => {
                                            const filteredTags = all_tags
                                                .filter(f => !["#TASK-COST", "#TASK", "UNTAGGED FILES"].includes(f.title))
                                                .filter(f =>
                                                    f.title.toLowerCase().trim().includes(src_string.toLowerCase().trim()) &&
                                                    f.tag_type === tab_state &&
                                                    !selected_ids.includes(f.tag_id)
                                                );

                                            return filteredTags.length > 0 ? (
                                                filteredTags.map((v, i) => (
                                                    <div
                                                        key={`_tags_${i}`}
                                                        onClick={() => select_tag(v)}
                                                        className="cursor-pointer rounded bg-[#f9f9f9] flex gap-1 items-center px-2 py-2 mb-2 mr-4 hover:bg-[#d9e2e8]"
                                                    >
                                                        <div style={{ backgroundColor: v.tag_color }} className="rounded-full border-2 w-4 h-4"></div>
                                                        <p className="text-[14px] text-[#0b1f47]">{v.title}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="flex flex-col -mt-3 items-center justify-center h-full text-gray-500">
                                                    <p>No tags found</p>
                                                    <p className="text-sm">Please create new tags</p>
                                                </div>
                                            );
                                        })()
                                    )}
                                </div>
                            </>
                    }
                </div>
            </div>
            {/* <FileUploadComment taskFileUpload={otherProps.taskFileUpload} onEditorStateChange={otherProps.onEditorStateChange} editorState={otherProps.editorState} /> */}
        </div>
    );
};

export default TagsPopupNew;