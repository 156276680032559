import React, { useState } from 'react';
const ForwardMessageTo = (props) => {
    const [message, setMessage] = useState('');
    return (
        <>

            <div className="backwrap" style={{ display: 'flex' }}>
                <div className="participantsModal">
                    <div className="participantsModalHead">
                        <h4 className="popupTitle"> Forward message to : </h4>
                        <span className="closeModal" onClick={props.close}></span>
                    </div>
                    <div className='participantsModalBody !pl-[30px]'>
                        <div className="selectedParticipants">
                            {props.minidiv}
                            <div className="edit_action_U" onClick={() => props.setforward_extra(false)} >Add/Edit</div>
                        </div>

                        <div className=''>
                            <label className='write_msg_label'>Write a message/comment</label>
                            <div className="secretUserList"></div>
                            <div>
                                <textarea onChange={(e) => setMessage(e.target.value)} className={'file_comment_box !pl-[10px]'} name='msg' placeholder='Write a message' />
                            </div>
                        </div>
                        <div className="customTitleFoot">
                            <button className="btnCancel" style={{ marginTop: '0px' }} onClick={props.close}>Cancel</button>
                            <button className={`saveTitle active ${props.next ? '' : 'pointer-events-none opacity-[0.6]'}`} onClick={() => props.forwardAmessageMethod(message)}>Forward</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ForwardMessageTo;