import React, { useState, useEffect, useRef } from "react";
import "moment-timezone";
import { IoSearchOutline } from "react-icons/io5";
import { MdCancel, MdPushPin } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa6";
import TagsFileList from "./TagsFileList";
// import RelatedTagsList from "./RelatedTagsList";
import { useClickAway } from "@uidotdev/usehooks";
import { HUB_ALL_FILES } from "../../Utils/GraphqlQueries";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import classNames from "classnames";
import {
  set_related_tag_popup,
  set_selected_tag,
  set_view_tag_files,
} from "../../redux/message";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GET_ME, OTHERS_TAG_Details } from "../../Utils/GraphqlQueries";
import { FAVOURITE_UNFAVOURITE } from "../../Utils/GraphqlMutaion";
import TagRelatedPopup from "./TagRelatedPopup";

const TagsTabHome = ({ relatedTag, setrelatedTag }) => {
  const client = useApolloClient();
  const cacheMe = client.readQuery({ query: GET_ME });
  const params = useParams();
  const dispatch = useDispatch();
  const _redux = useSelector((state) => state.message);
  const [fav_unfav] = useMutation(FAVOURITE_UNFAVOURITE);
  const view_tag_files = useSelector((state) => state.message.view_tag_files);
  // const [selectedRooms, setSelectedRooms] = useState(params.conversation_id ? [params.conversation_id] : []);
  const [get_files_api, { loading: loading } ] = useLazyQuery(HUB_ALL_FILES, { fetchPolicy: "no-cache" });
  const [searchStr, setSearchStr] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  // const [loading, setLoading] = useState(false);
  const [loaderTags, setLoaderTags] = useState(false);
  // const [relatedTags, setRelatedTags] = useState([]);
  // const [showRelatedTags, setShowRelatedTags] = useState(false);
  const [filterTagsOptions, setFilterTagsOptions] = useState(false);
  const [allFiles, setallFiles] = useState([]);
  // console.log("allFiles", allFiles);
  const [selectedFilters, setSelectedFilters] = useState("title_- Ascending");
  const [selectedTagFilters, setSelectedTagFilters] =
    useState("Tags - Ascending");
  const [pagination, setPagination] = useState([]); 
  // console.log('pagination:',pagination);
  const [page, setPage] = useState(1); 
  const containerRef = useRef();
  const [pinnedTagArr, setPinnedTagArr] = useState("");
  const selected_tag = useSelector((state) => state.message.selected_tag);
  // console.log("selected_tag", selected_tag);
  const [searchFileName, setSearchFileName] = useState("");
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    const [prevloader, setPrevLoader] = useState(false);

  // const { loading: loading_res, error: tag_error, data: tag_res } = useQuery(OTHERS_TAG_Details, {
  //     variables: {
  //         tag_id: selected_tag?.tag_id,
  //         conversation_id: params.conversation_id ? params.conversation_id : ''
  //     }, fetchPolicy: 'no-cache'
  // });

  const [get_related_tag] = useLazyQuery(OTHERS_TAG_Details, {
    fetchPolicy: "no-cache",
  });
  // const [loading, setLoading] = useState(true);
  // const [relatedTag, setrelatedTag] = useState([]);
  // console.log('relatedTag:file',relatedTag);
  // useEffect(() => {
  //     // debugger
  //     if (tag_res) {
  //         let allTag_ids = [];
  //         let allTags = [];
  //         for (let t of tag_res.others_tag_details) {
  //             if (allTag_ids.indexOf(t.tag_id) === -1 && t.tag_id !== selected_tag?.tag_id) {
  //                 allTag_ids.push(t.tag_id);
  //                 allTags.push({ ...t, isChecked: t.isChecked || false });
  //             }
  //         }
  //         setrelatedTag(allTags)
  //         // setLoading(false)

  //     }
  //     // console.log('tag_res:', tag_res)

  // }, [tag_res])

  //   const [summary, setSummary] = useState({ total: 0, image: 0, audio: 0, video: 0, other: 0, voice: 0, share: 0, conversation: [], tags: [] });
  const tagFilters = [
    "Tags - Most recent",
    "Tags - Ascending",
    "Tags - Descending",
  ];

  const ref = useClickAway(() => {
    setFilterTagsOptions(false);
  });

  const prevScrollHeight = useRef(0);
  
  // const onScrollLinks = (e) => {
  //   const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
  //   // console.log('scrollTop:', scrollTop, 'clientHeight:', clientHeight, 'scrollHeight:', scrollHeight);
  //   if (scrollTop + clientHeight >= scrollHeight - 10) {
  //     debugger;
  //     if (!loading) {
  //       if (pagination.totalPages > pagination.page) {
  //         console.log('pagination',pagination);
  //         let container = containerRef.current;
  //         prevScrollHeight.current = container.scrollHeight;
  //         get_files("scroll", selectedTag?.tag_id || "", page + 1);
  //         setPage((prevPage) => prevPage + 1); // Increment page for pagination
  //       }
  //     }
  //   }
  // };

  const onScrollLinks = ({ scrollDirection, scrollOffset }) => {
    if (scrollDirection === "forward") {
      // Calculate the total height of the list based on the new item size (50px)
      const listHeight = 50 * allFiles.length; // total height of the list, each item is 50px
      // Use container's height instead of window height for threshold calculation
      const threshold = listHeight - (containerRef.current ? containerRef.current.clientHeight : 0) - 50; // Adjust buffer (100px)
      // Check if we are near the bottom (or past the threshold)
      if (scrollOffset >= threshold && !loading && pagination.totalPages > pagination.page) {
        get_files("scroll", selectedTag?.tag_id || "", page + 1);
        
      }
    }
  };

  const change_file_data = useSelector(
    (state) => state.message.change_file_data
  );

  useEffect(() => {
    // debugger;
    if (change_file_data) {
      // console.log("change_file_data:", change_file_data);
      if (change_file_data.xmpp_type === "star_file") {
        setallFiles(
          allFiles.map((f) =>
            f.id === change_file_data.file_id
              ? { ...f, star: change_file_data.star }
              : f
          )
        );
      } 
      else if (
        change_file_data.xmpp_type === "delete_msg" ||
        change_file_data.xmpp_type === "delete_one_file"
      ) {
        setallFiles(allFiles.filter((f) => f.id !== change_file_data.file_id));

        let tag_list = change_file_data.tag_list || [];

      // Update tag counts
      setTags((prevTags) =>
        prevTags.map((tag) => {
          if (tag.title === "UNTAGGED FILES" && tag_list.length === 0) {
            return { ...tag, use_count: tag.use_count - 1 };
          } 
          else if (tag_list.includes(tag.tag_id)) {
            return { ...tag, use_count: tag.use_count - 1 };
          }
          return tag;
        })
      );
      } 
      else if (change_file_data.xmpp_type === "add_remove_tag_into_msg") {
        let previous_tag_list = change_file_data.previous_tag_list || [];
        let updated_tag_list = change_file_data.tag_list || [];
        let attachment = allFiles.map((f) =>
          f.id === change_file_data.file_id ||
          change_file_data.file_id === change_file_data.conversation_id
            ? {
                ...f,
                tag_list: f.tag_list
                  ? [
                      ...f.tag_list.filter(
                        (t) => change_file_data.removetag.indexOf(t) === -1
                      ),
                      ...change_file_data.newtag.filter(
                        (t) => f.tag_list.indexOf(t) === -1
                      ),
                    ]
                  : [...change_file_data.newtag],
                tag_list_details:
                  f.tag_list_details && f.tag_list
                    ? [
                        ...f.tag_list_details.filter(
                          (t) =>
                            change_file_data.removetag.indexOf(t.tag_id) === -1
                        ),
                        ...change_file_data.newtag_tag_data.filter(
                          (t) => f.tag_list.indexOf(t.tag_id) === -1
                        ),
                      ]
                    : [...change_file_data.newtag_tag_data],
              }
            : f
        );
        if (selected_tag?.title === "UNTAGGED FILES") {
          attachment = attachment.filter(
            (f) => f.id !== change_file_data.file_id
          );
        } else {
          attachment = attachment.filter((f) => {
            if (f.id === change_file_data.file_id) {
              // debugger;
              if (
                f.tag_list_details?.filter(
                  (f) =>
                    (f.tag_type === "public" ||
                      f.tag_type === "task" ||
                      (f.tag_type === "private" &&
                        f.tagged_by === cacheMe.me.id)) &&
                    f.title !== "UNTAGGED FILES"
                )?.length === 0
              ) {
                return false;
              } else if (
                change_file_data.removetag?.length &&
                change_file_data.removetag.includes(selected_tag?.tag_id)
              ) {
                return false;
              } else return true;
            } else return true;
          });
        }
        setallFiles(attachment);

        setTags((prevTags) => [
          ...prevTags.map((tag) => {
            if (tag.title === "UNTAGGED FILES") {
              if (previous_tag_list.length === 0 && updated_tag_list.length > 0) {
                // If the file was previously untagged but now has tags, decrement
                return { ...tag, use_count: tag.use_count - 1 };
              } else if (previous_tag_list.length > 0 && updated_tag_list.length === 0) {
                // If the file had tags but now has none, increment
                return { ...tag, use_count: tag.use_count + 1 };
              }
            }
        
            if (change_file_data.removetag?.includes(tag.tag_id)) {
              return { ...tag, use_count: tag.use_count - 1 };
            }
        
            if (change_file_data.newtag?.includes(tag.tag_id)) {
              return { ...tag, use_count: tag.use_count + 1 };
            }
        
            return tag;
          }),
        
          // Add new tags from `change_file_data.newtag_tag_data` if they don’t already exist in `prevTags`
          ...change_file_data.newtag_tag_data.filter(newTag => 
            !prevTags.some(existingTag => existingTag.tag_id === newTag.tag_id)
          ).map(newTag => ({ ...newTag, use_count: 1 })),
        ]);
      } 
      else if (change_file_data.xmpp_type === "file_share_link_create") {
        setallFiles(allFiles.map((f) =>
            f.id === change_file_data.id
              ? { ...f, url_short_id: change_file_data.url_short_id }
              : f
          )
        );
      }
      // setTimeout(()=>{
      //   get_tags("reload");

      // },500)
      
    }
  }, [change_file_data]);

  async function get_tags(type = "init") {
    if (type === "init") setLoaderTags(true); // Start loading when a tag is selected

    let json = {
      conversation_id: params.conversation_id || "",
      file_type: "all",
      file_sub_type: "",
      tag_id: "all",
      tab: "tag",
    };
    let res = await get_files_api({ variables: json });

    if (res?.data?.get_file_gallery?.tags) {
      // console.log("get_tags",res?.data?.get_file_gallery?.tags)
      let tags = res?.data.get_file_gallery.tags?.sort((a, b) => {
        // Ensure "UNTAGGED FILES" always appears on top
        if (a.title === "UNTAGGED FILES") return -1;
        if (b.title === "UNTAGGED FILES") return 1;
        return 0; // Maintain existing order for other tags
      });
      setTags(tags);
      if (type === "init") {
        setSelectedTag(tags[0]);
        dispatch(set_selected_tag(tags[0]));
        let pinnedFav = [...tags]
          .filter((e) => e.favourite?.indexOf(cacheMe.me.id) > -1)
          .map((m) => m.tag_id);
        if (tags.length) {
          if (tags[0].title === "UNTAGGED FILES") {
            // props.setPinnedTag(sorted[0].tag_id);
            // props.setPinnedTagDetails(sorted[0]);
            setPinnedTagArr([tags[0].tag_id, ...pinnedFav]);
          } else {
            setPinnedTagArr([...pinnedFav]);
          }
        }
      }
    }
    setLoaderTags(false); // Simulate loading completion
  }


  async function get_files(type = "init", tag_id = "", page = 1) {
    if (tag_id) {
        if(prevloader) return
        setPrevLoader(true)
        
        // let conv_id = params.conversation_id ? [params.conversation_id] : []
        let json = {
            conversation_id: params.conversation_id || "all_files",
            // conversation_ids: conv_id,
            file_name: searchFileName,
            file_type: "all",
            file_sub_type: selectedFilters === "reference_ID" ? "ref" : selectedFilters === "starred_files" ? "star" : "tag",
            tab: "file",
            // tag_id: [tag_id],
            tag_id: view_tag_files.state ? view_tag_files.tag_ids : tag_id,
            // tag_operator: 'or',
            limit: 20,
            page: page,
            selectedFilters: selectedFilters,
          };
        
          
        let res = await get_files_api({ variables: json });

        if (res?.data?.get_file_gallery?.files) {
            if (type === "init") {
              setallFiles(res?.data.get_file_gallery.files);
              setPage(1);
            } else {
              setallFiles([...allFiles, ...res?.data.get_file_gallery.files]);
              setPage((prevPage) => prevPage + 1); // Increment page for pagination
            }

            setPagination(res.data.get_file_gallery.pagination);
            
            // setSummary(res.data.get_file_gallery.summary);
          }
          setPrevLoader(false);
          // console.log('filter__page',page);
    // } else {
    //   // debugger
      
    }
  }
  useEffect(()=>{
    // console.log('filter__file',allFiles.length);
  },[allFiles])
  

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    // setLoading(true); // Start loading when a tag is selected
    // get_files(tag.tag_id)
    // setTimeout(() => {
    //   setLoading(false); // Simulate loading completion
    // }, 500); // Simulate a delay for loading
    // setShowRelatedTags(false);
    dispatch(set_selected_tag(tag));
    dispatch(set_view_tag_files({ state: false, tag_ids: [] }));
    setSelectedItemIndex(-1);
    setSearchFileName("");
    setSelectedFilters("title_- Ascending");

    // dispatch(set_related_tag_popup(true))
  };

  const favouriteUnfavourite = async (data) => {
    // debugger
    // event.preventDefault();
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    // console.log("🚀 ~ favouriteUnfavourite ~ data:", data)
    let apires = await fav_unfav({
      variables: {
        input: {
          favo_data: {
            tag_id: data.tag_id,
            status: data.status,
          },
        },
      },
    });
    // debugger
    // console.log("🚀 ~ fav_unfav ~ apires:", apires);
    // if (apires.data.favourite_unfavourite.status) {
    //     setApi_data(api_data?.map(v => v.tag_id === data.tag_id ? { ...v, favourite: data.status === '1' ? true : false } : v));
    //     toast.success(apires.data.favourite_unfavourite.message, { duration: 400 })
    // } else {
    //     toast.error(apires.data.favourite_unfavourite.message, { duration: 400 })
    // }
  };

  const handlePinClick = (tag_id) => {
    // debugger
    if (!pinnedTagArr.includes(tag_id)) {
      setPinnedTagArr([...pinnedTagArr, tag_id]);
      favouriteUnfavourite({ tag_id: tag_id, status: "1" });
    } else {
      setPinnedTagArr(pinnedTagArr.filter((id) => id !== tag_id));
      favouriteUnfavourite({ tag_id: tag_id, status: "2" });
    }

    // setTags(prevTags =>
    //     prevTags.map(tag =>
    //         tag.id === tagId ? { ...tag, pined: !tag.pined } : tag
    //     )
    // );
  };

  const handleRelatedTagsClick = () => {
    // setRelatedTags(selectedTag?.relatedTags || {});
    // setShowRelatedTags(true); // Show related tags when clicked
    dispatch(set_related_tag_popup(true));
  };

  useEffect(() => {
    get_tags();
  }, []);

  // useEffect(() => {
  //         debugger;
  //         return () => {
  //             debugger;
  //             // dispatch(set_view_tag_files({ state: false, tag_ids: [] }))
  //         }
  //     }, [])

  useEffect(() => {
    
    if (selectedTag?.tag_id || selectedFilters || view_tag_files.state) {
      get_files("init", selectedTag?.tag_id || "");
    }
  }, [selectedTag, selectedFilters, view_tag_files]);

  async function getRelatedTag() {
    if (selectedTag?.tag_id) {
      let res_tag = await get_related_tag({
        variables: {
          tag_id: selectedTag?.tag_id,
          conversation_id: params.conversation_id ? params.conversation_id : "",
        },
      });
      
      if (res_tag?.data?.others_tag_details.length) {
        let allTag_ids = [];
        let allTags = [];
        for (let t of res_tag?.data?.others_tag_details) {
          if (
            allTag_ids.indexOf(t.tag_id) === -1 &&
            t.tag_id !== selected_tag?.tag_id
          ) {
            allTag_ids.push(t.tag_id);
            allTags.push({ ...t, isChecked: t.isChecked || false });
          }
        }
        setrelatedTag(allTags);
        // console.log("allTags:related:", allTags);
        // setLoading(false)
      }
    }
  }

  useEffect(() => {
    getRelatedTag();
  }, [selectedTag]);

  // useEffect(() => {
  //     debugger
  //     if(searchFileName === ""){
  //         get_files('init', selectedTag?.tag_id || '')
  //     }

  // }, [searchFileName])

  const handleTagFilterClick = (filter) => {
    setSelectedTagFilters("");
    setTimeout(() => {
      setSelectedTagFilters(filter);
    }, 0);
    setSelectedTagFilters(filter);
    setFilterTagsOptions(!filterTagsOptions);
  };

  const filteredTags = tags.filter((tag) =>
    tag.title?.toLowerCase().includes(searchStr.toLowerCase())
  );

  const sortedAndFilteredTags = filteredTags
    .filter((tag) =>
      tag.title === "UNTAGGED FILES"
        ? true
        : (tag.l_show ?? true) && tag.use_count > 0
    )
    .sort((a, b) => {
      if (a.title === "UNTAGGED FILES") return -1;
      if (b.title === "UNTAGGED FILES") return 1;

      const aPinned = pinnedTagArr.includes(a.tag_id);
      const bPinned = pinnedTagArr.includes(b.tag_id);
      if (aPinned && !bPinned) return -1;
      if (!aPinned && bPinned) return 1;

      // Sort by the selected filter within pinned and unpinned tags
      switch (selectedTagFilters) {
        case "Tags - Most recent":
          return new Date(b.updated_at) - new Date(a.updated_at);
        case "Tags - Ascending":
          return a.title.localeCompare(b.title);
        case "Tags - Descending":
          return b.title.localeCompare(a.title);
        default:
          return 0;
      }
    });

  return (
    <div className="taglistBodyArea bg-[#EEF3F9]">
      <div className="p-6 h-24 relative">
        <input
          onChange={(e) => setSearchStr(e.target.value)}
          value={searchStr}
          type="text"
          className="h-[50px] w-full px-6 !pr-24 rounded-full !placeholder-[#318fff] searchBoxInput"
          placeholder="Search for a tag"
          autoFocus={true}
          maxLength={60}
        />
        {searchStr.length > 0 && (
          <MdCancel
            onClick={() => {
              setSearchStr("");
            }}
            className="absolute right-20 top-9 text-2xl text-red-600"
          />
        )}
        <IoSearchOutline className="absolute right-10 top-9 text-2xl text-[#318fff]" />
      </div>
      <div className="block lg:flex md:overflow-y-auto gap-5 p-6 !pt-0 h-[calc(100vh-180px)] mostRecentTagsBodyFullArea">
        <div className="w-[100%] lg:w-[50%] mb-5 lg:mb-0 rounded-2xl bg-white mostRecentTagsFlexBox ">
          <div className="flex items-center gap-[9.8px] bg-[#E3F2FE] rounded-t-2xl p-5 relative  mostRecentTagsHead">
            <div
              className="conv_filter opt_icons !bg-[length:20px] !w-[20px] !m-0 cursor-pointer"
              onClick={() => setFilterTagsOptions(!filterTagsOptions)}
            ></div>
            <p className="text-[16px] text-[#0b1f47]">{selectedTagFilters}</p>
            {filterTagsOptions && (
              <div
                ref={ref}
                className="bg-white w-52 rounded-lg border border-[#e3f2ff] absolute top-5 left-14 tagsFilterOptions"
              >
                {/* <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Most Recent Tags</p>
                                <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Ascending</p>
                                <p className="text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer">Descending</p> */}
                {tagFilters.map((filter) => (
                  <p
                    key={filter}
                    className={classNames(
                      "text-sm text-[#0b1f47] py-1.5 px-4 hover:bg-[#D9D9D9] cursor-pointer",
                      selectedTagFilters === filter ? "active" : ""
                    )}
                    onClick={() => handleTagFilterClick(filter)}
                  >
                    {filter.charAt(0).toUpperCase() +
                      filter.slice(1).replace("_", " ")}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="h-[calc(100%-80px)] overflow-y-auto mostRecentTagsBody">
            {loaderTags ? (
              <div className="btn_loader leftbar_loader !h-[300px]"></div>
            ) : sortedAndFilteredTags.length > 0 ? (
              sortedAndFilteredTags.map((tag) => (
                <div
                  key={tag.id}
                  className={`filteredTagsList flex group justify-between items-center border-b border-b-[#e3f2ff] last:border-b-0 p-4 hover:bg-[#D9D9D9] cursor-pointer ${
                    tag.tag_id === selectedTag?.tag_id ? "active" : ""
                  }`}
                  onClick={() => handleTagClick(tag)}
                >
                  <div className="flex gap-2 items-center">
                    <div
                      className="w-[14px] h-[14px] rounded-full"
                      style={{ backgroundColor: tag.tag_color }}
                    ></div>
                    <p className="text-sm text-[#0b1f47]">
                      {tag.title} ({tag.use_count})
                    </p>
                  </div>
                  <div className="flex gap-5 items-center">
                    <div
                      className="w-5 h-5"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePinClick(tag.tag_id);
                      }}
                    >
                      {pinnedTagArr.includes(tag.tag_id) ? (
                        <MdPushPin className="text-[#0b1f47] text-xl" />
                      ) : (
                        <MdPushPin className="text-white text-xl" />
                      )}
                    </div>
                    <div className="w-5 h-5">
                      <FaChevronRight className="text-[#0b1f47] text-xl hidden group-hover:block cursor-pointer" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no_files_available">
                No data found matching the criteria.
              </div>
            )}
          </div>
        </div>

        <div className="w-[100%] lg:w-[50%] rounded-2xl bg-white showRelatedTagsArea relative">
          {_redux.related_tag_popup ? (
            <TagRelatedPopup
              relatedTag={relatedTag}
              setrelatedTag={setrelatedTag}
            />
          ) : (
            // selectedTag &&
            <TagsFileList
              loading={loading}
              containerRef={containerRef}
              // setRelatedTags={setRelatedTags}
              selectedTag={selectedTag}
              relatedTag={relatedTag}
              setrelatedTag={setrelatedTag}
              handleRelatedTagsClick={handleRelatedTagsClick}
              allFiles={allFiles}
              setallFiles={setallFiles}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              pagination={pagination}
              onScrollLinks={onScrollLinks}
              page={page}
              setPage={setPage}
              searchFileName={searchFileName}
              setSearchFileName={setSearchFileName}
              get_files={get_files}
              selectedItemIndex={selectedItemIndex}
              setSelectedItemIndex={setSelectedItemIndex}
              prevloader={prevloader}
            />
          )}
        </div>
      </div>
      {/* <Tags /> */}
    </div>
  );
};

export default TagsTabHome;
