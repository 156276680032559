import { useClickAway } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { MdAdd, MdAttachFile, MdOutlineCancel } from 'react-icons/md';
import { BsTrash3 } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import UploadedFileList from './UploadedFileList';

const maintenanceTypes = [
    'Byblos Downtown',
    'Byblos Uptown',
    'Chica',
    'Patria',
    'Pink Sky',
    'Storys'
];

const MaintenanceForm = () => {
    const [rows, setRows] = useState([
        { issue: '', maintenanceType: 'Select Maintenance Type', estimate: '', actual: '' }
    ]);
    const [isOpenMaintenanceType, setIsOpenMaintenanceType] = useState(false);
    const [selectedMaintenanceType, setSelectedMaintenanceType] = useState('Select Maintenance Type');
    const [searchMaintenance, setSearchMaintenance] = useState('');
    const [notesFileTab, setNotesFileTab] = useState();
    const [notesOpen, setNotesOpen] = useState(false);

    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const clearInput = () => {
        setInputValue("");
    };

    const handleNotesTabOpen = () => {
        setNotesFileTab("notes");
        setNotesOpen(true);
    }

    const handleNotesTabClose = () => {
        setNotesFileTab("");
        setNotesOpen(false);
    }

    const handleFileTabOpen = () => {
        setNotesFileTab("files");
        setNotesOpen(false);
    }

    const ref = useClickAway(() => {
        setIsOpenMaintenanceType(false);
    })
    const handleDropdownToggle = () => {
        setIsOpenMaintenanceType(!isOpenMaintenanceType);
    };

    const handleSelectMaintenance = (maintenance) => {
        setSelectedMaintenanceType(maintenance);
        setSearchMaintenance(''); // Clear search field when selecting a venue
        setIsOpenMaintenanceType(false);
    };

    const handleSearchChange = (event) => {
        setSearchMaintenance(event.target.value);
    };

    // Filter venues based on the search query
    const filteredMaintenanceTypes = maintenanceTypes.filter(maintenanceTypes =>
        maintenanceTypes.toLowerCase().includes(searchMaintenance.toLowerCase())
    );

    const addRow = () => {
        setRows([...rows, { issue: '', maintenanceType: 'Select Maintenance Type', estimate: '', actual: '' }]);
    };

    const deleteRow = (index) => {
        if (rows.length > 1) {
            setRows(rows.filter((_, i) => i !== index));
        }
    };

    return (
        <div className='maitenance-form-container'>
            <div className=" mt-5 mb-2 pl-5 flex justify-between h-[40px] gap-[10px] items-center w-[calc(100%-104px)]">
                <div className='input-group-header'>Maintenance issue</div>
                <div className='input-group-header'>Category</div>
                <div className='input-group-header'>Estimated cost</div>
                <div className="input-group-header activeOpecity">Actual cost</div>
            </div>
            {rows.map((row, index) => (
                <div className="allFieldsGroup w-[calc(100%-104px)]">
                    <div className="relative input-group justify-between ml-5 mt-2">
                        <div className="group maintenanceInputArea relative w-[25%] rounded-[5px]">
                            <input
                                type="text"
                                name="maintenanceIssue"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="e.g. Computer"
                                className="mainInputFiled pl-2 pr-6 rounded-[5px] border border-[#d9d9d9] w-full"
                            />
                            {inputValue && (
                                <MdOutlineCancel
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 hidden group-hover:block text-red-500 cursor-pointer"
                                    onClick={clearInput}
                                />
                            )}
                        </div>
                        <div className="w-[25%]">
                            <div className='mainHeaderContainer'>
                                <div
                                    className='mainHeader'
                                    style={{ borderRadius: '4px' }}
                                    onClick={handleDropdownToggle}
                                >
                                    {selectedMaintenanceType}
                                    <i className={`fa fa-chevron-right custom_icon ${isOpenMaintenanceType ? 'open' : ''}`}></i>
                                </div>
                                {isOpenMaintenanceType && (
                                    <div className='custom_dropdown-body_task inReport open !top-1' ref={ref}>
                                        <div className="searchareaDiv relative">
                                            <input
                                                className="_inputBar searchLeft popupserach"
                                                type="text"
                                                placeholder="Search"
                                                value={searchMaintenance}
                                                onChange={handleSearchChange}
                                                autoFocus
                                            />
                                            {searchMaintenance.length > 0 && (
                                                <MdOutlineCancel
                                                    onClick={() => setSearchMaintenance('')}
                                                    className='absolute text-red-600 text-xl right-2 top-2'
                                                />
                                            )}
                                        </div>
                                        {filteredMaintenanceTypes.length > 0 ? (
                                            filteredMaintenanceTypes.map((maintenance, index) => (
                                                <div
                                                    key={index}
                                                    className='custom_dropdown-item'
                                                    onClick={() => handleSelectMaintenance(maintenance)}
                                                >
                                                    {maintenance}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="custom_dropdown-item">
                                                <p className='text-center py-6'> No maintenance found</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-[25%]">
                            <input
                                type="number"
                                name="estimateRate"
                                placeholder="$0.00"
                                className="mainInputFiled pl-2 pr-2 text-right rounded-[5px] border border-[#d9d9d9]"
                                min="0"
                                step="0.01"
                                maxLength="10"
                            />
                        </div>
                        <div className="w-[25%]">
                            <input
                                className="mainInputFiled readOnlyVal pl-2 pr-2 text-right rounded-[5px] border border-[#d9d9d9]"
                                type="number"
                                name="actualRate"
                                placeholder="$0.00"
                                min="0"
                                step="0.01"
                                readOnly
                            />
                        </div>
                        <div className={`absolute w-[80px] -right-[90px] `}>
                            <div className="flex gap-2 justify-start items-center">
                                <MdAttachFile className='text-[#0079D1] rotate-45 text-2xl cursor-pointer' />
                                {index === rows.length - 1 && (
                                    <MdAdd className='text-[#0b1f47] hover:text-[#0079D1] text-2xl cursor-pointer' onClick={addRow} />
                                )}
                                {rows.length > 1 && (
                                    <BsTrash3 className='text-red-500 text-xl cursor-pointer' onClick={() => deleteRow(index)} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="toggleHideClick ml-5 cursor-pointer">
                        <div className="flex gap-3 items-center">
                            <div className="writeNoteTab addTab flex gap-1 items-center">
                                {notesOpen === true ?
                                    <>
                                        <p onClick={handleNotesTabClose} className='text-[#0b1f47] font-bold text-sm border-b border-b-[#0b1f47]'>Close additional issue notes (optional)</p>
                                        <IoIosArrowDown className="text-[#0b1f47] text-sm" />
                                    </>
                                    :
                                    <>
                                        <p onClick={handleNotesTabOpen} className='text-[#0b1f47] text-sm'>Additional issue notes (optional)</p>
                                        <IoIosArrowDown className="text-[#0b1f47] text-sm" />
                                    </>
                                }

                            </div>
                            <div onClick={handleFileTabOpen} className={`fileTab addTab text-[#0b1f47] text-sm ml-3 ${notesFileTab === 'files' ? 'border-b border-b-[#0b1f47] font-bold' : ''} `} >Files(0)</div>
                        </div>
                    </div>
                    {
                        notesFileTab === 'notes' &&
                        <div className='px-5 py-2 w-[calc(100%-104px)]'>
                            <div className='relative w-full'>
                                <textarea className="youtnotesTextArea rounded-[5px]" name="notes" id="shortNoteTextArea" rows="3" placeholder="Write your note here"></textarea>
                            </div>
                        </div>
                    }
                    {notesFileTab === 'files' && <UploadedFileList />}
                </div>
            ))}
            <div className="totalFooter">
                <div>Total</div>
                <div></div>
                <div style={{ paddingRight: '15px' }}>$10.00</div>
                <div style={{ paddingRight: '15px' }}>$0.00</div>
            </div>
        </div>
    );
};

export default MaintenanceForm;