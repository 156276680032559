import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { set_downloading_progress } from '../../../redux/message';

function Downloading(props) {
    const dispatch = useDispatch()
    const downloading_file = useSelector(state => state.message.downloading_file)
    const downloading_progress = useSelector(state => state.message.downloading_progress)
    console.log('Downloading::', downloading_file)
    console.log('Downloading::', downloading_progress)
    return (
        <div className="downloadingPopup">
            <div className="downloadingHead">
                <span className="downloadingTitle">Downloading  <span style={{ marginLeft: '15px' }} className="downloadingFileName">{downloading_file.length > 0 ? downloading_file[0].progress : ''}</span></span>
                <span className="closePopup" onClick={() => dispatch(set_downloading_progress({}))}></span>
            </div>
            <div className="downloadingBody">
                {
                    Object.keys(downloading_progress).length ? Object.keys(downloading_progress).map((key, i) =>
                        <div key={'k_' + i} className="downloadingFile">
                            <span className="downloadingFileName">{key}</span>
                            <span className="downloadingFileName">{downloading_progress[key]}</span>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    )
}
export default Downloading;