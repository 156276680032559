import React from 'react'
import { InputValidationCheck, isBlankOrInvalid, preventPlusSign, sanitizeNumberInput } from '../../ValidationCheck';

function CreateNetSales({ totalData, setTotalData, getSubmitStatus, preventInvalidKeyPress  }) {
  // const [totalData, setTotalData] = useState({
  //     title: "Dinner",
  //     netSales: 100,
  //     comps: 10,
  //     total: 110
  //   })
  const handleInputChange = (e) => {
    const { value } = e.target;

    // Allow clearing the input (empty value)
    if (value === '') {
        setTotalData((prevData) => ({
            ...prevData,
            title: value,
        }));
        return;
    }

    // Prevent the first character from being a number
    if (/^\d/.test(value)) {
        console.warn('The title cannot start with a number');
        return;
    }

    // Use InputValidationCheck for further sanitization
    if (InputValidationCheck(value)) {
        console.warn('Invalid input for title');
        return;
    }

    // Trim leading spaces from the value
    const trimmedValue = value.trimStart();

    // Update the title in the state
    setTotalData((prevData) => ({
        ...prevData,
        title: trimmedValue,
    }));
};

const handleNetSalesOnChange = (e, setTotalData, prevData) => {
  const sanitizedValue = sanitizeNumberInput(e.target.value); // Sanitize input
  
  // If the sanitized value is blank, invalid, or just a sign, return early
  if (isBlankOrInvalid(sanitizedValue)) {
    setTotalData((prevData) => ({
      ...prevData,
      netSales: '',
      total: sanitizedValue === '' ? parseFloat(prevData.comps) || '' : prevData.total,
    }));
    return false;
  }else{
 // Ensure sanitizedValue is a valid number before updating the state
 const netSalesValue = parseFloat(sanitizedValue) || 0;  // default to 0 if invalid number

 setTotalData((prevData) => ({
   ...prevData,
   netSales: netSalesValue,
   total: netSalesValue + (parseFloat(prevData.comps) || 0),
 }));
  }

 
};
const handleCompsChange = (e) => {
  const value = e.target.value;
  const sanitizedValue = sanitizeNumberInput(value); // Sanitize input
  
  // If the sanitized value is blank or invalid, return early
  if (isBlankOrInvalid(sanitizedValue)) {
    setTotalData((prevData) => ({
      ...prevData,
      comps: '',
      total: sanitizedValue === '' ? parseFloat(prevData.netSales) || '' : prevData.total,
    }));
    return;
  }

  // Proceed with updating state
  setTotalData((prevData) => ({
    ...prevData,
    comps: sanitizedValue === '' ? '' : parseFloat(sanitizedValue) || '', // Set to empty if cleared
    total: sanitizedValue === ''
      ? parseFloat(prevData.netSales) || '' // Adjust total when comps is cleared
      : (parseFloat(sanitizedValue) || 0) + (parseFloat(prevData.netSales) || 0),
  }));
};

  return (
    <div className='eodBackground w-full pl-[25px] pr-[25px] pb-[0px]'>
      <div className="flex gap-[20px] text-sm font-semibold text-gray-700 bg-white pb-0 pt-[20px]  ">
        <div className="w-[31%] text-left text-[24px] font-medium text-[#0b1f47]">Shift<span className='requiredLabel'>*</span></div>
        <div className="w-[23%] text-left  text-[24px] font-medium text-[#0b1f47]">Net sales<span className='requiredLabel'>*</span></div>
        <div className="w-[23%] text-left  text-[24px] font-medium text-[#0b1f47]">Comps</div>
        <div className="w-[23%] text-left  text-[24px] font-medium text-[#0b1f47]">Total</div>
      </div>
      <div className="flex gap-[20px] text-sm items-center bg-white pb-[20px] pt-[10px] ">
        <div className="w-[31%] text-left  text-[16px] font-medium text-[#0b1f47] pl-[5px] shiftTitle relative">
          {/* {totalData.title} */}
          <input
            type="text"
            className={`w-full pl-[10px] bg-[#f1f6fa] focus:bg-[#fff] border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left text-[#5b6477] !h-[30px] ${totalData.title === '' ? 'red_border' : ''}`}
            name="title"
            value={totalData.title}
            placeholder='e.g., dinner'
            onChange={handleInputChange}
            readOnly={!getSubmitStatus().form}
          />
          {getSubmitStatus().form && totalData.title !== '' && (
            <span

              className="!absolute !right-[6px] !h-[18px] !top-[6px] !transform-translate-y-1/2 !cursor-pointer removeFile"
              onClick={() =>
                setTotalData((prevData) => ({
                  ...prevData,
                  title: '', // Clear the title field
                }))
              }

            >
            </span>
          )}
        </div>

        <div className="w-[23%] text-left text-[16px] font-medium text-[#0b1f47] relative">
          <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
            $
          </span>
          <input
            type="number"
            placeholder="0.00"
            step="0.01"
            // className={`w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff] 
              // ${totalData.netSales === '' || 0 || 0.00 ? 'red_border' : ''}`}
            className={`w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff] 
                ${!totalData.netSales || parseFloat(totalData.netSales) === 0 ? 'red_border' : ''}`
            }
            value={totalData.netSales} // Handle empty input
            name="netSales"
            onChange={(e) => handleNetSalesOnChange(e, setTotalData, totalData)}
            // onKeyDown={preventInvalidKeyPress} // Prevent invalid key presses
            onKeyDown={(e) => {
              preventPlusSign(e); // Prevent plus sign key
              preventInvalidKeyPress(e); // Other invalid key press prevention logic
            }}
            onWheel={(e) => e.target.blur()}
            readOnly={!getSubmitStatus().form}
            
            min="0"

          />

        </div>
        <div className="w-[23%] text-left text-[16px] font-medium text-[#0b1f47] relative">
          <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
            $
          </span>
          <input
            type="number"
            placeholder="0.00"
            step="0.01"
            className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]"
            value={totalData.comps} // Ensure netSales is a number
            name="comps"
            onChange={handleCompsChange }
            onWheel={(e) => e.target.blur()}
            readOnly={!getSubmitStatus().form}
            onKeyDown={(e) => {
              preventPlusSign(e); // Prevent plus sign key
              preventInvalidKeyPress(e); // Other invalid key press prevention logic
            }}
            min="0"
          />

        </div>
        <div className="w-[23%] text-left text-[16px] font-medium text-[#0b1f47] relative total_Info">
          <span className="absolute inset-y-0 left-2 flex items-center text-gray-500">
            $
          </span>
          <input
            type="number"
            placeholder="0.00"
            step="0.01"
            className="w-full pl-5 border border-b-[rgb(209 213 219 / 30%)] rounded px-2 py-1 text-left !h-[30px] bg-[#f1f6fa] focus:bg-[#fff]"
            value={Number(totalData.total || 0).toFixed(2)} // Ensure netSales is a number
            name="total"
            onChange={(e) =>
              setTotalData((prevData) => ({
                ...prevData,
                total: parseFloat(e.target.value) || 0, // Convert input to number
              }))
            }
            onWheel={(e) => e.target.blur()}
            readOnly={!getSubmitStatus().form}
            onKeyDown={preventInvalidKeyPress}
            min="0"
          />
        </div>
      </div>
    </div>
  )
}

export default CreateNetSales