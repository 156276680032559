import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { setLeftFilter } from '../../../redux/message'
import { useDispatch } from "react-redux";
import { isValidConvId } from "../../../Utils/Common";

export default function FilterStatus({ type, conversation_id, setTopSearchBarPopUp }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const close = () => {
        let last_conv = localStorage.getItem('last_active_conv')
        let _id = (isValidConvId(last_conv) && (type === 'unread_reply')) ? last_conv : conversation_id;
        setTopSearchBarPopUp(false)
        dispatch(setLeftFilter('normal'))
        navigate("/connect/" + _id)
    }

    return (
        <div className={'filter_section filterActionPreview'}>
            <div className={`filter_status_container miniItem`}>
                <div>
                    {
                        type === 'str' ?
                            <div className="miniItem_image msg_title"></div> :
                            type === "private" ?
                                <div className="miniItem_image private"></div> :
                                type === "all_reply" || type === "unread_reply" ?
                                    <div className="miniItem_image all_reply"></div> :
                                    type === "link" ?
                                        <div className="miniItem_image link"></div> :
                                        type === "msg_title" ?
                                            <div className="miniItem_image msg_title"></div> :
                                            type === "media" ?
                                                <div className="miniItem_image media"></div> :
                                                type === "voice" ?
                                                    <div className="miniItem_image voice"></div> :
                                                    type === "star" ?
                                                        <div className="miniItem_image star"></div> :
                                                        type === "flag" ?
                                                            <div className="miniItem_image flag"></div> :
                                                            type === "unread_msg" ?
                                                                <div className="miniItem_image unread"></div> : ""
                    }
                </div>
                <div className={'filter_status_text miniItem_name'}>
                    {type === 'str' ?
                        'Filter search only'
                        : type === 'all_reply' ?
                            'Filter Threaded chat(s) only'
                            : type === 'link' ?
                                'Filter Link message(s) only'
                                : type === 'msg_title' ?
                                    'Filter message with title(s) only'
                                    : type === 'media' ?
                                        'Filter File message(s) only'
                                        : type === 'voice' ?
                                            'Filter voice message(s) only'
                                            : type === 'star' ?
                                                'Filter Star file(s) only'
                                                : type === 'unread_msg' ?
                                                    'Filter Unread message(s) only'
                                                    : type === 'flag' ?
                                                        'Filter Flagged message(s) only'
                                                        : type === 'private' ?
                                                            'Filter Private message(s) only'
                                                            : type === 'unread_reply' ?
                                                                'Filter Unread Reply message(s) only'
                                                                : null}
                </div>
                <div className={'filter_status_close miniItem_remove hover:!bg-[#318FFF] !bg-[#0b1f47] !text-white !w-6 !h-6'} onClick={() => close()}> <IoClose className="font-bold" /> </div>

            </div>
        </div>
    );
}