import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CREATE_UPDATE_CATEGORY } from '../../../Utils/GraphqlMutaion';
import { InputValidationCheck } from '../../ValidationCheck';

function CreateCategory({ categories, type, setCreateCategory, setCategories, action_data }) {
    const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState(type === 'edit' ? action_data.unit_name : '');
    const [create_update_category] = useMutation(CREATE_UPDATE_CATEGORY)

    const createTeam = async () => {
        setLoader(true)
        let apires = await create_update_category({
            variables: {
                input: {
                    title: title
                }
            }
        })
        setLoader(false)
        // console.log("🚀 ~ createTeam ~ apires:", apires)
        if (apires.data.create_update_Category.status) {
            setCategories(prev => [JSON.parse(apires.data.create_update_Category.data), ...prev])
            toast.success(apires.data.create_update_Category.message, { duration: 400 })
        }
        setCreateCategory('')
    };

    const updateTeam = async () => {
        setLoader(true)
        let apires = await create_update_category({
            variables: {
                input: {
                    unit_id: action_data.unit_id,
                    title: title
                }
            }
        })
        setLoader(false)
        // console.log("🚀 ~ createTeam ~ apires:", apires)
        if (apires.data.create_update_Category.status) {
            setCategories(prev => prev.map(m => m.unit_id === action_data.unit_id ? { ...m, unit_name: title } : m))
            toast.success("Room category updated successfully.", { duration: 400 })
        }
        setCreateCategory('')
        // try {
        //     setLoader(true);
        //     // Simulated API call with static data
        //     const APIres = { status: true, data: { team_id: props.data.team_id, team_title: title, participants: props.data.participants } };
        //     // Simulated successful API response
        //     if (APIres.status) {
        //         toast.success('Category updated successfully', { duration: 4000 });
        //         setLoader(false);
        //         setTitle('');
        //         props.setCreateCategory('');
        //     }
        // } catch (error) {
        //     console.log(error);
        //     setLoader(false);
        //     toast.error('An error occurred while updating the category');
        // }
    };

    useEffect(() => {

        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setCreateCategory('');
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    const handleCategoryChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setTitle(value);
            return;
        }

        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (InputValidationCheck(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setTitle(trimmedValue);
    };


    const [title_match, setTitle_match] = useState(false);
    useEffect(() => {
        if (type !== 'edit') {
            const match = categories.some(
                (data) =>
                    data.unit_name.toLowerCase() === title.toLowerCase()
            );
            setTitle_match(match);
        }
    }, [type, categories, title]);



    return (
        <>
            <div className="backwrap">
                <div className="createTeamModal">
                    <div className="createTeamModalHead">
                        <h4 className="popupTitle">{type === 'edit' ? 'Edit category' : 'Create category'}</h4>
                        <span className="closeModal" onClick={() => setCreateCategory('')}></span>
                    </div>
                    <div className="createTeamModalBody">
                        <div className="crTeam_inputGroup">
                            <label>Category name</label>
                            <input autoFocus type="text" value={title} maxLength={25} onChange={handleCategoryChange} placeholder="Category name" />
                            {title_match && (<span className="rgInputMsg" style={{ color: 'red', fontSize: '12px', margin: '-8px 0px 4px 0px', }}>Catagory name already exist.</span>)}
                        </div>
                    </div>
                    <div className="createTeamModalFoot">
                        {loader ? (
                            <button className="btnAction btn_loader" style={{ color: 'transparent' }}>
                                {type === 'edit' ? 'Update' : 'Create category'}
                            </button>
                        ) : title.trim() !== '' && !title_match ? (
                            <button
                                className="btnAction"
                                onClick={type === 'edit' ? updateTeam : createTeam}
                                disabled={type === 'edit' && action_data.unit_name === title.trim()}
                            >
                                {type === 'edit' ? 'Update' : 'Create category'}
                            </button>
                        ) : (
                            <button className="btnAction" style={{ opacity: '0.5' }}>
                                {type === 'edit' ? 'Update' : 'Create category'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateCategory;
