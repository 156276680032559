import React, { useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import { TbArrowBackUp } from 'react-icons/tb';
import { MdOutlineCancel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useTaskContext } from '../TaskContext';
import sortArrayByKey from '../../../hooks/useSortedArray';

const RoomFilter = ({ setRoomFilterPopup }) => {
    const conversations = useSelector(state => state.message.conversations)
    const { fetchByFilter, set_filter, filter, setPagination, set_column_id } = useTaskContext()
    const [selected_room, set_selected_room] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleChange = (v) => {
        set_selected_room(prev => prev.includes(v.conversation_id) ? prev.filter(f => f !== v.conversation_id) : [...prev, v.conversation_id]);
    };

    const handleSelectAllChange = () => {
        set_selected_room(selected_room.length === conversations.length ? [] : conversations.map(m => m.conversation_id));
    };

    const view_filter = () => {
        let f_array = [...selected_room]; // get array of ids
        f_array.unshift('room'); // modify f_array to add 'keywords' at the beginning
        setPagination(prev => ({ ...prev, page: 1 }))
        set_column_id([])
        set_filter(prev => [...prev, f_array]); // spread and update the task_filter
        fetchByFilter([...filter, f_array]);
        setRoomFilterPopup(false)
    }


    const ref = useClickAway(() => {
        setRoomFilterPopup(false);
    });
    const filteredRoom = conversations.filter(f => f.title.toLowerCase().includes(searchQuery.toLowerCase()));
    const sorted_data = sortArrayByKey(filteredRoom, 'title', 'asc');

    return (
        <div ref={ref}>
            <div className='tagRelatedPopup forKeywords inRoomFilter !z-[9999] !w-[560px]'>
                <div className='tagToggle_sidebar'>
                    <div className="tagToggle_sidebar_head justify-between !py-6 !px-7">
                        <div className='flex items-center gap-2'>
                            <TbArrowBackUp className='text-xl text-[#318FFF] cursor-pointer' onClick={() => setRoomFilterPopup(false)} />
                            <div className="tagsText !text-[#2c56ac] !text-sm">Room</div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className="searchareaDiv relative">
                                <input
                                    id='popupserachFilter'
                                    className="_inputBar searchLeft relative"
                                    type="text"
                                    placeholder="Search room"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {searchQuery.length > 0 ?
                                    <MdOutlineCancel onClick={() => setSearchQuery("")} className=' cursor-pointer !right-2 absolute text-red-600 top-2 text-xl' /> : <div className="srcSectionPopup !right-2" data-for="top_head" data-tip="Search"></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="selectAllArea !py-4 !px-7">
                        <div className="checkboxes__item">
                            <label className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={selected_room.length === conversations}
                                    onChange={handleSelectAllChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Select all</div>
                            </label>
                        </div>
                    </div>
                    <div className="allTagsArea">
                        <ul className="alltagList">
                            {sorted_data.map(v => (
                                <li key={v.conversation_id} className='!py-4 !px-7'>
                                    <div className="tagsRelateLists">
                                        <div className="checkboxes__item">
                                            <label className="checkbox style-c">
                                                <input
                                                    type="checkbox"
                                                    checked={selected_room.includes(v.conversation_id)}
                                                    onChange={() => handleChange(v)}
                                                />
                                                <div className="checkbox__checkmark"></div>
                                                <div className="checkbox__body">{v.title}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tagCoupleBtn !bottom-5">
                        <div className="skipButton" onClick={() => setRoomFilterPopup(false)}>Skip</div>
                        <div className="skipButton !w-[80px]" onClick={() => set_selected_room([])}>Reset</div>
                        <div className="viewFilesButton !w-[75px]" onClick={view_filter}>View</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomFilter;
