import React, { useEffect, useState } from 'react';
import { MdClose, MdOutlineDone } from 'react-icons/md';
import Select, { components } from 'react-select';
import { FaAngleDown } from 'react-icons/fa6';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
import { GoTrash } from 'react-icons/go';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TEAMS } from '../../../Utils/GraphqlQueries';
import { useSelector } from 'react-redux';
import { CREATE_UPDATE_TAG } from '../../../Utils/GraphqlMutaion';
import { toast } from 'react-hot-toast';

const colors = [
    { color: '#032e84' },
    { color: '#732be2' },
    { color: '#318fff' },
    { color: '#7ed6d0' },
    { color: '#ff9f2b' },
    { color: '#ffc009' }
];
const CreateTags = ({ createTagsPopup, setCreateTagsPopup, select_tag, srcText, setAll_tags, setSrc_string }) => {
    const { user } = useSelector(state => state.message)
    const [searchQuery, setSearchQuery] = useState('');
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [selectedColor, setSelectedColor] = useState('');

    useEffect(() => {
        setSearchQuery(srcText)
    }, [srcText])

    useQuery(GET_TEAMS, {
        variables: { user_id: user.id },
        onCompleted: (data) => {
            const options = data.teams.map(team => ({
                value: team.team_id,
                label: team.team_title
            }));
            setTeams(options);
        }
    });

    const selectTeams = (e) => {
        setSelectedTeams(e);
    };

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <FaAngleDown className='text-2xl text-[#0b1f47]' />
            </components.DropdownIndicator>
        );
    };

    const customStyles = {
        control: base => ({
            ...base,
            height: 50,
            backgroundColor: "#f7f8f9",
            border: 'none'
        }),
        placeholder: defaultStyles => ({
            ...defaultStyles,
            color: '#979797',
        })
    };
    const handleInputChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setSearchQuery(value);
            return;
        }

        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setSearchQuery(trimmedValue);

    };

    const [create_tag] = useMutation(CREATE_UPDATE_TAG)

    // const createTag = async () => {
    //     try {
    //         let d = {
    //             title: searchQuery?.trim().toUpperCase(),
    //             tagged_by: user.id,
    //             tag_type: "public",
    //             tag_color: selectedColor,
    //             team_list: selectedTeams?.map(m => m.value)
    //         };
    //         console.log("🚀 ~ createTag ~ data:", d)
    //         let { data } = await create_tag({ variables: { input: d } });
    //         if (data.create_update_tag.status) {
    //             console.log(data.create_update_tag.tag);
    //             select_tag(data.create_update_tag.tag)
    //             toast.success(data.create_update_tag.message)
    //             setCreateTagsPopup(false)
    //         }
    //     } catch (error) {
    //         console.log("🚀 ~ createTag ~ error:", error)
    //     }
    // }
    const createTag = async () => {

        // debugger
        try {
            let d = {
                title: searchQuery?.trim()?.toUpperCase() || "",
                tagged_by: user?.id || "",
                tag_type: "public",
                tag_color: selectedColor || "#032e84",
                team_list: Array.isArray(selectedTeams) ? selectedTeams.map(m => m.value) : []
            };

            if (!d.title) {
                toast.error("Tag title is required.");
                return;
            }

            console.log("🚀 ~ createTag ~ data:", d);

            let { data } = await create_tag({
                variables: {
                    input: d
                }
            });

            if (data?.create_update_tag?.status) {
                setSearchQuery('')
                setSrc_string('')
                setAll_tags(prev => [data.create_update_tag.tag, ...prev]);
                select_tag(data.create_update_tag.tag);
                toast.success(data.create_update_tag.message);
                setCreateTagsPopup(false);
            } else {
                toast.error("Failed to create tag.");
            }
        } catch (error) {
            console.error("🚀 ~ createTag ~ error:", error);
            // if (error.networkError) {
            //     console.error("Network error:", error.networkError);
            // }
            // if (error.graphQLErrors) {
            //     error.graphQLErrors.forEach(({ message, locations, path }) =>
            //         console.error(`GraphQL error: ${message}, Path: ${path}, Locations: ${locations}`)
            //     );
            // }
            toast.error("Failed to create tag. Please try again.");
        }
    };


    return (
        <div className='createTagsPopup_area '>
            <div>
                {/* <p className='mt-4 mb-2 text-[#0b1f47]'>{createTagsPopup === "shared" ? "Create a shared tag" : "Create a personal tag"}</p> */}
                <p className='mt-5 text-[#0b1f47]'>Create shared tag</p>
                <div className='bg-[#f7f8f9] rounded mt-2 relative'>
                    <input
                        className='p-3 w-[50%] border bg-[#f7f8f9] text-[#0b1f47] rounded'
                        type="text"
                        placeholder={'Tag name'}
                        value={searchQuery}
                        onChange={handleInputChange}
                        autoFocus
                    />
                    {searchQuery.length > 0 &&
                        <MdClose onClick={() => setSearchQuery('')} className='text-red-600 absolute right-3 top-3 text-2xl cursor-pointer' />
                    }
                </div>
            </div>

            <div>
                <p className='mt-5 text-[#0b1f47]'>Choose colour</p>
                <div className='flex gap-3 mt-2'>
                    {createTagsPopup === "personal" ? (
                        // Show only the first color
                        <div
                            className={`p-4 w-[40px] rounded cursor-pointer relative`}
                            style={{ backgroundColor: colors[0].color }}
                            onClick={() => handleColorSelect(colors[0].color)}
                        >
                            {selectedColor === colors[0].color && (
                                <MdOutlineDone className='text-[#0b1f47] text-xl absolute top-[14px] right-[18px]' />
                            )}
                        </div>
                    ) : (
                        // Show all colors
                        colors.map((colorObj, index) => (
                            <div
                                key={index}
                                className={`p-4 w-[40px] rounded cursor-pointer relative`}
                                style={{ backgroundColor: colorObj.color }}
                                onClick={() => handleColorSelect(colorObj.color)}
                            >
                                {selectedColor === colorObj.color && (
                                    <MdOutlineDone className='text-[#0b1f47] text-xl absolute top-[14px] right-[18px]' />
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>

            {createTagsPopup === "shared" && (
                <div className='selectTeam_area'>
                    <p className='mt-5 text-[#0b1f47]'>Select team(s)</p>
                    <div className='bg-[#f7f8f9] border rounded mt-2 relative'>
                        <Select
                            isMulti
                            isClearable
                            isSearchable
                            name="colors"
                            options={teams}
                            onChange={selectTeams}
                            styles={customStyles}
                            className="basic-multi-select-tags"
                            classNamePrefix="select"
                            placeholder='Assign to specific team(s) or leave empty for all'
                            components={{ DropdownIndicator }}
                        />
                    </div>
                </div>
            )}

            <div className='flex gap-2 justify-end items-center mt-5'>
                <div onClick={() => setCreateTagsPopup(false)} className='relative rounded-full border p-1.5 border-[#d9d9d9]'>
                    <GoTrash className='text-[#5b6477] text-[20px] cursor-pointer' />
                </div>
                <button onClick={createTag} className='file_opt !pb-0 tooltip2 !text-[#0b1f47] hover:!text-white !bg-[#e3f2ff] hover:!bg-[#318fff]'>Create and assign</button>
            </div>
        </div>
    );
};

export default CreateTags;
