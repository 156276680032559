
import React from 'react';
// import ReactTooltip from 'react-tooltip';
export const  EmojiContainer = ({has_emoji,msg_id})=>{
    // console.log(has_emoji)
    const findUserList = async (type,status)=>{
        // let data = 
        //     {
        //         "msg_id": msg_id,
        //         "emoji": type,
        //         "participants":props.conv.participants
        //     }
        // if(status){
            
        //     let apires = await getReactionUser(data);

        //     if(apires.status){
        //         let allusers = [];
        //         for(let u of apires.result){
        //             allusers.push(u.user_fullname);
        //         }
        //         setTooltipview({...has_emoji,[type]:allusers});
        //     }
        // }
    }
	return (
		<>
            {/* <ReactTooltip type="dark"/> */}
			<div className="msgReactionContainer">
                {has_emoji.thumbsup > 0 ?
                    <div className="msgReaction" data-for={'thumbsup_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('thumbsup',true)}
                    onMouseLeave={() => findUserList('thumbsup',false)}>
                        {/* <ReactTooltip id={'thumbsup_tooltip'+msg_id} >  */}
                        {/* {has_emoji.thumbsup !== undefined ?
                            has_emoji.thumbsup.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="thumbsup" className="msgReactionIcon" data-name="thumbsup" alt="img"><img title='thumbsup' src="/media/images/reaction/like.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.thumbsup}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.heart > 0 ?
                    <div className="msgReaction" data-for={'heart_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('heart',true)}
                    onMouseLeave={() => findUserList('heart',false)}>
                       {/* <ReactTooltip id={'heart_tooltip'+msg_id} >  */}
                        {/* {has_emoji.heart !== undefined ?
                            has_emoji.heart.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="heart" className="msgReactionIcon" data-name="heart" alt="img"><img title='heart' src="/media/images/reaction/love.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.heart}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.joy > 0 ?
                    <div className="msgReaction" data-for={'joy_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('joy',true)}
                    onMouseLeave={() => findUserList('joy',false)}>
                         {/* <ReactTooltip id={'joy_tooltip'+msg_id} >  */}
                        {/* {has_emoji.joy !== undefined ?
                            has_emoji.joy.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="joy" className="msgReactionIcon" data-name="joy"><img title='joy' src="/media/images/reaction/care.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.joy}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.open_mouth > 0 ?
                    <div className="msgReaction" data-for={'open_mouth_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('open_mouth',true)}
                    onMouseLeave={() => findUserList('open_mouth',false)}>
                        {/* <ReactTooltip id={'open_mouth_tooltip'+msg_id} >  */}
                        {/* {has_emoji.open_mouth !== undefined ?
                            has_emoji.open_mouth.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="open_mouth" className="msgReactionIcon" data-name="open_mouth"><img title='open_mouth' src="/media/images/reaction/wow.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.open_mouth}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.grinning > 0 ?
                    <div className="msgReaction" data-for={'grinning_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('grinning',true)}
                    onMouseLeave={() => findUserList('grinning',false)}>
                        {/* <ReactTooltip id={'grinning_tooltip'+msg_id} >  */}
                        {/* {has_emoji.grinning !== undefined ?
                            has_emoji.grinning.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="grinning" className="msgReactionIcon" data-name="grinning"><img title='grinning' src="/media/images/reaction/haha.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.grinning}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.disappointed_relieved > 0 ?
                    <div className="msgReaction" data-for={'disappointed_relieved_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('disappointed_relieved',true)}
                    onMouseLeave={() => findUserList('disappointed_relieved',false)}>
                        {/* <ReactTooltip id={'disappointed_relieved_tooltip'+msg_id} >  */}
                        {/* {has_emoji.disappointed_relieved !== undefined ?
                            has_emoji.disappointed_relieved.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="disappointed_relieved" className="msgReactionIcon" data-name="disappointed_relieved"><img title='disappointed_relieved' src="/media/images/reaction/sad.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.disappointed_relieved}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.rage > 0 ?
                    <div className="msgReaction" data-for={'rage_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('rage',true)}
                    onMouseLeave={() => findUserList('rage',false)}>
                        {/* <ReactTooltip id={'rage_tooltip'+msg_id} >  */}
                        {/* {has_emoji.rage !== undefined ?
                            has_emoji.rage.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="rage" className="msgReactionIcon" data-name="rage"><img title='rage' src="/media/images/reaction/angry.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.rage}</span>
                    </div>
                    :
                    ''
                }
                {/* {has_emoji.thumbsdown > 0 ?
                    <div className="msgReaction" data-for={'thumbsdown_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('thumbsdown',true)}
                    onMouseLeave={() => findUserList('thumbsdown',false)}>
                        <ReactTooltip id={'thumbsdown_tooltip'+msg_id} > 
                        {has_emoji.thumbsdown !== undefined ?
                            has_emoji.thumbsdown.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        }
                        </ReactTooltip>
                        <span role="img" aria-label="thumbsdown" className="msgReactionIcon" data-name="thumbsdown">👎</span>
                        <span className="msgReactionCounter">{has_emoji.thumbsdown}</span>
                    </div>
                    :
                    ''
                } */}
                {has_emoji.folded_hands > 0 ?
                    <div className="msgReaction" data-for={'folded_hands_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('folded_hands',true)}
                    onMouseLeave={() => findUserList('folded_hands',false)}>
                        {/* <ReactTooltip id={'folded_hands_tooltip'+msg_id} >  */}
                        {/* {has_emoji.folded_hands !== undefined ?
                            has_emoji.folded_hands.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="folded_hands" className="msgReactionIcon" data-name="folded_hands"><img title='folded_hands' src="/media/images/reaction/ask.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.folded_hands}</span>
                    </div>
                    :
                    ''
                }
                {has_emoji.check_mark > 0 ?
                    <div className="msgReaction" data-for={'check_mark_tooltip'+msg_id} data-tip="" onMouseEnter={() => findUserList('check_mark',true)}
                    onMouseLeave={() => findUserList('check_mark',false)}>
                        {/* <ReactTooltip id={'check_mark_tooltip'+msg_id} >  */}
                        {/* {has_emoji.check_mark !== undefined ?
                            has_emoji.check_mark.map((u,i)=> <p key={i}>{u}</p>)
                            :
                            ''
                        } */}
                        {/* </ReactTooltip> */}
                        <span role="img" aria-label="check_mark" className="msgReactionIcon" data-name="check_mark"><img title='check_mark' src="/media/images/reaction/tick.webp" alt="img"/></span>
                        <span className="msgReactionCounter">{has_emoji.check_mark}</span>
                    </div>
                    :
                    ''
                }
            </div>
		</>
	  )
}

