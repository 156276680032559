import React, { useState, useMemo } from 'react';
import MaintenanceHeader from './MaintenanceHeader';
import SalesReportTab from './SalesReportTab';
import CreateEditMaintenance from './CreateEdit/CreateEditMaintenance';

const demoData = [
    {
        id: 1,
        formID: 'MF-241117000018',
        venue: 'byblos',
        date: 'August 2, 2024',
        estimated: '55.00',
        netSales: '55.00',
        comp: '33.00',
        total: '22.00',
        forecast: '16.00',
        actual: '14.00',
        netSalePercentage: '25%',
        status: 'Approve needed',
        JobStatus: 'Not Started',
        subMittedBy: 'MD Mahfuz Hossain Sujon',
        approveBy: 'Not Started',
        lastUpdatedUser: 'Mahfuz Hossain Sujon',
        lastUpdatedDate: 'August 1, 2024'
    },
    {
        id: 2,
        formID: 'MF-241117000019',
        venue: 'byblos2',
        date: 'July 30, 2024',
        estimated: '60.00',
        netSales: '$22.33',
        comp: '$11.12',
        total: '$11.21',
        forecast: '$11.98',
        actual: '$6.80',
        netSalePercentage: '30%',
        status: 'Approved',
        JobStatus: 'Not Started',
        subMittedBy: 'MD Mahfuz Hossain Sujon',
        approveBy: 'Not Started',
        lastUpdatedUser: 'Mahfuz Hossain Sujon',
        lastUpdatedDate: 'July 30, 2024'
    },
    {
        id: 3,
        formID: 'MF-241117000020',
        venue: 'byblos2',
        date: 'July 30, 2024',
        estimated: '65.00',
        netSales: '$111.00',
        comp: '$100.00',
        total: '$11.00',
        forecast: '$10.00',
        actual: '$10.00',
        netSalePercentage: '91%',
        status: 'Complete',
        JobStatus: 'Not Started',
        subMittedBy: 'MD Mahfuz Hossain Sujon',
        approveBy: 'Not Started',
        lastUpdatedUser: 'Mahfuz Hossain Sujon',
        lastUpdatedDate: 'July 30, 2024'
    },
    {
        id: 4,
        formID: 'MF-241117000021',
        venue: 'byblos2',
        date: 'July 30, 2024',
        estimated: '65.00',
        netSales: '$111.00',
        comp: '$100.00',
        total: '$11.00',
        forecast: '$10.00',
        actual: '$10.00',
        netSalePercentage: '91%',
        status: 'Draft',
        JobStatus: 'Not Started',
        subMittedBy: 'MD Mahfuz Hossain Sujon',
        approveBy: 'Not Started',
        lastUpdatedUser: 'Mahfuz Hossain Sujon',
        lastUpdatedDate: 'July 30, 2024'
    }
];

const MaintenanceHome = () => {
    const [sortField, setSortField] = useState('venue');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedReport, setSelectedReport] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [maintenanceTabs, setMaintenanceTabs] = useState('estimated');
    const [isCreatePopupEod, setIsCreatePopupEod] = useState(false);

    const handleMaintenanceTabs = (type) => {
        setMaintenanceTabs(type);
    }

    const handleSort = (field) => {
        setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
        setSortField(field);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // Filter data based on search term
    const filteredData = demoData.filter(row => {
        const values = Object.values(row);
        return values.some(value => value.toString().toLowerCase().includes(searchTerm.toLowerCase()));
    });

    // Sort filtered data
    const sortData = (data) => {
        const sortedData = [...data].sort((a, b) => {
            const aValue = a[sortField];
            const bValue = b[sortField];
            if (aValue === undefined || bValue === undefined) return 0;

            if (sortField === 'date') {
                return sortOrder === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
            }

            // Convert to numeric values if they contain currency signs or percentages
            const parseValue = (value) => {
                if (typeof value === 'string') {
                    if (value.startsWith('$')) return parseFloat(value.replace('$', '').replace(',', ''));
                    if (value.endsWith('%')) return parseFloat(value.replace('%', ''));
                }
                return value;
            };

            const aParsed = parseValue(aValue);
            const bParsed = parseValue(bValue);

            if (typeof aParsed === 'number' && typeof bParsed === 'number') {
                return sortOrder === 'asc' ? aParsed - bParsed : bParsed - aParsed;
            }

            // Handle string sorting for 'venue' and 'status'
            const compareStrings = (aStr, bStr) => aStr.localeCompare(bStr, undefined, { sensitivity: 'base' });

            return sortOrder === 'asc' ? compareStrings(aValue, bValue) : compareStrings(bValue, aValue);
        });

        return sortedData;
    };

    const sortedData = useMemo(() => sortData(filteredData), [filteredData, sortField, sortOrder]);

    // const handleEditReport = () => {
    //     setReportEdit(true);
    // };

    // const handleOpenPopup = (report) => {
    //     setSelectedReport(report);
    //     // location.pathname.includes('edit-reports');
    //     setIsPopupOpen(true);
    // };

    const handleOpenPopup = (report, type) => {
        if (type === 'editToken') {
            setSelectedReport(report);
            setIsPopupOpen(true);
        }
        setIsCreatePopupEod(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedReport(null);
        setIsCreatePopupEod(false);
    };


    // const handleClosePopup = () => {
    //     setIsPopupOpen(false);
    //     setSelectedReport(null);
    // };

    return (
        <>
            <div className='right_container task'>
                <MaintenanceHeader
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    handleClearSearch={handleClearSearch}
                    maintenanceTabs={maintenanceTabs}
                    handleMaintenanceTabs={handleMaintenanceTabs}
                    handleOpenPopup={handleOpenPopup}
                />
                {
                    maintenanceTabs === 'estimated' ? <SalesReportTab /> :
                        <div className="task_body_area inReportArea">
                            <div className="divTable">
                                <div className="divTableBody">
                                    <div className="divTableRow divTableFirstRow">
                                        <div className={`divTableCell ${sortField === 'formID' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('formID')}>
                                            <p className="columnNum"><span className={`${sortField === 'formID' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Form ID</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'venue' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('venue')}>
                                            <p className="columnNum"><span className={`${sortField === 'venue' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Venue</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'date' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('date')}>
                                            <p className="columnNum dateDivReport"><span className={`${sortField === 'date' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Date</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'estimated' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('estimated')}>
                                            <p className="columnNum dateDivReport"><span className={`${sortField === 'estimated' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Estimated</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'actual' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('actual')}>
                                            <p className="columnNum dateDivReport"><span className={`${sortField === 'actual' ? (sortOrder === 'asc' ? 'asce sortIcons' : 'sortIcons') : 'sortIcons'}`}></span>Actual</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'status' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('status')}>
                                            <p className="columnNum w-[140px]"><span className='sortIcons'></span>Status</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'JobStatus' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('JobStatus')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Job Status</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'subMittedBy' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('subMittedBy')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Submitted by</p>
                                        </div>
                                        <div className={`divTableCell ${sortField === 'approveBy' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('approveBy')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Approved by</p>
                                        </div>
                                        <div className={`divTableCell !w-[140px] lastupdatedCol ${sortField === 'lastUpdatedDate' ? (sortOrder === 'asc' ? 'asce' : 'desc') : ''}`} onClick={() => handleSort('lastUpdatedDate')}>
                                            <p className="columnNum"><span className='sortIcons'></span>Last updated by</p>
                                        </div>
                                        <span className="downloadIcon" style={{ position: 'absolute', right: '0px', top: '5px' }}>
                                            <span className="exportIcon"></span>
                                        </span>
                                    </div>
                                    {sortedData.length > 0 ? (
                                        sortedData.map((row, index) => (
                                            <div onClick={() => handleOpenPopup(row, "editToken")} key={index} className={`divTableRow ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                                <div className="divTableCell !px-1">{row.formID}</div>
                                                <div className="divTableCell !px-1">{row.venue}</div>
                                                <div className="divTableCell !px-1">{row.date}</div>
                                                <div className="divTableCell !px-1">${row.estimated}</div>
                                                <div className="divTableCell !px-1">${row.actual}</div>
                                                <div className="divTableCell !px-1">
                                                    <div className='!w-[140px]'>
                                                        <button className={`${row.status === 'Approved' ? 'bg-[#188744]' : row.status === 'Approve needed' ? 'bg-[#09266A]' : row.status === 'Complete' ? 'bg-[#336600]' : row.status === 'Draft' ? 'bg-[#CC6600]' : ''} px-2 py-1 rounded-full inline-block text-white text-center text-xs -ml-3`}>
                                                            {row.status}</button>
                                                    </div>
                                                </div>
                                                <div className="divTableCell !px-1">{row.JobStatus}</div>
                                                <div className="divTableCell !px-1">{row.subMittedBy}</div>
                                                <div className="divTableCell !px-1">{row.approveBy}</div>
                                                <div className="divTableCell lastupdatedCol !px-">
                                                    <div className="lastUpdated_text">
                                                        <p className="user">{row.lastUpdatedUser}</p>
                                                        <p className="userUpdateDate">{row.lastUpdatedDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="flex justify-center items-center text-xl font-bold">
                                            <p className='mt-32'>Sorry! No search result found matching this combination</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                }
            </div>
            {isPopupOpen || isCreatePopupEod ?
                <CreateEditMaintenance
                    report={selectedReport}
                    onClose={handleClosePopup}
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                /> : ""
            }
        </>
    );
};

export default MaintenanceHome;