import React, { useEffect, useState} from 'react';
// import moment from 'moment';
// import Moment from 'react-moment';
import 'moment-timezone';
import { connect } from 'react-redux';

// var user_diff = req.body.choose_plan.user - company_info.plan_user_limit;
// let per_month = moment().daysInMonth();
// let per_day = (4 / per_month);
// let days_left = moment().endOf('month').diff(moment(), 'days');
// let new_charge = days_left * per_day;
// if (user_diff > 0) {
//     amount = subscription.plan.amount / 100 + user_diff * new_charge;
// } else if (user_diff < 0) {
//     amount = subscription.plan.amount / 100 - user_diff * new_charge;
// }
const getAmountForStorage = (s) => {
    let data = [0,20, 50, 100, 200, 500, 1000, 2000];
    let price = [0,3.00, 7.00, 14.00, 20.00, 50.00, 100.00, 200.00];
    let index = data.indexOf(s);
    return price[index];
}


function Summary(props){
    const returnPerCompnayUSD =()=>{
        let usd = 4;
        // if(props.logindata.user.company_name.indexOf('OHS') > -1 ){
        //     usd = 2;
        // }
        return usd;
    }
    // // // //console.log('Summary',props);
    const [partial_payment, ] = useState(0);
    const [total,setTotal] = useState(0)
    // const [totalAditionl,setTotalAditional] = useState(0)
	// const changePartialPayment = ()=>{
	// 	// let per_month = moment().daysInMonth();
	// 	// let per_day = (returnPerCompnayUSD() / per_month);
	// 	// let days_left = moment().endOf('month').diff(moment(), 'days');
	// 	// let one_user_charge = days_left * per_day;
	// 	// let storage_charge = 0;
	// 	if(props.billingData.choose_plan.storage === 0){
	// 		// let p_day = 0;
	// 		// storage_charge = days_left * p_day;
	// 	}else if(props.billingData.choose_plan.storage === 20){
	// 		// let p_day = (3 / per_month);
	// 		// storage_charge = days_left * p_day;
	// 	}else if(props.billingData.choose_plan.storage === 50){
	// 		// let p_day = (7 / per_month);
	// 		// storage_charge = days_left * p_day;
	// 	}else if(props.billingData.choose_plan.storage === 100){
	// 		// let p_day = (14 / per_month);
	// 		// storage_charge = days_left * p_day;
	// 	}
	// 	// if(props.billingData.choose_plan.user > props.billingData.choose_plan.old_user){
	// 	// 	setPartialPayment((one_user_charge * (props.billingData.choose_plan.user - props.billingData.choose_plan.old_user)) +storage_charge)
	// 	// 	// setPartialPayment((one_user_charge * (props.billingData.choose_plan.user - props.billingData.choose_plan.old_user)) +storage_charge)
	// 	// }else{
	// 	// 	setPartialPayment(storage_charge)

	// 	// }
    // }
    const totalAmount = () => {
        let amount = 0;
        amount = (amount + getAmountForStorage(Number(props.billingData.choose_plan.storage)));
        amount = ((Number(props.billingData.choose_plan.user) * returnPerCompnayUSD()) + amount + partial_payment);
        // let total_additional_storage = 0;
        // for (let t of props.billingData.payment_db.storage_purchase_history) {
        //     amount = (amount + getAmountForStorage(Number(t.additional_storage)));
        //     total_additional_storage = total_additional_storage + Number(t.additional_storage)
        // }
        // // // // //console.log(64,amount)
        setTotal(amount.toFixed(3));
        // setTotalAditional(total_additional_storage);
    }
	useEffect(()=>{
        // changePartialPayment();
         totalAmount();
         //eslint-disable-next-line
	},[props.billingData.choose_plan.user,props.billingData.choose_plan.storage])
    useEffect(() => {
        // // //console.log(48,props.billingData)
        // changePartialPayment();
        totalAmount();
//eslint-disable-next-line
	},[])
	return(
	<>
		<div className='UpgradePlanRightArea'>
            <div className='orderSummary'>
                Order summary
            </div>
            <div className='parentSummary'>
                <div className='essentialPlanRightside'>
                        <div className='essentialsPlanDiv' style={{width:'80%'}}>
                        <h3>Essentials plan</h3> 
                        <p>{props.billingData.choose_plan.user} licenses X ${returnPerCompnayUSD()}.00</p>
                        <p style={{borderTop:'1px dotted #d8d8d8',marginTop:'6px',paddingTop:'6px'}}>(2 GB x {props.billingData.choose_plan.user} licenses) + 5 GB(FREE)</p>
                       <p>
                                {((Number(props.billingData.choose_plan.user) * 2) + 5)} GB
                       </p>
                       </div>
                        <div className='essentialsPlanCost'>
                            <h3>${props.billingData.choose_plan.user*returnPerCompnayUSD()}.00</h3> 
                        <p>Per month</p>
                        <p style={{borderTop:'1px dotted #d8d8d8',marginTop:'6px',paddingTop:'6px'}}>$0</p>
                        </div>

                </div>
                
                {/* {props.billingData.payment_db.storage_purchase_history.filter(s=> s.additional_storage !== 0).map(v =>
                    <div className='essentialPlanRightside' key={v.transection_date}>
                            <div className='essentialsPlanDiv'>
                            <h3>Additional {v.additional_storage} GB of Storage <span className='smallText'>(Added by You on {<Moment className="userDateText" format="MMM Do, YYYY">{v.transection_date}</Moment>})</span></h3> 
                            
                            </div>
                            <div className='essentialsPlanCost'>
                                <h3>$ {getAmountForStorage(v.additional_storage).toFixed(2)}</h3> 
                            <p>Per month</p>
                            </div>
                    </div>
                )} */}
                {props.billingData.choose_plan.storage > 0 ? 
                <div className='essentialPlanRightside'>
                        <div className='essentialsPlanDiv'>
                        <h3>Additional {props.billingData.choose_plan.storage === 1000 ? 1:props.billingData.choose_plan.storage === 2000 ? 2:props.billingData.choose_plan.storage} {props.billingData.choose_plan.storage === 1000 || props.billingData.choose_plan.storage === 2000 ? 'TB':'GB'} of storage</h3> 
                        
                        </div>
                        <div className='essentialsPlanCost'>
                            <h3>$ {getAmountForStorage(props.billingData.choose_plan.storage).toFixed(3)}</h3> 
                        <p>Per month</p>
                        </div>
                </div>
                :''}
            </div>
            {/* {partial_payment > 0 ? 
            <div className='essentialPlanRightside'>
                    <div className='essentialsPlanDiv'>
                    <h3>Partial Payment</h3> 
                    
                    </div>
                    <div className='essentialsPlanCost'>
                        <h3>{'$'+partial_payment.toFixed(2)}</h3> 
                    <p>Only for current month</p>
                    </div>
            </div>
            :''} */}
            <div className='essentialPlanTotal'>
                <div className='essentialPlanTotalText'>
                    Total
                </div>
                <div className='essentialsPlanTotalCost'>
                       <h3>${total}</h3> 
                    
                </div>
            </div>
            <div className='essentialPlanBottomside'>
                {props.loader ? 
                <div  className='proceedToPayment btn_loader' >
                    {props.billingData.plan_name.toUpperCase() === 'STARTER' ? 'Proceed':'Update'}  to Payment
                </div>:
                <div className='buttonArea'>
                    <div onClick={props.onClickNextHandler} className='proceedToPayment' >
                        Back
                    </div>
                    <div onClick={props.onClickHandler} className='proceedToPayment' >
                        {props.btn !== '' ? props.btn : props.billingData.plan_name.toUpperCase() === 'STARTER' ? 'Continue' :'Continue'}
                    </div>
                </div>
                }
            </div>
            
        </div>
    </>
	)
}




export default Summary;