import React, { useEffect, useState, useRef } from 'react'

import { UpdateCompany } from '../../../Utils/GraphqlMutaion';
import { Toaster, toast } from 'react-hot-toast';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { BiEditAlt } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';



function BusinessManager(props) {

	const userMe = useSelector(state => state.message.user)
	const [readOnly, setReadOnly] = useState(false);
	const [editCompanyTitle, setEditCompanyTitle] = useState(false);
	const [titleChanged, setTitleChanged] = useState(false);
	const [maxLengthExceeded, setMaxLengthExceeded] = useState(false);
	const [CompnayTitle, setCompnayTitle] = useState(userMe.company_name || "");
	const [warningMsg, setWarningMsg] = useState('');
	const inputRef = useRef(null);
	const [initialCompanyTitle, setInitialCompanyTitle] = useState(userMe.company_name || "");



	// Set initial value when entering edit mode
	const handleTitle = (e) => {
		let val = e.target.value;

		// Check if length exceeds max length
		if (val.length > 74) {
			setMaxLengthExceeded(true);
			val = val.slice(0, 75);
			e.target.value = val;
		} else {
			setMaxLengthExceeded(false);
		}

		const invalidStart = /^[^a-zA-Z]+/;
		const invalidSequence = /(\s{2,}|[^a-zA-Z0-9\s]{2,})/;

		if (val.length > 0 && invalidStart.test(val[0])) {
			val = val.replace(invalidStart, '');
			e.target.value = val;
			setWarningMsg('First character should be a letter (a-z or A-Z).');
		} else if (invalidSequence.test(val)) {
			val = val.replace(invalidSequence, (match) => match[0]);
			e.target.value = val;
			setWarningMsg('Double spaces or consecutive special characters are not allowed.');
		} else {
			setCompnayTitle(val);
			setWarningMsg('');
			if (!titleChanged) setTitleChanged(true); // Set titleChanged to true on first change
		}
	};

	useEffect(() => {
		if (editCompanyTitle && inputRef.current) {
			inputRef.current.focus();
			const length = inputRef.current.value.length;
			inputRef.current.setSelectionRange(length, length);
		}
	}, [editCompanyTitle, CompnayTitle]);

	useEffect(() => {
		if (editCompanyTitle && initialCompanyTitle === props.logindata?.user?.company_name) {
			setInitialCompanyTitle(CompnayTitle);
		}
	}, [editCompanyTitle]);


	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const showTooltip = () => {
        setIsTooltipVisible(true);
    };

    const hideTooltip = () => {
        setIsTooltipVisible(false);
    };
	const BussinessTooltip = () => {
		return (
			<div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Trigger Element */}
            <span
                style={{ cursor: 'pointer' }}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                className="tooltip user_Management_title"
            >
                <span className="info_tooltip_new"></span>
           

            {/* Tooltip Content */}
            {isTooltipVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '214px',
                        transform: 'translateX(-50%)',
                        zIndex: 999,
                        borderRadius: '4px',
                        padding: '10px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
						
                    }}
                    onMouseEnter={showTooltip} // Keep the tooltip visible while hovering over it
                    onMouseLeave={hideTooltip} // Hide the tooltip when leaving it
                    className="tooltiptext forInBussinessAccount"
                >
                    <p>
                        For the Workfreeli application, a business account provides teams and organizations with a dedicated space to collaborate, manage projects, and streamline communication. It includes enhanced features like file sharing, task management, real-time messaging, and secure storage, allowing businesses to centralize workflows and improve productivity. Designed for both small and large teams, a Workfreeli business account supports seamless collaboration with tools for organizing workspaces, setting permissions, and tracking project progress, making it ideal for efficient team management and coordinated project execution.
                    </p>
                    <span
                        onClick={hideTooltip}
                        className="tooltipClose"
                        style={{ cursor: 'pointer', color: '#ff5555', marginTop: '0px', display: 'block', textAlign: 'right' }}
                    >
                       
                    </span>
                </div>
            )} 
			</span>
        </div>
		)
	}

	

	// UpdateCompany 
	const [Company_update] = useMutation(UpdateCompany);

	const updateCompanyDetails = async () => {
		try {			
			const apires = await Company_update({
				variables: {
					input: {
						company_id: userMe?.company_id || "",
						company_name: CompnayTitle || "",
						domain_name: "@gmail.com",
					},
				},
			});
			if (apires.data.updateCompany?.status) {
				setEditCompanyTitle(!editCompanyTitle);
				toast.success("Company title updated successfully", { duration: 4000 });
			} else {
				setEditCompanyTitle(!editCompanyTitle);
				toast.error("Error: company title already exist.", { duration: 4000 });
			}
		} catch (error) {
			setEditCompanyTitle(!editCompanyTitle);
			toast.error(error, { duration: 4000 });
		}
	};





	return (
		<>
			<Toaster />
			<div className="right_container admin_right">
				<div className="adminRightHead">
					<span className="user_Management_title">Business account </span>
					<BussinessTooltip />

					{/* {(localStorage.getItem('total_login') <= 1) ? <UserManagerImpressons activeToolTip={activeToolTip} setActiveToolTip={setActiveToolTip} /> : ''} */}

				</div>
				<div className="usersTableContainer">
					<div className="inputGroup inCreateReport !pr-0 !relative">
						<label className="inputLabel">
							Company name<span className="red_star">*</span>
							{readOnly ? (
								<BiEditAlt
									id="CompnayTitleEdit"
									className="venueTitleEditIcon"
								/>
							) : (
								<BiEditAlt
									id="CompnayTitleEdit"
									className="venueTitleEditIcon"
									onClick={() => {
										setEditCompanyTitle(true);
										setTitleChanged(false); // Reset titleChanged when entering edit mode
									}}
								/>
							)}
						</label>
						<Tooltip anchorSelect="#CompnayTitleEdit" float="true"  place="right" content={readOnly ? "Your role does not allow access to this section":"Click to update Company name"}></Tooltip>
						<div className={classNames('compnayTitleArea', editCompanyTitle ? "titleHover" : "")}>
							<input
								ref={inputRef}
								value={CompnayTitle}
								onChange={handleTitle}
								type="text"
								name="team-name"
								className={classNames("team-name Companytitle", editCompanyTitle ? "" : "isDisable")}
								maxLength="75"
								placeholder={CompnayTitle}
							/>
							{CompnayTitle !== '' && (
								<span
									style={{
										position: 'absolute',
										right: '10px',
										height: '18px',
										top: '51px', // Center the icon vertically
										transform: 'translateY(-50%)', // Center the icon vertically
										cursor: 'pointer' // Change cursor to pointer to indicate clickable area
									}}
									className="removeFile"
									onClick={() => setCompnayTitle("")}

								>
								</span>
							)}
						</div>
						{warningMsg && <span style={{ color: 'red' }} className="rgInputMsg">{warningMsg}</span>}
						{maxLengthExceeded && (
							<span className="rgInputMsg" style={{ color: 'red' }}>Maximum length 75 characters.</span>
						)}
					</div>
					<div div className='tagCoupleBtn'
						style={{
							position: 'relative',
							clear: 'both',
							float: 'right',
							right: '0px',
							top: '20px',
							paddingBottom: '10px'

						}}
					>
						{/* Conditional button based on editCompanyTitle and titleChanged */}
						{!editCompanyTitle || !titleChanged || CompnayTitle.trim() === "" || CompnayTitle === initialCompanyTitle ? (
							<button className={classNames("createConv_create", "transparent")}>Update</button>
						) : (
							<button className="createConv_create" onClick={updateCompanyDetails}>Update</button>
						)}
					</div>
				</div>

			</div>


		</>
	)
}




export default BusinessManager;