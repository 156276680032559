import React, { useState } from 'react';
import DiscussionReplyPopup from './DiscussionReplyPopup';

const Discussion = () => {
    const messages = [
        {
            senderName: 'Md. Sadequr ss Rahman',
            date: 'November 17, 2024 at 12:28 PM',
            replayText: 'Test one',
        },
        {
            senderName: 'Md. Sadequr ss Rahman',
            date: 'November 17, 2024 at 12:28 PM',
            messageText: 'test two',
            file: '05-Tools-for-integration-testing.png',
            issue: '56uy56y',
            category: 'Carpentry',
            replayText: '',
        },
        {
            senderName: 'Md. Sadequr ss Rahman',
            date: 'November 17, 2024 at 12:28 PM',
            messageText: 'test two',
            file: '05-Tools-for-integration-testing.png',
            issue: '56uy56y',
            category: 'Carpentry',
            replayText: '',
        },
    ];

    // Initialize state to track which message's reply popup is open
    const [replied, setReplied] = useState(new Array(messages.length).fill(false));

    const handleReplyPopup = (index) => {
        setReplied((prev) => {
            const updatedReplies = [...prev];
            updatedReplies[index] = !updatedReplies[index]; // Toggle reply popup for specific message
            return updatedReplies;
        });
    };

    return (
        <>
            <div id="bottomID" className="discussion_main_area activityArea mt-5 mr-5">
                {messages.map((message, index) => (
                    <div key={index} className="messageDiv">
                        <div className="msgSeparator discussion"></div>
                        <div className="pb-4 group">
                            <div className="msgssenge !pl-0">
                                <p className="msg_sender_name !text-xs">
                                    {message.senderName} <span className="pipeBorder">| </span>
                                    <span className="dateText" style={{ paddingRight: '0px' }}>
                                        {message.date}
                                    </span>
                                </p>
                            </div>
                            {message.replayText ? (
                                <div className="mesg_text !pl-0 text-xs">
                                    {message.replayText}
                                </div>
                            ) : (
                                <div className="mesg_text !pl-0">
                                    <div className="text-xs">
                                        Notes on file: <strong>{message.messageText}</strong>
                                    </div>
                                    <div className="flex gap-1 items-center">
                                        {message.file && (
                                            <div className="text-xs">
                                                File: <strong>{message.file},</strong>
                                            </div>
                                        )}
                                        {message.issue && (
                                            <div className="text-xs">
                                                Issue: <strong>{message.issue},</strong>
                                            </div>
                                        )}
                                        {message.category && (
                                            <div className="text-xs">
                                                Category: <strong>{message.category},</strong>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div
                                className="msgFlagReplyOption"
                                onClick={() => handleReplyPopup(index)} // Toggle the reply popup for the clicked message
                            >
                                <div className="msgReplyBtn group-hover:visible">Reply</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {replied.map((isReplyPopupOpen, index) => (
                isReplyPopupOpen && (
                    <DiscussionReplyPopup
                        key={index}
                        setReplyPopup={() => handleReplyPopup(index)} // Close the popup for this message
                    />
                )
            ))}
        </>
    );
};

export default Discussion;
