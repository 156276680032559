/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useOutletContext, useNavigate } from "react-router-dom";
import { GET_ME } from '../../Utils/GraphqlQueries';
import { useMutation, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { TagPopup } from "../GlobalPopups/TagPopup";
import { sendMsgMutation } from '../../Utils/GraphqlMutaion';
import { useSelector, useDispatch } from 'react-redux'
import { setPopup, set_messages, set_raw_message_text, set_voice_file } from "../../redux/message";
import { modifyMyMsg, scrollToBottom, fileExtension, aesEncrypt, upload_obj } from "../../Utils/Common";
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { useClickAway } from "@uidotdev/usehooks";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

var placeCaretAtEnd = (el) => {
    el.focus();
    if (typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
};

var complted_files = [];
var all_files = [];
export const FileUpload = ({ type, ...otherProps }) => {
    // console.log("🚀 ~ FileUpload ~ type:", type)
    const dispatch = useDispatch();
    const active_conv = useSelector((state) => state.message.active_conversation)
    const messages = useSelector((state) => otherProps.is_reply_msg === 'no' ? state.message.msgs : state.message.reply_msgs)
    const voice_file = useSelector(state => state.message.voice_file)
    let navigate = useNavigate();
    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });
    const user = cacheMe.me;
    const params = useParams();
    const file_ref = useRef(null)
    const [referenceId, setReferenceId] = useState('');
    const [referenceType, setReferenceType] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [wrongText, setWrongText] = useState(false);
    const editorRef = useRef(null);

    const onEditorStateChange = (state) => {
        setEditorState(state);
    };

    const returnSuggestions = () => {
        return [
            { text: 'John Doe', value: 'john_doe' },
            { text: 'Jane Smith', value: 'jane_smith' },
            { text: 'Someone Else', value: 'someone_else' }
        ];
    };

    const [files, setFiles] = useState([]);

    function niceBytes(x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    const uploadFiles = async (_f) => {
        // console.log("🚀 ~ uploadFiles ~ _f:", _f)
        all_files = [...files];
        let bucket_name = user.email;
        bucket_name = bucket_name.replace("@", "-");
        bucket_name = bucket_name.replace(/\./g, '-');
        bucket_name = bucket_name.replace(/\./g, '-');
        // draw the file 
        for (let i = 0; i < _f.length; i++) {
            var sl = moment().format('x') + i;
            _f[i]['sl'] = sl;
            _f[i]['originalname'] = _f[i].name;
            _f[i]['mimetype'] = _f[i].type;
            _f[i]['fieldname'] = 'file_upload';
            let sf = {
                sl: sl,
                file_name: _f[i].name,
                mimetype: _f[i].type,
                file_size: _f[i].size,
                status: 0,
                progress: '0%',
                local_url: URL.createObjectURL(_f[i])
            }
            all_files.push(sf);
            setFiles([...all_files]);
        }
        // send the file to the server for upload
        for (let i = 0; i < _f.length; i++) {
            var data = new FormData();
            await new Promise(resolve => {
                data.append("bucket_name", bucket_name);
                data.append("sl", _f[i].sl);
                data.append("file_upload", _f[i]);
                const config = {
                    onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        // console.log(260, percentCompleted);
                        // debugger
                        let element = document.getElementById('file_container_' + _f[i].sl);
                        let progressStatus = element.querySelector('.progressStatus');
                        let progressColor = element.querySelector('.progressColor');
                        if (percentCompleted === 100) {
                            percentCompleted = 99;
                            progressColor.textContent = "Processing...";
                        }
                        progressStatus.textContent = percentCompleted + " %";
                        progressColor.style.width = percentCompleted + "%";
                    }
                }
                upload_obj(data, config, function (res) {
                    // file upload completed and push it into complted file array
                    for (let j = 0; j < all_files.length; j++) {
                        if (all_files[j].sl === res.sl) {
                            let rf = { ...res.file_info[0], oldname: all_files[j].file_name, size: res.file_info[0].size };
                            complted_files.push(rf);
                            break;
                        }
                    }
                    // remove the progress bar
                    const element = document.getElementById('file_container_' + res.sl);
                    // console.log(element, res.sl)
                    element.classList.add('upload_completed')
                    const progressStatus = element.querySelector('.progressContainer');
                    if (progressStatus) {
                        progressStatus.style.display = "none";
                    }
                    // console.log(249, complted_files.length, all_files.length);
                    setFiles([...all_files]);
                    resolve();
                });
            });
        }
        dispatch(set_voice_file(null))
        file_ref.current.value = null;
    }

    useEffect(() => {
        if (voice_file) {
            uploadFiles(voice_file)
        }
    }, [])

    const editFile = (e, i) => {
        document.getElementById('editNameFile_' + i).setAttribute('contentEditable', 'true')
        placeCaretAtEnd(document.getElementById('editNameFile_' + i))
    }

    const fileNameBlur = (e, name, i) => {
        placeCaretAtEnd(document.getElementById('editNameFile_' + i))
    }

    const cancelfEdit = (name, i) => {
        let oldName = document.getElementById('nameFile_' + i).getAttribute('data-originalname').split('.').slice(0, -1).join('.');
        document.getElementById('nameFile_' + i).innerHTML = oldName;
        document.getElementById('editNameFile_' + i).setAttribute('contentEditable', 'false');
    }

    const updateFileName = (name, i) => {

        let innerValue = document.getElementById('editNameFile_' + i).innerHTML;
        innerValue = innerValue.replace(/(<([^>]+)>)/ig, '');
        if (document.getElementById('editNameFile_' + i).innerHTML !== '') {
            document.getElementById('editNameFile_' + i).setAttribute('contentEditable', 'false')
            complted_files = complted_files.map(v => v.oldname === name ? { ...v, voriginalName: innerValue } : v);
        } else {
            placeCaretAtEnd(document.getElementById('editNameFile_' + i))
        }
    }

    const saveFileName = (e, name, i) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            updateFileName(name, i)
        }
    }

    let fileDesign = files.map((_f, i) =>
        <div className={'uploaded_file'} id={"file_container_" + _f.sl} key={i + 1} data-status={_f.status}>
            <span className={'removeFile'} data-id={"editNameFile_" + i} sl={_f.sl} name={_f.file_name} onClick={() => {
                setFiles(prev => prev.filter(v => v.sl !== _f.sl))
                all_files = all_files.filter(v => v.sl !== _f.sl)
                complted_files = complted_files.filter(v => v.oldname !== _f.file_name)
            }
            }></span>
            <div className={'uplogo'}>
                {_f.mimetype.indexOf('image') > -1 && _f.mimetype.indexOf('svg') === -1 ?
                    <img className={'uploading_img'} fill={"true"} sizes='100%' src={_f.local_url} alt="Logo" title="Logo" />
                    :
                    <img className={'uploading_img'} fill={"true"} sizes='100%' src={require(`../../../public/media/images/light_theme/${fileExtension(_f.file_name)}.svg`)} title="Logo" alt="Logo" />
                }
            </div>
            <div className={'upFileDetail'}>
                <span className={'upFileName'} id={"editNameFile_" + i} contentEditable={false} onKeyDown={(event) => saveFileName(event, _f.file_name, i)} onBlur={(event) => fileNameBlur(event, _f.file_name, i)}>
                    <span id={"nameFile_" + i} data-originalname={_f.file_name}>{_f.file_name.split('.').slice(0, -1).join(".")}</span>
                    <span className={'ext'}>.{_f.file_name.split('.')[_f.file_name.split('.').length - 1]}</span>
                </span>
                <span className={'editFile'} sl={_f.sl} name={_f.file_name} onClick={(event) => editFile(event, i)}></span>
                <span className={'fileUpAction'}>
                    <span className={'cancelfEdit'} onClick={() => cancelfEdit(_f.file_name, i)} >Cancel</span>
                    <span className={'savefEdit'} onClick={() => updateFileName(_f.file_name, i)} >Save</span>
                </span>
                <span className={'upFileSize'}>{niceBytes(_f.file_size)}</span>
            </div>
            <div className={classNames('progressContainer', 'progressContainer')} id={"progressContainer_" + _f.sl} >
                <div className={'progressStatus'}>{_f.progress}</div>
                <div className={'progressBar'}><span className={'progressColor'} style={{ width: _f.progress }}></span></div>
            </div>
        </div>
    );

    const [tag_popup, setTag_popup] = useState(false);
    const submitFileUpload = () => {
        if (complted_files.length === files.length && complted_files.length !== 0) {
            setTag_popup(true)
        }
    }


    const closePopup = () => {
        if (params.msg_id) {
            navigate(`/connect/${params.conversation_id}/reply/${params.msg_id}`)
        }
        else if (type === 'private_msg') {
            otherProps.setStep(2);
        } else {
            navigate(`/connect/${params.conversation_id}`)
        }
        complted_files = [];
        all_files = [];

    }

    function removeExtraBRTags(htmlString) {
        // Remove leading <br> tags
        var regexr = /<\/br>/;
        htmlString = htmlString.replace(regexr, '')
        // Remove trailing <br> tags
        htmlString = htmlString.replace(regexr, '')
        return htmlString.trimEnd();
    }

    const [send_msg_input, { data, loading, error }] = useMutation(sendMsgMutation);
    const handleSendMsg = (data1, with_attachment = false, with_tag = false) => {
        let { conversation_id } = params;
        return new Promise(async (resolve, reject) => {
            try {

                const content = removeExtraBRTags(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                var TrimText = content.replace(/[\r\n]/gm, '  </br>');
                var regex = /<\/br>$/;
                var TrimText1 = TrimText.replace(regex, '');

                let imgfile = [];
                let audiofile = [];
                let videofile = [];
                let otherfile = [];
                let all_attachment = {};
                let allfiles = complted_files;

                for (let f of complted_files) {
                    // console.log(97, f);
                    var mime = f.mimetype;
                    if (mime.indexOf('image') !== -1)
                        imgfile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('video') !== -1)
                        videofile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('audio') !== -1)
                        audiofile.push(f.bucket + '/' + f.key);
                    else
                        otherfile.push(f.bucket + '/' + f.key);
                }
                all_attachment = {
                    imgfile: imgfile,
                    audiofile: audiofile,
                    videofile: videofile,
                    otherfile: otherfile,
                    allfiles: allfiles.map(v => ({
                        originalname: v.originalname,
                        mimetype: v.mimetype,
                        voriginalName: v.voriginalName,
                        size: v.size,
                        bucket: v.bucket,
                        key: v.key,
                        acl: v.acl,
                    })),
                }
                // setLoader(true)
                let input = {
                    conversation_id: conversation_id,
                    company_id: user.company_id,
                    sender: user.id,
                    msg_type: with_attachment ? 'media_attachment' : 'text',
                    msg_body: aesEncrypt(TrimText1),
                    participants: active_conv?.participants, //ThisRoom.room.participants,
                    is_reply_msg: otherProps.is_reply_msg,
                    referenceId: referenceId,
                    reference_type: referenceType,
                    reply_for_msgid: '',
                    ...data1
                }
                if (otherProps.is_reply_msg === 'yes') {
                    input['reply_for_msgid'] = params.msg_id
                }

                if (with_attachment) {
                    input = {
                        ...input,
                        attach_files: all_attachment,
                        all_attachment: allfiles.map(v => ({ tag_list: [], has_tag: "" }))
                    }
                }

                let send_msg_data = await send_msg_input({
                    variables: {
                        input: input
                    }
                });
                // console.log(243, send_msg_data)
                dispatch(set_raw_message_text(''))
                let dycrepetMsg = await modifyMyMsg([send_msg_data.data.send_msg.msg])
                dispatch(set_messages({ data: JSON.parse(JSON.stringify([...messages, ...dycrepetMsg])), is_reply_msg: otherProps.is_reply_msg }))
                if (type === 'private_msg') {
                    dispatch(setPopup({ data: false, key: 'private_msg' }))
                } else {
                    navigate(-1);
                }
                setTimeout(() => {
                    scrollToBottom('main_msg_scroll_div')
                }, 300);
                resolve(true)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    const checkStatusUpload = (statusType, from, tag_ids = []) => {
        let data1 = {}
        let with_attachment = true;
        let with_tag = true

        switch (statusType) {
            case 'skip':
                switch (from) {
                    case 'tag':
                        with_tag = false;
                        break;
                    case 'file':
                        with_attachment = false;
                        break;

                    default:
                        break;
                }
                break;
            case 'upload':
                switch (from) {
                    case 'tag':
                        with_tag = true;
                        break;
                    case 'file':
                        with_attachment = true;
                        break;

                    default:
                        break;
                }
                break;
            default:
                break;
        }
        switch (type) {
            case 'file_upload':
                data1 = { is_secret: false, tag_list: tag_ids }
                break;
            case 'private_msg':
                data1 = { tag_list: tag_ids, secret_user: otherProps.secret_user, is_secret: true, msg_title: otherProps.form_data.title }
                break;
            default:
                break;
        }
        handleSendMsg(data1, with_attachment, with_tag);
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                if (otherProps.step === 3) {
                    otherProps.setStep("");
                    dispatch(setPopup({ data: false, key: 'private_msg' }));
                } else {
                    navigate(-1)
                }
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const [fileCommentEmoji, setFileCommentEmoji] = useState(false)

    const ref = useClickAway(() => {
        setFileCommentEmoji(false)
    })

    // const setRefId = (e) => {
    //     let str = e.target.value;
    //     if (str === '') {
    //         // Allow clearing the input
    //         setReferenceId(str);
    //         return;
    //     }
    //     var NewString = str.replace(/[^\w\s]/gi, '');
    //     const trimmedValue = NewString.trimStart();
    //     setReferenceId(trimmedValue);
    // }
    const setRefId = (e) => {
        let str = e.target.value;
    
        if (str === '') {
            // Allow clearing the input
            setReferenceId(str);
            return;
        }
    
        // Remove HTML tags
        str = str.replace(/<\/?[^>]+(>|$)/g, '');
    
        // Ensure the first character is not a special character
        while (/^[^\w]/.test(str)) {
            str = str.slice(1);
        }
    
        // Remove two consecutive special characters
        str = str.replace(/([^\w\s])\1+/g, '$1');
    
        // Trim the input
        str = str.trim();
    
        setReferenceId(str);
    };
    
    
    const setRefType = (e) => {
        setReferenceType(e.target.value);
    }

    useEffect(() => () => {
        complted_files = [];
        all_files = [];
        dispatch(set_raw_message_text(''))
    }, [])

    const raw_message_text = useSelector(state => state.message.raw_message_text)

    useEffect(() => {
        setTimeout(() => {
            const content = raw_message_text.replace(/<\/?[^>]+(>|$)/g, '')
            console.log("🚀 ~ setTimeout ~ content:", content)
            console.log("🚀 ~ setTimeout ~ raw_message_text:", raw_message_text)
            // if (raw_message_text) {
            let contentBlock = htmlToDraft((content.trim() === '') ? `<p>No Comments!</p>` : raw_message_text);
            console.log("🚀 ~ setTimeout ~ contentBlock:", contentBlock)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(EditorState.moveFocusToEnd(editorState));
            }
            // }
        }, 50)
    }, []);


    return (
        <>
            {!tag_popup &&
                <div className={"backwrap relative"}>
                    <div className='fileUploadModal'>
                        <div className="fileUploadModalHead">
                            <h4 className="popupTitle"> Upload file(s) [max. 20 files &amp; 500MB/file] </h4>
                            <span onClick={closePopup} className="closeModal" data-for="rightSection_tooltip" data-tip="Close"></span>
                        </div>
                        <div className="fileUploadModalBody">
                            <div>
                                <label className="file_upload_label" onClick={() => file_ref.current.click()}>Drop file(s) or click here to browse &amp; upload</label>
                                <input type="file" id="files" ref={file_ref} onChange={(e) => uploadFiles(e.target.files)} multiple hidden />
                                <div className="upload_file_container">
                                    {fileDesign}
                                </div>
                                <div className="chat-file-upload-comment" id="file_comment_box">
                                    <div className="fileRefDiv">
                                        <div className="fileRefType">
                                            <label>Select reference type (optional)</label>
                                            <select className="refTitleInput" value={referenceType} onChange={(event) => setRefType(event)}>
                                                <option value="" selected >Select reference type</option>
                                                <option value="SID">Student ID (SID)</option>
                                                <option value="EID">Employee ID (EID)</option>
                                                <option value="ID">No specific ref. (ID)</option>
                                            </select>
                                        </div>
                                        <div className="fileRefBody">
                                            <label>Add a reference ID (optional)</label>
                                            <input type="text" className="refTitleInput" placeholder="Add a reference ID (optional)"
                                                disabled={!referenceType}
                                                style={!referenceType || (referenceType && referenceId) ? {} : { border: '1px solid red' }}
                                                value={referenceId} onChange={(event) => setRefId(event)}
                                                autoFocus={true}
                                                />
                                        </div>
                                    </div>
                                    <label>Write a message/comment </label>
                                    {/* <div className="secretUserList"></div> */}
                                    {/* <div className="relative">
                                        <textarea onChange={(e) => setComment(e.target.value)} value={comment} placeholder='Write a message/comment' className='file_comment_box' type="text" />
                                        <div className="send_msg_opt inInputMsgBox !right-0 !bottom-[58px]">
                                            <div className="!m-0 microphone_audio_on_file" data-for="voice" data-tip="Send a voice message."></div>

                                            <div
                                                onClick={() => setFileCommentEmoji(!fileCommentEmoji)} className={`${!fileCommentEmoji ? 'emojiPlus_file' : 'emojiClose_file'}`}
                                                style={!fileCommentEmoji ? {} : { pointerEvents: 'none' }}

                                                data-for="Insertemoji" data-tip="Insert emoji"></div>

                                        </div>
                                        <div className="voiceIcon1MsgBox !-left-[6px] !-top-[6px]" data-for="top_head" data-tip="Click voice to text. 'send', => message send, 'clear' => clear text, 'Enter' => new line"></div>
                                    </div> */}
                                    <div className="relative">
                                        <Editor
                                            editorRef={(ref) => (editorRef.current = ref)}
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName={wrongText ? "demo-editor file_comment_boxRed" : "demo-editor file_comment_box"}
                                            placeholder={"Write a message/comment"}
                                            mention={{
                                                separator: ' ',
                                                trigger: '@',
                                                suggestions: returnSuggestions()
                                            }}
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                        <div className="send_msg_opt inInputMsgBox !right-0 !bottom-[58px]">
                                            <div className="!m-0 microphone_audio_on_file" data-for="voice" data-tip="Send a voice message."></div>

                                            <div onClick={() => setFileCommentEmoji(!fileCommentEmoji)} className={`${!fileCommentEmoji ? 'emojiPlus_file' : 'emojiClose_file'}`} style={!fileCommentEmoji ? {} : { pointerEvents: 'none' }} data-for="Insertemoji" data-tip="Insert emoji"></div>

                                        </div>
                                        <div className="voiceIcon1MsgBox !-left-[6px] !-top-[6px]" data-for="top_head" data-tip="Click voice to text. 'send', => message send, 'clear' => clear text, 'Enter' => new line"></div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button onClick={closePopup} className="btnCancel" style={{ marginTop: '16px' }}>Cancel</button>

                                    {type === 'private_msg' ?
                                        (complted_files.length === files.length && complted_files.length !== 0) ?
                                            <button className={'uploadbtn'} style={{ background: '#0b1f47' }} onClick={submitFileUpload} >Continue</button>
                                            :
                                            <button className={'uploadbtn'} style={{ background: '#0b1f47' }} onClick={() => checkStatusUpload('skip', 'file')} >Continue</button>
                                        :
                                        <button className={'uploadbtn'} style={(complted_files.length === files.length && complted_files.length !== 0) ? { background: '#0b1f47' } : { pointerEvents: 'none', opacity: '0.6', backgroundColor: '#9a9a9a' }} onClick={submitFileUpload} >Continue</button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        fileCommentEmoji &&
                        <div className="z-50 absolute" ref={ref}>
                            <Picker
                                perLine={9}
                                set={'Apple'}
                                theme="light"
                                data={data}
                                previewPosition="none"
                                onEmojiSelect={console.log} />
                        </div>
                    }
                </div>
            }
            {tag_popup && <TagPopup setTag_popup={setTag_popup} checkStatusUpload={checkStatusUpload} type={type} viewType={'all'} tag_list={[]} />}
        </>

    )
}