import { HiArrowSmUp } from "react-icons/hi";
import { set_aiCompletedFiles, set_messages, setBooleanState } from "../../redux/message";
import { CgAttachment } from "react-icons/cg";
import { aesEncrypt, fileExtension, generateImage, generateText, generateTextStream, modifyMyMsg, mongoObjectId, upload_obj } from "../../Utils/Common";
import { TfiClose } from "react-icons/tfi";
import { useMutation } from "@apollo/client";
import { Save_workfreeliai, sendMsgMutation } from "../../Utils/GraphqlMutaion";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const AISendBox = (props) => {
    const textareaRef = useRef(null);
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    // const [ai_files, set_ai_files] = useState(null);
    const { user, active_conversation, reply_main_msg, aiCompletedFiles, aiFilesLoader } = useSelector(state => state.message)
    const messages = useSelector((state) => !params.msg_id ? state.message.msgs : state.message.reply_msgs)
    const { setResponse, setMsgscreen, setRmsgscreen, scrollToBottom, response, set_active_ques, active_ques, set_all_ques, set_chunk_img_loader, setInitialLoader, initialLoader, handleStream, set_ai_msg_loader } = props.ai_props || {};
    // const [completed_files, set_completed_files] = useState([])
    const file_ref = useRef(null);

    const handleChange = (e) => {
        setMessage(e.target.value);

        // Auto-adjust height
        textareaRef.current.style.height = "auto"; // Reset height to calculate new height
        textareaRef.current.style.height = `${Math.max(
            45, // Minimum height
            Math.min(textareaRef.current.scrollHeight, 300) // Max height is 300px
        )}px`;
    };

    const [save_ai_res] = useMutation(Save_workfreeliai)
    const saveAIResInDB = async (fields) => {
        let apires = await save_ai_res({
            variables: {
                input: { ...fields }
            }
        })
        console.log("🚀 ~ saveAIResInDB ~ apires:", apires)
    }

    const handleSendAi = async () => {
        // debugger;
        if (message.trim() !== "") {
            let root_id = active_ques ? active_ques?._id : '';
            let q = message;
            setMessage(""); // Clear textarea after sending
            textareaRef.current.style.height = "45px"; // Reset to minimum height after sending
            try {
                setInitialLoader(true);

                if (root_id === '') {
                    setResponse([]);
                    setMsgscreen(false);
                    setRmsgscreen(true);
                }

                setTimeout(() => {
                    scrollToBottom();
                }, 100);

                const history = response?.slice(-5).flatMap(item => [
                    { role: "user", content: item.ques },
                    { role: "assistant", content: item.ans },
                ]);
                console.log("🚀 ~ handleSend ~ history:", history)

                let ni = await generateText("You are a decision-making assistant. If a question requires a visual representation (e.g., an image, chart, or diagram), respond with 'needs image'. Otherwise, respond with 'does not need image'.", q);
                // debugger
                const commonFields = {
                    _id: mongoObjectId(),
                    user_id: user.id,
                    ques: q,
                    ans: "",
                    res: true,
                    like: false,
                    dislike: false,
                    root_id: root_id,
                    created_at: new Date().getTime().toString(), //new Date().toISOString(),
                };
                console.log("🚀 ~ handleSendAi ~ commonFields:", commonFields)
                setResponse(prev => [...prev, commonFields]);
                if (root_id === '') {
                    set_active_ques(commonFields);
                    set_all_ques(prev => [...prev, commonFields]);
                }
                // Choose appropriate API based on the content
                let b;
                if (ni === 'needs image') {
                    set_chunk_img_loader(true)

                    setTimeout(() => {
                        scrollToBottom();
                    }, 100);

                    b = await generateImage(q);
                    let img = `<div class="genarated_img_wraper"><span class="download_ai_img"></span><img src="${b}" class="genarated_img" /></div>`;
                    setResponse((prev) =>
                        prev.map((item) =>
                            item._id === commonFields._id ? { ...item, ans: img } : item
                        )
                    );
                    setTimeout(function () {
                        saveAIResInDB({ ...commonFields, ans: img, image: b })
                    }, 1000)
                    set_chunk_img_loader(false)
                } else {
                    b = await generateTextStream(commonFields, q, handleStream);
                    setTimeout(function () {
                        saveAIResInDB({ ...commonFields, ans: b })
                    }, 1000)
                }
            } catch (error) {
                console.log(error);
            }

            setInitialLoader(false);
        }
    };

    const [send_msg_input] = useMutation(sendMsgMutation);
    const handleSend = async () => {
        let sendfiledata = [];
        for (let i of aiCompletedFiles) {
            sendfiledata.push(i);
        }

        if (message.trim() !== "") {
            // Create message payload
            const json_data = {
                conversation_id: params.conversation_id,
                company_id: user.company_id,
                sender: user.id,
                msg_body: aesEncrypt(message.trim()),
                participants: params.msg_id ? reply_main_msg?.participants : active_conversation?.participants,
                is_reply_msg: params.msg_id ? 'yes' : 'no',
                reply_for_msgid: params.msg_id ? params.msg_id : '',
                msg_type: sendfiledata.length > 0 ? 'attachment aiReplyReq' : 'text aiReplyReq',
                ai_reply: true
            };

            /**
             * Do it by mahfuz
             */
            // debugger
            if (sendfiledata.length) {
                let imgfile = [];
                let audiofile = [];
                let videofile = [];
                let otherfile = [];
                let all_attachment = {};

                let allfiles = aiCompletedFiles; //complted_files;

                for (let f of aiCompletedFiles) { //complted_files
                    // console.log(97, f);
                    var mime = f.mimetype;
                    if (mime.indexOf('image') !== -1)
                        imgfile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('video') !== -1)
                        videofile.push(f.bucket + '/' + f.key);
                    else if (mime.indexOf('audio') !== -1)
                        audiofile.push(f.bucket + '/' + f.key);
                    else
                        otherfile.push(f.bucket + '/' + f.key);
                }
                all_attachment = {
                    imgfile: imgfile,
                    audiofile: audiofile,
                    videofile: videofile,
                    otherfile: otherfile,
                    allfiles: allfiles.map(v => ({
                        originalname: v.originalname,
                        mimetype: v.mimetype,
                        voriginalName: v.voriginalName,
                        size: v.size,
                        bucket: v.bucket,
                        key: v.key,
                        acl: v.acl,
                        localPath: v.localFile.path,
                    })),
                }
                console.log(256, json_data);
                json_data['attach_files'] = all_attachment;
                json_data['all_attachment'] = allfiles.map(v => ({ tag_list: [], has_tag: "" }));
                console.log(259, json_data);
            }
            /**
             * end mahfuz work
             */
            // Send message and update state

            setMessage('')
            // debugger
            const send_msg_data = await send_msg_input({ variables: { input: json_data } });

            const dycrepetMsg = await modifyMyMsg([send_msg_data.data.send_msg.msg]);
            console.log("🚀  ~ dycrepetMsg:", dycrepetMsg)
            if (!params.msg_id) {
                navigate('reply/' + dycrepetMsg?.[0]?.msg_id)
            }
            dispatch(set_messages({
                data: [...messages, ...dycrepetMsg],
                is_reply_msg: params.msg_id ? 'yes' : 'no',
                created_at: new Date().toISOString(),
                my_new_msg: true
            }));
            dispatch(setBooleanState({ key: 'ai_res_waiting', data: true }))

            // Scroll to bottom
            // setTimeout(() => {
            //     scrollToBottom('main_msg_scroll_div');
            // }, 300);

            resetFiles();
            textareaRef.current.focus()
        }
    };

    const handleSendMethod = (e) => {
        e.preventDefault();
        if (props.type === 'ai') {
            handleSendAi();
        } else {
            handleSend();
        }
    }

    const resetFiles = () => {
        setFiles([]);
        // set_ai_files(null);
        dispatch(set_aiCompletedFiles([]))
        // set_completed_files([]);
    };

    const uploadFiles = async (_f) => {
        dispatch(setBooleanState({ key: 'aiFilesLoader', data: true }))
        // Convert FileList to Array
        const fileArray = Array.from(_f);

        const all_files = [...files]; // Ensure this is defined in your component state
        const bucket_name = user.email.replace("@", "-").replace(/\./g, "-");

        // Prepare file metadata
        fileArray.forEach((file, index) => {
            const sl = moment().format('x') + index;
            Object.assign(file, {
                sl,
                originalname: file.name,
                mimetype: file.type,
                fieldname: 'file_upload',
            });

            const sf = {
                sl,
                file_name: file.name,
                mimetype: file.type,
                file_size: file.size,
                status: 0,
                progress: '0%',
                local_url: URL.createObjectURL(file),
            };

            all_files.push(sf);
        });

        setFiles([...all_files]);

        // Upload files
        for (const file of fileArray) {
            // set_ai_files(file)
            const data = new FormData();
            data.append("bucket_name", bucket_name);
            data.append("sl", file.sl);
            data.append("file_upload", file);
            data.append("ai_reply", true);
            try {
                const response = await upload_obj(data, {
                    onUploadProgress: (progressEvent) => {
                        console.log("🚀 ~ uploadFiles ~ progressEvent:", progressEvent)
                    },
                });

                // Process successful upload
                const completedFile = all_files.find(f => f.sl === response.sl);
                console.log("🚀 ~ uploadFiles ~ completedFile:", completedFile)
                if (completedFile) {
                    const newFile = {
                        ...response.file_info[0],
                        oldname: completedFile.file_name,
                        sl: completedFile.sl,
                        size: response.file_info[0].size,
                    };
                    console.log("🚀 ~ uploadFiles ~ newFile:", newFile)
                    // set_completed_files(prev => [...prev, newFile])
                    dispatch(set_aiCompletedFiles([newFile]))
                }
            } catch (error) {
                console.error("Upload error:", error);
            }
            dispatch(setBooleanState({ key: 'aiFilesLoader', data: false }))
        }
        // debugger


        // Clean up
        file_ref.current.value = null;

        // Revoke all created object URLs
        all_files.forEach(file => URL.revokeObjectURL(file.local_url));
    };

    return (
        <div className={`ai_msg_send ${props.type === 'msg' ? ' relative min-h-[54px] pt-0 pb-0' : ''}`}>
            {(props.type !== 'ai' && !params.msg_id) && <span onClick={() => dispatch(setBooleanState({ key: 'msg_ai_active', data: false }))} className='w-[16px] h-[16px] cursor-pointer absolute left-[15px]'><TfiClose /></span>}
            <div className='ai_send_area'>
                <label className='ai_msg_attach_btn' htmlFor='aiFile' id='aiFileLa'>
                    <input type="file" ref={file_ref} name="file_upload" id="aiFile" onChange={(e) => uploadFiles(e.target.files)} accept='.pdf, image/*' hidden disabled={props.type === 'ai'} />
                    <CgAttachment />
                </label>
                {(aiCompletedFiles?.length > 0 || aiFilesLoader) &&
                    <div className='send_doc_holder'>
                        {!aiFilesLoader &&
                            aiCompletedFiles?.map((v, i) =>
                                <div className='ai_send_doc' key={'ai_' + i}>
                                    <span className='ai_doc_close' onClick={resetFiles}><TfiClose /></span>
                                    {v.mimetype?.includes('image') && !v.mimetype?.includes('svg') ?
                                        <img src={v.location} alt='ai_doc' />
                                        :
                                        <img src={`/media/images/light_theme/${fileExtension(v.oldname)}.svg`} alt='ai_doc' />
                                    }
                                </div>
                            )
                        }
                        {aiFilesLoader &&
                            <div className='ai_send_doc'>
                                <svg className="imgloader" width="35" height="35" viewBox="0 0 50 50">
                                    <circle cx="25" cy="25" r="20"></circle>
                                </svg>
                            </div>
                        }
                    </div>
                }
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleChange}
                    onKeyDown={(e) => (!aiFilesLoader && e.key === "Enter" && !e.shiftKey) && handleSendMethod(e)}
                    placeholder={active_ques ? 'Have any more question about this?' : 'How can I help you?'}
                    readOnly={initialLoader}
                    rows="1"
                    autoFocus
                />
                <button className='ai_msg_send_btn' onClick={handleSend} style={message.trim() ? {} : { pointerEvents: 'none', opacity: '0.5' }} ><HiArrowSmUp /></button>
            </div>
            {props.type === 'ai' && <Tooltip anchorSelect={"#aiFileLa"} float="true" content="Coming soon"></Tooltip>}
        </div>
    )
}

export default AISendBox;