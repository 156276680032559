/* eslint-disable react-hooks/exhaustive-deps */
import { useClickAway } from '@uidotdev/usehooks';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';


function TaskRoomSelect({ setValue, task }) {
    const params = useParams()
    // console.log("🚀 ~ TaskRoomSelect ~ params:", params)
    const conversations = useSelector(state => state.message.conversations);
    const [selectedRoom, setSelectedRoom] = useState(params?.conversation_id ? conversations.filter(f => f.conversation_id === params?.conversation_id)[0] : { title: 'You' });
    const [searchInput, setSearchInput] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const filteredRooms = conversations.filter(room => room.title.toLowerCase().includes(searchInput.toLowerCase()));

    const ref = useClickAway(() => {
        setIsEditing(false);
    });

    const handleRoomSelect = (room) => {
        setSelectedRoom(room);
        setValue((prev => prev.map(v => v.temp_key === task.temp_key ? { ...v, conversation_id: room.conversation_id, participants: room.participants, conversation_name: room.title } : v)))
        setIsEditing(false);
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (event) => {
        if (!isEditing) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredRooms.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredRooms.length) % filteredRooms.length);
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            handleRoomSelect(filteredRooms[highlightedIndex]);
        }
    };

    useEffect(() => {
        if (isEditing) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditing, highlightedIndex, filteredRooms]);

    const changeRoom = () => {
        // () => params?.conversation_id === 'tasks' && 
        if (!params?.conversation_id) {
            setIsEditing(true)
        }
    }

    return (
        <>
            {!isEditing ?
                <div className='flex gap-4 text-[#0079d1] cursor-pointer contentHover' onClick={changeRoom}>
                    <p className='text-sm'>Room: {selectedRoom?.title}</p>
                    <div className='editImage' id='updateRoom'></div>
                </div> :
                <div className='relative' ref={ref}>
                    <div className="border h-[28px] w-[240px] border-gray-300 flex justify-between rounded-[5px] items-center roomTaskproperties">
                        <div className="userDropDownArea">
                            <ul className="usersGroup"></ul>
                            <div className="ellipsis text-sm" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', cursor: 'pointer' }}>
                                <span className="selectOnew">{selectedRoom?.title}</span>
                            </div>
                        </div>
                        <div className="dropdown" style={{ backgroundColor: 'transparent' }}>
                            <div className="dropdown-header">
                                <i className="fa fa-chevron-right icon open" style={{ color: 'black' }}></i>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div className="AddKeywordsPopup_task_settings bg-white border border-gray-300 insightTaskBoard !mt-0 !top-[28px] !w-[240px]">
                        <div className="keywordItem">
                            <div className="searchAndFilterBar" style={{ position: 'relative' }}>
                                <div className="selectedRoomCont !pl-1 !pr-1">
                                    <span className="tags_Color roomColor">Room: {selectedRoom?.title}</span>
                                </div>
                                <div className="searchareaDiv">
                                    <div className='p-2'>
                                        <input className="_inputBar searchLeft" type="text" placeholder="Search Room" value={searchInput} onChange={handleSearchInputChange} />
                                        {searchInput !== '' && <span className='clearInput inv' style={{ top: '30px', right: '-8px' }} onClick={()=>setSearchInput('')}></span>}
                                    </div>

                                    <div className="keyword_list_task_settings workloadUsers">
                                        {filteredRooms.map((room, index) => (
                                            <p
                                                className={`_tag_rooms ${highlightedIndex === index ? 'bg-[#0C1E47] text-white' : ''}`}
                                                key={index}
                                                onClick={() => handleRoomSelect(room)}
                                                tabIndex="0"
                                            >
                                                {room.title}
                                            </p>
                                        ))}
                                        {
                                            filteredRooms.length === 0 && <p className=" text-center border-b border-b-[#e3f2ff] text-sm">No user found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!isEditing && (
                <Tooltip anchorSelect="#updateRoom" place='top' content="Click to update room"></Tooltip>
            )
            }
        </>
    );
}

export default TaskRoomSelect;
