
import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { GET_TEAMS } from '../../../Utils/GraphqlQueries';
import { useMutation, useQuery } from '@apollo/client';
import { TEAMMATE_INVITE } from '../../../Utils/GraphqlMutaion';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-hot-toast';
import { UPDATE_USER_MUTATION } from '../../../Utils/GraphqlMutaion';
import { id } from 'date-fns/locale';
import { InputValidationCheck } from '../../ValidationCheck';


const InviteNewUser = ({ activeTab, setInviteNewUser, popup_type, setall_user_data, all_user_data, onClose, selectedEditUser }) => {
    // console.log(14, all_user_data);

    const { data: teamsData } = useQuery(GET_TEAMS);
    const [loader, setLoader] = useState(false);
    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [lastNameTouched, setLastNameTouched] = useState(false);
    const [teamOptions, setTeamOptions] = useState([]);
    const [inviteUser] = useMutation(TEAMMATE_INVITE);
    const [update] = useMutation(UPDATE_USER_MUTATION);

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        emailError: '',
        passwordMatchError: '',
        showPassword: false,
        showConfirmPassword: false,
        selectedTeam: null,
    });

    useEffect(() => {
        if (selectedEditUser) {
            setFormState(prevState => ({
                ...prevState,
                firstName: selectedEditUser.firstname || '',
                lastName: selectedEditUser.lastname || '',
                email: selectedEditUser.email || '',
                phoneNumber: Array.isArray(selectedEditUser.phone) ? selectedEditUser.phone[0] : selectedEditUser.phone || '91',
            }));
        }
    }, [selectedEditUser]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setInviteNewUser(false);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     const trimmedValue = value.trimStart();

    //     if (name === 'firstName' && trimmedValue.length === 0) {
    //         console.warn('Input should not be empty or only spaces');
    //     }

    //     setFormState(prevState => ({
    //         ...prevState,
    //         [name]: trimmedValue
    //     }));
    // };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Always update the state, even if the value is empty
        const trimmedValue = value.trimStart();

        // Skip validation for password and confirmPassword fields
        if (name === 'password' || name === 'confirmPassword') {
            // Handle password match validation only for confirmPassword
            if (name === 'confirmPassword' && trimmedValue !== formState.password && trimmedValue !== '') {
                setFormState(prevState => ({
                    ...prevState,
                    passwordMatchError: 'Passwords do not match',
                }));
            } else {
                setFormState(prevState => ({
                    ...prevState,
                    passwordMatchError: '',
                }));
            }

            // Update state with the current value
            setFormState(prevState => ({
                ...prevState,
                [name]: trimmedValue,
            }));

            return; // Exit early since no further validation is needed
        }

        // Sanitize and validate other inputs
        const isNumber = name === 'phoneNumber';
        if (trimmedValue && InputValidationCheck(trimmedValue, isNumber)) {
            setFormState(prevState => ({
                ...prevState,
                [`${name}Error`]: `Invalid input for ${name}`,
            }));
            return;
        }

        // Clear errors for valid input
        setFormState(prevState => ({
            ...prevState,
            [`${name}Error`]: '',
        }));

        // Specific validation for email
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (trimmedValue && !emailRegex.test(trimmedValue)) {
                setFormState(prevState => ({
                    ...prevState,
                    emailError: 'Invalid email format',
                }));
            } else {
                setFormState(prevState => ({
                    ...prevState,
                    emailError: '',
                }));
            }
        }

        // Update state with the current value
        setFormState(prevState => ({
            ...prevState,
            [name]: trimmedValue,
        }));
    };



    const handlePasswordVisibility = (field) => {
        setFormState(prevState => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const { firstName, lastName, email, company, phoneNumber, password, confirmPassword, selectedTeam, emailError, passwordMatchError, showPassword, showConfirmPassword } = formState;

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const emailExists = all_user_data.filter(v => v.email).map(v => v.email)

    // console.log("emailExists", emailExists)

    useEffect(() => {
        // const emailExistsList = all_user_data.filter(v => v.email).map(v => v.email)

        if (!validateEmail(email)) {
            setFormState(prevState => ({ ...prevState, emailError: 'Invalid email address.' }));
            // console.log('Invalid email address.');
        }
        else if (emailExists.includes(email)) {
            setFormState(prevState => ({ ...prevState, emailError: 'This email address has already been used.' }));
            // console.log('This email address has already been used.');
        }
        else {
            setFormState(prevState => ({ ...prevState, emailError: '' }));
            // console.log('Valid email address.');
        }
    }, [email]);


    const handleInviteUser = async () => {
        // debugger;

        try {
            let inputdata = {
                firstname: firstName,
                lastname: lastName,
                emails: email,
                phone: phoneNumber,
                role: 'Member',
                team_list: selectedTeam ? selectedTeam.map((t) => t.value) : [],
                password_req: 'no',
            };
            if (popup_type === 'create') {
                if (password.length > 0) {
                    inputdata = {
                        ...inputdata,
                        password_req: 'yes',
                        password: password,
                        password2: confirmPassword,
                    };
                }
            }
            if (popup_type === 'contact') {
                inputdata = {
                    ...inputdata,
                    phone: phoneNumber,
                    role: 'Recipient',
                    company: company
                };
            }
            if (popup_type === 'guest') {
                inputdata = {
                    ...inputdata,
                    phone: phoneNumber,
                    role: 'Guest',
                };
            }

            let data = await inviteUser({
                variables: {
                    input: {
                        ...inputdata,
                    },
                },
            });
            // console.log(data.data.teammate_invite.data)
            setall_user_data(prev => ([...data.data.teammate_invite.data, ...prev]))
            setTimeout(() => {
                setLoader(false);
                if (data.data.teammate_invite.data.length === 0) {
                    toast.error("This company users added limit exceeded.", {
                        style: { maxWidth: "450px" }
                    });
                } else {
                    toast.success("User successfully created");
                }
                
                onClose();
            }, 500);
        } catch (error) {
            setLoader(false);
            toast.error('Error occurred while creating user');
            onClose();
        }
    };

    const handleUpdateUser = async () => {
        try {
            const { firstName, lastName, phoneNumber } = formState;
            const inputData = { user_id: selectedEditUser.id, firstname: firstName, lastname: lastName, phone: phoneNumber };
            const { data } = await update({ variables: { input: inputData } });
            if (data?.update_user?.status) {
                toast.success('User successfully updated');
                setall_user_data(prev => {
                    return prev.map(user =>
                        user.id === selectedEditUser.id ? { ...user, ...inputData } : user
                    );
                });
                onClose();
            } else {
                toast.error('Error occurred while updating user');
            }
        } catch (error) {
            toast.error('Error occurred while updating user');
            onClose();
        }
    };

    useEffect(() => {
        if (teamsData?.teams) {
            let team = [];
            for (const iterator of teamsData.teams) {
                team.push({ value: iterator.team_id, label: iterator.team_title });
            }
            setTeamOptions(team);
        }
    }, [teamsData]);

    const [info, setInfo] = useState('');

    const URL = "https://api.db-ip.com/v2/free/self";

    useEffect(() => {
        const fetchGeolocation = async () => {
            try {
                const response = await fetch(URL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setInfo(data.countryCode.toLowerCase());
            } catch (error) {
                console.error('Error fetching geolocation data:', error);
            }
        };

        fetchGeolocation();
    }, []);

    useEffect(() => {
        if (password !== confirmPassword) {
            setFormState(prevState => ({ ...prevState, passwordMatchError: 'Passwords do not match.' }));
        } else {
            setFormState(prevState => ({ ...prevState, passwordMatchError: '' }));
        }
    }, [password, confirmPassword]);

    const isUpdateButtonActive = firstName !== selectedEditUser?.firstname || lastName !== selectedEditUser?.lastname || email !== selectedEditUser?.email || phoneNumber !== selectedEditUser.phone[0]

    return (
        <>
            <div className="backwrap">
                <div className="createUserModal">
                    <div className="createUserModalHead">
                        <h4 className="popupTitle">{popup_type === 'invite' ? 'Invite a new user' : popup_type === 'create' ? 'Create new user' : popup_type === 'guest' ? 'Invite a new guest' : popup_type === 'contact' ? 'Create a contact' : popup_type === 'editPopup' ? 'Edit user' : ''}</h4>
                        <span className="closeModal" onClick={onClose}></span>
                    </div>
                    <div className="createUserModalBody !pb-2">
                        <div className="createUserFormContainer">
                            <form className="createUserFrom">
                                <div className="cu_doubleInputGroup">
                                    <div className="cu_inputGroup !mb-0">
                                        <label>First name<span className="red_star">*</span></label>
                                        <input
                                            className="firstName"
                                            placeholder="First name"
                                            type="text"
                                            name="firstName"
                                            maxLength="25"
                                            value={firstName}
                                            onChange={handleInputChange}
                                            onFocus={() => setFirstNameTouched(true)}
                                        />

                                        {(firstName.trim() === '' && firstNameTouched) && <span className="text-red-500 text-xs" style={{ position: 'relative' }}>First name should not be empty</span>}

                                    </div>
                                    <div className="cu_inputGroup !mb-0">
                                        <label>Last name<span className="red_star">*</span></label>
                                        <input
                                            type="text"
                                            placeholder="Last name"
                                            name="lastName"
                                            maxLength="25"
                                            value={lastName}
                                            onChange={handleInputChange}
                                            onFocus={() => setLastNameTouched(true)}
                                        />
                                        {
                                            (lastName.trim() === '' && lastNameTouched) && <span className="text-red-500 text-xs" style={{ position: 'relative' }}>Last name should not be empty</span>
                                        }
                                    </div>
                                </div>
                                <div className="cu_inputGroup !mb-0 mt-3">
                                    <label>Email<span className="red_star">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Enter email address"
                                        name="email"
                                        disabled={popup_type === 'editPopup'}
                                        value={email}
                                        onChange={handleInputChange}
                                    />
                                    {
                                        popup_type !== 'editPopup' && email.length > 0 && <span className="text-red-500 text-xs !relative">{emailError}</span>
                                    }
                                </div>
                                {popup_type === 'create' &&
                                    <>
                                        <div className="passInputGroup newCreateUser mt-3" style={{ marginBottom: '0px' }}>
                                            <label className="passInputLabel">New password<span className="red_star">*</span></label>
                                            <span className="passwordLock"></span>
                                            <span className={`passwordView ${showPassword ? 'active' : ''}`} data-for="loginTooltip" data-tip="Click to view the password as plain text" onClick={() => handlePasswordVisibility('showPassword')} >
                                            </span>
                                            <input
                                                className="passInput"
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={password}
                                                onChange={handleInputChange}
                                            />
                                            <span className="rgInputMsg errorLabel">Invalid password.</span>
                                            {
                                                password.length <= 0 && <span className="passwordPlaceholderNew">......</span>
                                            }
                                        </div>
                                        <div className="passInputGroup newCreateUser" style={{ marginBottom: '0px' }}>
                                            <label className="passInputLabel">Confirm password<span className="red_star">*</span></label>
                                            <span className="passwordLock"></span>
                                            <span className={`passwordView ${showConfirmPassword ? 'active' : ''}`} data-for="loginTooltip" data-tip="Click to view the password as plain text" onClick={() => handlePasswordVisibility('showConfirmPassword')}>
                                            </span>
                                            <input
                                                className="passInput !mb-0"
                                                id="password2"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={handleInputChange}
                                            />
                                            {/* <span className="rgInputMsg" style={{ color: 'red' }}></span> */}
                                            {
                                                confirmPassword.length > 0 && <p className="text-red-500 text-xs !-mt-1" style={{ position: 'relative' }}>{passwordMatchError}</p>
                                            }
                                            {
                                                confirmPassword.length <= 0 && <span className="passwordPlaceholderNew">......</span>
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    popup_type === "create" ? "" :
                                        <div className="inputGroup inTeam mt-2 !pr-0">
                                            <label className="inputLabel !pb-1">Phone number</label>
                                            <PhoneInput
                                                inputStyle={{ width: '100%', height: '50px', border: '1px solid #d6d6d6', borderRadius: '4px', padding: '8px' }}
                                                country={info}
                                                value={phoneNumber}
                                                onChange={(value) => setFormState(prevState => ({ ...prevState, phoneNumber: value }))}
                                            />
                                        </div>
                                }


                                {popup_type === "contact" ?
                                    <div className="cu_inputGroup !mb-0 mt-3">
                                        <label>Company name</label>
                                        <input
                                            placeholder='Enter company name'
                                            type="text"
                                            name="company"
                                            value={company}
                                            onChange={handleInputChange}
                                        />
                                    </div> : popup_type === "editPopup" ? "" :
                                        <div className="cu_inputGroup !mb-0 cu_inputGroup_select mt-3">
                                            <label>Team</label>
                                            <Select
                                                className="select-ecosystem user_manage"
                                                value={selectedTeam}
                                                onChange={(value) => setFormState(prevState => ({ ...prevState, selectedTeam: value }))}
                                                options={teamsData?.teams?.map(t => ({ value: t.team_id, label: t.team_title })) || []}
                                                isMulti
                                                menuPlacement="top"
                                                placeholder="Assign to specific Team(s) or leave empty for all"
                                            />
                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="createUserModalFoot !pb-5">
                        <button className="btnCancel" onClick={onClose}>Cancel</button>
                        {loader ?
                            <button className="btnAction btn_loader" style={{ 'color': 'transparent' }}>Create User</button>
                            :
                            popup_type === 'contact' ?
                                <button className="btnAction" style={!firstName || !lastName || !email ? { opacity: '0.5', cursor: 'not-allowed' } : {}} onClick={handleInviteUser} disabled={!firstName || !lastName || !email}>Create a contact user</button>
                                :
                                popup_type === 'guest' ?
                                    <button className="btnAction" style={!firstName || !lastName || !email || emailError ? { opacity: '0.5', cursor: 'not-allowed' } : {}} onClick={handleInviteUser} disabled={!firstName || !lastName || !email || emailError}>Create a guest user</button> :
                                    popup_type === 'editPopup' ?
                                        <>
                                            <button onClick={handleUpdateUser} style={!isUpdateButtonActive ? { opacity: '0.5', cursor: 'not-allowed' } : {}} className="btnAction">Update user</button>
                                        </>
                                        :
                                        popup_type === 'invite' ?
                                            <button className="btnAction" style={!firstName || !lastName || !email || emailError ? { opacity: '0.5', cursor: 'not-allowed' } : {}} onClick={handleInviteUser}>Invite user</button> :
                                            <button className="btnAction" style={!firstName || !lastName || !email || emailError || !password || !confirmPassword || passwordMatchError ? { opacity: '0.5', cursor: 'not-allowed' } : {}} onClick={handleInviteUser}>Create user</button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default InviteNewUser;