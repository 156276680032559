import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    eod_venue_ids: [''],
    venueFilter: false,
    dateFilter: false,
    selected_venue_id: null,
    selected_venue_title: "",
    report_list: [],
    venue_list: [],
    report_one_update: "",
}

export const eod = createSlice({
    name: 'eod',
    initialState,
    reducers: {
        set_venue_ids: (state, action) => {
            return {
                ...state,
                eod_venue_ids: [...action.payload],
            }
        },
        setVenueFilter: (state, action) => {
            return {
                ...state,
                venueFilter: [...action.payload],
            }
        },
        setDateFilter: (state, action) => {
            return {
                ...state,
                dateFilter: [...action.payload],
            }
        },
        set_selected_venue_id: (state, action) => {
            return {
                ...state,
                selected_venue_id: action.payload,
            }
        },
        set_selected_venue_title: (state, action) => {
            return {
                ...state,
                selected_venue_title: action.payload,
            }
        },
        set_venue_list: (state, action) => {
            return {
                ...state,
                venue_list: action.payload,
            }
        },
        set_report_list: (state, action) => {
            return {
                ...state,
                report_list: action.payload
            }

        },
        update_report_list: (state, action) => {
            const existingReportIndex = state.report_list.findIndex(report => report._id === action.payload._id);
            return {
                ...state,
                report_one_update: action.payload,
                report_list: existingReportIndex >= 0
                    ? [
                        ...state.report_list.slice(0, existingReportIndex),
                        { ...state.report_list[existingReportIndex], ...action.payload },
                        ...state.report_list.slice(existingReportIndex + 1)
                    ]
                    : [action.payload, ...state.report_list]
                // report_total_count: existingReportIndex === -1
                //     ? state.report_total_count + 1
                //     : state.report_total_count
            };

        }
        
       
        
    },
})

export const {  set_venue_ids, set_selected_venue_id, set_selected_venue_title, set_report_list, set_venue_list, update_report_list} = eod.actions
export default eod.reducer