/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import classNames from "classnames";
import { BsXLg, BsArrowRight, BsStarFill } from "react-icons/bs";
import { MdFolder } from "react-icons/md";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Toaster, toast } from "react-hot-toast";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { BiReset } from "react-icons/bi";
import { VscCalendar } from "react-icons/vsc";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fileExtension } from "../../Utils/Common";
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/client'
import { GET_ALL_USERS, GET_ME, HUB_ALL_FILES, GET_TAGS } from '../../Utils/GraphqlQueries';
import { ATTACHMENT_STAR_MUTATION } from "../../Utils/GraphqlMutaion";
import { useParams, useSearchParams } from "react-router-dom";
import { setPopup, set_action_file, set_all_tags, set_downloading_file, set_downloading_progress, set_global_action_data, set_related_tag_popup, set_selected_tag, set_share_link_popup, set_view_tag_files, set_selected_files_data, set_multi_file_ids } from "../../redux/message";

import Axios from "axios";
import WarningPopup from "../Connect/WarningPopup";
import { useMutation } from "@apollo/client";
import { Tooltip } from "react-tooltip";
import ImgPreviewPopup from '../Connect/Messages/ImgPreviewPopup';
import PlayerPopup from '../Connect/Messages/PlayerPopup';
import FloatingActionFiles from "./FloatingActionFiles";
import { VariableSizeList as List } from "react-window";
import OutsideClickHandler from "react-outside-click-handler";
const FileDownload = require('js-file-download');

function Files(props) {
  // console.log("Files:props:",props);
  const params = useParams();
  const [preview_data, setPreview_data] = useState(null);
  const [video_data, setVideo_data] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()
  // console.log("🚀 ~ Files ~ searchParams:", searchParams.get('file_type'))
  const client = useApolloClient();
  const cacheMe = client.readQuery({ query: GET_ME });
  const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: cacheMe.me.company_id } });
  const view_tag_files = useSelector(state => state.message.view_tag_files)
  const [resetCounter, setResetCounter] = useState(0);
  const [uploaderListPopup, setuploaderListPopup] = useState(false);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [Uploader_list, setUploader_list] = useState([]);
  const [searchByUploaderVal, setsearchByUploaderVal] = useState("");
  const [roomListPopup, setRoomListPopup] = useState(false);
  const [tagListPopup, setTagListPopup] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(params.conversation_id ? [params.conversation_id] : []);
  const [markIteam, setMarkIteam] = useState([]);
  const conversations = useSelector(state => state.message.conversations)
  const downloading_progress = useSelector(state => state.message.downloading_progress)
  const active_conversation = useSelector(state => state.message.active_conversation)
  const [roomList, setRoomList] = useState([])
  const [selectedTags, setSelectedTags] = useState([]);
  const [tag_list, setTag_list] = useState([]);
  const [searchByRoomVal, setSearchByRoomVal] = useState("");
  const [searchByTagVal, setSearchByTagVal] = useState("");
  const [initialLoader, setInitialLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [page, setPage] = useState(1); // Track current page for pagination
  // const [loader, setLoader] = useState(true);
  const [api_data, setApi_data] = useState([]); //console.log('api_data:files:',api_data)
  const [pagination, setPagination] = useState([]);
  const [summary, setSummary] = useState({ total: 0, image: 0, audio: 0, video: 0, other: 0, voice: 0, share: 0, conversation: [], tags: [] });

  const [prevloader, setPrevLoader] = useState(false);
  const isInitialMount = useRef(true);

  // const [files, setFiles] = useState([]);
  const [input_val, setInputVal] = useState("");
  const [is_advance, setIs_advance] = useState(true);
  const [activesortCol, setActivesortCol] = useState("originalname");
  const [activeOrdering, setActiveOrdering] = useState("asce");
  const options = [
    { label: "All file(s)", value: "all", customeName: "All file(s)" },
    { label: "Doc(s)", value: "docs", customeName: "Doc(s)" },
    { label: "Image(s)", value: "image", customeName: "Image(s)" },
    { label: "Voice(s)", value: "voice", customeName: "Voice(s)" },
    { label: "Audio(s)", value: "audio", customeName: "Audio(s)" },
    { label: "Video(s)", value: "video", customeName: "Video(s)" },
    // { label: "Shared by URL(s)", value: "share", customeName: "Shared by URL(s)", }
  ];
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedTagsIndex, setSelectedTagsIndex] = useState(-1);
  const [petchLoading, setpetchLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const years = range(2000, getYear(new Date()) + 10, 1);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];
  const [expandTotal] = useState(true);
  const [expandTotalLoader, setExpandTotalLoader] = useState(false);
  const containerRef = useRef();

  const [file_type, setFile_type] = useState(props.type === 'tag_files' ? 'all' : searchParams.get('file_type') ? searchParams.get('file_type') : 'all');
  const [file_sub_type, setFile_sub_type] = useState('');
  const [floatingAction, setFloatingAction] = useState(false);
  

  useEffect(() => {
    // console.log('conversations:', conversations)
    setRoomList(conversations)
  }, [conversations])

  // useEffect(() => {
    
  //   // setSelectedTags(view_tag_files.tag_ids)
  //   // get_files()
  //   filter_files('filter', 1)
  // }, [view_tag_files])

  useEffect(() => {
    if(triggerAPI){
      filter_files('filter', 1);
      setTriggerAPI(false);
    }

  },[triggerAPI])

  useEffect(() => {
    if (active_conversation) {
      let user_data = cacheAllUsers?.users?.filter(f => active_conversation?.participants?.indexOf(f.id) > -1);
      // console.log("🚀 ~ useEffect ~ user_data:", user_data)
      setUploader_list(user_data)
    } else {
      setUploader_list(cacheAllUsers.users)
    }
  }, [active_conversation])

  const sorting = (e, type, class_name) => {
    // debugger
    let actionType = class_name === "desc" ? true : false;
    setActivesortCol(type);
    if (actionType) {
      setActiveOrdering("asce");
      if (type === "file_size") {
        setApi_data([...api_data.sort((a, b) => parseFloat(a[type]) - parseFloat(b[type]))]);
      } else {
        setApi_data([...api_data.sort((a, b) => a[type].toUpperCase() > b[type].toUpperCase() ? 1 : -1)]);
      };
    } else {
      setActiveOrdering("desc");
      if (type === "file_size") {
        setApi_data([...api_data.sort((a, b) => parseFloat(b[type]) - parseFloat(a[type]))]);
      } else {
        setApi_data([...api_data.sort((a, b) => a[type].toUpperCase() < b[type].toUpperCase() ? 1 : -1)]);
      }
    }
  }

  const searchByRoom = (v) => {
    setSearchByRoomVal(v);
    if (v !== "") {
      setRoomListPopup(true);
    } else {
      setRoomListPopup(false);
    }
  };

  useEffect(() => {
    if (roomList.length === 0) return
    if (searchByRoomVal === '') {
      setRoomList(roomList.map(v => searchByRoomVal === '' ? { ...v, l_show: true } : v))
    } else {
      setRoomList(roomList.map(v => (v.title.toLowerCase().indexOf(searchByRoomVal.toLowerCase()) > -1) ? { ...v, l_show: true } : { ...v, l_show: false }))
    }
  }, [searchByRoomVal])

  const searchByTag = (v) => {
    setSearchByTagVal(v);
    if (v !== "") {
      setTagListPopup(true);
    } else {
      setTagListPopup(false);
    }
  };

  useEffect(() => {
    if (tag_list.length === 0) return
    if (searchByTagVal === '') {
      setTag_list(tag_list.map(v => searchByTagVal === '' ? { ...v, l_show: true } : v))
    } else {
      setTag_list(tag_list.map(v => (v.title.toLowerCase().indexOf(searchByTagVal.toLowerCase()) > -1) ? { ...v, l_show: true } : { ...v, l_show: false }))
    }
  }, [searchByTagVal]);

  const selectRooms = (v) => {
    setSearchByRoomVal("");
    setRoomListPopup(false);
    setSelectedRooms([...selectedRooms, v.conversation_id]);
  };

  useEffect(()=>{
    // console.log('filter__tab:selectedRooms',selectedRooms);

  },[selectedRooms])

  const selectTags = (v) => {
    // debugger;
    // if (view_tag_files.state) {
    //   dispatch(set_selected_tag(v))
    //   dispatch(set_related_tag_popup(true))
    // } else {
      setSearchByTagVal("");
      setTagListPopup(false);
      setSelectedTags([...selectedTags, v.tag_id]);
    // }
  };

  const clear_tag = (v) => {
    if (view_tag_files.state) {
      dispatch(set_view_tag_files({ state: false, tag_ids: [] }));
      // setTriggerAPI(true);
    } else {
      setSelectedTags(selectedTags.filter(r => r !== v.tag_id));
    }
  }

  const showHoverItemHandler = (i) => {
    setSelectedItemIndex(i);
  };

  const showHoverTagsHandler = (i) => {
    setSelectedTagsIndex(i);
  };

  const hideHoverTagsHandler = () => {
    setSelectedTagsIndex(-1);
  };

  const resetFun = () => {
    setError1(false);
    setError2(false);
    setSearchByRoomVal("");
    setSearchByTagVal("");
    setsearchByUploaderVal("");
    setInputVal("");
    setSelectedRooms(params.conversation_id ? [params.conversation_id] : []);
    setSelectedTags([]);
    setSelectedCreators([]);
    setStartDate("");
    setEndDate("");
    setResetCounter(resetCounter + 1);
  };

  const searchByUploader = (v) => {
    setsearchByUploaderVal(v);
    if (v !== "") {
      setuploaderListPopup(true);
    } else {
      setuploaderListPopup(false);
    }
  };

  useEffect(() => {
    if (Uploader_list.length === 0) return
    if (searchByUploaderVal === '') {
      setUploader_list(Uploader_list.map(v => searchByUploaderVal === '' ? { ...v, l_show: true } : v))
    } else {
      setUploader_list(Uploader_list.map(v => ((v.firstname + ' ' + v.lastname).toLowerCase().indexOf(searchByUploaderVal.toLowerCase()) > -1) ? { ...v, l_show: true } : { ...v, l_show: false }))
    }
  }, [searchByUploaderVal]);

  const selectCreate = (v) => {
    setsearchByUploaderVal("");
    setuploaderListPopup(false);
    setSelectedCreators([v.id]);
  };

  useEffect(() => {
    setpetchLoading(true);
  }, [resetCounter]);


  useEffect(() => {
    // debugger;
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    
    if (!params.conversation_id) {
      if (startDate === "" && endDate === "" && input_val === "" && searchByRoomVal === "" && searchByTagVal === "" && selectedRooms.length === 0 && selectedTags.length === 0) {
        setButtonStatus(false);
        filter_files('filter', 1)
      } else {
        setButtonStatus(true);
      }
    } else {
      if (startDate === "" && endDate === "" && input_val === "" && searchByRoomVal === "" && searchByTagVal === "" && selectedTags.length === 0 && selectedCreators.length < 1) {
        setButtonStatus(false);
        filter_files('filter', 1)
      } else {
        setButtonStatus(true);
      }
    }

  }, [startDate, endDate, input_val, searchByRoomVal, searchByTagVal, selectedTags, selectedRooms, selectedCreators, petchLoading, resetCounter]);

  const keyPress = useCallback((event) => {
    if (event.key === "Escape") {
      setRoomListPopup(false);
      setuploaderListPopup(false);
      setTagListPopup(false);
      setOpen(false);
      setSelectedItemIndex(-1);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false);
    return () => {
      document.removeEventListener("keydown", keyPress, false);
    };
  }, [keyPress]);

  const BlurEffect = (type) => {
    setTimeout(() => {
      if (type === "searchByRoom") {
        setRoomListPopup(false);
      }

      if (type === "searchByTag") {
        setTagListPopup(false);
      }
      if (type === "searchByUploaderVal") {
        setuploaderListPopup(false);
      }
    }, 500);
  };

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const ref = useClickAway(() => { setOpen(false) });
  const ref2 = useClickAway(() => { setRoomListPopup(false) });
  const ref3 = useClickAway(() => { setuploaderListPopup(false) });
  const ref4 = useClickAway(() => { setTagListPopup(false) });

  const dispatch = useDispatch()
  const user = cacheMe.me;
  const change_file_data = useSelector(state => state.message.change_file_data)

  const getItemSize = (index) => {
    const row = document.getElementById(api_data[index].id + "_file_id");
    // console.log("getItemSize", row)
    return row ? row.offsetHeight : 65; // Default height if the element is not found
  };
  useEffect(() => {
    if (searchParams.get('file_type')) {
      setFile_type(searchParams.get('file_type'));
    }
  }, [searchParams])

  const handleItemClick = (value) => {
    if (props.type === 'tag_files') {
      setFile_type(value);
    } else {
      if (value !== 'share') {
        setSearchParams({ file_type: value })
      } else {
        setFile_sub_type(value)
      }
    }
    setOpen(false);
  };

  const handleFileType = (type) => {
    if (props.type === 'tag_files') {
      setFile_type(type);
    } else {
      setSearchParams({ file_type: type })
    }
  }

  const { data: all_tags_data } = useQuery(GET_TAGS, { variables: { company_id: cacheMe.me.company_id, conversation_id: params.conversation_id ? params.conversation_id : '' }, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (all_tags_data) {
      let all_tags = [...all_tags_data.tags.private, ...all_tags_data.tags.public]
      dispatch(set_all_tags(all_tags))
      setTag_list(all_tags)
    }
  }, [all_tags_data])

  const [get_files_api] = useLazyQuery(HUB_ALL_FILES, { fetchPolicy: 'no-cache' });

  // async function get_files() {
  //   debugger
  //   let conv_id = params.conversation_id ? [params.conversation_id] : []
  //   let json = {
  //     conversation_ids: conv_id,
  //     file_type: file_type,
  //     file_sub_type: file_sub_type === '' || props.type === 'tag_files' ? 'tag' : file_sub_type,
  //     tag_id: props.type === 'tag_files' ? view_tag_files.tag_ids : selectedTags,
  //   }

  //   setLoader(true)
  //   let res = await get_files_api({ variables: json });

  //   if (res?.data) {
  //     console.log("🚀 ~ get_files ~ res:", res)
  //     setApi_data(res.data.get_file_gallery.files);
  //     setPagination(res.data.get_file_gallery.pagination);
  //     setSummary(res.data.get_file_gallery.summary);
  //     setLoader(false)
  //   }
  // }

  

  // let prevloader = false;
  const filter_files = async (type, _pg) => {
    if(prevloader) return;
    setPrevLoader(true);
    if(_pg === 1) {
      setApi_data([]);
    }
    let conv_id = params.conversation_id ? [params.conversation_id] : [];
    let json = {
      file_type: file_type,
      file_sub_type: file_sub_type === '' || props.type === 'tag_files' ? 'tag' : file_sub_type,
      tag_id: props.type === 'tag_files' ? view_tag_files.tag_ids : type === 'reset' ? [] : selectedTags,
      conversation_ids: type ===  'reset' ? conv_id : selectedRooms,
      file_name: type === 'reset' ? '' : input_val,
      uploaded_by: type === 'reset' ? '' : selectedCreators.toString(),
      from: type === 'reset' ? '' : startDate !== '' ? moment(startDate).format('YYYY-MM-DD') : startDate,
      to: type === 'reset' ? '' : endDate !== '' ? moment(endDate).format('YYYY-MM-DD') : endDate,
      page: type === 'reset' ? 1: _pg,
      // tag_operator: 'or',
    }
    //  console.log('filter__tab:file',_pg, json.conversation_ids);
    let res = await get_files_api({ variables: json });
    
    if (res?.data) {
      if (type === 'scroll') {
        setApi_data(prev => [...prev, ...res.data.get_file_gallery.files]);
        setPage((prevPage) => prevPage + 1); // Increment page for pagination
      } else {
        setApi_data(res.data.get_file_gallery.files);
        setPage(1)
      }
      setPagination(res.data.get_file_gallery.pagination);
      setSummary(res.data.get_file_gallery.summary);
    }
    setPrevLoader(false);
    
  }

 
  const prevScrollHeight = useRef(0);

  // const onScrollFiles = (e) => {
  //   const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
  //   if (scrollTop + clientHeight >= scrollHeight - 100) {
  //     if (!prevloader) {
  //       if (pagination.totalPages > page) {
  //         let container = containerRef.current;
  //         prevScrollHeight.current = container.scrollHeight;
  //         console.count('Calling API...')
          
  //         filter_files('scroll', page + 1)
  //         setPage(prevPage => prevPage + 1); // Increment page for pagination
  //       }
  //     }
  //   }
  // }
  useEffect(()=>{
    // console.log('filter__data',api_data.length)
  },[api_data])
  
  // const onScrollFiles = ({ scrollDirection, scrollOffset }) => {
  //   // console.log('onScrollFiles',scrollDirection,scrollOffset)
  //   if (scrollDirection === "forward") {
  //     // Calculate the total height of the list based on the new item size (70px per item)
  //     const listHeight = 80 * api_data.length; // total height of the list
  //     // Use container's height for threshold calculation
  //     const threshold = listHeight - (containerRef.current ? containerRef.current.clientHeight : 0) - 50; // Adjust buffer
  
  //     // Check if we are near the bottom (or past the threshold)
  //     if (scrollOffset >= threshold && !prevloader && pagination.totalPages > page) {
  //       let container = containerRef.current;
  //       prevScrollHeight.current = container?.scrollHeight || 0;
        
  //       filter_files("scroll", page + 1);
        
  //     }
  //   }
  // };

  const onScrollFiles = ({ scrollDirection, scrollOffset }) => {
    if (scrollDirection === "forward") {
      const listHeight = api_data.reduce((total, item, index) => {
        const row = document.getElementById(item.id + "_file_id");
        const rowHeight = row ? row.offsetHeight : 65; // Default fallback height
        // console.log("rowHeight",rowHeight)
        return total + rowHeight;
      }, 0);
  
      const threshold = listHeight - (containerRef.current ? containerRef.current.clientHeight : 0) - 50;
  
      if (scrollOffset >= threshold && !prevloader && pagination.totalPages > page) {
        filter_files("scroll", page + 1);
      }
    }
  };


  useEffect(() => {
    if (change_file_data) {
      // console.log('change_file_data:', change_file_data);
      if (change_file_data.xmpp_type === 'star_file') {
        setApi_data(api_data.map(f => f.id === change_file_data.file_id ? { ...f, star: change_file_data.star } : f))
      } else if (change_file_data.xmpp_type === 'delete_msg' || change_file_data.xmpp_type === 'delete_one_file') {
        setApi_data(api_data.filter(f => f.id !== change_file_data.file_id))
      } else if (change_file_data.xmpp_type === 'add_remove_tag_into_msg') {
        let attachment = api_data.map((f) =>
          f.id === change_file_data.file_id || change_file_data.file_id === change_file_data.conversation_id ?
            {
              ...f,
              tag_list: f.tag_list ? [...f.tag_list.filter((t) => change_file_data.removetag.indexOf(t) === -1), ...change_file_data.newtag.filter(t => f.tag_list.indexOf(t) === -1)] : [...change_file_data.newtag],
              tag_list_details: (f.tag_list_details && f.tag_list) ? [...f.tag_list_details.filter((t) => change_file_data.removetag.indexOf(t.tag_id) === -1), ...change_file_data.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)] : [...change_file_data.newtag_tag_data],
            } : f
        )
        setApi_data(attachment)
      } else if (change_file_data.xmpp_type === 'file_share_link_create') {
        setApi_data(api_data.map(f => f.id === change_file_data.id ? { ...f, url_short_id: change_file_data.url_short_id } : f))
      }
    }
  }, [change_file_data])

  useEffect(() => {
    filter_files('filter', 1)
  }, [file_type, file_sub_type, view_tag_files])

  function niceBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  const [file_delete, setFile_delete] = useState(false)
  const AfileDownload = (url, filename) => {
    dispatch(set_downloading_file([filename]))
    if (downloading_progress[filename]) return
    dispatch(set_downloading_progress({ ...downloading_progress, [filename]: 'Downloading...' }))
    Axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%";
        dispatch(set_downloading_progress({ ...downloading_progress, [filename]: progress }))
      }
    }).then((response) => {
      // console.log("🚀 ~ AfileDownload ~ response:", response)
      FileDownload(response.data, filename);
      let d_progress = { ...downloading_progress };
      d_progress = { ...(delete d_progress[filename] && d_progress) }
      dispatch(set_downloading_progress(d_progress))
    });
  }

  const [starMutation] = useMutation(ATTACHMENT_STAR_MUTATION);
  const starMarkFile = (file) => {
    starMutation({
      variables: {
        input: {
          file_id: file.id,
          is_reply_msg: file.main_msg_id ? 'true' : 'false'
        }
      }
    })
  }

  const [action_file, setaction_file] = useState(null)
  const handle_file_action = (type, _f, e) => {
    if(e) e.stopPropagation();
    dispatch(set_action_file(_f))
    setaction_file(_f)
    if (type === 'tag') {
      dispatch(setPopup({ data: true, key: 'tag_popup' }));
    } else if (type === 'forward') {
      dispatch(set_global_action_data({ msg: null, file: _f, forward_type: 'file' }));
      dispatch(setPopup({ data: true, key: 'forward_msg_popup' }));
    } else if (type === 'download') {
      AfileDownload(_f.location, _f.originalname)
    } else if (type === 'share') {
      dispatch(set_share_link_popup(true))
    } else if (type === 'delete') {
      setFile_delete(true)
    } else if (type === 'star') {
      starMarkFile(_f)
    }
  }

  const handleDelPopup = () => {
    setFile_delete(false)
  }

  const ButtonArea = () => {
    return (
      <div className="buttonList">
        <div className={classNames("buttonsDesign reset_button", buttonStatus ? "activeButton" : "inactiveButton")} 
        onClick={() => { 
          resetFun(); 
          filter_files('reset', 1);
        }}>
          <span className="goIconArea">
            <span className="goText" style={{ position: "relative", top: "0px", }}>Clear</span>
            <BiReset size={20} className="arrow_go_icon clear" />
          </span>
        </div>
        <div className={classNames("buttonsDesign go_button", buttonStatus ? "activeButton" : "inactiveButton")}
        // className="buttonsDesign go_button" 
        onClick={() => filter_files('filter', 1)}>
          <span className="goIconArea">
            <span className="goText" style={{ position: "relative", top: "0px", }}>Search</span>
            <BsArrowRight size={20} className="arrow_go_icon" />
          </span>
        </div>
      </div>
    );
  };

  const KeyUpHandle = (event) => {
      if (event.keyCode === 13) {
        filter_files('filter', 1)
      }
  }

   const file_action = (_f) => {
        if (_f.file_type.indexOf('image') > -1) {
            setPreview_data(_f)
            // setPreview_all_data(msg.all_attachment.filter((i) => i.file_type.indexOf('image') > -1))
        } else if (_f.file_type.indexOf('pdf') > -1 || _f.file_type.indexOf('audio') > -1) {
          localStorage.setItem('file_view',JSON.stringify(_f));
          window.open('/file', "_blank")
        } else if (_f.file_type.indexOf('application') > -1) {
          AfileDownload(_f.location, _f.originalname)

        } else if (_f.file_type.indexOf('video') > -1 ) {
          setVideo_data(_f);
        }
    }
  // useEffect(() => {
  
  //     if (markIteam.length > 1) {
  //       setFloatingAction(true) 
  //     } else {
  //       setFloatingAction(false) 
  //     }
  // }, [markIteam])

  useEffect(() => {
        let d = [];
        for (let m of api_data) {
            if (markIteam.indexOf(m.id) > -1) {
                d.push(m)
            }
        }
        let ids = [];
        for (let m of api_data) {
            if (markIteam.indexOf(m.id) > -1) {
                ids.push(m.id)
            }
        }
        dispatch(set_selected_files_data(d));
        dispatch(set_multi_file_ids(ids))

        if (markIteam.length > 1) {
          setFloatingAction(true) 
        } else {
          setFloatingAction(false) 
        }
    }, [markIteam])

    useEffect(()=>{
      if (is_advance) {
          resetFun();
          filter_files('reset', 1);
      }

    },[is_advance])

    const FileRow = ({ index, style }) => {
      const v = api_data[index];
       const filteredTags = v.tag_list_details?.filter(
          (f) =>
            (f.tag_type === "public" || f.tag_type === "task" 
                // || (f.tag_type === "private" && f.tagged_by === cacheMe.me.id)
            ) && f.title !== "UNTAGGED FILES" && f.tag_type !== "private"
        );
    
      return (
        
        <ul className="fileBodyRow inFileTagRow" style={{...style, padding: "10px 3px 0px 10px", height: "auto"}} key={v.id + "_file"} id={v.id + "_file_id"}>
            {/* {selectedItemIndex !== index ? null :
              <div className="backwrap">
                <div className="tagareaList" style={{ right: "0%", top: "0px", maxHeight: "500px", maxWidth: "500px", position: "relative", paddingTop: "20px", minWidth: "250px", }}>
                  <span className="closeModal forTag" onClick={() => { setSelectedItemIndex(-1); }} data-for="rightSection_tooltip" data-tip="Close" style={{ position: "absolute", right: "25px", top: "25px" }}></span>
                  <h2 className="popupTitle tagfileTitle" style={{}}>File tags</h2>
                  {v.tag_list_details?.filter((t) => t.title !== "UNTAGGED FILES").map((tag) => (
                    <span key={tag.tag_id} style={{ background: tag.tag_color }}>{tag.title}</span>
                  ))}
                </div>
              </div>
            } */}
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#tagopts" float="true" content="Add a tag"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#forward_opts" float="true" content="Forward"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#shareopts" float="true" content="Share"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#downloadopts" float="true" content="Download"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#delete_opts" float="true" content="Delete"></Tooltip>
            
              {selectedItemIndex === index  ? (
                <OutsideClickHandler
                onOutsideClick={() => setSelectedItemIndex(-1)}
              >
              <div
                className="allHoverTaglist !h-max-[200px] !w-max-[400px] overflow-auto p-[17px_1px_2px_13px] rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] bg-white z-50"
                style={{ right: "70px", left: "unset", top: "20px", position: "absolute", padding: "1px 15px 5px 10px",minWidth:'200px', maxWidth:'400px', maxHeight:'150px',overflow:'auto' }}
              >
                <div className="sticky top-0 w-full bg-white pt-[10px] pl-[20px] z-[99999] h-[40px]">
                  <span
                    className="closeModal forTag absolute !right-[0px] !top-[15px]"
                    onClick={() => {
                      setSelectedItemIndex(-1);
                    }}
                  ></span>
                  <h2 className="popupTitle tagfileTitle !mb-[10px]">File tags</h2>
                </div>
                <div className="tagsTitles flex gap-[10px] justify-start clear-both flex-wrap">
                {filteredTags.map((tag) => (
                    <p
                      className="p-[4px_9px_3px_11px] rounded-[4px] bg-[#e3f2ff] font-[Rubik] text-[14px] font-normal leading-normal tracking-normal text-left text-[#0b1f47]"
                      key={tag.tag_id}
                    >
                      {tag.title}
                    </p>
                  ))}
                </div>
              </div>
              </OutsideClickHandler>
            ) : null}
            {/* {selectedTagsIndex !== index ? null :
              <div className="tagareaList threeTags" style={{ right: "17%", top: "3px", maxWidth: "500px", position: "absolute", paddingTop: "20px", height: "auto", padding: "1px 15px 5px 10px", minWidth: "150px" }}>
                <h2 className="popupTitle tagfileTitle" style={{ marginBottom: "0px", }}>File tags</h2>
                {filteredTags.map((tag) =>
                  <span key={tag.tag_id} style={{ background: tag.tag_color }}>{tag.title}</span>
                )}
              </div>
            } */}
            <li className="_afileMark ovalClick" onClick={() => setMarkIteam(markIteam.indexOf(v.id) > -1 ? markIteam.filter((m) => m !== v.id) : [...markIteam, v.id])}>
              <span className={classNames("ovalCircle", markIteam.indexOf(v.id) > -1 ? "ovalActive" : "")}></span>
            </li>
            {/* Name */}
            <li className="_afileName fileName_list" onClick={() => file_action(v)} style={{ justifyContent: "flex-start", paddingLeft: "8px", width: "45%", paddingRight: "30px", overflow: "hidden", }}>
              <span className="_fileIcon" style={{ marginRight: "10px" }}>
                {v.file_category === "image" ?
                  <img src={v.location} width={50} height={50} alt='img' title="img" />
                  :
                  <img src={`/media/images/light_theme/${fileExtension(v.location)}.svg`} width={50} height={50} alt='img' title="img" />
                }
              </span>
              <div className="filenameAndIcon">
                {v.originalname.length < 60 ? (
                  <span className="workfreeliFileText" style={{ marginLeft: "0px", top: "-10px", width: "auto", }}>
                    {removeTags(v.originalname)}
                  </span>
                ) : (
                  <span className="workfreeliFileText" style={{ marginLeft: "0px", top: "-10px", width: "auto", }}>
                    {v.originalname.length < 60 ? `${removeTags(v.originalname)}` : `${removeTags(v.originalname).substring(0, 59)}...`}
                  </span>
                )}
                {v.url_short_id !== null && v.url_short_id !== "" ?
                  <span style={{ width: "auto", position: "relative", top: "-5px", }} className="sharedIcon"></span>
                  : null
                }
              </div>
              <p className="workfreeliDOCX">{v.file_category}</p>
              <div className={classNames("gFileHoverLists", v.url_short_id !== null && v.url_short_id !== "" ? "alreadyShared" : "")} style={{ right: "25px", top: "0px" }}>
                <div className="fileOpts tag_opts" id='tagopts' onClick={(e) => handle_file_action('tag', v, e)}></div>
                <div className="fileOpts forward_opts" id='forward_opts' onClick={(e) => handle_file_action('forward', v, e)}></div>
                <div className="fileOpts download_opts" id="downloadopts" onClick={(e) => handle_file_action('download', v, e)}></div>
                <div style={v.user_id === user.id ? {} : { pointerEvents: 'none', opacity: '0.5' }} className="fileOpts share_opts" id="shareopts" onClick={(e) => handle_file_action('share', v, e)}></div>
                <div style={v.user_id === user.id ? {} : { pointerEvents: 'none', opacity: '0.5' }} className="fileOpts delete_opts" id="delete_opts" onClick={(e) => handle_file_action('delete', v, e)}></div>
              
              </div>
             
            </li>
            {/* Starred */}
            <li className="_afileSize" onClick={(e) => handle_file_action('star', v, e)} style={{ width: "5%", display: "flex", alignItems: "center", justifyContent: "center", }}>
              {v.star.indexOf(user.id) > -1 ? <BsStarFill className="startedMark " color={"#febf3a"} size={16} /> : <BsStarFill className="startedMark inactive" color={"#d8d8d8"} size={16} />}
            </li>
            {/* Size */}
            <li className="_afileSize" style={{ width: "7%" }}>
              <span style={{ fontWeight: "normal", color: "#032e84", fontSize: "12px", paddingLeft: "20px", }} className="">{niceBytes(v.file_size)}</span>
            </li>
            {/* Date */}
            <li className="_afileSize" style={{ width: "10%" }}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "7px 0px", paddingLeft: "8px", }}>
                <p className="linkTextTable newText">
                  <Moment format="DD/MM/YYYY">{v.created_at}</Moment>
                </p>
                <p className="linkTextTable newText">
                  <Moment format="h:mm a">{v.created_at}</Moment>
                </p>
              </div>
            </li>
            {/* Uploaded by */}
            <li className="_afileSize uploadedByClm" style={{ width: "15%" }}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "7px 0px", paddingLeft: "8px", width: "100%" }}>
                <p className="linkTextTable newText whitespace-nowrap">{v.uploaded_by}</p>
                <p className="linkTextTable newText whitespace-nowrap">{v.conversation_title}</p>
              </div>
            </li>
            {/* Reference Id */}
            <li className="_afileSize" style={{ width: "9%" }}>
              {v.referenceId !== '' && <span style={{ backgroundColor: "#732be2" }} className="fileRef">Ref. ID: {v.referenceId}</span>}
            </li>
            {/* Tags */}
            <li className="_afileSize" style={{ flexBasis: "6%" }}>
              <p className="timeAndDate" style={{ paddingLeft: "10px", }}>
                {filteredTags.length > 4 ? filteredTags.length : ""}
              </p>
              {/* {v.tag_list_details?.filter((t) => t.title !== "UNTAGGED FILES").length > 4 ? */}
                <ul className="colorGroup" id="fileTagsAll" onClick={(e) => { showHoverItemHandler(index); }}>
                  {filteredTags.slice(0, 4).map((tag) =>
                    <li key={tag.tag_id} className="colorGroupList" style={{ background: tag.tag_color }}></li>
                  )}
                </ul>
                 <Tooltip className="z-50" anchorSelect="#fileTagsAll" float="true" content="Click to view all tag(s)"></Tooltip>
                {/* :
                <ul className="colorGroup" onMouseEnter={(e) => { showHoverTagsHandler(index); }} onMouseLeave={hideHoverTagsHandler}>
                  {filteredTags.slice(0, 4).map((tag, i) => (
                    <li key={i} className="colorGroupList" style={{ background: tag.tag_color }}></li>
                  ))}
                </ul>
              } */}
            </li>
          </ul>
      );
    };

  return (
    <>
      <Toaster />
     
      {is_advance ?
        <div className="search_total_area">
          <div className="search_right_area" style={{ flexBasis: "100%" }}>
            <div className="Advance_search">
              <div className="Advance_search_top" style={{ paddingRight: "0px" }}>
                <div style={{ flexBasis: "20%" }} onClick={() => setIs_advance(false)} className="Advance_search_text Advance_item">
                  Advanced search <span><MdKeyboardArrowDown size={16} /></span>
                </div>
                <div style={{ flexBasis: "84%", borderBottom: "unset", borderRight: "unset", padding: "5px 0px 0 20px", }} className="Advance_search_list Advance_item" name="fontSize">
                  <div className="searchAndFilter" style={{ borderBottom: "unset", position: "relative", width: "98%", }}>
                    <input type="text" id="_inputBar_tagdetails" placeholder="Search" value={input_val} onChange={(event) => setInputVal(event.target.value)} onKeyUp={(event) => KeyUpHandle(event)}
                    className="_inputSearchBar" style={{ width: '100%', marginRight: "0px" }} autoFocus />
                    {input_val === "" ?
                      <div style={{ right: "104px", height: "31px", }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                      :
                      <span style={{ right: "112px" }} className="clearAllSrcTest" onClick={() => { 
                        setInputVal("");
                        setTriggerAPI(true);
                      }} data-for="top_head" data-tip="Clear Search"></span>
                    }
                    <div 
                    // className="SearchButton" 
                    className={classNames("SearchButton", input_val ? "activeButton" : "inactiveButton")}
                    onClick={() => filter_files('filter', 1)}
                    >Go</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        :
        <div className="search_total_area">
          <div className="search_right_area">
            <div className="Advance_search">
              <div className="Advance_search_top" style={{ padding: "0px 0px 0 30px" }}>
                <div style={{ flexBasis: "20%" }} onClick={() => { setIs_advance(true); }} className="Advance_search_text Advance_item">
                  Advanced search
                  <span> <MdKeyboardArrowRight size={16} /></span>
                </div>
                <div className="Advance_search_list Advance_item" name="fontSize" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <div className="custom_dropdown">
                    <div ref={ref}>
                      <div className="custom_dropdown-header" onClick={toggleDropdown}>
                        {options.find((i) => i.value === file_type).customeName}
                        <i className={`fa fa-chevron-right custom_icon ${isOpen && "open"}`}></i>
                      </div>
                      <div className={`custom_dropdown-body ${isOpen && "open"}`}>
                        {options.map((item) => (
                          <div className={classNames("custom_dropdown-item", item.value === file_type ? 'selected' : '')}
                            onClick={(e) => handleItemClick(item.value)}
                            key={item.value}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="date_area Advance_item responsive_serch" style={{ flexBasis: "67.5%" }}>
                  <span className="calenderIcon"> <VscCalendar className="iconColor" size={22} /></span>
                  <div className="fromDate_One">
                    {/* <DatePicker className={error1 === true ? "start_Date errorDate" : "start_Date"}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="From   YYYY-MM-DD"
 
                      renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (
                        <div className="CalendarDiv">
                          <button name="decreaseMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}> {"<"}</button>
                          <div>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map((option) => (
                                <option key={option} value={option}> {option}</option>
                              ))}
                            </select>
                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map((option) => (
                                <option key={option} value={option}> {option}</option>
                              ))}
                            </select>
                          </div>

                          <button name="inecreaseMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                        </div>
                      )}
                      selected={startDate}
                      onChange={(date) => {
                        if (new Date(date) > new Date(endDate)) {
                          toast.error(
                            "Start date should be smaller than  or equal to end date",
                            { duration: 4000 }
                          );
                          setStartDate("");
                          setError1(true);
                          setTimeout(() => {
                            setError1(false);
                          }, 4000);
                        } else {
                          setStartDate(date);
                          setError1(false);
                        }
                      }}
                    /> */}
                    <DatePicker
                      className={error1 === true ? "start_Date errorDate" : "start_Date"}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="From   YYYY-MM-DD"
                      // selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="CalendarDiv" >
                          <button name="decreaseMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <div><select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>

                          <button name="inecreaseMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={startDate}
                      onChange={(date) => {

                        if (new Date(date) > new Date(endDate)) {
                          toast.error('Start date should be smaller than  or equal to end date', { duration: 4000 });
                          setStartDate('')
                          setError1(true)
                          setTimeout(() => {
                            setError1(false)
                          }, 4000)
                        } else {
                          setStartDate(date);
                          setError1(false)
                        }
                      }}
                    />

                    {startDate !== "" ? <span className="clearInput" onClick={() => { setStartDate(""); }}></span> : null}
                  </div>
                  <span className="calenderIcon2">
                    <VscCalendar className="iconColor" size={22} />
                  </span>
                  <div className="fromDate_two">
                    <DatePicker className={error2 === true ? "start_Date errorDate" : "start_Date"} dateFormat="yyyy-MM-dd" placeholderText="To    YYYY-MM-DD"
                      minDate={startDate}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="CalendarDiv">
                          <div>
                            <button name="deecreasedMonth" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map((option) =>
                                <option key={option} value={option}>{option}</option>
                              )}
                            </select>
                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map((option) =>
                                <option key={option} value={option}>{option}</option>
                              )}
                            </select>
                          </div>

                          <button name="increasedMonth" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                        </div>
                      )}
                      selected={endDate}
                      onChange={(date) => {
                        if (new Date(date) < new Date(startDate)) {
                          toast.error(
                            "End date should be greater than  or equal to Start date",
                            { duration: 4000 }
                          );
                          setError2(true);
                          setEndDate("");
                          setTimeout(() => {
                            setError2(false);
                          }, 4000);
                        } else {
                          setEndDate(date);
                          setError2(false);
                        }
                      }}
                    />
                    {endDate !== "" ? <span className="clearInput" onClick={() => { setEndDate(""); }}></span> : null}
                  </div>
                </div>

              </div>
              <div className="Advance_search_bottom">
                <div className="searchAndFilterBar LeftSide" style={{ position: "relative" }}>
                  {!params.conversation_id ?
                    <>
                      <div className="searchAndFilter">
                        <div ref={ref2}>
                          <input value={searchByRoomVal} onFocus={() => setRoomListPopup(true)} onChange={(event) => searchByRoom(event.target.value)} 
                          // onBlur={() => BlurEffect("searchByRoom")} 
                          className="_inputBar searchLeft" type="text" placeholder="Search by room" />
                          {searchByRoomVal === "" ?
                            <div style={{ right: "31px", height: "31px", top: "17px", }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                            :
                            <span style={{ right: "32px", top: "20px" }} className="clearAllSrcTest" onClick={(event) => searchByRoom("")} data-for="top_head" data-tip="Clear Search"></span>
                          }
                          {roomListPopup ?
                            <div className="tag_room_list">
                              {roomList?.filter((e) => (e.l_show === undefined || e.l_show) && selectedRooms.indexOf(e.conversation_id) === -1).map((v) => (
                                <p className="_tag_rooms" onClick={() => selectRooms(v)} key={"conv_" + v.conversation_id}>{v.title}</p>
                              ))}
                              {roomList?.filter((e) => (e.l_show === undefined || e.l_show) && selectedRooms.indexOf(e.conversation_id) === -1).length === 0 && <div className="tagNotFound">Not found</div>}
                            </div>
                            : null
                          }
                        </div>
                      </div>
                      <div className="selectedRoomCont dfgdfg">
                        {roomList.filter((r) => selectedRooms.indexOf(r.conversation_id) > -1).map((v) => (
                          <p className="tags_Color !pl-2 !mr-1 !mb-1" key={"conv1" + v.conversation_id}> {v.title}
                            {!params.conversation_id && <BsXLg size={14} className="tags_Icon_close" onClick={() => {
                              setSelectedRooms(selectedRooms.filter((r) => r !== v.conversation_id));
                            }}
                            />}
                          </p>
                        ))}
                      </div>
                    </>
                    : (
                      <>
                        <div className="searchAndFilter">
                          <div ref={ref3}>
                            <input value={searchByUploaderVal} onChange={(event) => searchByUploader(event.target.value)} className="_inputBar searchRight" type="text" onFocus={() => setuploaderListPopup(true)} 
                            // onBlur={() => BlurEffect("searchByUploaderVal")} 
                            placeholder="Uploaded By" 
                            />
                            {searchByUploaderVal === "" ?
                              <div style={{ right: "31px", height: "31px", top: "17px", }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                              :
                              <span style={{ right: "34px", top: "21px" }} className="clearAllSrcTest" onClick={(event) => searchByUploader("")} data-for="top_head" data-tip="Clear Search"></span>
                            }
                            {uploaderListPopup && (
                              <div className="tag_room_list">
                                {Uploader_list?.filter((e) => (e.l_show === undefined || e.l_show) && selectedCreators.indexOf(e.id) === -1).map((v) =>
                                  <p className="_tag_rooms" key={"tag" + v.id} onClick={() => selectCreate(v)} >{`${v.firstname} ${v.lastname}`}</p>
                                )}
                                {Uploader_list?.filter((e) => (e.l_show === undefined || e.l_show) && selectedCreators.indexOf(e.id) === -1).length === 0 &&
                                  <div className="tagNotFound">Not found</div>
                                }
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="selectedRoomCont">
                          {Uploader_list?.filter((r) => selectedCreators.indexOf(r.id) > -1).map((v) =>
                            <span className="tags_Color" id="selectedCreatorss" key={"tag1" + v.id}>
                              {`${v.firstname} ${v.lastname}`}
                              <BsXLg className="tags_Icon_close" size={14} onClick={() => { setSelectedCreators(selectedCreators.filter((r) => r !== v.id)); }} />
                            </span>
                          )}
                        </div>
                      </>
                    )}
                </div>
                <div className="searchAndFilterBar rightSide" style={{ position: "relative" }}>
                  <div className="searchAndFilter">
                    <div ref={ref4}>
                      <input value={searchByTagVal} onChange={(event) => searchByTag(event.target.value)} onFocus={() => setTagListPopup(true)} className="_inputBar searchRight" type="text" 
                      // onBlur={() => BlurEffect("searchByTag")} 
                      placeholder="Search by tag" />
                      {searchByTagVal === "" ?
                        <div style={{ right: "31px", height: "31px", top: "17px", }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                        :
                        <span style={{ right: "32px", top: "20px" }} className="clearAllSrcTest" onClick={(event) => searchByTag("")} data-for="top_head" data-tip="Clear Search"></span>
                      }

                      {tagListPopup ?
                        <div className="tag_room_list">
                          {tag_list.filter(e => (e.l_show === undefined || e.l_show) && selectedTags.indexOf(e.tag_id) === -1).map((v) =>
                            <p className="_tag_rooms" key={'tag' + v.tag_id} onClick={() => selectTags(v)}>{v.title}</p>
                          )}
                          {tag_list.filter(e => (e.l_show === undefined || e.l_show) && selectedTags.indexOf(e.tag_id) === -1).length === 0 && <div className="tagNotFound">Not found</div>}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="selectedRoomCont">
                    {tag_list.filter(r => selectedTags.indexOf(r.tag_id) > -1).map(v =>
                      <p className="tags_Color !pl-2 !mb-1 !mr-1" key={'tag1' + v.tag_id}>{v.title}
                          <BsXLg size={14} className="tags_Icon_close" onClick={() => clear_tag(v)}/>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="searchAndFilter pl-[15px] !border-b-[1px solid #eeeeee] !relative !h-[80px] gap-[11px]">
              {props.type !== 'tag_files' && <div className={classNames("filter_btn", file_sub_type === '' && 'active')} onClick={() => setFile_sub_type("")}>
                <MdFolder color="#ffffff" size={14} />
                <span id="AllFiles">All files</span>
              </div>}
              {props.type !== 'tag_files' && <div className={classNames("filter_btn", file_sub_type === 'star' && 'active')} onClick={() => setFile_sub_type("star")}>
                <BsStarFill color="#ffffff" size={14} />
                <span id="StarredFiles" className="changeColor">Starred files</span>
              </div>}
              {props.type !== 'tag_files' && <div className={classNames("filter_btn", file_sub_type === 'ref' && 'active')} onClick={() => setFile_sub_type("ref")}>
                <MdFolder color="#ffffff" size={14} />
                <span id="Referencetype">Reference type</span>
              </div>}
              {props.type !== 'tag_files' && <div className={classNames("filter_btn", file_sub_type === 'share' && 'active')} onClick={() => setFile_sub_type("share")}>
                <MdFolder color="#ffffff" size={14} />
                <span id="SharedbyURL">Shared by URL</span>
              </div>}
              <input type="text" 
              id="_inputBar_tagdetails " 
              placeholder="Search" value={input_val} 
              onChange={(event) => setInputVal(event.target.value)} style={props.type !== 'tag_files' ? {} : { width: '100%' }} 
              className="_inputSearchBar " onKeyUp={(event) => KeyUpHandle(event)} autoFocus 
              />
              {input_val === "" ?
                <div style={{ right: "31px", height: "31px", }} className="srcBtnSection" data-for="top_head" data-tip="Search"></div>
                :
                <span style={{ right: "32px" }} className="clearAllSrcTest" onClick={(event) => { setInputVal(""); setResetCounter(resetCounter + 1); }} data-for="top_head" data-tip="Clear Search"></span>
              }
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#AllFiles" float="true" content="All files"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#StarredFiles" float="true" content="Starred files"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#Referencetype" float="true" content="Reference type"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#SharedbyURL" float="true" content="Shared by URL"></Tooltip>
              
            </div>
          </div>
          <div className="serarchButton area">
            <ButtonArea />
          </div>
        </div>
      }
      <div className="show_search_results_area">
        {expandTotal ?
          <div className="show_results_eachfiles">
            {expandTotalLoader ?
              <span className={"btn_loader"} style={{ width: "158px", float: "left", border: "none", height: "20px" }}></span>
              :
              <ul className={classNames("file_count_list", expandTotalLoader ? "blur_effect" : "")}>
                <li className={classNames("file_ul_list", file_type === 'all' && 'active')} onClick={() => handleFileType('all')}>
                  <span className="file_count" id="Total">Total</span>
                  <span className="file_count_num"><span>{summary.total}</span></span>
                </li>
                <li className={classNames("file_ul_list", file_type === 'docs' && 'active')} onClick={() => handleFileType('docs')}>
                  <span className="file_count" id="doc"> Doc(s)</span>
                  <span className="file_count_num"><span>{summary.other}</span></span>
                </li>
                <li className={classNames("file_ul_list", file_type === 'image' && 'active')} onClick={() => handleFileType('image')}>
                  <span className="file_count" id="image">Image(s)</span>
                  <span className="file_count_num"><span> {summary.image}</span></span>
                </li>
                <li className={classNames("file_ul_list", file_type === 'voice' && 'active')} onClick={() => handleFileType('voice')}>
                  <span className="file_count" id="Voice">Voice(s)</span>
                  <span className="file_count_num"><span>{summary.voice}</span></span>
                </li>
                <li className={classNames("file_ul_list", file_type === 'audio' && 'active')} onClick={() => handleFileType('audio')}>
                  <span className="file_count" id="audio">Audio(s)</span>
                  <span className="file_count_num"><span>{summary.audio}</span></span>
                </li>
                <li className={classNames("file_ul_list", file_type === 'video' && 'active')} onClick={() => handleFileType('video')}>
                  <span className="file_count " id="Video">Video(s)</span>
                  <span className="file_count_num"><span>{summary.video}</span></span>
                </li>
                {/* <li className={classNames("file_ul_list", file_type === 'share' && 'active')} onClick={() => handleFileType('share')}>
                  <span className="file_count ">Shared By URL(S)</span>
                  <span className="file_count_num"><span>{summary.share}</span></span>
                </li> */}

                <li className="file_ul_list refreshIco" onClick={() => filter_files('filter', 1) }></li>
                
              </ul>
            }
             <Tooltip delayShow={1000} className="z-50" anchorSelect="#Total" float="true" content="Total"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#doc" float="true" content="Doc(s)"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#image" float="true" content="Image(s)"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#Voice" float="true" content="Voice(s)"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#audio" float="true" content="Audio(s)"></Tooltip>
              <Tooltip delayShow={1000} className="z-50" anchorSelect="#Video" float="true" content="Video(s)"></Tooltip>
          </div>
          : null}
      </div>
      <div className="tagFlieList_body">
      {prevloader && <div className="loaderMain mini_loader" style={{top: '50%'}}></div> }
        {/* {initialLoader ? ( <div className="loaderMain" style={{ height: "calc(100vh - 328px)" }}></div>) :  */}
        
          <div className="tagFlieList_top">
            <div className="taskListTableHead">
              <ul className="fileHeadRow" style={{ borderTop: "1px solid rgb(229, 238, 244)" }}>
                <li className="_afileMark"></li>
                <li className={classNames("_afileName ", activesortCol === "originalname" ? "sort_active " + activeOrdering : "desc")} style={{ justifyContent: "flex-start", paddingLeft: "8px", width: "45%", paddingRight: "0px", cursor: "default", }}
                onClick={(event) => sorting(event, "originalname", activesortCol === "originalname" ? activeOrdering : "desc")}>
                  <span className="sortIcons" style={{ cursor: "pointer" }}></span>
                  <span className="columnNum" style={{ cursor: "pointer", }}>File name</span>
                </li>
                <li className="_afileSize" style={{ justifyContent: "flex-start", paddingLeft: "0px", width: "5%", paddingRight: "30px", cursor: "default", }}>
                  <span className="columnNum changeColor">Starred</span>
                </li>
                <li className={classNames("_afileSize", activesortCol === "file_size" ? "sort_active " + activeOrdering : "desc")} style={{ width: "7%", marginLeft: "0px", paddingLeft: "16px", cursor: "default", }}>
                  <span className="columnNum changeColor">Size</span>
                </li>
                <li className={classNames("_afileDate ", activesortCol === "created_at" ? "sort_active " + activeOrdering : "desc")} style={{ justifyContent: "flex-start", width: "10%", paddingLeft: "0px", cursor: "default", }}>
                  <span className="columnNum">Date</span>
                </li>
                <li className="_afileDate sort_active desc" style={{ justifyContent: "flex-start", width: "15%", paddingLeft: "0px", paddingRight: "30px", cursor: "default", }}>
                  <span className="columnNum">Uploaded by</span>
                </li>
                <li className="_afileSize" style={{ justifyContent: "flex-start", width: "9%", paddingLeft: "0", paddingRight: "0", flexBasis: "9%", cursor: "default", }}>
                  <span className="columnNum changeColor">Reference ID</span>
                </li>
                <li className="_afileSize" style={{ justifyContent: "flex-start", width: "6%", paddingLeft: "0", paddingRight: "0px", cursor: "default", flexBasis: "6%", }}>
                  <span className="columnNum changeColor">Tags</span>
                </li>
              </ul>
              <div className={classNames("fileBody_area", is_advance ? "advanceFlieList_body" : "advanceFlieList_full_body")} style={{overflow: "unset"}}
              // onScroll={onScrollFiles} 
              ref={containerRef}
              >
                {api_data.length > 0 ? (
                  <List
                  height={containerRef.current ? containerRef.current.clientHeight : 0} // Adjust dynamically
                  itemCount={api_data.length}
                  itemSize={getItemSize}
                  width="100%"
                  // itemData={api_data}
                  onScroll={onScrollFiles}
                  // overscanRowCount={5}
                >
                  {FileRow}
                </List>
                )
                  
                  : !prevloader ?
                    <div className="fileNotFound" style={{ height: "calc(100% - 410px)", backgroundColor: "transparent" }}>
                      <h2>No data found matching the criteria! </h2>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        
        {/* } */}
      </div>
      {floatingAction ? <FloatingActionFiles markIteam={markIteam} setMarkIteam={setMarkIteam} floatingAction={floatingAction} setFloatingAction={setFloatingAction} handle_file_action={handle_file_action} /> : ''}
      
      {preview_data && <ImgPreviewPopup setPreview_data={setPreview_data} active_file={preview_data} all_file={[]} user={cacheMe.me} />}
      {video_data && <PlayerPopup setVideo_data={setVideo_data} video_data={video_data} all_file={[]} user={cacheMe.me} />}

      {file_delete && <WarningPopup messageTitle={'Delete File'} description={'Are you sure you want to delete this file?'} closePopUp={handleDelPopup} data={action_file} type={"file_delete"} allFiles={api_data} setallFiles={setApi_data} />}
    </>
  );
}

export default Files;
