import { useClickAway } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { getONLYUSERSDATA } from '../../../Utils/Common'
import { IoMdCloseCircleOutline } from 'react-icons/io';
import TaskObserver from './TaskObserver';
import { useTaskContext } from '../TaskContext';
import { Tooltip } from 'react-tooltip';
import sortArrayByKey from '../../../hooks/useSortedArray';

const TaskAssignee = () => {
    const { selectedTask, user, users, OnUpdateTask, isObserver, isAssignee } = useTaskContext()
    const [assignPopup, setAssignPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const task_participants = users?.filter(f => selectedTask?.participants?.indexOf(f.id) > -1 && selectedTask?.observers?.indexOf(f.id) === -1)
    const handleAssigneeClick = (u) => {
        OnUpdateTask({ assign_to: [u.id], save_type: 'assignee' });
        setAssignPopup(false);
    };

    const ref = useClickAway(() => {
        setAssignPopup(false)
    })

    const filter_data = task_participants?.filter(f => (f.firstname + ' ' + f.lastname).toLowerCase()?.includes(searchTerm?.toLowerCase()))?.filter(f => selectedTask?.assign_to?.indexOf(f.id) === -1)
    const sorted_data = sortArrayByKey(filter_data, 'firstname', 'asc');
    return (
        <>
            <div className='cretorAndAssignee_area relative'>
                <div className="taskmambers" tabIndex="0">
                    <div className="cretorAndAssignee_Div">
                        <div id={selectedTask?.assign_to.length === 0 ? "assignee" : "updateAssignee"} className="creator_area leftArea inAssigne true" style={{ borderBottom: 'unset' }}>
                            <div className="creator_label">Assigned</div>
                            <div className="assingeetrim">
                                <div className={`new_area relative ${(isObserver() || (isAssignee() && selectedTask.created_by !== user?.id)) && 'pointer-events-none'}`} onClick={() => setAssignPopup(true)}>
                                    {selectedTask?.assign_to.length > 0 ?
                                        <div className="assigneeMem">
                                            <div className="assigneeMem_inner" style={{ overflow: 'hidden', width: 'auto', marginLeft: '-16px' }}>
                                                <li className="ObsImageTask" style={{ marginTop: '0px', width: '27px', marginRight: '9px', height: '27px', alignItems: 'center', fontSize: '12px', backgroundColor: 'rgb(44, 86, 172)' }}>{getONLYUSERSDATA(users, selectedTask?.assign_to[0], 'fnln')}</li>
                                            </div>
                                            <div className="ellipsis" style={{ fontSize: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', cursor: 'pointer' }}>
                                                <span>{getONLYUSERSDATA(users, selectedTask?.assign_to[0], 'name')} &nbsp; </span>
                                                {(!isObserver() && (!isAssignee() || (isAssignee() && selectedTask.created_by === user?.id))) && <span onClick={() => OnUpdateTask({ assign_to: [], save_type: 'assignee' })} className="cleariconName removeFile" style={{ right: '-16px', top: '3px', display: 'flex', width: '14px', height: '14px', backgroundSize: '8px', border: '1px solid red' }}></span>}
                                            </div>
                                        </div> :
                                        <div onClick={() => setAssignPopup(true)} className="add_assignee hover:text-[#0C1E47]">Add assignee</div>}
                                </div>
                                {assignPopup &&
                                    <div ref={ref} className="AddKeywordsPopup_task_settings !top-[50px] insightTaskBoard p-2 border shadow-lg border-gray-300" style={{ marginLeft: '0px', width: '100%', left: '0px' }}>
                                        <div className="keywordItem">
                                            <div className="selected_close_row">
                                                <span className="selected_label">Please select a user</span>
                                                <span onClick={() => setAssignPopup(false)} className="removeFile closeBtn"></span>
                                            </div>
                                            <div className="searchAndFilterBar" style={{ position: 'relative' }}>
                                                <div className="selectedRoomCont !p-0 !mt-[10px]">
                                                    {selectedTask?.assign_to?.length > 0 && <span className="!bg-[#0C1E47] !px-2 tag_Color">{getONLYUSERSDATA(users, selectedTask?.assign_to[0], 'name')}
                                                        <MdOutlineClose onClick={() => OnUpdateTask({ assign_to: [], save_type: 'assignee' })} className='w-[14px] h-[14px] ml-1' />
                                                    </span>}
                                                </div>
                                                <div className="searchAndFilterKeyword"></div>
                                                <div className="searchareaDiv relative">
                                                    <input className="_inputBar searchLeft" type="text" placeholder="Search User" onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} />
                                                    {searchTerm.length > 0 && <IoMdCloseCircleOutline onClick={() => setSearchTerm('')} className='text-red-600 cursor-pointer text-2xl absolute right-2 top-1' />}
                                                </div>
                                                <div>
                                                    <div className="keyword_list_task_settings">
                                                        {sorted_data.map((v, i) => (
                                                            <p className="_tag_rooms" style={{ padding: '10px', cursor: 'pointer' }} key={i} onClick={() => handleAssigneeClick(v)} tabIndex="0">
                                                                {v.firstname + ' ' + v.lastname}
                                                            </p>
                                                        ))}
                                                        {task_participants?.filter(f => selectedTask?.assign_to?.indexOf(f.id) === -1).length === 0 && <p className="text-center">No user found</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <TaskObserver assigneeLength={selectedTask?.assign_to.length} OnUpdateTask={OnUpdateTask} />
                    </div>
                </div>
            </div >
            {
                assignPopup === false &&
                <>
                    {
                        selectedTask?.assign_to.length === 0 ?
                            <Tooltip style={{ fontSize: '12px', marginTop: '20px' }} delayShow={1000} anchorSelect="#assignee" place="top" content="Click to add assignee(s)"></Tooltip> :
                            <Tooltip style={{ fontSize: '12px', marginTop: '20px' }} delayShow={1000} anchorSelect="#updateAssignee" place="top" content="Click to update assignee(s)"></Tooltip>
                    }
                </>
            }
        </>
    );
};

export default TaskAssignee;





