import React, { useState, useEffect } from 'react';
import { MODULE_ADD } from '../../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";


function CreateEditModule({ modules, company_id, setModules, setCreatePopup, editModule, setEditModule }) {
    const [moduleName, setModuleName] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [loader, setLoader] = useState(false);

    const [update] = useMutation(MODULE_ADD);

    // Initialize state when editModule changes
    useEffect(() => {
        if (editModule) {
            setIsEditMode(true);
            setModuleName(editModule.module_title);
        } else {
            setIsEditMode(false);
            setModuleName('');
        }
    }, [editModule]);

    const handleCreateOrUpdateModule = async () => {
        if (isEditMode) {
            // Update existing module
            setModules((prevModules) =>
                prevModules.map((module) =>
                    module.module_id === editModule.module_id
                        ? { ...module, module_title: moduleName }
                        : module
                )
            );
        } else {
            // Create new module
            setLoader(true);
            try {
                const { data: moduleAddData } = await update({
                    variables: {company_id, module_title: moduleName, is_sub_module: false}
                });
                const { status, message, data } = moduleAddData.module_add;
                if (status) {
                    // console.log(45, data)
                    setModules((prevModules) => [...prevModules, data]);
                }
            } catch (error) {
                // Handle error if needed
            } finally {
                setLoader(false);
            }
            
        }

        // Reset fields and close modal
        setModuleName('');
        setCreatePopup(false);
        setEditModule(null);
    };

    const handleInputChange = (event) => {
        setModuleName(event.target.value);
    };

    return (
        <div className="backwrap">
            <div className="createTeamModal">
                <div className="createTeamModalHead">
                    <h4 className="popupTitle">
                        {isEditMode ? 'Edit Module' : 'Create Module'}
                    </h4>
                    <button
                        className="closeModal"
                        onClick={() => {
                            setCreatePopup(false);
                            setEditModule(null);
                        }}
                    >
                        &times;
                    </button>
                </div>
                <div className="createTeamModalBody">
                    <div className="crTeam_inputGroup">
                        <label htmlFor="moduleName">Module Name</label>
                        <input
                            type="text"
                            id="moduleName"
                            maxLength="25"
                            placeholder="Enter module name"
                            value={moduleName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='createTeamModalFoot'>
                        <button
                            className={`btnAction ${moduleName ? '' : 'opacity-50 pointer-events-none'}`}
                            onClick={handleCreateOrUpdateModule}
                            disabled={!moduleName} // Disable button when input is empty
                        >
                            {isEditMode ? 'Update Module' : 'Create Module'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEditModule;
