/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './FileHub.css';
import { useQuery, useApolloClient } from '@apollo/client'
// import { GET_ME } from '../../Utils/GraphqlQueries';
import { OTHERS_TAG_Details } from '../../Utils/GraphqlQueries';
import { useDispatch } from 'react-redux';
import { set_related_tag_popup, set_view_tag_files } from '../../redux/message';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useClickAway } from '@uidotdev/usehooks';
import { MdClose } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';

function TagRelatedPopup({relatedTag, setrelatedTag}) {
    const params = useParams()
    const selected_tag = useSelector(state => state.message.selected_tag)
    // console.log("🚀 ~ TagRelatedPopup ~ selected_tag_open:", selected_tag)
    // const client = useApolloClient();
    const dispatch = useDispatch()
    
    const [selectAll, setSelectAll] = useState(false);
    const [searchByVenueVal, setsearchByVenueVal] = useState('');

   const relatedTagF = () => 
    relatedTag.filter(f => 
        searchByVenueVal !== '' 
            ? f.title.toLowerCase().includes(searchByVenueVal.toLowerCase()) 
            : true
    );

    // console.log('relatedTagF',searchByVenueVal, relatedTagF, relatedTag);

    const searchByVenue = (v) => {
        setsearchByVenueVal(v);
    };

    const toggleCheckbox = (id) => {
        // debugger;
        const updatedtags = relatedTag.map((tag) =>
            tag.tag_id === id ? { ...tag, isChecked: !tag.isChecked } : tag
        );
        setrelatedTag(updatedtags);
        setSelectAll(updatedtags.every((tag) => tag.isChecked));
    };

    // const toggleSelectAll = () => {
    //     const updatedtags = relatedTag.map((tag) => ({
    //         ...tag,
    //         isChecked: !selectAll,
    //     }));
    //     setrelatedTag(updatedtags);
    //     setSelectAll(!selectAll);
    // };

    const toggleSelectAll = () => {
        // Get the filtered tags that match the current search criteria
        const filteredTags = relatedTagF();

        // Update the filtered tags' isChecked property based on the selectAll state
        const updatedFilteredTags = filteredTags.map((tag) => ({
            ...tag,
            isChecked: !selectAll,
        }));

        // Merge updated filtered tags back into the full relatedTag array
        const updatedTags = relatedTag.map((tag) =>
            updatedFilteredTags.find((fTag) => fTag.tag_id === tag.tag_id) || tag
        );

        // Update the state
        setrelatedTag(updatedTags);

        // Update the selectAll state
        setSelectAll(!selectAll);
    };

    const goToFiles = () => {
        // debugger;
        let tag_ids = [selected_tag?.tag_id, ...relatedTagF().filter(v => v.isChecked).map(t => t.tag_id)];
        // console.log('goto:tag_ids',tag_ids,relatedTag,relatedTagF())
        dispatch(set_view_tag_files({ state: true, tag_ids: tag_ids  }));
        dispatch(set_related_tag_popup(false));
    }
    

    const ref = useClickAway(() => {
        dispatch(set_related_tag_popup(false))
    }, ".tagRelatedPopup")

    // Filter data based on search term
    

    return (
        <div className="tagRelatedPopup filehub " ref={ref}>
            <div className='tagToggle_sidebar '>

                <div className="tagToggle_sidebar_head rounded-t-2xl">
                    <div className="tagsText !w-auto !text-[20px] text-[#0b1f47] pr-1">Tags</div>
                    <div className="tagBorderLine"></div>
                    <div className="tagsRelatedText">Related tags</div>
                     {/* <MdClose  size={21} className='text-[#0a256a] text-4xl cursor-pointer absolute top-0 right-3' /> */}
                     <span id='popupClose' onClick={() => {dispatch(set_related_tag_popup(false))}}  className="createConv_close profileClose !w-[21px] !h-[21px] !top-[15px]"></span>
                      <Tooltip delayShow={1000} className="z-50" anchorSelect="#popupClose" float="true" content="Click to view tags"></Tooltip>
                </div>
                <div className='tagToggle_sidebar_serach'>
                    <div className='selectTags'>
                        <div className="selectedTagCircle"></div>
                        <div className="selectedTagLabel">{selected_tag?.title}</div>
                    </div>
                </div>
                <div className='instructionPara'>
                    The tags below are related to your search. Pick one or more tags to refine your search further.
                </div>
                {relatedTag.length > 0 &&
                    <div className='selectAllArea'>
                        <div className="checkboxes__item">
                            <label className="checkbox style-c" >
                                <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Select all</div>
                            </label>
                        </div>
                        <div className="searchareaDiv">
                            <input
                                value={searchByVenueVal}
                                onChange={(event) => searchByVenue(event.target.value)}
                                className="_inputBar searchLeft"
                                type="text"
                                placeholder="Search tags"
                                autoFocus
                            />
                            {searchByVenueVal !== "" && (
                                <span
                                   
                                    className="removeFile !absolute !right-2 !h-[18px] !float-right !-mt-[35px] !block !top-[42px]"
                                    onClick={() => setsearchByVenueVal("")}
                                    data-for="member_tooltip"
                                    data-tip="Clear Search"
                                ></span>
                            )}
                        </div>
                        <div className='tagFileTotal'>{relatedTagF().length} File tags</div>
                    </div>
                }
                <div className='allTagsArea'>
                {relatedTagF().length > 0 ?
                    <ul className="alltagList">
                        {relatedTagF().map((tags) => (
                            <li key={tags.tag_id}>
                                <div className='tagsRelateLists'>
                                    <div className="checkboxes__item">
                                        <label className="checkbox style-c" >
                                            <input type="checkbox"
                                                checked={tags.isChecked}
                                                onChange={() => toggleCheckbox(tags.tag_id)}
                                            />
                                            <div className="checkbox__checkmark"></div>
                                            <div className="checkbox__body">{tags.title}</div>
                                        </label>
                                    </div>
                                    {/* <div className='tagFileTotal'>19 Files</div> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                    :
                    <div className="no_files_available">No data found matching the criteria.</div>
                    }
                </div>
                <div className='tagCoupleBtn'>
                    <div className="skipButton" onClick={() => {
                        dispatch(set_related_tag_popup(false));
                        dispatch(set_view_tag_files({ state: false, tag_ids: [] }))
                    }}>Skip</div>
                    <div className={classNames("viewFilesButton" , relatedTagF()?.filter(v => v.isChecked)?.length === 0 ? 'inactive' : '')}
                    onClick={goToFiles}>View Files</div>
                </div>
            </div>

        </div>
    )
}

export default TagRelatedPopup;