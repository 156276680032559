

// const baseURL = process.env.REACT_APP_API || "http://bd.freeli.io:4001/";

const baseURL =  "https://cadevapicdn02.freeli.io";
const wsbaseURL = "wss://cadevapicdn02.freeli.io";
//const baseURL =  "https://bddevcdn02.freeli.io/v2/v2"; 
//const baseURL =  "https://cacdn02.freeli.io/v2/v2"; 
//const baseURL =  "/api";
//const baseURL =  "https://cacacheapicdn02.freeli.io/v2";
//const baseURL =  "https://cadevapicdn02.freeli.io";
//const baseURL =  "http://182.163.122.134:6081/v2";
//const xmpp_domain = 'bddevquecdn02.freeli.io';
const xmpp_domain = 'caquecdn02.freeli.io';
const FILE_SERVER = "https://wfss001.freeli.io";
const return_data = {
    "FILE_SERVER": "https://wfss001.freeli.io/",
    "baseURL":baseURL,
    "xmpp_domain":xmpp_domain
}
//export {return_data};
export {return_data, FILE_SERVER, baseURL, wsbaseURL};
