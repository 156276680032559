/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SET_NEW_PASSWORD } from '../../Utils/GraphqlMutaion';
import { useMutation } from '@apollo/client';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const ChangePassword = ({ onClose, user, from_admin }) => {
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    })

    const [type1, setType1] = useState('password');
    const [type2, setType2] = useState('password');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [passwordMatchValid, setPasswordMatchValid] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [matchErrorMessage, setMatchErrorMessage] = useState('');
    const [submitNewPass] = useMutation(SET_NEW_PASSWORD);

    const updatePassword = async () => {
        if (isPasswordValid && passwordMatchValid) {
            let apires = await submitNewPass({
                variables: {
                    input: {
                        email: user.email,
                        password: password.newPassword?.trim(),
                        password2: password.confirmPassword?.trim(),
                    }
                }
            });
            // console.log("🚀 ~ updatePassword ~ apires:", apires)

            if (apires.data.set_new_password.status) {
                toast.success('Password changed successfully');
                onClose();
                if (!from_admin) {
                    setTimeout(() => {
                        localStorage.clear();
                        window.location.href = "/login"
                    }, 1000)
                } else {
                    setTimeout(() => {
                        onClose();
                    }, 500)
                }
            }

        } else {
            if (!isPasswordValid) {
                toast.error('Invalid password');
            } else if (!passwordMatchValid) {
                toast.error('Passwords do not match');
            }
        }
    }

    const strongPasswordValidate = (value) => {
        // console.log("🚀 ~ strongPasswordValidate ~ target:", value)
        // (?=.*[!@#\$%\^&\*]) Special character validation
        // (?=.*[A-Z]) Uppercase validation
        let reg = /^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/;
        let test = reg.test(value);
        return test;
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setPassword({ ...password, [name]: value?.trim() })
    };

    useEffect(() => {
        let isValid = strongPasswordValidate(password.newPassword);
        let isEqual = (password.newPassword === password.confirmPassword)
        setIsPasswordValid(isValid);
        setPasswordMatchValid(isEqual);
        if (!isValid) {
            if (password.newPassword?.trim() !== '') {
                setPasswordErrorMessage('Invalid Password');
            } else {
                setPasswordErrorMessage('');
            }
        } else {
            setPasswordErrorMessage('');
        }

        if (!isEqual) {
            if (password.confirmPassword?.trim() !== '') {
                setMatchErrorMessage('Passwords do not match');
            } else {
                setMatchErrorMessage('');
            }
        } else {
            setMatchErrorMessage('');
        }
    }, [password])

    const previewPassword = (e, type) => {
        if (type === 'newPass') {
            setType1(prev => (prev === 'password' ? 'text' : 'password'));
        } else {
            setType2(prev => (prev === 'password' ? 'text' : 'password'));
        }
    };

    const close_popup = () => {
        onClose();
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    // console.log(password.newPassword.length)
    return (
        <>
            <Toaster />

            <div className='backwrap'>
                <div className='passwordModal'>
                    <div className='passwordModalHead'>
                        <h4 className='popupTitle'>Change password</h4>
                        <span onClick={close_popup} className='closeModal' data-for='rightSection_tooltip' data-tip='Close'></span>
                    </div>
                    <div className='passwordModalBody'>
                        <div className='passInputGroup'>
                            <label className='passInputLabel'>New password</label>
                            <span className='passwordLock'></span>
                            <span
                                className={classNames('passwordView', type1 === 'text' && 'active')}
                                onClick={event => previewPassword(event, 'newPass')}
                                data-for='loginTooltip'
                                data-tip='Click to view the password as plain text'
                                style={password.newPassword === '' ? { pointerEvents: 'none' } : {}}
                            ></span>
                            <input
                                type={type1}
                                className='passInput error'
                                id='newPass'
                                name='newPassword'
                                onChange={e => handleChange(e)}
                                required
                                value={password.newPassword}
                                autoComplete='new-password'
                            />
                            {
                                password.newPassword.length > 0 && password.newPassword.length <= 5 ? <span style={{ color: "red" }} className='errorLabel !visible'>Invalid Password</span> : <span className='rgInputMsg errorLabel'>{passwordErrorMessage}</span>
                            }

                            <span className='rgInputMsg'>
                                Create a strong password that is at least 6 characters long. Include numbers, lower case characters to increase the strength of your password.
                            </span>
                            <span className='passwordPlaceholder'>......</span>
                        </div>
                        <div className='passInputGroup'>
                            <label className='passInputLabel'>Confirm password</label>
                            <span className='passwordLock'></span>
                            <span
                                className={classNames('passwordView', type2 === 'text' && 'active')}
                                onClick={event => previewPassword(event, 'confPass')}
                                data-for='loginTooltip'
                                data-tip='Click to view the password as plain text'
                                style={password.confirmPassword === '' ? { pointerEvents: 'none' } : {}}
                            ></span>
                            <input
                                type={type2}
                                className='passInput error'
                                id='confPass'
                                name='confirmPassword'
                                onChange={e => handleChange(e)}
                                value={password.confirmPassword}
                                autoComplete='new-password'
                                required
                            />
                            {/* {
                                password.confirmPassword.length > 0 && password.newPassword.length <= 5 ? <span style={{ color: "red" }} className='errorLabel !visible'>Passwords do not match</span> : <span className='rgInputMsg errorLabel'>{matchErrorMessage}</span>
                            } */}
                            <span className='rgInputMsg errorLabel'>{matchErrorMessage}</span>
                            {/* <span className='rgInputMsg errorLabel'>{matchErrorMessage}</span> */}
                            <span className='passwordPlaceholder'>......</span>
                        </div>
                    </div>
                    <div className='passwordModalFoot'>
                        <button className='btnCancel' onClick={close_popup}>
                            Cancel
                        </button>
                        <button
                            className={`btnAction ${(!isPasswordValid || !passwordMatchValid) ? 'inactive' : ''}`}
                            style={{ opacity: (!isPasswordValid || !passwordMatchValid) ? 0.5 : 1 }}
                            onClick={updatePassword}
                            disabled={!isPasswordValid || !passwordMatchValid}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
