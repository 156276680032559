/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router';
import { Get_notifications } from '../../Utils/GraphqlQueries';
import Moment from 'react-moment';
import 'moment-timezone';
import { Tooltip } from 'react-tooltip';
import { GET_SEPERATOR } from '../../Utils/Common';

const Notifications = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true);
    const [tabType, setTabType] = useState("no");
    const [all_notification, setall_notification] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, totalPages: 1, total: 0 });
    const [prevloader, setPrevloader] = useState(false)
    const containerRef = useRef(null);

    const [getNotification] = useLazyQuery(Get_notifications, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            console.log("🚀 ~ Notifications ~ res:", res)
            if (res) {
                setall_notification((prev) => (pagination.page === 1 ? res?.get_notifications?.notification : [...prev, ...res?.get_notifications?.notification]));
                setPagination(res?.get_notifications?.pagination)
                setLoader(false)
                setPrevloader(false)
            }
        },
        onError: (error) => {
            console.log("🚀 ~ Notifications ~ error:", error)
        }
    })

    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        setLoader(true)
        scrollToTop()
        setall_notification([]);
        setPagination(prev => ({ ...prev, page: 1 }));
        getNotification({ variables: { read_status: tabType, page: '1' } })
    }, [tabType])

    const handleGoBack = () => {
        navigate(-1)
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                navigate(-1)
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const prevScrollHeight = useRef(0);
    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            if (!prevloader) {
                if (pagination.totalPages > pagination.page) {
                    let container = containerRef.current;
                    prevScrollHeight.current = container.scrollHeight;
                    console.count('Calling API...')
                    setPrevloader(true)
                    setPagination(prev => ({ ...prev, page: prev.page + 1 })); // Increment page for pagination
                    getNotification({ variables: { read_status: tabType, page: (pagination.page + 1).toString() } })
                }
            }
        }
    }

    return (
        <div className="right_container">
            <div className="notificationHeader">
                <span onClick={handleGoBack} className="backToChat ml-[20px] mr-0" data-for="galleryTooltip" data-tip="Back"></span>
                <div className="action_list">
                    <div onClick={() => setTabType("no")} className={tabType === "no" ? "notiActions active" : "notiActions"}>Unread notification(s)</div>
                    <div onClick={() => setTabType("yes")} className={tabType === "yes" ? "notiActions active" : "notiActions"}>Read (History)</div>
                    <span onClick={handleGoBack} className="backToChat closeBtn" id='close11'></span>
                </div>
                <Tooltip anchorSelect="#close11" place='top' content="Delete"></Tooltip>
            </div>

            <div className="notify_boundary relative" onScroll={handleScroll} ref={containerRef}>
                {
                    loader ?
                        <div className={`msg_screen_loader fixed w-[calc(100%-346px)] h-[calc(100%-76px)] flex items-center justify-center right-0 top-[76px] bg-[#f9f9f9b0] z-10`}>
                            <div className="loaderMain mini_loader"></div>
                        </div>
                        : all_notification.length > 0 ?
                            all_notification?.map(m => ({ ...m, created_at: m.created_at }))?.map((v, k) => {
                                let separator = GET_SEPERATOR(all_notification?.[k - 1], v)
                                return (
                                    <Fragment key={'noti_' + k}>
                                        {separator !== '' && <div className="msgSeparator" ><p className="z-10">{separator}</p></div>}
                                        <div key={v.created_at} className="notification_msg">
                                            <div className="sender_img">
                                                <span className="nameLetters">{v.created_by_name.charAt(0)}</span>
                                            </div>
                                            <div className="msg_info" style={{ width: 'calc(100% - 45px)' }}>
                                                <div className="sender_info flex !justify-between">
                                                    <p className="sender_name !text-sm">{v?.created_by_name}</p>
                                                    <p className="msg_time !text-xs">
                                                        <Moment format="h:mm A">{v?.created_at}</Moment>
                                                    </p>
                                                </div>
                                                <div className="msg_body">{v?.title}</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })
                            :
                            <div className="fileNotFound ">
                                <div className='!-mt-10'>
                                    <div className="notificariontextArea">
                                        <div className="notificationIcon flex justify-center">
                                            <img src="/media/images/NotificationsPage_icon.png" alt="notificationIcon" title='notificationIcon' /></div>
                                        <div className="notificationText">
                                            <h2 className="notificationHeading">Welcome to your notification page</h2>
                                        </div>
                                        <div className="notificationIconImages flex justify-center">
                                            <img src="/media/images/NotificationsPage_images.png" alt="NotificationsPage_images.png" title='NotificationsPage_images' /></div>
                                        <div className="notificationIconPara">
                                            <p className="darkTextWhite">Notification about who reacted to your messages, what</p>
                                            <p className="darkTextWhite">messages has been deleted from a conversation, and who </p>
                                            <p className="darkTextWhite">has been removed from a room will be listed here.</p>
                                        </div>
                                        <div className="notificationIconButton">
                                            <div className="buttonText">You have no new notifications!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </div>
        </div>
    );
};

export default Notifications;