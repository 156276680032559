import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    allUser: [],
    all_venue: null,
    eod_venu_member: [],
}

export const admin = createSlice({
    name: 'admin',
    initialState,

    reducers: {
        all_Users: (state, action) => {
            return ({
                ...state,
                allUser: action.payload
            })
        },

        all_venue_result: (state, action) => {
            return ({
                ...state,
                all_venue: action.payload
            })
        },

        // EOD_Venue_userList_add btn in = Add member(s) to this venue
        add_eod_venu_member: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_participants: action.payload,
                },
            };
        },

        // EOD_Venue_userList_remove btn
        remove_eod_venu_member: (state, action) => {
            const updatedMembers = state.all_venue.eod_participants.filter(
                (data) => data !== action.payload.id
            );

            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_participants: updatedMembers,
                },
            };
        },


        // admin setting >> Venue management >> EOD_member >> Submitter >> checkBox
        set_eod_Venue_check_Submitter: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_submitters: action.payload,
                },
            };
        },



        // admin setting >> Venue management >> EOD_member >> EOD Venue_checkBox_Re_Open
        set_eod_Venue_check_Re_Open: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_reopen: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> EOD_member >> EOD Venue_ Email checkBox 
        set_eod_Venue_check_eod_email: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_email: action.payload,
                },
            };
        },



        // maintanence_Venue_userList_add btn in = Add member(s) to this venue   = Maintanence
        add_Maintanence_venu_member: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_participants: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> maintanence >> Submitter >> update checkBox
        set_maintanence_Venue_update_check_Submitter: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_submitter: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> maintanence >> Approve >> update checkBox
        set_maintanence_Venue_update_check_Approve: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_approver: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> maintanence >> Observer >> Observer update checkBox
        set_maintanence_Venue_update_check_Observer: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_observer: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> maintanence >> Email >> update checkBox
        set_maintanence_Venue_update_check_Email: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_memail: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> EOD >> Recipent User >> update user
        set_contact_EOD_Contact_user: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    eod_contact_participants: action.payload,
                },
            };
        },


        // admin setting >> Venue management >> maintenance_contact_participants >> Recipent User >> update user
        set_contact_Maintanence_Contact_user: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_contact_participants: action.payload,
                },
            };
        },

           
        set_EOD_Contact_Email: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    recipients: action.payload,
                },
            };
        },


        set_maintanence_Contact_Email: (state, action) => {
            return {
                ...state,
                all_venue: {
                    ...state.all_venue,
                    maintenance_recipients: action.payload,
                },
            };
        },


        set_New_Contact_user: (state, action) => {
            return {
                ...state,
                allUser: [...state.allUser, ...action.payload],
            };
        },








    },
})

export const { all_Users,
    all_venue_result,
    add_eod_venu_member,
    remove_eod_venu_member,
    set_eod_Venue_check_Submitter,
    set_eod_Venue_check_Re_Open,
    set_eod_Venue_check_eod_email,


    add_Maintanence_venu_member,
    set_maintanence_Venue_update_check_Submitter,
    set_maintanence_Venue_update_check_Approve,
    set_maintanence_Venue_update_check_Observer,
    set_maintanence_Venue_update_check_Email,

    set_contact_EOD_Contact_user,
    set_contact_Maintanence_Contact_user,

    set_EOD_Contact_Email,
    set_maintanence_Contact_Email,
    set_New_Contact_user

} = admin.actions

export default admin.reducer