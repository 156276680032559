import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { GETVENULIST, GET_ME } from '../../Utils/GraphqlQueries';
import {useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client';

function EodVenuesRelatedPopup(props) {
    const [searchByVenueVal, setsearchByVenueVal] = useState('');
    // const [venueLists, setvenueLists] = useState([]);
    // const [selectAll, setSelectAll] = useState(false);

    const [loader, setLoader] = useState(false);

    const client = useApolloClient();
    const cacheMe = client.readQuery({ query: GET_ME });

    const getVenuePermit = (venue) => {
        if (venue.venue_title === 'All') return false;

        let Submitter = venue?.eod_submitters;
        let Reopener = venue?.eod_reopen;

        if (Reopener?.includes(cacheMe.me.id) || Submitter?.includes(cacheMe.me.id)) { return true }
        else { return false }

        // if (Reopener?.includes(cacheMe.me.id) && Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Submitter?.includes(cacheMe.me.id)) { return true }
        // else if (Reopener?.includes(cacheMe.me.id)) { return false }
        // else { return false }


    }


    const venueLists = () => 
        props.venueLists
        .filter(f => getVenuePermit(f))
        .filter(f => searchByVenueVal !== '' ? f.venue_title.toLowerCase().includes(searchByVenueVal.toLowerCase()) : true
    );

   

    const searchByVenue = (v) => {
        setsearchByVenueVal(v);
    };

    const toggleCheckbox = (id) => {
        const updatedVenues = props.venueLists.map((projects) =>
            projects.id === id ? { ...projects, isChecked: !projects.isChecked } : projects
        );
        props.setvenueLists(updatedVenues);
        props.setSelectAll(updatedVenues.every((projects) => projects.isChecked));
    };

    // const toggleSelectAll = () => {
    //     const updatedVenues = props.venueLists.map((projects) => ({
    //         ...projects,
    //         isChecked: !props.selectAll,
    //     }));
    //     props.setvenueLists(updatedVenues);
    //     props.setSelectAll(!props.selectAll);
    // };

    const toggleSelectAll = () => {
        // Get the filtered venues that match the current search criteria
        const filteredVenues = venueLists();

        // Update the isChecked property of filtered venues based on the selectAll state
        const updatedFilteredVenues = filteredVenues.map((venue) => ({
            ...venue,
            isChecked: !props.selectAll,
        }));

        // Merge updated filtered venues back into the full venueLists array
        const updatedVenues = props.venueLists.map((venue) =>
            updatedFilteredVenues.find((fVenue) => fVenue.id === venue.id) || venue
        );

        // Store the updated venue lists, including the unselected ones
        props.setvenueLists(updatedVenues);

        // Update the selectAll state
        props.setSelectAll(!props.selectAll);
    };


    // const resetCheckboxes = () => {
    //     const updatedVenues = props.venueLists.map((projects) => ({
    //         ...projects,
    //         isChecked: false,
    //     }));
    //     props.setvenueLists(updatedVenues);
    //     setSelectAll(false);
    // };

    const isAnyChecked = props.venueLists.some((projects) => projects.isChecked);
    
    const { data: venue_list, loading: loading } = useQuery(GETVENULIST, { fetchPolicy: 'no-cache' })
    
    useEffect(() => {
        if (props.venueLists.length === 0 && venue_list?.get_venue_list?.status) {
            props.setvenueLists(venue_list?.get_venue_list.result.filter(f=> f.id))
        }
    }, [venue_list])

    return (
        
        <div className="tagRelatedPopup forKeywords" style={{ width: '550px' }}>
            <div className='tagToggle_sidebar'>
                <div className='tagToggle_sidebar_head' style={{ paddingLeft: '30px' }}>
                    <span  className="closeAdminPanel" onClick={props.close_related_venue_popup}></span>
                    <div className='tagsText'>Venues</div>
                    <div className="searchareaDiv">
                        <input
                            value={searchByVenueVal}
                            onChange={(event) => searchByVenue(event.target.value)}
                            className="_inputBar searchLeft"
                            type="text"
                            placeholder="Search a venue"
                            autoFocus
                        />
                        {searchByVenueVal !== "" && (
                            <span
                               
                                className="removeFile !absolute !right-2 !h-[18px] !float-right !-mt-[35px] !block !top-[42px]"
                                onClick={() => setsearchByVenueVal("")}
                                data-for="member_tooltip"
                                data-tip="Clear Search"
                            ></span>
                        )}
                    </div>
                </div>
                        
                {
                    loading ?
                    <div className='h-full w-full flex items-center justify-center absolute left-0 top-50 z-[1] bg-white/90'>
                        <div className="loaderMain mini_loader"></div>
                    </div> :
                    venueLists().length ? (
                    <>
                        <div className='selectAllArea'>
                            <div className="checkboxes__item">
                                <label className="checkbox style-c">
                                    <input
                                        type="checkbox"
                                        checked={props.selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                    <div className="checkbox__checkmark"></div>
                                    <div className="checkbox__body">Select all</div>
                                </label>
                            </div>
                        </div>
                        <div className='allTagsArea'>
                            <ul className="alltagList">
                                {venueLists().map((projects) => (
                                    <li key={projects.id}>
                                        <div className='tagsRelateLists'>
                                            <div className="checkboxes__item">
                                                <label className="checkbox style-c">
                                                    <input
                                                        type="checkbox"
                                                        checked={projects.isChecked}
                                                        onChange={() => toggleCheckbox(projects.id)}
                                                    />
                                                    <div className="checkbox__checkmark"></div>
                                                    <div className="checkbox__body">{projects.venue_title}</div>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                ) : (
                    <div className='filter-no-user'>No data found matching the criteria.</div>
                )}

                <div className='tagCoupleBtn'>
                    <div
                        className="skipButton"
                        onClick={() => {
                            props.close_related_venue_popup();
                            props.setVenueTopFilter(false);
                        }}
                    >
                        Skip
                    </div>
                    <div
                        className="skipButton"
                        style={{ width: '80px', opacity: isAnyChecked ? 1 : 0.5, cursor: isAnyChecked ? 'pointer' : 'not-allowed' }}
                        onClick={() => {
                            if (isAnyChecked) {
                                props.resetCheckboxes();
                                props.setVenueTopFilter(false);
                            }
                        }}
                    >
                        Reset
                    </div>
                    <div
                        className="viewFilesButton"
                        style={{ width: '75px', opacity: isAnyChecked ? 1 : 0.5, cursor: isAnyChecked ? 'pointer' : 'not-allowed' }}
                        onClick={() => {
                            if (isAnyChecked) {
                                props.setVenueTopFilter(null);
                                setTimeout(()=>{
                                    props.setVenueTopFilter(true);
                                },100)
                                
                                props.close_related_venue_popup();
                            }
                        }}
                    >
                        View
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EodVenuesRelatedPopup;
