/* eslint-disable react-hooks/exhaustive-deps */

import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { MdAdd } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { useClickAway } from "@uidotdev/usehooks";
import { getONLYUSERSDATA, isValidConvId, modifyMyMsg } from '../../../Utils/Common'
import TaskStatus from './TaskStatus';
import TaskProgress from './TaskProgress';
import TaskStartEndDate from './TaskStartEndDate';
import TaskDueTime from './TaskDueTime';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import TrackCostPopup from './TrackCostPopup';
import TrackHourPopup from './TrackHourPopup';
import TaskChatSections from './TaskChatSections';
import { IoChatbubblesOutline } from "react-icons/io5";
import TaskDescriptionsPopup from './TaskDescriptionsPopup';
import TaskNotesPopup from './TaskNotesPopup';
import TaskFilePopup from './TaskFilePopup';
import TaskCheckListPopup from './TaskCheckListPopup';
import TaskAssignee from './TaskAssignee';
import TaskRoomSelect from './TaskRoomSelect';
import TaskProject from './TaskProject';
// import ChangeOwnership from './ChangeOwnership';
import Moment from "react-moment";
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USERS, GET_SINGLE_TASK } from '../../../Utils/GraphqlQueries';
import { CREATE_NEW_KEY_WORD } from '../../../Utils/GraphqlMutaion';
import { Tooltip } from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { set_active_discussion, set_active_task } from '../../../redux/taskSlice';
import { useTaskContext } from '../TaskContext';
import { set_active_conversation } from '../../../redux/message';
import { useSelector } from 'react-redux';
import { UploadProvider } from '../../../context/UploadContext'
import FileUploadNew from '../../Connect/FileUpload/FileUploadNew';
import TaskWarningPopup from './TaskWarningPopup';
import { validFirstChercterAlsoNuber } from '../../ValidationCheck';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import sortArrayByKey from '../../../hooks/useSortedArray';
import { RiCloseCircleLine } from "react-icons/ri";

const TaskPropertiesCon = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const client = useApolloClient();
    const { set_tasks, user, keywords, delete_task, OnUpdateTask, selectedTask, setSelectedTask, trackCostPopup, setTrackCostPopup, taskFileUpload, setTaskFileUpload, isObserver } = useTaskContext();
    const conversations = useSelector(state => state.message.conversations)
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [create_keyword] = useMutation(CREATE_NEW_KEY_WORD)
    const [all_keywords_data, setAllKeyWordsData] = useState(keywords);
    // const [all_checklists, setAll_checklists] = useState([]);
    const [cost_id, set_cost_id] = useState('');
    const [temp_room, set_temp_room] = useState(null);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [loader, setLoader] = useState(true)
    const sectionRef = useRef(null);


    useEffect(() => {
        if (params.task_id) {
            setLoader(true)
        }
        setTaskDeletePopup(false)
        setProjectPopup(false)
        setTrackCheckListPopup(false)
        setTrackHourPopup(false)
        setTaskFilesPopup(false)
        setTaskDescriptionsPopup(false)
        setTaskNotesPopup(false)
        handleTrackCostPopupClose()
    }, [params.task_id])


    // Check if params.task_id exists and matches the pattern
    const taskIdPattern = /^[a-fA-F0-9]{24}$/; // Regex to match a 24-character hexadecimal MongoDB ObjectId
    const shouldFetch = params.task_id && taskIdPattern.test(params.task_id);
    useQuery(GET_SINGLE_TASK, {
        variables: shouldFetch ? { _id: params.task_id } : undefined,
        fetchPolicy: 'no-cache',
        skip: !shouldFetch, // Skip the query if shouldFetch is false
        onCompleted: async (res) => {
            if (res?.task?.status) {
                const taskData = res.task.data?.[0] || {};
                if ((taskData?.created_by === user.id || taskData?.assign_to?.includes(user.id) || taskData?.observers?.includes(user.id))) {
                    setSelectedTask({ ...taskData });
                    dispatch(set_active_task({ ...taskData }));
                    set_tasks(prev => prev.map(t => t._id === taskData?._id ? { ...t, view_update: t.view_update?.filter(uid => uid !== user.id) } : t))
                    if (taskData.discussion) {
                        const discussion = await modifyMyMsg(taskData.discussion);
                        dispatch(set_active_discussion(discussion));
                    }
                    if (!isValidConvId(params.conversation_id)) {
                        const activeConversation = conversations.find((f) => f.conversation_id === taskData.conversation_id);
                        dispatch(set_active_conversation(activeConversation || null));
                    }
                    setLoader(false)
                } else {
                    toast.error('You don\'t have access to the task.')
                    closePopUp()
                }
            }
        },
    });

    useEffect(() => {
        if (selectedTask) {
            setSelectedRoom(conversations?.filter(f => f.conversation_id === selectedTask?.conversation_id)[0])
            // setAll_checklists(selectedTask.checklists || []);
        }
    }, [selectedTask])

    const handleScroll = () => {
        if (!sectionRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = sectionRef.current;
        // Check if the scroll is at or near the bottom
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            setIsButtonVisible(false);
        } else {
            setIsButtonVisible(true);
        }
    };
    const scrollToBottom = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollTo({
                top: sectionRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const currentRef = sectionRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
            // Check on initial load in case content already fills the container
            handleScroll();
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (keywords) {
            setAllKeyWordsData(keywords)
        }
    }, [keywords])

    const cacheAllUsers = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: user.company_id } });
    const [isEditTitle, setIsEditTitle] = useState(false);  // State to track whether the title is in edit mode
    const [addKeyWordPopup, setAddKeyWordPopup] = useState(false);
    const [newKeyword, setNewKeyword] = useState('');
    // const addKeyword = async () => {
    //     if (newKeyword.trim() !== '') {
    //         try {
    //             let apires = await create_keyword({
    //                 variables: {
    //                     input: {
    //                         keywords_title: newKeyword
    //                     }
    //                 }
    //             })
    //             if (apires.data.create_new_key_word.status) {
    //                 let { _id, keywords_title } = apires.data.create_new_key_word.data;
    //                 setAllKeyWordsData(prev => [{ _id, keywords_title }, ...prev])
    //                 toast.success(apires.data.create_new_key_word.message)
    //             } else {
    //                 toast.success(apires.data.create_new_key_word.message)
    //             }
    //             console.log("🚀 ~ addKeyword ~ apires:", apires)

    //         } catch (error) {
    //             console.log(error);
    //         }
    //         setNewKeyword('');
    //     }
    // };
    const addKeyword = async () => {
        const trimmedKeyword = newKeyword.trim();

        if (trimmedKeyword !== '') {
            // Check if the keyword already exists (case-insensitive and ignoring spaces)
            const keywordExists = all_keywords_data.some(
                (keyword) => keyword.keywords_title.trim().toLowerCase() === trimmedKeyword.toLowerCase()
            );

            if (keywordExists) {
                toast.error('Keyword already exists');
                return;
            }

            try {
                let apires = await create_keyword({
                    variables: {
                        input: {
                            keywords_title: trimmedKeyword
                        }
                    }
                });

                if (apires.data.create_new_key_word.status) {
                    let { _id, keywords_title } = apires.data.create_new_key_word.data;
                    setAllKeyWordsData(prev => [{ _id, keywords_title }, ...prev]);
                    toast.success(apires.data.create_new_key_word.message);
                } else {
                    toast.success(apires.data.create_new_key_word.message);
                }

            } catch (error) {
                console.log(error);
            }
            setNewKeyword('');
        }
    };

    const selectKeyword = (keyword) => {
        OnUpdateTask({ key_words: [...selectedTask?.key_words, keyword.keywords_title], save_type: 'keyword' })
    }

    const [taskDescriptionsPopup, setTaskDescriptionsPopup] = useState(false);
    const [taskNotesPopup, setTaskNotesPopup] = useState(false);
    const [taskFilesPopup, setTaskFilesPopup] = useState(false);
    const [trackCheckListPopup, setTrackCheckListPopup] = useState(false);
    const [projectPopup, setProjectPopup] = useState(false);

    const handleTrackCostPopupOpen = () => {
        setTrackCostPopup(true);
    }

    const handleTrackCostPopupClose = () => {
        setTrackCostPopup(false);
    }
    const [trackHourPopup, setTrackHourPopup] = useState(false);

    const ref2 = useClickAway(() => {
        setAddKeyWordPopup(false)
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            OnUpdateTask({ task_title: tempTitle, save_type: 'tasktitle' });
            setIsEditTitle(false)
        }
    };

    const [tempTitle, setTempTitle] = useState('')

    const [taskDeletePopup, setTaskDeletePopup] = useState(false);
    const [taskRoomUpdateWarning, setTaskRoomUpdateWarning] = useState(false);

    const [projectEdit, set_projectEdit] = useState(false);

    const handleProjectEdit = () => {
        set_projectEdit(true);
        setProjectPopup(true);
    }

    const onConfirm = (type) => {
        if (type === 'delete') {
            delete_task(selectedTask?._id)
            setTaskDeletePopup(false)
        } else if (type === 'roomUpdate') {
            OnUpdateTask({
                conversation_id: temp_room.conversation_id,
                conversation_name: temp_room.title,
                conversation_img: temp_room.conv_img,
                participants: temp_room.participants,
                assign_to: [],
                observers: [],
            })
            setSelectedRoom(temp_room);
            setTaskRoomUpdateWarning(false)

            if (params.conversation_id && (temp_room.conversation_id !== params.conversation_id)) {
                closePopUp()
            }
        }
    }

    const handleInputChange = (e) => {
        const { value } = e.target;

        // If the value is empty or the user is clearing the input (backspace case)
        if (value === '') {
            // Allow clearing the input
            setNewKeyword(value);
            return;
        }

        // Use InputValidationCheck to validate the input (assuming title is not a number field)
        if (validFirstChercterAlsoNuber(value)) {
            console.warn('Invalid input for title'); // Can log or display an error message here
            return;
        }

        // Trim spaces from the start of the value
        const trimmedValue = value.trimStart();

        // If the title is empty or contains only spaces, we prevent further updates
        if (!trimmedValue) {
            console.warn('Title cannot be empty or contain only spaces');
            return;
        }

        // Update state with the validated value
        setNewKeyword(trimmedValue);

    };

    useEffect(() => () => {
        setSelectedTask(null)
    }, [])

    const closePopUp = () => {
        if (params.tasks_tab) {
            navigate(params.tasks_tab);
        } else {
            navigate('/connect/' + params.conversation_id);
        }
        setSelectedTask(null)
    }

    const filter_keywords = all_keywords_data?.filter(f => !selectedTask?.key_words?.some(k => k === f.keywords_title))?.filter(f => f.keywords_title.toLowerCase()?.indexOf(newKeyword.toLowerCase()) > -1)


    return (
        <>
            <div className='task_right_container forQuickViewTask'>
                {taskDeletePopup === true ?
                    <TaskWarningPopup
                        delete_task={delete_task}
                        selectedTask={selectedTask}
                        setTaskDeletePopup={setTaskDeletePopup}
                        onConfirm={onConfirm}
                        type="delete"
                    />
                    :
                    taskRoomUpdateWarning === true ?
                        <TaskWarningPopup
                            setTaskRoomUpdateWarning={setTaskRoomUpdateWarning}
                            type="roomUpdate"
                            onConfirm={onConfirm}
                        />
                        :
                        <div className="adminRightHead taskHeader" style={{ paddingLeft: '20px' }}>
                            <div className="taskHeader_right_area justify-end">
                                <span id="delete" className="Duetomorrow"></span>
                                {selectedTask?.created_by === user?.id && <div onClick={() => setTaskDeletePopup(true)} className="fileOptsCreateDet relative" id="deleteTask"></div>}
                                <div className="verTiclePipeLinee mr-1"></div>
                                <span><RiCloseCircleLine size={28} onClick={closePopUp} id="close" className='taskCloseIcon cursor-pointer' /></span>
                                {/* <span id="close" onClick={closePopUp} className="taskCreateCloseIcon cursor-pointer !top-[22px]"></span> */}
                                <Tooltip anchorSelect="#deleteTask" place='left' content="Delete"></Tooltip>
                            </div>
                        </div>
                }
                {loader &&
                    <div className='taskLoadBfrore h-full w-full flex items-center justify-center absolute left-0 top-[60px] z-[10] bg-white/90' style={{ height: 'calc(100% - 60px)' }}>
                        <div className="loaderMain mini_loader"></div>
                    </div>
                }
                <div className={`task_body_area ${taskDeletePopup || taskRoomUpdateWarning ? 'disabled' : ""} relative z-0`} ref={sectionRef}>
                    <div className='task_body_container'>
                        <div className="task_body_left quickShowTask">
                            <div className='quickViewTop flex justify-between'>
                                <TaskRoomSelect setTaskRoomUpdateWarning={setTaskRoomUpdateWarning} selectedRoom={selectedRoom} set_temp_room={set_temp_room} />
                                <div className='flex gap-4 items-center'>
                                    <div className="addOwnere topPropertiess mr-5">
                                        {/* <ChangeOwnership task={selectedTask} all_users={cacheAllUsers.users} /> */}
                                    </div>
                                    {isButtonVisible && <div id="chatHere" className=' cursor-pointer top-[68px] z-[999] right-4 fixed' onClick={scrollToBottom}>
                                        <IoChatbubblesOutline className='w-7 h-7' />
                                    </div>}
                                </div>
                            </div>
                            <div className="quickViewTop mt-1" >
                                {isEditTitle === true ?
                                    <div className="taskTitle_div">
                                        <input type="text" className="taskTitle" value={tempTitle} onChange={(e) => setTempTitle(e.target.value)} onKeyDown={handleKeyDown} name="taskTitle" id="taskTitle" placeholder="Add a Task title" maxLength="72" autoFocus />
                                        <MdOutlineCancel onClick={() => setIsEditTitle(false)} className='absolute top-[6px] right-3 text-red-600 text-lg' />
                                    </div> :
                                    <div className="taskTitle_div relative !flex gap-3 items-center group">
                                        <p className="titleHeading">{selectedTask?.task_title}</p>
                                        {selectedTask?.created_by === user.id && <div id="updateTitle" className="hidden group-hover:block editFile !relative !top-[0px] !right-[0px]" onClick={() => { setTempTitle(selectedTask?.task_title); setIsEditTitle(true) }}></div>}
                                    </div>
                                }

                                <div className="creator_area leftArea" style={{ height: '34.5px', borderBottom: 'unset', paddingLeft: '0px', marginTop: '0px' }}>
                                    <div className="creator_label inlineText" style={{ width: 'auto' }}>
                                        Created by <p>{getONLYUSERSDATA(cacheAllUsers.users, selectedTask?.created_by, 'name')},</p><span>dated on <Moment format="MMM DD, YYYY, hh:mm A">{selectedTask?.created_at}</Moment></span>
                                    </div>
                                </div>
                                <div className='text-sm pb-2 cursor-pointer taskTop'>
                                    Project: {selectedTask?.project_title || 'Not Defined'}
                                    {selectedTask?.project_title === 'Not Defined' || selectedTask?.project_title === '' ?
                                        selectedTask?.created_by === user.id &&
                                        <>[<span className='text-[#0079d1]' onClick={() => setProjectPopup(true)} id="notDefined" >add this task to a project</span>]</>
                                        : !isObserver() && <>
                                            &nbsp; [<span id="updateTitle" onClick={() => setProjectPopup(true)} className='text-[#0079d1]'>Change</span>]
                                            [<span id="editTitle" onClick={handleProjectEdit} className='text-[#0079d1]'>Edit</span>]
                                        </>
                                    }
                                </div>
                                <div className="new_area">
                                    <div className="flagIcom_area">
                                        <div id="flagTip" onClick={() => OnUpdateTask({ flag: selectedTask?.flag?.indexOf(user.id) > -1 ? [...selectedTask?.flag?.filter(f => f !== user.id)] : [...selectedTask?.flag, user.id] })} className={`${selectedTask?.flag?.indexOf(user.id) > -1 ? 'fill_flagIcom_Active_Task' : 'fill_flagIcom'}`}></div>
                                        <div className="keywords_area">
                                            {selectedTask?.key_words?.map((keyword, index) => (
                                                <div key={index} className="singleKeywordPro">
                                                    {keyword}
                                                    {!isObserver() && <span className="removeFile" style={{ right: '-2px', top: '0px', display: 'block', position: 'relative', width: '12px', height: '12px', backgroundSize: '8px', border: '1px solid red' }}
                                                        onClick={() => OnUpdateTask({ key_words: [...selectedTask?.key_words?.filter(f => f !== keyword)], save_type: 'keyword' })}
                                                    ></span>}
                                                </div>
                                            ))}
                                        </div>

                                        {!isObserver() && <div ref={ref2}>
                                            {
                                                addKeyWordPopup === true ?
                                                    <div className="addKeyword_section myTask">
                                                        <span className="priority_icon">
                                                            <span className="plusIcon">
                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="#0079d1" height="16" width="16" xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(0, 121, 209)' }}>
                                                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                                                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <div>
                                                            <div className="keyword_div">
                                                                <input type="text" className="markPriority !h-[26px] task" maxLength="32" value={newKeyword} onChange={handleInputChange} name="addkeyword" id="addkeyword" placeholder="Add a keyword" autoFocus />
                                                            </div>

                                                            <div className="AddKeywordsPopup_task_settings insightTaskBoard keywordsLists" style={{ width: '100%', maxHeight: '380px', overflowY: 'auto', marginTop: '1px', marginLeft: '0px' }}>
                                                                <div className="searchareaDiv"></div>
                                                                {sortArrayByKey(filter_keywords, 'keywords_title', 'asc').map((keyword, index) => (
                                                                    <p key={index} className="_tag_rooms hover:!text-white" onClick={() => selectKeyword(keyword)}>
                                                                        {keyword.keywords_title}
                                                                    </p>
                                                                ))}
                                                                {filter_keywords.length === 0 &&
                                                                    <div className="saveArea" onClick={addKeyword}>Create keyword</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="addKeyword_section myTask hover:!text-white" onClick={() => setAddKeyWordPopup(true)}>
                                                        <div id='addKeywordTooltip' className="addselectKeywordsdiv hover:bg-[#0B1F47] hover:!text-white !text-[#5b6477] pl-2 pr-2">
                                                            <MdAdd className='h-4 w-4' />
                                                            <span className="newAddText">Add a keyword</span>
                                                        </div>
                                                        <Tooltip anchorSelect="#addKeywordTooltip" place='top' content="Add a keyword"></Tooltip>
                                                    </div>
                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className='first_section'> */}
                            <div className='statuAndProgress_area'>
                                <TaskStatus />
                                <TaskProgress />
                                <div className='date_area' style={{ width: '14%' }}>
                                    <p className='date_label'>Review</p>
                                    <div id="reviewText" className={classNames('toggle-button-cover', isObserver() && 'pointer-events-none')} style={{ left: '0px' }}>
                                        <input className='cm-toggle' defaultChecked={selectedTask?.review} onChange={() => OnUpdateTask({ review: !selectedTask?.review })} value={selectedTask?.review} type='checkbox' />
                                    </div>
                                </div>
                            </div>
                            <div className='first_task_area pb-4 pt-4'>
                                <TaskStartEndDate />
                                <TaskDueTime />
                            </div>
                            <TaskAssignee />
                            <div className='yourNotes'>
                                <div className="notes_label_area" style={{ justifyContent: 'flex-start', gap: '10px' }}>
                                    <div onClick={() => setTaskDescriptionsPopup(true)} className="noteFirstColumn hoverProColor" id="expandDescription" >
                                        <p className="notes_label">Description</p>
                                        {selectedTask?.view_description?.indexOf(user?.id) > -1 && <span className="task_read"></span>}
                                    </div>
                                    <span className="verTicleborderLine">|</span>
                                    <div onClick={() => setTaskNotesPopup(true)} className="noteFirstColumn hoverProColor" id="expandNotes" >
                                        <p className="notes_label">Your notes</p>
                                        {selectedTask?.view_note?.indexOf(user?.id) > -1 && <span className="task_read"></span>}
                                    </div>
                                    <span className="verTicleborderLine">|</span>
                                    <div onClick={() => setTaskFilesPopup(true)} className="files_area hoverProColor" id="expandFiles" >
                                        <p className="notes_label">Files <span style={{ fontSize: '12px' }}>({selectedTask?.files?.filter(f => !f.has_delete?.includes(user.id))?.length || 0})</span></p>
                                    </div>
                                    <span className="verTicleborderLine">|</span>
                                    <div onClick={() => setTrackCheckListPopup(true)} className="checkLists_label hoverProColor flex gap-1" id="expandCheckLists" >
                                        <p className="notes_label">Checklists</p>
                                        <p style={{ fontSize: '12px' }}>({selectedTask?.checklists?.filter(f => f.status === "complete").length}/{selectedTask?.checklists?.length})</p>
                                        {selectedTask?.view_checklist?.indexOf(user?.id) > -1 && <span className="task_read"></span>}
                                    </div>
                                </div>

                            </div>
                            <div className="secound_section quickTask">
                                <div className="left_part">
                                    <div className="track_form_div">
                                        <div className="track_hoursArea" onClick={handleTrackCostPopupOpen}>
                                            <div className="notes_label_area">
                                                <p className="notes_label">Track costs</p>
                                                {selectedTask?.view_cost?.indexOf(user?.id) > -1 && <span className="task_read absolute left-[98px]"></span>}
                                                <div className="Track_body_headding foot">
                                                    <div className="Track_col">Forcasted: ${selectedTask?.forecasted_cost}</div>
                                                    <span className="verTicleborderLine">|</span>
                                                    <div className="Track_col">Actual: ${selectedTask?.actual_cost}</div>
                                                    <span className="verTicleborderLine">|</span>
                                                    <div className="Track_col">Variance: ${selectedTask?.cost_variance}</div>
                                                </div>
                                                <span className="downArrowSpan !absolute !right-2">
                                                    <MdOutlineKeyboardArrowDown className="text-[#318fff] -rotate-90 text-2xl" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="track_hoursArea" onClick={() => { setTrackHourPopup(selectedTask?.end_date ? true : false); !selectedTask.end_date && toast.error('Please select due date before adding track hour.') }}>
                                            <div className="notes_label_area">
                                                <p className="notes_label">Track hours</p>
                                                {selectedTask?.view_hour?.indexOf(user?.id) > -1 && <span className="task_read absolute left-[98px]"></span>}
                                                <div className="Track_body_headding foot">
                                                    <div className="Track_col">Forcasted: {selectedTask?.forecasted_hours}hrs</div>
                                                    <span className="verTicleborderLine">|</span>
                                                    <div className="Track_col">Actual: {selectedTask?.actual_hours}hrs</div>
                                                    <span className="verTicleborderLine">|</span>
                                                    <div className="Track_col">Variance: {selectedTask?.hours_variance}hrs</div>
                                                </div>
                                                <span className="downArrowSpan !absolute !right-2">
                                                    <MdOutlineKeyboardArrowDown className="text-[#318fff] -rotate-90 text-2xl" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TaskChatSections sectionRef={sectionRef} task={selectedTask} me={user} />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <Tooltip anchorSelect="#notDefined" place="top" content="Not defined"></Tooltip>
                <Tooltip anchorSelect="#editTitle" place="top" content="Edit project title"></Tooltip>
                <Tooltip anchorSelect="#flagTip" place="top" content="Click to flag"></Tooltip>
                <Tooltip anchorSelect="#reviewText" place="top" content="This field is not accessible to your user role "></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#chatHere" place="top" content="Chat here"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#close" place="top" content="Close"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#delete" place="top" content="Delete"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#expandDescription" place="top" content="Click to expand description"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#expandNotes" place="top" content="Click to expand Your notes"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#expandFiles" place="top" content="Click to expand files"></Tooltip>
                <Tooltip delayShow={1000} anchorSelect="#expandCheckLists" place="top" content="Click to expand CheckLists"></Tooltip>
                <Tooltip anchorSelect="#updateTitle" place="top" content="Click to update title"></Tooltip>
            </div>

            {projectPopup && <TaskProject setProjectPopup={setProjectPopup} projectEdit={projectEdit} set_projectEdit={set_projectEdit} />}
            {trackCheckListPopup && <TaskCheckListPopup setTrackCheckListPopup={setTrackCheckListPopup} />}
            {trackCostPopup && <TrackCostPopup handleTrackCostPopupClose={handleTrackCostPopupClose} set_cost_id={set_cost_id} />}
            {trackHourPopup && <TrackHourPopup setTrackHourPopup={setTrackHourPopup} />}
            {taskFilesPopup && <TaskFilePopup setTaskFilesPopup={setTaskFilesPopup} />}
            {taskDescriptionsPopup && <TaskDescriptionsPopup setTaskDescriptionsPopup={setTaskDescriptionsPopup} me={user} />}
            {taskNotesPopup && <TaskNotesPopup setTaskNotesPopup={setTaskNotesPopup} />}
            {
                taskFileUpload &&
                <UploadProvider>
                    <FileUploadNew
                        type="file_upload"
                        task={selectedTask}
                        upload_type={'task'}
                        from={trackCostPopup ? '#TASK-COST' : '#TASK'}
                        cost_id={cost_id}
                        is_reply_msg={'no'}
                        taskFileUpload={taskFileUpload}
                        setTaskFileUpload={setTaskFileUpload}
                    />
                </UploadProvider>
            }

        </>
    );
};

export default TaskPropertiesCon;