import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { CREATE_VENUE } from '../../../Utils/GraphqlMutaion';
import { set_venue_particeprnt_popup, set_venue_contact_popup } from '../../../redux/message';
import { toast } from 'react-hot-toast';
import { venue_create_update_api } from "./Venue_Create_update_Api";

import {
    add_eod_venu_member,
    set_eod_Venue_check_Submitter,
    set_eod_Venue_check_Re_Open,
    set_eod_Venue_check_eod_email,

    add_Maintanence_venu_member,
    set_maintanence_Venue_update_check_Submitter,
    set_maintanence_Venue_update_check_Approve,
    set_maintanence_Venue_update_check_Observer,
    set_maintanence_Venue_update_check_Email,

    set_EOD_Contact_Email,
    set_contact_EOD_Contact_user,

    set_maintanence_Contact_Email,
} from '../../../redux/adminSlice';


import VenueUserParticipents from './VenueUserParticipents';
import AddNewContactPopup from './AddNewContactPopup';


function VenueUserSettting({ setVenuesList, venueSingle_Old, setVenueDetails, activeTabMenu, setActiveTabMenu }) {

    const _redux = useSelector(state => state.message);
    const dispatch = useDispatch()
    const selectTab = async (e) => {
        let active = e.target.id;
        setActiveTabMenu(active);
        // console.log(activeTabMenu + "==" + active);

    }
    const open_particeprnt_popup = (type, action) => {
        setEod_maintanence_PopUp_Type(type)
        dispatch(set_venue_particeprnt_popup(true))
    };
    // const close_AddNewContactPopup = (type) => {
    //     dispatch(set_venue_contact_popup(false))
    // };


    const [eod_maintanence_Contact_PopUp_Type, setEod_maintanence_Contact_PopUp_Type] = useState('');

    const open_AddNewContactPopup = (type) => {
        setEod_maintanence_Contact_PopUp_Type(type);
        dispatch(set_venue_contact_popup(true))
    };



    const [allparticipants, setAllparticipants] = useState([]);
    const all_venue = useSelector(state => state.admin.all_venue);
    const allUser = useSelector(state => state.admin.allUser);


    const [all_Check_submitter, setAll_Check_submitter] = useState(false);
    const [all_Check_ReOpen, setAll_Check_ReOpen] = useState(false);
    const [all_Check_Email, setAll_Check_Email] = useState(false);

    const [eod_maintanence_PopUp_Type, setEod_maintanence_PopUp_Type] = useState('');





    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // const compareArrays = (oldArray, newArray) => {
    //     if (oldArray.length !== newArray.length) return false;
    //     return oldArray.every((item, index) => item === newArray[index]);
    // };


    const compareArrays = (oldArray, newArray) => {
        try {
            if (!Array.isArray(oldArray) || !Array.isArray(newArray)) {
                // console.log("erro");
                return false;
            }    
            if (oldArray.length !== newArray.length) return false;
    
            return oldArray.every((item, index) => item === newArray[index]);
        } catch (error) {
            console.log("erro");
            return false;
        }
    };


  

    useEffect(() => {
        const allMatch = compareArrays(venueSingle_Old?.eod_participants, all_venue?.eod_participants) &&
            compareArrays(venueSingle_Old?.eod_submitters, all_venue?.eod_submitters) &&
            compareArrays(venueSingle_Old?.eod_reopen, all_venue?.eod_reopen) &&
            compareArrays(venueSingle_Old?.eod_email, all_venue?.eod_email) &&
            compareArrays(venueSingle_Old?.maintenance_participants, all_venue?.maintenance_participants) &&
            compareArrays(venueSingle_Old?.maintenance_submitter, all_venue?.maintenance_submitter) &&
            compareArrays(venueSingle_Old?.maintenance_approver, all_venue?.maintenance_approver) &&
            compareArrays(venueSingle_Old?.maintenance_memail, all_venue?.maintenance_memail) &&
            compareArrays(venueSingle_Old?.eod_contact_participants, all_venue?.eod_contact_participants) &&
            compareArrays(venueSingle_Old?.maintenance_contact_participants, all_venue?.maintenance_contact_participants) &&
            compareArrays(venueSingle_Old?.recipients, all_venue?.recipients) &&
            compareArrays(venueSingle_Old?.maintenance_recipients, all_venue?.maintenance_recipients);

        setIsButtonDisabled(allMatch);
    }, [venueSingle_Old, all_venue]);



    useEffect(() => {
        const filteredUsers = allUser?.filter(user => all_venue?.eod_participants?.includes(user.id));
        setAllparticipants(filteredUsers);

        if (all_venue?.eod_participants.length !== all_venue?.eod_submitters.length) {
            setAll_Check_submitter(false);
        } else {
            setAll_Check_submitter(true);
        }

        if (all_venue?.eod_participants.length !== all_venue?.eod_reopen.length) {
            setAll_Check_ReOpen(false);
        } else {
            setAll_Check_ReOpen(true);
        }

        if (all_venue?.eod_participants.length !== all_venue?.eod_email.length) {
            setAll_Check_Email(false);
        } else {
            setAll_Check_Email(true);
        }
    }, [all_venue, allUser]);



    const [maintenance_all_Check_submitter, setMaintenance_all_Check_submitter] = useState(false);
    const [maintenance_all_Check_approve, setMaintenance_all_Check_approve] = useState(false);
    const [maintenance_all_Check_email, setMaintenance_all_Check_email] = useState(false);

    // maintenance Part use Effect start
    const [maintanenceUser, setMaintanenceUser] = useState([]);

    useEffect(() => {
        const maintenance_User = allUser?.filter(user => all_venue?.maintenance_participants?.includes(user.email));
        setMaintanenceUser(maintenance_User);

        // maintenance_submitter main top main checkbox = maintenance_submitter
        if (all_venue?.maintenance_participants.length !== all_venue?.maintenance_submitter.length) {
            setMaintenance_all_Check_submitter(false);
        } else {
            setMaintenance_all_Check_submitter(true);
        }

        // maintenance_approver main top main checkbox  = Approve
        if (all_venue?.maintenance_participants.length !== all_venue?.maintenance_approver.length) {
            setMaintenance_all_Check_approve(false);
        } else {
            setMaintenance_all_Check_approve(true);
        }


        // maintenance_memail main top main checkbox = Email
        if (all_venue?.maintenance_participants.length !== all_venue?.maintenance_memail.length) {
            setMaintenance_all_Check_email(false);
        } else {
            setMaintenance_all_Check_email(true);
        }


    }, [all_venue, allUser]);
    // maintenance Part use Effect End




    // EOD participant/user Remove onClick funciton btn
    const removeMember = (type, member) => {
        if (type === "venue_EOD") {

            const eod_participants_Delete = all_venue?.eod_participants?.filter((data) => data !== member.id);
            dispatch(add_eod_venu_member(eod_participants_Delete));

            const eodSubmitters = all_venue?.eod_submitters?.filter((data) => data !== member.id);
            dispatch(set_eod_Venue_check_Submitter(eodSubmitters));

            const eodReOpen = all_venue?.eod_reopen?.filter((data) => data !== member.id);
            dispatch(set_eod_Venue_check_Re_Open(eodReOpen));

            const eodEmail = all_venue?.eod_email?.filter((data) => data !== member.id);
            dispatch(set_eod_Venue_check_eod_email(eodEmail));
        }
        if (type === "venue_Maintanence") {
            const Delete_participants = all_venue?.maintenance_participants?.filter((data) => data !== member.email);
            dispatch(add_Maintanence_venu_member(Delete_participants));

            const Delete_maintenance_submitter = all_venue?.maintenance_submitter.filter((data) => data !== member.email);
            dispatch(set_maintanence_Venue_update_check_Submitter(Delete_maintenance_submitter));

            const Delete_maintenance_approver = all_venue?.maintenance_approver.filter((data) => data !== member.email);
            dispatch(set_maintanence_Venue_update_check_Approve(Delete_maintenance_approver));

            const Delete_maintenance_observer = all_venue?.maintenance_observer.filter((data) => data !== member.email);
            dispatch(set_maintanence_Venue_update_check_Observer(Delete_maintenance_observer));

            const Delete_maintenance_memail = all_venue?.maintenance_memail.filter((data) => data !== member.email);
            dispatch(set_maintanence_Venue_update_check_Email(Delete_maintenance_memail));

        }

    };


    // EOD = checked Save/value data show checkBox = EOD
    const checkBox_method = (id, name) => {
        if (name === "submitter") {
            const isChecked = all_venue?.eod_submitters.includes(id);
            return isChecked;
        }
        if (name === "Reopen") {
            const isChecked = all_venue?.eod_reopen.includes(id);
            return isChecked;
        }
        if (name === "Email_EOD") {
            const isChecked = all_venue?.eod_email.includes(id);
            return isChecked;
        }

    };


    // EOD_Contact_email
    const [all_EOD_Contact_Check_email, setAll_EOD_Contact_Check_email] = useState(false);
    const [all_maintance_Contact_Check_email, setAll_maintance_Contact_Check_email] = useState(false);
    useEffect(() => {

        if (all_venue?.eod_contact_participants.length !== all_venue?.recipients.length) {
            setAll_EOD_Contact_Check_email(false);
        } else {
            setAll_EOD_Contact_Check_email(true);
        }

        if (all_venue?.maintenance_contact_participants.length !== all_venue?.maintenance_recipients.length) {
            setAll_maintance_Contact_Check_email(false);
        } else {
            setAll_maintance_Contact_Check_email(true);
        }

    }, [all_venue]);




    const checkBox_All_EOD_maintance_Contact_Email = (check, option) => {

        if (check === "EOD_Contact_all_mail_checkBox") {
            if (option) {
                dispatch(set_EOD_Contact_Email(all_venue?.eod_contact_participants));
            } else {
                dispatch(set_EOD_Contact_Email([]));
            }
        }

        if (check === "Mataintanence_Contact_all_mail_checkBox") {
            if (option) {
                dispatch(set_maintanence_Contact_Email(all_venue?.maintenance_contact_participants));
            } else {
                dispatch(set_maintanence_Contact_Email([]));
            }
        }

    }





    // EOD Contact = checked Save/value data show checkBox = EOD contact
    const eod_maintanence_contact_checkBox = (email, name) => {
        if (name === "eod_contact_Email") {
            const isChecked = all_venue?.recipients.includes(email);
            return isChecked;
        }

        if (name === "maintanence_contact_Email") {
            const isChecked = all_venue?.maintenance_recipients.includes(email);
            return isChecked;
        }
    };


    // Vanue Contact eod_maintanence__Email check box update EOD with maintanence
    const Contact_eod_maintanence_Email_onClick = (check, email, option) => {

        if (check === "eod_contactEmail") {
            let recipientsList = [...all_venue?.recipients];

            if (option) {
                if (!recipientsList.includes(email)) {
                    recipientsList.push(email);
                }
            } else {
                recipientsList = recipientsList.filter(data => data !== email);
            }
            dispatch(set_EOD_Contact_Email(recipientsList));
        }

        if (check === "maintanence_contactEmail") {
            let maintenance_recipientsList = [...all_venue?.maintenance_recipients];

            if (option) {
                if (!maintenance_recipientsList.includes(email)) {
                    maintenance_recipientsList.push(email);
                }
            } else {
                maintenance_recipientsList = maintenance_recipientsList.filter(data => data !== email);
            }
            dispatch(set_maintanence_Contact_Email(maintenance_recipientsList));
        }

    }



    // Vanue maintanence_Submitter_Approve_Email_ All check box add/delete = maintanence
    const maintanence_Submitter_Approve_Email = (check, option) => {
        if (check === "submitter") {
            if (option) {
                dispatch(set_maintanence_Venue_update_check_Submitter(all_venue?.maintenance_participants));
                dispatch(set_maintanence_Venue_update_check_Observer([]));
            } else {
                dispatch(set_maintanence_Venue_update_check_Submitter([]));
            }
        }

        if (check === "approve") {
            if (option) {
                dispatch(set_maintanence_Venue_update_check_Approve(all_venue?.maintenance_participants));
                dispatch(set_maintanence_Venue_update_check_Observer([]));
            } else {
                dispatch(set_maintanence_Venue_update_check_Approve([]));
            }
        }

        if (check === "email") {
            if (option) {
                dispatch(set_maintanence_Venue_update_check_Email(all_venue?.maintenance_participants));
                dispatch(set_maintanence_Venue_update_check_Observer([]));
            } else {
                dispatch(set_maintanence_Venue_update_check_Email([]));
            }
        }
    }



    // maintanence only check true/false, submitter, approve, email = maintanence
    const maintanence_checkBox_method = (email, name) => {
        if (name === "submitter") {
            // console.log("147", all_venue);
            const isChecked = all_venue?.maintenance_submitter.includes(email);
            return isChecked;
        }
        if (name === "Approve") {
            const isChecked = all_venue?.maintenance_approver.includes(email);
            return isChecked;
        }

        if (name === "Observer") {
            const isChecked = all_venue?.maintenance_observer.includes(email);
            return isChecked;
        }

        if (name === "Email_mantanence") {
            const isChecked = all_venue?.maintenance_memail.includes(email);
            return isChecked;
        }

    };


    // maintanence onchange function true/false click update submitter, approve, email = maintanence
    const maintanence_venue_update_checkBox = (check, email, option) => {

        // maintenance submitter update check
        if (check === "submitter") {
            let maintenance_submitterList = [...all_venue?.maintenance_submitter];
            // console.log("Initial List:", maintenance_submitterList);
            if (option) {
                if (!maintenance_submitterList.includes(email)) {
                    maintenance_submitterList.push(email);
                }
                const maint_observer = all_venue?.maintenance_observer.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Observer(maint_observer));
            } else {
                maintenance_submitterList = maintenance_submitterList.filter(data => data !== email);
            }
            dispatch(set_maintanence_Venue_update_check_Submitter(maintenance_submitterList));
        }


        // maintenance Approve update check
        if (check === "Approve") {
            let maintenance_approverList = [...all_venue?.maintenance_approver];
            // console.log("Initial List:", maintenance_approverList);
            if (option) {
                if (!maintenance_approverList.includes(email)) {
                    maintenance_approverList.push(email);
                }
                const maint_observer = all_venue?.maintenance_observer.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Observer(maint_observer));
            } else {
                maintenance_approverList = maintenance_approverList.filter(data => data !== email);
            }
            dispatch(set_maintanence_Venue_update_check_Approve(maintenance_approverList));
        }


        // maintenance Approve update check
        if (check === "Observer") {
            let maintenance_observerList = [...all_venue?.maintenance_observer];

            if (option) {
                if (!maintenance_observerList.includes(email)) {
                    maintenance_observerList.push(email);
                }
                const maint_submitter = all_venue?.maintenance_submitter.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Submitter(maint_submitter));

                const maint_eApprover = all_venue?.maintenance_approver.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Approve(maint_eApprover));

                const maint_email = all_venue?.maintenance_memail.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Email(maint_email));
            } else {
                maintenance_observerList = maintenance_observerList.filter(data => data !== email);
            }
            dispatch(set_maintanence_Venue_update_check_Observer(maintenance_observerList));
        }


        // maintenance Email update check
        if (check === "Email") {
            let maintenance_memailList = [...all_venue?.maintenance_memail];
            console.log("Initial List:", maintenance_memailList);
            if (option) {
                if (!maintenance_memailList.includes(email)) {
                    maintenance_memailList.push(email);
                }
                const maint_observer = all_venue?.maintenance_observer.filter(data => data !== email);
                dispatch(set_maintanence_Venue_update_check_Observer(maint_observer));
            } else {
                maintenance_memailList = maintenance_memailList.filter(data => data !== email);
            }
            dispatch(set_maintanence_Venue_update_check_Email(maintenance_memailList));
        }

    }




    // EOD onChange function true/false click update submitter, re_Open, email = EOD
    const eod_venue_checkBox = (check, id, option) => {
        let submitterList = [...all_venue?.eod_submitters];
        if (check === "submitter") {
            if (option) {
                if (!submitterList.includes(id)) {
                    submitterList.push(id);
                }
            } else {
                submitterList = submitterList.filter(data => data !== id);
            }
            dispatch(set_eod_Venue_check_Submitter(submitterList));
        }


        // Re-Open check
        let reOpenList = [...all_venue?.eod_reopen];
        if (check === "reOpen") {
            if (option) {
                if (!reOpenList.includes(id)) {
                    reOpenList.push(id);
                }
            } else {
                reOpenList = reOpenList.filter(data => data !== id);
            }
            dispatch(set_eod_Venue_check_Re_Open(reOpenList));
        }

        // E-mail check
        let eod_emailList = [...all_venue?.eod_email];
        if (check === "email") {
            if (option) {
                if (!eod_emailList.includes(id)) {
                    eod_emailList.push(id);
                }
            } else {
                eod_emailList = eod_emailList.filter(data => data !== id);
            }
            dispatch(set_eod_Venue_check_eod_email(eod_emailList));
        }
    }



    // Vanue OnChange functiion (all = true/false) EOD_Submitter_Reopen_Email_ All check box add/delete = EOD
    const Submitter_Reopen_Email = (check, option) => {
        if (check === "submitter") {
            if (option) {
                dispatch(set_eod_Venue_check_Submitter(all_venue?.eod_participants));
            } else {
                dispatch(set_eod_Venue_check_Submitter([]));
            }
        }

        if (check === "reOpen") {
            if (option) {
                dispatch(set_eod_Venue_check_Re_Open(all_venue?.eod_participants));
            } else {
                dispatch(set_eod_Venue_check_Re_Open([]));
            }
        }

        if (check === "email") {
            if (option) {
                dispatch(set_eod_Venue_check_eod_email(all_venue?.eod_participants));
            } else {
                dispatch(set_eod_Venue_check_eod_email([]));
            }
        }
    }



    const [create_venue] = useMutation(CREATE_VENUE)


    // all update function onClick Button = Update Vanue
    const venue_Update = async (types) => {
        try {
            console.log("184", types);
            const response = await venue_create_update_api(types, create_venue, all_venue, "", 0);

            console.log("187 rajon", response.response.data.create_update_venue.result);

            if (response?.actionType === "update" && response?.response.data.create_update_venue.status) {
                toast.success(response.response.data.create_update_venue.message, { duration: 4000 });
                setVenueDetails(false);
                setVenuesList((prev) => {
                    const newVenue = {
                        ...response.response.data.create_update_venue.result,
                        _id: all_venue._id,
                    };
                    return prev.map((venue) =>
                        venue._id === all_venue._id ? newVenue : venue
                    );
                });
            } else {
                toast.error("update not update", { duration: 4000 });
                setVenueDetails((pre) => pre === true ? false : true);
            }
        } catch (error) {
            console.error("Error in venueUpdate:", error);
            toast.error("An unexpected error occurred. Please try again later.", { duration: 4000 });
        }
    };



    // EOD contact user list set
    const [eod_Contact_user, setEod_Contact_user] = useState([]);

    useEffect(() => {
        const uniqueUsers = [
            ...new Map(allUser.map(user => [user.email, user])).values()
        ];

        const eod_contact_User = uniqueUsers.filter(user => all_venue?.eod_contact_participants.includes(user.email));
        setEod_Contact_user(eod_contact_User);
    }, [all_venue, allUser]);



    // Maintanence contact user list set
    const [maintanence_Contact_user, setMaintanence_Contact_user] = useState([]);

    useEffect(() => {
        const uniqueUsers = [
            ...new Map(allUser.map(user => [user.email, user])).values()
        ];

        const eod_contact_User = uniqueUsers.filter(user => all_venue?.maintenance_contact_participants.includes(user.email));
        setMaintanence_Contact_user(eod_contact_User);
    }, [all_venue, allUser]);



    const removeEOD_contact_Member = (member) => {
        const eod_conactMember = all_venue?.eod_contact_participants.filter((data) => data !== member.email);
        const recipients = all_venue?.recipients.filter((data) => data !== member.email);
        dispatch(set_EOD_Contact_Email(recipients));
        dispatch(set_contact_EOD_Contact_user(eod_conactMember));
    }



    return (
        <div className='venueSettingArea'>
            {_redux.venue_particeprnt_popup && <VenueUserParticipents eod_maintanence_PopUp_Type={eod_maintanence_PopUp_Type} />}
            {_redux.venue_contact_popup && <AddNewContactPopup eod_maintanence_Contact_PopUp_Type={eod_maintanence_Contact_PopUp_Type} />}

            <div className="usersTabSecDevide !mb-[30px] !w-full" >
                <div id="eodTab" onClick={selectTab} className={`usersTabList teamManagementTitle ${activeTabMenu === 'eodTab' ? 'active' : ''}`}>EOD user settings  </div>
                <div id="maintenanceTab" onClick={selectTab} className={`usersTabList teamManagementTitle ${activeTabMenu === 'maintenanceTab' ? 'active' : ''}`}>Maintenance user settings </div>

            </div>
            {activeTabMenu === 'eodTab' &&
                <div className="forEodTap">
                    <div className="name_and_profile !w-full">
                        <div className="addMember !pr-0" >
                            <div className="inputGroup !mb-[5px]">
                                <button className="addMemberBtn" onClick={() => open_particeprnt_popup("EOD", true)}>
                                    Add workfreeli user(s) to this venue EOD
                                </button>
                            </div>

                            {all_venue?.eod_participants?.length === 0 && <div className="noGust">No user(s) has been added yet!</div>}


                            <div className="addedMemberList" style={{ display: all_venue?.eod_participants.length === 0 ? 'none' : 'block' }}>
                                <div className="headerMark !mb-[5px]">
                                    <label className="inputLabel !w-[55%]" style={{ fontSize: '16px' }}>
                                        User(s)
                                    </label>
                                    <div className="markAllChecked recip !flex !w-[45%]">

                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" id="custom-checkbox"
                                                checked={all_Check_submitter ? true : false}
                                                onChange={(e) => Submitter_Reopen_Email("submitter", e.target.checked)}
                                                name="check_submitter" />
                                            <label htmlFor="custom-checkbox">
                                                <span></span>Submit
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" checked={all_Check_ReOpen ? true : false}
                                                onChange={(e) => Submitter_Reopen_Email("reOpen", e.target.checked)}
                                                id="Reopen" name="check_reopen" />
                                            <label htmlFor="Reopen">
                                                <span></span>Reopen
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" checked={all_Check_Email ? true : false}
                                                onChange={(e) => Submitter_Reopen_Email("email", e.target.checked)}
                                                id="EmailEOD" name="check_Email" />

                                            <label htmlFor="EmailEOD">
                                                <span></span>Email (EOD)
                                            </label>
                                        </div>

                                    </div>
                                </div>


                                {allparticipants?.map((participant, index) => (
                                    <div className="maintainterUserArea flex" key={participant.id}>
                                        <div className="memberItem !w-[55%]">
                                            <div className="memberItemImage">
                                                <img src={participant.img} alt={`Profile of ${participant.firstname}`} />
                                            </div>
                                            <div className="memberItemData d-block">
                                                <p className="memberItemTitle">
                                                    {participant.firstname} {participant.lastname}
                                                </p>
                                                <p className="memberItemEmail" style={{ fontSize: 12 }}>
                                                    [{participant.role}]
                                                </p>
                                            </div>
                                            <div className="memberItemAction" onClick={() => removeMember("venue_EOD", participant)}>
                                                <span className="removeAdmin">Remove</span>
                                            </div>
                                        </div>
                                        <div className="markCheckedFields flex !w-[45%]">

                                            {/* submitter */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`submit1-${participant.id}`}
                                                    name={`check-submit1-${participant.id}`}
                                                    checked={checkBox_method(participant.id, "submitter")}
                                                    onChange={(e) => eod_venue_checkBox("submitter", participant.id, e.target.checked)}
                                                />
                                                <label htmlFor={`submit1-${participant.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>


                                            {/* Reopen */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`reopen1-${participant.id}`}
                                                    name={`check-reopen1-${participant.id}`}
                                                    checked={checkBox_method(participant.id, "Reopen")}
                                                    onChange={(e) => eod_venue_checkBox("reOpen", participant.id, e.target.checked)}
                                                />
                                                <label htmlFor={`reopen1-${participant.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>


                                            {/* Email EOD */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`mail-${participant.id}`}
                                                    name={`check-mail-${participant.id}`}
                                                    checked={checkBox_method(participant.id, "Email_EOD")}
                                                    onChange={(e) => eod_venue_checkBox("email", participant.id, e.target.checked)}
                                                />
                                                <label htmlFor={`mail-${participant.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    <div className="name_and_profile teamProfile !w-full">
                        <div className="addGuest pl-0" >
                            <div className="inputGroup guestButton pl-0 !pr-[0px] mb-[12px]">
                                <button className="addMemberBtn" onClick={(e) => open_AddNewContactPopup("eod_conact")}>Add contact(s) to this venue</button>
                            </div>
                            <div className="addedGuestList teamGuest eodContact">

                                {eod_Contact_user?.length === 0 && <div className="noGust">No contact(s) has been invited yet!</div>}

                                <div className="addedMemberList" style={{ display: eod_Contact_user.length === 0 ? 'none' : 'block' }}>
                                    <div className="headerMark">
                                        <label className="inputLabel !w-[55%]" style={{ fontSize: '16px' }}>
                                            User(s)
                                        </label>
                                        <div className="markAllChecked recip !flex !w-[45%]">
                                            <div className="checkbox-wrapper">
                                                <input type="checkbox"
                                                    checked={all_EOD_Contact_Check_email ? true : false}
                                                    onChange={(e) => checkBox_All_EOD_maintance_Contact_Email("EOD_Contact_all_mail_checkBox", e.target.checked)}
                                                    id="eod_contact-checkbox"
                                                    name="check_reopen" />
                                                <label htmlFor="eod_contact-checkbox">
                                                    <span></span>Email(EOD)
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    {/* EOD contact User List */}
                                    {eod_Contact_user?.map((eodContactuser, index) =>
                                        <div className="maintainterUserArea flex" key={eodContactuser.id}>
                                            <div className="memberItem !w-[55%]">
                                                <div className="memberItemImage">
                                                    <img src={eodContactuser.img} alt={`Profile of ${eodContactuser.firstname}`} />
                                                </div>
                                                <div className="memberItemData d-block">
                                                    <p className="memberItemTitle">{eodContactuser.firstname} {eodContactuser.lastname}</p>
                                                    <p className="memberItemEmail" style={{ fontSize: 12 }}>
                                                        [{eodContactuser.email}]
                                                    </p>
                                                </div>
                                                <div className="memberItemAction" onClick={() => removeEOD_contact_Member(eodContactuser)}>
                                                    <span className="removeAdmin">Remove</span>
                                                </div>
                                            </div>
                                            <div className="markCheckedFields flex !w-[45%]">

                                                <div className="checkbox-wrapper">
                                                    <input type="checkbox"
                                                        id={`eod_contact-${eodContactuser.id}`}
                                                        checked={eod_maintanence_contact_checkBox(eodContactuser.email, "eod_contact_Email")}
                                                        onChange={(e) => Contact_eod_maintanence_Email_onClick("eod_contactEmail", eodContactuser.email, e.target.checked)}
                                                        name={`eod_contact_email-${eodContactuser.id}`}
                                                    />
                                                    <label htmlFor={`eod_contact-${eodContactuser.id}`}>
                                                        <span></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }


            {activeTabMenu === 'maintenanceTab' &&
                <div className="forMaintanenceTap">
                    <div className="name_and_profile !w-full">
                        <div className="addMember !pr-0" >
                            <div className="inputGroup !mb-[5px]">
                                <button className="addMemberBtn" onClick={() => open_particeprnt_popup("maintanence", true)}>
                                    Add workfreeli user(s) to this venue
                                </button>
                            </div>

                            {all_venue?.maintenance_participants?.length === 0 && <div className="noGust">No user(s) has been added yet!</div>}

                            <div className="addedMemberList" style={{ display: all_venue.maintenance_participants.length === 0 ? 'none' : 'block' }}>
                                <div className="headerMark !mb-[5px]">
                                    <label className="inputLabel !w-[45%]" style={{ fontSize: '16px' }}>
                                        User(s)
                                    </label>
                                    <div className="markAllChecked recip !flex !w-[55%]">

                                        <div className="checkbox-wrapper">
                                            <input type="checkbox"
                                                id="Submit" name="check"
                                                checked={maintenance_all_Check_submitter ? true : false}
                                                onChange={(e) => maintanence_Submitter_Approve_Email("submitter", e.target.checked)}
                                            />
                                            <label htmlFor="Submit">
                                                <span></span>Submit
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" id="Approve" name="check"
                                                checked={maintenance_all_Check_approve ? true : false}
                                                onChange={(e) => maintanence_Submitter_Approve_Email("approve", e.target.checked)}
                                            />
                                            <label htmlFor="Approve">
                                                <span></span>Approve
                                            </label>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" id="Observe" name="check" disabled style={{ backgroundColor: "gray" }} />
                                            <label htmlFor="Observe" style={{ cursor: 'not-allowed' }}>
                                                <span></span>Observe
                                            </label>
                                        </div>

                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" id="EmailEOD" name="check"
                                                checked={maintenance_all_Check_email ? true : false}
                                                onChange={(e) => maintanence_Submitter_Approve_Email("email", e.target.checked)}
                                            />
                                            <label htmlFor="EmailEOD">
                                                <span></span>Email (Maintenance)
                                            </label>
                                        </div>

                                    </div>
                                </div>


                                {maintanenceUser?.map((maintUser, index) => (
                                    <div className="maintainterUserArea flex" key={maintUser.id}>
                                        <div className="memberItem !w-[45%]">
                                            <div className="memberItemImage">
                                                <img src={maintUser.img} alt={`Profile of ${maintUser.firstname}`} />
                                            </div>
                                            <div className="memberItemData d-block">
                                                <p className="memberItemTitle">{maintUser.firstname} {maintUser.lastname}</p>
                                                <p className="memberItemEmail" style={{ fontSize: 12 }}>
                                                    [[{maintUser.role}]]
                                                </p>
                                            </div>
                                            <div className="memberItemAction" onClick={() => removeMember("venue_Maintanence", maintUser)}>
                                                <span className="removeAdmin">Remove</span>
                                            </div>
                                        </div>
                                        <div className="markCheckedFields flex !w-[55%]">

                                            {/* maintanence submitter */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`maintanance_submit-${maintUser.id}`}
                                                    name={`check-submit1-${maintUser.id}`}
                                                    checked={maintanence_checkBox_method(maintUser.email, "submitter")}
                                                    onChange={(e) => maintanence_venue_update_checkBox("submitter", maintUser.email, e.target.checked)}
                                                />
                                                <label htmlFor={`maintanance_submit-${maintUser.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>



                                            {/* maintanence Approve */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`reopen2-${maintUser.id}`}
                                                    name={`check-reopen2-${maintUser.id}`}
                                                    checked={maintanence_checkBox_method(maintUser.email, "Approve")}
                                                    onChange={(e) => maintanence_venue_update_checkBox("Approve", maintUser.email, e.target.checked)}
                                                />
                                                <label htmlFor={`reopen2-${maintUser.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>



                                            <div className="checkbox-wrapper">
                                                <input type="checkbox"
                                                    id={`observer-${maintUser.id}`}
                                                    name={`check-observer-${maintUser.id}`}
                                                    checked={maintanence_checkBox_method(maintUser.email, "Observer")}
                                                    onChange={(e) => maintanence_venue_update_checkBox("Observer", maintUser.email, e.target.checked)}
                                                />
                                                <label htmlFor={`observer-${maintUser.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>



                                            {/* maintanence Email_mantanence */}
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    id={`EmailMmanta-${maintUser.id}`}
                                                    name={`check-EmailMmanta-${maintUser.id}`}
                                                    checked={maintanence_checkBox_method(maintUser.email, "Email_mantanence")}
                                                    onChange={(e) => maintanence_venue_update_checkBox("Email", maintUser.email, e.target.checked)}
                                                />
                                                <label htmlFor={`EmailMmanta-${maintUser.id}`}>
                                                    <span></span>
                                                </label>
                                            </div>




                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="name_and_profile teamProfile !w-full">
                        <div className="addGuest pl-0 !pr-0" >
                            <div className="inputGroup guestButton pl-0 !pr-[0px] mb-[5px]">
                                <button onClick={(e) => open_AddNewContactPopup("maintenance_conact")} className="addMemberBtn">Add contact(s) to this venue</button>
                            </div>

                            <div className="addedGuestList teamGuest eodContact">
                                {maintanence_Contact_user?.length === 0 && <div className="noGust">No contact(s) has been invited yet!</div>}

                                <div className="addedMemberList" style={{ display: maintanence_Contact_user.length === 0 ? 'none' : 'block' }}>
                                    <div className="headerMark">
                                        <label className="inputLabel !w-[55%]" style={{ fontSize: '16px' }}>
                                            User(s)
                                        </label>
                                        <div className="markAllChecked recip !flex !w-[45%]">

                                            <div className="checkbox-wrapper">
                                                <input type="checkbox"
                                                    id="maintanence_Contact-checkbox"
                                                    name="check"
                                                    checked={all_maintance_Contact_Check_email ? true : false}
                                                    onChange={(e) => checkBox_All_EOD_maintance_Contact_Email("Mataintanence_Contact_all_mail_checkBox", e.target.checked)}
                                                />
                                                <label htmlFor="maintanence_Contact-checkbox">
                                                    <span></span>Email(EOD)
                                                </label>
                                            </div>
                                        </div>
                                    </div>



                                    {/* maintanence_Contact_user List */}
                                    {maintanence_Contact_user?.map((maintance_Contactuser, index) =>
                                        <div className="maintainterUserArea flex" key={maintance_Contactuser.id}>
                                            <div className="memberItem !w-[55%]">
                                                <div className="memberItemImage">
                                                    <img src={maintance_Contactuser.img} alt={`Profile of ${maintance_Contactuser.firstname}`} />
                                                </div>
                                                <div className="memberItemData d-block">
                                                    <p className="memberItemTitle">{maintance_Contactuser.firstname} {maintance_Contactuser.lastname}</p>
                                                    <p className="memberItemEmail" style={{ fontSize: 12 }}>
                                                        [{maintance_Contactuser.email}]
                                                    </p>
                                                </div>
                                                <div className="memberItemAction">
                                                    <span className="removeAdmin">Remove</span>
                                                </div>
                                            </div>
                                            <div className="markCheckedFields flex !w-[45%]">

                                                <div className="checkbox-wrapper">
                                                    <input type="checkbox" id={`maintanance_contact-${maintance_Contactuser.id}`}
                                                        checked={eod_maintanence_contact_checkBox(maintance_Contactuser.email, "maintanence_contact_Email")}
                                                        onChange={(e) => Contact_eod_maintanence_Email_onClick("maintanence_contactEmail", maintance_Contactuser.email, e.target.checked)}
                                                        name="check" />
                                                    <label htmlFor={`maintanance_contact-${maintance_Contactuser.id}`}>
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}




                                </div>
                                {/* <div className="noGust">No contact(s) has been invited yet!</div> */}

                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="relative float-right clear-both right-0 top-[20px] pb-[10px]">
                {/* <button className="createConv_create transparent">Update venue</button> */}

                {isButtonDisabled ?
                    <button className="createConv_create" style={{ opacity: '0.5' }}>Update venue</button>
                    :
                    <button className="createConv_create" onClick={() => venue_Update("update")}>Update venue</button>
                }


            </div>
        </div>
    )
}

export default VenueUserSettting