/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USERS, Get_KeyWords, GET_ME, GET_TASKS, Projects, Task_assign_to_member } from '../../Utils/GraphqlQueries';
import { _DELETE_TASK, UPDATE_SINGLE_TASK } from '../../Utils/GraphqlMutaion';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { set_all_projects, set_deleted_task, set_project_ids, set_user } from '../../redux/taskSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { update_CountersData } from '../../redux/message';

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tasks_tab, conversation_id, task_id } = useParams();
    const client = useApolloClient();
    const { me: user } = client.readQuery({ query: GET_ME });
    const { users } = client.readQuery({ query: GET_ALL_USERS, variables: { company_id: user.company_id } });
    const countersdata = useSelector(state => state.message.countersdata)
    const { new_checklist, project_ids, new_file, new_task, delete_file, deleted_task } = useSelector(state => state.task)

    // State definitions
    const [viewType, setViewType] = useState(tasks_tab === 'kanban-view' ? 'kanban' : tasks_tab === 'list-view' ? 'list' : 'kanban'); // 'kanban', 'kanban_dueby', 'kanban_progress'
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [kanban_pagination, set_kanban_pagination] = useState([]);
    const [tasks, set_tasks] = useState([]);
    const [filter, set_filter] = useState([]);
    const [flag, set_flag] = useState([]);
    const [keywords, set_keywords] = useState([]);
    const [assign_to_list, set_assign_to_list] = useState([]);
    const [taskMemberFilter, set_taskMemberFilter] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [column_id, set_column_id] = useState([]);
    const [read_all, set_read_all] = useState('no');
    const [trackCostPopup, setTrackCostPopup] = useState(false);
    const [taskFileUpload, setTaskFileUpload] = useState(null);
    const [paginationLoading, setPaginationLoading] = useState(false);

    useEffect(() => {
        if (new_task?.participants?.includes(user.id)) {
            if (new_task.assign_to?.includes(user.id) || new_task.observers?.includes(user.id) || new_task.created_by === user.id) {
                set_tasks(prev => prev?.filter(f => f._id === new_task?._id).length > 0 ? prev?.map(m => m._id === new_task?._id ? { ...new_task } : m) : [new_task, ...prev])
                if (task_id) {
                    setSelectedTask(prev => prev?._id === new_task?._id ? { ...new_task } : prev)
                }
            } else {
                set_tasks(prev => prev?.filter(f => f._id !== new_task?._id))
                if (task_id) {
                    setSelectedTask(null)
                    if (tasks_tab) {
                        navigate(tasks_tab);
                    } else {
                        navigate('/connect/' + conversation_id);
                    }
                }
            }
        }
    }, [new_task])

    useEffect(() => {
        if (deleted_task) {
            setSelectedTask(prev => prev?._id === deleted_task?._id ? null : prev)
            if (task_id === deleted_task?._id) {
                setSelectedTask(null)
                if (tasks_tab) {
                    navigate(tasks_tab);
                } else {
                    navigate('/connect/' + conversation_id);
                }
            }
            set_tasks(prev => prev?.filter(f => f._id !== deleted_task?._id))
            dispatch(set_deleted_task(null))
        }
    }, [deleted_task])

    useEffect(() => {
        if (new_file?.length > 0) {
            setSelectedTask(prev => prev && ({ ...prev, files: prev?.files ? [...prev?.files, ...new_file] : [...new_file] }))
        }
    }, [new_file])

    useEffect(() => {
        if (delete_file) {
            setSelectedTask(prev => prev && ({ ...prev, files: prev?.files.filter(f => f.id !== delete_file.id) }))
        }
    }, [delete_file])

    useEffect(() => {
        let type = tasks_tab === 'kanban-view' ? 'kanban' : tasks_tab === 'list-view' ? 'list' : 'kanban'
        setViewType(type)
    }, [tasks_tab])

    useEffect(() => {
        dispatch(set_user(user));
    }, [user, dispatch]);

    useEffect(() => {
        if (new_checklist && selectedTask) {
            // Check if the new checklist belongs to the selected task
            const isMatchingTask = new_checklist.task_id === selectedTask._id;

            // Check if the checklist already exists in the selected task
            const isChecklistExists = selectedTask.checklists?.some((c) => c._id === new_checklist._id);

            if (isMatchingTask) {
                setSelectedTask((prev) => {
                    if (!prev) return prev; // Safeguard for undefined state

                    const updatedChecklists = isChecklistExists
                        ? prev.checklists.map((c) =>
                            c._id === new_checklist._id ? { ...new_checklist } : c
                        )
                        : [...prev.checklists, new_checklist];

                    return {
                        ...prev,
                        checklists: updatedChecklists,
                    };
                });
            }
        }
    }, [new_checklist, selectedTask]);


    useEffect(() => {
        if (tasks.length) {
            let cnt = tasks.filter(task => (task.view_update?.indexOf(user.id) > -1 || task.view_checklist?.indexOf(user.id) > -1 || task.view_cost?.indexOf(user.id) > -1 || task.view_description?.indexOf(user.id) > -1 || task.view_hour?.indexOf(user.id) > -1)).length;
            if (cnt > countersdata.taskunreadcount) {
                dispatch(update_CountersData({ taskunreadcount: cnt }))
            }
        }
    }, [tasks])

    useQuery(Projects, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.projects.status) {
                dispatch(set_all_projects(res.projects.data))
            }
        }
    });

    // Fetch keywords
    useQuery(Get_KeyWords, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            set_keywords(res?.keywords.data?.map(({ _id, keywords_title }) => ({ _id, keywords_title })));
        },
    });

    useQuery(Task_assign_to_member, {
        variables: { conversation_id: conversation_id || '' },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.task_assign_to_member.status) {
                set_assign_to_list(res.task_assign_to_member.assign_to)
                set_taskMemberFilter(res.task_assign_to_member.taskMemberFilter)
            }
        },
    });

    const [fetch_tasks, { loading, error, refetch }] = useLazyQuery(GET_TASKS, {
        fetchPolicy: 'no-cache',
        skip: !viewType.trim(),
        onCompleted: (res) => {
            set_tasks((prev) => (pagination.page === 1 ? res?.tasks?.data : [...prev, ...res?.tasks?.data]));

            if (viewType === 'list') {
                set_kanban_pagination(res?.tasks?.kanban_pagination);
            } else {
                set_kanban_pagination((prev) =>
                    pagination.page === 1
                        ? res?.tasks?.kanban_pagination
                        : prev.map((m) =>
                            m.kb_row_name === res?.tasks?.kanban_pagination?.[0]?.kb_row_name
                                ? { ...res?.tasks?.kanban_pagination?.[0] }
                                : m
                        )
                );
            }

            setPaginationLoading(false);
        },
        onError: (error) => toast.error(`Error fetching tasks: ${error.message}`),
    });

    useEffect(() => {
        set_tasks([])
        set_kanban_pagination([])
        setPagination(prev => ({ ...prev, page: 1 }));
        set_column_id([]);

        fetch_tasks({
            variables: {
                page: 1,
                limit: viewType === 'list' ? 15 : 10,
                filter: filter,
                view_type: viewType,
                project_ids: project_ids,
                status: [],
                flag: flag,
                read_all: read_all,
                conversation_id: conversation_id || ''
            }
        })
    }, [project_ids])

    useEffect(() => {
        fetch_tasks({
            variables: {
                page: 1,
                limit: viewType === 'list' ? 15 : 10,
                filter: filter,
                view_type: viewType,
                project_ids: project_ids,
                status: [],
                flag: flag,
                read_all: read_all,
                conversation_id: conversation_id || ''
            }
        })
    }, [flag])

    useEffect(() => {
        if (read_all !== 'no') {
            fetch_tasks({
                variables: {
                    page: 1,
                    limit: viewType === 'list' ? 15 : 10,
                    filter: filter,
                    view_type: viewType,
                    project_ids: project_ids,
                    status: [],
                    flag: flag,
                    read_all: read_all,
                    conversation_id: conversation_id || ''
                }
            })
        }
    }, [read_all])

    const fetchByPage = (page, columnId) => {
        fetch_tasks({
            variables: {
                page: page,
                limit: viewType === 'list' ? 15 : 10,
                filter: filter,
                view_type: viewType,
                project_ids: project_ids,
                status: columnId,
                flag: flag,
                read_all: read_all,
                conversation_id: conversation_id || ''
            }
        })
    }

    const fetchByViewType = (type) => {
        fetch_tasks({
            variables: {
                page: 1,
                limit: type === 'list' ? 15 : 10,
                filter: filter,
                view_type: type,
                project_ids: project_ids,
                status: [],
                flag: flag,
                read_all: read_all,
                conversation_id: conversation_id || ''
            }
        })
    }

    const fetchByFilter = (_f) => {
        fetch_tasks({
            variables: {
                page: 1,
                limit: viewType === 'list' ? 15 : 10,
                filter: _f,
                view_type: viewType,
                project_ids: project_ids,
                status: [],
                flag: flag,
                read_all: read_all,
                conversation_id: conversation_id || ''
            }
        })
    }

    const isObserver = () => {
        return selectedTask?.observers?.includes(user.id)
    }

    const isAssignee = () => {
        return selectedTask?.assign_to?.includes(user.id)
    }

    const [updateTask] = useMutation(UPDATE_SINGLE_TASK)

    const OnUpdateTask = async (updates) => {
        let _id = { _id: selectedTask?._id }
        try {
            let { data } = await updateTask({
                variables: {
                    input: { ..._id, ...updates }
                }
            })
            if (!conversation_id) {
                modifyTask(data.update_single_task?.[0])
                setSelectedTask(prev => ({ ...prev, ...data.update_single_task?.[0] }))
            } else {
                let conv_id = data.update_single_task?.[0].conversation_id
                if (conversation_id !== conv_id) {
                    set_tasks((prev) => prev.filter((task) => task.conversation_id !== conv_id));
                } else {
                    modifyTask(data.update_single_task?.[0])
                    setSelectedTask(prev => ({ ...prev, ...data.update_single_task?.[0] }))
                }
            }
            toast.success('Task updated successfully!')
        } catch (error) {
            console.log(error)
        }
    }

    // Function to modify a task in the list
    const modifyTask = useCallback((new_item) => {
        set_tasks((prev) => prev.map((task) => (task._id === new_item._id ? { ...new_item } : task)));
    }, []);

    // Function to delete a task from the list
    const deleteTask = useCallback((taskId) => {
        set_tasks((prev) => prev.filter((task) => task._id !== taskId));
    }, []);

    // Mutation to delete a task
    const [task_delete] = useMutation(_DELETE_TASK);
    const delete_task = async (_id) => {
        try {
            const apires = await task_delete({ variables: { input: { task_id: _id } } });
            if (apires.data.delete_task.status) {
                deleteTask(_id);
                setSelectedTask(null);
                navigate(tasks_tab)
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => () => {
        dispatch(set_project_ids(['all']))
    }, [])


    return (
        <TaskContext.Provider
            value={{
                tasks,
                set_tasks,
                user,
                users,
                loading,
                paginationLoading,
                setPaginationLoading,
                set_column_id,
                error,
                viewType,
                setViewType,
                pagination,
                setPagination,
                kanban_pagination,
                set_kanban_pagination,
                filter,
                set_filter,
                keywords,
                refetchTasks: refetch,
                modifyTask,
                deleteTask,
                delete_task,
                selectedTask,
                setSelectedTask,
                assign_to_list,
                taskMemberFilter,
                set_flag,
                flag,
                OnUpdateTask,
                trackCostPopup,
                setTrackCostPopup,
                taskFileUpload,
                setTaskFileUpload,
                isObserver,
                isAssignee,
                set_read_all,
                // New Events
                fetchByPage,
                fetchByViewType,
                fetchByFilter
            }}
        >
            {children}
        </TaskContext.Provider>
    );
};

// Hook to use the Task context
export const useTaskContext = () => {
    const context = useContext(TaskContext);
    if (!context) {
        throw new Error('useTaskContext must be used within a TaskProvider');
    }
    return context;
};
