import React from 'react';

const UserProWarnning = ({ onCancel, onSave }) => {
    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle !font-bold">Save changes ?</h4>
                    <span className="closeModal" onClick={onCancel}></span>
                </div>
                <div className="freeliModalBody">
                    <p className="delDesc !text-base !text-justify !px-[10px]">
                        You have made some changes in User Profile. Would you like to save the changes?
                    </p>
                </div>
                <div className="freeliModalFoot">
                    <button className="btnCancel" onClick={onCancel}>Cancel changes</button>
                    <button className="btnActionConfirm hover:!bg-[#318EFA]" onClick={onSave}>Save changes</button>
                </div>
            </div>
        </div>
    );
};

export default UserProWarnning;