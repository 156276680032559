/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import { useMutation, useLazyQuery } from "@apollo/client";
// import { GET_RING_CALLING, SET_CALLING_URL, GET_CALLING_ACCEPT } from '../../../Utils/GraphqlQueries';
// import ReactTooltip from 'react-tooltip';
import { voip_send_msg, getXmppToken } from '../../../Utils/Common';
import { useDispatch } from 'react-redux';
import { set_callringpopup } from '../../../redux/message';

window.timer1 = 0;

function CallRinging({ data, setCallRinging, me, getCallingAccept, getCallingReject }) {
    const dispatch = useDispatch();
    // const [getCallingAccept] = useLazyQuery(GET_CALLING_ACCEPT);

    // const staticData = {
    //     ring_index: 1,
    //     caller_img: "/media/images/call/guest_16px.png",
    //     caller_name: "John Doe",
    //     conversation_id: "12345",
    //     user_busy: false,
    //     call_merge: {}
    // };

    useEffect(() => {
        window.timer1 = setTimeout(() => {
            // ReactTooltip.hide();
            closeRingWindow();
        }, 60000);

        if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((rstream) => {
                let ring_audio = document.getElementById('ring_audio_' + data.ring_index);
                if (ring_audio) ring_audio.play();
                rstream.getTracks().forEach((track) => track.stop());
            });
        } else {
            let ring_audio = document.getElementById('ring_audio_' + data.ring_index);
            if (ring_audio) ring_audio.play();
            alert('call not supported.');
        }
    }, []);

    const closeRingWindow = () => {
        clearTimeout(window.timer1);
        let ring_audio = document.getElementById('ring_audio_' + data.ring_index);
        if (ring_audio) ring_audio.pause();
        // console.log('Call window closed');
        dispatch(set_callringpopup({ status: false }));
    };
    function openCallWindow(conversation_id) {
        var ww = window.screen.availWidth * 0.8;
        var hh = window.screen.availHeight * 0.8;
        var left = (window.screen.width / 2) - (ww / 2);
        var top = (window.screen.height / 2) - (hh / 2);
        // window.name = "parent";
        var win_voip = window.open("", 'calling_' + conversation_id, "width=" + ww + ",height=" + hh + ', top=' + top + ', left=' + left);
        // console.log('win_voip', win_voip.location.href)
        if (conversation_id && win_voip && win_voip.location) {
            if (win_voip.location.href === 'about:blank') win_voip.location.href = '/call/' + conversation_id;
            return win_voip;
        }
        // clean_timer_user();
    }
    const callAcceptBtn = async () => {
        // ReactTooltip.hide();
        // debugger
        // console.log('Call accepted:', data.conversation_id);
        closeRingWindow();
        openCallWindow(data.conversation_id);

        getCallingAccept({
            variables: {
                user_id: me.id,
                conversation_id: data.conversation_id,
                token: getXmppToken(),
                type: 'accept',
                device_type: 'web'
            },
            fetchPolicy: 'network-only',
        });
        // let cb_data = apires?.data?.jitsi_call_accept;
        // console.log("getCallingAccept", cb_data);

        
    };
    const callRejectBtn = async () => {
        setCallRinging(false);
        // ReactTooltip.hide();
        closeRingWindow();
        let apires = await getCallingReject({
            variables: {
                user_id: me.id,
                user_fullname: me.firstname,
                conversation_id: data.conversation_id,
                msg_code: 0,
                token: getXmppToken()
    
            },
            fetchPolicy: 'network-only',
        });

        let cb_data = apires?.data?.jitsi_call_hangup;
        // console.log("callRejectBtn", cb_data);


        // voip_send_msg('jitsi_call_hangup', {
        //     user_id: me.id,
        //     user_fullname: me.firstname,
        //     conversation_id: data.conversation_id,
        //     msg_code: 0,
        //     token: getXmppToken()

        // });

    };
    const callHoldBtn = () => {
        // ReactTooltip.hide();
        closeRingWindow();
        // console.log('Call held:', data.conversation_id);
    };

    const mergeCallBtn = () => {
        // ReactTooltip.hide();
        // closeRingWindow();
        // console.log('Call merged:', data.conversation_id);
        if (Object.keys(data.call_merge).length > 1) {
            // props.setPopup({type:'mergeconvpopup', data: true});
            closeRingWindow()
        } else {
            clearTimeout(window.timer1);
            let ring_audio = document.getElementById('ring_audio_' + data.ring_index);
            if (ring_audio) ring_audio.pause();
            dispatch({ type: 'set_callringpopup', payload: { status: false } });
            dispatch({ type: 'busy_call_msg', payload: false });
            // props.setPopup({type:'voipHoldMute', data: false});
            // props.setPopup({type:'mergeconvpopup', data: false});
            closeRingWindow();
            var logindata = null; logindata = localStorage.getItem('logindata'); if (logindata) logindata = JSON.parse(logindata);
            voip_send_msg('jitsi_call_merge', {
                user_id: me.id,
                conversation_id: data.user_busy, // current conv_id for single merge
                merge_id: data.caller_id, // from jitsi_ring_send
                conv_id_old: data.conversation_id, // from jitsi_ring_send
                token: getXmppToken(),
                // user_fullname: logindata.user.firstname, // new
                // user_img: logindata.user.img, // new
                // user_email: logindata.user.email,

            });


        }
    };

    const CallBusyMsg = () => {
        // ReactTooltip.hide();
        // console.log('Busy message sent');
    };

    return (
        <>
            <div className="backwrapCall">
                <div id={`ring_div_${data.ring_index}`} className="myCallModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <img className="calling_userimg" src={data.caller_img} alt="Caller" title='Caller' />
                                <p className="modalCallMsg">{data.caller_name}</p>
                                <p className="modal_incomingcall">INCOMING CALL</p>
                                <p className="modal_callstatus">Ringing...</p>
                                <audio id={`ring_audio_${data.ring_index}`} className="mdCallRingtone call_ringtone_audio" playsInline autoPlay src="https://wfss001.freeli.io/common/simple_corporate_tone.mp3" controls loop></audio>
                            </div>
                            <div className="modal-footer">
                                <div className="mergeBtnGroup">
                                    <img title='Reject Call' alt="Reject Call" src="/media/images/call/hang-up_56px.png" className="modalCallReject" onClick={callRejectBtn} />
                                    {data.user_busy ?
                                        <>
                                            {Object.keys(data.call_merge).length ? <img title='Merge Call' data-tip="Merge Call" src="/media/images/call/call_merge.png" className="modalCallAccept" onClick={mergeCallBtn} alt="Merge Call" /> : ''}
                                            <img title='Hold & Accept Call' alt="Hold & Accept Call" src="/media/images/call/answer_64px.png" className="modalCallAccept" onClick={callHoldBtn} />
                                        </>
                                        :
                                        <img title='Accept Call' alt="Accept Call" src="/media/images/call/answer_64px.png" className="modalCallAccept" onClick={callAcceptBtn} />
                                    }
                                    <img title='Reject Message' alt="Reject Message" src="/media/images/call/message_56px.png" className="modalCallTextMsg" onClick={CallBusyMsg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CallRinging;
