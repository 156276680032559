import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MdAttachFile, MdClose, MdOutlineKeyboardBackspace } from "react-icons/md";
import SelectVenueAndMaintenance from './SelectVenueAndDate';
import { IoCloseOutline } from 'react-icons/io5';
import { TfiClose } from "react-icons/tfi";
import MaintenanceForm from './MaintenanceForm';
import SelectVenueAndDate from './SelectVenueAndDate';
import ActivityAndFilesFooter from './ActivityAndFilesFooter';

const CreateEditMaintenance = ({ onClose, report, isPopupOpen, setIsPopupOpen }) => {
    const navigate = useNavigate();
    const [reportingDate, setReportingDate] = useState(moment().toDate());

    useEffect(() => {
        setReportingDate(moment().toDate());
    }, []);

    const [isCheckedEmergency, setIsCheckedEmergency] = useState(false);
    const [isCheckedAlreadyDone, setIsCheckedAlreadyDone] = useState(false);

    const handleEmergencyCheckboxChange = () => {
        setIsCheckedEmergency(prevChecked => !prevChecked);
    };
    const handleAlreadyDoneCheckboxChange = () => {
        setIsCheckedAlreadyDone(prevChecked => !prevChecked);
    };


    return (
        <div className='popUpRightContainer'>
            <div className='adminRightHead taskHeader !pl-5 flex items-center justify-between'>
                <div onClick={onClose} className="backToMainProperties flex items-center">
                    <MdOutlineKeyboardBackspace className='text-[#0b1f47] text-3xl mr-2' />
                    <span className="mfromId"> Maintenance form:</span> <span className="mfromId">MF-5269170359</span>
                </div>
                <div className='flex gap-4'>
                    <div className="fromstatusDiv statusdiv flex gap-2 items-center">
                        <p className="statusNewLabel">Form status:</p>
                        <p className='bg-[#DFE7EC] text-[#0b1f47] w-max flex items-center py-[2px] px-3 rounded-[15px]'>Draft</p>
                    </div>
                    <div className="jobstatusDiv statusdiv flex gap-2 items-center">
                        <p className="statusNewLabel">Job status:</p>
                        <p className="pointerClass bg-[#DFE7EC] text-[#0b1f47] w-max flex items-center py-[2px] px-3 rounded-[15px]">
                            <span className="textTransformFast">Not started</span>
                        </p>
                    </div>
                </div>
                <div onClick={onClose}><TfiClose className='text-[#0b1f47] text-2xl mr-3' /></div>
            </div>
            <div className='task_body_area inReportArea mt-3'>
                <SelectVenueAndDate />
                <MaintenanceForm />

                <div className="mcolumn px-5 mt-7">
                    <div className="operational_note allNotes">
                        <p className="reportLabel">
                            Detailed description of the issue (optional): <span className='!text-xs'>Characters left: 300</span>
                        </p>
                        <textarea
                            className="maintenanceTextArea rounded-[5px] w-[calc(100%-88px)]"
                            name="notes"
                            id="shortNoteTextArea"
                            rows="2"
                            placeholder="Add a details description"
                        />
                    </div>
                </div>

                <div className='flex gap-5 items-center py-3'>
                    <div className="tagsRelateLists px-5">
                        <div className="checkboxes__item">
                            <label className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={isCheckedEmergency}
                                    onChange={handleEmergencyCheckboxChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body !text-sm !mt-[2px]">
                                    Mark as emergency (optional)
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="tagsRelateLists px-5 mt-2">
                        <div className="checkboxes__item">
                            <div className="checkbox style-c">
                                <input
                                    type="checkbox"
                                    checked={isCheckedAlreadyDone}
                                    onChange={handleAlreadyDoneCheckboxChange}
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body !text-sm !mt-[2px]">
                                    Has the job already been done on this request?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ActivityAndFilesFooter />


                <div className="tagCoupleBtn py-5">
                    <button className='py-1.5 mr-1.5 px-4 hover:bg-[#318FFF] text-white bg-[#0b1f47] rounded-full text-xs'>
                        Save as draft
                    </button>
                    <button className='py-1.5 mr-1.5 px-4 hover:bg-[#0b1f47] text-white bg-[#318FFF] rounded-full text-xs'>
                        Submit
                    </button>
                    <button className='py-1.5 mr-1.5 px-4 hover:bg-[#318FFF] text-white bg-[#0b1f47] rounded-full text-xs'>
                        Approve
                    </button>
                    <button className='py-1.5 px-4 hover:bg-[#0b1f47] text-white bg-[#318FFF] rounded-full text-xs'>
                        Mark as complete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateEditMaintenance;