/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useLazyQuery } from '@apollo/client';
import React from 'react';
import { DELETE_FILE_MUTATION, DELETE_MSG_MUTATION } from '../../Utils/GraphqlMutaion';
import { SET_CALLING_URL } from '../../Utils/GraphqlQueries';
import toast from 'react-hot-toast';
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
const WarningPopup = ({ closePopUp, messageTitle, description, type, data, setshortID, allFiles = [], setallFiles, ...props }) => {
    const user = useSelector(state => state.message.user)
    const active_conversation = useSelector(state => state.message.active_conversation)
    console.log("🚀 ~ WarningPopup ~ data:", data, user)
    const [deleteMutaion] = useMutation(DELETE_MSG_MUTATION)
    const [deleteFile] = useMutation(DELETE_FILE_MUTATION)
    const [setCallingURL] = useLazyQuery(SET_CALLING_URL);

    const deleteMsgMethod = (delete_type) => {
        try {
            deleteMutaion({
                variables: {
                    input: {
                        conversation_id: data.conversation_id,
                        msg_id: data.msg_id,
                        delete_type: delete_type,
                        is_reply_msg: data.is_reply_msg,
                        participants: data.participants
                    }
                }
            });
            toast.success('Message deleted!');
            // dispatch(delete_single_item_form_array({ state_key: data.is_reply_msg === 'yes' ? 'reply_msgs' : 'msgs', item_key: 'msg_id', value: data.msg_id }))
            closePopUp();
        } catch (error) {
            console.log(error)
        }
    }

    const delete_file_method = () => {
        try {
            // debugger;
            deleteFile({
                variables: {
                    input: {
                        file_id: data.id,
                        participants: data.participants,
                    }
                }
            })
            toast.success('File deleted!');
            closePopUp();
            if(setallFiles) setallFiles([...allFiles.filter(f=> f.id !== data.id)])
        } catch (error) {
            console.log("🚀 ~ WarningPopup ~ error:", error)
        }
    }

    const onConfirm = async () => {
        switch (type) {
            case 'file_delete':
                delete_file_method()
                break;
            case 'call_url_reset':
                let apires = await setCallingURL({
                    variables: {
                        "user_id": user.id,
                        "company_id": user.company_id,
                        "conversation_id": user.id,
                        "type": "user",
                        "action": "reset"
                    },
                    fetchPolicy: 'network-only', // Ensures it fetches fresh data from the server
                });
                let cb_data = apires?.data?.set_url_calling;
                if (setshortID && cb_data.short_id) setshortID(cb_data.short_id);
                closePopUp()
                break;
            
            case 'call_group_reset':
                let apires2 = await setCallingURL({
                    variables: {
                        "user_id": active_conversation.conversation_id,
                        "company_id": active_conversation.company_id,
                        "conversation_id": user.id,
                        "type": "group",
                        "action": "reset"
                    },
                    fetchPolicy: 'network-only', // Ensures it fetches fresh data from the server
                });
                let cb_data2 = apires2?.data?.set_url_calling;
                if (setshortID && cb_data2.short_id) setshortID(cb_data2.short_id);
                closePopUp()
                break;
                
            case 'task_update':
                props.onConfirm();
                break
            default:
                break;
        }
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                closePopUp();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div className="backwrap">
            <div className="freeliModal">
                <div className="freeliModalHead">
                    <h4 className="popupTitle">{messageTitle}</h4>
                    <span onClick={closePopUp} className="closeModal" data-for="rightSection_tooltip" data-tip="Close" currentitem="false"></span>
                </div>
                <div className="freeliModalBody">
                    <div className="delDesc">{description}</div>
                </div>
                <div className="freeliModalFoot">
                    <button onClick={closePopUp} className="btnCancel">Cancel</button>
                    {type === 'delete_msg' ?
                        active_conversation?.conversation_id === user.id ?
                            <button className="btnActionConfirm" onClick={() => deleteMsgMethod('for_all')} style={{ backgroundColor: '#f55' }}>Delete</button>
                            :
                            <>
                                <button className="btnActionConfirm !bg-[#FF5955] hover:!bg-[#D82D28]" onClick={() => deleteMsgMethod('for_me')} style={{ backgroundColor: '#f55' }}>Delete for me</button>
                                {data?.sender === user.id && <button className="btnActionConfirm !bg-[#FF5955] hover:!bg-[#D82D28]" onClick={() => deleteMsgMethod('for_all')} style={{ backgroundColor: '#f55' }}>Delete for all</button>}
                            </>
                        :
                        <button className="btnActionConfirm" onClick={onConfirm}>Confirm</button>
                    }

                </div>
            </div>
        </div>
    );
};

export default WarningPopup;