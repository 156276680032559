import { useClickAway } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { MODULE_ADD } from '../../../Utils/GraphqlMutaion';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';

const ModuleDetails = ({ modules, setModules, selectedModule, setModuleDetailsPopup }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(false);
    const [sortKey, setSortKey] = useState('module_title'); // default sorting by name
    const [sortOrder, setSortOrder] = useState('asc'); // default sorting order
    const [moduleName, setModuleName] = useState('');
    const [createSubModulePopup, setCreateSubModulePopup] = useState(false);
    const [thisModule, setThisModule] = useState(selectedModule);
    const [openOptions, setOpenOptions] = useState(null);
    
    const [update] = useMutation(MODULE_ADD);

    const ref = useClickAway(() => {
        setOpenOptions(false);
    });

    if (!thisModule) return null; // Handle the case where thisModule might be null

    // Filter subModules based on search query
    const filteredSubModules = thisModule.sub_module.filter(subModule =>
        subModule.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Sort filteredSubModules
    const sortedSubModules = filteredSubModules.sort((a, b) => {
        const aValue = a[sortKey];
        const bValue = b[sortKey];

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleMoreOptionsClick = (moduleId) => {
        setOpenOptions((prev) => (prev === moduleId ? null : moduleId));
    };

    // Handle sort change
    const handleSortChange = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const handleCreateSubModule = async () => {
        setCreateSubModulePopup(false)
        // console.log(62, thisModule)
        setLoader(true);
        try {
            const { data: moduleAddData } = await update({
                variables: {company_id: thisModule.company_id, module_title: moduleName, is_sub_module: true, module_id: thisModule.module_id}
            });
            const { status, message, data } = moduleAddData.module_add;
            if (status) {
                // console.log(70, data)
                
                setModules((prevModules) => [...prevModules.map((e)=> e.module_id === data.module_id ? {...data} : e)]);
                setThisModule({...data})
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            setLoader(false);
        }

        // Reset fields and close modal
        setModuleName('');
    };

    return (
        <>
            <div className='right_containerr'>
                <div className="userInfoModal">
                    <div className="adminRightHead" onClick={() => setModuleDetailsPopup(false)}>
                        <span className="closeAdminPanel">Back to Module management</span>
                    </div>
                    <div className="teamInfoNameSection">
                        <div className="teamInfoName">{thisModule.module_title} ({thisModule.sub_module.length})</div>
                        <div className="teamInfoDate">
                            <span>Created by: {thisModule.createdBy} On: <Moment format="MMM Do, YYYY">{thisModule.updated_at}</Moment></span> |
                            <span> Last updated by: {thisModule.createdBy} On: <Moment format="MMM Do, YYYY h:mm A">{thisModule.updated_at}</Moment></span>
                        </div>
                    </div>
                    <div className="tagInfoActionSection">
                        <div style={{ width: '400px', position: 'relative', marginRight: '12px' }}>
                            <input
                                className="searchTeamUser"
                                type="text"
                                placeholder="Search sub modules"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <span onClick={() => setSearchQuery('')} className="leftSearchCloseBtn remove" style={{ top: '8px', right: '10px' }}></span>
                        </div>
                        <div onClick={() => setCreateSubModulePopup(true)}>
                            <button className="userCreateBtn tooltip5 !mr-0">Create Sub Module</button>
                        </div>
                    </div>
                    <div className='usersTableContainer'>
                        <div className="userTableHead">
                            <ul className="">
                                <li className={`_userName !w-[95%] ${sortOrder === 'asc' ? 'asce' : 'desc'} sort_active`}
                                    onClick={() => handleSortChange('module_title')}>
                                    <span className={`sortIcons`}></span>Module Name
                                </li>
                                <li className="_userAction !justify-center !w-[5%]">
                                    Action
                                </li>
                            </ul>
                        </div>
                        <div className='userTableBody'>
                            {sortedSubModules.length > 0 ? (
                                sortedSubModules.map((subModule, k) => (
                                    <div className='relative' key={`subModulekey${k}`}>
                                        <ul className="_userList">
                                            <li className="_userName" style={{ width: "95%" }}>
                                                <span className="_userNameTxt">{subModule}</span>
                                            </li>
                                            <li onClick={() => handleMoreOptionsClick(subModule)} className="_userAction !justify-center" style={{ width: "5%" }}>
                                                <span className="userActionIcon"></span>
                                            </li>
                                        </ul>

                                        {openOptions === subModule && (
                                            <ul ref={ref} className="userActionPopup !absolute">
                                                {/* <li className="!text-start">Active sub module</li> */}
                                                <li className="!text-start">Edit sub module</li>
                                                <li className="!text-start">Delete sub module</li>
                                            </ul>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="noResults !text-center mt-48">
                                    <p>No modules found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {createSubModulePopup &&
                <div className="backwrap">
                    <div className="createTeamModal">
                        <div className="createTeamModalHead">
                            <h4 className="popupTitle">Create Sub Module</h4>
                            <span className="closeModal" onClick={() => setCreateSubModulePopup(false)}></span>
                        </div>
                        <div className="createTeamModalBody">
                            <div className="crTeam_inputGroup">
                                <label htmlFor="moduleName">Sub Module Name</label>
                                <input type="text" id="moduleName" value={moduleName} onChange={(e) => setModuleName(e.target.value)} maxLength="25" placeholder="Sub module name" />
                            </div>
                            <div className='createTeamModalFoot !px-0'>
                                <button onClick={() => handleCreateSubModule()} className={`${moduleName ? 'btnAction' : 'btnAction opacity-50 pointer-events-none'}`}>
                                    Create Sub Module
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ModuleDetails;
