import React, { useState } from "react";
function StatisticalSummary({ demoData }) {
    // console.log('StatisticalSummary:demoData',demoData);
    let summaryData = [
        { label: "Total Hours", value: `${demoData?.statistics?.TotalHours || 0}` },
        { label: "Average $/Hour Gross", value: `$ ${demoData?.statistics?.AvgHourGross || 0}`},
        { label: "Average $/Hour Net", value: `$ ${demoData?.statistics?.AvgHourNet || 0}` },
        { label: "Average Guest/Hour", value: `${demoData?.statistics?.AvgGuestHour || 0}` },
        { label: "Total Checks", value: `${demoData?.statistics?.CheckCount || 0}` },
        { label: "Average $/Check Gross", value: `$ ${demoData?.statistics?.AvgCheckGross || 0}` },
        { label: "Average $/Check Net", value: `$ ${demoData?.statistics?.AvgCheckNet || 0}` },
        { label: "Total Entrees", value: `${demoData?.statistics?.EntreeCount || 0}` },
        { label: "Total Guests", value: `${demoData?.statistics?.GuestCount || 0}` },
        { label: "Average $/Guest Gross", value: `$ ${demoData?.statistics?.AvgGuestGross || 0}` },
        { label: "Average $/Guest Net", value: `$ ${demoData?.statistics?.AvgGuestNet || 0}` },
        { label: "Average Stay (hrs)", value: `${demoData?.statistics?.AverageStay || 0}` },
      ];
    
      return (
        <div className="rounded-lg p-6">
          <h2 className="text-[#0b1f47] text-[32px]  mb-4">Statistical summary</h2>
          <div className="rounded-[10px] px-[20px] py-[20px] border-b-[#f1f6fa] shadow-md shadow-black/10 border border-[#f1f6fa] bg-white">
            {summaryData.map((item, index) => (
              <div key={index} className="flex justify-between py-[22px] pb-[7px] text-[14px]  text-[#0b1f47]">
                <span className="">{item.label}</span>
                <span className="">{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
  export default  StatisticalSummary
  