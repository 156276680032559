import React from 'react';

const SupportAndBillingProgressBar = ({progress,height}) => {
     
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: '#e1e4e8',
        borderRadius: 25,
        position: 'relative',
        top: '4px'
       
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#0c1e47',
        borderRadius:25,
        textAlign: 'right'
      }
      
      const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
      }
        
    return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}></span>
       
      </div>
    </div>
    )
}
  
export default SupportAndBillingProgressBar;




