import { useClickAway } from '@uidotdev/usehooks';
import classNames from 'classnames';
import React from 'react';

const LeftFilterOptions = ({ setFilterOptions, filter_status, setFilter_status }) => {
    const ref1 = useClickAway(() => {
        setFilterOptions(false)
    });

    const handleFilterChk = (type) => {
        setFilter_status(type)
        setFilterOptions(false)
    }

    return (
        <div ref={ref1} className="filterActionPopup !z-[9999] !top-[360px]">
            <p className="profilenavMidleArea_label">Filter</p>
            <ul className="filterActionList">
                <li onClick={() => { handleFilterChk('') }} className={classNames('all', filter_status === '' && 'active')} > All</li>
                <li onClick={() => { handleFilterChk('createdByMe') }} className={classNames('filter_list', filter_status === 'createdByMe' && 'active')} > Created by me</li>
                <li onClick={() => { handleFilterChk('createdByOther') }} className={classNames('filter_list', filter_status === 'createdByOther' && 'active')} >Created by others</li>
                <li onClick={() => { handleFilterChk('group') }} className={classNames('filter_list', filter_status === 'group' && 'active')} >Rooms</li>
                <li onClick={() => { handleFilterChk('single') }} className={classNames('filter_list', filter_status === 'single' && 'active')} >Direct messages</li>
            </ul>
        </div>
    );
};

export default LeftFilterOptions;