import { useClickAway } from '@uidotdev/usehooks';
import React, { useRef, useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
// import {CREATE_QUICK_TASK} from '../../../Utils/GraphqlMutaion'

const CreateTaskTitle = ({ task, setValue }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [charactersLeft, setCharactersLeft] = useState(40);

    const inputRef = useRef(null);

    useEffect(() => {
        setCharactersLeft(40 - task.task_title.length); // Update characters left on initial load
    }, [task.task_title]); // Added dependency to rerun effect when title changes

    const handleEditClick = () => {
        setIsEditing(true);
        inputRef.current.focus();
    };

    const handleSaveClick = async () => {
        setIsEditing(false);
        toast.success("Task saved successfully!");
    };

    const handleChange = (event) => {
        const inputText = event.target.value;
        setValue(prev => prev.map(t => t.temp_key === task.temp_key ? { ...t, task_title: inputText } : t))
        // setTitle(inputText);
        const remainingCharacters = 40 - inputText.length;
        setCharactersLeft(remainingCharacters);

        if (remainingCharacters < 1) {
            toast.error("Maximum character limit reached!");
        }
    };

    const inputWidth = task.task_title.length * 8; // Adjusted input width calculation

    const ref = useClickAway(() => {
        handleSaveClick()
    });

    return (
        <>
            <div>
                <Toaster />
                {isEditing ? (
                    <div className='flex gap-2' ref={ref}>
                        <input
                            ref={inputRef}
                            value={task.task_title}
                            style={{ width: inputWidth }}
                            className="addNewTask text-sm"
                            placeholder='Write a task here'
                            autoFocus={true}
                            maxLength={40}
                            onChange={handleChange}
                        />
                        <div onClick={handleSaveClick} className='editImageEdit'></div>
                    </div>
                ) : (
                    <div className='flex gap-4'>
                        <p className='text-sm task_title_text'>{task.task_title}</p>
                        <div onClick={handleEditClick} id='updateTitle' className='editImageEdit'></div>
                    </div>
                )}
                {isEditing && (
                    <p className={`text-[10px] ${charactersLeft < 5 && "text-red-500"}`}>
                        Characters left: {charactersLeft}
                    </p>
                )}

            </div>
            <Tooltip anchorSelect="#updateTitle" place='top' content="Click to update title"></Tooltip>
        </>
    );
};

export default CreateTaskTitle;
