/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom"
import ChatRoomMoreOptions from "./ChatTopNav/ChatRoomMoreOptions";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import SearchRoomOptions from "./ChatTopNav/SearchRoomListOptions";
import { MdKeyboardArrowDown } from "react-icons/md";
import FilterOptions from "./ChatTopNav/FilterOptions";
import { useDispatch } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";
import { PIN_UNPIN_MUTATION } from "../../Utils/GraphqlMutaion";
import { set_pin_unpin, setUserShortUrl, setConvShortUrl } from "../../redux/message";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import GroupCallPopup from "../Call/GroupCallPopup";
// import CallRinging from "../Call_window/Popup/CallRinging";
import { setCallingUrl, ring_calling } from '../../Utils/Common';
import { GET_RING_CALLING, SET_CALLING_URL, GET_CALLING_ACCEPT, GET_CALLING_REJECT } from '../../Utils/GraphqlQueries';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from 'react-hot-toast';
import { GoSearch } from "react-icons/go";
import { IoClose } from "react-icons/io5";


export const RightTop = (props) => {
    const params = useParams()
    const { conversation_id } = params
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const active_conv = useSelector(state => state.message.active_conversation);
    const conv_calling_data = useSelector(state => state.message.conv_calling_data);

    // const callringpopup = useSelector(state => state.message.callringpopup)
    const _user = useSelector(state => state.message.user)
    const [roomMoreOptions, setRoomMoreOptions] = useState(false);
    const [searchRoomListOptions, setSearchRoomListOptions] = useState(false);
    const [openHeadMoreOptions, setOpenHeadMoreOptions] = useState(false);
    const [headSearchOptions, setHeadSearchOptions] = useState(false);
    // const [, setCallRinging] = useState(false);
    const [, set_callMembers] = useState([]);
    const [groupPopup, setGroupPopup] = useState(false);
    const [voice, setVoice] = useState(false)
    const [fetchCalling] = useLazyQuery(GET_RING_CALLING);
    const [setCallingURL] = useLazyQuery(SET_CALLING_URL);
    const [getCallingAccept] = useLazyQuery(GET_CALLING_ACCEPT);
    const [getCallingReject] = useLazyQuery(GET_CALLING_REJECT);


    const {
        listening,
        transcript,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const ToggleVoice = (type) => {
        if (browserSupportsSpeechRecognition) {
            if (isMicrophoneAvailable) {
                if (listening) {
                    SpeechRecognition.abortListening();
                    resetTranscript();
                } else {
                    SpeechRecognition.startListening({ continuous: true })
                }
            } else {
                toast.error("Mircrophone access denied !", { duration: 2000 });
            }
        } else {
            toast.error("Your Browser doesn't  support this feature", { duration: 2000 });
        }
        setVoice(type)
    }

    const getUserOnlineOrOffline = (userid) => {
        if (userid === undefined || userid.length === 0) {
            userid = ''
        }
        if (props.me.id === conversation_id) {
            return true;
        } else {
            if (props.online_users.indexOf(userid) > -1) {
                return true;
            } else {
                return false;
            }
        }
    }

    const checkLength = (arr) => {
        if (arr === undefined) {
            arr = [];
        }
        return arr.length;
    }

    const gotoProfile = () => {
        console.log(_user);
        if (active_conv?.group === 'yes') {
            navigate('update_room')
        } else {
            if (_user.id === conversation_id) {
                navigate('/connect/profile')
            }
        }
    }

    const [pin_unpin, { data: pin_unpin_res }] = useMutation(PIN_UNPIN_MUTATION);
    useEffect(() => {
        if (pin_unpin_res) {
            if (pin_unpin_res.pin_unpin.status) {
                dispatch(set_pin_unpin({
                    conversation_id: conversation_id,
                    action: active_conv?.pin.indexOf(props.me.id) > -1 ? 'unpin' : 'pin',
                    user_id: props.me.id
                }))
            }
        }
    }, [pin_unpin_res])

    const handlePinUnpin = () => {
        try {
            pin_unpin({
                variables: {
                    input: {
                        conversation_id: conversation_id,
                        action: active_conv?.pin.indexOf(props.me.id) > -1 ? 'unpin' : 'pin'
                    }
                }
            })
        } catch (error) {
            console.log("🚀 ~ handlePinUnpin ~ error:", error)
        }
    }

    const [search_string, setStringSearch] = useState('')

    const handleStringSearch = (e) => {
        let value = e.target.value;
        console.log("🚀 ~ handleStringSearch ~ value:", value)
        let enterkey = e.keyCode === 13;
        setStringSearch(value);
        let encodedStr = encodeURIComponent(value)
        if (value && enterkey) {
            navigate(`filter/str/${encodedStr}`);
        }
    }

    const searchClearMethod = () => {
        setStringSearch('');
    }

    useEffect(() => {
        if (transcript.length > 0) {
            var cc = (transcript === 'quotation' || transcript === 'semicolon' || transcript === 'new line' || transcript === "dash" || transcript === "comma" || transcript === 'underscore' || transcript === 'full stop' || transcript === 'dot' || transcript === 'enter' || transcript === 'exclamation mark' || transcript === 'question mark' || transcript === 'square bracket' || transcript === 'round bracket' || transcript === 'apostrophe' || transcript === 'tab' || transcript === 'space' ? '' : ' ') + transcript.replace(/tab/gmi, '\t').replace(/new line|enter/gmi, "\n").replace(/full stop|dot/gmi, ".").replace(/space/gmi, " ").replace(/semicolon/gmi, ";").replace(/underscore/gmi, "_").replace(/hyphen/gmi, "-").replace(/Question mark/gmi, "?").replace(/exclamation mark/gmi, "!").replace(/quotation/gmi, "'").replace(/comma/gmi, ",").replace(/dash/gmi, "-").replace(/square bracket/gmi, "[ ]").replace(/round bracket/gmi, "( )").replace(/apostrophe|appostrophe/gmi, "'")
            console.log(160, "🚀 ~ useEffect ~ cc:", cc)
            if (voice) {
                setStringSearch(cc);
            }
        }
    }, [transcript])

    const GroupPopupToggle = () => {
        setGroupPopup(!groupPopup)
    }

    const [groupCallPopup, setGroupCallPopup] = useState(false);

    const open_calling_window = async (type) => {
        let conversation_type = active_conv?.group === 'yes' ? 'group' : 'personal';
        let user_id = props.me.id;
        var active_conv_details = { ...active_conv };
        if (conversation_type === 'group') {
            if (!active_conv_details.short_id) {
                let res = await setCallingUrl({
                    conversation_id: active_conv_details.conversation_id,
                    company_id: active_conv_details.company_id,
                    user_id: user_id,
                    type: 'group',
                    action: 'get',

                }, setCallingURL)
                if (res.data.status) {
                    dispatch(setConvShortUrl({ short_id: res.data.short_id }));
                    active_conv_details.short_id = res.data.short_id;
                }
            }

            await ring_calling({
                user_id: user_id,
                conversation_type: 'group',
                arr_participants: [],
                participants_all: active_conv_details.participants,
                company_id: active_conv_details.company_id,
                conversation_id: active_conv_details.conversation_id,
                convname: active_conv_details.title,
                call_link: window.location.origin + '/c/' + active_conv_details.short_id,
                // call_option: arr_participants.length > 1 ? 'ring' : 'window',
                props, set_callMembers, GroupPopupToggle, setGroupCallPopup, fetchCalling, getCallingAccept, getCallingReject

            });

        } else {
            var call_link;
            if (user_id === active_conv_details.conversation_id) {
                if (!active_conv_details.short_id) {
                    let res = await setCallingUrl({
                        conversation_id: active_conv_details.conversation_id,
                        company_id: active_conv_details.company_id,
                        user_id: user_id,
                        type: 'user',
                        action: 'get',

                    }, setCallingURL)
                    if (res.data.status) {
                        dispatch(setUserShortUrl({ short_id: res.data.short_id }));
                        active_conv_details.short_id = res.data.short_id;
                    }
                }

                call_link = window.location.origin + '/u/' + active_conv_details.short_id
            } else {
                if (!active_conv_details.short_id) {
                    let res = await setCallingUrl({
                        conversation_id: active_conv_details.conversation_id,
                        company_id: active_conv_details.company_id,
                        user_id: user_id,
                        type: 'group',
                        action: 'get',

                    }, setCallingURL)
                    if (res.data.status) {
                        dispatch(setConvShortUrl({ short_id: res.data.short_id }));
                        active_conv_details.short_id = res.data.short_id;
                    }
                }

                call_link = window.location.origin + '/c/' + active_conv_details.short_id
            }

            await ring_calling({
                user_id: user_id,
                conversation_type: 'personal',
                arr_participants: active_conv_details.participants,
                participants_all: active_conv_details.participants,
                company_id: active_conv_details.company_id,
                conversation_id: active_conv_details.conversation_id,
                convname: active_conv_details.title,
                call_link: call_link,
                call_option: active_conv_details.participants.length > 1 ? 'ring' : 'window',
                props, fetchCalling, getCallingAccept, getCallingReject
            });
        }
    }

    const flaggedItemShow = params.filter_name === 'flag'

    const handleHeaderSearchOptions = () => {
        setStringSearch('')
        setHeadSearchOptions(true)
        props.setTopSearchBarPopUp(true)
    }

    const HeadSearchOptionsClose = () => {
        setStringSearch('');
        setHeadSearchOptions(false)
        props.setTopSearchBarPopUp(false)
    }

    const url = params.conversation_id
    const get_user_image = (conv, group) => {
        return conv?.title.charAt(0)
    }

    return (
        <>
            <div className="top_bar connectPage">
                <div className={classNames("part_top_bar !pl-0", active_conv?.group === 'yes' ? 'group' : 'single')}>
                    <div className={"conv_img " + (getUserOnlineOrOffline(active_conv?.friend_id) ? ' online' : ' offline')}>
                        {active_conv?.conv_img.indexOf('img.png') > -1 || active_conv?.conv_img.indexOf('feelix.jpg') > -1 ?
                            active_conv?.group !== 'yes' ?
                                active_conv?.conversation_id === props.me.id ?
                                    <h3 className="nameLetters">{props.me.fnln}</h3>
                                    :
                                    <h3 className="nameLetters">{get_user_image(active_conv, 'no')}</h3>
                                :
                                <h3 className="nameLetters" style={{ backgroundColor: '#5c5c5c' }}>{get_user_image(active_conv, 'yes')}</h3>
                            :
                            <img src={active_conv?.conv_img} onClick={gotoProfile} alt="user" title="user" />
                        }
                    </div>
                    {active_conv?.group === 'yes' ?
                        <div className="conv_desc" >
                            <h3 className="conv_name" title={active_conv?.title} onClick={gotoProfile}>{active_conv?.title}</h3>
                            <p className="conv_info_gray -mt-[5px]"><span className="!text-[#5b6477]">{checkLength(active_conv?.participants)}</span> <span className="middleBar !text-[#5b6477]" style={{ padding: '0px' }}>|</span> <span className="!text-[#5b6477]"> {active_conv?.team_id_name}</span> {/*active_conv?.b_unit_id_name !== '' ? <><span style={{padding:'0px'}}>|</span> <span className="">{active_conv?.b_unit_id_name}</span></>:''*/}</p>
                        </div>
                        :
                        <div className="conv_desc" >
                            <h3 className="conv_name" title={active_conv?.title} onClick={gotoProfile}>{active_conv?.title}</h3>
                            <p className="conv_info_gray -mt-[5px]"><span className="!text-[#5b6477]">{checkLength(active_conv?.participants)}</span>
                                <span className="middleBar !text-[#5b6477]" style={{ padding: '0px' }}>|</span>
                                <span className="!text-[#5b6477]"> {active_conv?.team_id_name}</span>|<span className="!text-[#5b6477]">{active_conv?.b_unit_id_name}</span></p>
                        </div>
                    }
                </div>
                {headSearchOptions === true && props.topSearchBarPopUp === true ?
                    <div className="allSearchSection">
                        <div className="searchPartsContainer">
                            <div className="searchSelectionSection !relative" onClick={() => setSearchRoomListOptions(true)}>
                                <div className="py-[2px] flex items-center pl-3 pr-1 mr-2 rounded-full gap-1 bg-white text-[#7b40d5]">
                                    <p>Room</p>
                                    <MdKeyboardArrowDown className='ml-1 mr-1 text-xl' />
                                </div>
                                {searchRoomListOptions && <SearchRoomOptions setSearchRoomListOptions={setSearchRoomListOptions} setHeadSearchOptions={setHeadSearchOptions} />}
                            </div>
                            <input id="searchSecTop" onKeyUp={(event) => handleStringSearch(event)} onChange={(event) => handleStringSearch(event)} value={search_string} className="allSearchInput" type='text' placeholder='Search a message' autoFocus />
                            <span id="clearSearch" className="clearAllSrcTest !right-[75px]" onClick={searchClearMethod}></span>
                            <div className="vl"></div>
                            <div className={`${voice ? 'voiceIcon' : 'voiceIcon1'}`} onClick={() => ToggleVoice(!voice)} data-for="top_head" data-tip="Voice to text. Say 'clear' to clear text and 'search' for searching"></div>
                            {
                                !search_string ? <GoSearch className="text-[#318fff] text-xl absolute right-3 cursor-pointer" /> : <GoSearch className="text-[#318fff] text-xl absolute right-3 cursor-pointer" onClick={() => navigate(`filter/str/${search_string}`)} />
                            }
                        </div>
                        {/* <span className="closeConnectSearch" onClick={HeadSearchOptionsClose}></span> */}
                        <IoClose className=" absolute text-3xl cursor-pointer top-[24px] right-[10px] " onClick={HeadSearchOptionsClose} />

                    </div>
                    :
                    <div className='top_bar_right_section flex items-center'>
                        <div className="top_bar_left_options flex">
                            <div className="part_top_bar">

                                <div className="buttonContainerTop">
                                    <div id="btnFirst">
                                        <Link to={"tasks/create-task"}>
                                            <IoMdAdd className='text-white w-5 h-5' />
                                        </Link>
                                    </div>
                                    <div id="btnLast" className="darkTextWhite !w-[70px]">
                                        <Link className="darkTextWhite !px-2" to='tasks/kanban-view'>Tasks</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="part_top_bar">
                                <h3 className="file_opt tooltip2" data-for="top_head" data-tip="Create a task" style={{ width: '120px' }}>Create task</h3>
                            </div> */}

                            <div className="part_top_bar !h-[34px]" id="fileHub">
                                <Link to={'file_hub'} className="file_opt !pb-0 tooltip2 hover:bg-[#318fff]" >Files</Link>
                            </div>
                        </div>

                        {/* {
                            !flaggedItemShow &&
                            <div className="part_top_bar" id="fileHub">
                                <Link to={'file_hub'} title="file hub" className="file_opt tooltip2" >Files</Link>
                            </div>
                        } */}

                        <div className="line-top"></div>
                        <div className="part_top_bar top_bar_right_options">
                            {
                                props.params ?
                                    <div className={`active opt_icons conv_filter relative`} id="Filters"  ></div> :

                                    <div className="relative">
                                        {
                                            !flaggedItemShow &&
                                            <div onClick={() => setOpenHeadMoreOptions(!openHeadMoreOptions)} className={`${openHeadMoreOptions ? 'active' : ''} opt_icons conv_filter conv_filter_chatTop relative`} id="Filters"  ></div>
                                        }
                                        {openHeadMoreOptions && <FilterOptions openHeadMoreOptions={openHeadMoreOptions} me={props.me.id} url={url} conversation_id={conversation_id} setOpenHeadMoreOptions={setOpenHeadMoreOptions} />}
                                    </div>
                            }
                            {(active_conv?.has_mute && active_conv?.has_mute.indexOf(props.me.id) > -1 && props.room?.conversation_id !== props.me?.id) &&
                                <div className={classNames("opt_icons conv_mute")} onClick={() => navigate('mute_conversation')} data-for="top_head" data-tip="Unmute notification for this room"></div>
                            }
                            {/* {
                                !flaggedItemShow &&
                                <div className={`opt_icons conv_voice ${groupCallPopup ? 'active' : ''} `} onClick={() => setGroupCallPopup(true)} id="startCall" ></div>
                            } */}
                            {/* {
                                !flaggedItemShow &&
                                <div className={`opt_icons conv_voice ${groupCallPopup ? 'active' : ''} `} onClick={() => open_calling_window()} id="startCall" ></div>
                            } */}
                            {/* {!flaggedItemShow ?
                                conv_calling_data && conv_calling_data[props.room?.conversation_id] ?
                                    <div className={`opt_icons conv_voice join-call animate-flicker-call ${groupCallPopup ? 'active' : ''} `} onClick={() => open_calling_window()} id="startCall" ></div>
                                    :
                                    <div className={`opt_icons conv_voice ${groupCallPopup ? 'active' : ''} `} onClick={() => open_calling_window()} id="startCall" ></div>
                            : ''} */}

                            {!flaggedItemShow ? (
                                conv_calling_data && conv_calling_data.some(({ conv_id }) => conv_id === props.room?.conversation_id) ? (
                                    <div
                                        className={`opt_icons conv_voice join-call animate-flicker-call ${groupCallPopup ? 'active' : ''}`}
                                        onClick={() => open_calling_window()}
                                        id="startCall"
                                    ></div>
                                ) : (
                                    <div
                                        className={`opt_icons conv_voice ${groupCallPopup ? 'active' : ''}`}
                                        onClick={() => open_calling_window()}
                                        id="startCall"
                                    ></div>
                                )
                            ) : ''}

                            {(active_conv?.pin.indexOf(props.me.id) > -1 && props.room?.conversation_id !== props.me?.id) && <div className={classNames("opt_icons conv_unpin active")} onClick={handlePinUnpin} id="upPin"></div>}

                            <div id="my-tooltip" className="opt_icons conv_search" onClick={handleHeaderSearchOptions}></div>

                            {(props.room?.conversation_id !== props.me?.id && !params.filter_name) &&
                                <div id="options" onClick={() => setRoomMoreOptions(!roomMoreOptions)} className={`${roomMoreOptions ? 'active' : ''} opt_icons conv_more`} data-tip="More options"></div>
                            }

                        </div>
                    </div>
                }
            </div>
            {roomMoreOptions && <ChatRoomMoreOptions setRoomMoreOptions={setRoomMoreOptions} me={props.me} room={props.room} />}
            {groupCallPopup && <GroupCallPopup fetchCalling={fetchCalling} getCallingAccept={getCallingAccept} getCallingReject={getCallingReject} setGroupCallPopup={setGroupCallPopup} active_conv={active_conv} />}
            {/* {callringpopup.status && <CallRinging data={callringpopup} getCallingAccept={getCallingAccept} getCallingReject={getCallingReject} me={props.me} setCallRinging={setCallRinging} active_conv={active_conv} />} */}

            <Tooltip delayShow={1000} className="z-50" anchorSelect="#btnFirst" content="Create a task"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#btnLast" content="View all tasks in this room"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#fileHub" content="Files"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#Filters" content="Filters"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#my-tooltip" content="Search this channel"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#options" content="More options"></Tooltip>

            <Tooltip delayShow={1000} className="z-50" anchorSelect="#startCall"
                content={conv_calling_data.some(({ conv_id }) => conv_id === props.room?.conversation_id) ? "Join call" : "Start a call"}></Tooltip>


            <Tooltip delayShow={1000} className="z-50" anchorSelect="#clearSearch" content="Clear Search"></Tooltip>
            <Tooltip delayShow={1000} className="z-50" anchorSelect="#upPin" content="Unpin this room"></Tooltip>
        </>
    )
}