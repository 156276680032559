import React, { useState } from 'react';

const demoData = [
    {
        fileName: "Shakil-Ahmed-2.webp",
        fileSize: "8.10 KB",
        fileType: "image/webp",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/Shakil-Ahmed-2@1731754782841.webp",
        tags: ["#Maintenance", "#CARPENTRY", "#estimate-docs"],
    },
    {
        fileName: "Shakil Ahmed (5).png",
        fileSize: "78.55 KB",
        fileType: "image/png",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/Shakil_Ahmed_(5)@1731754781211.png",
        tags: ["#Maintenance", "#CARPENTRY", "#estimate-docs"],
    },
    {
        fileName: "postLine.png",
        fileSize: "4.72 KB",
        fileType: "image/png",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/postLine@1731754779951.png",
        tags: ["#Maintenance", "#CARPENTRY", "#estimate-docs"],
    },
    {
        fileName: "Extra File.png",
        fileSize: "5.50 KB",
        fileType: "image/png",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/postLine@1731754779951.png",
        tags: ["#Extra", "#Demo"],
    },
    {
        fileName: "postLine.png",
        fileSize: "4.72 KB",
        fileType: "image/png",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/postLine@1731754779951.png",
        tags: ["#Maintenance", "#CARPENTRY", "#estimate-docs"],
    },
    {
        fileName: "Extra File.png",
        fileSize: "5.50 KB",
        fileType: "image/png",
        fileLink: "https://wfss001.freeli.io/msrkhokoncse-gmail-com/Photos/postLine@1731754779951.png",
        tags: ["#Extra", "#Demo"],
    },
];

const UploadedFileList = () => {
    const [showAll, setShowAll] = useState(false);

    // Data to display based on the `showAll` state
    const visibleData = showAll ? demoData : demoData.slice(0, 3);
    return (
        <div className='relative'>
            <div className='ml-5 my-2 cursor-pointer'>
                <p className='text-[#032e84] hover:text-[#0079d1]'>Please upload a file first!</p>
            </div>
            <div className='relative flex gap-2 justify-between items-start'>
                <div className=" w-[calc(100%-140px)] grid grid-cols-3 gap-2 ml-5 mb-2" >
                    {visibleData.map((file, index) => (
                        <div key={index} className="multi_value single_file" filetype={file.fileType}>
                            <span className="fileActiveActions"></span>
                            <div className="file_hover_option">
                                <div data-tip="Add a tag" className="fileOpts tag_opts"></div>
                                <div
                                    data-tip="Download"
                                    className="fileOpts download_opts"
                                    data-name={file.fileName}
                                    data-link={file.fileLink}
                                ></div>
                                <div data-tip="Delete" className="fileOpts delete_opts"></div>
                            </div>
                            <div className="fileContent audioFiles">
                                <div className="img_holder">
                                    <img src={file.fileLink} alt={file.fileName} />
                                </div>
                                <div className="file_info">
                                    <p className="file_name" title={file.fileName}>
                                        {file.fileName}
                                    </p>
                                    <p className="file_size">{file.fileSize}</p>
                                </div>
                                <div className="fileTagHolder">
                                    {file.tags.map((tag, tagIndex) => (
                                        <span
                                            key={tagIndex}
                                            className="fileTag"
                                            style={{ backgroundColor: tagIndex === 0 ? "rgb(232, 42, 135)" : "rgb(10, 37, 106)" }}
                                        >
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {!showAll && demoData.length > 3 && (
                    <div
                        className="attachFileMaintenance absolute right-5 top-0 w-[88px] "
                        filetype="image/png"
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => setShowAll(true)}>
                        <p className="py-[38px] pl-6 text-black font-bold text-xl">  + {demoData.length - 3}</p>
                    </div>
                )}

            </div>
            {
                showAll && <div className='w-full text-center mb-2 cursor-pointer' onClick={() => setShowAll(!showAll)}>
                    <p className='text-[#032e84] hover:text-[#0079d1]'>Show Less</p>
                </div>
            }
        </div>
    );
};

export default UploadedFileList;